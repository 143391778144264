import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useSelector } from 'react-redux';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import Header from '../../components/header';
import '../../Global.css';
import { RootState } from '../../redux/store';

const BrandSelection: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          <IonRow className='desktopContent'>
            <IonCol size='12' className='ion-no-padding ion-no-margin'>
              <DesktopContainer>
                <div
                  className='background'
                  style={{
                    backgroundImage: `url(https://za-api.mapo-int.com/api/theme/getbackgroundimage?user=${sessionState.user?.username})`,
                  }}
                ></div>
              </DesktopContainer>
            </IonCol>
          </IonRow>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>Tutorial - content required</div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default BrandSelection;
