import { useCallback, useState } from "react";
import { icons, images } from "../assets";
import { IonIcon, IonRow } from "@ionic/react";
import { chevronDown, chevronForward, closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface DataAssuranceProps {}

const DataAssurance: React.FC<DataAssuranceProps> = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const [btnTxt, setBtnTxt] = useState("CLOSE");
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const dataSourceFile = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].dataSource;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  return (
    <div className="dataIntegrityHolderCont" >
      <p className="title">DATA INTEGRITY</p>
      <div className="dataIntegrityHolder">
        <div className="top">
          <IonRow className="topChild">
            <span>SOURCE:</span>
            <span>
              &nbsp;
              {dataSourceFile(uiState.selectedVehicle?.g_id) == "thirdparty"
                ? "Third Party"
                : dataSourceFile(uiState.selectedVehicle?.g_id)}
            </span>
          </IonRow>
          <IonRow className="topChild">
            <span>TYPE:</span>
            <span>&nbsp; Recommended Retail Excl VAT</span>
          </IonRow>
          <IonRow className="topChild">
            <span>UPDATED:</span>
            <span>&nbsp; {getFileAge(uiState.selectedVehicle?.g_id)}</span>
          </IonRow>
        </div>

        <IonRow className="planDropDown trigger">
          <span className="dropDownIcon">
            <IonIcon
              icon={showDisclaimer == false ? chevronForward : chevronDown}
              onClick={() => {
                setShowDisclaimer(!showDisclaimer);
              }}
            ></IonIcon>
          </span>
          <span className="textDropDown">DISCLAIMER</span>
        </IonRow>
        {showDisclaimer && (
          <div className="disclaimer">
            DISCLAIMER <br />
            All MAPO Solutions are market research and data analytic solutions
            that analyse the data that is fed into the system and then provide
            clear reports. Although care has been taken to ensure the accuracy,
            completeness and reliability of the information provided, The
            Supplier assumes no responsibility, therefore. The information is
            provided `as is` without warranty of any kind. The Supplier does not
            accept any responsibility or liability for the accuracy, content,
            completeness, legality, or reliability of the information contained
            in any of the MAPO Solutions.
            <br />
            <br /> MAPO shall not be liable for any loss or damage of whatever
            nature (direct, indirect, consequential, or other) whether arising
            in Agreement, tort or otherwise, which may arise as a result of your
            use of (or inability to use) this data, or from your use of (or
            failure to use) the information. MAPO shall, at no time, act as
            agent on behalf of OEMs. MAPO shall, furthermore, not originate in
            the marketing or promotion of an OEM or any of its products, in any
            manner, and shall be limited to providing the information as
            provided for in terms of the subscription on MAPO Solutions.
            <br />
            <br /> Nothing in the subscription to MAPO Solutions shall
            constitute a partnership or joint venture arrangement of any kind
            between the parties nor does this agreement entitle or authorize
            MAPO to incur any liability or undertake any obligation on behalf of
            any OEM.
          </div>
        )}
      </div>
    </div>
  );
};

export default DataAssurance;
