import {
  IonAccordion,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  useIonViewDidEnter,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import "../../Global.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./style.css";
import { Md5 } from "ts-md5";
import { Buffer } from "buffer";
import { icons, images } from "../../assets";
import LoginForm from "./login-form";
import RegistrationForm from "./registration-form";
import ForgotPasswordForm from "./forgotpassword-form";
import { User } from "../../interfaces";
import {
  hideLoader,
  hideSideMenu,
  setFileAgeData,
  setFilteredSearchVisible,
  setLabourRatesData,
  setLargeHeader,
  setScreen,
  setShareSocialVisible,
  setUndentifiedVinSearchVisible,
  showLoader,
  showSideMenu,
} from "../../redux/ui/uiSlice";
import { LabourRates, getPriceFileAge, getTheme } from "../../api/mapo";
import {
  sessionLogin,
  sessionLogout,
  setBranded,
  setSelectedOem,
  setTermsAccepted,
  setTheme,
} from "../../redux/session/sessionSlice";
import { AppScreens } from "../../enums";
import { authUser, userOrganizationDetails } from "../../api/auth";
import Heade from "../../components/heade";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import InstallPrompt from "../../components/install/install";
import { Button } from "react-bootstrap";
import { RSA_NO_PADDING } from "constants";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const Login: React.FC = (props) => {
  // @ts-ignore
  window.Buffer = Buffer;
  const uiState = useSelector((state: RootState) => state.ui);
  const [mode, setMode] = useState(1);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const userName = queryParams.get("x")?.toString() || "";
  const hash = queryParams.get("y")?.toString() || "";

  const buff1 = Buffer.from(userName, "base64");
  const buff2 = Buffer.from(userName, "base64");
  let u = buff1.toString("ascii");
  let p = buff2.toString("ascii");
  const [user, setUser] = useState<User>({
    username: u,
    password: p,
  });
  let today = new Date().toISOString().slice(0, 10);
  let triggered = false;
  let md = Md5.hashStr(u + today);

  const onSignInPress = useCallback(() => {
    setMode(1);
  }, [setMode]);

  const onSignUpPress = useCallback(() => {
    setMode(2);
  }, [setMode]);

  const onForgotPasswordPress = useCallback(() => {
    setMode(3);
  }, [setMode]);

  const history = useHistory();

  const LoginMethod = () => {
    if (hash === md) {
      dispatch(showLoader());
      userOrganizationDetails(user)
        .then((regResponse) => {
          if (regResponse.status === 200) {
            LabourRates()
              .then((LabourRatesResponse) => {
                dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
                getPriceFileAge()
                  .then((priceAgeResponse) => {
                    dispatch(setFileAgeData(priceAgeResponse.data));
                    getTheme(user.username ?? "")
                      .then((themeResponse) => {
                        dispatch(hideLoader());
                        const newUser: User = {
                          ...user,
                          loginResponse: regResponse.data,
                        };
                        if (
                          newUser?.loginResponse?.oems &&
                          newUser?.loginResponse?.oems?.length > 0
                        ) {
                          dispatch(
                            setSelectedOem(newUser?.loginResponse?.oems[0])
                          );
                        }
                        dispatch(sessionLogin(newUser));
                        dispatch(setTheme(themeResponse.data));
                        dispatch(setBranded(true));

                        if (
                          newUser.loginResponse?.message?.indexOf(
                            "Terms and conditions need to be accepted"
                          ) !== -1
                        ) {
                          if (uiState.isDesktop) {
                            dispatch(setTermsAccepted(false));
                            //dispatch(setScreen(AppScreens.Welcome));
                            history.push("/home");
                          } else {
                            // setTermsVisible(true);
                          }
                        } else {
                          dispatch(setTermsAccepted(true));
                          // dispatch(setScreen(AppScreens.Home));
                          history.push("/home");
                        }
                        window.location.href = "/";
                      })
                      .catch((err) => {
                        dispatch(hideLoader());
                        console.log(err);
                        alert(JSON.stringify(err));
                      });
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    console.log(err);
                    alert(JSON.stringify(err));
                  });
              })
              .catch((err) => {
                dispatch(hideLoader());
                console.log(err);
                alert(JSON.stringify(err));
              });
          } else {
            alert(regResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log(err);
          alert(JSON.stringify(err));
        });
    }
  };

  const sessionState = useSelector((state: RootState) => state.session);

  const onToggleMenu = useCallback(() => {
    if (!uiState.sideMenuOpen) {
      dispatch(showSideMenu());
    } else {
      dispatch(hideSideMenu());
    }
  }, [dispatch, uiState.sideMenuOpen]);

  // const onLogout = useCallback(() => {
  //   dispatch(setLargeHeader(false));
  //   dispatch(hideSideMenu());
  //   dispatch(sessionLogout());
  //   dispatch(setScreen(AppScreens.Login));
  // }, [dispatch]);

  const onHome = useCallback(() => {
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Home));
    //history.push("/home");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));

    setTimeout(() => {
      window.location.reload();
      history.push("/home");
    }, 500);
  }, [dispatch]);

  const onGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Garage));
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.VehicleLanding));
      history.push("/Garage");
    }, 500);
    // history.push("/Garage");
  }, [dispatch]);

  const onTutorial = useCallback(() => {
    const guest: User = {
      username: "GUEST",
    };
    dispatch(sessionLogin(guest));
    dispatch(setBranded(true));
    history.push("/home");
    setTimeout(() => {
      history.push("/Tutorial");
    }, 500);
  }, [dispatch]);

  const onAbout = useCallback(() => {
    const guest: User = {
      username: "GUEST",
    };
    dispatch(sessionLogin(guest));
    dispatch(setBranded(true));
    history.push("/home");
    setTimeout(() => {
      history.push("/About");
    }, 500);
  }, [dispatch]);

  const Myprofile = useCallback(() => {
    //dispatch(setScreen(AppScreens.UserProfile));
    // history.push("/UserProfile");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/UserProfile");
    }, 500);
  }, [dispatch]);

  const onSupport = useCallback(() => {
    const guest: User = {
      username: "GUEST",
    };
    dispatch(sessionLogin(guest));
    dispatch(setBranded(true));
    history.push("/home");
    setTimeout(() => {
      history.push("/Support");
    }, 500);
  }, [dispatch]);

  const onContact = useCallback(() => {
    const guest: User = {
      username: "GUEST",
    };
    dispatch(sessionLogin(guest));
    dispatch(setBranded(true));
    history.push("/home");
    setTimeout(() => {
      history.push("/Contact");
    }, 500);
  }, [dispatch]);

  const OnShare = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    dispatch(setShareSocialVisible(true));
  }, [dispatch]);

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    //history.push("/login");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  const OnCart = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Cart");
    }, 500);
  }, [dispatch]);

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, []);

  const renderSideMenu = useCallback(() => {
    return (
      <>
        {uiState.sideMenuOpen ? (
          <div
            className="gridSideMenuCont"
            onClick={() => {
              onToggleMenu();
            }}
          >
            <IonRow className="gridSideMenu">
              <IonCol size="12" sizeXl="12" className="gridSideMenuItems">
                INFO &amp; SUPPORT
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onTutorial}
              >
                <img src={icons.appTutorialIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">APP TUTORIAL</span>
              </IonCol>

              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onAbout}
              >
                <img src={icons.mobileMapoIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">MAPO SOLUTIONS</span>
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onContact}
              >
                <img src={icons.appContactIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">CONTACT US</span>
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={OnShare}
              >
                <img src={icons.appShareIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">Share</span>
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
              ></IonCol>
            </IonRow>
          </div>
        ) : null}
      </>
    );
  }, [
    uiState.sideMenuOpen,
    onHome,
    onGarage,
    onToggleMenu,
    onAbout,
    onTutorial,
    onContact,
    onSupport,
    sessionState.user,
  ]);

  useIonViewDidEnter(() => {
    if (!triggered && hash === md) {
      triggered = true;
      LoginMethod();
      LabourRates()
        .then((LabourRatesResponse) => {
          dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log(err);
          alert(JSON.stringify(err));
        });
    }
  });

  return (
    <>
      <IonGrid>
        <InstallPrompt />
        <IonRow className="gridHeader">
          <IonCol>
            <img
              id="grdiHeaderIcons"
              src={icons.WhiteIconMenu}
              onClick={() => {
                onToggleMenu();
              }}
            />
          </IonCol>
          <IonCol sizeXs="6" sizeMd="6" sizeLg="6">
            <img id="grdiHeaderLogo" src={images.logo} alt="" />
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>
      {renderSideMenu()}
      <IonGrid>
        <IonRow className="informedDecision">
          <IonCol size="12">
            <h1 id="header">MAKE THE INFORMED DECISON</h1>
          </IonCol>
          <br />
          <IonCol size="12">
            <h2> Are you looking to purchase your dream vehicle.</h2>
          </IonCol>
          <IonCol size="12">
            <p>
              {" "}
              MAPO is a market research tool on vehicles and equipment in
              various industries. We offer data in the areas of their parts
              pricing, service- and maintenance costs, total cost of operation
              and much more. <br />
              <br />
              Stay informed, plan effectively, and approach your next vehicle
              purchase with confidence.
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            {mode === 1 && (
              <LoginForm
                onSignUpMode={onSignUpPress}
                onForgotPasswordMode={onForgotPasswordPress}
              />
            )}
            {mode === 2 && <RegistrationForm onSigninMode={onSignInPress} />}
            {mode === 3 && <ForgotPasswordForm onSigninMode={onSignUpPress} />}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Login;
