import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { useCallback, useState } from "react";
import Button from "../../components/button";
import "../../Global.css";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/ui/uiSlice";
import "./style.css";
import Link from "../../components/link";
import { forgotPassword } from "../../api/auth";
import { User } from "../../interfaces";
import { images } from "../../assets";
import { checkmarkDoneCircleSharp } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";

interface ForgotPasswordFormProps {
  onSigninMode: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSigninMode,
}: ForgotPasswordFormProps) => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [user, setUser] = useState<User>({
    username: "",
  });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const dispatch = useDispatch();

  const onForgotPasswordPress = useCallback(() => {
    if (!/^\S+@\S+\.\S+$/.test(user.username || "")) {
      setErrorMessage("Please enter a valid email address.");
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
      return;
    }

    dispatch(showLoader());
    forgotPassword(user)
      .then((regResponse) => {
        dispatch(hideLoader());
        if (regResponse.status === 200) {
          // Handle success
          setSuccessMessage("Link sent to your email address.");
        } else {
          alert(JSON.stringify(regResponse));
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        alert(JSON.stringify(err));
      });
  }, [dispatch, user]);

  return (
    <>
      {" "}
      <IonGrid className="loginPageCont">
        <IonRow>
          <IonCol size="12" className="guestOption">
            <IonCard className="guest-card">
              <IonCol size="12" className="guest-btn">
                <button
                  className="guest-btn"
                  // onClick={onForgotPasswordPress}
                  title="Sign In"
                >
                  Forgot Password
                </button>
              </IonCol>

              <IonCol size="12" className="formLabels">
                <IonLabel className="label">
                  Your username
                  <IonInput
                    className="input"
                    placeholder="Enter a 6-10 character username"
                    value={user.username}
                    onIonChange={(ev: any) =>
                      setUser({ ...user, username: ev.target.value })
                    }
                  ></IonInput>
                </IonLabel>
                <IonLabel className="label">
                  <button onClick={onForgotPasswordPress} title="Sign In">
                    Request Reset
                  </button>
                </IonLabel>

                <IonCol size="12" onClick={onSigninMode} className="noAccount">
                  Already have a login?{" "}
                  <strong onClick={onSigninMode} style={{ color: "black" }}>
                    SIGN IN
                  </strong>
                </IonCol>
              </IonCol>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ForgotPasswordForm;
