import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
} from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { desktopImages, icons, images } from "../../../assets";
import Button from "../../../components/button";
import DesktopContainer from "../../../components/desktop-container";
import Header from "../../../components/header";
import MessageModal from "../../../components/message-modal";
import SideMenu from "../../../components/side-menu";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens, Format } from "../../../enums";
import "../../../Global.css";
import { RootState } from "../../../redux/store";
import {
  hideLoader,
  hideSideMenuDisabledItems,
  setCompetitors,
  setCustomReportParams,
  setFilteredSearchVisible,
  setScreen,
  setSelectedVehicle,
  showLoader,
  showSideMenuDisabledItems,
} from "../../../redux/ui/uiSlice";
import {
  DataCompleteness,
  SelectedVehicle,
  Vehicle,
} from "../../../interfaces";
import {
  Attributes,
  ModelSpecification,
} from "../../../interfaces/modelSpecification";
import { useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import DesktopHeader from "../../../components/desktop-header";
import DesktopVehicleLabel from "../../../components/desktop-vehicle-label";
import { CompetitorData } from "../../../interfaces/competitorData";
import DesktopChartLegend from "../../../components/desktop-chart-legend";
import { decVal, format } from "../../../utils";
import { VehicleCustomReportParams } from "../../../interfaces/customReportParams";
import Tutorial from "../../Tutorial";
import axios from "axios";
import { sessionLogout } from "../../../redux/session/sessionSlice";
import {
  addVehicle,
  createTransaction,
  dataCompleteness,
  getGarage,
  getImage,
  manualSearch,
  modelClass,
  modelSpecificationDetails,
  queryTransaction,
} from "../../../api/mapo";
import { Config } from "../../../Config";
import { Transaction } from "../../../interfaces/transaction";
import {
  chevronBackCircleSharp,
  cartSharp,
  eyeSharp,
  thumbsUpSharp,
} from "ionicons/icons";
import moment from "moment";
import { ModelClass } from "../../../interfaces/modelClass";
import {
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../../api/getAll";
import { vistorsParking } from "../../../interfaces/VistorsParking";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
}

const ScanResults: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [garageData, setGarageData] = useState<Array<ModelClass> | undefined>();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [addCartAlert, setAddCartAlert] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [CartClicked, setIsClicked] = useState(false);
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );

  const onModalPress = useCallback((buttonIndex: number) => {
    setModalVisible(false);
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push("/home");
  }, [dispatch]);

  const onEyePress = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push("/OverViewDetail");
  }, [dispatch]);
  //To save the selectedVehicle in the cart
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Hide the message after 3 seconds (adjust as needed)
    const timeoutId = setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, 3000);

    return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or state change
  }, [showMessage]);

  const showMessageWithTimeout = (text: any) => {
    setMessage(text);
    setShowMessage(true);
  };

  //The Date for the vehicle in modelClass
  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  const handleSaveSelectedVehicleToCart = () => {
    if (uiState.selectedVehicle) {
      // Retrieve existing cart data from localStorage
      const storedCartString = localStorage.getItem("cart");
      const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

      // Check if the item with the same 'gmv' is already in the cart
      const isDuplicate = existingCart.some(
        (item: any) => item.variant === uiState.selectedVehicle?.variant
      );

      if (!isDuplicate) {
        // Add the current model to the cart
        existingCart.push(uiState.selectedVehicle);

        localStorage.setItem("cart", JSON.stringify(existingCart));
        // Set the flag to indicate that the item was added
        setIsClicked(true);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ADDED TO CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      } else {
        setTimeout(() => {
          setAddCartMsg("VEHICLE ALREADY IN CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (garageData === undefined) {
      dispatch(showLoader());

      getGarage(sessionState.user?.username ?? "")
        .then((getGarageResponse) => {
          if (getGarageResponse.status === 200) {
            const newList: Array<ModelClass> = [];
            const filteredGarageItems = getGarageResponse.data.filter(
              (garageItem: { g_id: number; m_id: number; v_id: number }) =>
                garageItem.g_id !== 0 &&
                garageItem.m_id !== 0 &&
                garageItem.v_id !== 0
            );

            if (filteredGarageItems.length === 0) {
              dispatch(hideLoader());
              setGarageData([]);
            } else {
              filteredGarageItems.forEach((garageItem: any) => {
                modelClass(garageItem.g_id, garageItem.m_id, garageItem.v_id)
                  .then((modelClassResponse) => {
                    if (modelClassResponse.status === 200) {
                      newList.push(modelClassResponse.data);
                      if (newList.length === filteredGarageItems.length) {
                        dispatch(hideLoader());
                        setGarageData(newList);
                      }
                    } else {
                      dispatch(hideLoader());
                      console.log(modelClassResponse.statusText);
                      return;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(hideLoader());
                    console.log(JSON.stringify(err));
                  });
              });
            }
          } else {
            dispatch(hideLoader());
            console.log(getGarageResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log(JSON.stringify(err));
        });
    }
  }, [garageData, sessionState.user]);

  //Clicking on the Vistors parking To click
  const onSelectVehicleAllParking = useCallback(
    (modelClass: vistorsParking) => {
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competitors ?? []));

                const selectedVehicleCustomParams: VehicleCustomReportParams = {
                  retailprice: decVal(
                    allData.selected?.modelSpecification.result?.basic_specs
                      ?.base_price
                  ),
                  co2tax: Number(
                    allData.selected?.modelSpecification.result?.engine_specs
                      .co2 ?? ""
                  ),

                  // Service
                  servicestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan ?? "0"
                  ),
                  servicestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan_months ?? "0"
                  ),

                  // Maintenance
                  maintenancestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan ?? "0"
                  ),
                  maintenancestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan_months ??
                      "0"
                  ),

                  // baloonpayment: 0,
                };

                const competitorCustomParams: Array<VehicleCustomReportParams> =
                  [];

                allData.competitors?.map((competitor) => {
                  const competitorCustomParam: VehicleCustomReportParams = {
                    retailprice: decVal(
                      competitor.modelSpecification.result?.basic_specs
                        ?.base_price
                    ),
                    co2tax: Number(
                      competitor.modelSpecification.result?.engine_specs.co2 ??
                        ""
                    ),

                    // Service
                    servicestandardkm: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.service_plan ?? "0"
                    ),
                    servicestandardmnt: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.service_plan_months ??
                        "0"
                    ),

                    // Maintenance
                    maintenancestandardkm: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.maintenance_plan ?? "0"
                    ),
                    maintenancestandardmnt: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty
                        .maintenance_plan_months ?? "0"
                    ),

                    baloonpayment: 0,
                  };
                  competitorCustomParams.push(competitorCustomParam);
                });

                dispatch(
                  setCustomReportParams({
                    ...uiState.customReportParams,
                    selectedVehicleCustomParams: selectedVehicleCustomParams,
                    competitorCustomParams: competitorCustomParams,
                  })
                );

                dispatch(hideLoader());
                dispatch(setFilteredSearchVisible(false));
                //dispatch(setScreen(AppScreens.VehicleDetail));
                dispatch(showSideMenuDisabledItems());
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }

          dispatch(hideSideMenuDisabledItems());
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );
  const onSelectVehicleParking = useCallback(
    (modelClass: vistorsParking) => {
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competition ?? []));

                dispatch(hideLoader());
                dispatch(setFilteredSearchVisible(false));
                // dispatch(setScreen(AppScreens.VehicleDetail));
                // history.push("/VehicleDetail");
                setTimeout(() => {
                  //dispatch(setScreen(AppScreens.VehicleLanding));
                  history.push("/VehicleDetail");
                }, 500);
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );
  return (
    <>
      
        <GlobalGrid
          currentPageTitle="SCAN RESULTS"
          closeCurrentPage={() => onBackPress()}
        >
          
          <IonGrid>
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>VIN</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("Vin Scanned") || ""}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>ENGINE</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("ScannedEngine") || ""}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>REGISTRATION</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("ScannedRegistration") || ""}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>GROUP</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("ScannedGroup") || ""}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>MODEL</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("ScannedModel") || ""}
              </IonCol>
            </IonRow>
            
            <IonRow>
              <IonCol className="ion-justify-content-end desktopLabelLower">
                <strong>BODY TYPE</strong>
              </IonCol>
              <IonCol className="ion-justify-content-start padding-table desktopValue">
                {sessionStorage.getItem("ScannedType") || ""}
              </IonCol>
            </IonRow>
          </IonGrid>
          {addCartAlert && (
            <div className="addToCartAlertCont">
              <div className="thumb">
                <IonIcon src={thumbsUpSharp}></IonIcon>
              </div>
              <p>{addCartMsg}</p>
            </div>
          )}
<IonCol className="ion-justify-content-center">
<p className="vinResultsTxt">
            The information obtained was cross-referenced with our database,
            confirming it's identity as the following model.
          </p>
</IonCol>
          

          <section className="ScanResultsDetail">
            {uiState.selectedVehicle ? (
              <div className="returnedCar">
                <div className="returnedCarImage">
                  <img
                    className="carImg"
                    src={getImage(
                      uiState.selectedVehicle.g_id.toString(),
                      uiState.selectedVehicle.m_id.toString(),
                      uiState.selectedVehicle.v_id.toString()
                    )}
                    alt=""
                  />
                </div>
                <div className="returnedCarDetails">
                  <span>
                    {uiState.selectedVehicle.make}{" "}
                    {uiState.selectedVehicle.model}
                  </span>
                  <span> {uiState.selectedVehicle.variant}</span>

                  {Array.isArray(ModelDate) &&
                    ModelDate.map((details, index) => (
                      <span key={index} className="garageDate">
                        MY{" "}
                        {moment(new Date(details.introDate ?? "")).format(
                          "YYYY"
                        )}
                      </span>
                    ))}

                  {uiState.selectedVehicle ? (
                    <span className="checkoutResultsMini">
                      {uiState.VistorsParking?.some(
                        (result) =>
                          result.g_id === uiState.selectedVehicle?.g_id &&
                          result.m_id === uiState.selectedVehicle?.m_id &&
                          result.v_id === uiState.selectedVehicle?.v_id
                      ) ? (
                        <>
                          {uiState.VistorsParking?.filter(
                            (result) =>
                              result.g_id === uiState.selectedVehicle?.g_id &&
                              result.m_id === uiState.selectedVehicle?.m_id &&
                              result.v_id === uiState.selectedVehicle?.v_id
                          ).map((filteredResult, index) => (
                            <>
                              <button
                                onClick={() => {
                                  onSelectVehicleParking(filteredResult);
                                  onSelectVehicleAllParking(filteredResult);
                                }}
                                className="ParkingIcon"
                              >
                                <img id="icon" src={icons.VistorsParking} />
                              </button>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <button onClick={onEyePress} className="eyeIcon" style={{color: 'white'}}>
                            <IonIcon id="icon" src={eyeSharp} color="white"/>
                          </button>
                        </>
                      )}

                      {!garageData?.find(
                        (garageItem: any) =>
                          garageItem.result[0].groupCode ===
                            uiState.selectedVehicle?.g_id &&
                          garageItem.result[0].modelCode ===
                            uiState.selectedVehicle?.m_id &&
                          garageItem.result[0].variantCode ===
                            uiState.selectedVehicle?.v_id
                      ) ? (
                        <button
                          onClick={handleSaveSelectedVehicleToCart}
                          className="cartIcon"
                          style={{color:'white'}}
                        >
                          ADD TO CART{" "}
                          <img id="icon" src={icons.WhiteCartIcon} />
                        </button>
                      ) : (
                        <button className="Garage-Icon">
                          IN GARAGE <img id="icon" src={icons.GarageHeader} />
                        </button>
                      )}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}
          </section>
        </GlobalGrid>
      
    </>
  );
};

export default ScanResults;
