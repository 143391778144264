import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonIcon,
  IonGrid,
} from "@ionic/react";
import {
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  personCircle,
  locationOutline,
  cogSharp,
  refresh,
} from "ionicons/icons";
import { RootState } from "../../redux/store";
import axios from "axios";
import { useHistory } from "react-router-dom";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import "./userProfile.css";
import "../../dynamsoft.config";
import VideoCapture from "./ScanComponents/VideoCapture/VideoCapture";
import DesktopContainer from "../../components/desktop-container";

enum Modes {
  VIDEO_CAPTURE = "video",
  IMAGE_CAPTURE = "image",
}

interface BarcodeResult {
  format: string;
  text: string;
  [key: string]: any; // This allows for any other parsed fields
}

const UserProfile: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.Home));
    history.push("/home");
  }, [dispatch]);
  const [mode, setMode] = useState<Modes>(Modes.VIDEO_CAPTURE);
  const [results, setResults] = useState<BarcodeResult[]>([]);
  const [isCameraVisible, setIsCameraVisible] = useState(false);

  const showVideoCapture = () => {
    setMode(Modes.VIDEO_CAPTURE);
    setIsCameraVisible((prevState) => !prevState);
  };
  const showImageCapture = () => {
    setMode(Modes.IMAGE_CAPTURE);
  };

  console.log("The results", results);
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [photo, setPhoto] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Load image from local storage on component mount
    getGeoInfo();
  }, []);

  function convertHex(val: string): string {
    // Function to convert bytes to a hex string
    function hexFromBytes(bytes: Uint8Array): string {
      return Array.from(bytes)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    }

    // Convert string to bytes using TextEncoder for "ISO-8859-1" encoding
    const encoder = new TextEncoder();
    const bytes = encoder.encode(val);

    // Get hex representation
    let ss = hexFromBytes(bytes);

    // Replace "3F3F" with "00" and convert to lowercase
    ss = ss.replace(/3F3F/g, "00").toLowerCase();

    return ss;
  }

  const [hexResult, setHexResult] = useState("");
  const [inputString, setInputString] = useState("");
  // Handle input change
  const handleInputChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setInputString(value);
    setHexResult(convertHex(value)); // Convert to hex when input changes
  };

  console.log("The decryppted ", hexResult);

  const [hexInput, setHexInput] = useState<string>("");
  const [readableOutput, setReadableOutput] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState(0);

  const hexToReadable = (hex: string) => {
    let result = "";

    // Convert hex to binary
    for (let i = 0; i < hex.length; i += 2) {
      const byte = hex.substring(i, i + 2);
      const charCode = parseInt(byte, 16);
      result += String.fromCharCode(charCode);
    }

    return result;
  };

  const handleConvert = () => {
    // const readableText = hexToReadable(hexInput);
    // setReadableOutput(readableText);
    // setReadableOutput
    localStorage.removeItem("barcodeResults");
  };

  useEffect(() => {
    // Retrieve and log the saved barcode results from localStorage
    let savedResults = JSON.parse(
      localStorage.getItem("barcodeResults") || "[]"
    );
    console.log("Fetched results from localStorage:", savedResults);

    // Optional: Remove duplicates if they exist
    savedResults = savedResults.filter(
      (item: { text: any }, index: any, self: any[]) =>
        index === self.findIndex((t: { text: any }) => t.text === item.text)
    );

    setResults(savedResults);
  }, [refreshKey]);

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleCombinedClick = () => {
    showVideoCapture();
    handleRefresh();
  };

  // Utility function to format field names with capitalized words
  function formatFieldName(fieldName: string): string {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <GlobalGrid
      currentPageTitle="MY PROFILE"
      closeCurrentPage={() => onBackPress()}
    >
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          display: "flex",
        }}
      >
        <DesktopContainer>
          <h2
            style={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              marginTop: "40px",
            }}
          >
            Driver License Details
          </h2>
          {!isCameraVisible && (
            <div className="buttons-container">
              {results.length ? (
                <IonGrid
                  className=""
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #000",
                    marginBottom: "20px",
                  }}
                >
                  {results.map((result, index) => (
                    <div key={index} style={{ textAlign: "center" }}>
                      {result.ResultInfo.filter(
                        (item: any) => item.FieldName !== "ChildFields"
                      )
                        .sort((a: any, b: any) => {
                          const fieldNameOrder = [
                            "initials",
                            "surname",
                            "gender",
                            "birthDate",
                            "idNumber",
                            "idIssuedCountry",
                            "driverRestrictionCodes",
                            "idNumberType",
                            "licenseIssueNumber",
                            "licenseIssuedCountry",
                            "licenseNumber",
                            "licenseValidityFrom",
                            "licenseValidityTo",
                            "professionalDrivingPermitCodes",
                            "professionalDrivingPermitExpiryDate",
                          ];
                          const indexA = fieldNameOrder.indexOf(a.FieldName);
                          const indexB = fieldNameOrder.indexOf(b.FieldName);
                          return (
                            (indexA === -1 ? fieldNameOrder.length : indexA) -
                            (indexB === -1 ? fieldNameOrder.length : indexB)
                          );
                        })
                        .map((item: any, index: any) => (
                          <IonRow key={index} className="basic-spec-row">
                            {item.FieldName === "vehicleLicense" ? (
                              <IonCol className="license-details">
                                {(() => {
                                  try {
                                    const vehicleData = JSON.parse(item.Value);
                                    return vehicleData.map(
                                      (field: any, fieldIndex: any) => (
                                        <div
                                          key={fieldIndex}
                                          className="LicenseItems"
                                        >
                                          <IonRow className="ion-justify-content-center">
                                            <IonCol className="label-colu">
                                              <span>License Issued Date</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>
                                                {field.licenseCodeIssuedDate}
                                              </span>
                                              <br />
                                            </IonCol>
                                          </IonRow>
                                          <IonRow>
                                            <IonCol className="label-colu">
                                              <span>Vehicle Code</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>{field.vehicleCode}</span>
                                              <br />
                                            </IonCol>
                                          </IonRow>
                                          <IonRow>
                                            <IonCol className="label-colu">
                                              <span>Vehicle Restriction</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>
                                                {field.vehicleRestriction}
                                              </span>
                                            </IonCol>
                                          </IonRow>
                                        </div>
                                      )
                                    );
                                  } catch (e) {
                                    return (
                                      <div>
                                        Error parsing vehicle license data
                                      </div>
                                    );
                                  }
                                })()}
                              </IonCol>
                            ) : (
                              <IonCol className="center-content">
                                <div className="center-wrapper">
                                  <IonCol size="6" className="label-col">
                                    {formatFieldName(item.FieldName)}
                                  </IonCol>
                                  <IonCol size="6" className="value-col">
                                    {item.Value}
                                  </IonCol>
                                </div>
                              </IonCol>
                            )}
                          </IonRow>
                        ))}
                    </div>
                  ))}
                </IonGrid>
              ) : (
                <>
                  {" "}
                  <p style={{ textAlign: "center", fontSize: "35px" }}>
                    No record of drivers license data
                  </p>
                </>
              )}
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="buttons-container">
            <button
              style={{
                backgroundColor:
                  mode === Modes.VIDEO_CAPTURE ? "grey" : "white",
                fontSize: "45px",
                color: "white",
                paddingTop: "10px",
              }}
              onClick={handleCombinedClick}
            >
              {isCameraVisible ? "Close Camera" : "Scan Driver's License"}
            </button>
          </div>{" "}
          {isCameraVisible && (
            <div className="container">
              {mode === Modes.VIDEO_CAPTURE ? <VideoCapture /> : <></>}
            </div>
          )}
        </DesktopContainer>
      </div>
    </GlobalGrid>
  );
};

export default UserProfile;
