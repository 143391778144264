import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
} from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DesktopContainer from "../../../components/desktop-container";
import DesktopHeader from "../../../components/desktop-header";
import Header from "../../../components/header";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens, Format } from "../../../enums";
import "../../../Global.css";
import { RootState } from "../../../redux/store";
import { debounce } from "lodash";
import {
  hideLoader,
  setCompetitors,
  setCustomReportParams,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from "../../../redux/ui/uiSlice";
import { useHistory } from "react-router-dom";
import { CompetitorData } from "../../../interfaces/competitorData";
import { decVal, format } from "../../../utils";
import { Line } from "react-chartjs-2";
import { TCO, getImage, manualSearch, modelClass } from "../../../api/mapo";
import {
  chevronBack,
  chevronBackCircleSharp,
  chevronBackOutline,
  chevronDown,
  chevronForward,
} from "ionicons/icons";
import moment from "moment";
import DataAssurance from "../../../components/dataAssurance";
import { icons } from "../../../assets";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";
import Whatsapp from "../../../components/whatsapp/whatsapp";

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}

const desktopChartOptions = {
  maintainAspectRatio: true,
  tension: 0.5,
  spanGaps: true,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      display: true,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    x: {
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const DesktopChartOptions = {
  // responsive: true,
  // maintainAspectRatio: true,
  spanGaps: true,
  // aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

interface TCOData {
  result: {
    country: string;
    lifeTime: number;
    serviceCost: number;
    serviceCostPerKm: number;
    maintenanceCost: number;
    maintenanceCostPerKm: number;
    maintenanceAdditionalCost: number;
    maintenanceAdditionalCostPerKm: number;
    tyreCostPerKm: number;
    tyreCost: number;
    fuelCostPerKm: number;
    fuelCost: number;
    residualValue: number;
    residualValuePerKm: number;
    depreciationValue: number;
    depreciationPerKm: number;
    financeCost: number;
    financeCostPerKm: number;
    financeCostBase: number;
    financeCostBasePerKm: number;
    financeCostInterest: number;
    financeCostPerInterestKm: number;
    insuranceCost: number;
    insuranceCostPerKm: number;
  };
}

interface RowData {
  interval: number;
  depreciation: number;
  retailPrice: number;
  backgroundClass?: string;
}

const ResellValue: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const introDate = uiState.selectedVehicle?.vehicleData?.intro_date || "";
  const initialYear = introDate
    ? moment(new Date(introDate)).format("YYYY")
    : moment().format("YYYY");

  // Calculate minimum value based on introDate year
  const minYear = parseInt(initialYear, 10);
  const currentYear = moment().format("YYYY");
  const maxMonths = (parseInt(currentYear, 10) - minYear) * 12 + 1;

  const [AgeMonths, setAgeMonths] = useState(0);
  const [initialised, setInitialised] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [showServiceData, setShowServiceData] = useState(true);
  const [rangeValue, setRangeValue] = useState(25000);

  const [interestRat, setInterestRat] = useState(11);
  const [paymentTer, setPaymentTer] = useState(60);
  const [financeResidual, setFinanceResidual] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [Inflation, setInflationValue] = useState<number>(0);
  const [CurrentKm, setCurrentKm] = useState(0);

  const [TotalDistanceTravelKm, setTravelKm] = useState(0);
  const [years, setYears] = useState<number>(0);
  const [depreciationPercentage, setDepreciationPercentage] = useState(0);
  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  let touchStartX = 0;

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  const totalServiceCosPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM"></p>
    </>
  );

  const convertMonthsToYears = (months: any) => {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return { years, remainingMonths };
  };

  //console.log("The annualMilege",uiState?.customReportParams.annualMileage)

  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
            .length - 1
        ].interval
      : 0
  );
  const [depositAmoun, setDepositAmoun] = useState(0);

  const [kmFromSlider, setKmFromSlider] = useState(0);

  const [range, setRange] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tcoData, setTcoData] = useState<TCOData[]>([]);

  const debouncedEffect = debounce(() => {
    if (!isSliding) {
      // Only perform TCO calculation if not sliding
      const { selectedVehicle } = uiState;
      if (selectedVehicle) {
        // TCO(
        //   selectedVehicle.g_id,
        //   selectedVehicle.m_id,
        //   selectedVehicle.v_id,
        //   kmToSlider,
        //   tyreChangeInterval,
        //   rangeValue,
        //   depositAmoun,
        //   interestRat,
        //   paymentTer,
        //   financeResidual
        // )
        //   .then((response) => {
        //     const tcoDataFromResponse = response.data;
        //     setTcoData([tcoDataFromResponse]);
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
      }
    }
  }, 500);

  // useEffect hook
  useEffect(() => {
    debouncedEffect();

    return () => {
      debouncedEffect.cancel();
    };
  }, [
    uiState.selectedVehicle,
    depositAmoun,
    interestRat,
    paymentTer,
    kmToSlider,
    rangeValue,
    isSliding,
  ]);

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setRange({ lower: 10, upper: 20 });
      }, 10);

      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getIntervals = useCallback(() => {
    const list = [];
    for (let i = 1; i <= 30; i++) {
      const fromValue = i * 10000;
      if (fromValue <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(fromValue);
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getChartIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 30; i++) {
      const toValue = i * 10 + 10;
      if (i * 10000 <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(toValue + "k");
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getTableIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 29; i++) {
      const toValue = (i * 10 + 10) * 1000;
      if (i * 10000 <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(toValue);
      }
    }
    return list;
  }, [uiState.customReportParams]);

  // console.log("The intervals", getTableIntervals());

  const getDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((_, index) => {
        const plans = getData(col)?.depreciation;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] !== 0) data.push(value[index]);
          else data.push(null);
        }
        return "";
      });
      return data;
    },
    [getData, getIntervals]
  );

  const getModelDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((_, index) => {
        const plans = getData(col)?.depreciationModel;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] !== 0) data.push(value[index]);
          else data.push(null);
        }
        return "";
      });
      return data;
    },
    [getData, getIntervals]
  );

  const getGroupDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(col)?.depreciationGroup;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] !== 0) data.push(value[index]);
          else data.push(null);
        }
        return "";
      });
      return data;
    },
    [getData, getIntervals]
  );

  //console.log("The Depreciation Data KM 10 to 300K", getGroupDataset(0));

  const getMonthDataset = useCallback(
    (col: number) => {
      const intervals = [
        "months_4",
        "months_8",
        "months_12",
        "months_16",
        "months_20",
        "months_24",
        "months_28",
        "months_32",
        "months_36",
        "months_40",
        "months_44",
        "months_48",
        "months_52",
        "months_56",
        "months_60",
        "months_64",
        "months_68",
        "months_72",
        "months_76",
        "months_80",
        "months_84",
        "months_88",
        "months_92",
        "months_96",
        "months_100",
        "months_104",
        "months_108",
        "months_112",
        "months_116",
        "months_120",
        "months_124",
        "months_128",
        "months_132",
        "months_136",
        "months_140",
        "months_144",
        "months_148",
        "months_152",
        "months_156",
        "months_160",
      ];

      const plans = getData(col)?.depreciation;
      const data = intervals.map((interval) => plans?.[interval] || null);
      return data;
    },
    [getData]
  );

  const getMonthModelDataset = useCallback(
    (col: number) => {
      const intervals = [
        "months_4",
        "months_8",
        "months_12",
        "months_16",
        "months_20",
        "months_24",
        "months_28",
        "months_32",
        "months_36",
        "months_40",
        "months_44",
        "months_48",
        "months_52",
        "months_56",
        "months_60",
        "months_64",
        "months_68",
        "months_72",
        "months_76",
        "months_80",
        "months_84",
        "months_88",
        "months_92",
        "months_96",
        "months_100",
        "months_104",
        "months_108",
        "months_112",
        "months_116",
        "months_120",
        "months_124",
        "months_128",
        "months_132",
        "months_136",
        "months_140",
        "months_144",
        "months_148",
        "months_152",
        "months_156",
        "months_160",
      ];

      const plans = getData(col)?.depreciationModel;
      const data = intervals.map((interval) => plans?.[interval] || null);
      return data;
    },
    [getData]
  );
  const getMonthGroupDataset = useCallback(
    (col: number) => {
      const intervals = [
        "months_4",
        "months_8",
        "months_12",
        "months_16",
        "months_20",
        "months_24",
        "months_28",
        "months_32",
        "months_36",
        "months_40",
        "months_44",
        "months_48",
        "months_52",
        "months_56",
        "months_60",
        "months_64",
        "months_68",
        "months_72",
        "months_76",
        "months_80",
        "months_84",
        "months_88",
        "months_92",
        "months_96",
        "months_100",
        "months_104",
        "months_108",
        "months_112",
        "months_116",
        "months_120",
        "months_124",
        "months_128",
        "months_132",
        "months_136",
        "months_140",
        "months_144",
        "months_148",
        "months_152",
        "months_156",
        "months_160",
      ];

      const plans = getData(col)?.depreciationGroup;
      const data = intervals.map((interval) => plans?.[interval] || null);
      return data;
    },
    [getData]
  );

  //console.log("The Depreciation Data for Months 3 to 120", getMonthDataset(0));

  const getDesktopChartData = useCallback(() => {
    const labelsWithRadius: { [key: string]: number } = {};
    const labels: string[] = [];
    let foundCurrentKm = false;

    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
      (v, i) => {
        if (
          v.attributes["interval"] >= kmFromSlider &&
          v.attributes["interval"] <= kmToSlider
        ) {
          labels.push(v.attributes["interval"]);
          const interval = v.attributes["interval"];
          const threshold = 10000;

          if (Math.abs(interval - CurrentKm) <= threshold) {
            labelsWithRadius[interval] = 25;
            if (interval === CurrentKm && !foundCurrentKm) {
              foundCurrentKm = true;
            }
          } else {
            labelsWithRadius[interval] = 5;
          }
        }
      }
    );

    const desktopChartData = {
      labels,
      datasets: [
        {
          label: "VARIANT",
          data: getDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: labels.map((label) => labelsWithRadius[label]),
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [
    getDataset,
    getData,
    getChartIntervals,
    kmToSlider,
    kmFromSlider,
    CurrentKm,
  ]);

  const getDesktopChartDataModels = useCallback(() => {
    const labelsWithRadius: { [key: string]: number } = {};
    const labels: string[] = [];
    let foundCurrentKm = false;

    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
      (v, i) => {
        if (
          v.attributes["interval"] >= kmFromSlider &&
          v.attributes["interval"] <= kmToSlider
        ) {
          labels.push(v.attributes["interval"]);
          const interval = v.attributes["interval"];
          const threshold = 10000;

          if (Math.abs(interval - CurrentKm) <= threshold) {
            labelsWithRadius[interval] = 25;
            if (interval === CurrentKm && !foundCurrentKm) {
              foundCurrentKm = true;
            }
          } else {
            labelsWithRadius[interval] = 5;
          }
        }
      }
    );

    const desktopChartData = {
      labels,
      datasets: [
        {
          label: "MODEL",

          data: getModelDataset(0),
          borderColor: "#fc8f00",
          backgroundColor: "#fc8f00",
          borderWidth: 5,
          elements: {
            point: {
              radius: labels.map((label) => labelsWithRadius[label]),
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [
    getDataset,
    getData,
    getChartIntervals,
    kmToSlider,
    kmFromSlider,
    CurrentKm,
  ]);

  const getDesktopChartDataGroup = useCallback(() => {
    const labelsWithRadius: { [key: string]: number } = {};
    const labels: string[] = [];
    let foundCurrentKm = false;

    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
      (v, i) => {
        if (
          v.attributes["interval"] >= kmFromSlider &&
          v.attributes["interval"] <= kmToSlider
        ) {
          labels.push(v.attributes["interval"]);
          const interval = v.attributes["interval"];
          const threshold = 10000;

          if (Math.abs(interval - CurrentKm) <= threshold) {
            labelsWithRadius[interval] = 25;
            if (interval === CurrentKm && !foundCurrentKm) {
              foundCurrentKm = true;
            }
          } else {
            labelsWithRadius[interval] = 5;
          }
        }
      }
    );
    const desktopChartData = {
      labels,
      datasets: [
        {
          label: "GROUP",

          data: getGroupDataset(0),
          borderColor: "rgba(255, 0, 0, 0.6)",
          backgroundColor: "rgba(255, 0, 0, 0.6)",
          borderWidth: 5,
          elements: {
            point: {
              radius: labels.map((label) => labelsWithRadius[label]),
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [
    getDataset,
    getData,
    getChartIntervals,
    kmToSlider,
    kmFromSlider,
    CurrentKm,
  ]);

  const getDesktopModelChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getModelDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [getData, getChartIntervals, getModelDataset]);

  const getDesktopGroupChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label: getData(0)?.modelSpecification.attributes.make,
          data: getGroupDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [getData, getChartIntervals, getGroupDataset]);

  const reloadData = useCallback(() => {
    dispatch(showLoader());
    TCO(
      uiState.selectedVehicle?.g_id ?? 0,
      uiState.selectedVehicle?.m_id ?? 0,
      uiState.selectedVehicle?.v_id ?? 0,
      uiState.customReportParams.totalLifeSpan ?? 0,
      uiState.customReportParams.tyreChangeInterval ?? 0,
      uiState.customReportParams.annualMileage ?? 0,
      uiState.customReportParams.deposit ?? 0,
      uiState.customReportParams.interest ?? 0,
      uiState.customReportParams.term ?? 0,
      uiState.customReportParams.residual ?? 0
    )
      .then((tcoResponse) => {
        dispatch(hideLoader());
        if (tcoResponse.status === 200) {
          const newSelectedVehicle: any = {
            ...uiState.selectedVehicle,
            data: {
              ...uiState.selectedVehicle?.data,
              tcoData: tcoResponse.data,
            },
          };
          dispatch(setSelectedVehicle(newSelectedVehicle));
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [uiState, dispatch]);

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
    }
  }, [initialised]);

  const onBackPress = useCallback(() => {
    history.push("/OverViewDetail");
  }, [dispatch]);

  const handleInflationChange = (e: any) => {
    const Value = e.detail.value;
    setInflationValue(Value);
  };

  const [annualMileage, setAnnualMileage] = useState(25000);

  useEffect(() => {
    // Reset the annualMileage to 25000 when the page is closed
    return () => {
      setAnnualMileage(25000);
    };
  }, []);

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team.\n\n I have a question on the RESIDUAL VALUE screen of the\n\n  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} \n\n ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      } \n\n  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} \n\n*My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );
  const ChartInitialized = useMediaQuery({ minWidth: 1200 });
  const basePrice =
    (Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    ) *
      15) /
      100 +
    Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    );

  const depreciationVariantData = getDataset(0);
  const monthDepreciation = getMonthDataset(0);
  const ModelMonthDepreciation = getMonthModelDataset(0);
  const GroupMonthDepreciation = getMonthGroupDataset(0);
  const depreciationModelData = getModelDataset(0);
  const depreciationGroup = getGroupDataset(0);

  const totalDepreciation = monthDepreciation.map((monthDep, index) => {
    const kmDep = depreciationVariantData[index];

    const monthValue = monthDep !== null ? monthDep : 0;
    const kmValue = kmDep !== null ? kmDep : 0;

    // return monthValue + kmValue;
    return kmValue;
  });
  const totalDepr = monthDepreciation.map((monthDep, index) => {
    const kmDep = depreciationVariantData[index];
    const monthValue = monthDep !== null ? monthDep : 0;
    const kmValue = kmDep !== null ? kmDep : 0;
    return kmValue;
    // return kmValue;
  });

  const totalMonthDepreciation = monthDepreciation.map((monthDep, index) => {
    const kmDep = depreciationVariantData[index];
    const monthValue = monthDep !== null ? monthDep : 0;
    const kmValue = kmDep !== null ? kmDep : 0;
    // return monthValue + kmValue;
    //console.log("The variant months", monthValue);
    return monthValue;
  });

  const totalModelDepreciation = ModelMonthDepreciation.map(
    (monthDep, index) => {
      const kmDep = depreciationModelData[index];
      const monthValue = monthDep !== null ? monthDep : 0;
      const kmValue = kmDep !== null ? kmDep : 0;
      //return monthValue + kmValue;
      return kmValue;
    }
  );

  const totalModelDepr = ModelMonthDepreciation.map((monthDep, index) => {
    const kmDep = depreciationModelData[index];
    const monthValue = monthDep !== null ? monthDep : 0;
    const kmValue = kmDep !== null ? kmDep : 0;
    return kmValue;
    //return kmValue;
  });

  const totalModelMonthDepreciation = ModelMonthDepreciation.map(
    (monthDep, index) => {
      const kmDep = depreciationModelData[index];
      const monthValue = monthDep !== null ? monthDep : 0;
      const kmValue = kmDep !== null ? kmDep : 0;
      //return monthValue + kmValue;
      return monthValue;
    }
  );

  const totalGroupDepreciation = GroupMonthDepreciation.map(
    (monthDep, index) => {
      const kmDep = depreciationGroup[index];
      const monthValue = monthDep !== null ? monthDep : 0;
      const kmValue = kmDep !== null ? kmDep : 0;
      //return monthValue + kmValue;
      return kmValue;
    }
  );

  const totalGroupDepr = GroupMonthDepreciation.map((monthDep, index) => {
    const kmDep = depreciationGroup[index];
    const monthValue = monthDep !== null ? monthDep : 0;
    const kmValue = kmDep !== null ? kmDep : 0;
    return kmValue;
    //return kmValue;
  });

  const totalGroupMonthDepreciation = GroupMonthDepreciation.map(
    (monthDep, index) => {
      const kmDep = depreciationGroup[index];
      const monthValue = monthDep !== null ? monthDep : 0;
      const kmValue = kmDep !== null ? kmDep : 0;
      // return monthValue + kmValue;
      //console.log("The month", monthValue);
      return monthValue;
    }
  );

  const calculateRetail = (
    basePrice: number,
    depreciationPercent: number
  ): string => {
    const retail = basePrice - (basePrice * (depreciationPercent / 2)) / 100;
    return isNaN(retail) ? "0.00" : retail.toFixed(2);
  };

  const DepreciationValue = (
    basePrice: number,
    depreciationPercent: number
  ): { retail: string; amountReduced: string } => {
    const amountReduced = (basePrice * depreciationPercent) / 100;
    const retail = basePrice - amountReduced;

    return {
      retail: isNaN(retail) ? "0.00" : retail.toFixed(2),
      amountReduced: isNaN(amountReduced) ? "0.00" : amountReduced.toFixed(2),
    };
  };
  const GetDepreciationTable = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label: getData(0)?.modelSpecification.attributes.make,
          data: getGroupDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };
    return desktopChartData;
  }, [getData, getChartIntervals, getGroupDataset]);

  const servicePlanMonths =
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan_months;
  const ServicePlanKm =
    Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    ) || 0;

  // If ServicePlanKm is 999999, treat it as zero
  const adjustedServicePlanKm = ServicePlanKm === 999999 ? 0 : ServicePlanKm;
  const servicePlanYears = Number(servicePlanMonths) / 12;

  const calculateDepreciationPercentage = (): number => {
    if (CurrentKm === 0) {
      return 0;
    }

    const index = (CurrentKm - 10000) / 10000;

    // console.log("The index of the numbers");
    let depreciationValue = 0;

    // Check totalDepreciation first
    if (index >= 0 && index < depreciationVariantData.length) {
      const totalDepValue = depreciationVariantData[Math.floor(index)];
      if (typeof totalDepValue === "number" && totalDepValue > 0) {
        depreciationValue = totalDepValue;
      }
    }

    // If no valid depreciation found, check totalModelDepreciation
    if (
      depreciationValue === 0 &&
      index >= 0 &&
      index < depreciationModelData.length
    ) {
      const modelDepValue = depreciationModelData[Math.floor(index)];
      if (typeof modelDepValue === "number" && modelDepValue > 0) {
        depreciationValue = modelDepValue;
      }
    }

    // If still no valid depreciation found, check totalGroupDepreciation
    if (
      depreciationValue === 0 &&
      index >= 0 &&
      index < depreciationGroup.length
    ) {
      const groupDepValue = depreciationGroup[Math.floor(index)];
      if (typeof groupDepValue === "number" && groupDepValue > 0) {
        depreciationValue = groupDepValue;
      }
    }

    return depreciationValue;
  };

  const calculateMonthDepreciationPercentage = (): number => {
    if (CurrentKm === 0) {
      return 0;
    }

    const index = Math.floor(AgeMonths / 4) - 1; // Adjusted to ensure integer index

    let depreciationValue = 0;

    // Check totalDepreciation first
    if (index >= 0 && index < totalMonthDepreciation.length) {
      const totalDepValue = totalMonthDepreciation[index];
      if (typeof totalDepValue === "number" && totalDepValue > 0) {
        depreciationValue = totalDepValue;
      }
    }

    // If no valid depreciation found, check totalModelDepreciation
    if (
      depreciationValue === 0 &&
      index >= 0 &&
      index < totalModelMonthDepreciation.length
    ) {
      const modelDepValue = totalModelMonthDepreciation[index];
      if (typeof modelDepValue === "number" && modelDepValue > 0) {
        depreciationValue = modelDepValue;
      }
    }

    // If still no valid depreciation found, check totalGroupDepreciation
    if (
      depreciationValue === 0 &&
      index >= 0 &&
      index < totalGroupMonthDepreciation.length
    ) {
      const groupDepValue = totalGroupMonthDepreciation[index];
      if (typeof groupDepValue === "number" && groupDepValue > 0) {
        depreciationValue = groupDepValue;
      }
    }

    //console.log("The depreciation",depreciationValue)

    return depreciationValue;
  };

  const [DepreAvarage, setDepreAvarage] = useState();

  const calculateAverageDepreciationPercentage = () => {
    const depreciationPercentage = calculateDepreciationPercentage();
    const monthDepreciationPercentage = calculateMonthDepreciationPercentage();

    // Calculate the average of the two percentages
    const averageDepreciation =
      (depreciationPercentage + monthDepreciationPercentage) / 2;

    return averageDepreciation;
  };

  const [calcDuration, setCalcDuration] = useState(0);

  useEffect(() => {
    setCalcDuration(Number(kmToSlider - kmFromSlider));
  }, [kmFromSlider, kmToSlider]);

  const initialLoads = useRef(true);

  // const handleModelAgeChange = (age: any) => {
  //   setAgeMonths(age);
  //   const DistanceTime = AgeMonths > 0 ? AgeMonths / 12 : 1;
  //   const totalTravel = CurrentKm / DistanceTime;
  //   //setRangeValue(totalTravel);
  // };
  const handleModelAgeChange = (age: any) => {
    setAgeMonths(age);
  };

  const handleCurrentMileageChange = (mileage: any) => {
    const age = (mileage / rangeValue) * 12;
    setCurrentKm(mileage);
    //setAgeMonths(age);
  };

  const handleTravelPerYearChange = (mileage: any) => {
    setRangeValue(mileage);
  };

  const roundToNearestThousand = (value: any) => {
    return Math.round(value / 1000) * 1000;
  };

  useEffect(() => {
    const calculatedMonths = Number((CurrentKm / rangeValue) * 12);

    //setAgeMonths(calculatedMonths);
    // console.log("The totla km", calculatedMonths);
  }, [calcDuration, rangeValue]);

  const RetailpriceVAT =
    (Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    ) *
      15) /
      100 +
    Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    );
  // console.log("The avarage percentage", RetailpriceVAT);
  const calculateAverageDepreciPercentage = () => {
    const depreciationPercentage = calculateDepreciationPercentage();
    const monthDepreciationPercentage = calculateMonthDepreciationPercentage();

    // Calculate the average of the two percentages
    const averageDepreciation =
      depreciationPercentage + monthDepreciationPercentage / 2;

    return averageDepreciation;
  };
  const DepreciationV =
    (RetailpriceVAT * calculateAverageDepreciationPercentage()) / 100;

  const RetailPrice =
    (Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    ) *
      15) /
      100 +
    Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    );

  let lastRetailPrice: string | null = calculateRetail(
    basePrice,
    totalDepreciation[0]
  );

  //For The Depreciation Table
  const rows: (RowData | React.ReactNode)[] = [
    { interval: 0, depreciation: 0, retailPrice: basePrice },
    ...getIntervals().map((interval, index) => {
      let currentDepreciation = 0;
      let backgroundClass = "";
      let showDepreciation = false;

      // Check totalDepreciation first
      if (index < totalDepreciation.length) {
        const totalDepValue = totalDepreciation[index];
        const MonthIndex = Math.floor(AgeMonths / 4) - 1;
        const monthDepreciation = totalMonthDepreciation[MonthIndex];

        if (totalDepValue > 0) {
          if (typeof monthDepreciation === "undefined") {
            currentDepreciation = totalDepValue;
          } else {
            // Otherwise, add groupDepValue and monthDepreciation
            currentDepreciation = totalDepValue + Number(monthDepreciation);
          }
          // currentDepreciation = totalDepValue + Number(monthDepreciation);
          showDepreciation = true;
          backgroundClass = "";
        } else if (
          totalDepValue === 0 &&
          totalDepreciation.some((dep) => dep > 0)
        ) {
          currentDepreciation = 0;
        }
      }

      // If no valid depreciation found, check totalModelDepreciation
      if (!showDepreciation && index < totalModelDepreciation.length) {
        const modelDepValue = totalModelDepreciation[index];
        const MonthIndex = Math.floor(AgeMonths / 4) - 1;
        const monthDepreciation = totalModelMonthDepreciation[MonthIndex];

        if (modelDepValue > 0) {
          if (typeof monthDepreciation === "undefined") {
            currentDepreciation = modelDepValue;
          } else {
            // Otherwise, add groupDepValue and monthDepreciation
            currentDepreciation = modelDepValue + Number(monthDepreciation);
          }
          // currentDepreciation = modelDepValue + Number(monthDepreciation);
          showDepreciation = true;
          backgroundClass = "bg-dark-grey";
        } else if (
          modelDepValue === 0 &&
          totalModelDepreciation.some((dep) => dep > 0) &&
          backgroundClass == "bg-light-grey"
        ) {
          currentDepreciation = 0;
          backgroundClass = "bg-light-grey";
        }
      }

      if (!showDepreciation && index < totalGroupDepreciation.length) {
        const groupDepValue = totalGroupDepreciation[index];
        const MonthIndex = Math.floor(AgeMonths / 4) - 1;
        const monthDepreciation = totalGroupMonthDepreciation[MonthIndex];

        if (groupDepValue > 0) {
          if (typeof monthDepreciation === "undefined") {
            currentDepreciation = groupDepValue;
          } else {
            // Otherwise, add groupDepValue and monthDepreciation
            currentDepreciation = groupDepValue + Number(monthDepreciation);
          }
          // currentDepreciation = groupDepValue + Number(monthDepreciation);
          showDepreciation = true;
          // console.log("The depreciation", groupDepValue);

          backgroundClass = "bg-dark-grey";
        } else if (
          groupDepValue === 0 &&
          totalGroupDepreciation.some((dep) => dep > 0)
        ) {
          currentDepreciation = 0;
          backgroundClass = "bg-dark-grey";
        }
      }

      const currentRetailPrice = calculateRetail(
        basePrice,
        currentDepreciation
      );

      lastRetailPrice =
        currentDepreciation === 0 ? lastRetailPrice : currentRetailPrice;

      return {
        interval,
        depreciation: currentDepreciation,
        retailPrice: lastRetailPrice,
        backgroundClass,
      };
    }),
  ];
  const currentDate = new Date();
  const ageMonths = AgeMonths.toFixed();
  const ageYears = (AgeMonths / 12).toFixed();

  //console.log("Range Value" + rangeValue);
  // console.log("current" + CurrentKm);

  const [calculatedPerYearVal, setCalculatedPerYearVal] = useState(0);

  useEffect(() => {
    setCalculatedPerYearVal(CurrentKm / Number(ageYears));
  }, [calculatedPerYearVal, CurrentKm, ageYears, rangeValue]);

  const targetDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - Number(ageMonths),
    currentDate.getDate()
  );

  // Format the target date as YYYY-MM-DD
  const formattedDate = `${getMonthName(
    targetDate.getMonth()
  )} ${targetDate.getFullYear()}`;

  function getMonthName(monthIndex: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  }
  const formatAge = (ageMonths: any) => {
    // Check if ageMonths is not a number or NaN
    if (isNaN(Number(ageMonths))) {
      return "0";
    }

    // Calculate years and remaining months
    const years = Math.floor(ageMonths / 12);
    const months = ageMonths % 12;

    // Construct the formatted string based on years and months
    if (years === 0) {
      return `${months} month${months !== 1 ? "s" : ""}`;
    } else if (months === 0) {
      return `${years} yr${years !== 1 ? "s" : ""}`;
    } else {
      return `${years} yr${years !== 1 ? "s" : ""} ${months} month${
        months !== 1 ? "s" : ""
      }`;
    }
  };

  const intervalStep = 10000;
  const [priceDifference, setPriceDifference] = useState<number | null>(null);

  useEffect(() => {
    let fromPrice: number | null = null;
    let toPrice: number | null = null;

    rows.forEach((row) => {
      if (row && typeof row === "object" && "interval" in row) {
        const nextInterval =
          Math.ceil(kmFromSlider / intervalStep) * intervalStep;
        const prevInterval =
          Math.ceil(kmToSlider / intervalStep) * intervalStep;

        if (row.interval === nextInterval) {
          fromPrice = row.retailPrice;
        }

        if (row.interval === prevInterval) {
          toPrice = row.retailPrice;
        }
      }
    });

    if (fromPrice !== null && toPrice !== null) {
      const total = toPrice - fromPrice;
      setPriceDifference(Number(total));
    } else {
      setPriceDifference(null);
    }
  }, [rows, kmFromSlider, kmToSlider]);

  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  return (
    <>
      <GlobalGrid
        currentPageTitle="RESIDUAL VALUE"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <div className="headHolder HeaderStick">
            <IonRow id="selectedVehicleGlobal">
              {uiState.selectedVehicle ? (
                <>
                  <IonCol
                    sizeXs="6"
                    sizeMd="4"
                    sizeLg="3"
                    id="gridOverviewImageCont"
                  >
                    <img
                      id="gridOverviewImage"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />{" "}
                  </IonCol>

                  <IonCol
                    sizeXs="6"
                    sizeMd="8"
                    sizeLg="9"
                    id="gridOverviewDetails"
                  >
                    <h1>{uiState.selectedVehicle.make}</h1>
                    <h1>{uiState.selectedVehicle.model}</h1>
                    <h1 id="gridOverviewVariant">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <>{details.variant}</>
                        ))}
                    </h1>
                    <span id="overViewCluster">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <h1 key={index}>
                            MY{" "}
                            {moment(new Date(details.introDate ?? "")).format(
                              "YYYY"
                            )}
                          </h1>
                        ))}
                      <h1>
                        G{uiState.selectedVehicle.g_id.toString()}M
                        {uiState.selectedVehicle.m_id.toString()}V
                        {uiState.selectedVehicle.v_id.toString()}
                      </h1>
                      <h1>{uiState.selectedVehicle.variant_id}</h1>{" "}
                    </span>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </div>

          <div className="genericHolder">
            <IonRow className="planDropDown">
              <span className="dropDownIcon">
                <IonIcon
                  icon={showPlan == false ? chevronForward : chevronDown}
                  onClick={() => {
                    setShowPlan(!showPlan);
                    handleButtonClick();
                  }}
                ></IonIcon>
              </span>
              <span className="textDropDown">LIFESPAN SETTINGS</span>
            </IonRow>
            {showPlan && (
              <>
                <section className="sliderContainer">
                  <span id="sliderContainerACtual">
                    <div className="digi">
                      <span>
                        FROM:
                        <h1>{kmFromSlider}</h1>
                      </span>
                      <span id="digiRight">
                        TO:
                        <h1>{kmToSlider}</h1>
                      </span>
                    </div>
                    <IonRange
                      id="slider"
                      aria-label="Dual Knobs Range"
                      dualKnobs={true}
                      min={0}
                      max={
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans &&
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans?.length > 0
                          ? uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans[
                              uiState.selectedVehicle?.data?.servicePlanTotals
                                ?.result?.servicePlans.length - 1
                            ].interval
                          : 20000
                      }
                      step={1000}
                      onIonChange={(ev: any) => {
                        const lowerValue = isNaN(ev.detail.value.lower)
                          ? 0
                          : ev.detail.value.lower;
                        const upperValue = isNaN(ev.detail.value.upper)
                          ? 0
                          : ev.detail.value.upper;
                        setKmToSlider(upperValue);
                        setKmFromSlider(lowerValue);
                      }}
                      onPointerUp={() => setIsSliding(false)}
                      value={{
                        upper: kmToSlider,
                        lower: kmFromSlider,
                      }}
                    ></IonRange>

                    {/* <IonRow className="sliderStateDiv">
                      <div className="from">
                        <span id="fromOne">TRAVELING</span>
                        <span id="fromTwo">
                          {" "}
                          {roundToNearestThousand(CurrentKm / Number(ageYears))} / YR{" "}
                        </span>
                      </div>
                      <div className="from">
                        <IonRange
                          step={500}
                          min={2500}
                          max={300000}
                          value={CurrentKm}
                          onIonChange={(e) =>
                            // handleTravelPerYearChange(Number(e.target.value))
                            handleCurrentMileageChange(Number(e.target.value))
                          }
                        />
                      </div>
                    </IonRow> */}

                    <div className="digi">
                      <span>CURRENT MILEAGE</span>
                      <span id="digiRight">
                        <h1>{roundToNearestThousand(CurrentKm)}</h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      step={10000}
                      min={10}
                      max={
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans &&
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans?.length > 0
                          ? uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans[
                              uiState.selectedVehicle?.data?.servicePlanTotals
                                ?.result?.servicePlans.length - 1
                            ].interval
                          : 20000
                      }
                      value={CurrentKm}
                      onIonChange={(e) =>
                        handleCurrentMileageChange(Number(e.target.value))
                      }
                    ></IonRange>

                    <div className="digi">
                      <span>MODEL AGE IN MONTHS</span>
                      <span id="digiRight">
                        <h1>
                          {isNaN(Number(ageMonths)) ? "0" : `${ageMonths}mnt`}{" "}
                          {formattedDate}
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      step={4}
                      min={0}
                      max={244}
                      value={AgeMonths}
                      onIonChange={(e) =>
                        handleModelAgeChange(Number(e.target.value))
                      }
                    ></IonRange>

                    {/* <div>
                      <IonCol>
                        {CurrentKm > 0 && (
                          <div className="BalloonPayPop">
                            Model reg on{" "}
                            <span className="BallonAmoutColour">
                              {formattedDate}
                            </span>
                            , and now it is{" "}
                            <span className="BallonAmoutColour">
                              {isNaN(Number(ageMonths))
                                ? "0"
                                : formatAge(ageMonths)}
                            </span>{" "}
                            old
                          </div>
                        )}
                      </IonCol>
                    </div> */}
                  </span>
                </section>
              </>
            )}

            <IonRow className="hiddenPlanDetails">
              <div></div>
            </IonRow>
          </div>
          <IonGrid>
            <IonRow
              style={{ marginTop: "2em" }}
              className="residual-scrollable"
            >
              {" "}
              <IonGrid fixed>
                <IonRow>
                  <IonCol
                    size="12"
                    sizeMd="4"
                    sizeLg="4"
                    style={{
                      // backgroundColor: "#f0f0f0",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "10vh" }} className="FontMobileSpcs">
                      DEPRECIATION ON MILEAGE <br /> -
                      {format(
                        calculateDepreciationPercentage(),
                        Format.Currency
                      )}
                      %
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    sizeMd="4"
                    sizeLg="4"
                    style={{
                      // backgroundColor: "#d0d0d0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "10vh",
                        textAlign: "center",
                        color: "red",
                        fontSize: "40px",
                      }}
                      className="FontMobileSpcs"
                    >
                      <span style={{ fontWeight: "100" }}>AVARAGE</span> <br />
                      <b
                        style={{
                          display: "block",
                          width: "100%",
                          fontSize: "55px",
                          fontWeight: "600",
                        }}
                      >
                        -
                        {format(
                          calculateAverageDepreciationPercentage(),
                          Format.Currency
                        )}
                        %
                      </b>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    sizeMd="4"
                    sizeLg="4"
                    style={{
                      // backgroundColor: "#b0b0b0",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      textAlign: "end",
                      fontSize: "20px",
                    }}
                  >
                    <div style={{ height: "10vh" }} className="FontMobileSpcs">
                      DEPRECIATION ON AGE <br /> -
                      {format(
                        calculateMonthDepreciationPercentage(),
                        Format.Currency
                      )}
                      %
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonCol size="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="8">
                <div className="scrollables">
                  <>
                    <IonRow>
                      <IonCol
                        size="12"
                        sizeXl="2"
                        className="detail rightAlign"
                      >
                        <strong className="FontMobileSpcs">
                          ESTIMATED CURRENT RETAIL VALUE
                        </strong>
                      </IonCol>
                    </IonRow>
                    <div style={{ marginLeft: "0em" }}>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          RETAIL PRICE(NEW)
                        </IonCol>

                        <IonCol
                          size="3.1"
                          sizeXl="2"
                          className="residual-details"
                        >
                          {format(
                            (Number(
                              uiState.selectedVehicle?.data?.modelSpecification
                                ?.result.basic_specs.base_price
                            ) *
                              15) /
                              100 +
                              Number(
                                uiState.selectedVehicle?.data
                                  ?.modelSpecification?.result.basic_specs
                                  .base_price
                              ),
                            Format.Currency
                          )}{" "}
                        </IonCol>

                        <IonCol
                          size="2.9"
                          sizeXl="2"
                          className="residual-details"
                        >
                          <span style={{ margin: "auto" }}>VAT INCL</span>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          DEPRECIATION
                        </IonCol>
                        <IonCol
                          size="3.1"
                          sizeXl="2"
                          className="residual-details"
                        >
                          -{format(DepreciationV, Format.Currency)}
                          {/* <span style={{ margin: "auto" }}>
                            -
                            {format(
                              calculateAverageDepreciationPercentage(),
                              Format.Currency
                            )}
                            %
                          </span> */}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          RESIDUAL VALUE
                        </IonCol>
                        <IonCol
                          size="3.1"
                          sizeXl="2"
                          className="residual-details"
                        >
                          {format(RetailPrice - DepreciationV, Format.Currency)}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          DEPRECIATION VALUE /KM
                        </IonCol>
                        <IonCol
                          size="3.1"
                          sizeXl="2"
                          className="residual-details"
                        >
                          -
                          {format(
                            isNaN(DepreciationV / CurrentKm)
                              ? "0"
                              : DepreciationV / CurrentKm,
                            Format.Currency
                          )}
                          /KM
                        </IonCol>
                      </IonRow>
                    </div>
                  </>

                  {/* <section className="Residual-content">
                    {initialised && (
                      <>
                        {ChartInitialized ? (
                          <>
                            <div className="chart-row">
                              {" "}
                              <div className="chart-container">
                                <Line
                                  options={DesktopChartOptions}
                                  data={getDesktopChartData()}
                                />
                              </div>
                              <div className="chart-container">
                                <Line
                                  options={DesktopChartOptions}
                                  data={getDesktopChartDataModels()}
                                />
                              </div>
                              <div className="chart-container">
                                <Line
                                  options={DesktopChartOptions}
                                  data={getDesktopChartDataGroup()}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <Line
                              options={desktopChartOptions}
                              data={getDesktopChartData()}
                            />

                            <Line
                              options={desktopChartOptions}
                              data={getDesktopChartDataModels()}
                            />

                            <Line
                              options={desktopChartOptions}
                              data={getDesktopChartDataGroup()}
                            />
                          </>
                        )}
                      </>
                    )}
                  </section>  */}
                </div>
              </IonCol>
              <br />
              <IonCol
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="12"
                sizeXl="8"
                style={{ marginTop: "20px" }}
              >
                <div className="scrollables">
                  <>
                    <IonRow>
                      <IonCol
                        size="12"
                        sizeXl="2"
                        className="detail rightAlign"
                      >
                        <strong className="FontMobileSpcs">
                          ESTIMATED DEPRECIATION IN SET LIFESPAN
                        </strong>
                      </IonCol>
                    </IonRow>
                    <div style={{ marginLeft: "0em" }}>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          ESTIMATED VALUE AT{" "}
                          <strong style={{ marginLeft: "5px" }}>
                            {kmFromSlider}
                          </strong>
                        </IonCol>
                        {rows.map((row, index) => {
                          if (
                            row &&
                            typeof row === "object" &&
                            "interval" in row
                          ) {
                            const intervalStep = 10000;

                            const nextInterval =
                              Math.ceil(kmFromSlider / intervalStep) *
                              intervalStep;

                            if (row.interval === nextInterval) {
                              return (
                                <IonCol
                                  size="3.1"
                                  sizeXl="2"
                                  className={`residual-details`}
                                >
                                  {format(row.retailPrice, Format.Currency)}
                                </IonCol>
                              );
                            }
                          }
                        })}
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          ESTIMATED VALUE AT{" "}
                          <strong style={{ marginLeft: "5px" }}>
                            {" "}
                            {kmToSlider}
                          </strong>
                        </IonCol>

                        {rows.map((row, index) => {
                          if (
                            row &&
                            typeof row === "object" &&
                            "interval" in row
                          ) {
                            const intervalStep = 10000;

                            const prevInterval =
                              Math.ceil(kmToSlider / intervalStep) *
                              intervalStep;

                            if (row.interval === prevInterval) {
                              return (
                                <IonCol
                                  size="3.1"
                                  sizeXl="2"
                                  className={`residual-details`}
                                >
                                  {format(row.retailPrice, Format.Currency)}
                                </IonCol>
                              );
                            }
                          }
                        })}
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="6"
                          sizeXl="2"
                          className="detail rightAlign"
                        >
                          ESTIMATED DEPRECIATION
                        </IonCol>

                        {priceDifference !== null && (
                          <IonCol
                            size="3.1"
                            sizeXl="2"
                            className={`residual-details`}
                          >
                            {`${format(priceDifference, Format.Currency)}`}
                          </IonCol>
                        )}
                      </IonRow>
                    </div>
                  </>
                </div>
              </IonCol>
              <IonGrid fixed>
                <br />
                {/* <IonRow className="color-legend">
                  <IonCol size="4" className="FontResidual">
                    {" "}
                    <div className="color-box bg-dark-grey"></div>
                    Group Depreciation
                  </IonCol>
                  <IonCol size="4" className="FontResidual">
                    <div className="color-box bg-light-grey"></div>
                    Model Depreciation
                  </IonCol>

                  <IonCol size="4" className="FontResidual">
                    <div className="color-box border "></div>
                    Variant Depreciation
                  </IonCol>
                </IonRow> */}

                <IonRow className="header-row">
                  <IonCol size="12" className="header-col FontMobileSpcs">
                    Estimated Retail Value
                  </IonCol>
                </IonRow>

                {/* Column Titles */}
                <IonRow className="title-row">
                  <IonCol className="title-col FontMobileSpcs">Interval</IonCol>
                  <IonCol className="title-col FontMobileSpcs">
                    Depreciation
                  </IonCol>
                  <IonCol className="title-col FontMobileSpcs">Retail</IonCol>
                </IonRow>

                {/* Data Rows */}

                {rows.map((row, index) => {
                  if (row && typeof row === "object" && "interval" in row) {
                    const isCloseToInterval =
                      Math.abs(CurrentKm - row.interval) <= 3000;

                    // Ensure interval is available in the row object
                    if (
                      row.interval >= kmFromSlider &&
                      row.interval <= kmToSlider
                    ) {
                      return (
                        <IonRow
                          key={index}
                          className={`data-row ${
                            row.interval === CurrentKm || isCloseToInterval
                              ? "red-bottom-border"
                              : ""
                          }`}
                        >
                          <IonCol
                            className={`residualGrid data-col FontMobileService ${row.backgroundClass}`}
                          >
                            {row.interval}
                          </IonCol>
                          <IonCol
                            className={`residualGrid data-col FontMobileService ${row.backgroundClass}`}
                          >
                            -
                            {format(
                              isNaN(row.depreciation)
                                ? "0"
                                : row.depreciation / 2,
                              Format.Currency
                            )}
                            %
                          </IonCol>
                          <IonCol
                            className={`residualGrid data-col FontMobileService ${row.backgroundClass}`}
                          >
                            {format(row.retailPrice, Format.Currency)}
                          </IonCol>
                        </IonRow>
                      );
                    }
                  }
                  return null;
                })}
              </IonGrid>
              <IonCol
                size="11.5"
                sizeXs="11.5"
                sizeMd="11.5"
                sizeLg="12"
                sizeXl="3.5"
                className="filters-desktop"
              >
                <div className="Filters">
                  <>
                    <section className="sliderContainer">
                      <span id="sliderContainerACtual">
                        <div className="digi">
                          <span className="FontMobileSpecs">
                            FROM:
                            <h1>{kmFromSlider}</h1>
                          </span>
                          <span id="digiRight">
                            TO:
                            <h1>{kmToSlider}</h1>
                          </span>
                        </div>
                        <IonRange
                          id="slider"
                          aria-label="Dual Knobs Range"
                          dualKnobs={true}
                          min={0}
                          max={
                            uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans &&
                            uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans?.length > 0
                              ? uiState.selectedVehicle?.data?.servicePlanTotals
                                  ?.result?.servicePlans[
                                  uiState.selectedVehicle?.data
                                    ?.servicePlanTotals?.result?.servicePlans
                                    .length - 1
                                ].interval
                              : 20000
                          }
                          step={1000}
                          onIonChange={(ev: any) => {
                            const lowerValue = isNaN(ev.detail.value.lower)
                              ? 0
                              : ev.detail.value.lower;
                            const upperValue = isNaN(ev.detail.value.upper)
                              ? 0
                              : ev.detail.value.upper;
                            setKmToSlider(upperValue);
                            setKmFromSlider(lowerValue);
                          }}
                          value={{
                            upper: kmToSlider,
                            lower: kmFromSlider,
                          }}
                        ></IonRange>

                        <div className="digi">
                          <span>TRAVELLING:</span>
                          <span id="digiRight">
                            <h1>
                              {rangeValue}
                              /YR
                            </h1>
                          </span>
                        </div>

                        <IonRange
                          id="slider"
                          step={500}
                          min={2500}
                          max={100000}
                          value={rangeValue}
                        ></IonRange>

                        <div className="digi">
                          <span>CURRENT MILEAGE</span>
                          <span id="digiRight">
                            <h1>{CurrentKm}Km</h1>
                          </span>
                        </div>

                        <IonRange
                          id="slider"
                          step={1000}
                          min={0}
                          max={
                            uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans &&
                            uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans?.length > 0
                              ? uiState.selectedVehicle?.data?.servicePlanTotals
                                  ?.result?.servicePlans[
                                  uiState.selectedVehicle?.data
                                    ?.servicePlanTotals?.result?.servicePlans
                                    .length - 1
                                ].interval
                              : 20000
                          }
                          value={CurrentKm}
                          onIonChange={(ev: any) => {
                            setCurrentKm(ev.target.value.toString());
                          }}
                        ></IonRange>
                      </span>
                    </section>
                  </>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <DataAssurance />
          <Whatsapp shareClicked={handleShareClick} />
        </div>

        {/* <div className="totalsDisplay">
          <span>ESTIMATED RESIDUAL VALUE</span>
          <IonCol
            size=""
            className="ion-justify-content-center mobileTotalCostMoney"
          >
            {format(RetailPrice - DepreciationV, Format.Currency)}
          </IonCol>
        </div> */}

        <div
          className={`totalsDisplay ${
            slideDirection === "in" ? "slideIn" : "slideOut"
          }`}
          onTouchStart={(e) => {
            touchStartX = e.touches[0].clientX;
          }}
          onTouchEnd={(e) => {
            const touchEndX = e.changedTouches[0].clientX;
            const swipeDistance = touchEndX - touchStartX;
            const swipeThreshold = 50;

            if (Math.abs(swipeDistance) > swipeThreshold) {
              if (
                slideDirection === "in" ||
                slideDirection === "in-after-out"
              ) {
                setShowWithSlider(true);
                setSlideDirection("out");
              } else {
                setShowWithSlider(false);
                setSlideDirection("in-after-out");
              }
            }
          }}
        >
          <span>
            {showWithSlider ? (
              <>ESTIMATED RESIDUAL VALUE</>
            ) : (
              <span className="res-total">
                EST. DEPRECIATION IN SET LIFESPAN
              </span>
            )}
          </span>
          <span>
            <IonIcon
              src={chevronBack}
              id="Total-prev-arrow"
              onClick={(e) => {
                handleSwipe();
              }}
              style={{ width: "80px", height: "80px", color: "gray" }}
            ></IonIcon>
            {showWithSlider ? (
              <IonCol
                size=""
                className="ion-justify-content-center mobileTotalCostMoney"
              >
                {format(RetailPrice - DepreciationV, Format.Currency)}
              </IonCol>
            ) : (
              <>
                {/* <IonRow>
                  <IonCol size="" sizeXl="2">
                    <p id="residual-valuef-from-km">FROM: {kmFromSlider}</p>
                  </IonCol>
                  {rows.map((row, index) => {
                    if (row && typeof row === "object" && "interval" in row) {
                      const intervalStep = 10000;

                      const nextInterval =
                        Math.ceil(kmFromSlider / intervalStep) * intervalStep;

                      if (row.interval === nextInterval) {
                        return (
                          <IonCol
                            size="20"
                            sizeXl="2"
                            className={`residual-details-total-left`}
                          >
                            {format(row.retailPrice, Format.Currency)}
                          </IonCol>
                        );
                      }
                    }
                  })}
                </IonRow> */}

                {priceDifference !== null && (
                  <IonCol
                    size="6"
                    className="ion-justify-content-center mobile-total-cost"
                  >
                    {`${format(priceDifference, Format.Currency)}`}
                  </IonCol>
                )}

                {/* <IonRow>
                  <IonCol size="" sizeXl="2">
                    <p id="residual-value-km">TO: {kmToSlider}</p>
                  </IonCol>

                  {rows.map((row, index) => {
                    if (row && typeof row === "object" && "interval" in row) {
                      const intervalStep = 10000;

                      const prevInterval =
                        Math.ceil(kmToSlider / intervalStep) * intervalStep;

                      if (row.interval === prevInterval) {
                        return (
                          <IonCol
                            size=""
                            sizeXl="2"
                            className={`residual-details-total`}
                          >
                            {format(row.retailPrice, Format.Currency)}
                          </IonCol>
                        );
                      }
                    }
                  })}
                </IonRow> */}
              </>
            )}
            <IonIcon
              src={chevronForward}
              id="Total-next-arrow"
              onClick={(e) => {
                handleSwipe();
              }}
              style={{ width: "80px", height: "80px", color: "gray" }}
            ></IonIcon>
          </span>
        </div>
      </GlobalGrid>
    </>
  );
};

export default ResellValue;
