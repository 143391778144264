export enum Config {
  API_SERVER = "https://za-api.mapo-int.com",
  AUTH_SERVER = "https://za-apihub.mapo-int.com",
  // APP_SERVER = 'https://mapoapp.herokuapp.com',
  // API_KEY = '4b1c65da08c313f',

  // API_SERVER = 'https://qa-za-api.mapo-int.com',
  // AUTH_SERVER = 'https://qa-za-apihub.mapo-int.com',
  // API_SERVER = 'https://localhost:44366',
  // AUTH_SERVER = 'https://localhost:44362',
  APP_SERVER = "https://mapoapp.herokuapp.com",
  API_KEY = "4b1c65da08c313f",
  //For the BasketLayout
  TYPE_VEHICLE = "CARS",
}
