import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import Header from '../../components/header';
import Loading from '../../components/loading';
import SideMenu from '../../components/side-menu';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import { RootState } from '../../redux/store';
import { setScreen } from '../../redux/ui/uiSlice';

const Notifications: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();

  const onBackPress = useCallback(() => {
    dispatch(setScreen(AppScreens.Notifications));
  }, [dispatch]);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          <IonRow className='desktopContent'>
            {/* <IonCol size='1' className='menu-col ion-no-padding ion-no-margin'>
              <SideMenu></SideMenu>
            </IonCol> */}
            <IonCol size='12' className='ion-no-padding ion-no-margin'>
              <DesktopContainer>
                <div className='detailContent'>
                  <IonRow className='spaceitems'>
                    <IonCol size='12' className='ion-no-padding'>
                      <span
                        className='desktopHeadingText'
                        style={{
                          color: sessionState.theme?.sidebar_background,
                        }}
                      >
                        Notifications
                      </span>
                    </IonCol>
                    <IonCol size='6'></IonCol>
                  </IonRow>
                </div>
              </DesktopContainer>
            </IonCol>
          </IonRow>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
                vehicle={uiState.selectedVehicle}
              ></SubPageHeading>
            </div>
            <div className='detailContent'>
              <p>Coming soon...</p>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Notifications;
