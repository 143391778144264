import React from "react";
import "../../../../dynamsoft.config";
import { CameraEnhancer, CameraView } from "dynamsoft-camera-enhancer";
import { CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter } from "dynamsoft-utility";
import "./VideoCapture.css";
import { CodeParser } from "dynamsoft-code-parser";

const componentDestroyedErrorMsg = "VideoCapture Component Destroyed";

class VideoCapture extends React.Component {
  cameraViewContainer: React.RefObject<HTMLDivElement> = React.createRef();
  resultsContainer: React.RefObject<HTMLDivElement> = React.createRef();

  resolveInit?: () => void;
  pInit: Promise<void> = new Promise((r) => (this.resolveInit = r));
  isDestroyed = false;

  cvRouter?: CaptureVisionRouter;
  cameraEnhancer?: CameraEnhancer;
  codeParser?: CodeParser;

  async componentDidMount() {
    try {
      // Create a `CameraEnhancer` instance for camera control and a `CameraView` instance for UI control.
      const cameraView = await CameraView.createInstance();
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      } // Check if component is destroyed after every async

      this.cameraEnhancer = await CameraEnhancer.createInstance(cameraView);
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }
      console.log("DCE ok");

      // Get default UI and append it to DOM.
      this.cameraViewContainer.current!.append(cameraView.getUIElement());

      // Create a `CaptureVisionRouter` instance and set `CameraEnhancer` instance as its image source.
      this.cvRouter = await CaptureVisionRouter.createInstance();
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }
      this.cvRouter.setInput(this.cameraEnhancer);
      console.log("CVR ok");

     
      this.codeParser = await CodeParser.createInstance();
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }
      console.log("DCP ok");

      // Define a callback for results.
      // this.cvRouter.addResultReceiver({
      //   onDecodedBarcodesReceived: async (result) => {
      //     if (!result.barcodeResultItems.length) return;

      //     this.resultsContainer.current!.textContent = "";
      //     console.log(result);
      //     for (let item of result.barcodeResultItems) {
      //       // this.resultsContainer.current!.textContent += `${item.formatString}: ${item.text}\n\n`;
      //       try {
      //         const parsedResult = await this.codeParser!.parse(item.bytes);
      //         const parsedInfo = JSON.parse(parsedResult.jsonString);
      //         this.resultsContainer.current!.textContent += `Parsed Info: ${JSON.stringify(parsedInfo, null, 2)}\n\n`;
      //       } catch (err) {
      //         console.error("Error parsing barcode: ", err);
      //         this.resultsContainer.current!.textContent += `${item.formatString}: ${item.text}\n\n`;
      //       }
      //     }
      //   },
      // });
      this.cvRouter.addResultReceiver({
        onDecodedBarcodesReceived: async (result) => {
          if (!result.barcodeResultItems.length) return;
      
          this.resultsContainer.current!.textContent = "";
          console.log(result);
      
          // Clear old data in localStorage
          localStorage.removeItem("barcodeResults");
      
          // Array to hold new results to save to localStorage
          const newResults = [];
      
          for (let item of result.barcodeResultItems) {
            try {
              const parsedResult = await this.codeParser!.parse(item.bytes);
              const parsedInfo = JSON.parse(parsedResult.jsonString);
      
              // Display parsed information
              const resultString = `Parsed Info: ${JSON.stringify(parsedInfo, null, 2)}\n\n`;
              this.resultsContainer.current!.textContent += resultString;
      
              // Add new parsed info to results array
              newResults.push(parsedInfo);
            } catch (err) {
              console.error("Error parsing barcode: ", err);
              const resultString = `${item.formatString}: ${item.text}\n\n`;
              this.resultsContainer.current!.textContent += resultString;
      
              // Add fallback result to results array
              newResults.push({ format: item.formatString, text: item.text });
            }
          }
      
          // Save new results to localStorage
          localStorage.setItem("barcodeResults", JSON.stringify(newResults));
      
          // Close camera after results are found
          if (this.cameraEnhancer) {
            this.cameraEnhancer.close();
            console.log("Camera closed after results were found.");
          }
        },
      });
      

      // Filter out unchecked and duplicate results.
      const filter = new MultiFrameResultCrossFilter();
      // Filter out unchecked barcodes.
      filter.enableResultCrossVerification("barcode", true);
      // Filter out duplicate barcodes within 3 seconds.
      filter.enableResultDeduplication("barcode", true);
      await this.cvRouter.addResultFilter(filter);
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }

      // Open camera and start scanning single barcode.
      await this.cameraEnhancer.open();
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }
      await this.cvRouter.startCapturing("ReadSingleBarcode");
      if (this.isDestroyed) {
        throw Error(componentDestroyedErrorMsg);
      }
    } catch (ex: any) {
      if ((ex as Error)?.message === componentDestroyedErrorMsg) {
        console.log(componentDestroyedErrorMsg);
      } else {
        let errMsg = ex.message || ex;
        console.error(errMsg);
        alert(errMsg);
      }
    }

    // Resolve pInit promise once initialization is complete.
    this.resolveInit!();
  }

  async componentWillUnmount() {
    this.isDestroyed = true;
    try {
      // Wait for the pInit to complete before disposing resources.
      await this.pInit;
      this.cvRouter?.dispose();
      this.cameraEnhancer?.dispose();
    } catch (_) {}
  }

  shouldComponentUpdate() {
    // Never update UI after mount, sdk use native way to bind event, update will remove it.
    return false;
  }

  render() {
    return (
      <div>
        <div ref={this.cameraViewContainer} style={{ width: "50vh", height: "60vh" }}></div>
        <br />
      
        <div ref={this.resultsContainer} className="results"></div>
      </div>
    );
  }
}

export default VideoCapture;
