import axios, { AxiosResponse } from 'axios';
import { Config } from '../Config';

export const vinImageSearch = (blobData: any) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', blobData, 'myimage.png');
    // const url = `${Config.APP_SERVER}/api/vin/upload`;
    const url = 'https://node.medimatters.co.za/api/account/upload'
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
