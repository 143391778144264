import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppScreens } from "../../enums";
import { SelectedVehicle, Vehicle } from "../../interfaces";
import { FileAgeData } from "../../interfaces/ageData";
import { CompetitorData } from "../../interfaces/competitorData";
import { CustomReportParams } from "../../interfaces/customReportParams";
import { CompetitorInfo } from "../../interfaces/competitorInfo";
import { ParamSearch } from "../../interfaces/paramSearch";
import { LabourRatesData } from "../../interfaces/LabourRates";
import { vistorsParking } from "../../interfaces/VistorsParking";
import { BasketLayout } from "../../interfaces/BasketLayout";

export type UI = {
  showSideMenuDisabledItems: boolean;
  screen: AppScreens;
  modalData: any;
  sideMenuOpen: boolean;
  sideMenu: boolean;
  sideMenuDisabled: boolean;
  loading: boolean;
  toolbarVisible: boolean;
  messageTitle: string;
  messageDetails: string;
  messageVisible: boolean;
  largeHeader: boolean;
  isDesktop?: boolean;
  searchKeyword: string | undefined;
  searchResults: Array<Vehicle> | undefined;
  addCompSearchResults: Array<Vehicle> | undefined;
  selectedVehicle: SelectedVehicle | undefined;
  fromSearch?: boolean;

  // modelSpecification: ModelSpecification | undefined;
  competitors: Array<CompetitorData> | undefined;
  competition: Array<CompetitorInfo> | undefined;
  customReportParams: CustomReportParams;
  fileAgeData: Array<FileAgeData> | undefined;
  LabourRatesData: Array<LabourRatesData> | undefined;
  VistorsParking: Array<vistorsParking> | undefined;
  Basketlayout: Array<BasketLayout> | undefined;
  filteredSearchVisible: boolean;
  parameterSearchVisible: boolean;
  UndentifiedCinSearchVisible: boolean;
  swapoutSearchVisible: boolean;
  swapoutCompetitorIndex?: number;
  createReportVisible: boolean;
  watchVideoModalVisible: boolean;
  ShareSocialVisible: boolean;
  mobileSearchType?: string;
  menuCollapsed: boolean;
};

export declare type MessageOptions = {
  title: string;
  message: string;
}; 

const initialState: UI = {
  screen: AppScreens.Home,
  modalData: null,
  sideMenuOpen: false,
  sideMenu: false,
  showSideMenuDisabledItems: false,
  sideMenuDisabled: false,
  loading: false,
  toolbarVisible: false,
  messageTitle: "",
  messageDetails: "",
  messageVisible: false,
  largeHeader: false,
  isDesktop: undefined,
  searchKeyword: undefined,
  searchResults: undefined,
  addCompSearchResults: undefined,
  selectedVehicle: undefined,
  fromSearch: undefined,
  competitors: undefined,
  competition: undefined,
  customReportParams: {
    deposit: 0,
    interest: 9.5,
    term: 60,
    residual: 0,
    toggles: {
      finance: true,
      service: true,
      maintenance: true,
      fuel: true,
      insurance: true,
      tyre: true,
      depreciation: true,
    },
    totalLifeSpan: 300000,
    annualMileage: 25000,
    tyreChangeInterval: 60000,
  },
  fileAgeData: undefined,
  LabourRatesData: undefined,
  VistorsParking: undefined,
  Basketlayout: undefined,
  filteredSearchVisible: false,
  parameterSearchVisible: false,
  UndentifiedCinSearchVisible: false,
  swapoutSearchVisible: false,
  swapoutCompetitorIndex: undefined,
  createReportVisible: false,
  mobileSearchType: "MANUAL SEARCH",
  menuCollapsed: false,
  watchVideoModalVisible: false,
  ShareSocialVisible: false,
};

const uiSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    showLoader: (state) => {
      state.loading = true;
    },
    hideLoader: (state) => {
      state.loading = false;
    },
    showSideMenuButton: (state) => {
      state.sideMenu = true;
    },
    showSideMenuDisabledItems: (state) => {
      state.showSideMenuDisabledItems = true;
    },
    hideSideMenuDisabledItems: (state) => {
      state.showSideMenuDisabledItems = false;
    },
    hideSideMenuButton: (state) => {
      state.sideMenu = false;
    },
    showSideMenu: (state) => {
      state.sideMenuOpen = true;
    },
    hideSideMenu: (state) => {
      state.sideMenuOpen = false;
    },
    showToolbar: (state) => {
      state.toolbarVisible = true;
    },
    hideToolbar: (state) => {
      state.toolbarVisible = false;
    },
    showMessage: (state, action: PayloadAction<MessageOptions>) => {
      state.messageTitle = action.payload.title;
      state.messageDetails = action.payload.message;
      state.messageVisible = true;
    },
    hideMessage: (state) => {
      state.messageVisible = false;
    },
    setScreen: (state, action: PayloadAction<AppScreens>) => {
      state.screen = action.payload;
    },
    setModalData: (state, action: PayloadAction<any>) => {
      state.modalData = action.payload;
    },
    setLargeHeader: (state, action: PayloadAction<boolean>) => {
      state.largeHeader = action.payload;
    },
    setIsDesktop: (state, action: PayloadAction<boolean>) => {
      state.isDesktop = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<any>) => {
      state.searchResults = action.payload;
    },
    setSearchKeyword: (state, action: PayloadAction<any>) => {
      state.searchKeyword = action.payload;
    },
    setSelectedVehicle: (state, action: PayloadAction<any>) => {
      state.selectedVehicle = action.payload;
    },
    setSelectedVehicleTCO: (state, action: PayloadAction<any>) => {
      if (state.selectedVehicle != null && state.selectedVehicle.data != null)
        state.selectedVehicle.data.tcoData = action.payload;
    },
    setCompetitorTCO: (state, action: PayloadAction<any>) => {
      let competitor = state.competitors?.at(action.payload.index);
      if (competitor != null && competitor?.tcoData != null)
        competitor.tcoData = action.payload.data;
    },
    setFromSearch: (state, action: PayloadAction<any>) => {
      state.fromSearch = action.payload;
    },
    setCompetitors: (
      state,
      action: PayloadAction<Array<CompetitorData> | undefined>
    ) => {
      state.competition = action.payload?.sort((a, b) =>
        (a.competitorBase?.g_id || 0) > (b.competitorBase?.g_id || 0) ? 1 : -1
      );
      state.competitors = action.payload?.sort((a, b) =>
        (a.competitorBase?.g_id || 0) > (b.competitorBase?.g_id || 0) ? 1 : -1
      );
    },
    setCompetition: (
      state,
      action: PayloadAction<Array<CompetitorInfo> | undefined>
    ) => {
      state.competition = action.payload?.sort((a, b) =>
        (a.competitorBase?.g_id || 0) > (b.competitorBase?.g_id || 0) ? 1 : -1
      );
      state.competitors = action.payload?.sort((a, b) =>
        (a.competitorBase?.g_id || 0) > (b.competitorBase?.g_id || 0) ? 1 : -1
      );
    },
    setCustomReportParams: (
      state,
      action: PayloadAction<CustomReportParams>
    ) => {
      state.customReportParams = action.payload;
    },
    setFileAgeData: (
      state,
      action: PayloadAction<Array<FileAgeData> | undefined>
    ) => {
      state.fileAgeData = action.payload;
    },
    setLabourRatesData: (
      state,
      action: PayloadAction<Array<LabourRatesData> | undefined>
    ) => {
      state.LabourRatesData = action.payload;
    },
    setBasketLayout: (
      state,
      action: PayloadAction<Array<BasketLayout> | undefined>
    ) =>{ state.Basketlayout = action.payload},
    setVistorsGarage: (
      state,
      action: PayloadAction<Array<vistorsParking> | undefined>
    ) => {
      state.VistorsParking = action.payload;
    },
    setFilteredSearchVisible: (state, action: PayloadAction<boolean>) => {
      state.filteredSearchVisible = action.payload;
    },
    setParameterSearchVisible: (state, action: PayloadAction<boolean>) => {
      state.parameterSearchVisible = action.payload;
    },
    setUndentifiedVinSearchVisible: (state , action: PayloadAction<boolean> ) => {
      state.UndentifiedCinSearchVisible = action.payload;
    },
    setSwapoutSearchVisible: (state, action: PayloadAction<boolean>) => {
      state.swapoutSearchVisible = action.payload;
    },
    setSwapoutCompetitorIndex: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.swapoutCompetitorIndex = action.payload;
    },

    setCreateReportVisible: (state, action: PayloadAction<boolean>) => {
      state.createReportVisible = action.payload;
    },
    setMobileSearchType: (state, action: PayloadAction<string>) => {
      state.mobileSearchType = action.payload;
    },
    setMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.menuCollapsed = action.payload;
    },
    setWatchVideoModalVisible: (state, action: PayloadAction<boolean>) => {
      state.watchVideoModalVisible = action.payload;
    },

    setShareSocialVisible: (state, action: PayloadAction<boolean>) => {
      state.ShareSocialVisible = action.payload;
    }
  },
});

export const {
  showLoader,
  hideLoader,
  showSideMenuButton,
  hideSideMenuButton,
  showSideMenu,
  hideSideMenu,
  showSideMenuDisabledItems,
  hideSideMenuDisabledItems,
  showToolbar,
  hideToolbar,
  showMessage,
  hideMessage,
  setScreen,
  setModalData,
  setLargeHeader,
  setIsDesktop,
  setSearchKeyword,
  setSearchResults,
  setSelectedVehicle,
  setSelectedVehicleTCO,
  setCompetitorTCO,
  setFromSearch,
  setCompetitors,
  setCompetition,
  setCustomReportParams,
  setFileAgeData,
  setLabourRatesData,
  setVistorsGarage,
  setBasketLayout,
  setFilteredSearchVisible,
  setSwapoutSearchVisible,
  setParameterSearchVisible,
  setUndentifiedVinSearchVisible,
  setSwapoutCompetitorIndex,
  setCreateReportVisible,
  setMobileSearchType,
  setMenuCollapsed,
  setWatchVideoModalVisible,
  setShareSocialVisible,
} = uiSlice.actions;
export default uiSlice.reducer;
