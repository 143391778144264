import {
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import {
  hideLoader,
  setCompetition,
  setCompetitors,
  setCustomReportParams,
  setFilteredSearchVisible,
  setFromSearch,
  setLargeHeader,
  setMobileSearchType,
  setScreen,
  setSearchKeyword,
  setSearchResults,
  setSelectedVehicle,
  setUndentifiedVinSearchVisible,
  showLoader,
} from "../../redux/ui/uiSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrScanner from "qr-scanner";
import "./index.css";
import Button from "../../components/button";
import { manualSearch, vinSearch } from "../../api/mapo";
import { Model, SelectedVehicle, Vehicle } from "../../interfaces";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { RootState } from "../../redux/store";
import { $CombinedState } from "redux";
import { chevronBackCircleSharp, document } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import {
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../api/getAll";
import DesktopContainer from "../../components/desktop-container";
import { icons, images } from "../../assets";
import { title } from "process";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { decVal } from "../../utils";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

export enum Html5QrcodeSupportedFormats {
  QR_CODE = 0,
  AZTEC,
  CODABAR,
  CODE_39,
  CODE_93,
  CODE_128,
  DATA_MATRIX,
  MAXICODE,
  ITF,
  EAN_13,
  EAN_8,
  PDF_417,
  RSS_14,
  RSS_EXPANDED,
  UPC_A,
  UPC_E,
  UPC_EAN_EXTENSION,
}

const VinScan: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);

  const dispatch = useDispatch();
  const history = useHistory();
  const [vinNumber, setVinNumber] = useState("");
  const [vinModel, SetVinModel] = useState("");
  const [vinNumberSearch, setVinNumberSearch] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [Make, setMake] = useState("");
  const [Model, setModel] = useState("");
  const [Type, setType] = useState("");
  const [LicenseNumber, setLicenseNumber] = useState("");
  const [EngineNumber, setEngineNumber] = useState("");
  const [ExpiryData, setExpiryData] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [showFilterPage, setShowFilterPage] = useState(false);

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
  };

  const programmaticButtonClick = () => {
    // Trigger the button click programmatically
    if (buttonRef.current) {
      (buttonRef.current as HTMLButtonElement).click();
    }
  };

  const onBackPress = useCallback(() => {
    window.location.reload();
  }, [dispatch]);
  const onPress = useCallback(() => {
    dispatch(setScreen(AppScreens.UndentifiedVin));
  }, [dispatch]);

  const formatsToSupport = [Html5QrcodeSupportedFormats.PDF_417];

  useEffect(() => {
    let scanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 800, height: 600 },
        formatsToSupport: formatsToSupport,
        videoConstraints: {
          facingMode: { exact: "environment" },
        },
        //To scan without selecting item from the gallery
        //supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      },

      /* verbose= */ false
    );

    scanner.render(success, error);

    function success(result: any) {
      scanner.clear();
      setVinNumber(result);
    }

    function error(err: any) {
      //console.warn(err)
    }
  }, []);

  useEffect(() => {
    const str = vinNumber;

    let MakeVin = str.split("%")[12];
    let Group = str.split("%")[9];
    let Model = str.split("%")[10];
    let Type = str.split("%")[8];
    let LicenseNr = str.split("%")[6];
    let EngineNr = str.split("%")[13];
    let ExpiryDate = str.split("%")[14];

    // SetVinModel(str);
    // SetVinModel(MakeVin + " " + stringModel);
    SetVinModel(MakeVin);
    setMake(Group);
    setModel(Model);
    setType(Type);
    setLicenseNumber(LicenseNr);
    setEngineNumber(EngineNr);
    setExpiryData(ExpiryDate);

    sessionStorage.setItem("Vin Scanned", MakeVin);
    sessionStorage.setItem("ScannedGroup", Group);
    sessionStorage.setItem("ScannedModel", Model);
    sessionStorage.setItem("ScannedRegistration", LicenseNr);
    sessionStorage.setItem("ScannedEngine", EngineNr);
    sessionStorage.setItem("ScannedExpiry", ExpiryDate);
    sessionStorage.setItem("ScannedType", Type);

    // Create tags based on MakeVin or any other logic then updating Update tags state
  }, [vinNumber]);

  const handleOpenInput = () => {
    setShowInput(true);
  };

  //
  const onVinSearch = useCallback(() => {
    dispatch(showLoader());
    vinSearch(vinModel)
      .then((vinResponse) => {
        if (vinResponse.status === 200) {
          const model: Model = vinResponse.data;

          if (model && model.result?.length > 0) {
            const selectedVehicle: SelectedVehicle = {
              make: model.result[0].make,
              model: model.result[0].model,
              variant: model.result[0].variant,
              g_id: model.result[0].groupCode,
              m_id: model.result[0].modelCode,
              v_id: model.result[0].variantCode,
            };

            getSelectedSpecificModelData(
              selectedVehicle.g_id,
              selectedVehicle.m_id,
              selectedVehicle.v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            ).then((allData) => {
              dispatch(
                setSelectedVehicle({
                  ...selectedVehicle,
                  data: allData.selected,
                })
              );
              dispatch(setCompetition(allData.competition ?? []));

              dispatch(setMobileSearchType("FILTERED SEARCH"));
              dispatch(setFromSearch(false));
              //dispatch(setScreen(AppScreens.VehicleLanding));
              dispatch(hideLoader());
              history.push("/ScanResults");
              dispatch(setLargeHeader(false));
            });
          } else {
            dispatch(hideLoader());
            alert("No data available on this VIN number.");
            //dispatch(setScreen(AppScreens.UndentifiedVin));
            history.push("/UndentifiedVin");
          }
        } else {
          dispatch(hideLoader());
          alert("Error.");
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        //alert(JSON.stringify(err));
        dispatch(setUndentifiedVinSearchVisible(true));
      });
  }, [dispatch, vinModel]);

  const onVinSear = useCallback(() => {
    dispatch(showLoader());
    vinSearch(vinModel)
      .then((vinResponse) => {
        if (vinResponse.status === 200) {
          const model: Model = vinResponse.data;

          if (model && model.result?.length > 0) {
            const selectedVehicle: SelectedVehicle = {
              make: model.result[0].make,
              model: model.result[0].model,
              variant: model.result[0].variant,
              g_id: model.result[0].groupCode,
              m_id: model.result[0].modelCode,
              v_id: model.result[0].variantCode,
            };

            getAllSelectedData(
              selectedVehicle.g_id,
              selectedVehicle.m_id,
              selectedVehicle.v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            ).then((allData) => {
              dispatch(
                setSelectedVehicle({
                  ...selectedVehicle,
                  data: allData.selected,
                })
              );
              //dispatch(setCompetitors(allData.competitors ?? []));

              const selectedVehicleCustomParams: VehicleCustomReportParams = {
                retailprice: decVal(
                  allData.selected?.modelSpecification.result?.basic_specs
                    ?.base_price
                ),
                co2tax: Number(
                  allData.selected?.modelSpecification.result?.engine_specs
                    .co2 ?? ""
                ),

                // Service
                servicestandardkm: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.service_plan ?? "0"
                ),
                servicestandardmnt: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.service_plan_months ?? "0"
                ),

                // Maintenance
                maintenancestandardkm: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.maintenance_plan ?? "0"
                ),
                maintenancestandardmnt: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.maintenance_plan_months ??
                    "0"
                ),

                // baloonpayment: 0,
              };

              dispatch(
                setCustomReportParams({
                  ...uiState.customReportParams,
                  selectedVehicleCustomParams: selectedVehicleCustomParams,
                  // competitorCustomParams: competitorCustomParams,
                })
              );

              dispatch(setMobileSearchType("FILTERED SEARCH"));
              dispatch(setFromSearch(false));
              //dispatch(setScreen(AppScreens.VehicleLanding));
              dispatch(hideLoader());
              // history.push("/ScanResults");
              dispatch(setLargeHeader(false));
            });
          } else {
            dispatch(hideLoader());
            alert("No data available on this VIN number.");
            //dispatch(setScreen(AppScreens.UndentifiedVin));
            // history.push("/UndentifiedVin");
          }
        } else {
          dispatch(hideLoader());
          alert("Error.");
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        //alert(JSON.stringify(err));
        if (window.innerWidth > 992) {
          setShowFilterPage(true); // Execute only in desktop view
        } else {
          dispatch(setUndentifiedVinSearchVisible(true)); // Execute for other views
        }
      });
  }, [dispatch, vinModel]);

  const hasData = vinModel && Object.keys(vinModel).length > 0;

  useEffect(() => {
    if (vinModel && Object.keys(vinModel).length > 0) {
      onVinSearch(); // Call the onVinSearch function
      onVinSear();
    }
  }, [vinModel]);

  //On vin Manually search

  const onVinSearchManual = useCallback(() => {
    dispatch(showLoader());
    vinSearch(vinNumberSearch)
      .then((vinResponse) => {
        if (vinResponse.status === 200) {
          const model: Model = vinResponse.data;

          if (model && model.result?.length > 0) {
            const selectedVehicle: SelectedVehicle = {
              make: model.result[0].make,
              model: model.result[0].model,
              variant: model.result[0].variant,
              g_id: model.result[0].groupCode,
              m_id: model.result[0].modelCode,
              v_id: model.result[0].variantCode,
            };

            getSelectedSpecificModelData(
              selectedVehicle.g_id,
              selectedVehicle.m_id,
              selectedVehicle.v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            ).then((allData) => {
              dispatch(
                setSelectedVehicle({
                  ...selectedVehicle,
                  data: allData.selected,
                })
              );
              dispatch(setCompetition(allData.competition ?? []));
              dispatch(setMobileSearchType("FILTERED SEARCH"));
              dispatch(setFromSearch(false));
              //dispatch(setScreen(AppScreens.VehicleLanding));
              dispatch(hideLoader());
              history.push("/OverViewDetail");
              dispatch(setLargeHeader(false));
            });
          } else {
            dispatch(hideLoader());
            alert("No data available on this VIN number.");
            //dispatch(setScreen(AppScreens.UndentifiedVin));
            history.push("/UndentifiedVin");
          }
        } else {
          dispatch(hideLoader());
          alert("Error.");
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        //alert(JSON.stringify(err));
        dispatch(setUndentifiedVinSearchVisible(true));
      });
  }, [dispatch, vinNumberSearch]);

  const Press = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push("/ScanResults");
  }, [dispatch]);

  return (
    <IonPage>
      <GlobalGrid
        currentPageTitle="Vin Scanner"
        closeCurrentPage={() => onBackPress()}
        videoSource="../VinScan/APP-VINScan.mp4"
      >
        <div
          style={{
            marginTop: "10rem",
          }}
        >
          <div className="Camera" id="reader"></div>
        </div>
      </GlobalGrid>
    </IonPage>
  );
};

export default VinScan;
