import React, { useState, useEffect } from "react";
import moment from "moment";
import { time } from "console";
import { icons, images } from "../../assets";
import { eyeSharp } from "ionicons/icons";
import { IonCol, IonIcon, IonRow } from "@ionic/react";
import "./style.css";

interface CountdownTimerProps {
  endDate: string;
  onSelectVehicleGarageLimited: () => void;
  AddToCart: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  endDate,
  onSelectVehicleGarageLimited,
  AddToCart,
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      const duration = moment.duration(difference);

      // Calculate total years
      const totalYears = Math.floor(duration.asYears());

      if (totalYears > 0) {
        timeLeft.years = totalYears;
      }

      // Calculate remaining months
      const remainingMonths = duration.months();
      timeLeft.months = remainingMonths;

      // Calculate remaining days
      const remainingDays = duration.days();
      timeLeft.days = remainingDays;

      // Calculate remaining hours, minutes, and seconds
      timeLeft.hours = Math.floor(duration.hours());
      timeLeft.minutes = Math.floor(duration.minutes());
      timeLeft.seconds = Math.floor(duration.seconds());
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [rePurchase, setRePurchase] = useState(false);

  useEffect(() => {
    if (timeLeft.seconds! > 0) {
      setRePurchase(true);
    }
  }, [setRePurchase, timeLeft]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      {rePurchase ? (
        <IonCol size="12" className="visitTimer">
          <IonCol size="6">Model available for</IonCol>
          {timeLeft.years > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.years > 0 ? timeLeft.years : timeLeft.years}y
            </IonCol>
          )}
          {timeLeft.months > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.months > 0 ? timeLeft.months : timeLeft.months}mnt
            </IonCol>
          )}
          {timeLeft.days > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.days > 0 ? timeLeft.days : timeLeft.days}d
            </IonCol>
          )}
          {timeLeft.hours > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.hours > 0 ? timeLeft.hours : timeLeft.hours}h
            </IonCol>
          )}
          {timeLeft.minutes > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.minutes > 0 ? timeLeft.minutes : timeLeft.minutes}m
            </IonCol>
          )}
          {timeLeft.seconds > 0 && (
            <IonCol size="1">
              {" "}
              {timeLeft.seconds > 0 ? timeLeft.seconds : timeLeft.seconds}s
            </IonCol>
          )}
        </IonCol>
      ) : (
        <IonCol className="checkoutResultsGarage">
          <button onClick={onSelectVehicleGarageLimited}>
            <IonIcon id="cartIcon" src={eyeSharp} />
          </button>
          <button onClick={AddToCart}>
            ADD TO CART
            <img id="icon" src={icons.WhiteCartIcon} />
          </button>
        </IonCol>
      )}
    </>
  );
};

export default CountdownTimer;
