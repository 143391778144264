import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getImage } from "../../api/mapo";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import { format } from "../../utils";
import "./style.css";
import { CompetitorData } from "../../interfaces/competitorData";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { useHistory } from "react-router-dom";
import { thumbsUpSharp } from "ionicons/icons";
import CompetitorView from "../../components/competitorsView/competitorView";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import Competitor from "../ViewsDetailsBtn/Competitor";

const Competitors: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableView, setTableView] = useState(true);
  const [CartClicked, setIsClicked] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [addCartAlert, setAddCartAlert] = useState(false);
  // const [values, setValues] = useState({
  //   totalLifeSpan: uiState.customReportParams.totalLifeSpan ?? 120000,
  //   annualMileage: uiState.customReportParams.annualMileage ?? 20000,
  // });

  // const mileageOptions = () => {
  //   const options = [];
  //   for (let i = 0; i <= 30; i++) {
  //     options.push(i * 10000);
  //   }
  //   return options;
  // };

  // const anualMileageOptions = () => {
  //   const options = [];
  //   for (let i = 0; i <= 10; i++) {
  //     options.push(i * 5000);
  //   }
  //   return options;
  // };

  const onBackPress = useCallback(() => {
    // dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const renderLowestValue = useCallback(
    (
      index: number,
      val?: string,
      val1?: string,
      val2?: string,
      val3?: string,
      fmt?: Format
    ) => {
      let valInt = val ?? 0;

      if (index === 1) {
        valInt = val1 ?? 0;
      } else if (index === 2) {
        valInt = val2 ?? 0;
      } else if (index === 3) {
        valInt = val3 ?? 0;
      }

      if (fmt !== undefined) {
        return <span>{format(valInt, fmt)}</span>;
      } else {
        return <span>{valInt}</span>;
      }
    },
    []
  );

  const renderHighestValue = useCallback(
    (
      index: number,
      val?: string,
      val1?: string,
      val2?: string,
      val3?: string,
      fmt?: Format
    ) => {
      let valInt = val ?? 0;

      if (index === 1) {
        valInt = val1 ?? 0;
      } else if (index === 2) {
        valInt = val2 ?? 0;
      } else if (index === 3) {
        valInt = val3 ?? 0;
      }

      if (fmt !== undefined) {
        return <span>{format(valInt, fmt)}</span>;
      } else {
        return <span>{valInt}</span>;
      }
    },
    []
  );

  const getAdditionalServicePlanKm = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalkm
          ? customParams.serviceadditionalkm ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalServicePlanMonths = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalmnt
          ? customParams.serviceadditionalmnt ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan_months ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalMaintenancePlanKm = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.maintenanceadditionalkm
          ? customParams.maintenanceadditionalkm ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalMaintenancePlanMonths = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.maintenanceadditionalmnt
          ? customParams.maintenanceadditionalmnt ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan_months ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );

  const addToCart = (competitor: any) => {
    const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");

    // Check if 'modelClass.result' exists and has at least one element
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        modelClass,
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      // Check if the competitor is already in the cart
      const isInCart = cartItems.some(
        (item: any) => item.modelCode === modelCode
      );

      if (!isInCart) {
        // Add the competitor details to the cart
        cartItems.push({
          modelClass,
          make,
          modelCode,
          groupCode,
          variantCode,
          introDate,
          mapoCode,
          modelClassShortDescription,
          variant,
          variantId,
          status,
          model,
        });

        // Update localStorage with the new cart items
        localStorage.setItem("cart", JSON.stringify(cartItems));

        // The console
        //console.log("Cart Data:", cartItems);

        //alert("Item added to cart!");
        setIsClicked(true);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ADDED TO CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      } else {
        setDuplicateItemIndex(competitor);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ALREADY IN CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      }
    } else {
      console.error("Error: No result found in competitor.modelClass object.");
    }
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);
  return (
    <GlobalGrid
      currentPageTitle="COMPETITORS"
      closeCurrentPage={() => onBackPress()}
    >
      <div className="innerContent">
        <SubPageHeading vehicle={uiState.selectedVehicle} />
      </div>
      {uiState.competitors?.length === 0 ? (
        <span className="resultSubtitle">No competitors data available.</span>
      ) : null}
      <DesktopContainer>
        <IonGrid className="gridCompHolder">
          {uiState.competitors?.map((result, index) => {
            return (
              <CompetitorView
                competitor={result}
                compIndex={index}
                Addtocart={() => addToCart(result)}
              />
            );
          })}
        </IonGrid>
      </DesktopContainer>
      {addCartAlert && (
        <div className="addToCartAlertCont">
          <div className="thumb">
            <IonIcon src={thumbsUpSharp}></IonIcon>
          </div>
          <p>{addCartMsg}</p>
        </div>
      )}
    </GlobalGrid>
  );
};

export default Competitors;
