import { IonContent, IonPage } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { acceptTerms } from '../../api/auth';
import { getTerms } from '../../api/mapo';
import Button from '../../components/button';
import Header from '../../components/header';
import Link from '../../components/link';
import Loading from '../../components/loading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import {
  sessionLogout,
  setTermsAccepted,
} from '../../redux/session/sessionSlice';
import { RootState } from '../../redux/store';
import { hideLoader, setScreen, showLoader } from '../../redux/ui/uiSlice';
import './style.css';

const Welcome: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [termsText, setTermsText] = useState<string | undefined>();

  const loadTerms = useCallback(() => {
    dispatch(showLoader());
    getTerms()
      .then((termsResponse) => {
        if (termsResponse.status === 200) {
          dispatch(hideLoader());
          setTermsText(termsResponse.data);
        } else {
          alert(JSON.stringify(termsResponse.statusText));
        }
      })
      .catch((err: any) => {
        dispatch(hideLoader());
        console.log(err);
        alert(JSON.stringify(err));
      });
  }, [dispatch]);

  const onAcceptTerms = useCallback(() => {
    if (sessionState.user) {
      dispatch(showLoader());
      acceptTerms(sessionState.user)
        .then((regResponse) => {
          dispatch(hideLoader());
          if (regResponse.status === 200) {
            dispatch(setTermsAccepted(true));
            setTimeout(() => {
              dispatch(setScreen(AppScreens.Home));
            }, 300);
          } else {
            alert(JSON.stringify(regResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert('err');
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [dispatch, sessionState.user]);

  const onDisagreeTerms = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      dispatch(setScreen(AppScreens.Login));
    }, 300);
  }, [dispatch]);

  useEffect(() => {
    if (termsText === undefined) {
      loadTerms();
    }
  }, [termsText, loadTerms]);

  if (uiState.isDesktop) {
    return (
      <>
        <div
          className='background'
          style={{
            backgroundImage: `url(https://za-api.mapo-int.com/api/theme/getbackgroundimage?user=${sessionState.user?.username})`,
          }}
        >
          <div className='termsOverlay'>
            <div
              className='termsTerms'
              dangerouslySetInnerHTML={{ __html: termsText ?? '' }}
            ></div>
            <Button
              customClass='acceptButton'
              onPress={onAcceptTerms}
              title='I AGREE, PLEASE PROCEED'
            />
            <p style={{ textAlign: 'center' }}>
              <Link
                bold={true}
                color='#ffffff'
                title='I disagree'
                onPress={onDisagreeTerms}
              ></Link>
            </p>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>Welcome - content required</div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Welcome;
