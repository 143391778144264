import { useState } from "react";
import { CompetitorData } from "../interfaces/competitorData";
import { CompetitorInfo } from "../interfaces/competitorInfo";
import { Competitor } from "../interfaces/competitors";
import { ModelSpecification } from "../interfaces/modelSpecification";
import Competitors from "../pages/Competitors";
import {
  getCompetitors,
  getDepreciation,
  maintenancePlan,
  maintenancePlanTotals,
  modelClass,
  modelSpecificationDetails,
  partsBaskets,
  rates10KIntervals,
  servicePlan,
  servicePlanTotals,
  TCO,
} from "./mapo";

import { useDispatch } from "react-redux";

export interface AllData {
  selected: CompetitorData | undefined;

  competitors: Array<CompetitorData> | undefined;
}
export interface ModelSpecificationData {
  selected: CompetitorInfo | undefined;
  competition: Array<CompetitorInfo> | undefined;
}
let cancelFlag = false; // Flag to indicate whether to cancel the operation.

export const cancelGetAllData = () => {
  cancelFlag = true;

  // Set a short timeout (e.g., 100 milliseconds) to reset the cancelFlag.
  // setTimeout(() => {
  //   cancelFlag = true;
  // }, 100); // Adjust the timeout duration as needed (e.g., 100 milliseconds)
};

export const StartGetAllData = () => {
  cancelFlag = false;

  // Set a short timeout (e.g., 100 milliseconds) to reset the cancelFlag.
  // setTimeout(() => {
  //   cancelFlag = true;
  // }, 100); // Adjust the timeout duration as needed (e.g., 100 milliseconds)
};

export const getAllData = (
  g_id: number,
  m_id: number,
  v_id: number,
  lifeTime: number,
  tyreChangeInterval: number,
  kmPerYear: number,
  financeDeposit: number,
  financeInterest: number,
  financeTerm: number,
  financeResidual: number
) => {
  return new Promise<CompetitorData>(async (resolve, reject) => {
    // Check the cancelFlag before proceeding with each asynchronous call.
    if (cancelFlag) {
      throw new Error("Operation cancelled by user.");
    }
    await modelSpecificationDetails(g_id, m_id, v_id)
    
      .then((modelClassResponse) => {
        if (modelClassResponse.status === 200) {
          if (cancelFlag) {
            throw new Error("Operation cancelled by user.");
          }
          TCO(
            g_id,
            m_id,
            v_id,
            lifeTime,
            tyreChangeInterval,
            kmPerYear,
            financeDeposit,
            financeInterest,
            financeTerm,
            financeResidual
          )
            .then(async (tcoResponse: any) => {
              if (tcoResponse.status === 200) {
                if (cancelFlag) {
                  throw new Error("Operation cancelled by user.");
                }
                await maintenancePlanTotals(g_id, m_id, v_id)
                  .then((maintenancePlanTotalResponse: any) => {
                    if (cancelFlag) {
                      throw new Error("Operation cancelled by user.");
                    }
                    maintenancePlan(g_id, m_id, v_id)
                      .then((maintenancePlanResponse: any) => {
                        if (cancelFlag) {
                          throw new Error("Operation cancelled by user.");
                        }
                        servicePlan(g_id, m_id, v_id)
                          .then((servicePlanResponse: any) => {
                            if (servicePlanResponse.status === 200) {
                              if (cancelFlag) {
                                throw new Error("Operation cancelled by user.");
                              }
                              servicePlanTotals(g_id, m_id, v_id)
                                .then((servicePlanTotalResponse: any) => {
                                  if (servicePlanTotalResponse.status === 200) {
                                    if (cancelFlag) {
                                      throw new Error(
                                        "Operation cancelled by user."
                                      );
                                    }
                                    partsBaskets(g_id, m_id, v_id)
                                      .then(async (partsResponse: any) => {
                                        if (partsResponse.status === 200) {
                                          if (cancelFlag) {
                                            throw new Error(
                                              "Operation cancelled by user."
                                            );
                                          }
                                          await modelClass(g_id, m_id, v_id)
                                            .then((modelClassData) => {
                                              if (
                                                modelClassData.status === 200
                                              ) {
                                                if (cancelFlag) {
                                                  throw new Error(
                                                    "Operation cancelled by user."
                                                  );
                                                }
                                                rates10KIntervals(
                                                  g_id,
                                                  m_id,
                                                  v_id,
                                                  0,
                                                  lifeTime,
                                                  kmPerYear
                                                )
                                                  .then((ratesResponse) => {
                                                    if (
                                                      ratesResponse.status ===
                                                      200
                                                    ) {
                                                      if (cancelFlag) {
                                                        throw new Error(
                                                          "Operation cancelled by user."
                                                        );
                                                      }
                                                      getDepreciation(
                                                        g_id,
                                                        m_id,
                                                        v_id,
                                                        lifeTime
                                                      )
                                                        .then(
                                                          (
                                                            depreciationResponse
                                                          ) => {
                                                            if (cancelFlag) {
                                                              throw new Error(
                                                                "Operation cancelled by user."
                                                              );
                                                            }
                                                            const res =
                                                              partsResponse.data.reduce(
                                                                (
                                                                  acc: any,
                                                                  curr: any
                                                                ) => {
                                                                  if (
                                                                    !acc[
                                                                      curr
                                                                        .partsGroupMainName
                                                                    ]
                                                                  ) {
                                                                    acc[
                                                                      curr.partsGroupMainName
                                                                    ] = [];
                                                                  }
                                                                  acc[
                                                                    curr
                                                                      .partsGroupMainName
                                                                  ].push(curr);
                                                                  return acc;
                                                                },
                                                                {}
                                                              );

                                                            const data: CompetitorData =
                                                              {
                                                                modelSpecification:
                                                                  modelClassResponse.data,
                                                                tcoData:
                                                                  tcoResponse.data,
                                                                maintenancePlanTotals:
                                                                  maintenancePlanTotalResponse.data,
                                                                servicePlanTotals:
                                                                  servicePlanTotalResponse.data,
                                                                maintenancePlanItem:
                                                                  maintenancePlanResponse.data,
                                                                servicePlanItem:
                                                                  servicePlanResponse.data,
                                                                partGroups: res,
                                                                modelClass:
                                                                  modelClassData.data,
                                                                rates10kIntervalData:
                                                                  ratesResponse.data,
                                                                depreciation:
                                                                  depreciationResponse
                                                                    .data.vcode,
                                                                depreciationModel:
                                                                  depreciationResponse
                                                                    .data.mcode,
                                                                depreciationGroup:
                                                                  depreciationResponse
                                                                    .data.gcode,
                                                              };

                                                            resolve(data);
                                                          }
                                                        )
                                                        .catch((err) => {
                                                          console.log(
                                                            "getAllData 1: ",
                                                            err
                                                          );
                                                          reject(
                                                            JSON.stringify(err)
                                                          );
                                                        });
                                                    }
                                                  })
                                                  .catch((err) => {
                                                    console.log(
                                                      "getAllData 2: ",
                                                      err
                                                    );
                                                    reject(JSON.stringify(err));
                                                  });
                                              }
                                            })
                                            .catch((err) => {
                                              console.log(
                                                "getAllData 3: ",
                                                err
                                              );
                                              reject(JSON.stringify(err));
                                            });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log("getAllData 4: ", err);
                                        reject(JSON.stringify(err));
                                      });
                                  }
                                })
                                .catch((err) => {
                                  console.log("getAllData 5: ", err);
                                  reject(JSON.stringify(err));
                                });
                            }
                          })
                          .catch((err) => {
                            console.log("getAllData ServicePlan: ", err);
                            reject(JSON.stringify(err));
                          });
                      })
                      .catch((err) => {
                        console.log("getAllData maintenancePlan ", err);
                        reject(JSON.stringify(err));
                      });
                  })
                  .catch((err) => {
                    console.log("getAllData 6: ", err);
                    reject(JSON.stringify(err));
                  });
              } else {
                reject(modelClassResponse.statusText);
                return;
              }
            })
            .catch((err) => {
              console.log("getAllData 7: ", err);
              reject(JSON.stringify(err));
            });
        }
      })
      .catch((err) => {
        console.log("getAllData 8: ", err);
        reject(JSON.stringify(err));
      });
  });
};

// The function that gets specific details for model clicked
export const getSpecificModelData = (
  g_id: number,
  m_id: number,
  v_id: number,
  lifeTime: number,
  tyreChangeInterval: number,
  kmPerYear: number
) => {
  return new Promise<CompetitorInfo>(async (resolve, reject) => {
    await modelSpecificationDetails(g_id, m_id, v_id).then(
      async (modelClassResponse) => {
        if (modelClassResponse.status === 200) {
          await modelClass(g_id, m_id, v_id).then((modelClassData) => {
            if (modelClassData.status === 200) {
              const data: CompetitorInfo = {
                modelSpecification: modelClassResponse.data,

                modelClass: modelClassData.data,
              };

              resolve(data);
            }
          });
        }
      }
    );
  });
};
//FUnction for getting selected model in row.
export const getSelectedSpecificModelData = (
  g_id: number,
  m_id: number,
  v_id: number,
  lifeTime: number,
  tyreChangeInterval: number,
  kmPerYear: number
) => {
  const competition: Array<CompetitorInfo> = [];
  return new Promise<ModelSpecificationData>((resolve, reject) => {
    getSpecificModelData(
      g_id,
      m_id,
      v_id,
      lifeTime,
      tyreChangeInterval,
      kmPerYear
    ).then((selectedData) => {
      getCompetitors(g_id, m_id, v_id)
        .then((competitorResponse: any) => {
          if (competitorResponse.status === 200) {
            const competitorsGMV: Array<Competitor> = competitorResponse.data;

            if (competitorsGMV.length === 0) {
              const allData: ModelSpecificationData = {
                selected: selectedData,
                competition: [],
              };
              resolve(allData);
            } else {
              competitorsGMV.sort().map((competitor) => {
                getSpecificModelData(
                  competitor.g_id,
                  competitor.m_id,
                  competitor.v_id,
                  lifeTime,
                  tyreChangeInterval,
                  kmPerYear
                )
                  .then((CompetitorInfo) => {
                    competition.push({
                      ...CompetitorInfo,
                      competitorBase: competitor,
                    });
                    if (competition.length === competitorsGMV.length) {
                      const allData: ModelSpecificationData = {
                        selected: selectedData,
                        competition: competition?.sort(),
                      };
                      resolve(allData);
                    }
                  })
                  .catch((err) => {
                    reject(JSON.stringify(err));
                  });
              });
            }
          }
        })
        .catch((err) => {
          reject(JSON.stringify(err));
        });
    });
  });
};

//For the filter
// export const getAllSelectedData = (
//   g_id: number,
//   m_id: number,
//   v_id: number,
//   lifeTime: number,
//   tyreChangeInterval: number,
//   kmPerYear: number,
//   financeDeposit: number,
//   financeInterest: number,
//   financeTerm: number,
//   financeResidual: number
// ) => {
//   const competitors: Array<CompetitorData> = [];
//   return new Promise<AllData>((resolve, reject) => {
//     getAllData(
//       g_id,
//       m_id,
//       v_id,
//       lifeTime,
//       tyreChangeInterval,
//       kmPerYear,
//       financeDeposit,
//       financeInterest,
//       financeTerm,
//       financeResidual
//     )
//       .then((selectedData) => {
//         getCompetitors(g_id, m_id, v_id)
//           .then((competitorResponse: any) => {
//             if (competitorResponse.status === 200) {
//               const competitorsGMV: Array<Competitor> =
//                 competitorResponse.data?.sort();

//               if (competitorsGMV.length === 0) {
//                 const allData: AllData = {
//                   selected: selectedData,
//                   competitors: [],
//                 };
//                 resolve(allData);
//               } else {
//                 competitorsGMV.sort().map((competitor) => {
//                   getAllData(
//                     competitor.g_id,
//                     competitor.m_id,
//                     competitor.v_id,
//                     lifeTime,
//                     tyreChangeInterval,
//                     kmPerYear,
//                     financeDeposit,
//                     financeInterest,
//                     financeTerm,
//                     financeResidual
//                   )
//                     .then((competitorData) => {
//                       competitors.push({
//                         ...competitorData,
//                         competitorBase: competitor,
//                       });
//                       if (competitors.length === competitorsGMV.length) {
//                         const allData: AllData = {
//                           selected: selectedData,
//                           competitors: competitors?.sort(),
//                         };
//                         resolve(allData);
//                       }
//                     })
//                     .catch((err) => {
//                       reject(JSON.stringify(err));
//                     });
//                 });
//               }
//             }
//           })
//           .catch((err) => {
//             reject(JSON.stringify(err));
//           });
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

//Updated it to fetch only for the selected model no competitors 


export const getAllSelectedData = (
  g_id: number,
  m_id: number,
  v_id: number,
  lifeTime: number,
  tyreChangeInterval: number,
  kmPerYear: number,
  financeDeposit: number,
  financeInterest: number,
  financeTerm: number,
  financeResidual: number
) => {
  return new Promise<AllData>((resolve, reject) => {
    getAllData(
      g_id,
      m_id,
      v_id,
      lifeTime,
      tyreChangeInterval,
      kmPerYear,
      financeDeposit,
      financeInterest,
      financeTerm,
      financeResidual
    )
      .then((selectedData) => {
        const allData: AllData = {
          selected: selectedData,
          competitors: [], // No competitors
        };
        resolve(allData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

