import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  supportCloseTicket,
  supportGetCategories,
  supportGetFAQ,
  supportGetScreens,
  supportGetTicketReplies,
  supportGetTickets,
  supportSubmitReply,
  supportSubmitTicket,
} from "../../api/mapo";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router-dom";
import { hideLoader, setScreen, showLoader } from "../../redux/ui/uiSlice";
import { chevronBackCircleSharp } from "ionicons/icons";
import { images } from "../../assets";

const Support: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [screens, setScreens] = useState<Array<string>>();
  const [categories, setCategories] = useState<Array<string>>();
  const [selectedScreen, setSelectedScreen] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [gCode, setGCode] = useState("");
  const [mCode, setMCode] = useState("");
  const [vCode, setVCode] = useState("");
  const [description, setDescription] = useState("");
  const [replyText, setReplyText] = useState("");
  const [ticketsView, setTicketsView] = useState(false);
  const [tickets, setTickets] = useState<Array<any>>();
  const [ticketReplies, setTicketReplies] = useState<Array<any>>();
  const [faq, setFaq] = useState<Array<any>>();
  const [selectedTicket, setSelectedTicket] = useState("");

  const fixedText = `Hey there!
  Are you dreaming of purchasing the perfect vehicle? Check out MAPO's awesome mobile app at https://mobile.mapo-int.com. 
  My advice to you... Stay informed, plan effectively, and approach your next vehicle purchase with confidence. This app is packed with amazing features. I easily researched the vehicle that perfectly matched my needs. Effortlessly access precise and up-to-date service costs, maintenance expenses, total cost of ownership, residual values, and even parts pricing — all conveniently from my phone!
  Also, have a look at this YouTube video that shows some of the amazing features included in the app: [insert YouTube video link here].  
  Your dream car is closer than you think. Get the MAPO mobile app now.
  Cheers`;
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const onBackPress = useCallback(() => {
    // dispatch(setScreen(AppScreens.Home));
    history.push("/home");
  }, [dispatch]);

  const loadScreens = useCallback(() => {
    dispatch(showLoader());
    supportGetScreens()
      .then((screensResponse) => {
        if (screensResponse.status === 200) {
          dispatch(hideLoader());
          setScreens(screensResponse.data);
        } else {
          alert(JSON.stringify(screensResponse));
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.status === 400) {
          alert("err");
        } else {
          console.log(err);
          alert(JSON.stringify(err));
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (screens === undefined) {
      loadScreens();
    }
  }, [screens, loadScreens]);

  const loadCategories = useCallback(() => {
    if (selectedScreen) {
      dispatch(showLoader());
      supportGetCategories(selectedScreen)
        .then((categoriesResponse) => {
          if (categoriesResponse.status === 200) {
            dispatch(hideLoader());
            setCategories(categoriesResponse.data);
          } else {
            alert(JSON.stringify(categoriesResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [dispatch, selectedScreen]);

  const loadFAQ = useCallback(() => {
    if (selectedScreen && selectedCategory) {
      dispatch(showLoader());
      supportGetFAQ(selectedScreen, selectedCategory)
        .then((faqResponse) => {
          dispatch(hideLoader());
          if (faqResponse.status === 200) {
            setFaq(faqResponse.data);
          } else {
            alert(JSON.stringify(faqResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [dispatch, selectedCategory, selectedScreen]);

  const submitTicket = useCallback(() => {
    if (selectedScreen && selectedCategory) {
      dispatch(showLoader());
      supportSubmitTicket(
        "sales",
        selectedScreen,
        selectedCategory,
        description,
        gCode,
        mCode,
        vCode,
        sessionState.user?.username ?? ""
      )
        .then((ticketResponse) => {
          if (ticketResponse.status === 200) {
            dispatch(hideLoader());
            setTickets(undefined);
            setTicketsView(true);
          } else {
            alert(JSON.stringify(ticketResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [
    dispatch,
    selectedScreen,
    selectedCategory,
    description,
    gCode,
    mCode,
    vCode,
    sessionState.user,
  ]);

  const loadTickets = useCallback(() => {
    if (sessionState.user) {
      dispatch(showLoader());
      supportGetTickets(sessionState.user?.username ?? "")
        .then((ticketsResponse) => {
          dispatch(hideLoader());
          if (ticketsResponse.status === 200) {
            setTickets(ticketsResponse.data);
            setTicketReplies(undefined);
            setSelectedTicket("");
          } else {
            alert(JSON.stringify(ticketsResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [dispatch, sessionState.user]);

  const loadTicketReplies = useCallback(
    (reference: string) => {
      setSelectedTicket(reference);
      if (sessionState.user) {
        dispatch(showLoader());
        supportGetTicketReplies(sessionState.user?.username ?? "", reference)
          .then((ticketRepliesResponse) => {
            dispatch(hideLoader());
            if (ticketRepliesResponse.status === 200) {
              setTicketReplies(ticketRepliesResponse.data);
            } else {
              alert(JSON.stringify(ticketRepliesResponse));
            }
          })
          .catch((err) => {
            dispatch(hideLoader());
            if (err.status === 400) {
              alert("err");
            } else {
              console.log(err);
              alert(JSON.stringify(err));
            }
          });
      }
    },
    [dispatch, sessionState.user]
  );

  const submitReply = useCallback(() => {
    if (sessionState.user) {
      dispatch(showLoader());
      supportSubmitReply(
        selectedTicket,
        sessionState.user?.username ?? "",
        replyText
      )
        .then((replyResponse) => {
          dispatch(hideLoader());
          if (replyResponse.status === 200) {
            loadTicketReplies(selectedTicket);
            setReplyText("");
          } else {
            alert(JSON.stringify(replyResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [
    dispatch,
    sessionState.user,
    selectedTicket,
    replyText,
    loadTicketReplies,
  ]);

  const closeTicket = useCallback(() => {
    if (sessionState.user) {
      dispatch(showLoader());
      supportCloseTicket(sessionState.user?.username ?? "", selectedTicket)
        .then((closeResponse) => {
          dispatch(hideLoader());
          if (closeResponse.status === 200) {
            setTicketReplies(undefined);
            setTickets(undefined);
            setSelectedTicket("");
          } else {
            alert(JSON.stringify(closeResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          if (err.status === 400) {
            alert("err");
          } else {
            console.log(err);
            alert(JSON.stringify(err));
          }
        });
    }
  }, [dispatch, sessionState.user, selectedTicket, setTicketReplies]);

  useEffect(() => {
    if (categories === undefined && selectedScreen !== undefined) {
      loadCategories();
    }
  }, [selectedScreen, categories, loadCategories]);

  useEffect(() => {
    if (tickets === undefined) {
      loadTickets();
    }
  }, [loadTickets, tickets]);

  const accordGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const accordScreen = useRef<null | HTMLIonAccordionGroupElement>(null);

  const toggleScreenAccordian = () => {
    if (!accordScreen.current) {
      return;
    }
    const natEl = accordScreen.current;

    if (natEl.value === "ScreenAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "ScreenAccord";
    }
  };

  const toggleCategoriesAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "CategoryAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "CategoryAccord";
    }
  };

  const [selectedCheckbox, setSelectedCheckbox] = useState(String);
  const [selectedCategoriesCheckbox, setSelectedCategoriesCheckbox] =
    useState(String);
  const handleActiveModelCheckBox = (key: string) => {
    setSelectedCheckbox(key);
  };
  const handleCategoriesModelCheckBox = (key: string) => {
    setSelectedCategoriesCheckbox(key);
  };
  // const [selectedScreen, setSelectedScreen] = useState(""

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <IonPage className="pageBg">
        <Header></Header>
        <IonContent className="contentPage">
          <div className="innerContent">
            {/* <SubPageHeading
              onBack={onBackPress}
              title={"APP SUPPORT"}
            ></SubPageHeading> */}
            <div className="headHolder">
              <span className="navBack">
                <IonIcon
                  src={chevronBackCircleSharp}
                  className="subPageBackIcon"
                  onClick={onBackPress}
                ></IonIcon>
                APP SUPPORT
              </span>
            </div>
            {!ticketsView ? (
              <>
                <IonRow className="SupportPage">
                  <IonCol size="12">
                    <p className="underline">
                      Please complete as many of the fields as possible in order
                      tor us to reply on your request as accurate as possible.
                    </p>
                    <br />
                    <p className="supportFieldLabel">
                      Is there a specific screen on the App you need <br />{" "}
                      support on?
                    </p>
                    <div className="supportField">
                      {/* <IonSelect
                        className="selectInput"
                        value={selectedScreen}
                        onIonChange={(ev: any) => {
                          setSelectedScreen(ev.target.value);
                          setSelectedCategory(undefined);
                          setCategories(undefined);
                        }}
                        hidden
                      >
                        {screens?.map((screen) => {
                          return (
                            <IonSelectOption value={screen}>
                              {screen}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect> */}
                      <IonAccordionGroup ref={accordScreen}>
                        <IonAccordion
                          className="accordSupport"
                          value="ScreenAccord"
                        >
                          <IonItem slot="header" className="SupportHeader">
                            <span className="ColorBack">
                              <span className="alignSupportWords">
                                Select Page {selectedScreen}
                              </span>
                            </span>
                          </IonItem>

                          <ul slot="content" className="listCont">
                            {screens?.map((e, screen) => {
                              return (
                                <li
                                  title="variant"
                                  className="variantsItems"
                                  // id="varItems"
                                  key={screen}
                                >
                                  <label className="listContVals">
                                    <input
                                      className="radio"
                                      type="checkbox"
                                      onClick={() => toggleScreenAccordian()}
                                      value={e.toString()}
                                      checked={
                                        selectedCheckbox === screen.toString()
                                      }
                                      onChange={(ev: any) => {
                                        setSelectedScreen(ev.target.value);
                                        setSelectedCategory(undefined);
                                        setSelectedCheckbox(screen.toString());
                                        setCategories(undefined);
                                      }}
                                    ></input>
                                    {e}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </IonAccordion>
                      </IonAccordionGroup>
                    </div>
                    <br />
                    <br />

                    <p className="supportFieldLabel">
                      Is there a specific model you have a query on?
                    </p>

                    <div className="supportField">
                      <IonRow>
                        <IonCol
                          size="3"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonInput
                            className="inputSuppor"
                            placeholder="G Code"
                            value={gCode}
                            onIonChange={(ev: any) => setGCode(ev.target.value)}
                          ></IonInput>
                        </IonCol>
                        <IonCol size="1.5"></IonCol>
                        <IonCol
                          size="3"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonInput
                            className="inputSupport"
                            placeholder="M Code"
                            value={mCode}
                            onIonChange={(ev: any) => setMCode(ev.target.value)}
                          ></IonInput>
                        </IonCol>
                        <IonCol size="1.5"></IonCol>
                        <IonCol
                          size="3"
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonInput
                            className="inputSupport"
                            placeholder=" VCode"
                            value={vCode}
                            onIonChange={(ev: any) => setVCode(ev.target.value)}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                    </div>
                    <br />
                    <br />

                    <p className="supportFieldLabel">
                      What is the category of the query?
                    </p>
                    <div className="supportField">
                      {/* <IonSelect
                        className="selectInput"
                        value={selectedCategory}
                        onIonChange={(ev: any) =>
                          setSelectedCategory(ev.target.value)
                        }
                      >
                        {categories?.map((category) => {
                          return (
                            <IonSelectOption value={category}>
                              {category}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect> */}

                      <IonAccordionGroup ref={accordGroup}>
                        <IonAccordion
                          className="accordSupport"
                          value="CategoryAccord"
                        >
                          <IonItem slot="header" className="SupportHeader">
                            <span className="ColorBack">
                              <span className="alignSupportWords">
                                Select related question {selectedCategory}
                              </span>
                            </span>
                          </IonItem>

                          <ul slot="content" className="listCont">
                            {categories?.map((e, category) => {
                              return (
                                <li
                                  title="variant"
                                  className="variantsItems"
                                  id="varItems"
                                  key={category}
                                >
                                  <label className="listContVals">
                                    <input
                                      className="radio"
                                      type="checkbox"
                                      onClick={() =>
                                        toggleCategoriesAccordian()
                                      }
                                      // id={category.toString()}
                                      value={e.toString()}
                                      checked={
                                        selectedCategoriesCheckbox ===
                                        category.toString()
                                      }
                                      onChange={(ev: any) => {
                                        setSelectedCategory(ev.target.value);
                                        setSelectedCategoriesCheckbox(
                                          category.toString()
                                        );
                                      }}
                                    ></input>
                                    {e}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </IonAccordion>
                      </IonAccordionGroup>
                    </div>
                    <br />
                    <br />

                    <p className="supportFieldLabel">
                      Please provide short description on query?
                    </p>
                    <div className="supportField">
                      <IonRow>
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <textarea
                            className="inputTextsupport"
                            placeholder="0/100"
                            value={description}
                            onChange={(ev: any) =>
                              setDescription(ev.target.value)
                            }
                          ></textarea>
                        </IonCol>
                      </IonRow>
                    </div>
                    <IonRow>
                      <IonCol size="6"></IonCol>
                      <IonCol size="6">
                        {/* <Button
                          onPress={() => loadFAQ()}
                          title="SEARCH SUPPORT"
                        /> */}
                        <br />
                        <button
                          onClick={() => loadFAQ()}
                          className="SearchSupport"
                        >
                          SEARCH SUPPORT
                        </button>
                      </IonCol>
                    </IonRow>
                  </IonCol>

                
                </IonRow>
                <IonRow>
                  <IonCol>
                    {faq && faq.length > 0 ? (
                      <IonCol size="5">
                        <p className="FontMobileSpcs">
                          We found similar queries posted to support, please see
                          the responses below.
                        </p>
                        {faq?.map((item) => {
                          return (
                            <IonRow className="underline">
                              <IonCol>
                                <p>
                                  <strong>Q: {item.question}</strong>
                                </p>
                                <p>A: {item.answer}</p>
                              </IonCol>
                            </IonRow>
                          );
                        })}
                        <p className="FontMobileSpcs">
                          If the Q&amp;A above did not provide the answer to
                          your query. Please click "SUBMIT NEW" below.
                        </p>
                        <IonRow>
                          <IonCol size="12">
                            {/* <Button
                              customClass="ion-float-right button"
                              onPress={() => submitTicket()}
                              title="SUBMIT NEW TICKET"
                            /> */}
                            <button
                              onClick={() => submitTicket()}
                              className="SearchSupport"
                            >
                              SUBMIT NEW TICKET
                            </button>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    ) : null}
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <>
                <IonRow>
                  <IonCol size="12">
                    <IonRow>
                      <IonCol className="underline" size="12">
                        <IonRow>
                          <IonCol size="9">
                            <h2>TICKETS</h2>
                          </IonCol>
                          <IonCol size="3">
                            <Button
                              customClass="ion-float-right button"
                              onPress={() => {
                                setTicketsView(false);
                              }}
                              title="NEW TICKET"
                            />
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="1">
                        <strong className="FontTableTicket">ID</strong>
                      </IonCol>
                      <IonCol size="2">
                        <strong className="FontTableTicket">DATE</strong>
                      </IonCol>
                      <IonCol size="3">
                        <strong className="FontTableTicket">CATEGORY</strong>
                      </IonCol>
                      <IonCol size="3">
                        <strong className="FontTableTicket">STATUS</strong>
                      </IonCol>
                      <IonCol size="3">
                        <strong className="FontTableTicket">LAST UPDATE</strong>
                      </IonCol>
                    </IonRow>
                    {tickets?.map((ticket) => {
                      return (
                        <IonRow
                          onClick={() => loadTicketReplies(ticket.reference)}
                        >
                          <IonCol className="FontTableTicket" size="1">
                            {ticket.id}
                          </IonCol>
                          <IonCol className="FontTableTicket" size="2">
                            {moment(new Date(ticket.created_date)).format(
                              "YYYY-MM-DD"
                            )}
                          </IonCol>
                          <IonCol className="FontTableTicket" size="3">
                            {ticket.category}
                          </IonCol>
                          <IonCol className="FontTableTicket" size="3">
                            {ticket.status}
                          </IonCol>
                          <IonCol className="FontTableTicket" size="3">
                            {moment(new Date(ticket.last_update)).format(
                              "YYYY-MM-DD"
                            )}
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonCol>
                  <IonCol size="6">
                    {selectedTicket !== "" ? (
                      <>
                        <IonRow className="underline">
                          <IonCol size="9">
                            <br />
                            <h2 className="FontMobileSpcs">TICKET DETAILS</h2>
                          </IonCol>
                        </IonRow>
                        <br />
                        {ticketReplies?.map((ticket) => {
                          return (
                            <>
                              {ticket.sender === "client" ? (
                                <IonRow>
                                  <IonCol className="FontTableTicket" size="12">
                                    <span className="centerContent">
                                      {moment(new Date(ticket.date)).format(
                                        "YYYY-MM-DD hh:mm"
                                      )}
                                    </span>
                                    <IonRow>
                                      <IonCol
                                        size="7"
                                        className="speechBubbleClient FontMobileSpcs"
                                      >
                                        {ticket.text}
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                </IonRow>
                              ) : (
                                <IonRow>
                                  <IonCol size="12">
                                    <span className="centerContent FontMobileSpcs">
                                      {moment(new Date(ticket.date)).format(
                                        "YYYY-MM-DD hh:mm"
                                      )}
                                    </span>
                                    <IonRow>
                                      <IonCol size="3"></IonCol>
                                      <IonCol
                                        size="6"
                                        className="speechBubbleOwner FontMobileSpcs"
                                      >
                                        {ticket.text}
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                </IonRow>
                              )}
                            </>
                          );
                        })}
                        <br />
                        <IonRow>
                          <IonCol>
                            <div className="supportField">
                              <IonRow>
                                <IonCol
                                  size="12"
                                  className="ion-no-padding ion-no-margin FontMobileSpcs"
                                >
                                  <IonTextarea
                                    className="inputText"
                                    placeholder="Enter reply...."
                                    value={replyText}
                                    onIonChange={(ev: any) =>
                                      setReplyText(ev.target.value)
                                    }
                                  ></IonTextarea>
                                </IonCol>
                              </IonRow>
                            </div>
                            <IonRow>
                              <IonCol size="6">
                                <Button
                                  onPress={() => {
                                    submitReply();
                                  }}
                                  title="REPLY"
                                />
                              </IonCol>
                              <IonCol size="6">
                                <Button
                                  onPress={() => closeTicket()}
                                  title="CLOSE"
                                />
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </>
                    ) : null}
                  </IonCol>
                </IonRow>
              </>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
//};

export default Support;
