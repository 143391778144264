import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { personCircle, logOut, eye, eyeOff } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { images } from '../../assets';
import { Config } from '../../Config';
import { AppScreens } from '../../enums';
import {
  sessionLogout,
  setSelectedOem,
} from '../../redux/session/sessionSlice';
import { RootState } from '../../redux/store';
import {
  hideLoader,
  setCompetitors,
  setCustomReportParams,
  setLargeHeader,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from '../../redux/ui/uiSlice';

import './style.css';
import { Oem } from '../../interfaces';
import { menuOutline } from 'ionicons/icons';
import { setMenuCollapsed } from '../../redux/ui/uiSlice';
import { keepAlive } from '../../api/auth';

interface DesktopHeaderProps {
  //   children?: React.ReactNode;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = () => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const [fullScreen, setFullScreen] = useState(false);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    dispatch(showLoader());
    dispatch(sessionLogout());
    dispatch(setSelectedVehicle(null));
    dispatch(setCompetitors(undefined));
    dispatch(setLargeHeader(false));
    dispatch(
      setCustomReportParams({
        deposit: 0,
        interest: 9.5,
        term: 60,
        residual: 0,
        toggles: {
          finance: true,
          service: true,
          maintenance: true,
          fuel: true,
          insurance: true,
          tyre: true,
          depreciation: true,
        },
        totalLifeSpan: 120000,
        annualMileage: 20000,
        tyreChangeInterval: 0,
      })
    );
    dispatch(hideLoader());
    window.location.href = 'https://za-apihub.mapo-int.com';
  }, [dispatch]);

  const onUserProfile = useCallback(() => {
    dispatch(setScreen(AppScreens.UserProfile));
  }, [dispatch]);

  const onHome = useCallback(() => {
    dispatch(setScreen(AppScreens.BrandSelection));
  }, [dispatch]);

  const onFullScreen = useCallback(() => {
    const myDocument = document.documentElement;
    if (!fullScreen) {
      setFullScreen(true);
      if (myDocument.requestFullscreen) {
        myDocument.requestFullscreen();
      }
      //@ts-ignore
      else if (myDocument.msRequestFullscreen) {
        //@ts-ignore
        myDocument.msRequestFullscreen();
      }
      //@ts-ignore
      else if (myDocument.mozRequestFullScreen) {
        //@ts-ignore
        myDocument.mozRequestFullScreen();
      }
      //@ts-ignore
      else if (myDocument.webkitRequestFullscreen) {
        //@ts-ignore
        myDocument.webkitRequestFullscreen();
      }
    } else {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      //@ts-ignore
      else if (document.msexitFullscreen) {
        //@ts-ignore
        document.msexitFullscreen();
      }
      //@ts-ignore
      else if (document.mozexitFullscreen) {
        //@ts-ignore
        document.mozexitFullscreen();
      }
      //@ts-ignore
      else if (document.webkitexitFullscreen) {
        //@ts-ignore
        document.webkitexitFullscreen();
      }
    }
  }, [fullScreen]);

  const selectOem = useCallback(
    (oem: Oem) => {
      dispatch(setSelectedOem(oem));
      dispatch(setSelectedVehicle(undefined));
      dispatch(setCompetitors(undefined));
      dispatch(
        setCustomReportParams({
          deposit: 0,
          interest: 9.5,
          term: 60,
          residual: 0,
          toggles: {
            finance: true,
            service: true,
            maintenance: true,
            fuel: true,
            insurance: true,
            tyre: true,
            depreciation: true,
          },
          totalLifeSpan: 120000,
          annualMileage: 20000,
          tyreChangeInterval: 60000,
        })
      );
      dispatch(setScreen(AppScreens.Home));
    },
    [dispatch]
  );

  const onToggleMenu = useCallback(() => {
    dispatch(setMenuCollapsed(!uiState.menuCollapsed));
  }, [dispatch, uiState.menuCollapsed]);

  useEffect(() => {
    const timer: any = setInterval(() => setCount(count + 1), 1000);

    if (count % 30 === 0) {
      if (sessionState.user) {
        keepAlive(sessionState.user);
      }
    }

    return () => clearInterval(timer);
  }, [count, sessionState.user]);

  return (
    <div className='desktopHeader'>
      <IonRow
        className='desktopHeaderTop'
        style={
          sessionState.branded
            ? { backgroundColor: sessionState.theme?.header }
            : {}
        }
      >
        <IonCol size='0.5'>
          <img className='saFlag' src={images.saflag} alt='' />
        </IonCol>
        <IonCol size='9.5'>
          <span className='mapoTitle'>MAPO Sales Solution</span>
        </IonCol>
        <IonCol size='2'>
          <IonIcon
            className='headerIcon'
            icon={logOut}
            onClick={onLogout}
          ></IonIcon>
          <IonIcon
            className='headerIcon'
            icon={personCircle}
            onClick={onUserProfile}
          ></IonIcon>
          <IonIcon
            className='headerIcon'
            icon={fullScreen ? eyeOff : eye}
            onClick={onFullScreen}
          ></IonIcon>
        </IonCol>
      </IonRow>
      <IonRow className='desktopHeaderLogo'>
        <IonCol className='logo-col'>
          <div className='logoContainer'>
            <IonButton className='menuButton' onClick={onToggleMenu}>
              <IonIcon icon={menuOutline} className='menuIcon' />
            </IonButton>
            {sessionState.branded ? (
              <img
                onClick={onHome}
                className='menuLogo'
                src={
                  Config.API_SERVER +
                  '/api/theme/getlogo?user=' +
                  sessionState.user?.username
                }
                alt=''
              />
            ) : (
              <img className='menuLogo' src={images.logo} alt='' />
            )}
          </div>
        </IonCol>
        <IonCol size='11.4' className='oemLogos'>
          {uiState.screen === AppScreens.BrandSelection
            ? sessionState.user?.loginResponse?.oems?.map((oem: Oem) => {
                return (
                  <img
                    onClick={() => selectOem(oem)}
                    className='oemLogo'
                    src={
                      Config.API_SERVER +
                      '/api/image/getlogo?gCode=' +
                      oem.g_id.toString()
                    }
                    alt=''
                  />
                );
              })
            : null}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default DesktopHeader;
