import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonRange,
  IonIcon,
  IonToggle,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import Header from "../../components/header";
import MessageModal from "../../components/message-modal";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { SelectedVehicle } from "../../interfaces";
import { RootState } from "../../redux/store";
import { setCustomReportParams, setScreen } from "../../redux/ui/uiSlice";
import { Attributes } from "../../interfaces/modelSpecification";
import { CompetitorData } from "../../interfaces/competitorData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import DesktopChartLegend from "../../components/desktop-chart-legend";
import { format } from "../../utils";
import Button from "../../components/button";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { useHistory } from "react-router-dom";
import DataAssurance from "../../components/dataAssurance";
import "./style.css";
import {
  chevronForward,
  chevronDown,
  chevronUp,
  cogSharp,
  caretBackOutline,
  caretForwardOutline,
  chevronBack,
} from "ionicons/icons";
import { months } from "moment";
import { ServicePlan } from "../../interfaces/servicePlanTotals";
import { partsInfo } from "../../interfaces/maintenancePlanItems";
import VehicleDetail from "../VehicleDetail";
import { useMediaQuery } from "react-responsive";
import Whatsapp from "../../components/whatsapp/whatsapp";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

type PriceInfoItem = {
  iteration: any;
  partName: string;
  nextPrice: number;
  interval: any;
};

type MaintenancePriceInfoItem = {
  iteration: any;
  partName: string;
  Name: string;
  nextPrice: number;
  interval: any;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 45,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const desktopChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  spanGaps: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};
const Desktopoptions = {
  // responsive: false,
  // maintainAspectRatio: false,
  // aspectRatio: 0,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const DesktopChartOptions = {
  // responsive: true,
  // maintainAspectRatio: true,
  spanGaps: true,
  // aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};
const Rates10K: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [tabVisible, setTabVisible] = useState("TABLE");
  const [showServiceItem, setShowServiceItem] = useState(false);
  const [kmFromSlider, setKmFromSlider] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
            .length - 1
        ].interval
      : 0
  );

  useEffect(() => {
    // Initialize a flag to track if the state has been updated
    let stateUpdated = false;

    if (kmToSlider === 300000 && !stateUpdated) {
      // Set kmToSlider to 310000
      setKmToSlider(310000);
      // Set the flag to true to indicate that the state has been updated
      stateUpdated = true;
    }
  }, [kmToSlider]);

  let totalMaintenance = 0;
  let totalservice = 0;

  const servicePlanMonths =
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan_months;
  const ServicePlanKm =
    Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    ) || 0;
  // If ServicePlanKm is 999999, treat it as zero
  const adjustedServicePlanKm = ServicePlanKm === 999999 ? 0 : ServicePlanKm;
  const servicePlanYears = Number(servicePlanMonths) / 12;

  const [TotalDistanceTravelKm, setTravelKm] = useState(0);
  const [rangeValue, setRangeValue] = useState(25000);
  const [Inflation, setInflationValue] = useState<number>(0);
  const handleInflationChange = (e: any) => {
    const Value = e.detail.value;
    setInflationValue(Value);
  };

  const handleRangeChange = (event: any) => {
    const newRangeValue = parseInt(event.target.value, 10);
    setRangeValue(newRangeValue);

    const servicePlanMonths =
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan_months;
    const ServicePlanKm = Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    );

    // const remainingServiceKm = Number(servicePlanMonths) / 12;
    const servicePlanYears = Number(servicePlanMonths) / 12;
    const distanceTravelled = servicePlanYears * rangeValue;

    //The Km travelled in a year

    setTravelKm(distanceTravelled);
  };

  useEffect(() => {
    const distanceTravelled = servicePlanYears * rangeValue;
    setTravelKm(distanceTravelled);
  }, [servicePlanYears, rangeValue]);

  const onModalPress = useCallback(() => {
    setModalVisible(false);
  }, []);

  // const onMorePress = useCallback(() => {
  //   setModalVisible(true);
  // }, []); I want the table to slide according to sliderValue

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const getChartIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 50; i++) {
      const fromValue = i * 10;
      const toValue = i * 10 + 10;
      if (i * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(fromValue + "k - " + toValue + "k");
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getDataset = useCallback(
    (col: number) => {
      const data: Array<number> = [];
      getData(col)?.rates10kIntervalData?.map((interval, index) => {
        if (index * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
          data.push(interval.maintenanceCostTotal + interval.serviceCostTotal);
        }
      });
      return data;
    },
    [getData, uiState?.customReportParams]
  );

  const getDesktopChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
        },
        {
          label:
            getData(1)?.modelSpecification.attributes.make +
            " " +
            getData(1)?.modelSpecification.attributes.model,
          data: getDataset(1),
          borderColor: "#ff2a2a",
          backgroundColor: "#ff2a2a",
          borderWidth: 5,
        },
        {
          label:
            getData(2)?.modelSpecification.attributes.make +
            " " +
            getData(2)?.modelSpecification.attributes.model,
          data: getDataset(2),
          borderColor: "#fc8f00",
          backgroundColor: "#fc8f00",
          borderWidth: 5,
        },
        {
          label:
            getData(3)?.modelSpecification.attributes.make +
            " " +
            getData(3)?.modelSpecification.attributes.model,
          data: getDataset(3),
          borderColor: "#00df17",
          backgroundColor: "#00df17",
          borderWidth: 5,
        },
      ],
    };
    return desktopChartData;
  }, [getDataset, getData, getChartIntervals]);

  const getAccumulativeDataset = useCallback(
    (index: number) => {
      const data: Array<number> = [];
      let total = 0;
      getData(index)?.rates10kIntervalData?.map((interval) => {
        if (
          interval.kmPerYear < (uiState?.customReportParams?.totalLifeSpan ?? 0)
        ) {
          total =
            total + interval.maintenanceCostTotal + interval.serviceCostTotal;
          data.push(total);
        }
      });
      return data;
    },
    [getData, uiState?.customReportParams]
  );

  const getDesktopAccumaltiveChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getAccumulativeDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
        },
        {
          label:
            getData(1)?.modelSpecification.attributes.make +
            " " +
            getData(1)?.modelSpecification.attributes.model,
          data: getAccumulativeDataset(1),
          borderColor: "#ff2a2a",
          backgroundColor: "#ff2a2a",
        },
        {
          label:
            getData(2)?.modelSpecification.attributes.make +
            " " +
            getData(2)?.modelSpecification.attributes.model,
          data: getAccumulativeDataset(2),
          borderColor: "#fc8f00",
          backgroundColor: "#fc8f00",
        },
        {
          label:
            getData(3)?.modelSpecification.attributes.make +
            " " +
            getData(3)?.modelSpecification.attributes.model,
          data: getAccumulativeDataset(3),
          borderColor: "#00df17",
          backgroundColor: "#00df17",
        },
      ],
    };
    return desktopChartData;
  }, [getAccumulativeDataset, getData, getChartIntervals]);

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: "",
    };
    return vehicle;
  };

  const getIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 29; i++) {
      const fromValue = i * 10;
      const toValue = i * 10 + 10;
      if (i * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(fromValue + "k - " + toValue + "k");
      }
    }
    return list;
  }, [uiState?.customReportParams]);

  const underServicePlan = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return true;
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return true;
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );

  const underMaintenancePlan = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return "MP";
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return "MP";
        } else {
          return "";
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return "MP";
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return "MP";
        } else {
          return "";
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );
  const gtTotal = useCallback((vehicle: number) => {
    let total = 0;
  }, []);

  const getTotal = useCallback(
    (vehicleIndex: number, column: number) => {
      let total = 0;
      getData(vehicleIndex)?.rates10kIntervalData?.map((interval, index) => {
        if (index * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
          if (column === 0) {
            total += underServicePlan(vehicleIndex, index * 10000)
              ? 0
              : interval.serviceCostTotal;
          } else if (column === 1) {
            total += underMaintenancePlan(vehicleIndex, index * 10000)
              ? 0
              : interval.maintenanceCostTotal;
          } else if (column === 2) {
            total += interval.maintenanceCostTotal + interval.serviceCostTotal;
          } else if (column === 3) {
            total +=
              (interval.maintenanceCostTotal + interval.serviceCostTotal) /
              10000;
          }
        }
      });
      return total;
    },
    [
      getData,
      uiState?.customReportParams,
      underServicePlan,
      underMaintenancePlan,
    ]
  );

  const getMobileDataset = useCallback(
    (service: boolean) => {
      const data: Array<number> = [];
      let total = 0;
      getData(0)?.rates10kIntervalData?.map((interval) => {
        if (
          interval.kmPerYear < (uiState?.customReportParams?.totalLifeSpan ?? 0)
        ) {
          if (service) {
            total = total + interval.serviceCostTotal;
          } else {
            total = total + interval.maintenanceCostTotal;
          }
          data.push(total);
        }
      });
      return data;
    },
    [getData, uiState?.customReportParams]
  );

  const getMobileChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label: "Service Costs",
          data: getMobileDataset(true),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 6,
        },
        {
          label: "Maintenance Costs",
          data: getMobileDataset(false),
          borderColor: "#ff2a2a",
          backgroundColor: "#ff2a2a",
          borderWidth: 6,
        },
      ],
    };
    return desktopChartData;
  }, [getMobileDataset, getChartIntervals]);

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
    }
  }, [initialised]);

  const onLogOut = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.Login));
      history.push("/login");
    }, 300);
  }, [dispatch]);

  const [sliderValue, setSliderValue] = useState(120000);
  const maxSliderValue = 300000;
  const handleSliderChange = (e: any) => {
    setSliderValue(e.detail.value);

    dispatch(
      setCustomReportParams({
        ...uiState?.customReportParams,
        totalLifeSpan: Number(e.target.value ?? "0"),
      })
    );
  };

  const [totals, setTotals] = useState({
    service: 0,
    maintenance: 0,
    total: 0,
    rateTotal: 0,
  });

  // useEffect(() => {
  //   // Calculate totals based on the current slider value
  //   const serviceTotal =
  //     getData(0)
  //       ?.rates10kIntervalData?.filter(
  //         (interval, index) => index * 10000 <= sliderValue
  //       )
  //       .reduce((acc, interval) => acc + interval.serviceCostTotal, 0) || 0;

  //   const maintenanceTotal =
  //     getData(0)
  //       ?.rates10kIntervalData?.filter(
  //         (interval, index) => index * 10000 <= sliderValue
  //       )
  //       .reduce((acc, interval) => acc + interval.maintenanceCostTotal, 0) || 0;

  //   const rateTotal =
  //     getData(0)
  //       ?.rates10kIntervalData?.filter(
  //         (interval, index) => index * 10000 <= sliderValue
  //       )
  //       .reduce(
  //         (acc, interval) =>
  //           acc +
  //           (interval.serviceCostTotal + interval.maintenanceCostTotal) / 10000,
  //         0
  //       ) || 0;

  //   const overallTotal = serviceTotal + maintenanceTotal;

  //   // Update the state with the new totals
  //   setTotals({
  //     service: serviceTotal,
  //     maintenance: maintenanceTotal,
  //     total: overallTotal,
  //     rateTotal,
  //   });
  // }, [sliderValue]);

  const [showPlan, setShowPlan] = useState(false);
  const [showServiceData, setShowServiceData] = useState(false);
  const [showTenkData, setshowTenK] = useState(true);
  const [showBarDetail, setShowBarDetail] = useState(false);

  function renderServicePlans(servicePlans: any) {
    return servicePlans?.map((servicePlan: any, index: any) => (
      <span key={index} className="">
        {servicePlan.interval}
      </span>
    ));
  }

  const [range, setRange] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setRange({ lower: 10, upper: 20 });
      }, 10);

      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  //The 10K rates to work as the
  const LabourRates = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].hourlyRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const SundriesRate = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].sundriesRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const calculateMonth = (interval: any) => {
    const distancePerYear = rangeValue;

    const months = Math.floor(interval / distancePerYear) * 12;

    return months;
  };

  // Calculate the years
  function calculateYearsLabel(interval: any) {
    const months = calculateMonth(interval);

    return months;
  }

  //calculating the Inflation rate

  const intervalHolder: Array<number> = [];

  uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
    (v, i) => {
      intervalHolder.push(v.attributes["interval"]);
    }
  );

  // For the parts
  const [priceInfo, setPriceInfo] = useState<PriceInfoItem[][]>([]);

  //console.log("The parts price", priceInfo);

  const initialPartsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);
  //
  useEffect(() => {
    if (initialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;

            const PartQuantity = part.quantity;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price * PartQuantity;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(priceInfo)) {
        setPriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialPartsArray]);

  //For the fluids

  const [FluidsInfo, setFluidsInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The fluid price", FluidsInfo)
  const initialFluidsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.fluidsInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialFluidsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialFluidsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(FluidsInfo)) {
        setFluidsInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialFluidsArray]);

  //Calculating the inflation for the  Labour

  const [LabourInfo, setLabourInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The labour rate",LabourInfo)

  const initialLabourArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(LabourInfo)) {
        setLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  //Calculating the inflation for the  Labour

  const [SundriesInfo, setSundriesInfo] = useState<PriceInfoItem[][]>([]);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult: any, index: any) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = SundriesRate(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(SundriesInfo)) {
        setSundriesInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  const filteredPlanInfo =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo
          ?.map((part: any) => {
            const matchingPriceInfo = priceInfo.flat().filter((priceItem) => {
              return (
                priceItem.partName === part?.partNumber &&
                priceItem.interval === planItem.attributes?.interval
              );
            });

            return matchingPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the fluids
        const fluidNextPrices = planItem.fluidsInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = FluidsInfo.flat().filter(
              (priceItem) => {
                return (
                  priceItem.partName === fluid?.oemPartNumber &&
                  priceItem.interval === planItem.attributes?.interval
                );
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the Labour
        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = LabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        //Sundries Rates
        const SundriesNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = SundriesInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item: any) => item.nextPrice);
          })
          .flat();

        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalFluidPrices = fluidNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalSundriesPrices = SundriesNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          fluidNextPrices: fluidNextPrices,
          totalPartPrices: totalPartPrices,
          totalFluidPrices: totalFluidPrices,
          totalLabourPrices: totalLabourPrices,
          totalSundriesPrices: totalSundriesPrices,
        };
      }
    );

  const intervalHoldr: Array<number> = [];

  uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo.forEach(
    (v, i) => {
      intervalHoldr.push(v.attributes["interval"]);
    }
  );

  const [MaintenancepriceInfo, setMaintenancePriceInfo] = useState<
    MaintenancePriceInfoItem[][]
  >([]);

  //console.log("The maintaince parts price", priceInfo);

  const MaintenceinitialPartsArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (MaintenceinitialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: MaintenancePriceInfoItem[][] = [];

      MaintenceinitialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;
            const PartSubName = part.basketSubName;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: MaintenancePriceInfoItem[] = [];

              for (let i = 0; i < intervalHoldr.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHoldr[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHoldr[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: nextPrice,
                  interval: intervalHoldr[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (
        JSON.stringify(newPriceInfo) !== JSON.stringify(MaintenancepriceInfo)
      ) {
        setMaintenancePriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialPartsArray]);

  const [MaintenanceLabourInfo, setMaintenanceLabourInfo] = useState<
    PriceInfoItem[][]
  >([]);

  // The selecting
  const MaintenanceinitialLabourArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (MaintenanceinitialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: MaintenancePriceInfoItem[][] = [];

      MaintenanceinitialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              const PartSubName = part.basketSubName;
              let LabournextPrice;

              const calculatedPrices: MaintenancePriceInfoItem[] = [];

              for (let i = 0; i < intervalHoldr.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHoldr[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHoldr[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: LabournextPrice,
                  interval: intervalHoldr[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (
        JSON.stringify(newPriceInfo) !== JSON.stringify(MaintenanceLabourInfo)
      ) {
        setMaintenanceLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  const MaintanncefilteredPlanInfo =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo
          ?.map((part: any) => {
            const matchingPriceInfo = MaintenancepriceInfo.flat().filter(
              (priceItem) => {
                return (
                  priceItem.partName === part?.partNumber &&
                  priceItem.interval === planItem.attributes?.interval
                );
              }
            );

            return matchingPriceInfo.map((item) => ({
              nextPrice: item.nextPrice,
              Name: item.Name, // Adding Name next to the nextPrice
            }));
          })
          .flat();
        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price.nextPrice, // Change here to access nextPrice
          0
        );

        //For the labour

        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = MaintenanceLabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          totalPartPrices: totalPartPrices,
          totalLabourPrices: totalLabourPrices,
        };
      }
    );

  // Function to organize rates into intervals
  function organizeRatesIntoIntervals(filteredPlanInfo: any[] | undefined) {
    const intervals: any[][] = [];

    for (let i = 0; i < 31; i++) {
      intervals.push([]);
    }

    // Push rates into corresponding intervals
    filteredPlanInfo?.forEach(
      (Rates: { attributes: { interval: number | undefined } }) => {
        if (
          Rates &&
          Rates.attributes &&
          Rates.attributes.interval !== undefined
        ) {
          const intervalIndex = Math.floor(Rates.attributes.interval / 10000);
          if (intervals[intervalIndex]) {
            intervals[intervalIndex].push(Rates);
          }
        }
      }
    );

    return intervals;
  }

  const intervals = organizeRatesIntoIntervals(filteredPlanInfo);

  //The function for bar chart
  const getChartData = useCallback(() => {
    const labels: string[] = [];
    const maintenance: number[] = [];
    const service: number[] = [];

    intervals.map((intervalRates, index) => {
      const intervalStart = index * 10000;
      const intervalEnd = (index + 1) * 10000;

      // Check if the interval meets the condition
      if (intervalStart >= kmFromSlider && intervalEnd <= kmToSlider) {
        // Push interval start as label
        labels.push(`${intervalStart}`);

        let maintenanceDataList = [];

        if (MaintanncefilteredPlanInfo) {
          for (let i = 0; i < MaintanncefilteredPlanInfo.length; i++) {
            const data = MaintanncefilteredPlanInfo[i];

            // Check if the interval of the data falls within the current interval
            if (
              data.attributes.interval >= intervalStart &&
              data.attributes.interval < intervalEnd
            ) {
              maintenanceDataList.push(data);
              // Note: We don't break here, so that all matching values within the range are collected
            }
          }
        }

        // Calculate maintenance total
        let maintenancTotal = 0;

        if (maintenanceDataList.length > 0) {
          maintenancTotal = maintenanceDataList.reduce((acc, curr) => {
            const maintenanceValue = showTenkData
              ? Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.maintenance_plan ?? "0"
                ) >= curr?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan ?? "0"
                  ) === 1000000
                  ? (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                  : Number(
                      (Number(curr?.attributes.interval) / rangeValue) * 12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.maintenance_plan_months
                    )
                  ? (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                  : 0
                : (curr.totalPartPrices || 0) +
                  (curr.totalLabourPrices || 0) *
                    (curr.labourInfo[0]?.labourQuantity || 0)
              : (curr.totalPartPrices || 0) +
                (curr.totalLabourPrices || 0) *
                  (curr.labourInfo[0]?.labourQuantity || 0);

            return acc + maintenanceValue;
          }, 0);
        }

        let maintenanceData;

        if (MaintanncefilteredPlanInfo) {
          for (let i = 0; i < MaintanncefilteredPlanInfo.length; i++) {
            const data = MaintanncefilteredPlanInfo[i];

            if (
              data.attributes.interval >= intervalStart &&
              data.attributes.interval < intervalEnd
            ) {
              maintenanceData = data;
              break;
            }
          }
        }

        // Calculate maintenance total
        let maintenanceTotal = 0;

        if (maintenanceData) {
          maintenanceTotal = 0;
          if (intervalStart >= kmFromSlider && intervalEnd <= kmToSlider) {
            maintenanceTotal = maintenanceDataList.reduce((acc, curr) => {
              const maintenanceValue = showTenkData
                ? Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan ?? "0"
                  ) >= curr?.attributes.interval
                  ? 0 ||
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.maintenance_plan ?? "0"
                    ) === 1000000
                    ? (curr.totalPartPrices || 0) +
                      (curr.totalLabourPrices || 0) *
                        (curr.labourInfo[0]?.labourQuantity || 0)
                    : Number(
                        (Number(curr?.attributes.interval) / rangeValue) * 12
                      ) >
                      Number(
                        uiState.selectedVehicle?.data?.modelSpecification.result
                          .service_maintenance_warranty.maintenance_plan_months
                      )
                    ? (curr.totalPartPrices || 0) +
                      (curr.totalLabourPrices || 0) *
                        (curr.labourInfo[0]?.labourQuantity || 0)
                    : 0
                  : (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                : (curr.totalPartPrices || 0) +
                  (curr.totalLabourPrices || 0) *
                    (curr.labourInfo[0]?.labourQuantity || 0);
              return acc + maintenanceValue;
            }, 0);
          }
        }

        totalMaintenance += maintenanceTotal;

        //Calculate service
        const intervalRatesTotal =
          intervalRates.length > 0
            ? intervalRates.reduce((acc, Rates) => {
                if (
                  intervalStart >= kmFromSlider &&
                  intervalEnd <= kmToSlider
                ) {
                  acc += showTenkData
                    ? Number(
                        uiState.selectedVehicle?.data?.modelSpecification.result
                          .service_maintenance_warranty.service_plan ?? "0"
                      ) >= Rates?.attributes.interval
                      ? 0 ||
                        Number(
                          uiState.selectedVehicle?.data?.modelSpecification
                            .result.service_maintenance_warranty.service_plan ??
                            "0"
                        ) === 999999
                        ? Rates.totalPartPrices +
                          Rates.totalLabourPrices *
                            Rates.labourInfo[0].labourQuantity +
                          Rates.totalFluidPrices +
                          Rates.totalSundriesPrices
                        : Number(
                            (Number(Rates?.attributes.interval) / rangeValue) *
                              12
                          ) >
                          Number(
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty
                              .service_plan_months
                          )
                        ? Rates.totalPartPrices +
                          Rates.totalLabourPrices *
                            Rates.labourInfo[0].labourQuantity +
                          Rates.totalFluidPrices +
                          Rates.totalSundriesPrices
                        : 0
                      : Rates.totalPartPrices +
                        Rates.totalLabourPrices *
                          Rates.labourInfo[0].labourQuantity +
                        Rates.totalFluidPrices +
                        Rates.totalSundriesPrices
                    : Rates.totalPartPrices +
                      Rates.totalLabourPrices *
                        Rates.labourInfo[0].labourQuantity +
                      Rates.totalFluidPrices +
                      Rates.totalSundriesPrices;
                }
                return acc;
              }, 0)
            : 0;

        totalservice += intervalRatesTotal;

        // Push maintenance and service data
        maintenance.push(maintenanceTotal);
        service.push(intervalRatesTotal);
      }
    });

    const data = {
      labels,
      datasets: [
        {
          label: "Maintenance",
          backgroundColor: "rgb(106, 178, 44)",
          borderWidth: 0,
          data: maintenance,
        },
        {
          label: "Service",

          backgroundColor: "rgb(52, 61, 235)",
          borderWidth: 0,
          data: service,
        },
      ],
    };

    return data;
  }, [
    // Your existing dependencies
    intervals,
    MaintanncefilteredPlanInfo,
    kmFromSlider,
    kmToSlider,
    showTenkData,
    TotalDistanceTravelKm,
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.maintenance_plan,
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan,
  ]);

  const getChartDataAccumulative = useCallback(() => {
    const labels: string[] = [];
    const maintenance: number[] = [];
    const service: number[] = [];

    let maintenanceAccumulative = 0;
    let serviceAccumulative = 0;

    intervals.map((intervalRates, index) => {
      const intervalStart = index * 10000;
      const intervalEnd = (index + 1) * 10000;

      // Check if the interval meets the condition
      if (intervalStart >= kmFromSlider && intervalEnd <= kmToSlider) {
        // Push interval start as label
        labels.push(`${intervalStart}`);

        let maintenanceDataList = [];

        if (MaintanncefilteredPlanInfo) {
          for (let i = 0; i < MaintanncefilteredPlanInfo.length; i++) {
            const data = MaintanncefilteredPlanInfo[i];

            // Check if the interval of the data falls within the current interval
            if (
              data.attributes.interval >= intervalStart &&
              data.attributes.interval < intervalEnd
            ) {
              maintenanceDataList.push(data);
              // Note: We don't break here, so that all matching values within the range are collected
            }
          }
        }

        // Calculate maintenance total
        let maintenancTotal = 0;

        if (maintenanceDataList.length > 0) {
          maintenancTotal = maintenanceDataList.reduce((acc, curr) => {
            const maintenanceValue = showTenkData
              ? Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.maintenance_plan ?? "0"
                ) >= curr?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan ?? "0"
                  ) === 1000000
                  ? (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                  : Number(
                      (Number(curr?.attributes.interval) / rangeValue) * 12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.maintenance_plan_months
                    )
                  ? (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                  : 0
                : (curr.totalPartPrices || 0) +
                  (curr.totalLabourPrices || 0) *
                    (curr.labourInfo[0]?.labourQuantity || 0)
              : (curr.totalPartPrices || 0) +
                (curr.totalLabourPrices || 0) *
                  (curr.labourInfo[0]?.labourQuantity || 0);

            return acc + maintenanceValue;
          }, 0);
        }

        let maintenanceData;

        if (MaintanncefilteredPlanInfo) {
          for (let i = 0; i < MaintanncefilteredPlanInfo.length; i++) {
            const data = MaintanncefilteredPlanInfo[i];

            if (
              data.attributes.interval >= intervalStart &&
              data.attributes.interval < intervalEnd
            ) {
              maintenanceData = data;
              break;
            }
          }
        }

        // Calculate maintenance total
        let maintenanceTotal = 0;

        if (maintenanceData) {
          maintenanceTotal = 0;
          if (intervalStart >= kmFromSlider && intervalEnd <= kmToSlider) {
            maintenanceTotal = maintenanceDataList.reduce((acc, curr) => {
              const maintenanceValue = showTenkData
                ? Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan ?? "0"
                  ) >= curr?.attributes.interval
                  ? 0 ||
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.maintenance_plan ?? "0"
                    ) === 1000000
                    ? (curr.totalPartPrices || 0) +
                      (curr.totalLabourPrices || 0) *
                        (curr.labourInfo[0]?.labourQuantity || 0)
                    : Number(
                        (Number(curr?.attributes.interval) / rangeValue) * 12
                      ) >
                      Number(
                        uiState.selectedVehicle?.data?.modelSpecification.result
                          .service_maintenance_warranty.maintenance_plan_months
                      )
                    ? (curr.totalPartPrices || 0) +
                      (curr.totalLabourPrices || 0) *
                        (curr.labourInfo[0]?.labourQuantity || 0)
                    : 0
                  : (curr.totalPartPrices || 0) +
                    (curr.totalLabourPrices || 0) *
                      (curr.labourInfo[0]?.labourQuantity || 0)
                : (curr.totalPartPrices || 0) +
                  (curr.totalLabourPrices || 0) *
                    (curr.labourInfo[0]?.labourQuantity || 0);
              return acc + maintenanceValue;
            }, 0);
          }
        }

        totalMaintenance += maintenanceTotal;

        maintenanceAccumulative += maintenanceTotal;

        // Calculate service total
        const intervalRatesTotal =
          intervalRates.length > 0
            ? intervalRates.reduce((acc, Rates) => {
                if (
                  intervalStart >= kmFromSlider &&
                  intervalEnd <= kmToSlider
                ) {
                  acc += showTenkData
                    ? // ? TotalDistanceTravelKm >= Rates?.attributes.interval
                      //   ? 0
                      //   : Number(
                      //       uiState.selectedVehicle?.data?.modelSpecification
                      //         .result.service_maintenance_warranty.service_plan ??
                      //         "0"
                      //     ) >= Rates?.attributes.interval
                      //   ? 0 ||
                      //     Number(
                      //       uiState.selectedVehicle?.data?.modelSpecification
                      //         .result.service_maintenance_warranty.service_plan ??
                      //         "0"
                      //     ) === 999999
                      //     ? Rates.totalPartPrices +
                      //       Rates.totalLabourPrices *
                      //         Rates.labourInfo[0].labourQuantity +
                      //       Rates.totalFluidPrices +
                      //       Rates.totalSundriesPrices
                      //     : 0
                      //   : Rates.totalPartPrices +
                      //     Rates.totalLabourPrices *
                      //       Rates.labourInfo[0].labourQuantity +
                      //     Rates.totalFluidPrices +
                      //     Rates.totalSundriesPrices
                      // : Rates.totalPartPrices +
                      //   Rates.totalLabourPrices *
                      //     Rates.labourInfo[0].labourQuantity +
                      //   Rates.totalFluidPrices +
                      //   Rates.totalSundriesPrices;
                      Number(
                        uiState.selectedVehicle?.data?.modelSpecification.result
                          .service_maintenance_warranty.service_plan ?? "0"
                      ) >= Rates?.attributes.interval
                      ? 0 ||
                        Number(
                          uiState.selectedVehicle?.data?.modelSpecification
                            .result.service_maintenance_warranty.service_plan ??
                            "0"
                        ) === 999999
                        ? Rates.totalPartPrices +
                          Rates.totalLabourPrices *
                            Rates.labourInfo[0].labourQuantity +
                          Rates.totalFluidPrices +
                          Rates.totalSundriesPrices
                        : Number(
                            (Number(Rates?.attributes.interval) / rangeValue) *
                              12
                          ) >
                          Number(
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty
                              .service_plan_months
                          )
                        ? Rates.totalPartPrices +
                          Rates.totalLabourPrices *
                            Rates.labourInfo[0].labourQuantity +
                          Rates.totalFluidPrices +
                          Rates.totalSundriesPrices
                        : 0
                      : Rates.totalPartPrices +
                        Rates.totalLabourPrices *
                          Rates.labourInfo[0].labourQuantity +
                        Rates.totalFluidPrices +
                        Rates.totalSundriesPrices
                    : Rates.totalPartPrices +
                      Rates.totalLabourPrices *
                        Rates.labourInfo[0].labourQuantity +
                      Rates.totalFluidPrices +
                      Rates.totalSundriesPrices;
                }
                return acc;
              }, 0)
            : 0;

        serviceAccumulative += intervalRatesTotal;

        // Push accumulative maintenance and service data
        maintenance.push(maintenanceAccumulative);
        service.push(serviceAccumulative);
      }
    });

    const data = {
      labels,
      datasets: [
        {
          label: "Maintenance",
          backgroundColor: "rgb(106, 178, 44)",
          borderColor: "rgb(106, 178, 44)",
          data: maintenance,
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
        {
          label: "Service",
          backgroundColor: "rgb(52, 61, 235)",
          borderColor: "rgb(52, 61, 235)",
          data: service,

          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };

    return data;
  }, [
    // Your existing dependencies
    intervals,
    MaintanncefilteredPlanInfo,
    kmFromSlider,
    kmToSlider,
    showTenkData,
    TotalDistanceTravelKm,
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.maintenance_plan,
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan,
  ]);

  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  let touchStartX = 0;

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  const totalServiceCosPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team.\n\n I have a question on the 10K RATES TABLE screen of the\n\n  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} \n\n ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      } \n\n  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} \n\n*My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const dataSourceFile = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].dataSource;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [calcDuration, setCalcDuration] = useState(0);

  useEffect(() => {
    setCalcDuration(Number(kmToSlider - kmFromSlider));
  }, [kmFromSlider, kmToSlider]);

  return (
    <>
      <GlobalGrid
        currentPageTitle="10K RATES TABLE"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="detailContents">
          {sessionState.user?.username === "" ? (
            <GlobalGrid
              currentPageTitle="10K RATES TABLE"
              closeCurrentPage={() => onBackPress()}
            >
              <div className="innerContent">
                <SubPageHeading
                  onBack={onBackPress}
                  vehicle={uiState.selectedVehicle}
                ></SubPageHeading>
                <p className="Subscription" onClick={onLogOut}>
                  Create Account or Log in
                </p>
              </div>
            </GlobalGrid>
          ) : (
            <>
              <IonRow style={{ width: "100%" }}>
                <SubPageHeading
                  onBack={onBackPress}
                  vehicle={uiState.selectedVehicle}
                ></SubPageHeading>
              </IonRow>

              <div className="genericHolder">
                <IonRow className="planDropDown">
                  <span className="dropDownIcon">
                    <IonIcon
                      icon={showPlan == false ? chevronForward : chevronDown}
                      onClick={() => {
                        setShowPlan(!showPlan);
                        handleButtonClick();
                      }}
                    ></IonIcon>
                  </span>
                  <span className="textDropDown">LIFESPAN SETTINGS</span>
                  <span className="activeState">
                    {showTenkData ? "ACTIVATED" : "DEACTIVATED"}
                  </span>
                  <div className="toggleState">
                    <IonToggle
                      checked={showTenkData}
                      className="ServiceCostToggleBtn"
                      onIonChange={(e) => {
                        setshowTenK(!showTenkData);
                      }}
                    />
                  </div>
                </IonRow>
                {showPlan && (
                  <section className="sliderContainer">
                    <span id="sliderContainerACtual">
                      <div className="digi">
                        <span>
                          FROM:
                          <h1>{kmFromSlider}</h1>
                        </span>
                        <span id="digiRight">
                          TO:
                          <h1>{kmToSlider - 10000}</h1>
                        </span>
                      </div>

                      <IonRange
                        id="slider"
                        aria-label="Dual Knobs Range"
                        dualKnobs={true}
                        min={0}
                        max={
                          // uiState.selectedVehicle?.data?.maintenancePlanTotals
                          //   ?.result?.maintenancePlans &&
                          // uiState.selectedVehicle?.data?.maintenancePlanTotals
                          //   ?.result?.maintenancePlans?.length > 0
                          //   ? uiState.selectedVehicle?.data?.maintenancePlanTotals
                          //       ?.result?.maintenancePlans[
                          //       uiState.selectedVehicle?.data
                          //         ?.maintenancePlanTotals?.result
                          //         ?.maintenancePlans.length - 1
                          //     ].interval
                          //   : 0
                          310000
                        }
                        step={1000}
                        onIonChange={(ev: any) => {
                          const lowerValue = isNaN(ev.detail.value.lower)
                            ? 0
                            : ev.detail.value.lower;
                          const upperValue = isNaN(ev.detail.value.upper)
                            ? 0
                            : ev.detail.value.upper;
                          setKmToSlider(upperValue);
                          setKmFromSlider(lowerValue);
                        }}
                        value={{
                          upper: kmToSlider,
                          lower: kmFromSlider,
                        }}
                      ></IonRange>
                      <div className="digi">
                        <span>TRAVELLING:</span>
                        <span id="digiRight">
                          <h1>{rangeValue}</h1>
                          /YR
                        </span>
                      </div>

                      <IonRange
                        id="slider"
                        step={500}
                        min={2500}
                        max={100000}
                        value={rangeValue}
                        onIonChange={handleRangeChange}
                      ></IonRange>
                      <div className="digi">
                        <span>ANNUAL PRICE INCREASE</span>
                        <span id="digiRight">
                          <h1>{Inflation}</h1>%
                        </span>
                      </div>

                      <IonRange
                        id="slider"
                        step={0.25}
                        min={0}
                        max={30}
                        value={Inflation}
                        onIonChange={handleInflationChange}
                      ></IonRange>
                    </span>
                  </section>
                )}

                <IonRow className="hiddenPlanDetails">
                  <div>
                    <span id="actualPlans" className="ActualPlanDetails">
                      The report below covers a lifespan of{" "}
                      <span style={{ color: "red" }}>{calcDuration}</span>{" "}
                      kilometres or{" "}
                      <span style={{ color: "red" }}>
                        {Number(
                          (Number(calcDuration) / rangeValue) * 12
                        ).toFixed(0)}
                      </span>{" "}
                      months. The cost calculation below has the mentioned
                      service and maintenance plan{" "}
                      <span style={{ color: "red" }}>
                        {showTenkData ? "activated" : "deactivated"}
                      </span>{" "}
                      and includes an annual price increase of
                      <span style={{ color: "red" }}> {Inflation}%</span>
                    </span>
                  </div>
                </IonRow>
              </div>
              <br />
              <div className="scrollable">
                <IonGrid>
                  {/* On mobile, display components in a row */}
                  <IonRow>
                    <IonCol
                      size="11.5"
                      sizeXs="11.5"
                      sizeMd="11.5"
                      sizeLg="12"
                      sizeXl="3.8"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol size="2.5" className="gridhead">
                            <span className="FontMobileSpecs">Interval </span>
                          </IonCol>
                          <IonCol size="2.1" className="gridhead">
                            <span className="FontMobileSpecs"> Service </span>
                          </IonCol>
                          <IonCol size="3.4" className="gridhead">
                            <span className="FontMobileSpecs">
                              {" "}
                              Maintenance{" "}
                            </span>
                          </IonCol>
                          <IonCol size="2.3" className="gridhead">
                            <span className="FontMobileSpecs"> Total </span>
                          </IonCol>
                          <IonCol size="1.7" className="gridhead">
                            <span className="FontMobileSpecs"> /Km </span>
                          </IonCol>
                        </IonRow>

                        {intervals.map((intervalRates, index) => {
                          const intervalStart = index * 10000;
                          const intervalEnd = (index + 1) * 10000;

                          let maintenanceDataList = [];

                          if (MaintanncefilteredPlanInfo) {
                            for (
                              let i = 0;
                              i < MaintanncefilteredPlanInfo.length;
                              i++
                            ) {
                              const data = MaintanncefilteredPlanInfo[i];

                              // Check if the interval of the data falls within the current interval
                              if (
                                data.attributes.interval >= intervalStart &&
                                data.attributes.interval < intervalEnd
                              ) {
                                maintenanceDataList.push(data);
                                // Note: We don't break here, so that all matching values within the range are collected
                              }
                            }
                          }

                          // Calculate maintenance total
                          let maintenancTotal = 0;

                          if (maintenanceDataList.length > 0) {
                            maintenancTotal = maintenanceDataList.reduce(
                              (acc, curr) => {
                                const maintenanceValue = showTenkData
                                  ? Number(
                                      uiState.selectedVehicle?.data
                                        ?.modelSpecification.result
                                        .service_maintenance_warranty
                                        .maintenance_plan ?? "0"
                                    ) >= curr?.attributes.interval
                                    ? 0 ||
                                      Number(
                                        uiState.selectedVehicle?.data
                                          ?.modelSpecification.result
                                          .service_maintenance_warranty
                                          .maintenance_plan ?? "0"
                                      ) === 1000000
                                      ? (curr.totalPartPrices || 0) +
                                        (curr.totalLabourPrices || 0) *
                                          (curr.labourInfo[0]?.labourQuantity ||
                                            0)
                                      : Number(
                                          (Number(curr?.attributes.interval) /
                                            rangeValue) *
                                            12
                                        ) >
                                        Number(
                                          uiState.selectedVehicle?.data
                                            ?.modelSpecification.result
                                            .service_maintenance_warranty
                                            .maintenance_plan_months
                                        )
                                      ? (curr.totalPartPrices || 0) +
                                        (curr.totalLabourPrices || 0) *
                                          (curr.labourInfo[0]?.labourQuantity ||
                                            0)
                                      : 0
                                    : (curr.totalPartPrices || 0) +
                                      (curr.totalLabourPrices || 0) *
                                        (curr.labourInfo[0]?.labourQuantity ||
                                          0)
                                  : (curr.totalPartPrices || 0) +
                                    (curr.totalLabourPrices || 0) *
                                      (curr.labourInfo[0]?.labourQuantity || 0);

                                return acc + maintenanceValue;
                              },
                              0
                            );
                          }

                          let maintenanceData;

                          if (MaintanncefilteredPlanInfo) {
                            for (
                              let i = 0;
                              i < MaintanncefilteredPlanInfo.length;
                              i++
                            ) {
                              const data = MaintanncefilteredPlanInfo[i];

                              if (
                                data.attributes.interval >= intervalStart &&
                                data.attributes.interval < intervalEnd
                              ) {
                                maintenanceData = data;
                                break;
                              }
                            }
                          }

                          // Calculate maintenance total
                          let maintenanceTotal = 0;

                          if (maintenanceData) {
                            maintenanceTotal = 0;
                            if (
                              intervalStart >= kmFromSlider &&
                              intervalEnd <= kmToSlider
                            ) {
                              maintenanceTotal = maintenanceDataList.reduce(
                                (acc, curr) => {
                                  const maintenanceValue = showTenkData
                                    ? Number(
                                        uiState.selectedVehicle?.data
                                          ?.modelSpecification.result
                                          .service_maintenance_warranty
                                          .maintenance_plan ?? "0"
                                      ) >= curr?.attributes.interval
                                      ? 0 ||
                                        Number(
                                          uiState.selectedVehicle?.data
                                            ?.modelSpecification.result
                                            .service_maintenance_warranty
                                            .maintenance_plan ?? "0"
                                        ) === 1000000
                                        ? (curr.totalPartPrices || 0) +
                                          (curr.totalLabourPrices || 0) *
                                            (curr.labourInfo[0]
                                              ?.labourQuantity || 0)
                                        : Number(
                                            (Number(curr?.attributes.interval) /
                                              rangeValue) *
                                              12
                                          ) >
                                          Number(
                                            uiState.selectedVehicle?.data
                                              ?.modelSpecification.result
                                              .service_maintenance_warranty
                                              .maintenance_plan_months
                                          )
                                        ? (curr.totalPartPrices || 0) +
                                          (curr.totalLabourPrices || 0) *
                                            (curr.labourInfo[0]
                                              ?.labourQuantity || 0)
                                        : 0
                                      : (curr.totalPartPrices || 0) +
                                        (curr.totalLabourPrices || 0) *
                                          (curr.labourInfo[0]?.labourQuantity ||
                                            0)
                                    : (curr.totalPartPrices || 0) +
                                      (curr.totalLabourPrices || 0) *
                                        (curr.labourInfo[0]?.labourQuantity ||
                                          0);
                                  return acc + maintenanceValue;
                                },
                                0
                              );
                            }
                          }

                          totalMaintenance += maintenanceTotal;

                          // Calculate service
                          const intervalRatesTotal =
                            intervalRates.length > 0
                              ? intervalRates.reduce((acc, Rates) => {
                                  if (
                                    intervalStart >= kmFromSlider &&
                                    intervalEnd <= kmToSlider
                                  ) {
                                    acc += showTenkData
                                      ? Number(
                                          uiState.selectedVehicle?.data
                                            ?.modelSpecification.result
                                            .service_maintenance_warranty
                                            .service_plan ?? "0"
                                        ) >= Rates?.attributes.interval
                                        ? 0 ||
                                          Number(
                                            uiState.selectedVehicle?.data
                                              ?.modelSpecification.result
                                              .service_maintenance_warranty
                                              .service_plan ?? "0"
                                          ) === 1000000
                                          ? Rates.totalPartPrices +
                                            Rates.totalLabourPrices *
                                              Rates.labourInfo[0]
                                                .labourQuantity +
                                            Rates.totalFluidPrices +
                                            Rates.totalSundriesPrices
                                          : Number(
                                              (Number(
                                                Rates?.attributes.interval
                                              ) /
                                                rangeValue) *
                                                12
                                            ) >
                                            Number(
                                              uiState.selectedVehicle?.data
                                                ?.modelSpecification.result
                                                .service_maintenance_warranty
                                                .service_plan_months
                                            )
                                          ? Rates.totalPartPrices +
                                            Rates.totalLabourPrices *
                                              Rates.labourInfo[0]
                                                .labourQuantity +
                                            Rates.totalFluidPrices +
                                            Rates.totalSundriesPrices
                                          : 0
                                        : Rates.totalPartPrices +
                                          Rates.totalLabourPrices *
                                            Rates.labourInfo[0].labourQuantity +
                                          Rates.totalFluidPrices +
                                          Rates.totalSundriesPrices
                                      : Rates.totalPartPrices +
                                        Rates.totalLabourPrices *
                                          Rates.labourInfo[0].labourQuantity +
                                        Rates.totalFluidPrices +
                                        Rates.totalSundriesPrices;
                                  }
                                  return acc;
                                }, 0)
                              : 0;

                          totalservice += intervalRatesTotal;

                          return (
                            <>
                              {intervalStart >= kmFromSlider &&
                              intervalEnd <= kmToSlider ? (
                                <>
                                  <IonRow key={index}>
                                    <IonCol size="2.5" className="grid">
                                      <span className="FontMobileSpec">
                                        {intervalStart / 1000}k -{" "}
                                        {intervalEnd / 1000}k
                                      </span>
                                    </IonCol>

                                    <IonCol size="2.1" className="grid">
                                      <span className="FontMobileSpec">
                                        {format(
                                          intervalRatesTotal,
                                          Format.Currency
                                        )}
                                      </span>
                                    </IonCol>

                                    <IonCol size="3.4" className="grid">
                                      <span className="FontMobileSpec">
                                        {format(
                                          maintenanceData
                                            ? maintenanceTotal
                                            : 0,
                                          Format.Currency
                                        )}
                                      </span>
                                    </IonCol>

                                    <IonCol size="2.3" className="grid">
                                      <span className="FontMobileSpec">
                                        {format(
                                          intervalRatesTotal + maintenanceTotal,
                                          Format.Currency
                                        )}
                                      </span>
                                    </IonCol>
                                    <IonCol size="1.7" className="grid">
                                      <span className="FontMobileSpec">
                                        {format(
                                          intervalRatesTotal / 10000 +
                                            maintenanceTotal / 10000,
                                          Format.Currency
                                        )}
                                      </span>
                                    </IonCol>
                                  </IonRow>
                                </>
                              ) : null}
                            </>
                          );
                        })}

                        <IonRow>
                          <IonCol size="2.5"></IonCol>
                          <IonCol size="2.1" className="grid">
                            <strong>
                              <span className="FontMobileSpec">
                                {/* {format(getTotal(0, 0), Format.Currency)}{" "} */}
                                {format(totalservice, Format.Currency)}
                              </span>
                            </strong>
                          </IonCol>
                          <IonCol size="3.4" className="grid">
                            <strong>
                              <span className="FontMobileSpec">
                                {/* {format(getTotal(0, 1), Format.Currency)}{" "} */}
                                {format(totalMaintenance, Format.Currency)}
                              </span>
                            </strong>
                          </IonCol>
                          <IonCol size="2.3" className="grid">
                            <strong>
                              <span className="FontMobileSpec">
                                {/* {format(getTotal(0, 2), Format.Currency)}{" "} */}
                                {format(
                                  totalservice + totalMaintenance,
                                  Format.Currency
                                )}
                              </span>
                            </strong>
                          </IonCol>
                          <IonCol size="1.7" className="grid">
                            <strong>
                              <span className="FontMobileSpec">
                                {format(
                                  totalservice / 10000 +
                                    totalMaintenance / 10000,
                                  Format.Currency
                                )}
                              </span>
                            </strong>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol
                      size="11.5"
                      sizeXs="11.5"
                      sizeMd="11.5"
                      sizeLg="12"
                      sizeXl="4"
                    >
                      <IonRow>
                        {isDesktop ? (
                          <>
                            <Bar
                              options={Desktopoptions}
                              height={300}
                              width={500}
                              data={getChartData()}
                            />

                            <Line
                              options={DesktopChartOptions}
                              height={300}
                              width={500}
                              data={getChartDataAccumulative()}
                            />
                          </>
                        ) : (
                          <>
                            <Bar
                              options={options}
                              width={100}
                              height={850}
                              data={getChartData()}
                            />

                            <Line
                              options={desktopChartOptions}
                              data={getChartDataAccumulative()}
                            />
                          </>
                        )}
                      </IonRow>
                    </IonCol>

                    <IonCol
                      size="11.5"
                      sizeXs="11.5"
                      sizeMd="11.5"
                      sizeLg="12"
                      sizeXl="3.8"
                      className="filters-desktop"
                    >
                      <IonRow className="ion-justify-content-end">
                        <div>
                          <span className="textDropDown">
                            LIFE SPAN SETTINGS
                          </span>
                        </div>
                        <br />
                        <div className="Filters">
                          <>
                            <section className="sliderContainer">
                              <span id="sliderContainerACtual">
                                <div className="digi">
                                  <span className="FontMobileSpecs">
                                    FROM:
                                    <h1>{kmFromSlider}</h1>
                                  </span>
                                  <span id="digiRight">
                                    TO:
                                    <h1>{kmToSlider - 10000}</h1>
                                  </span>
                                </div>
                                <IonRange
                                  id="slider"
                                  aria-label="Dual Knobs Range"
                                  dualKnobs={true}
                                  min={0}
                                  max={310000}
                                  step={1000}
                                  onIonChange={(ev: any) => {
                                    const lowerValue = isNaN(
                                      ev.detail.value.lower
                                    )
                                      ? 0
                                      : ev.detail.value.lower;
                                    const upperValue = isNaN(
                                      ev.detail.value.upper
                                    )
                                      ? 0
                                      : ev.detail.value.upper;
                                    setKmToSlider(upperValue);
                                    setKmFromSlider(lowerValue);
                                  }}
                                  value={{
                                    upper: kmToSlider,
                                    lower: kmFromSlider,
                                  }}
                                ></IonRange>

                                <div className="digi">
                                  <span>TRAVELLING:</span>
                                  <span id="digiRight">
                                    <h1>
                                      {rangeValue}
                                      /YR
                                    </h1>
                                  </span>
                                </div>

                                <IonRange
                                  id="slider"
                                  step={500}
                                  min={2500}
                                  max={100000}
                                  value={rangeValue}
                                  onIonChange={handleRangeChange}
                                ></IonRange>

                                <div className="digi">
                                  <span>ANNUAL PRICE INCREASE</span>
                                  <span id="digiRight">
                                    <h1>{Inflation}%</h1>
                                  </span>
                                </div>

                                <IonRange
                                  id="slider"
                                  step={0.25}
                                  min={0}
                                  max={30}
                                  value={Inflation}
                                  onIonChange={handleInflationChange}
                                ></IonRange>
                              </span>
                            </section>
                          </>

                          <IonRow className="hiddenPlanDetails">
                            <div>
                              <span
                                id="actualPlans"
                                className="ActualPlanDetails"
                              >
                                This calculation is over the next{" "}
                                <span style={{ color: "red" }}>
                                  {kmToSlider - 10000}
                                </span>{" "}
                              </span>
                            </div>
                          </IonRow>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          <span className="textDropDown">
                            SERVICE AND MAINTENANCE PLAN SETTINGS
                          </span>
                        </div>
                        <div className="Filters">
                          <section className="sliderContainer">
                            <span id="sliderContainerACtual">
                              <IonCol className="activeState ion-justify-content-end">
                                {showTenkData ? "ACTIVATED" : "DEACTIVATED"}
                                <div className="toggleState">
                                  <IonToggle
                                    checked={showTenkData}
                                    className="ServiceCostToggleBtn"
                                    onIonChange={(e) =>
                                      setshowTenK(!showTenkData)
                                    }
                                  />
                                </div>
                              </IonCol>
                            </span>
                          </section>
                          <IonRow className="hiddenPlanDetails">
                            <div>
                              <span
                                id="actualPlans"
                                className="ActualPlanDetails"
                              >
                                The model follows a{" "}
                                <span style={{ color: "red" }}>
                                  <span style={{ textTransform: "lowercase" }}>
                                    {
                                      uiState.selectedVehicle?.data
                                        ?.modelSpecification.result
                                        .service_maintenance_warranty
                                        .service_plan_type
                                    }{" "}
                                    service schedule
                                  </span>
                                </span>{" "}
                                and if there's a service plan included, it's{" "}
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {showTenkData ? "activated" : "deactivated"}
                                </span>{" "}
                                in the report
                              </span>
                            </div>
                          </IonRow>
                        </div>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <section className="costsContainers">
                  <div>
                    <br />

                    <DataAssurance />
                    <Whatsapp shareClicked={handleShareClick} />
                    {/*
                     <div className="TenRatesWhatsApp">
                    <img
                      src={icons.mnewWhatsApp}
                      className="SupportWhatsApp"
                      onClick={handleShareClick}
                    />
                  </div>
                    */}
                  </div>
                </section>
              </div>
              <div
                className={`totalsDisplay ${
                  slideDirection === "in" ? "slideIn" : "slideOut"
                }`}
                onTouchStart={(e) => {
                  touchStartX = e.touches[0].clientX;
                }}
                onTouchEnd={(e) => {
                  const touchEndX = e.changedTouches[0].clientX;
                  const swipeDistance = touchEndX - touchStartX;
                  const swipeThreshold = 50;

                  if (Math.abs(swipeDistance) > swipeThreshold) {
                    if (
                      slideDirection === "in" ||
                      slideDirection === "in-after-out"
                    ) {
                      setShowWithSlider(true);
                      setSlideDirection("out");
                    } else {
                      setShowWithSlider(false);
                      setSlideDirection("in-after-out");
                    }
                  }
                }}
              >
                <span> TOTAL RATES COST</span>
                <span>
                  <IonIcon
                    src={chevronBack}
                    id="Total-prev-arrow"
                    onClick={(e) => {
                      handleSwipe();
                    }}
                    style={{ width: "80px", height: "80px", color: "gray" }}
                  ></IonIcon>
                  <div className="ExcVat">
                    <span></span>
                    {showWithSlider
                      ? format(
                          totalservice / 2 + totalMaintenance / 3,
                          Format.Currency
                        )
                      : format(
                          (totalservice / 2 + totalMaintenance / 3) / 10000,
                          Format.Currency
                        )}
                    <small className="Text-excvat">EXC VAT</small>
                  </div>
                  <div className="InclVat">
                    <span></span>{" "}
                    {showWithSlider
                      ? format(
                          ((totalservice / 2 + totalMaintenance / 3) * 15) /
                            100 +
                            (totalservice / 2 + totalMaintenance / 3),
                          Format.Currency
                        )
                      : format(
                          (((totalservice / 2 + totalMaintenance / 3) / 10000) *
                            15) /
                            100 +
                            (totalservice / 2 + totalMaintenance / 3) / 10000,
                          Format.Currency
                        )}
                  </div>
                  <p className="InclVat-per-km">{totalServiceCostPerKm}</p>
                  <p className="ExcVat">{totalServiceCosPerKm}</p>

                  <IonIcon
                    src={chevronForward}
                    id="Total-next-arrow"
                    onClick={(e) => {
                      handleSwipe();
                    }}
                    style={{ width: "80px", height: "80px", color: "gray" }}
                  ></IonIcon>
                </span>
                <span className="Text-incvat">INC VAT</span>
              </div>
            </>
          )}
        </div>{" "}
      </GlobalGrid>

      <MessageModal
        visible={modalVisible}
        serviceInfoModal={true}
        onPress={onModalPress}
      />
    </>
  );
};

export default Rates10K;
