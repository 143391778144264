import { IonCol, IonModal, IonRow } from '@ionic/react';
import './style.css';
import '../../Global.css';
import { useCallback } from 'react';
import Button from '../../components/button';
import { useDispatch } from 'react-redux';
import { setWatchVideoModalVisible } from '../../redux/ui/uiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface WatchVideoProps {
  isVisible: boolean;
  onClosed: () => void;
}

const WatchVideo: React.FC<WatchVideoProps> = ({
  isVisible,
  onClosed,
}: WatchVideoProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setWatchVideoModalVisible(false));
  }, [dispatch]);

  return (
    <IonModal
      className='auto-height'
      onDidDismiss={onClosed}
      isOpen={isVisible}
    >
      <IonRow>
        <IonCol className='ion-no-padding ion-no-margin titlebar'>
          WATCH TUTORIAL
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12'>
          <iframe
            width='1090'
            height='650'
            src={uiState.modalData}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          ></iframe>
        </IonCol>
        <IonCol size='12'>
          <IonRow style={{ paddingTop: 0 }}>
            <IonCol size='4'>
              <Button
                onPress={onCancel}
                title='CLOSE'
                customClass='redButton'
              />
            </IonCol>

            <IonCol size='2'></IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='1'></IonCol>
      </IonRow>
    </IonModal>
  );
};

export default WatchVideo;
