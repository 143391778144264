import { SelectedVehicle } from '../../interfaces';
import './style.css';

interface DesktopVehicleLabelProps {
  vehicle?: SelectedVehicle;
  vertical?: boolean;
}

const DesktopVehicleLabel: React.FC<DesktopVehicleLabelProps> = ({
  vehicle,
  vertical,
}: DesktopVehicleLabelProps) => {
  return (
    <div
      className='vehicleModelDisplay'
      style={vertical ? { textAlign: 'center' } : {}}
    >
      <span className='dvlBrand'>{vehicle?.make} </span>
      {vertical ? <br /> : null}
      <span className='dvlModel'>{vehicle?.model} </span>
      {vertical ? <br /> : null}
      <span className='dvlVariant'>{vehicle?.variant} </span>
      <br />
      {vehicle && vehicle?.g_id !== 0 ? (
        <span className='dvlModel'>
          G{vehicle?.g_id}M{vehicle?.m_id}V{vehicle?.v_id}{' '}
        </span>
      ) : (
        <span className='dvlModel'> </span>
      )}
      <br />
      <span className='dvlModel'>
        {(vehicle && vehicle?.variant_id) ?? vehicle === undefined
          ? ''
          : 'Not available'}
      </span>
    </div>
  );
};

export default DesktopVehicleLabel;
