import { DEFAULT_BREAKPOINTS } from "react-bootstrap/esm/ThemeProvider";
import { icons } from "../../assets";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../redux/store";
import "./whatsapp.css";
import { image, images } from "ionicons/icons";

interface WhatsappProps {
  shareClicked: () => void;
}

const Whatsapp: React.FC<WhatsappProps> = ({ shareClicked }) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userQuery,setUserQuery] = useState(" I WANT TO KNOW THIS AND THAT ABOUT THE CAR")
  const fixedText: string = uiState.selectedVehicle
  ? `Good day MAPO Team.\n\n 
  *My Question:*   ${userQuery}`
  : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `                          
    ?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <div className="whatsapContainer">
        <div className="whatsappMini">
          <img src={icons.mnewWhatsApp} />
          <span>
            <p>VIA WHATSAPP</p>
            <p>
              Click on the icon below and contact out MAPO Team Via 
              WhatsApp.
            </p>
          </span>
        </div>
        <div className="whatsappShareable">
        
          <img onClick={shareClicked} src={icons.mnewWhatsApp} />
        </div>
      </div>
    </>
  );
};

export default Whatsapp;
