import { IonCol, IonRow } from '@ionic/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SelectedVehicle } from '../../interfaces';
import { RootState } from '../../redux/store';
import DesktopVehicleLabel from '../desktop-vehicle-label';

const DesktopChartLegend: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);

  const getCompetitor = useCallback(
    (index: number) => {
      if (uiState.competitors && uiState.competitors.length > index) {
        const competitor: SelectedVehicle = {
          make: uiState.competitors
            ? uiState.competitors[index].modelSpecification.attributes.make
            : '',
          model: uiState.competitors
            ? uiState.competitors[index].modelSpecification.attributes.model
            : '',
          variant: uiState.competitors
            ? uiState.competitors[index].modelSpecification.attributes.variant
            : '',
          g_id: uiState.competitors
            ? Number(uiState.competitors[index].modelSpecification.attributes.G)
            : 0,
          m_id: uiState.competitors
            ? Number(uiState.competitors[index].modelSpecification.attributes.M)
            : 0,
          v_id: uiState.competitors
            ? Number(uiState.competitors[index].modelSpecification.attributes.V)
            : 0,
          attributes: undefined,
          variant_id: uiState.competitors
            ? uiState.competitors[index].modelClass?.result[0].variantId
            : '',
          data: uiState.competitors ? uiState.competitors[index] : undefined,
        };
        return competitor;
      } else {
        return undefined;
      }
    },
    [uiState]
  );

  return (
    <IonRow>
      <IonCol size='12'>
        <IonRow>
          <IonCol size='0.5'>
            <div className='legendBlue'></div>
          </IonCol>
          <IonCol size='2.5'>
            <DesktopVehicleLabel
              vertical={false}
              vehicle={uiState.selectedVehicle}
            />
          </IonCol>
          {uiState.competitors && uiState.competitors?.length > 0 ? (
            <>
              <IonCol size='0.5'>
                <div className='legendRed'></div>
              </IonCol>
              <IonCol size='2.5'>
                <DesktopVehicleLabel
                  vertical={false}
                  vehicle={getCompetitor(0)}
                />
              </IonCol>
            </>
          ) : null}
          {uiState.competitors && uiState.competitors?.length > 1 ? (
            <>
              <IonCol size='0.5'>
                <div className='legendOrange'></div>
              </IonCol>
              <IonCol size='2.5'>
                <DesktopVehicleLabel
                  vertical={false}
                  vehicle={getCompetitor(1)}
                />
              </IonCol>
            </>
          ) : null}
          {uiState.competitors && uiState.competitors?.length > 2 ? (
            <>
              <IonCol size='0.5'>
                <div className='legendGreen'></div>
              </IonCol>
              <IonCol size='2.5'>
                <DesktopVehicleLabel
                  vertical={false}
                  vehicle={getCompetitor(2)}
                />
              </IonCol>
            </>
          ) : null}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default DesktopChartLegend;
