import { IonCol, IonContent, IonInput, IonPage, IonRow } from '@ionic/react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import Header from '../../components/header';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import { RootState } from '../../redux/store';
import { hideLoader, setScreen, showLoader } from '../../redux/ui/uiSlice';
import { images } from '../../assets';
import { createReport } from '../../api/mapo';
import { CreateReportData, ReportVehicle } from '../../interfaces/createReport';
import { VehicleCustomReportParams } from '../../interfaces/customReportParams';
import { useHistory } from 'react-router-dom';

const Reviews: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push('/VehicleDetail')
  }, [dispatch]);

  const onCreateReport = useCallback(() => {
    if (email === '') {
      alert('Please enter your email address.');
    } else {
      const selectedVehicle: ReportVehicle = {
        GMV:
          'G' +
          uiState.selectedVehicle?.g_id +
          'M' +
          uiState.selectedVehicle?.m_id +
          'V' +
          uiState.selectedVehicle?.v_id,
        RetailPrice:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.retailprice ?? 0,
        Discount:
          uiState.customReportParams?.selectedVehicleCustomParams?.discount ??
          0,
        OptionalExtras:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.optionalextras ?? 0,
        Deposit:
          uiState.customReportParams?.selectedVehicleCustomParams?.deposit ?? 0,
        SubTotal:
          uiState.customReportParams?.selectedVehicleCustomParams?.subtotal ??
          0,
        CO2Tax:
          uiState.customReportParams?.selectedVehicleCustomParams?.co2tax ?? 0,
        VAT: uiState.customReportParams?.selectedVehicleCustomParams?.vat ?? 0,
        FinancedAmount:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.financeamount ?? 0,
        Balloon:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.baloonpayment ?? 0,
        StandardServicePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.servicestandardkm ?? 0,
        AdditionalServicePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.serviceadditionalkm ?? 0,
        AdditionalServicePlanCost:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.serviceadditionalcost ?? 0,
        StandardMaintenancePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenancestandardkm ?? 0,
        AdditionalMaintenancePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenanceadditionalkm ?? 0,
        AdditionalMaintenancePlanCost:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenanceadditionalcost ?? 0,
      };

      const competitors: Array<ReportVehicle> = [];
      uiState.customReportParams?.competitorCustomParams?.map(
        (customReportParams: VehicleCustomReportParams, index: number) => {
          if (uiState.competitors && uiState.competitors.length > index) {
            const competitorReport: ReportVehicle = {
              GMV:
                'G' +
                uiState.competitors[index].competitorBase?.g_id +
                'M' +
                uiState.competitors[index].competitorBase?.m_id +
                'V' +
                uiState.competitors[index].competitorBase?.v_id,
              RetailPrice: customReportParams?.retailprice ?? 0,
              Discount: customReportParams?.discount ?? 0,
              OptionalExtras: customReportParams?.optionalextras ?? 0,
              Deposit: customReportParams?.deposit ?? 0,
              SubTotal: customReportParams?.subtotal ?? 0,
              CO2Tax: customReportParams.co2tax ?? 0,
              VAT: customReportParams?.vat ?? 0,
              FinancedAmount: customReportParams?.financeamount ?? 0,
              Balloon: customReportParams?.baloonpayment ?? 0,
              StandardServicePlan: customReportParams?.servicestandardkm ?? 0,
              AdditionalServicePlan:
                customReportParams?.serviceadditionalkm ?? 0,
              AdditionalServicePlanCost:
                customReportParams?.serviceadditionalcost ?? 0,
              StandardMaintenancePlan:
                customReportParams?.maintenancestandardkm ?? 0,
              AdditionalMaintenancePlan:
                customReportParams?.maintenanceadditionalkm ?? 0,
              AdditionalMaintenancePlanCost:
                customReportParams?.maintenanceadditionalcost ?? 0,
            };
            competitors.push(competitorReport);
          }
        }
      );

      const createReportData: CreateReportData = {
        UserEmail: '',
        CustomerEmail: email,
        IncludeFinance: uiState.customReportParams?.toggles?.finance ?? true,
        DealType: uiState.customReportParams?.toggles?.finance
          ? 'Finance'
          : 'Cash',
          Lifespan: uiState.customReportParams?.totalLifeSpan ?? 0,
        AnnualMileage: uiState.customReportParams?.annualMileage ?? 0,
        InterestRate: uiState.customReportParams?.interest ?? 0,
        InterestTerm: uiState.customReportParams?.term ?? 0,
        IncludeService: uiState.customReportParams?.toggles?.service ?? true,
        IncludeMaintenance:
          uiState.customReportParams?.toggles?.maintenance ?? true,
        IncludeFuel: uiState.customReportParams?.toggles?.fuel ?? true,
        IncludeResidual:
          uiState.customReportParams?.toggles?.depreciation ?? true,
        IncludeInsurance:
          uiState.customReportParams?.toggles?.insurance ?? true,
        SelectedVehicle: selectedVehicle,
        Competitors: competitors,
      };

      dispatch(showLoader());
      createReport(createReportData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(hideLoader());
            alert(response.data);
          } else {
            alert(response.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log('createReport 1', err);
        });
    }
  }, [dispatch, uiState, email]);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          <IonRow className='desktopContent'>
            {/* <IonCol size='1' className='ion-no-padding ion-no-margin'>
              <SideMenu></SideMenu>
            </IonCol> */}
            <IonCol size='12' className='ion-no-padding ion-no-margin'>
              <DesktopContainer>
                <div className='detailContent'>Coming soon...</div>
              </DesktopContainer>
            </IonCol>
          </IonRow>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
          
                vehicle={uiState.selectedVehicle}
              ></SubPageHeading>
              <IonRow>
                <IonCol>
                  <strong>MAPO REPORT</strong>
                  <p className='detail'>
                    All the data of the selected model will be compiled into a
                    .PDF file.{' '}
                  </p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='10'></IonCol>
                <IonCol size='2' className='alignRight'>
                  <img src={images.downloadPdf} alt='' style={{ width: 45 }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='10'>
                  <IonInput
                    className='input'
                    placeholder='Email address to email file'
                    value={email}
                    onIonChange={(ev: any) => setEmail(ev.target.value)}
                  ></IonInput>
                </IonCol>
                <IonCol size='2' className='alignRight'>
                  <a href='#' onClick={() => onCreateReport()}>
                    <img src={images.emailicon} alt='' style={{ width: 45 }} />
                  </a>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='10'>
                  <IonInput
                    className='input'
                    placeholder='Mobile number to receive message'
                    value={mobile}
                    onIonChange={(ev: any) => setMobile(ev.target.value)}
                  ></IonInput>
                </IonCol>
                <IonCol size='2' className='alignRight'>
                  <img src={images.mobileicon} alt='' style={{ width: 45 }} />
                </IonCol>
              </IonRow>
              &nbsp;
              <br />
              &nbsp;
              <br />
              <IonRow>
                <IonCol>
                  <strong>OTHER MEDIA REPORTS</strong>
                </IonCol>
              </IonRow>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Reviews;
