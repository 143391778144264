import { IonCol, IonRow, IonLabel, IonIcon } from "@ionic/react";
// import "../style.css";
import "../../../Global.css";
import { SelectedVehicle, Vehicle } from "../../../interfaces";
import { getCustomCompetitors, getImage } from "../../../api/mapo";
import { desktopImages } from "../../../assets";
import { useCallback } from "react";
import { ParameterSearchViewType } from "../index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import Button from "../../../components/button";
import { CompetitorData } from "../../../interfaces/competitorData";
import {
  getAllData,
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../../api/getAll";
import { Competitor } from "../../../interfaces/competitors";
import {
  setCompetitors,
  setSwapoutSearchVisible,
  setSwapoutCompetitorIndex,
  showLoader,
  hideLoader,
  setSelectedVehicle,
  setCustomReportParams,
  setScreen,
  setFilteredSearchVisible,
  setParameterSearchVisible,
} from "../../../redux/ui/uiSlice";
import { addOutline, removeOutline } from "ionicons/icons";

import { VehicleCustomReportParams } from "../../../interfaces/customReportParams";
import { decVal } from "../../../utils";
import { AppScreens } from "../../../enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//describe the shape or structure of an object in TypeScript
interface ParameterResultViewProps {
  searchResults: Array<Vehicle> | undefined;
  viewType: ParameterSearchViewType;
}

const ParameterResultView: React.FC<ParameterResultViewProps> = ({
  searchResults,
  viewType,
}: ParameterResultViewProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();

  const hasData = useCallback((type: string, vehicle: Vehicle) => {
    switch (type) {
      case "serviceChecks": {
        if (
          ((Number(vehicle.servicechecks) / Number(vehicle.servicechecks_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceLabour": {
        if (
          ((Number(vehicle.servicelabour) / Number(vehicle.servicelabour_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceLubes": {
        if (
          ((Number(vehicle.servicelubes) / Number(vehicle.servicelubes_max)) *
            100 ?? 0) > 70
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceParts": {
        if (
          ((Number(vehicle.serviceparts) / Number(vehicle.serviceparts_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceChecks": {
        if (
          ((Number(vehicle.maintenancechecks) /
            Number(vehicle.maintenancechecks_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceLabour": {
        if (
          ((Number(vehicle.maintenancelabour) /
            Number(vehicle.maintenancelabour_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceParts": {
        if (
          ((Number(vehicle.maintenanceparts) /
            Number(vehicle.maintenanceparts_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }, []);

  const selectPrimaryVehicle = useCallback(
    (v: Vehicle) => {
      const selectedVehicle: SelectedVehicle = {
        make: v.g_desc,
        model: v.m_desc,
        variant: v.v_desc,
        g_id: v.g_id,
        m_id: v.m_id,
        v_id: v.v_id,
        variant_id: v.oem_code,
      };
      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());

      getAllSelectedData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0,
        uiState.customReportParams.deposit ?? 0,
        uiState.customReportParams.interest ?? 0,
        uiState.customReportParams.term ?? 0,
        uiState.customReportParams.residual ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
          dispatch(setCompetitors(allData.competitors ?? []));

          const selectedVehicleCustomParams: VehicleCustomReportParams = {
            retailprice: decVal(
              allData.selected?.modelSpecification.result?.basic_specs
                ?.base_price
            ),
            co2tax: 0,
            // co2tax: Number(
            //   allData.selected?.modelSpecification.result?.engine_specs.co2 ??
            //     ''
            // ),

            // Service
            servicestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan ?? "0"
            ),
            servicestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan_months ?? "0"
            ),

            // Maintenance
            maintenancestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan ?? "0"
            ),
            maintenancestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
            ),

            // baloonpayment: 0,
          };

          const competitorCustomParams: Array<VehicleCustomReportParams> = [];

          allData.competitors?.map((competitor) => {
            const competitorCustomParam: VehicleCustomReportParams = {
              retailprice: decVal(
                competitor.modelSpecification.result?.basic_specs?.base_price
              ),
              co2tax: 0,
              // co2tax: Number(
              //   competitor.modelSpecification.result?.engine_specs.co2 ?? ''
              // ),

              // Service
              servicestandardkm: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.service_plan ?? "0"
              ),
              servicestandardmnt: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.service_plan_months ?? "0"
              ),

              // Maintenance
              maintenancestandardkm: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.maintenance_plan ?? "0"
              ),
              maintenancestandardmnt: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
              ),

              baloonpayment: 0,
            };
            competitorCustomParams.push(competitorCustomParam);
          });

          dispatch(
            setCustomReportParams({
              ...uiState.customReportParams,
              selectedVehicleCustomParams: selectedVehicleCustomParams,
              competitorCustomParams: competitorCustomParams,
            })
          );

          dispatch(hideLoader());
          // dispatch(setFilteredSearchVisible(false));
          dispatch(setParameterSearchVisible(false));
          //dispatch(setScreen(AppScreens.VehicleLanding));
          // setTimeout(() => {
          //   dispatch(setScreen(AppScreens.VehicleLanding));
          // }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams]
  );

  const selectPrimaryVehic = useCallback(
    (v: Vehicle) => {
      const selectedVehicle: SelectedVehicle = {
        make: v.g_desc,
        model: v.m_desc,
        variant: v.v_desc,
        g_id: v.g_id,
        m_id: v.m_id,
        v_id: v.v_id,
        variant_id: v.oem_code,
      };
      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());

      getSelectedSpecificModelData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
          dispatch(setCompetitors(allData.competition ?? []));

          dispatch(hideLoader());
          dispatch(setParameterSearchVisible(false));
          setTimeout(() => {
            //dispatch(setScreen(AppScreens.VehicleLanding));
            history.push('/VehicleLanding')
          }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    },
    [dispatch]
  );
  const [showDataIcon, setshowDataIcon] = useState(false);
  const [showDataIcons, setShowDataIcons] = useState<Array<boolean>>(
    Array(searchResults?.length || 0).fill(false)
  );

  const handleToggleDataIcon = (index: number) => {
    setShowDataIcons((prev) =>
      prev.map((value, i) => (i === index ? !value : value))
    );
  };

  

  return (
    <>
      <IonRow>
        {!searchResults || searchResults.length === 0 ? (
          <IonCol>
            <IonLabel className="detail AlignCen">No results to show.</IonLabel>
          </IonCol>
        ) : (
          <div className="carDivider">
            {searchResults?.map((row, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={`resultDisplay ${
                      showDataIcons[index] ? "showData" : ""
                    }`}
                  >
                    <div className="top">
                      <div className="left">
                        <img
                          className="carImage"
                          onClick={() => {
                            selectPrimaryVehic(row);
                            selectPrimaryVehicle(row);
                          }}
                          src={getImage(
                            row.g_id.toString(),
                            row.m_id.toString(),
                            row.v_id.toString()
                          )}
                          alt=""
                        />
                      </div>
                      <div className="right">
                        <span
                          id="group"
                          onClick={() => {
                            selectPrimaryVehic(row);
                            selectPrimaryVehicle(row);
                          }}
                        >
                          <span className="garageMake">{row.g_desc}</span>
                        </span>
                        <span
                          id="model"
                          onClick={() => {
                            selectPrimaryVehic(row);
                            selectPrimaryVehicle(row);
                          }}
                        >
                          <span className="garageModel"> {row.m_desc}</span>
                        </span>
                        <span
                          id="model"
                          onClick={() => {
                            selectPrimaryVehic(row);
                            selectPrimaryVehicle(row);
                          }}
                        >
                          <span className="garageVariant cut-text">
                            {row.v_desc}
                          </span>{" "}
                        </span>
                        <span
                          id="model"
                          onClick={() => {
                            selectPrimaryVehic(row);
                            selectPrimaryVehicle(row);
                          }}
                        >
                          <span className="garageDate">
                            {" "}
                            MY {moment(new Date(row.intro_date)).format("YYYY")}
                          </span>
                        </span>
                        <span id="model">
                          <span className="garageDate">
                            {" "}
                            G{row?.g_id}M{row?.m_id}V{row?.v_id}{" "}
                          </span>
                        </span>
                        <span id="model">
                          <span className="garageDate cut-text">
                            {" "}
                            {row?.oem_code ?? "OEM Variant ID - N/A"}
                          </span>
                        </span>
                        <span className="model" id="moreInfoButton">
                          <button onClick={() => handleToggleDataIcon(index)}>
                            {showDataIcons[index] ? "Less Info" : " More Info"}
                          </button>
                        </span>
                      </div>
                    </div>

                    {showDataIcons[index] && (
                      <>
                        <div className="bottom">
                          <span>
                            <div
                              className={
                                hasData("serviceChecks", row)
                                  ? "dataAvail green"
                                  : "dataAvail red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon1}
                                alt="loading"
                              />
                            </div>
                            <div
                              className={
                                hasData("serviceLabour", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon2}
                                alt="loading"
                              />
                            </div>
                            <div
                              className={
                                hasData("serviceLubes", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon3}
                                alt=""
                              />
                            </div>
                            <div
                              className={
                                hasData("serviceParts", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon4}
                                alt=""
                              />
                            </div>
                            <div
                              className={
                                hasData("maintenanceChecks", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon5}
                                alt=""
                              />
                            </div>
                            <div
                              className={
                                hasData("maintenanceLabour", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon6}
                                alt=""
                              />
                            </div>
                            <div
                              className={
                                hasData("maintenanceParts", row)
                                  ? "dataAvailBadg green"
                                  : "dataAvailBadg red"
                              }
                            >
                              <img
                                className="dataIcons"
                                src={desktopImages.dataIcon7}
                                alt=""
                              />
                            </div>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        )}
      </IonRow>
    </>
  );
};

export default ParameterResultView;
