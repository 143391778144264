import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
} from "@ionic/react";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PartsBasketIcons, desktopImages, icons, images } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import Header from "../../components/header";
import Loading from "../../components/loading";
import MessageModal from "../../components/message-modal";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import "./style.css";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import { SelectedVehicle } from "../../interfaces";
import { Attributes } from "../../interfaces/modelSpecification";
import Button from "../../components/button";
import { partsBaskets } from "../../api/mapo";
import { Part } from "../../interfaces/partsBasket";
import { Pie } from "react-chartjs-2";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { format } from "../../utils";
import {
  addOutline,
  chevronBack,
  chevronBackOutline,
  chevronForward,
  chevronForwardOutline,
  removeOutline,
  starOutline,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import DataAssurance from "../../components/dataAssurance";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import Whatsapp from "../../components/whatsapp/whatsapp";

const Parts: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [tabVisible, setTabVisible] = useState("TABLE");

  const [partG, setPartG] = useState(String);
  const [partM, setPartM] = useState(String);
  const [partV, setPartV] = useState(String);

  const [selectedParts, setSelectedParts] = useState<{
    [key: string]: { isChecked: boolean; quantity: number };
  }>(() => {
    const initialParts: {
      [key: string]: { isChecked: boolean; quantity: number };
    } = {};
    if (uiState.selectedVehicle?.data?.partGroups) {
      Object.values(uiState.selectedVehicle.data.partGroups).forEach(
        (group) => {
          Object.values(group).forEach((part) => {
            initialParts[part.partsGroupItemName] = {
              isChecked: false,
              quantity: Number(part.partQuantity),
            };
          });
        }
      );
    }
    return initialParts;
  });

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const handleQuantityChange = (part: Part, change: number) => {
    setSelectedParts((prevSelectedParts) => {
      const currentItem = prevSelectedParts[part.partsGroupItemName];
      const currentQuantity = currentItem?.quantity || part.partQuantity;
      const newQuantity = Math.max(1, Number(currentQuantity) + change);

      // Update the part in the state
      const updatedParts = {
        ...prevSelectedParts,
        [part.partsGroupItemName]: {
          isChecked: currentItem?.isChecked || false,
          quantity: newQuantity,
        },
      };

      // Calculate the new total price
      const newTotalPrice = Object.values(updatedParts).reduce(
        (total, item) => {
          if (item.isChecked) {
            return total + part.partPrice * item.quantity;
          }
          return total;
        },
        0
      );

      // Update total price state
      setTotalPrice(newTotalPrice);

      return updatedParts;
    });
  };

  const handleCheckboxChange = (part: Part, isChecked: boolean) => {
    setSelectedParts((prevSelectedParts) => {
      // Get current quantity and update the state
      const currentQuantity =
        prevSelectedParts[part.partsGroupItemName]?.quantity ||
        part.partQuantity;
      const updatedParts = {
        ...prevSelectedParts,
        [part.partsGroupItemName]: { isChecked, quantity: currentQuantity },
      };

      return updatedParts;
    });

    setTotalPrice((prevTotalPrice) => {
      // Calculate the total price of all checked items after change
      const newTotalPrice = Object.values(selectedParts).reduce(
        (total, item) => {
          if (item.isChecked) {
            return total + part.partPrice * item.quantity;
          }
          return total;
        },
        0
      );

      // Add or subtract the price of the current item based on its checked status
      const currentItem = selectedParts[part.partsGroupItemName];
      const currentQuantity = currentItem?.quantity || part.partQuantity;
      const partTotalPrice = part.partPrice * currentQuantity;

      return isChecked
        ? prevTotalPrice + partTotalPrice
        : prevTotalPrice - partTotalPrice;
    });
  };

  const handleClickedPart = useCallback(
    (p: any) => {
      setPartG(p.partsGroupMainId.toString());
      setPartM(p.partsGroupSubId.toString());
      setPartV(p.partsGroupItemId.toString());
    },
    [partG, partM, partV]
  );

  const onModalPress = useCallback(() => {
    setModalVisible(false);
  }, []);

  // Inside the component function
  const [searchText, setSearchText] = useState("");

  // Add a function to handle text input change
  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  // const onMorePress = useCallback(() => {
  //   setModalVisible(true);
  // }, []);

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: "",
    };
    return vehicle;
  };

  const toggleRow = useCallback(
    (groupName: string) => {
      if (groupName === selectedGroup) {
        setSelectedGroup("");
      } else {
        setSelectedGroup(groupName);
      }
    },
    [selectedGroup]
  );
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedPartGroup, setSelectedPartGroup] = useState<string | null>(
    null
  );

  const [part, setPart] = useState({ partQuantity: 5 });

  const decrementQuantity = () => {
    setPart((prevPart) => ({
      ...prevPart,
      partQuantity: prevPart.partQuantity > 0 ? prevPart.partQuantity - 1 : 0,
    }));
  };

  const renderParts = (): any => {
    const controlList: Array<any> = [];
    let lastPartsGroupSubName: string | null = null;

    if (uiState.selectedVehicle?.data?.partGroups) {
      Object.keys(uiState.selectedVehicle?.data?.partGroups).forEach(
        (partGroup) => {
          if (uiState.selectedVehicle?.data?.partGroups === undefined) {
            return;
          }

          const partsInGroup = Object.values(
            uiState.selectedVehicle?.data?.partGroups[partGroup]
          );

          const groupsWithNonNullParts = partsInGroup.filter(
            (part) => part.partPrice !== null
          );

          const hasNonNullPrice = groupsWithNonNullParts.length > 0;

          if (!hasNonNullPrice) {
            return;
          }

          if (
            (selectedPartGroup === null || partGroup === selectedPartGroup) &&
            (searchTerm === "" ||
              groupsWithNonNullParts.some(
                (part) =>
                  part.partsGroupSubName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  part.partsGroupItemName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  partGroup.toLowerCase().includes(searchTerm.toLowerCase())
              ))
          ) {
            controlList.push(
              <IonRow
                key={partGroup}
                className="groupHeader"
                onClick={() => toggleRow(partGroup)}
              >
                <IonCol size="12">
                  <span>
                    <strong>
                      <span className="FontMobileSpecs">
                        {partGroup.toString()}
                      </span>
                    </strong>
                  </span>
                </IonCol>
              </IonRow>
            );

            groupsWithNonNullParts.sort((a, b) =>
              a.partsGroupSubName.localeCompare(b.partsGroupSubName)
            );

            groupsWithNonNullParts.forEach((part: Part) => {
              if (part.partsGroupSubName !== lastPartsGroupSubName) {
                controlList.push(
                  <IonRow className="groupHeader" key={part.partsGroupSubName}>
                    <IonCol size="12">
                      <span className="FontMobileSp">
                        {part.partsGroupSubName}
                      </span>
                    </IonCol>
                  </IonRow>
                );
              }

              const partQuantity = Number(
                selectedParts[part.partsGroupItemName]?.quantity ||
                  part.partQuantity
              );

              controlList.push(
                <IonRow className="itemRow" key={part.partsGroupItemName}>
                  <IonCol size="0.6">
                    <IonCheckbox
                      checked={
                        !!selectedParts[part.partsGroupItemName]?.isChecked
                      }
                      onIonChange={(e) =>
                        handleCheckboxChange(part, e.detail.checked)
                      }
                      style={{ width: "60px", height: "50px", padding: "10px" }}
                    />
                  </IonCol>
                  <IonCol size="" className="partName">
                    <span>{part.partsGroupItemName}</span>
                  </IonCol>
                  <IonCol size="2" className="partPrice">
                    <span>{format(part.partPrice, Format.Currency)}</span>
                  </IonCol>
                  <IonCol size="1.5" className="partPrice">
                    <button
                      className="Left-btn-click"
                      onClick={() => handleQuantityChange(part, -1)}
                    >
                      -
                    </button>
                    <span>{partQuantity}</span>
                    <button
                      className="Right-btn-click"
                      onClick={() => handleQuantityChange(part, 1)}
                    >
                      <span style={{ fontSize: "30px" }}>+</span>
                    </button>
                  </IonCol>
                  <IonCol size="2" className="partPrice">
                    <span>
                      {format(part.partPrice * partQuantity, Format.Currency)}
                    </span>
                  </IonCol>
                </IonRow>
              );

              lastPartsGroupSubName = part.partsGroupSubName;
            });
          }
        }
      );
    }

    return controlList;
  };

  const partGroups = Object.keys(
    uiState.selectedVehicle?.data?.partGroups || {}
  );

  const getChartData = useCallback(() => {
    const data = {
      labels: [
        "Finance Cost",
        "Service Cost",
        "Maintenance Cost",
        "Fuel Cost",
        "Tyre Cost",
        "Residual Value",
      ],
      datasets: [
        {
          label: "# of Votes",
          data: [
            uiState.selectedVehicle?.data?.tcoData?.result.financeCost,
            uiState.selectedVehicle?.data?.tcoData?.result.serviceCost,
            uiState.selectedVehicle?.data?.tcoData?.result.maintenanceCost,
            uiState.selectedVehicle?.data?.tcoData?.result.fuelCost,
            uiState.selectedVehicle?.data?.tcoData?.result.tyreCost,
            uiState.selectedVehicle?.data?.tcoData?.result.residualValue,
          ],
          backgroundColor: [
            "rgb(106, 178, 44)",
            "rgb(17, 38, 150)",
            "rgb(158, 116, 4)",
            "rgb(213, 0, 27)",
            "rgb(16, 69, 128)",
            "rgb(254, 219, 90)",
          ],
          borderWidth: 1,
        },
      ],
    };
    return data;
  }, [uiState.selectedVehicle?.data]);

  const onLogOut = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      dispatch(setScreen(AppScreens.Login));
    }, 300);
  }, [dispatch]);

  const groupIcons: { [key: string]: string } = {
    LUBRICANTS: PartsBasketIcons.Lubricants, // Replace 'starOutline' with the actual Ionicon you want for 'Wheel'
    "ENGINE PARTS": PartsBasketIcons.Engine,
    SAFETY: PartsBasketIcons.Safety,
    "CAB AND BODY PANELS": PartsBasketIcons.Body,
    "ELECTRICAL PARTS": PartsBasketIcons.Electrical,
    "VEHICLE ACCESSORIES": PartsBasketIcons.Accessories,
    "EXHAUST SYSTEM": PartsBasketIcons.Exhaust,
    "TRANSMISSION AND TRANSFERCASE": PartsBasketIcons.Transmission,
    "DRIVE TRAIN": PartsBasketIcons.DriveTrain,
    "COOLING SYSTEM": PartsBasketIcons.Cooling,
    "SUSPENSION PARTS": PartsBasketIcons.Suspension,
    BRAKES: PartsBasketIcons.Brakes,
    "FUEL SYSTEM": PartsBasketIcons.Fuel,
    "WHEELS AND TYRES": PartsBasketIcons.Wheels,
    "GLASS AND WINDOW PARTS ": PartsBasketIcons.Glass,
    "INTERIOR AND TRIMMINGS": PartsBasketIcons.Interior,
    "STEERING SYSTEM": PartsBasketIcons.Steering,

    // Add more mappings as needed
  };

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team. I have a question on the Parts Basket screen of the  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      }  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} *My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (direction: "left" | "right") => {
    const container = scrollContainerRef.current;

    if (container) {
      const scrollAmount = 200; // Adjust the scroll amount as needed
      container.scrollLeft +=
        direction === "left" ? -scrollAmount : scrollAmount;
    }
  };

  const clearAll = () => {
    setSearchTerm("");
    setSelectedPartGroup(null);
  };

  return (
    <>
      {sessionState.user?.username === "" ? (
        <IonContent className="contentPage">
          <div className="innerContent">
            <SubPageHeading
              onBack={onBackPress}
              vehicle={uiState.selectedVehicle}
            ></SubPageHeading>
            <p className="Subscription" onClick={onLogOut}>
              Create Account or Log in
            </p>
          </div>
        </IonContent>
      ) : (
        <GlobalGrid
          currentPageTitle="PARTS BASKET"
          closeCurrentPage={() => onBackPress()}
        >
          <SubPageHeading
            onBack={onBackPress}
            vehicle={uiState.selectedVehicle}
          ></SubPageHeading>

          <div className="genericHolderParts">
            {partGroups.map((group) => {
              const partsInGroup =
                uiState.selectedVehicle?.data?.partGroups?.[group];

              if (partsInGroup) {
                const hasNonNullPrice = Object.values(partsInGroup).some(
                  (part) => part.partPrice !== null
                );

                if (hasNonNullPrice) {
                  return (
                    <div
                      key={group}
                      onClick={() => setSelectedPartGroup(group)}
                    >
                      <div className="group-text">
                        <img src={groupIcons[group]} className="Part-icon" />
                      </div>
                    </div>
                  );
                }
              }

              return null; // Hide the group if conditions are not met
            })}
          </div>
          <div className="scrollable">
            <section className="costsContainers">
              <div className="RowDown">
                <IonRow className="RowFixed">
                  <IonCol size="" className="gridhead">
                    <span className="FontMobileSpecs"> </span>
                  </IonCol>
                  <IonCol size="1.9" className="gridhead">
                    {" "}
                    <span className="FontMobileSpecs"> PRICE </span>
                  </IonCol>
                  <IonCol size="1.5" className="gridhead ">
                    <span className="FontMobileSpecs"> QTY </span>
                  </IonCol>

                  <IonCol size="2" className="gridhead">
                    <span className="FontMobileSpecs"> Total </span>
                  </IonCol>
                </IonRow>
                {/*

                this shows the image of a part , leaving for later implementations 
                  <div className="partsBasketImage">
                <img src={`https://za-apihub.mapo-int.com/images/partimg/CARS/${partG}_${partM}_${partV}.jpg`} alt="" />
              </div>
                
                */}
              </div>
              <div className="">
                {tabVisible === "TABLE" ? (
                  <>
                    {" "}
                    <IonGrid className="tableFixHead">
                      {renderParts()?.map((ctl: any) => {
                        return <>{ctl}</>;
                      })}
                    </IonGrid>
                  </>
                ) : tabVisible === "CHART" ? (
                  <Pie data={getChartData()} />
                ) : (
                  <></>
                )}
              </div>

              <DataAssurance />

              <Whatsapp shareClicked={handleShareClick} />
            </section>
          </div>
          <div className="totalsDisplay">
            <span> TOTAL BASKET COST</span>

            <IonRow>
              <IonCol className="ion-justify-content-end">
                <p id="mobile-basket-cost">
                  {format(totalPrice, Format.Currency)}
                </p>
              </IonCol>
              <span id="Text-excvat-basket">EXC VAT</span>
            </IonRow>

            <IonCol className="ion-justify-content-center">
              <p id="mobile-total-cost">
                {format((totalPrice * 15) / 100 + totalPrice, Format.Currency)}
              </p>
              <span className="Text-incvat-basket"> INC VAT</span>
            </IonCol>
          </div>
        </GlobalGrid>
      )}

      <MessageModal
        visible={modalVisible}
        serviceInfoModal={true}
        onPress={onModalPress}
      />
    </>
  );
};

export default Parts;
