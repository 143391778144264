import { IonButton, IonIcon } from '@ionic/react';
import './style.css';

interface ButtonProps {
  onPress: () => void;
  title: string;
  icon?: string;
  customClass?: string;
}

const Button: React.FC<ButtonProps> = ({
  onPress,
  customClass,
  icon,
  title,
}: ButtonProps) => {
  return (
    <IonButton
      onClick={onPress}
      size='large'
      className={customClass ? customClass : 'button'}
      expand='block'
    >
      {icon ? <IonIcon src={icon}></IonIcon> : null}
      {title}
    </IonButton>
  );
};

export default Button;
