// import Home from "./pages/Home";
// import Login from "./pages/Login";

// import { useSelector } from "react-redux";
// import { RootState } from "./redux/store";
// import { AppScreens } from "./enums";
// import { useDispatch } from "react-redux";
// import {
//   setCreateReportVisible,
//   setFilteredSearchVisible,
//   setIsDesktop,
//   setParameterSearchVisible,
//   setShareSocialVisible,
//   setSwapoutSearchVisible,
//   setWatchVideoModalVisible,
// } from "./redux/ui/uiSlice";
// import Garage from "./pages/Garage";
// import Reviews from "./pages/Reviews";
// import Competitors from "./pages/Competitors";
// import Rates10K from "./pages/Rates10K";
// import Parts from "./pages/Parts";
// import Tco from "./pages/Tco";
// import Maintenance from "./pages/Maintenance";
// import Services from "./pages/Services";
// import Specs from "./pages/Specs";
// import VehicleLanding from "./pages/VehicleLanding";
// import SearchResult from "./pages/SearchResult";
// import FinanceCalculator from "./pages/FinanceCalculator";
// import Tutorial from "./pages/Tutorial";
// import About from "./pages/About";
// import Contact from "./pages/Contact";
// import VehicleDetail from "./pages/VehicleDetail";
// import ResidualValue from "./pages/ResidualValue";
// import Welcome from "./pages/Welcome";
// import Depreciation from "./pages/Depreciation";
// import Notifications from "./pages/Notifications";
// import UserProfile from "./pages/UserProfile";
// import Settings from "./pages/Settings";
// import Support from "./pages/Support";
// import CustomizeReport from "./pages/CustomizeReport";
// import BrandSelection from "./pages/BrandSelection";
// import FilteredSearchMobile from "./pages/FilteredSearchMobile";
// import VinScan from "./pages/VinScan";
// import OverViewDetail from "./pages/ViewsDetailsBtn/OverView";
// import MaintenancePlan from "./pages/ViewsDetailsBtn/MaintenancePlan";
// import TotalCostOwner from "./pages/ViewsDetailsBtn/TotalCostOwner";
// import ServicePlan from "./pages/ViewsDetailsBtn/ServicePlan";
// import FinanceCalculate from "./pages/ViewsDetailsBtn/FinanceCalculate";
// import Competitor from "./pages/ViewsDetailsBtn/Competitor";
// import DetailSpecs from "./pages/ViewsDetailsBtn/DetailSpec";
// import ResellValue from "./pages/ViewsDetailsBtn/ResellValue";
// import Basket from "./pages/ViewsDetailsBtn/Basket";
// import RatesTenK from "./pages/ViewsDetailsBtn/RatesTenK";
// import UndentifiedVin from "./pages/undentifiedVin";
// import FilteredSearch, {
//   FilteredSearchViewType,
// } from "./modals/filtered-search";
// import CreateReport from "./modals/create-report";
// import Loading from "./components/loading";
// import Preowned from "./pages/Preowned";
// import WatchVideo from "./modals/watch-video";
// import Subscription from "./pages/Subscription";
// import ParameterSearch, { ParameterSearchViewType } from "./modals/parameter-search";
// import ParameterResultView from "./modals/parameter-search/parameter-result-view";
// import ShareSocial from "./modals/share-social";
// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// const StackContainer: React.FC = () => {
//   const sessionState = useSelector((state: RootState) => state.session);
//   const uiState = useSelector((state: RootState) => state.ui);
//   const dispatch = useDispatch();
//   // dispatch(setIsDesktop(true));
//   // if (uiState.isDesktop === undefined) {
//   //   dispatch(setIsDesktop(false));
//   // }

//   return (
//     <>
//       <Route
//         exact
//         render={(props) => {
//           if (!sessionState.user) {
//             return <Login />;
//           } else if (!sessionState.termsAcceped && uiState.isDesktop) {
//             return <Welcome />;
//           } else {
//             switch (uiState.screen) {
//               case AppScreens.Home:
//                 return <Home />;
//               case AppScreens.Welcome:
//                 return <Welcome />;
//               case AppScreens.Garage:
//                 return <Garage />;
//               case AppScreens.Reviews:
//                 return <Reviews />;
//               case AppScreens.Competitors:
//                 return <Competitors />;
//               case AppScreens.Rates10K:
//                 return <Rates10K />;
//               case AppScreens.Parts:
//                 return <Parts />;
//               case AppScreens.TCO:
//                 return <Tco />;
//               case AppScreens.Maintenance:
//                 return <Maintenance />;
//               case AppScreens.Services:
//                 return <Services />;
//               case AppScreens.Specs:
//                 return <Specs />;
//               case AppScreens.VehicleLanding:
//                 return <VehicleLanding />;
//               case AppScreens.VehicleDetail:
//                 return <VehicleDetail />;
//               case AppScreens.SearchResult:
//                 return <SearchResult />;

//               case AppScreens.FinanceCalculator:
//                 return <FinanceCalculator />;
//               case AppScreens.Tutorial:
//                 return <Tutorial />;
//               case AppScreens.About:
//                 return <About />;
//               case AppScreens.Contact:
//                 return <Contact />;
//               case AppScreens.ResidualValue:
//                 return <ResidualValue />;
//               case AppScreens.Depreciation:
//                 return <Depreciation />;
//               case AppScreens.Notifications:
//                 return <Notifications />;
//               case AppScreens.UserProfile:
//                 return <UserProfile />;
//               case AppScreens.Support:
//                 return <Support />;
//               case AppScreens.Settings:
//                 return <Settings />;
//               case AppScreens.CustomizeReport:
//                 return <CustomizeReport />;
//               case AppScreens.BrandSelection:
//                 return <BrandSelection />;
//               case AppScreens.FilteredSearchMobile:
//                 return <FilteredSearchMobile />;
//               case AppScreens.Preowned:
//                 return <Preowned />;
//               case AppScreens.VinScan:
//                 return <VinScan />;
//               case AppScreens.UndentifiedVin:
//                 return <UndentifiedVin />;
//               case AppScreens.Subscription:
//                 return <Subscription />;
//               case AppScreens.OverViewDetail:
//                 return <OverViewDetail />;
//               case AppScreens.MaintenancePlan:
//                 return <MaintenancePlan />;
//               case AppScreens.ServicePlan:
//                 return <ServicePlan />;
//               case AppScreens.TotalCostOwner:
//                 return <TotalCostOwner />;
//               case AppScreens.FinanceCalculate:
//                 return <FinanceCalculate />;
//               case AppScreens.Competitor:
//                 return <Competitor />;
//               case AppScreens.DetailSpecs:
//                 return <DetailSpecs />;
//               case AppScreens.ResellValue:
//                 return <ResellValue/>
//                 case AppScreens.Basket:
//                   return <Basket/>
//               case AppScreens.RatesTenK:
//                 return <RatesTenK/>
//               default:
//                 return <Login />;
//             }
//           }
//         }}
//       />
//       <FilteredSearch
//         viewType={FilteredSearchViewType.SearchResult}
//         isVisible={uiState.filteredSearchVisible}
//         onClosed={() => dispatch(setFilteredSearchVisible(false))}
//       />
//       <ParameterSearch
//       viewType={ParameterSearchViewType.SearchResult}
//       isVisible={uiState.parameterSearchVisible}
//       onClosed={() => dispatch(setParameterSearchVisible(false))}
//       />
//       <FilteredSearch
//         viewType={FilteredSearchViewType.SwapOut}
//         isVisible={uiState.swapoutSearchVisible}
//         onClosed={() => dispatch(setSwapoutSearchVisible(false))}
//       />
//       <CreateReport
//         isVisible={uiState.createReportVisible}
//         onClosed={() => dispatch(setCreateReportVisible(false))}
//       />
//       <WatchVideo
//         isVisible={uiState.watchVideoModalVisible}
//         onClosed={() => dispatch(setWatchVideoModalVisible(false))}
//       />

//       <ShareSocial
//       isVisible={uiState.ShareSocialVisible}
//       onClosed={() => dispatch(setShareSocialVisible(false))}
//       />
//     </>
//   );
// };

// export default StackContainer; 

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { AppScreens } from "./enums";
import {
  setCreateReportVisible,
  setFilteredSearchVisible,
  setIsDesktop,
  setParameterSearchVisible,
  setShareSocialVisible,
  setSwapoutSearchVisible,
  setUndentifiedVinSearchVisible,
  setWatchVideoModalVisible,
} from "./redux/ui/uiSlice";
import Home from "./pages/Home";
import Login from "./pages/Login";
import FilteredSearch, {
  FilteredSearchViewType,
} from "./modals/filtered-search";
import Welcome from "./pages/Welcome";
import Garage from "./pages/Garage";
import Reviews from "./pages/Reviews";
import Competitors from "./pages/Competitors";
import Rates10K from "./pages/Rates10K";
import Parts from "./pages/Parts";
import Tco from "./pages/Tco";
import Maintenance from "./pages/Maintenance";
import Services from "./pages/Services";
import Specs from "./pages/Specs";
import VehicleLanding from "./pages/VehicleLanding";
import SearchResult from "./pages/SearchResult";
import FinanceCalculator from "./pages/FinanceCalculator";
import Tutorial from "./pages/Tutorial";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ResidualValue from "./pages/ResidualValue";
import Depreciation from "./pages/Depreciation";
import Notifications from "./pages/Notifications";
import UserProfile from "./pages/UserProfile";
import Support from "./pages/Support";
import Settings from "./pages/Settings";
import { IonRouterOutlet } from "@ionic/react";
import CustomizeReport from "./pages/CustomizeReport";
import BrandSelection from "./pages/BrandSelection";
import FilteredSearchMobile from "./pages/FilteredSearchMobile";
import Preowned from "./pages/Preowned";
import VehicleDetail from "./pages/VehicleDetail";
import VinScan from "./pages/VinScan";
import Subscription from "./pages/Subscription";
import OverViewDetail from "./pages/ViewsDetailsBtn/OverView";
import MaintenancePlan from "./pages/ViewsDetailsBtn/MaintenancePlan";
import ServicePlans from "./pages/ViewsDetailsBtn/ServicePlan";
import TotalCostOwner from "./pages/ViewsDetailsBtn/TotalCostOwner";
import FinanceCalculate from "./pages/ViewsDetailsBtn/FinanceCalculate";
import Competitor from "./pages/ViewsDetailsBtn/Competitor";
import DetailSpecs from "./pages/ViewsDetailsBtn/DetailSpec";
import ResellValue from "./pages/ViewsDetailsBtn/ResellValue";
import Basket from "./pages/ViewsDetailsBtn/Basket";
import RatesTenK from "./pages/ViewsDetailsBtn/RatesTenK";
import CreateReport from "./modals/create-report";
import ParameterSearch, {
  ParameterSearchViewType,
} from "./modals/parameter-search";
import ShareSocial from "./modals/share-social";
import WatchVideo from "./modals/watch-video";
import Cart from "./pages/Cart";
import UndentifiedVinModal, {UndetinfiedVinSearchViewType}from "./modals/UndentifiedVin";
import ScanResults from "./pages/ViewsDetailsBtn/ScanResults";
import SDKProvider from "./pages/UserProfile/ScanComponents/sdk";
import { StoreProvider } from "./pages/UserProfile/ScanComponents/store";



const StackContainer: React.FC = () => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/welcome" component={Welcome} />
        {sessionState.user ? (
          <>
            <Redirect to="/home" />
            <Route path="/Cart" component={Cart} />
            <Route path="/home" component={Home} />
            <Route path="/Welcome" component={Welcome} />
            <Route path="/Garage" component={Garage} />
            <Route path="/Reviews" component={Reviews} />
            <Route path="/Competitors" component={Competitors} />
            <Route path="/Rates10K" component={Rates10K} />
            <Route path="/Parts" component={Parts} />
            <Route path="/TCO" component={Tco} />
            <Route path="/Maintenance" component={Maintenance} />
            <Route path="/Services" component={Services} />
            <Route path="/Specs" component={Specs} />
            <Route path="/VehicleLanding" component={VehicleLanding} />
            <Route path="/SearchResult" component={SearchResult} />
            <Route path="/FinanceCalculator" component={FinanceCalculator} />
            <Route path="/Tutorial" component={Tutorial} />
            <Route path="/About" component={About} />
            <Route path="/Contact" component={Contact} />
            <Route path="/ResidualValue" component={ResidualValue} />
            <Route path="/Depreciation" component={Depreciation} />
            <Route path="/Notifications" component={Notifications} />
          
            <Route path="/UserProfile" component={UserProfile} />
            
            <Route path="/Support" component={Support} />
            <Route path="/Settings" component={Settings} />
            <Route path="/CustomizeReport" component={CustomizeReport} />
            <Route path="/BrandSelection" component={BrandSelection} />
            <Route
              path="/FilteredSearchMobile"
              component={FilteredSearchMobile}
            />
            <Route path="/VehicleDetail" component={VehicleDetail} />
            <Route path="/Preowned" component={Preowned} />
            <Route path="/VinScan" component={VinScan} />
            {/* <Route path="/UndentifiedVin" component={UndentifiedVin} /> */}
            <Route path="/Subscription" component={Subscription} />
            <Route path="/OverViewDetail" component={OverViewDetail} />
            <Route path="/MaintenancePlan" component={MaintenancePlan} />
            <Route path="/ServicePlan" component={ServicePlans} />
            <Route path="/TotalCostOwner" component={TotalCostOwner} />
            <Route path="/FinanceCalculate" component={FinanceCalculate} />
            <Route path="/Competitor" component={Competitor} />
            <Route path="/DetailSpecs" component={DetailSpecs} />
            <Route path="/ResellValue" component={ResellValue} />
            <Route path="/Basket" component={Basket} />
            <Route path="/ScanResults" component={ScanResults}/>
            <Route path="/RatesTenK" component={RatesTenK} />
          </>
        ) : (
          <>
            <Route path="/" exact component={Login} />
            <Redirect to="/" />
          </>
        )}
        <Route component={Home} /> {/* This will act as a fallback route */}
      </Switch>

      <FilteredSearch
        viewType={FilteredSearchViewType.SearchResult}
        isVisible={uiState.filteredSearchVisible}
        onClosed={() => dispatch(setFilteredSearchVisible(false))}
      />
      <ParameterSearch
        viewType={ParameterSearchViewType.SearchResult}
        isVisible={uiState.parameterSearchVisible}
        onClosed={() => dispatch(setParameterSearchVisible(false))}
      />

      <UndentifiedVinModal
      viewType={UndetinfiedVinSearchViewType.SearchResult}
      isVisible={uiState.UndentifiedCinSearchVisible}
      onClosed={() => dispatch(setUndentifiedVinSearchVisible(false))}
      />
      <FilteredSearch
        viewType={FilteredSearchViewType.SwapOut}
        isVisible={uiState.swapoutSearchVisible}
        onClosed={() => dispatch(setSwapoutSearchVisible(false))}
      />
      <CreateReport
        isVisible={uiState.createReportVisible}
        onClosed={() => dispatch(setCreateReportVisible(false))}
      />
      <WatchVideo
        isVisible={uiState.watchVideoModalVisible}
        onClosed={() => dispatch(setWatchVideoModalVisible(false))}
      />

      <ShareSocial
        isVisible={uiState.ShareSocialVisible}
        onClosed={() => dispatch(setShareSocialVisible(false))}
      />
    </Router>
  );
};

export default StackContainer;
