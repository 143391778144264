import { IonCol, IonContent, IonPage, IonRow } from "@ionic/react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { SelectedVehicle } from "../../interfaces";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import { Attributes } from "../../interfaces/modelSpecification";
import { format } from "../../utils";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const Specs: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const renderStar = (powerKw: any) => {
    const kWThreshold = 140;
    // Check if power is more than 140 kW
    if (powerKw && parseInt(powerKw) > kWThreshold) {
      // Render the star or any other indicator
      return <span className="goldStar">&#9733;</span>;
    } else {
      //The
      return null;
    }
  };

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: "",
    };
    return vehicle;
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <GlobalGrid
      currentPageTitle="DETAILED SPECS"
      closeCurrentPage={() => onBackPress()}
    >
      <div className="innerContent">
        <SubPageHeading onBack={onBackPress} vehicle={uiState.selectedVehicle}>
          {" "}
        </SubPageHeading>
      </div>
      <DesktopContainer>
        <strong className="FontMobileSpcs">BASIC SPECIFICATION</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Model Class
              <br />
              Body Style
              <br />
              Status
              <br />
              Fuel Type
              <br />
              Transmission
              <br />
              List Price
              <br />
              Kw
              <br />
              Nm
              <br />
              Drive
              <br />
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  ?.basic_specs?.model_class
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelClass?.result[0]
                  .modelClassShortDescription
              }
              <br />
              {uiState.selectedVehicle?.data?.modelClass?.result[0].status}
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.fuel_type
              }
              <br />
              {uiState.selectedVehicle?.data?.modelSpecification.result.basic_specs.manual_or_auto.toUpperCase()}
              <br />
              {format(
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.base_price,
                Format.Currency
              )}
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.max_power_kw
              }
              Kw @
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.max_power_kw_rpm
              }
              rpm{" "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  ?.performance_consumption.max_power_kw
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.torque_nm
              }
              Nm @
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.torque_nm_rpm
              }
              rpm
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.wheel_drive
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">SERVICE &amp; MAINTENANCE</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Service Units
              <br />
              First Units
              <br />
              Service Intervals
              <br />
              Service Plan
              <br />
              Maintenance Plan
              <br />
              Warranty
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.service_unit
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.first_service
              }
              <br />
              {format(
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.service_interval,
                Format.Number
              )}
              {" km "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.service_interval_months
              }{" "}
              mnt
              <br />
              {format(
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.service_plan,
                Format.Number
              )}
              {" km "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.service_plan_months
              }{" "}
              mnt
              <br />
              {format(
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.maintenance_plan,
                Format.Number
              )}
              {" km "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.maintenance_plan_months
              }{" "}
              mnt
              <br />
              {format(
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.warranty,
                Format.Number
              )}
              {" km "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .service_maintenance_warranty.warranty_months
              }{" "}
              mnt
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">ENGINE SPECIFICATIONS</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Capacity
              <br />
              Description
              <br />
              No of Cylinders
              <br />
              Bore Stroke
              <br />
              Compression Ratio
              <br />
              No of Valves
              <br />
              Ignition Type
              <br />
              Aspiration Type
              <br />
              Hybrid
              <br />
              Fuel Injection
              <br />
              Commonrail
              <br />
              2nd Fuel Tank
              <br />
              Emissions
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.capacity
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.engine
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.cylinders
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.bore_stroke
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.comp_ratio
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.valves
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.ignition
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.aspiration
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.hybrid
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.fuel_injection
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.commonrail
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.second_fueltank
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .engine_specs.co2
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">DIMENSIONS &amp; WEIGHT</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Overall Length
              <br />
              Overall Width
              <br />
              Overall Height
              <br />
              Wheel Base
              <br />
              Ground Clearance
              <br />
              Cargo Mass (GCM)
              <br />
              Vehicle Mass (GVM)
              <br />
              Boot Capacity
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.overall_length
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.overall_width
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.overall_height
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.wheel_base
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.ground_clearance
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.mass
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.gross
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .dimensions_weight.boot_capacity
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">PERFORMANCE &amp;CONSUMPTION</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Fuel Supply Type
              <br />
              Consumption (Urban)
              <br />
              Consumption (Rural)
              <br />
              Consumption (Avg)
              <br />
              Fuel Tank Capacity
              <br />
              Max Speed
              <br />
              Acceleration 0-100kph
              <br />
              Deceleration 100-0kph
              <br />
              Max Power (KW)
              <br />
              Max Torque (NM)
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.fuel_supply
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.fuel_consumption_urban
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.fuel_consumption_rural
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.fuel_avg
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.fuel_tank_capacity
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.max_speed
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.acc_0_100kph
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.dec_100_0kph
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.max_power_kw
              }{" "}
              kw /{" "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.max_power_kw_rpm
              }{" "}
              rpm
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.torque_nm
              }{" "}
              nm /{" "}
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .performance_consumption.torque_nm_rpm
              }{" "}
              rpm
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">SUSPENSION &amp; STEERING</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />

            <p className="alignRight desktopLabelLower">
              Suspension Type (Front)
              <br />
              Suspension Type (Rear)
              <br />
              Self Leveling Suspension
              <br />
              Steering Type
              <br />
              Power Assist Steering
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .suspension_steering.suspension_type_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .suspension_steering.suspension_type_rear
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .suspension_steering.self_leveling_suspension
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .suspension_steering.steering_type
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .suspension_steering.power_steering
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">
          STANDARD &amp; OPTIONAL ITEMS
        </strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Air Conditioning
              <br />
              Automatic A/C
              <br />
              Climate Control
              <br />
              Electric Windows
              <br />
              Electric Windows Details
              <br />
              E - Windows Onetouch
              <br />
              Sliding Rear Window
              <br />
              Tinted Windscreen
              <br />
              Tinded Side Windows
              <br />
              Audio System
              <br />
              AM/FM Radio
              <br />
              Tape Deck
              <br />
              CD Player
              <br />
              Shuttle CD Player
              <br />
              Handbrake Position
              <br />
              Multi Function Wheel
              <br />
              Steering Height Adjust
              <br />
              Steering Reach Adjust
              <br />
              Steering Wheel Electric
              <br />
              Steering Wheel Memory
              <br />
              Heated Seats
              <br />
              Electric Seat Adjustment
              <br />
              Electric Seat Detail
              <br />
              Electric Seat Memory
              <br />
              Sliding Panoramic Roof
              <br />
              Sliding Panoramic Roof(s)
              <br />
              Cruise Control
              <br />
              Rear Window Demister
              <br />
              Rear Window Wiper
              <br />
              Auto Windscreen Wiper
              <br />
              Speed Sensitive Wipers
              <br />
              Onboard Computer
              <br />
              Cup Holders
              <br />
              Headrest Front
              <br />
              Headrest Rear
              <br />
              Foglamp Front
              <br />
              Foglamp Rear
              <br />
              Auto Head Lights
              <br />
              Xenon Head Lights
              <br />
              Remote Boot Opener
              <br />
              Remote Fuel Cap Open
              <br />
              Hi-Level Brake Light
              <br />
              Hands Free Phone
              <br />
              Cell Phone Integration
              <br />
              Compass
              <br />
              Compass Details
              <br />
              Satelite Navigation
              <br />
              Rev Counter
              <br />
              Rear Seat Split
              <br />
              Service Indicator
              <br />
              2nd Row E - Windows
              <br />
              Tailgate Window
              <br />
              Sunroof
              <br />
              Panoramic Roof
              <br />
              DVD Player
              <br />
              MP3 Player
              <br />
              ICE Power AUX Input
              <br />
              Ipod Integration
              <br />
              Rear Entertainment
              <br />
              Bluetooth
              <br />
              Fold/Tumble 2nd Row
              <br />
              Removable 2nd Row
              <br />
              Fold/Tumble 3nd Row
              <br />
              Removable 3nd Row
              <br />
              Auto Light Dim Interior
              <br />
              Auto Light Dim Exterior
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .air_conditioner
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .automatic_air_conditioner
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .climate_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_windows
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_windows_details
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_windows_onetouch
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .sliding_rear_window_ldv
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .tinted_windscreen
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .tinted_side_windows
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .audio_system
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.radio
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.tape
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.cd_player
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.cd_shuttle
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .handbrake_position
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .steering_wheel_multi_function
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .steering_column_height_adjust
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .steering_column_reach_adjust
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .steering_wheel_electric
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .steering_wheel_memory
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .heated_seats
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_seat_adjustment
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_seat_adj_detail
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_seat_memory
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .sliding_panoramic_roof
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .no_of_sliding_roofs
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .cruise_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .rear_screen_demister
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .rear_screen_wiper
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .auto_windscreen_wipers
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .speed_sensitive_wipers
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .onboard_computer
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.cup_holders
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .head_rest_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .head_rest_rear
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .foglamps_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .foglamps_rear
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .auto_head_lamps
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .xenon_head_lamps
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .remote_boot_opener
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .remote_fuel_flap_opener
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .hi_level_brake_light
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .handsfree_phone
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .cellphone_prep_ficed
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.compass
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .compass_detail
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .satelite_navigation
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.rev_counter
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .rear_seat_split
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .service_indicator
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .electric_windows_r2
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .opening_tailgate_window
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.sunroof
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .panoramic_roof
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.dvd
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.mp3
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .ice_aux_input
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .ipod_integration
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .rear_entertainment_system
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior.bluetooth
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .tumble_2nd_row
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .removable_2nd_row
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .tumble_3rd_row
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .removable_3rd_row
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .auto_dim_interior
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.interior_and_exterior
                  .auto_dim_exterior
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">BODY &amp; STYLING</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <p className="alignRight desktopLabelLower">
              Seat Trim
              <br />
              Remote Side Mirrors
              <br />
              Electric Side Mirrors
              <br />
              Memory Side Mirrors
              <br />
              Folding Side Mirrors
              <br />
              Heated Side Mirrors
              <br />
              Metallic Paint
              <br />
              Body Coloured Bumper
              <br />
              Mirror Color
              <br />
              Rear Spoiler
              <br />
              Alloy Wheels
              <br />
              Auto Locking Doors
              <br />
              Tyre Pressure Monitor
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            {/* <strong>&nbsp;</strong> */}
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.leather
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .remote_side_mirrors
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .remote_side_mirrors_electric
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .remote_electric_mirror_memory
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .electric_folding_mirrors
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .electric_heated_mirrors
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.metallic_paint
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .body_colored_bumper
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.mirror_color
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.rear_spoiler
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.alloy_wheels
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling.autolock_doors
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.body_and_styling
                  .tyre_pressure_monitor
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">SAFETY</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <p className="alignRight desktopLabelLower">
              Driver Airbag
              <br />
              Passenger Airbag
              <br />
              Side Airbag
              <br />
              No of Airbags
              <br />
              Airbag Details
              <br />
              Side Impact Protection
              <br />
              Traction Control (ATC)
              <br />
              ABS
              <br />
              Impact Tension Seatbelt
              <br />
              Child Seat Fitting
              <br />
              PDC (Rear)
              <br />
              PDC (Front)
              <br />
              Hill Descent Control
              <br />
              Incline Meter
              <br />
              Altitude Meter
              <br />
              Cruise Control Sensor
              <br />
              Collision Warning
              <br />
              Blindspot Warning
              <br />
              Lane Change Warning
              <br />
              Lane Assist
              <br />
              Side Airbag Front
              <br />
              Side Airbag Rear
              <br />
              Knee Airbag
              <br />
              Curtain Airbag
              <br />
              Headlight Height Adjust
              <br />
              Daytime Running Lights
              <br />
              Cornering Lights
              <br />
              EBD
              <br />
              Brake Apply Sensor
              <br />
              Cornering Brake Control
              <br />
              Brake Hazard Lights
              <br />
              Spare Wheel Type
              <br />
              Run Flat Tyres
              <br />
              Tyre Repair Kit
            </p>
          </IonCol>
          <IonCol size="5" className="colBorderLeft colBorderTop">
            <strong>&nbsp;</strong>
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.driver_airbag
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.passenger_airbag
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.side_airbag
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.no_of_airbags
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.airbag_detail
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.side_impact_protection
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.auto_traction_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.abs
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety
                  .impact_tensioning_seatbelts
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.isofix
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.park_distance_control_rear
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety
                  .park_distance_control_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.descent_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.incline_meter
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.altimeter
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety
                  .cruise_control_distance_sensor
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.collision_warning_system
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.blindspot_warning
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.lane_change_warning
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.lane_assist
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.side_airbag_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.side_airbag_rear
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.knee_airbag
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.curtain_airbag
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.headlights_height_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.lights_daytime_running
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.lights__cornering
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety
                  .electronic_brake_distribution
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.brake_apply_sensor
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.cornering_brake_control
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.brake_hazard_lights
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.spare_wheel_type
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.run_flat_tyres
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.safety.tyre_repair_kit
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">SECURITY</strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <p className="alignRight desktopLabelLower">
              Central Locking
              <br />
              Security System
              <br />
              Alarm System
              <br />
              Immobiliser
              <br />
              Gearlock
              <br />
              Code Entry
              <br />
              Keyless Entry
            </p>
          </IonCol>
          <IonCol size="2.5" className="colBorderLeft colBorderTop">
            <strong>&nbsp;</strong>
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.central_locking
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.security_system
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.alarm
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.immobiliser
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.gearlock
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.code_entry
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .optional_and_standard_items.security.keyless_entry
              }
            </p>
          </IonCol>
        </IonRow>
        <strong className="FontMobileSpcs">
          DRIVETRAIN &amp; TRANSMISSION
        </strong>
        <IonRow className="Specs">
          <IonCol size="5" className="colBorderTop">
            <br />
            <br />
            <p className="alignRight desktopLabelLower">
              Transmission Description
              <br />
              No of Gears
              <br />
              Driving Wheels
              <br />
              Diff Lock
              <br />
              Diff Lock Position
              <br />
              Wheel Drive
              <br />
              Full Time 4X4
              <br />
              Limited Slip Diff
              <br />
              Low Ratio
              <br />
              Brake Type (Front)
              <br />
              Brake Type (Rear)
            </p>
          </IonCol>
          <IonCol size="2.5" className="colBorderLeft colBorderTop">
            <strong>&nbsp;</strong>
            <br />
            <br />
            <p className="desktopValue colSpace">
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.transmission_description
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.no_gears
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.drive_wheels
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.diff_lock
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.diff_position
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.wheel_drive
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.full_4x4
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.limited_slip_diff
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.low_ratio
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.break_type_front
              }
              <br />
              {
                uiState.selectedVehicle?.data?.modelSpecification.result
                  .drivetrain_transmission.break_type_rear
              }
            </p>
          </IonCol>
        </IonRow>
      </DesktopContainer>
    </GlobalGrid>
  );
};

export default Specs;
