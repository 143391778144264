import { IonCol, IonRow } from '@ionic/react';
import './style.css';
import { images } from '../../assets';
import Button from '../button';
import { useCallback } from 'react';
import Link from '../link';

interface MessageModalProps {
  onPress?: (buttonIndex: number) => void;
  icon?: string;
  message?: string;
  visible: boolean;
  button1?: string;
  button2?: string;
  serviceInfoModal?: boolean;
  tcoInfoModal?: boolean;
  partsInfoModal?: boolean;
  ratesInfoModal?: boolean;
}

const MessageModal: React.FC<MessageModalProps> = ({
  onPress,
  icon,
  message,
  visible,
  button1,
  button2,
  serviceInfoModal,
  tcoInfoModal,
  partsInfoModal,
  ratesInfoModal,
}: MessageModalProps) => {
  const onButtonPress = useCallback(
    (buttonIndex: number) => {
      if (onPress) {
        onPress(buttonIndex);
      }
    },
    [onPress]
  );

  return (
    <>
      {visible ? (
        <div className='modalOverlay' onClick={() => onButtonPress(-1)}>
          <div className='modalBox'>
            <IonRow class='ion-align-items-center'>
              <IonCol class='ion-align-items-center'>
                <img
                  src={icon ? icon : images.infoIcon}
                  className='infoIcon'
                  alt=''
                />
              </IonCol>
            </IonRow>
            {message ? (
              <IonRow>
                <IonCol className='modalText'>{message}</IonCol>
              </IonRow>
            ) : null}
            {serviceInfoModal ? (
              <IonRow>
                <IonCol className='modalText'>
                  <p>
                    <strong>
                      Schedule is compiled with following papameters.
                    </strong>
                  </p>
                  <ul>
                    <li>Standard operating conditions</li>
                    <li>Total life-span of 300,000 Km</li>
                    <li>Traveling 20,000Km per year</li>
                    <li>Standerd available service plan is included.</li>
                  </ul>
                </IonCol>
              </IonRow>
            ) : null}
            {tcoInfoModal ? (
              <IonRow>
                <IonCol className='modalText'>
                  <p>
                    <strong>
                      The TCO (Total Cost of Ownership) report is calculated
                      using the follow parameters.
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Financed over outwit at 7.5:0 vitn 10% Deposit Total
                    </li>
                    <li>
                      life-span of the calculation is 150,000km Travelling
                    </li>
                    <li>
                      20,000km per year. Available service &amp; maintenance
                      plans are
                    </li>
                    <li>
                      included OEM Fuel type and average consumption is used.
                      All
                    </li>
                    <li>tires (excl. spare) is replaced every 80,000km</li>
                  </ul>
                </IonCol>
              </IonRow>
            ) : null}
            {partsInfoModal ? (
              <IonRow>
                <IonCol className='modalText'>
                  <p>
                    <strong>
                      The Parts Basket includes the follow parameters.
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Recommended retail price excl. VAT. Prices in all reports
                    </li>
                    <li>were updated 30 Days ago.</li>
                  </ul>
                </IonCol>
              </IonRow>
            ) : null}
            {ratesInfoModal ? (
              <IonRow>
                <IonCol className='modalText'>
                  <p>
                    <strong>
                      The Rate Table report below is compiled using the follow
                      parameters.
                    </strong>
                  </p>
                  <ul>
                    <li>Total life-span of 300,000 Km</li>
                    <li>Traveling 20,000Km per year</li>
                  </ul>
                </IonCol>
              </IonRow>
            ) : null}
            {button1 ? (
              <IonRow>
                <IonCol>
                  <Button onPress={() => onButtonPress(1)} title={button1} />
                </IonCol>
              </IonRow>
            ) : null}
            {button2 ? (
              <IonRow>
                <IonCol className='button2'>
                  <br/>
                  <Button
                    // color={'#000000'}
                    onPress={() => onButtonPress(2)}
                    title={button2}
                    
                  />
                </IonCol>
              </IonRow>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MessageModal;
