import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonRange,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FuelPrices, TCO, getImage } from "../../api/mapo";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import Header from "../../components/header";
import MessageModal from "../../components/message-modal";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format, OptionalData } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import { CompetitorData } from "../../interfaces/competitorData";
import { format } from "../../utils";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { useHistory } from "react-router-dom";
import DataAssurance from "../../components/dataAssurance";
import {
  chevronForward,
  chevronDown,
  chevronUp,
  caretBackOutline,
  caretForwardOutline,
  chevronBack,
} from "ionicons/icons";
import { icons, images } from "../../assets";
import { debounce } from "lodash";
import Whatsapp from "../../components/whatsapp/whatsapp";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import React from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

type PriceInfoItem = {
  iteration: any;
  partName: string;
  nextPrice: number;
  interval: any;
};

type MaintenanceInfoItem = {
  iteration: any;
  partName: string;
  Name: string;
  nextPrice: number;
  interval: any;
};

type FuelPrice = {
  //Diesel prices
  d_05_reef: any;
  d_01_reef: any;
  d_05_coast: any;
  d_01_coast: any;
  //Petrol prices
  p_unleaded_93_reef: any;
  p_unleaded_95_reef: any;
  p_lpr_93_reef: any;
  p_unleaded_93_coast: any;
  p_unleaded_95_coast: any;
  p_lpr_93_coast: any;
};

const Desktopoptions = {
  // responsive: false,
  // maintainAspectRatio: false,
  // aspectRatio: 0,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const desktopChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  spanGaps: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

interface TCOData {
  result: {
    country: string;
    lifeTime: number;
    serviceCost: number;
    serviceCostPerKm: number;
    maintenanceCost: number;
    maintenanceCostPerKm: number;
    maintenanceAdditionalCost: number;
    maintenanceAdditionalCostPerKm: number;
    tyreCostPerKm: number;
    tyreCost: number;
    fuelCostPerKm: number;
    fuelCost: number;
    residualValue: number;
    residualValuePerKm: number;
    depreciationValue: number;
    depreciationPerKm: number;
    financeCost: number;
    financeCostPerKm: number;
    financeCostBase: number;
    financeCostBasePerKm: number;
    financeCostInterest: number;
    financeCostPerInterestKm: number;
    insuranceCost: number;
    insuranceCostPerKm: number;
  };
}

const Tco: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositAmoun, setDepositAmoun] = useState(0);
  const [paymentTerm, setPaymentTerm] = useState(60);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const servicePlanMonths =
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan_months;
  const ServicePlanKm =
    Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    ) || 0;

  // If ServicePlanKm is 999999, treat it as zero
  const adjustedServicePlanKm = ServicePlanKm === 999999 ? 0 : ServicePlanKm;
  const servicePlanYears = Number(servicePlanMonths) / 12;
  const [rangeValue, setRangeValue] = useState(25000);
  const [balloonPayment, setBalloonPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(11);
  const [interestRat, setInterestRat] = useState(11);
  const [paymentTer, setPaymentTer] = useState(60);
  const [tyreChangeInterval] = useState(60000);
  const [financeResidual, setFinanceResidual] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [monthlyInstallment, setMonthlyInstallment] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const [basePrice, setBasePrice] = useState(
    uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
      .base_price
  );
  //Cash price with the vat
  const [cashPrice, setcashPrice] = useState(
    Number(
      uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
        .base_price
    )
  );

  const [FinanceCash, setFinanceCash] = useState(true);

  const [kmFromSlider, setKmFromSlider] = useState(0);
  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
            .length - 1
        ].interval
      : 0
  );
  const [tcoData, setTcoData] = useState<TCOData[]>([]);
  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  const [AdditMaint, setAdditMaint] = useState("");

  let touchStartX = 0;
  const [calcDuration, setCalcDuration] = useState(0);

  useEffect(() => {
    setCalcDuration(Number(kmToSlider - kmFromSlider));
  }, [kmFromSlider, kmToSlider]);

  const [GasPrice, setGasPrice] = useState<FuelPrice[]>([]);
  const [FuelPrice, setFuelPrice] = useState<number>(0);

  const onChangeAdditionalCost = (event: any) => {
    setAdditMaint(event.target.value);
  };
  //States for the toggling btn
  const [InsuranceToggle, setInsuranceToggled] = useState(true);
  const [FinanceToggle, setFinanceToggle] = useState(true);
  const [InterestToggle, setInterestToggle] = useState(true);
  const [ServiceToggle, setServiceToggle] = useState(true);
  const [MaintenanceToggle, setMaintenanceToggle] = useState(true);
  const [FuelToggle, setFuelToggle] = useState(true);
  const [TyreToggle, setTyreToggle] = useState(true);
  const [DepreciationToggle, setDepreciationToggle] = useState(true);
  const [ResidualToggle, setResidualToggle] = useState(true);
  const [AdditionalToggle, setAdditionalToggle] = useState(true);

  const handleToggleChange = (e: any) => {
    setInsuranceToggled(e.detail.checked);
  };

  const FinanceToggleChange = (e: any) => {
    setFinanceToggle(e.detail.checked);
  };

  const InterestToggleChange = (e: any) => {
    setInterestToggle(e.detail.checked);
  };
  const ServiceToggleChange = (e: any) => {
    setServiceToggle(e.detail.checked);
  };

  const MaintenanceToggleChange = (e: any) => {
    setMaintenanceToggle(e.detail.checked);
  };
  const FuelToggleChange = (e: any) => {
    setFuelToggle(e.detail.checked);
  };

  const TyreToggleChange = (e: any) => {
    setTyreToggle(e.detail.checked);
  };

  const DepreciationToggleChange = (e: any) => {
    setDepreciationToggle(e.detail.checked);
  };
  const ResidualToggleChange = (e: any) => {
    setResidualToggle(e.detail.checked);
  };
  const AdditionalToggleChange = (e: any) => {
    setAdditionalToggle(e.detail.checked);
  };

  // const [isExpanded, setIsExpanded] = useState(false);

  // // Handle click to toggle expanded state
  // const handleExpandClick = () => {
  //   setIsExpanded(!isExpanded);
  // };

  // const [MaintExpanded, setMaintExpanded] = useState(false);

  // // Handle click to toggle expanded state
  // const MaintExpandClick = () => {
  //   setMaintExpanded(!MaintExpanded);
  // };

  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  // Handle click to toggle expanded section
  const handleExpandClick = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  useEffect(() => {
    if (GasPrice.length > 0) {
      const firstValidPrice = GasPrice.find(
        (price) => price.p_unleaded_95_reef !== null || price.d_05_reef !== null
      );
      if (firstValidPrice) {
        setFuelPrice(
          firstValidPrice.p_unleaded_95_reef || firstValidPrice.d_05_reef
        );
      }
    }
  }, [GasPrice]);

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );
  const totalServiceCosPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  const debouncedEffect = debounce(() => {
    if (!isSliding) {
      // Only perform TCO calculation if not sliding
      const { selectedVehicle } = uiState;
      if (selectedVehicle) {
        TCO(
          selectedVehicle.g_id,
          selectedVehicle.m_id,
          selectedVehicle.v_id,
          calcDuration,
          tyreChangeInterval,
          rangeValue,
          depositAmoun,
          interestRat,
          paymentTer,
          financeResidual
        )
          .then((response) => {
            const tcoDataFromResponse = response.data;
            setTcoData([tcoDataFromResponse]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, 500);

  // useEffect hook
  useEffect(() => {
    debouncedEffect();

    return () => {
      debouncedEffect.cancel();
    };
  }, [
    uiState.selectedVehicle,
    depositAmoun,
    interestRat,
    paymentTer,
    calcDuration,
    rangeValue,
    isSliding,
  ]);

  const onModalPress = useCallback(() => {
    setModalVisible(false);
  }, []);

  // const onMorePress = useCallback(() => {
  //   setModalVisible(true);
  // }, []);

  //for the ballon payment
  const handleTouchStart = () => {
    setIsTouching(true);
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
  };

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const showData = useCallback(
    (dataField: OptionalData, value?: number) => {
      if (dataField === OptionalData.Finance) {
        if (uiState.customReportParams.toggles.finance) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Service) {
        if (uiState.customReportParams.toggles.service) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Maintenance) {
        if (uiState.customReportParams.toggles.maintenance) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Fuel) {
        if (uiState.customReportParams.toggles.fuel) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Tyre) {
        if (uiState.customReportParams.toggles.tyre) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Depreciation) {
        if (uiState.customReportParams.toggles.depreciation) {
          return value ?? 0;
        } else {
          return 0;
        }
      } else if (dataField === OptionalData.Insurance) {
        if (uiState.customReportParams.toggles.insurance) {
          return value ?? 0;
        } else {
          return 0;
        }
      }
      return 0;
    },
    [uiState.customReportParams]
  );

  const renderLowestValue = useCallback(
    (
      dataField: OptionalData,
      index: number,
      val?: string,
      val1?: string,
      val2?: string,
      val3?: string
    ) => {
      let valInt = showData(dataField, Number(val ?? 0));

      if (index === 1) {
        valInt = showData(dataField, Number(val1 ?? 0));
      } else if (index === 2) {
        valInt = showData(dataField, Number(val2 ?? 0));
      } else if (index === 3) {
        valInt = showData(dataField, Number(val3 ?? 0));
      }

      return <span>{format(valInt, Format.Currency)}</span>;
    },
    [showData]
  );

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getBasePrice = useCallback(
    (index: number): number => {
      const competitorData = getData(index);
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.financeamount
          ? customParams.financeamount
          : competitorData.tcoData?.result?.financeCostBase;

        return (value ?? 0) < 0 ? value ?? 0 : (value ?? 0) * -1;
      } else {
        return 0;
      }
    },
    [getData, uiState.customReportParams]
  );

  const getTotalCost = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.totalcost
          ? (customParams.totalcost ?? 0) * -1
          : competitorData.tcoData?.result?.financeCost;
        return value ?? 0;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getFinanceCost = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.financeamount
          ? customParams.totalinterest
          : competitorData.tcoData?.result?.financeCostInterest;

        return (value ?? 0) < 0 ? value ?? 0 : (value ?? 0) * -1;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getFinanceCostPerKm = useCallback(
    (index: number) => {
      const basePricePerKm =
        getFinanceCost(index) / (uiState.customReportParams.totalLifeSpan ?? 0);
      return basePricePerKm;
    },
    [uiState.customReportParams, getFinanceCost]
  );

  const getBasePricePerKm = useCallback(
    (index: number) => {
      const basePricePerKm =
        getBasePrice(index) / (uiState.customReportParams.totalLifeSpan ?? 0);
      return basePricePerKm;
    },
    [uiState.customReportParams, getBasePrice]
  );

  const underServicePlan = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return true;
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return true;
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );

  const underMaintenancePlan = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return true;
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return true;
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );

  const getAdditionalServicePlanCost = useCallback(
    (vehicleIndex) => {
      let total = 0;
      getData(vehicleIndex)?.rates10kIntervalData?.map((interval, index) => {
        if (index * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
          total += underServicePlan(vehicleIndex, index * 10000)
            ? 0
            : interval.serviceCostTotal;
        }
      });
      return (total ?? 0) < 0 ? total ?? 0 : (total ?? 0) * -1;
    },
    [getData, uiState?.customReportParams, underServicePlan]
  );

  const getAdditionalServicePlanCostPerKm = useCallback(
    (index: number) => {
      const basePricePerKm =
        getAdditionalServicePlanCost(index) /
        (uiState.customReportParams.totalLifeSpan ?? 0);
      return basePricePerKm;
    },
    [uiState.customReportParams, getAdditionalServicePlanCost]
  );

  const getAdditionalMaintenancePlanCost = useCallback(
    (vehicleIndex) => {
      let total = 0;
      getData(vehicleIndex)?.rates10kIntervalData?.map((interval, index) => {
        if (index * 10000 < (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
          total += underMaintenancePlan(vehicleIndex, index * 10000)
            ? 0
            : interval.maintenanceCostTotal;
        }
      });
      return (total ?? 0) < 0 ? total ?? 0 : (total ?? 0) * -1;
    },
    [getData, uiState?.customReportParams, underMaintenancePlan]
  );

  const getAdditionalMaintenancePlanCostPerKm = useCallback(
    (index: number) => {
      const basePricePerKm =
        getAdditionalMaintenancePlanCost(index) /
        (uiState.customReportParams.totalLifeSpan ?? 0);
      return basePricePerKm;
    },
    [uiState.customReportParams, getAdditionalMaintenancePlanCost]
  );

  const getDepreciation = useCallback(
    (index: number) => {
      switch (uiState.customReportParams.totalLifeSpan) {
        case 10000:
          return getData(index)?.depreciation?.km_10k;
        case 20000:
          return getData(index)?.depreciation?.km_20k;
        case 30000:
          return getData(index)?.depreciation?.km_30k;
        case 40000:
          return getData(index)?.depreciation?.km_40k;
        case 50000:
          return getData(index)?.depreciation?.km_50k;
        case 60000:
          return getData(index)?.depreciation?.km_60k;
        case 70000:
          return getData(index)?.depreciation?.km_70k;
        case 80000:
          return getData(index)?.depreciation?.km_80k;
        case 90000:
          return getData(index)?.depreciation?.km_90k;
        case 100000:
          return getData(index)?.depreciation?.km_100k;
        case 110000:
          return getData(index)?.depreciation?.km_110k;
        case 120000:
          return getData(index)?.depreciation?.km_120k;
        case 130000:
          return getData(index)?.depreciation?.km_130k;
        case 140000:
          return getData(index)?.depreciation?.km_140k;
        case 150000:
          return getData(index)?.depreciation?.km_150k;
        case 160000:
          return getData(index)?.depreciation?.km_160k;
        case 170000:
          return getData(index)?.depreciation?.km_170k;
        case 180000:
          return getData(index)?.depreciation?.km_180k;
        case 190000:
          return getData(index)?.depreciation?.km_190k;
        case 210000:
          return getData(index)?.depreciation?.km_210k;
        case 220000:
          return getData(index)?.depreciation?.km_220k;
        case 230000:
          return getData(index)?.depreciation?.km_230k;
        case 240000:
          return getData(index)?.depreciation?.km_240k;
        case 250000:
          return getData(index)?.depreciation?.km_250k;
        case 260000:
          return getData(index)?.depreciation?.km_260k;
        case 270000:
          return getData(index)?.depreciation?.km_270k;
        case 280000:
          return getData(index)?.depreciation?.km_280k;
        case 290000:
          return getData(index)?.depreciation?.km_290k;
        case 300000:
          return getData(index)?.depreciation?.km_300k;
        default:
          return 0;
      }
    },
    [getData, uiState]
  );

  const renderTotalCost = useCallback(
    (index: Number) => {
      let total0 = 0;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;

      total0 += Math.abs(getBasePrice(0));
      total0 += Math.abs(getFinanceCost(0));
      total0 += Math.abs(getAdditionalServicePlanCost(0));
      total0 += Math.abs(getAdditionalMaintenancePlanCost(0));
      total0 += Math.abs(
        showData(
          OptionalData.Fuel,
          uiState.selectedVehicle?.data?.tcoData?.result?.fuelCost ?? 0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Insurance,
          uiState.selectedVehicle?.data?.tcoData?.result?.insuranceCost ?? 0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Tyre,
          uiState.selectedVehicle?.data?.tcoData?.result?.tyreCost ?? 0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Depreciation,
          uiState.selectedVehicle?.data?.tcoData?.result?.depreciationValue ?? 0
        )
      );
      total0 -= Math.abs(
        showData(
          OptionalData.Depreciation,
          uiState.selectedVehicle?.data?.tcoData?.result?.residualValue ?? 0
        )
      );

      total1 += Math.abs(getBasePrice(1));
      total1 += Math.abs(getFinanceCost(1));
      total1 += Math.abs(getAdditionalServicePlanCost(1));
      total1 += Math.abs(getAdditionalMaintenancePlanCost(1));
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[0].tcoData?.result?.fuelCost ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[0].tcoData?.result?.insuranceCost ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[0].tcoData?.result?.tyreCost ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[0].tcoData?.result?.depreciationValue ?? 0
            )
          : 0
      );
      total1 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[0].tcoData?.result?.residualValue ?? 0
            )
          : 0
      );

      total2 += Math.abs(getBasePrice(2));
      total2 += Math.abs(getFinanceCost(2));
      total2 += Math.abs(getAdditionalServicePlanCost(2));
      total2 += Math.abs(getAdditionalMaintenancePlanCost(2));
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[1].tcoData?.result?.fuelCost ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[1].tcoData?.result?.insuranceCost ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[1].tcoData?.result?.tyreCost ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[1].tcoData?.result?.depreciationValue ?? 0
            )
          : 0
      );
      total2 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[1].tcoData?.result?.residualValue ?? 0
            )
          : 0
      );

      total3 += Math.abs(getBasePrice(3));
      total3 += Math.abs(getFinanceCost(3));
      total3 += Math.abs(getAdditionalServicePlanCost(3));
      total3 += Math.abs(getAdditionalMaintenancePlanCost(3));
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[2].tcoData?.result?.fuelCost ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[2].tcoData?.result?.insuranceCost ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[2].tcoData?.result?.tyreCost ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[2].tcoData?.result?.depreciationValue ?? 0
            )
          : 0
      );
      total3 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[2].tcoData?.result?.residualValue ?? 0
            )
          : 0
      );

      if (index === 0) {
        return <span>{format(total0, Format.Currency)}</span>;
      } else if (index === 1) {
        return <span>{format(total1, Format.Currency)}</span>;
      } else if (index === 2) {
        return <span>{format(total2, Format.Currency)}</span>;
      } else if (index === 3) {
        return <span>{format(total3, Format.Currency)}</span>;
      }
    },
    [
      uiState.selectedVehicle?.data,
      uiState.competitors,
      showData,
      getAdditionalMaintenancePlanCost,
      getAdditionalServicePlanCost,
      getBasePrice,
      getFinanceCost,
    ]
  );

  const renderTotalCostPerKm = useCallback(
    (index: number) => {
      let total0 = 0;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;

      total0 += Math.abs(getBasePricePerKm(0));
      total0 += Math.abs(getFinanceCostPerKm(0));
      total0 += Math.abs(getAdditionalServicePlanCostPerKm(0));
      total0 += Math.abs(getAdditionalMaintenancePlanCostPerKm(0));
      total0 += Math.abs(
        showData(
          OptionalData.Fuel,
          uiState.selectedVehicle?.data?.tcoData?.result?.fuelCostPerKm ?? 0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Insurance,
          uiState.selectedVehicle?.data?.tcoData?.result?.insuranceCostPerKm ??
            0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Tyre,
          uiState.selectedVehicle?.data?.tcoData?.result?.tyreCostPerKm ?? 0
        )
      );
      total0 += Math.abs(
        showData(
          OptionalData.Depreciation,
          uiState.selectedVehicle?.data?.tcoData?.result?.depreciationPerKm ?? 0
        )
      );
      total0 -= Math.abs(
        showData(
          OptionalData.Depreciation,
          uiState.selectedVehicle?.data?.tcoData?.result?.residualValuePerKm ??
            0
        )
      );

      total1 += Math.abs(getBasePricePerKm(1));
      total1 += Math.abs(getFinanceCostPerKm(1));
      total1 += Math.abs(getAdditionalServicePlanCostPerKm(1));
      total1 += Math.abs(getAdditionalMaintenancePlanCostPerKm(1));
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[0].tcoData?.result?.fuelCostPerKm ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[0].tcoData?.result?.insuranceCostPerKm ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[0].tcoData?.result?.tyreCostPerKm ?? 0
            )
          : 0
      );
      total1 += Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[0].tcoData?.result?.depreciationPerKm ?? 0
            )
          : 0
      );
      total1 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 0
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[0].tcoData?.result?.residualValuePerKm ?? 0
            )
          : 0
      );

      total2 += Math.abs(getBasePricePerKm(2));
      total2 += Math.abs(getFinanceCostPerKm(2));
      total2 += Math.abs(getAdditionalServicePlanCostPerKm(2));
      total2 += Math.abs(getAdditionalMaintenancePlanCostPerKm(2));
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[1].tcoData?.result?.fuelCostPerKm ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[1].tcoData?.result?.insuranceCostPerKm ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[1].tcoData?.result?.tyreCostPerKm ?? 0
            )
          : 0
      );
      total2 += Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[1].tcoData?.result?.depreciationPerKm ?? 0
            )
          : 0
      );
      total2 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 1
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[1].tcoData?.result?.residualValuePerKm ?? 0
            )
          : 0
      );

      total3 += Math.abs(getBasePricePerKm(3));
      total3 += Math.abs(getFinanceCostPerKm(3));
      total3 += Math.abs(getAdditionalServicePlanCostPerKm(3));
      total3 += Math.abs(getAdditionalMaintenancePlanCostPerKm(3));
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Fuel,
              uiState.competitors[2].tcoData?.result?.fuelCostPerKm ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Insurance,
              uiState.competitors[2].tcoData?.result?.insuranceCostPerKm ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Tyre,
              uiState.competitors[2].tcoData?.result?.tyreCostPerKm ?? 0
            )
          : 0
      );
      total3 += Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[2].tcoData?.result?.depreciationPerKm ?? 0
            )
          : 0
      );
      total3 -= Math.abs(
        uiState.competitors && uiState.competitors.length > 2
          ? showData(
              OptionalData.Depreciation,
              uiState.competitors[2].tcoData?.result?.residualValuePerKm ?? 0
            )
          : 0
      );

      if (index === 0) {
        return (
          <span>
            {format(total0 * -1, Format.Currency)}
            <sub className="tcoPricePerKm">/KM</sub>
          </span>
        );
      } else if (index === 1) {
        return (
          <span>
            {format(total1 * -1, Format.Currency)}
            <sub className="tcoPricePerKm">/KM</sub>
          </span>
        );
      } else if (index === 2) {
        return (
          <span>
            {format(total2 * -1, Format.Currency)}
            <sub className="tcoPricePerKm">/KM</sub>
          </span>
        );
      } else if (index === 3) {
        return (
          <span>
            {format(total3 * -1, Format.Currency)}
            <sub className="tcoPricePerKm">/KM</sub>
          </span>
        );
      }
    },
    [
      uiState.selectedVehicle?.data,
      uiState.competitors,
      showData,
      getBasePricePerKm,
      getFinanceCostPerKm,
      getAdditionalMaintenancePlanCostPerKm,
      getAdditionalServicePlanCostPerKm,
    ]
  );

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const onLogOut = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      dispatch(setScreen(AppScreens.Login));
    }, 300);
  }, [dispatch]);

  // Calculate the total cost per kilometer
  const totalCostPerKm =
    (uiState.selectedVehicle?.data?.tcoData?.result?.financeCostPerKm ?? 0) +
    (uiState.selectedVehicle?.data?.tcoData?.result?.serviceCostPerKm ?? 0) +
    (uiState.selectedVehicle?.data?.tcoData?.result?.maintenanceCostPerKm ??
      0) +
    (uiState.selectedVehicle?.data?.tcoData?.result?.fuelCostPerKm ?? 0) +
    (uiState.selectedVehicle?.data?.tcoData?.result?.tyreCostPerKm ?? 0) +
    (uiState.selectedVehicle?.data?.tcoData?.result?.residualValuePerKm ?? 0);

  const [showPlan, setShowPlan] = useState(false);
  const [FinanceSettings, setFinanceSettings] = useState(false);
  const [TCOSettings, setTCOSettings] = useState(false);
  const [showServiceData, setShowServiceData] = useState(true);
  const [showBarDetail, setShowBarDetail] = useState(false);
  const [originalConsumption, setOriginalConsumption] = useState<
    number | undefined
  >(undefined);
  const [adjustedConsumption, setAdjustedConsumption] = useState<
    number | undefined
  >(originalConsumption);

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team.\n\n I have a question on the TCO screen of the\n\n  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} \n\n ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      } \n\n  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} \n\n*My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const handleInflationChange = (e: any) => {
    const Value = e.detail.value;
    setInflationValue(Value);
  };

  const [range, setRange] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [TotalDistanceTravelKm, setTravelKm] = useState(0);
  const [FuelCost, setFuelCost] = useState(0);
  const IntialFuelPrice = Number(FuelCost) / Number(kmToSlider);

  const [adjustedFuelCost, setAdjustedFuelCost] = useState(0);
  //Calculating the % for the fuel consumption
  const consumptionFuel =
    uiState.selectedVehicle?.data?.modelSpecification?.result
      ?.performance_consumption?.fuel_consumption_urban;
  const CapacityFuel =
    uiState.selectedVehicle?.data?.modelSpecification?.result
      ?.performance_consumption?.fuel_tank_capacity;
  const [FuelConsumption, setFuelFuelConsumption] = useState(
    (Number(consumptionFuel) / Number(CapacityFuel)) * 100 || 0
  );

  const [Inflation, setInflationValue] = useState<number>(0);

  const handleRangeChange = (event: any) => {
    const newRangeValue = parseInt(event.target.value, 10);
    setRangeValue(newRangeValue);

    const servicePlanMonths =
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan_months;
    const ServicePlanKm = Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    );

    // const remainingServiceKm = Number(servicePlanMonths) / 12;
    const servicePlanYears = Number(servicePlanMonths) / 12;
    const distanceTravelled = servicePlanYears * rangeValue;

    //The Km travelled in a year

    setTravelKm(distanceTravelled);
  };

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setRange({ lower: 10, upper: 20 });
      }, 10);

      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  //The finance cost for  Getting the price
  function calculateCarLoan(
    loanAmount: number,
    termMonths: number,
    interestRate: number
  ): { monthlyPayment: number; totalPayment: number } {
    // const termMonths = termYears * 12;
    const monthlyInterestRate = interestRate / 1200; // 12 months in a year, 100 to convert from percentage to decimal, 1200 is the result.
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -termMonths));
    const totalPayment = monthlyPayment * termMonths;
    return { monthlyPayment, totalPayment };
  }

  const getBallonPayment = useCallback(() => {
    let balloonAmount = (totalAmount * balloonPayment) / 100;
    return balloonAmount;
  }, [depositAmount, balloonPayment, uiState.selectedVehicle]);

  const getFinancedAmount = useCallback(() => {
    let financeAmount =
      // Number(
      //   uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
      //     .base_price
      // ) - depositAmount;

      Number(basePrice) - depositAmount;

    // if (balloonPayment !== 0) {
    //   let balloonAmount = financeAmount * (balloonPayment / 100);
    //   financeAmount = financeAmount - balloonAmount;
    // }
    return financeAmount;
  }, [depositAmount, balloonPayment, uiState.selectedVehicle]);

  useEffect(() => {
    const { monthlyPayment, totalPayment } = calculateCarLoan(
      getFinancedAmount(),
      paymentTerm,
      interestRate
    );
    setMonthlyInstallment(monthlyPayment);
    setTotalCost(totalPayment);
  }, [
    getFinancedAmount,
    paymentTerm,
    interestRate,
    depositAmount,
    balloonPayment,
    uiState.selectedVehicle,
  ]);

  const P = Number(basePrice);
  const r = Number(interestRate) / 100 / 12;
  const n = Number(paymentTerm);
  const b = Number(balloonPayment) / 100;

  const numerator = P * (r / (1 - Math.pow(1 + r, -n)));
  const balloonPayments = (P * b) / n;
  const TotalFinacedAmount = totalCost - getFinancedAmount();
  const TotalInterestAmou = (balloonPayment * TotalFinacedAmount) / 100;

  useEffect(() => {
    // Calculate the total amount whenever any relevant data changes
    const calculatedTotalAmount = Number(basePrice);

    // Update the state with the new total amount
    setTotalAmount(calculatedTotalAmount);
  }, [uiState.selectedVehicle]);

  const LabourRates = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].hourlyRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const SundriesRate = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].sundriesRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  {
    /**THE SERVICE COST TOTAL */
  }

  const calculateMonth = (interval: any) => {
    const distancePerYear = rangeValue;

    const months = Math.floor(interval / distancePerYear) * 12;

    return months;
  };

  const intervalHolder: Array<number> = [];

  uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
    (v, i) => {
      intervalHolder.push(v.attributes["interval"]);
    }
  );

  // Calculate the years
  function calculateYearsLabel(interval: any) {
    const months = calculateMonth(interval);

    return months;
  }
  // For the parts
  const [priceInfo, setPriceInfo] = useState<PriceInfoItem[][]>([]);

  //console.log("The parts price", priceInfo);

  const initialPartsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);
  //
  useEffect(() => {
    if (initialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;

            const PartQuantity = part.quantity;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price * PartQuantity;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(priceInfo)) {
        setPriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialPartsArray]);

  //For the fluids

  const [FluidsInfo, setFluidsInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The fluid price", FluidsInfo)
  const initialFluidsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.fluidsInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialFluidsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialFluidsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(FluidsInfo)) {
        setFluidsInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialFluidsArray]);

  //Calculating the inflation for the  Labour

  const [LabourInfo, setLabourInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The labour rate",LabourInfo)

  const initialLabourArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(LabourInfo)) {
        setLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  //Calculating the inflation for the  Labour

  const [SundriesInfo, setSundriesInfo] = useState<PriceInfoItem[][]>([]);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult: any, index: any) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = SundriesRate(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(SundriesInfo)) {
        setSundriesInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  const filteredPlanInfo =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo
          ?.map((part: any) => {
            const matchingPriceInfo = priceInfo.flat().filter((priceItem) => {
              return (
                priceItem.partName === part?.partNumber &&
                priceItem.interval === planItem.attributes?.interval
              );
            });

            return matchingPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the fluids
        const fluidNextPrices = planItem.fluidsInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = FluidsInfo.flat().filter(
              (priceItem) => {
                return (
                  priceItem.partName === fluid?.oemPartNumber &&
                  priceItem.interval === planItem.attributes?.interval
                );
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the Labour
        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = LabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        //Sundries Rates
        const SundriesNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = SundriesInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item: any) => item.nextPrice);
          })
          .flat();

        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalFluidPrices = fluidNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalSundriesPrices = SundriesNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          fluidNextPrices: fluidNextPrices,
          totalPartPrices: totalPartPrices,
          totalFluidPrices: totalFluidPrices,
          totalLabourPrices: totalLabourPrices,
          totalSundriesPrices: totalSundriesPrices,
        };
      }
    );

  //The service cost Grand total
  //The grand total
  const getGrandTotalMob = useCallback(
    (index: number) => {
      let total = 0;
      if (filteredPlanInfo) {
        filteredPlanInfo.forEach((servicePlan) => {
          if (
            servicePlan?.attributes?.interval >= kmFromSlider &&
            servicePlan?.attributes?.interval <= kmToSlider
          ) {
            total += showServiceData
              ? Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.service_plan ?? "0"
                ) >= servicePlan?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.service_plan ?? "0"
                  ) === 999999
                  ? servicePlan.totalFluidPrices +
                    servicePlan.totalPartPrices +
                    servicePlan.totalLabourPrices *
                      servicePlan.labourInfo[0]?.labourQuantity +
                    servicePlan.totalSundriesPrices
                  : Number(
                      (Number(servicePlan?.attributes.interval) / rangeValue) *
                        12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.service_plan_months
                    )
                  ? servicePlan.totalFluidPrices +
                    servicePlan.totalPartPrices +
                    servicePlan.totalLabourPrices *
                      servicePlan.labourInfo[0]?.labourQuantity +
                    servicePlan.totalSundriesPrices
                  : 0
                : servicePlan.totalFluidPrices +
                  servicePlan.totalPartPrices +
                  servicePlan.totalLabourPrices *
                    servicePlan.labourInfo[0]?.labourQuantity +
                  servicePlan.totalSundriesPrices
              : servicePlan.totalFluidPrices +
                servicePlan.totalPartPrices +
                servicePlan.totalLabourPrices *
                  servicePlan.labourInfo[0]?.labourQuantity +
                servicePlan.totalSundriesPrices;
          }

          return "";
        });
      }
      return total;
    },
    [
      filteredPlanInfo,
      underServicePlan,
      kmFromSlider,
      kmToSlider,
      TotalDistanceTravelKm,
    ]
  );

  {
    /*THE MAINTENANCE CALCULATOR**/
  }

  const MPintervalHolder: Array<number> = [];

  uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo.forEach(
    (v, i) => {
      MPintervalHolder.push(v.attributes["interval"]);
    }
  );

  const [MPpriceInfo, setMPPriceInfo] = useState<MaintenanceInfoItem[][]>([]);

  //console.log("The maintaince parts price", priceInfo);

  const MPinitialPartsArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (MPinitialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: MaintenanceInfoItem[][] = [];

      MPinitialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;
            const PartSubName = part.basketSubName;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: MaintenanceInfoItem[] = [];

              for (let i = 0; i < MPintervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(MPintervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear ===
                      calculateYearsLabel(MPintervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: nextPrice,
                  interval: MPintervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(MPpriceInfo)) {
        setMPPriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [MPinitialPartsArray]);

  const [MPLabourInfo, setMPLabourInfo] = useState<MaintenanceInfoItem[][]>([]);

  // console.log("The labour rate",LabourInfo)

  const MPinitialLabourArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (MPinitialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: MaintenanceInfoItem[][] = [];

      MPinitialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              const PartSubName = part.basketSubName;
              let LabournextPrice;

              const calculatedPrices: MaintenanceInfoItem[] = [];

              for (let i = 0; i < MPintervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(MPintervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear ===
                      calculateYearsLabel(MPintervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: LabournextPrice,
                  interval: MPintervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(MPLabourInfo)) {
        setMPLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [MPinitialLabourArray]);

  const MPfilteredPlanInfo =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo

          ?.map((part: any) => {
            const matchingPriceInfo = MPpriceInfo.flat().filter((priceItem) => {
              return (
                priceItem.partName === part?.partNumber &&
                priceItem.interval === planItem.attributes?.interval
              );
            });

            return matchingPriceInfo.map((item) => ({
              nextPrice: item.nextPrice,
              Name: item.Name, // Adding Name next to the nextPrice
            }));
          })
          .flat();
        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price.nextPrice,
          0
        );

        //For the labour

        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = MPLabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          totalPartPrices: totalPartPrices,
          totalLabourPrices: totalLabourPrices,
        };
      }
    );

  // console.log("The tco total", MPfilteredPlanInfo);

  const MPgetGrandTotal = useCallback(
    (index: number) => {
      let total = 0;
      if (MPfilteredPlanInfo) {
        MPfilteredPlanInfo.forEach((maintenancePlan) => {
          if (
            maintenancePlan?.attributes?.interval >= kmFromSlider &&
            maintenancePlan?.attributes?.interval <= kmToSlider
          ) {
            total += showServiceData
              ? // ? Number(
                //     uiState.selectedVehicle?.data?.modelSpecification.result
                //       .service_maintenance_warranty.maintenance_plan ?? "0"
                //   ) >= maintenancePlan?.attributes.interval
                //   ? 0
                //   : maintenancePlan.totalPartPrices +
                //     maintenancePlan.totalLabourPrices *
                //       maintenancePlan.labourInfo[0]?.labourQuantity
                // : maintenancePlan.totalPartPrices +
                //   maintenancePlan.totalLabourPrices *
                //     maintenancePlan.labourInfo[0]?.labourQuantity;
                Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.service_plan ?? "0"
                ) >= maintenancePlan?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.service_plan ?? "0"
                  ) === 999999
                  ? maintenancePlan.totalPartPrices +
                    maintenancePlan.totalLabourPrices *
                      maintenancePlan.labourInfo[0]?.labourQuantity
                  : Number(
                      (Number(maintenancePlan?.attributes.interval) /
                        rangeValue) *
                        12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.maintenance_plan_months
                    )
                  ? maintenancePlan.totalPartPrices +
                    maintenancePlan.totalLabourPrices *
                      maintenancePlan.labourInfo[0]?.labourQuantity
                  : 0
                : maintenancePlan.totalPartPrices +
                  maintenancePlan.totalLabourPrices *
                    maintenancePlan.labourInfo[0]?.labourQuantity
              : maintenancePlan.totalPartPrices +
                maintenancePlan.totalLabourPrices *
                  maintenancePlan.labourInfo[0]?.labourQuantity;
          }

          return "";
        });
      }
      return total;
    },
    [MPfilteredPlanInfo, kmFromSlider, kmToSlider]
  );

  //The chart

  //Setting the Fuel Cost in the useState
  useEffect(() => {
    if (tcoData.length > 0) {
      const calculatedFuelCost = tcoData.map(
        (item) => item.result && item.result.fuelCost * (1 + Inflation / 100)
      );
      // Assuming you want to set the first value from the calculatedFuelCost array
      if (calculatedFuelCost.length > 0) {
        setFuelCost(calculatedFuelCost[0]);
      }
    }
    if (
      uiState.selectedVehicle?.data?.modelSpecification?.result
        ?.performance_consumption?.fuel_consumption_urban
    ) {
      const consumption = parseFloat(
        uiState.selectedVehicle.data.modelSpecification.result
          .performance_consumption.fuel_consumption_urban
      );
      setOriginalConsumption(consumption);
    }
  }, [tcoData, Inflation]);

  const handlePercentageChange = (newPercentage: number) => {
    setFuelFuelConsumption(newPercentage);
    if (originalConsumption !== undefined) {
      const adjustedConsumptionValue =
        (newPercentage / 100) * originalConsumption;
      setAdjustedConsumption(adjustedConsumptionValue);
    }
  };

  // Your recalculateFuelCost function
  const recalculateFuelCost = () => {
    if (
      originalConsumption !== undefined &&
      adjustedConsumption !== undefined
    ) {
      const newAdjustedFuelCost =
        (adjustedConsumption / originalConsumption) * FuelCost;
      setAdjustedFuelCost(newAdjustedFuelCost + FuelCost);
    } else {
      setAdjustedFuelCost(FuelCost);
    }
  };

  useEffect(() => {
    recalculateFuelCost();
  }, [originalConsumption, adjustedConsumption, FuelCost]);

  const Consmpution =
    adjustedConsumption !== 0
      ? Number(adjustedConsumption) + Number(originalConsumption)
      : originalConsumption;
  const displayConsumptionValue = isNaN(Consmpution!)
    ? originalConsumption
    : Consmpution!.toFixed(1);

  //THE TYRE COST
  const [InitialTyresCost, setTyresCost] = useState(0);
  const [InitialTyresCostPekM, setTyresCostPerKm] = useState(0);

  useEffect(() => {
    if (tcoData.length > 0) {
      const calculatedFuelCost = tcoData.map(
        (item) => item.result && item.result.tyreCost * (1 + Inflation / 100)
      );
      // Assuming you want to set the first value from the calculatedFuelCost array
      if (calculatedFuelCost.length > 0) {
        setTyresCost(calculatedFuelCost[0]);
      }
    }

    if (tcoData.length > 0) {
      const calculatedFuelCost = tcoData.map(
        (item) =>
          item.result && item.result.tyreCostPerKm * (1 + Inflation / 100)
      );
      // Assuming you want to set the first value from the calculatedFuelCost array
      if (calculatedFuelCost.length > 0) {
        setTyresCostPerKm(calculatedFuelCost[0]);
      }
    }
  }, [tcoData, Inflation]);

  //The tire cost per Km
  const [TyreCost, setTyreCost] = useState(80000);

  const handleTyreChange = (value: number) => {
    setTyreCost(value);
  };

  // Calculate cost per kilometer
  const costPerKm = InitialTyresCost / kmToSlider;

  const distance = TyreCost;
  const tireCost = costPerKm * distance;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await FuelPrices(
          uiState.selectedVehicle?.data?.modelSpecification.result.basic_specs
            .fuel_type ?? ""
        );
        if (response.status === 200) {
          setGasPrice(response.data);
        } else {
        }
      } catch (error) {
        // console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const GetTotalFuelCost = useCallback(() => {
    let FuelCost =
      ((Number(displayConsumptionValue) * calcDuration) / 100) * FuelPrice;

    return FuelCost;
  }, [FuelPrice, FuelConsumption, calcDuration, displayConsumptionValue]);

  const getChartData = useCallback(
    (tcoData) => {
      const datasets = tcoData.map((item: any) => {
        return {
          label: "",
          data: [
            FinanceCash
              ? -totalCost * (1 + Inflation / 100)
              : -cashPrice * (1 + Inflation / 100),
            -getGrandTotalMob(0),
            -MPgetGrandTotal(0),
            -GetTotalFuelCost(),
            tireCost,
            item.result?.insuranceCost * (1 + Inflation / 100),
            item.result.depreciationValue * (1 + Inflation / 100),
            item.result?.residualValue * (1 + Inflation / 100),
          ],
          backgroundColor: [
            "#249CFF",
            "rgb(17, 38, 150)",
            "rgb(52, 61, 235)",
            "#0B9A8D",
            "rgb(0,63,92)",
            "#9C2162",
            "#FF6F50",
            "rgb(106, 178, 44)",
          ],
          fontSize: 35,
        };
      });

      const data = {
        labels: [
          "Finance Cost",
          "Service Cost",
          "Maintenance Cost",
          "Fuel Cost",
          "Tyre Cost",
          "Insurance",
          "Depreciation",
          "Residual Value",
        ],
        datasets: datasets,
      };

      return data;
    },
    [depositAmount, totalCost, getGrandTotalMob, MPgetGrandTotal]
  );

  const dataSourceFile = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].dataSource;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const InterestCost = totalCost - getFinancedAmount();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  return (
    <>
      {sessionState.user?.username === "" ? (
        <IonContent className="contentPage">
          <div className="innerContent">
            <SubPageHeading
              onBack={onBackPress}
              vehicle={uiState.selectedVehicle}
            ></SubPageHeading>
            <p className="Subscription" onClick={onLogOut}>
              Create Account or Log in
            </p>
          </div>
        </IonContent>
      ) : (
        <GlobalGrid
          currentPageTitle="TCO"
          closeCurrentPage={() => onBackPress()}
        >
          <div className="costsCont HeaderStick">
            <SubPageHeading
              onBack={onBackPress}
              vehicle={uiState.selectedVehicle}
            ></SubPageHeading>
          </div>
          <div className="genericHolder">
            <IonRow className="planDropDown">
              <span className="dropDownIcon">
                <IonIcon
                  icon={showPlan == false ? chevronForward : chevronDown}
                  onClick={() => {
                    setShowPlan(!showPlan);

                    // if (FinanceSettings) {
                    //   setFinanceSettings(false);
                    // }
                    handleButtonClick();
                  }}
                ></IonIcon>
              </span>
              <span className="textDropDown">LIFESPAN SETTINGS</span>
              <span className="activeState">
                {showServiceData ? "ACTIVATED" : "DEACTIVATED"}
              </span>
              <div className="toggleState">
                <IonToggle
                  checked={showServiceData}
                  className="ServiceCostToggleBtn"
                  onIonChange={(e) => setShowServiceData(!showServiceData)}
                />
              </div>
            </IonRow>
            {showPlan && (
              <>
                <section className="sliderContainer">
                  <span id="sliderContainerACtual">
                    <div className="digi">
                      <span>
                        FROM:
                        <h1>{kmFromSlider}</h1>
                      </span>
                      <span id="digiRight">
                        TO:
                        <h1>{kmToSlider}</h1>
                      </span>
                    </div>
                    <IonRange
                      id="slider"
                      aria-label="Dual Knobs Range"
                      dualKnobs={true}
                      min={0}
                      max={
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans &&
                        uiState.selectedVehicle?.data?.servicePlanTotals?.result
                          ?.servicePlans?.length > 0
                          ? uiState.selectedVehicle?.data?.servicePlanTotals
                              ?.result?.servicePlans[
                              uiState.selectedVehicle?.data?.servicePlanTotals
                                ?.result?.servicePlans.length - 1
                            ].interval
                          : 300000
                      }
                      step={1000}
                      onIonChange={(ev: any) => {
                        const lowerValue = isNaN(ev.detail.value.lower)
                          ? 0
                          : ev.detail.value.lower;
                        const upperValue = isNaN(ev.detail.value.upper)
                          ? 0
                          : ev.detail.value.upper;
                        setKmToSlider(upperValue);
                        setKmFromSlider(lowerValue);
                      }}
                      onPointerUp={() => setIsSliding(false)}
                      value={{
                        upper: kmToSlider,
                        lower: kmFromSlider,
                      }}
                    ></IonRange>

                    <div className="digi">
                      <span>TRAVELLING:</span>
                      <span id="digiRight">
                        <h1>
                          {rangeValue}
                          /YR
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      step={500}
                      min={2500}
                      max={100000}
                      value={rangeValue}
                      onIonChange={handleRangeChange}
                    ></IonRange>

                    <div className="digi">
                      <span>ANNUAL PRICE INCREASE</span>
                      <span id="digiRight">
                        <h1>{Inflation}%</h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      step={0.25}
                      min={0}
                      max={30}
                      value={Inflation}
                      onIonChange={handleInflationChange}
                    ></IonRange>
                  </span>
                </section>
              </>
            )}

            <IonRow className="planDropDown">
              <span className="dropDownIcon">
                <IonIcon
                  icon={FinanceSettings == false ? chevronForward : chevronDown}
                  onClick={() => {
                    setFinanceSettings(!FinanceSettings);
                    // if (showPlan) {
                    //   setShowPlan(false);
                    // }
                  }}
                ></IonIcon>
              </span>
              <span className="textDropDown">FINANCE SETTINGS</span>
            </IonRow>

            {FinanceSettings && (
              <>
                <section className="sliderContainer">
                  <IonRow className="ToggleDiv">
                    <span className="TCOToggleText">
                      {FinanceCash ? "FINANCE" : "CASH"}
                    </span>
                    <div className="toggleState">
                      <IonToggle
                        checked={FinanceCash}
                        className="FinanceToggleBtn"
                        onIonChange={(e: any) => setFinanceCash(!FinanceCash)}
                      />
                    </div>
                  </IonRow>
                  <span id="sliderContainerACtual">
                    <div className="digi">
                      <span>DEPOSIT AMOUNT</span>
                      <span id="digiRight">
                        <h1>
                          <IonInput
                            type="text"
                            value={
                              depositAmount !== null
                                ? depositAmount.toLocaleString()
                                : ""
                            }
                            onIonChange={(e: any) => {
                              const value = e.detail.value
                                ? parseFloat(
                                    e.detail.value.replace(/[^\d.]/g, "")
                                  )
                                : 0;
                              setDepositAmount(value);
                            }}
                            className="inline-input"
                          ></IonInput>
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      value={depositAmount}
                      onIonChange={(ev: any) => {
                        setDepositAmount(Number(ev.detail.value));
                      }}
                      min={0}
                      step={500}
                      max={Number(basePrice) ?? 0}
                    ></IonRange>

                    <div className="digi">
                      <span>PAYMENT TERM</span>
                      <span id="digiRight">
                        <h1>
                          <IonInput
                            type="number"
                            value={paymentTerm}
                            onIonChange={(e: any) => {
                              const value = e.detail.value
                                ? parseInt(e.detail.value, 10)
                                : 0;
                              setPaymentTerm(value);
                            }}
                            className="inline-input"
                          >
                            {" "}
                          </IonInput>
                          mnt
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      min={0}
                      max={96}
                      value={paymentTerm}
                      onIonChange={(ev: any) => {
                        setPaymentTerm(Number(ev.target.value ?? "0"));
                      }}
                    ></IonRange>

                    <div className="digi">
                      <span>BALLOON PAYMENT</span>
                      <span id="digiRight">
                        <h1>
                          <IonInput
                            type="number"
                            value={balloonPayment}
                            debounce={500}
                            onIonChange={(e: any) => {
                              const value = e.detail.value
                                ? parseFloat(e.detail.value)
                                : 0;
                              setBalloonPayment(value);
                            }}
                            className="inline-input"
                          ></IonInput>
                          {"%"}
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      onIonChange={(ev: any) => {
                        setBalloonPayment(Number(ev.target.value ?? "0"));
                      }}
                      value={balloonPayment}
                      min={0}
                      max={100}
                      step={1}
                      onTouchStart={handleTouchStart}
                    ></IonRange>
                    {isTouching && balloonPayment > 0 && (
                      <div className="BalloonPayPop">
                        At the end of the payment term, you'll owe
                        <span className="BallonAmoutColour">
                          {format(getBallonPayment(), Format.Currency)}
                        </span>{" "}
                        plus interest
                      </div>
                    )}

                    <div className="digi">
                      <span>INTEREST RATE</span>
                      <span id="digiRight">
                        <h1>
                          <IonInput
                            type="number"
                            value={interestRate}
                            onIonChange={(e: any) => {
                              const value = e.detail.value
                                ? parseFloat(e.detail.value)
                                : 0;
                              setInterestRate(value);
                            }}
                            className="inline-input"
                          ></IonInput>
                          {"%"}
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      onIonChange={(ev: any) => {
                        setInterestRate(Number(ev.target.value ?? "0"));
                      }}
                      value={interestRate}
                      min={0}
                      max={30}
                      step={0.25}
                    ></IonRange>
                  </span>
                </section>
              </>
            )}

            <IonRow className="planDropDown">
              <span className="dropDownIcon">
                <IonIcon
                  icon={TCOSettings == false ? chevronForward : chevronDown}
                  onClick={() => {
                    setTCOSettings(!TCOSettings);
                    // if (showPlan) {
                    //   setShowPlan(false);
                    // }
                  }}
                ></IonIcon>
              </span>
              <span className="textDropDown">TCO SETTINGS</span>
            </IonRow>

            {TCOSettings && (
              <>
                <section className="sliderContainer">
                  <span id="sliderContainerACtual">
                    <div className="digi">
                      <span>FUEL PRICE</span>
                      <span id="digiRight">
                        <h1>
                          {format(FuelPrice, Format.Currency)}
                          {/* {FuelPrice === 0 ? `${IntialFuelPrice.toFixed(2)}%` : `${FuelPrice}%`} */}
                        </h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      value={FuelPrice}
                      onIonChange={(ev: any) => {
                        setFuelPrice(Number(ev.detail.value));
                      }}
                      min={0}
                      step={0.01}
                      max={30}
                    ></IonRange>

                    <div className="digi">
                      <span>FUEL CONSUMPTION</span>
                      <span id="digiRight">
                        <h1> {displayConsumptionValue} L/100 km</h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      min={-25}
                      step={0.25}
                      max={25}
                      value={FuelConsumption}
                      onIonChange={(ev: CustomEvent) =>
                        handlePercentageChange(ev.detail.value as number)
                      }
                    ></IonRange>

                    <div className="digi">
                      <span>TYRE LIFE </span>
                      <span id="digiRight">
                        <h1>{TyreCost} Km</h1>
                      </span>
                    </div>

                    <IonRange
                      id="slider"
                      onIonChange={(ev: CustomEvent) =>
                        handleTyreChange(ev.detail.value as number)
                      }
                      value={TyreCost}
                      min={0}
                      max={
                        uiState.selectedVehicle?.data?.maintenancePlanTotals
                          ?.result?.maintenancePlans &&
                        uiState.selectedVehicle?.data?.maintenancePlanTotals
                          ?.result?.maintenancePlans?.length > 0
                          ? uiState.selectedVehicle?.data?.maintenancePlanTotals
                              ?.result?.maintenancePlans[
                              uiState.selectedVehicle?.data
                                ?.maintenancePlanTotals?.result
                                ?.maintenancePlans.length - 1
                            ].interval
                          : 0
                      }
                      step={1000}
                    ></IonRange>
                  </span>
                </section>
              </>
            )}

            <IonRow className="hiddenPlanDetails">
              <div>
                <span id="actualPlans" style={{ fontSize: "30px" }}>
                  The TCO report below covers a Lifespan of{" "}
                  <span style={{ color: "red" }}>{calcDuration}</span>{" "}
                  kilometres or{" "}
                  <span style={{ color: "red" }}>
                    {Number((Number(calcDuration) / rangeValue) * 12).toFixed(
                      0
                    )}{" "}
                  </span>{" "}
                  months.The parameters set in Finance Settings and TCO Settings
                  are also factored into the calculation.
                </span>
              </div>
            </IonRow>
          </div>

          <div className="scrollable">
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  sizeXs="12"
                  sizeMd="12"
                  sizeLg="12"
                  sizeXl="3.8"
                >
                  <IonGrid className="TcoTable">
                    <section className="costsContainers">
                      <IonRow>
                        <IonCol size="0.5"></IonCol>
                        <IonCol size="5" className="gridhead">
                          <span className="FontMobileSpecs"></span>
                        </IonCol>
                        <IonCol size="3" className="gridhead">
                          <span className="FontMobileSpecs"> TOTAL COST </span>
                        </IonCol>
                        <IonCol
                          size="2"
                          className="gridhead"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <span className="FontMobileSpecs">/ Km </span>
                        </IonCol>
                      </IonRow>

                      {tcoData.length > 0
                        ? tcoData.map((item, index) => (
                            <>
                              <IonRow key={index}>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("FinanceCost")
                                    }
                                  >
                                    {" "}
                                    {FinanceCash
                                      ? "Finance Cost"
                                      : "Cash Cost"}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {FinanceCash
                                      ? format(
                                          FinanceToggle
                                            ? Number(-getFinancedAmount())
                                            : 0,
                                          Format.Currency
                                        )
                                      : format(
                                          FinanceToggle
                                            ? -Number(
                                                cashPrice *
                                                  (1 + Inflation / 100)
                                              )
                                            : 0,
                                          Format.Currency
                                        )}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {FinanceCash
                                      ? format(
                                          FinanceToggle
                                            ? (-getFinancedAmount() /
                                                kmToSlider) *
                                                (1 + Inflation / 100)
                                            : 0,
                                          Format.Currency
                                        )
                                      : format(
                                          FinanceToggle
                                            ? (-Number(cashPrice) /
                                                kmToSlider) *
                                                (1 + Inflation / 100)
                                            : 0,
                                          Format.Currency
                                        )}{" "}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={FinanceToggle}
                                    onIonChange={FinanceToggleChange}
                                  />
                                </IonCol>
                                {expandedSection === "FinanceCost" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              // id="actualPlans"
                                              style={{
                                                fontSize: "27px",
                                                justifyContent: "center",
                                              }}
                                            >
                                              The{" "}
                                              {FinanceCash
                                                ? "Finance Cost"
                                                : "Cash Cost"}
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                {format(
                                                  FinanceToggle ? P : 0,
                                                  Format.Currency
                                                )}
                                              </span>{" "}
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>

                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("InterestCost")
                                    }
                                  >
                                    {" "}
                                    Interest Cost{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {FinanceCash
                                      ? format(
                                          InterestToggle ? -InterestCost : 0,
                                          Format.Currency
                                        )
                                      : "0.00"}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {FinanceCash
                                      ? format(
                                          InterestToggle
                                            ? -InterestCost / kmToSlider
                                            : 0,
                                          Format.Currency
                                        )
                                      : "0.00"}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={InterestToggle}
                                    onIonChange={InterestToggleChange}
                                  />
                                </IonCol>
                                {expandedSection === "InterestCost" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              id="actualPlans"
                                              style={{ fontSize: "27px" }}
                                            >
                                              The interest cost is calculated
                                              with a interest rate of{" "}
                                              <span style={{ color: "red" }}>
                                                {interestRate}%{" "}
                                              </span>
                                              . Using this interest rate, you
                                              find that your monthly interest
                                              would be
                                              <span style={{ color: "red" }}>
                                                {format(
                                                  InterestToggle
                                                    ? InterestCost / paymentTerm
                                                    : 0,
                                                  Format.Currency
                                                )}
                                              </span>
                                              . Over the full{" "}
                                              <span style={{ color: "red" }}>
                                                {paymentTerm}
                                              </span>
                                              -month term, you will make a
                                              Interest payment of{" "}
                                              <span style={{ color: "red" }}>
                                                {format(
                                                  InterestToggle
                                                    ? InterestCost
                                                    : 0,
                                                  Format.Currency
                                                )}
                                              </span>
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>

                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("serviceCost")
                                    }
                                  >
                                    {" "}
                                    Service Cost{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      ServiceToggle ? -getGrandTotalMob(0) : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      ServiceToggle
                                        ? -getGrandTotalMob(0) / kmToSlider
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={ServiceToggle}
                                    onIonChange={ServiceToggleChange}
                                  />
                                </IonCol>
                                {expandedSection === "serviceCost" && (
                                  <IonGrid>
                                    <div className="Tco-sp-table">
                                      <IonRow className="tco-sp-table">
                                        <IonCol size="2.4" className="gridhead">
                                          <span className="FontMobileSpecs">
                                            INTERVAL
                                          </span>
                                        </IonCol>
                                        <IonCol size="1.9" className="gridhead">
                                          <span className="FontMobileSpecs">
                                            {" "}
                                            Parts
                                          </span>
                                        </IonCol>
                                        <IonCol size="1.9" className="gridhead">
                                          <span className="FontMobileSpecs">
                                            {" "}
                                            Fluids
                                          </span>
                                        </IonCol>
                                        <IonCol size="2" className="gridhead">
                                          <span className="FontMobileSpecs">
                                            {" "}
                                            Labour{" "}
                                          </span>
                                        </IonCol>
                                        <IonCol size="2" className="gridhead">
                                          <span className="FontMobileSpecs">
                                            {" "}
                                            Total{" "}
                                          </span>
                                        </IonCol>
                                      </IonRow>

                                      {filteredPlanInfo?.map(
                                        (servicePlan, index) => (
                                          <React.Fragment key={"row" + index}>
                                            {servicePlan?.attributes.interval >=
                                              kmFromSlider &&
                                            servicePlan?.attributes.interval <=
                                              kmToSlider ? (
                                              <>
                                                <IonRow className="tco-sp-table">
                                                  <IonCol
                                                    size="2.4"
                                                    className="gridfirst"
                                                  >
                                                    <span
                                                      className="FontMobileService"
                                                      id="intervalValue"
                                                    >
                                                      {
                                                        servicePlan?.attributes
                                                          .interval
                                                      }
                                                    </span>
                                                  </IonCol>
                                                  <IonCol
                                                    size="1.9"
                                                    className="grid TableHeight"
                                                  >
                                                    <span className="FontMobileService">
                                                      {showServiceData
                                                        ? Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .service_plan ??
                                                              "0"
                                                          ) >=
                                                          servicePlan
                                                            ?.attributes
                                                            .interval
                                                          ? 0 ||
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .service_plan ??
                                                                "0"
                                                            ) === 1000000
                                                            ? format(
                                                                ServiceToggle
                                                                  ? 0
                                                                  : servicePlan.totalPartPrices,
                                                                Format.Currency
                                                              )
                                                            : (
                                                                ServiceToggle
                                                                  ? Number(
                                                                      (Number(
                                                                        servicePlan
                                                                          ?.attributes
                                                                          .interval
                                                                      ) /
                                                                        rangeValue) *
                                                                        12
                                                                    ) >
                                                                    Number(
                                                                      uiState
                                                                        .selectedVehicle
                                                                        ?.data
                                                                        ?.modelSpecification
                                                                        .result
                                                                        .service_maintenance_warranty
                                                                        .service_plan_months
                                                                    )
                                                                  : 0
                                                              )
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan.totalPartPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : 0
                                                          : format(
                                                              ServiceToggle
                                                                ? servicePlan.totalPartPrices
                                                                : 0,
                                                              Format.Currency
                                                            )
                                                        : format(
                                                            ServiceToggle
                                                              ? servicePlan.totalPartPrices
                                                              : 0,
                                                            Format.Currency
                                                          )}
                                                    </span>
                                                  </IonCol>
                                                  <IonCol
                                                    size="1.9"
                                                    className="grid"
                                                  >
                                                    <span className="FontMobileService">
                                                      {showServiceData
                                                        ? Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .service_plan ??
                                                              "0"
                                                          ) >=
                                                          servicePlan
                                                            ?.attributes
                                                            .interval
                                                          ? 0 ||
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .service_plan ??
                                                                "0"
                                                            ) === 1000000
                                                            ? format(
                                                                ServiceToggle
                                                                  ? 0
                                                                  : servicePlan.totalFluidPrices,
                                                                Format.Currency
                                                              )
                                                            : (
                                                                ServiceToggle
                                                                  ? Number(
                                                                      (Number(
                                                                        servicePlan
                                                                          ?.attributes
                                                                          .interval
                                                                      ) /
                                                                        rangeValue) *
                                                                        12
                                                                    ) >
                                                                    Number(
                                                                      uiState
                                                                        .selectedVehicle
                                                                        ?.data
                                                                        ?.modelSpecification
                                                                        .result
                                                                        .service_maintenance_warranty
                                                                        .service_plan_months
                                                                    )
                                                                  : 0
                                                              )
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan.totalFluidPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : 0
                                                          : format(
                                                              ServiceToggle
                                                                ? servicePlan.totalFluidPrices
                                                                : 0,
                                                              Format.Currency
                                                            )
                                                        : format(
                                                            ServiceToggle
                                                              ? servicePlan.totalFluidPrices
                                                              : 0,
                                                            Format.Currency
                                                          )}
                                                    </span>
                                                  </IonCol>
                                                  <IonCol
                                                    size="2"
                                                    className="grid"
                                                  >
                                                    <span className="FontMobileService">
                                                      {showServiceData
                                                        ? Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .service_plan ??
                                                              "0"
                                                          ) >=
                                                          servicePlan
                                                            ?.attributes
                                                            .interval
                                                          ? 0 ||
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .service_plan ??
                                                                "0"
                                                            ) === 1000000
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan
                                                                      .labourInfo[0]
                                                                      ?.labourQuantity *
                                                                      servicePlan.totalLabourPrices +
                                                                      servicePlan.totalSundriesPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : (
                                                                ServiceToggle
                                                                  ? Number(
                                                                      (Number(
                                                                        servicePlan
                                                                          ?.attributes
                                                                          .interval
                                                                      ) /
                                                                        rangeValue) *
                                                                        12
                                                                    ) >
                                                                    Number(
                                                                      uiState
                                                                        .selectedVehicle
                                                                        ?.data
                                                                        ?.modelSpecification
                                                                        .result
                                                                        .service_maintenance_warranty
                                                                        .service_plan_months
                                                                    )
                                                                  : 0
                                                              )
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan
                                                                      .labourInfo[0]
                                                                      ?.labourQuantity *
                                                                      servicePlan.totalLabourPrices +
                                                                      servicePlan.totalSundriesPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : 0
                                                          : format(
                                                              ServiceToggle
                                                                ? servicePlan
                                                                    .labourInfo[0]
                                                                    ?.labourQuantity *
                                                                    servicePlan.totalLabourPrices +
                                                                    servicePlan.totalSundriesPrices
                                                                : 0,
                                                              Format.Currency
                                                            )
                                                        : format(
                                                            ServiceToggle
                                                              ? servicePlan
                                                                  .labourInfo[0]
                                                                  ?.labourQuantity *
                                                                  servicePlan.totalLabourPrices +
                                                                  servicePlan.totalSundriesPrices
                                                              : 0,
                                                            Format.Currency
                                                          )}
                                                    </span>
                                                  </IonCol>
                                                  <IonCol
                                                    size="2"
                                                    className="grid TableHeight"
                                                  >
                                                    <span className="FontMobileService">
                                                      {showServiceData
                                                        ? Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .service_plan ??
                                                              "0"
                                                          ) >=
                                                          servicePlan
                                                            ?.attributes
                                                            .interval
                                                          ? 0 ||
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .service_plan ??
                                                                "0"
                                                            ) === 1000000
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan.totalFluidPrices +
                                                                      servicePlan.totalPartPrices +
                                                                      servicePlan
                                                                        .labourInfo[0]
                                                                        ?.labourQuantity *
                                                                        servicePlan.totalLabourPrices +
                                                                      servicePlan.totalSundriesPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : (
                                                                ServiceToggle
                                                                  ? Number(
                                                                      (Number(
                                                                        servicePlan
                                                                          ?.attributes
                                                                          .interval
                                                                      ) /
                                                                        rangeValue) *
                                                                        12
                                                                    ) >
                                                                    Number(
                                                                      uiState
                                                                        .selectedVehicle
                                                                        ?.data
                                                                        ?.modelSpecification
                                                                        .result
                                                                        .service_maintenance_warranty
                                                                        .service_plan_months
                                                                    )
                                                                  : 0
                                                              )
                                                            ? format(
                                                                ServiceToggle
                                                                  ? servicePlan.totalFluidPrices +
                                                                      servicePlan.totalPartPrices +
                                                                      servicePlan
                                                                        .labourInfo[0]
                                                                        ?.labourQuantity *
                                                                        servicePlan.totalLabourPrices +
                                                                      servicePlan.totalSundriesPrices
                                                                  : 0,
                                                                Format.Currency
                                                              )
                                                            : 0
                                                          : format(
                                                              ServiceToggle
                                                                ? servicePlan.totalFluidPrices +
                                                                    servicePlan.totalPartPrices +
                                                                    servicePlan
                                                                      .labourInfo[0]
                                                                      ?.labourQuantity *
                                                                      servicePlan.totalLabourPrices +
                                                                    servicePlan.totalSundriesPrices
                                                                : 0,
                                                              Format.Currency
                                                            )
                                                        : format(
                                                            ServiceToggle
                                                              ? servicePlan.totalFluidPrices +
                                                                  servicePlan.totalPartPrices +
                                                                  servicePlan
                                                                    .labourInfo[0]
                                                                    ?.labourQuantity *
                                                                    servicePlan.totalLabourPrices +
                                                                  servicePlan.totalSundriesPrices
                                                              : 0,

                                                            Format.Currency
                                                          )}
                                                    </span>
                                                  </IonCol>
                                                </IonRow>
                                              </>
                                            ) : null}
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("maintenanceCost")
                                    }
                                  >
                                    Maintenance Cost{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      MaintenanceToggle
                                        ? -MPgetGrandTotal(0)
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      MaintenanceToggle
                                        ? -MPgetGrandTotal(0) / kmToSlider
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={MaintenanceToggle}
                                    onIonChange={MaintenanceToggleChange}
                                  />
                                </IonCol>

                                {expandedSection === "maintenanceCost" && (
                                  <IonGrid>
                                    <div className="Tco-mp-table">
                                      <IonRow className="tco-sp-table">
                                        <IonCol size="0.1"></IonCol>
                                        <IonCol size="2.2" className="gridhead">
                                          <span className="cell">
                                            {" "}
                                            INTERVAL{" "}
                                          </span>
                                        </IonCol>
                                        <IonCol size="2" className="gridhead">
                                          <span className="cell"> Brakes </span>
                                        </IonCol>
                                        <IonCol size="1.8" className="gridhead">
                                          <span className="cell"> Wipers </span>
                                        </IonCol>
                                        <IonCol size="2" className="gridhead">
                                          <span className="cell"> Shocks </span>
                                        </IonCol>
                                        <IonCol size="1.9" className="gridhead">
                                          <span className="cell"> Clutch </span>
                                        </IonCol>
                                        <IonCol size="2" className="gridhead">
                                          <span className="cell"> Total </span>
                                        </IonCol>
                                      </IonRow>

                                      <>
                                        {MPfilteredPlanInfo?.map(
                                          (maintenancePlan, index) => {
                                            const totalNextPriceForWipers =
                                              maintenancePlan.nextPrices.reduce(
                                                (total: any, part: any) => {
                                                  if (part.Name === "WIPERS") {
                                                    return (
                                                      total + part.nextPrice
                                                    );
                                                  }
                                                  return total;
                                                },
                                                0
                                              );

                                            const totalNextPriceForBrakes =
                                              maintenancePlan.nextPrices.reduce(
                                                (total: any, part: any) => {
                                                  if (
                                                    part.Name === "DISCS" ||
                                                    part.Name === "DRUMS"
                                                  ) {
                                                    return (
                                                      total + part.nextPrice
                                                    );
                                                  }
                                                  return total;
                                                },
                                                0
                                              );

                                            const totalNextPriceForShocks =
                                              maintenancePlan.nextPrices.reduce(
                                                (total: any, part: any) => {
                                                  if (
                                                    part.Name ===
                                                    "ABSORBER SHOCKS"
                                                  ) {
                                                    return (
                                                      total + part.nextPrice
                                                    );
                                                  }
                                                  return total;
                                                },
                                                0
                                              );

                                            const totalNextPriceForClutch =
                                              maintenancePlan.nextPrices.reduce(
                                                (total: any, part: any) => {
                                                  if (
                                                    part.Name ===
                                                    "CLUTCH / TORQUE CONVERTER"
                                                  ) {
                                                    return (
                                                      total + part.nextPrice
                                                    );
                                                  }
                                                  return total;
                                                },
                                                0
                                              );
                                            return (
                                              <React.Fragment
                                                key={"row" + index}
                                              >
                                                {maintenancePlan?.attributes
                                                  .interval >= kmFromSlider &&
                                                maintenancePlan?.attributes
                                                  .interval <= kmToSlider ? (
                                                  <>
                                                    <IonRow className="tco-sp-table">
                                                      <IonCol size="0.1"></IonCol>

                                                      <IonCol
                                                        size="2.2"
                                                        className="gridfirst"
                                                      >
                                                        <span
                                                          className="FontMobileService"
                                                          id="intervalValue"
                                                        >
                                                          {
                                                            maintenancePlan
                                                              ?.attributes
                                                              .interval
                                                          }
                                                        </span>
                                                      </IonCol>
                                                      <IonCol
                                                        size="2"
                                                        className="grid"
                                                      >
                                                        <span className="FontMobileService">
                                                          {showServiceData
                                                            ? Number(
                                                                uiState
                                                                  .selectedVehicle
                                                                  ?.data
                                                                  ?.modelSpecification
                                                                  .result
                                                                  .service_maintenance_warranty
                                                                  .maintenance_plan ??
                                                                  "0"
                                                              ) >=
                                                              maintenancePlan
                                                                ?.attributes
                                                                .interval
                                                              ? 0 ||
                                                                Number(
                                                                  uiState
                                                                    .selectedVehicle
                                                                    ?.data
                                                                    ?.modelSpecification
                                                                    .result
                                                                    .service_maintenance_warranty
                                                                    .maintenance_plan ??
                                                                    "0"
                                                                ) === 999999
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForBrakes
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : (
                                                                    MaintenanceToggle
                                                                      ? Number(
                                                                          (Number(
                                                                            maintenancePlan
                                                                              ?.attributes
                                                                              .interval
                                                                          ) /
                                                                            rangeValue) *
                                                                            12
                                                                        ) >
                                                                        Number(
                                                                          uiState
                                                                            .selectedVehicle
                                                                            ?.data
                                                                            ?.modelSpecification
                                                                            .result
                                                                            .service_maintenance_warranty
                                                                            .maintenance_plan_months
                                                                        )
                                                                      : 0
                                                                  )
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForBrakes
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : 0
                                                              : format(
                                                                  MaintenanceToggle
                                                                    ? totalNextPriceForBrakes
                                                                    : 0,
                                                                  Format.Currency
                                                                )
                                                            : format(
                                                                MaintenanceToggle
                                                                  ? totalNextPriceForBrakes
                                                                  : 0,
                                                                Format.Currency
                                                              )}
                                                        </span>
                                                      </IonCol>
                                                      <IonCol
                                                        size="1.8"
                                                        className="grid"
                                                      >
                                                        <span className="FontMobileService">
                                                          {showServiceData
                                                            ? Number(
                                                                uiState
                                                                  .selectedVehicle
                                                                  ?.data
                                                                  ?.modelSpecification
                                                                  .result
                                                                  .service_maintenance_warranty
                                                                  .maintenance_plan ??
                                                                  "0"
                                                              ) >=
                                                              maintenancePlan
                                                                ?.attributes
                                                                .interval
                                                              ? 0 ||
                                                                Number(
                                                                  uiState
                                                                    .selectedVehicle
                                                                    ?.data
                                                                    ?.modelSpecification
                                                                    .result
                                                                    .service_maintenance_warranty
                                                                    .maintenance_plan ??
                                                                    "0"
                                                                ) === 999999
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForWipers
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : (
                                                                    MaintenanceToggle
                                                                      ? Number(
                                                                          (Number(
                                                                            maintenancePlan
                                                                              ?.attributes
                                                                              .interval
                                                                          ) /
                                                                            rangeValue) *
                                                                            12
                                                                        ) >
                                                                        Number(
                                                                          uiState
                                                                            .selectedVehicle
                                                                            ?.data
                                                                            ?.modelSpecification
                                                                            .result
                                                                            .service_maintenance_warranty
                                                                            .maintenance_plan_months
                                                                        )
                                                                      : 0
                                                                  )
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForWipers
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : 0
                                                              : format(
                                                                  MaintenanceToggle
                                                                    ? totalNextPriceForWipers
                                                                    : 0,
                                                                  Format.Currency
                                                                )
                                                            : format(
                                                                MaintenanceToggle
                                                                  ? totalNextPriceForWipers
                                                                  : 0,
                                                                Format.Currency
                                                              )}
                                                        </span>
                                                      </IonCol>
                                                      <IonCol
                                                        size="2"
                                                        className="grid"
                                                      >
                                                        <span className="FontMobileService">
                                                          {showServiceData
                                                            ? Number(
                                                                uiState
                                                                  .selectedVehicle
                                                                  ?.data
                                                                  ?.modelSpecification
                                                                  .result
                                                                  .service_maintenance_warranty
                                                                  .maintenance_plan ??
                                                                  "0"
                                                              ) >=
                                                              maintenancePlan
                                                                ?.attributes
                                                                .interval
                                                              ? 0 ||
                                                                Number(
                                                                  uiState
                                                                    .selectedVehicle
                                                                    ?.data
                                                                    ?.modelSpecification
                                                                    .result
                                                                    .service_maintenance_warranty
                                                                    .maintenance_plan ??
                                                                    "0"
                                                                ) === 999999
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForShocks
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : (
                                                                    MaintenanceToggle
                                                                      ? Number(
                                                                          (Number(
                                                                            maintenancePlan
                                                                              ?.attributes
                                                                              .interval
                                                                          ) /
                                                                            rangeValue) *
                                                                            12
                                                                        ) >
                                                                        Number(
                                                                          uiState
                                                                            .selectedVehicle
                                                                            ?.data
                                                                            ?.modelSpecification
                                                                            .result
                                                                            .service_maintenance_warranty
                                                                            .maintenance_plan_months
                                                                        )
                                                                      : 0
                                                                  )
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForShocks
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : 0
                                                              : format(
                                                                  MaintenanceToggle
                                                                    ? totalNextPriceForShocks
                                                                    : 0,
                                                                  Format.Currency
                                                                )
                                                            : format(
                                                                MaintenanceToggle
                                                                  ? totalNextPriceForShocks
                                                                  : 0,
                                                                Format.Currency
                                                              )}
                                                        </span>
                                                      </IonCol>
                                                      <IonCol
                                                        size="1.9"
                                                        className="grid"
                                                      >
                                                        <span className="FontMobileService">
                                                          {showServiceData
                                                            ? Number(
                                                                uiState
                                                                  .selectedVehicle
                                                                  ?.data
                                                                  ?.modelSpecification
                                                                  .result
                                                                  .service_maintenance_warranty
                                                                  .maintenance_plan ??
                                                                  "0"
                                                              ) >=
                                                              maintenancePlan
                                                                ?.attributes
                                                                .interval
                                                              ? 0 ||
                                                                Number(
                                                                  uiState
                                                                    .selectedVehicle
                                                                    ?.data
                                                                    ?.modelSpecification
                                                                    .result
                                                                    .service_maintenance_warranty
                                                                    .maintenance_plan ??
                                                                    "0"
                                                                ) === 999999
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForClutch
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : (
                                                                    MaintenanceToggle
                                                                      ? Number(
                                                                          (Number(
                                                                            maintenancePlan
                                                                              ?.attributes
                                                                              .interval
                                                                          ) /
                                                                            rangeValue) *
                                                                            12
                                                                        ) >
                                                                        Number(
                                                                          uiState
                                                                            .selectedVehicle
                                                                            ?.data
                                                                            ?.modelSpecification
                                                                            .result
                                                                            .service_maintenance_warranty
                                                                            .maintenance_plan_months
                                                                        )
                                                                      : 0
                                                                  )
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? totalNextPriceForClutch
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : 0
                                                              : format(
                                                                  MaintenanceToggle
                                                                    ? totalNextPriceForClutch
                                                                    : 0,
                                                                  Format.Currency
                                                                )
                                                            : format(
                                                                MaintenanceToggle
                                                                  ? totalNextPriceForClutch
                                                                  : 0,
                                                                Format.Currency
                                                              )}
                                                        </span>
                                                      </IonCol>
                                                      <IonCol
                                                        size="2"
                                                        className="grid"
                                                      >
                                                        <span className="FontMobileService">
                                                          {showServiceData
                                                            ? Number(
                                                                uiState
                                                                  .selectedVehicle
                                                                  ?.data
                                                                  ?.modelSpecification
                                                                  .result
                                                                  .service_maintenance_warranty
                                                                  .maintenance_plan ??
                                                                  "0"
                                                              ) >=
                                                              maintenancePlan
                                                                ?.attributes
                                                                .interval
                                                              ? 0 ||
                                                                Number(
                                                                  uiState
                                                                    .selectedVehicle
                                                                    ?.data
                                                                    ?.modelSpecification
                                                                    .result
                                                                    .service_maintenance_warranty
                                                                    .maintenance_plan ??
                                                                    "0"
                                                                ) === 999999
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? maintenancePlan.totalPartPrices +
                                                                          maintenancePlan.totalLabourPrices *
                                                                            maintenancePlan
                                                                              .labourInfo[0]
                                                                              ?.labourQuantity
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : (
                                                                    MaintenanceToggle
                                                                      ? Number(
                                                                          (Number(
                                                                            maintenancePlan
                                                                              ?.attributes
                                                                              .interval
                                                                          ) /
                                                                            rangeValue) *
                                                                            12
                                                                        ) >
                                                                        Number(
                                                                          uiState
                                                                            .selectedVehicle
                                                                            ?.data
                                                                            ?.modelSpecification
                                                                            .result
                                                                            .service_maintenance_warranty
                                                                            .maintenance_plan_months
                                                                        )
                                                                      : 0
                                                                  )
                                                                ? format(
                                                                    MaintenanceToggle
                                                                      ? maintenancePlan.totalPartPrices +
                                                                          maintenancePlan.totalLabourPrices *
                                                                            maintenancePlan
                                                                              .labourInfo[0]
                                                                              ?.labourQuantity
                                                                      : 0,
                                                                    Format.Currency
                                                                  )
                                                                : 0
                                                              : format(
                                                                  MaintenanceToggle
                                                                    ? maintenancePlan.totalPartPrices +
                                                                        maintenancePlan.totalLabourPrices *
                                                                          maintenancePlan
                                                                            .labourInfo[0]
                                                                            ?.labourQuantity
                                                                    : 0,
                                                                  Format.Currency
                                                                )
                                                            : format(
                                                                MaintenanceToggle
                                                                  ? maintenancePlan.totalPartPrices +
                                                                      maintenancePlan.totalLabourPrices *
                                                                        maintenancePlan
                                                                          .labourInfo[0]
                                                                          ?.labourQuantity
                                                                  : 0,
                                                                Format.Currency
                                                              )}
                                                        </span>
                                                      </IonCol>
                                                    </IonRow>
                                                  </>
                                                ) : null}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("AddMainte")
                                    }
                                  >
                                    Additional Maintenance
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    -{format(AdditionalToggle ? 
                                      item.result &&
                                        item.result.maintenanceAdditionalCost + Number(AdditMaint) *
                                          (1 + Inflation / 100) : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    -{format(AdditionalToggle ? 
                                      item.result &&
                                        item.result
                                          .maintenanceAdditionalCostPerKm +
                                          (Number(AdditMaint) / calcDuration) *
                                            (1 + Inflation / 100) : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle className="ServiceCostToggleBtn" 
                                  checked={AdditionalToggle}
                                  onIonChange={AdditionalToggleChange}/>
                                </IonCol>

                                {expandedSection === "AddMainte" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              // id="actualPlans"
                                              style={{ fontSize: "27px",justifyContent:'center'}}
                                            >
                                              Enter the amount for Additional
                                              Maintenance <br />
                                              <IonCol style={{justifyContent: 'center', alignSelf: 'center'}}>
                                              <input
                                                type="number"
                                                value={AdditMaint}
                                                onChange={(event) =>
                                                  onChangeAdditionalCost(event)
                                                }
                                                placeholder="0.00"
                                                className="PlaceHolderAdditionalMaint"
                                              />
                                              </IonCol>
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("FuelCost")
                                    }
                                  >
                                    Fuel Cost{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      FuelToggle ? -GetTotalFuelCost() : 0,
                                      Format.Currency
                                    )}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      FuelToggle
                                        ? -GetTotalFuelCost() / kmToSlider
                                        : 0,
                                      Format.Currency
                                    )}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={FuelToggle}
                                    onIonChange={FuelToggleChange}
                                  />
                                </IonCol>

                                {expandedSection === "FuelCost" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              id="actualPlans"
                                              style={{ fontSize: "27px" }}
                                            >
                                              The fuel consumption of this
                                              vehicle is{" "}
                                              <span style={{ color: "red" }}>
                                                {displayConsumptionValue
                                                }
                                              </span>
                                              L/100 Km. The fuel price is
                                              adjusted to
                                              <span style={{ color: "red" }}>
                                                {format(
                                                  FuelPrice,
                                                  Format.Currency
                                                )}
                                              </span>
                                              Over a lifespan of{" "}
                                              <span style={{ color: "red" }}>
                                                {calcDuration}
                                              </span>{" "}
                                              km
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("TyreCost")
                                    }
                                  >
                                    Tyre Cost{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      TyreToggle ? tireCost : 0,
                                      Format.Currency
                                    )}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      TyreToggle ? tireCost / kmToSlider : 0,
                                      Format.Currency
                                    )}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={TyreToggle}
                                    onIonChange={TyreToggleChange}
                                  />
                                </IonCol>

                                {expandedSection === "TyreCost" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              id="actualPlans"
                                              style={{ fontSize: "27px" }}
                                            >
                                              The tyre cost per km is{" "}
                                              <span style={{ color: "red" }}>
                                                {costPerKm}
                                              </span>
                                              {""} Km .The tyre life is set to{" "}
                                              <span style={{ color: "red" }}>
                                                {TyreCost}
                                              </span>
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span
                                    className="FontMobileService"
                                    onClick={() =>
                                      handleExpandClick("InsuranceCost")
                                    }
                                  >
                                    Insurance{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {InsuranceToggle
                                      ? format(
                                          item.result &&
                                            item.result.insuranceCost *
                                              (1 + Inflation / 100),
                                          Format.Currency
                                        )
                                      : "0.00"}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {InsuranceToggle
                                      ? format(
                                          item.result &&
                                            item.result.insuranceCostPerKm *
                                              (1 + Inflation / 100),
                                          Format.Currency
                                        )
                                      : "0.00"}{" "}
                                  </span>
                                </IonCol>

                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={InsuranceToggle}
                                    onIonChange={handleToggleChange}
                                  />
                                </IonCol>

                                {expandedSection === "InsuranceCost" && (
                                  <IonGrid>
                                    <div className="Tco-finance-cost-details{">
                                      <IonRow className="tco-finance-cost-details">
                                        <IonRow className="hiddenPlanDetails">
                                          <div>
                                            <span
                                              id="actualPlans"
                                              style={{ fontSize: "27px" }}
                                            >
                                              The insurance cost over the period
                                              of {""}
                                              <span style={{ color: "red" }}>
                                                {calcDuration}
                                              </span>{" "}
                                              lifespan
                                            </span>
                                          </div>
                                        </IonRow>
                                      </IonRow>
                                    </div>
                                  </IonGrid>
                                )}
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span className="FontMobileService">
                                    Depreciation{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      DepreciationToggle
                                        ? item.result &&
                                            item.result.depreciationValue *
                                              (1 + Inflation / 100)
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      DepreciationToggle
                                        ? item.result &&
                                            item.result.depreciationPerKm *
                                              (1 + Inflation / 100)
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={DepreciationToggle}
                                    onIonChange={DepreciationToggleChange}
                                  />
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol size="0.5"></IonCol>
                                <IonCol size="5" className="gridfirst">
                                  <span className="FontMobileService">
                                    Residual Value{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="3" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      ResidualToggle
                                        ? item.result &&
                                            item.result.residualValue *
                                              (1 + Inflation / 100)
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol size="2" className="grid">
                                  <span className="FontMobileService">
                                    {format(
                                      ResidualToggle
                                        ? item.result &&
                                            item.result.residualValuePerKm *
                                              (1 + Inflation / 100)
                                        : 0,
                                      Format.Currency
                                    )}{" "}
                                  </span>
                                </IonCol>
                                <IonCol>
                                  <IonToggle
                                    className="ServiceCostToggleBtn"
                                    checked={ResidualToggle}
                                    onIonChange={ResidualToggleChange}
                                  />
                                </IonCol>
                              </IonRow>
                            </>
                          ))
                        : null}

                      {tcoData.length > 0
                        ? tcoData.map((item, index) => (
                            <IonRow key={index}>
                              <IonCol size="0.5"></IonCol>
                              <IonCol size="5" className="">
                                {/* <span className="FontMobileService">Total </span> */}
                              </IonCol>
                              <IonCol
                                size="3"
                                className="grid"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <strong>
                                  <span className="FontMobileService">
                                    {format(
                                      (FinanceToggle
                                        ? FinanceCash
                                          ? -getFinancedAmount() *
                                            (1 + Inflation / 100)
                                          : -Number(
                                              cashPrice * (1 + Inflation / 100)
                                            )
                                        : 0) +
                                        (InterestToggle
                                          ? -InterestCost *
                                            (1 + Inflation / 100)
                                          : 0) +
                                        (ServiceToggle
                                          ? -getGrandTotalMob(0)
                                          : 0) +
                                        (MaintenanceToggle
                                          ? -MPgetGrandTotal(0)
                                          : 0) +
                                        (InsuranceToggle
                                          ? ((item.result &&
                                              item.result.insuranceCost) ||
                                              0) *
                                            (1 + Inflation / 100)
                                          : 0) +
                                        (FuelToggle ? -GetTotalFuelCost() : 0) +
                                        (TyreToggle ? tireCost : 0) +
                                        (ResidualToggle
                                          ? ((item.result &&
                                              item.result.residualValue) ||
                                              0) *
                                            (1 + Inflation / 100)
                                          : 0) +
                                        (DepreciationToggle
                                          ? (item.result &&
                                              item.result.depreciationValue) ||
                                            0
                                          : 0) *
                                          (1 + Inflation / 100) +(AdditionalToggle ?  -Number(AdditMaint) : 0),
                                      Format.Currency
                                    )}
                                  </span>
                                </strong>
                              </IonCol>
                              <IonCol
                                size="2"
                                className="grid"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <strong>
                                  <span className="FontMobileService">
                                    {format(
                                      (FinanceToggle
                                        ? FinanceCash
                                          ? (-getFinancedAmount() /
                                              kmToSlider) *
                                            (1 + Inflation / 100)
                                          : -Number(
                                              (cashPrice / kmToSlider) *
                                                (1 + Inflation / 100)
                                            )
                                        : 0) +
                                        (InterestToggle
                                          ? -InterestCost / kmToSlider
                                          : 0) *
                                          (1 + Inflation / 100) +
                                        +(ServiceToggle
                                          ? -getGrandTotalMob(0) / kmToSlider
                                          : 0) +
                                        (MaintenanceToggle
                                          ? -MPgetGrandTotal(0) / kmToSlider
                                          : 0) +
                                        (FuelToggle
                                          ? -GetTotalFuelCost() / kmToSlider
                                          : 0) +
                                        (InsuranceToggle
                                          ? (item.result &&
                                              item.result.insuranceCostPerKm) *
                                            (1 + Inflation / 100)
                                          : 0) +
                                        (TyreToggle
                                          ? tireCost / kmToSlider
                                          : 0) +
                                        (ResidualToggle
                                          ? (item.result &&
                                              item.result.residualValuePerKm) *
                                            (1 + Inflation / 100)
                                          : 0) +
                                        (DepreciationToggle
                                          ? (item.result &&
                                              item.result.depreciationPerKm) *
                                            (1 + Inflation / 100)
                                          : 0) + (AdditionalToggle ? -Number(AdditMaint)/ kmToSlider : 0),
                                      Format.Currency
                                    )}
                                  </span>
                                </strong>
                              </IonCol>
                            </IonRow>
                          ))
                        : null}
                    </section>
                  </IonGrid>{" "}
                </IonCol>

                <IonCol
                  size="12"
                  sizeXs="12"
                  sizeMd="12"
                  sizeLg="12"
                  sizeXl="4"
                >
                  {isDesktop ? (
                    <>
                      <Bar
                        options={Desktopoptions}
                        height={300}
                        width={500}
                        style={{ marginBottom: "15rem" }}
                        data={getChartData(tcoData)}
                      />
                    </>
                  ) : (
                    <>
                      <Bar
                        options={options}
                        width={100}
                        height={850}
                        className="TCOChart"
                        data={getChartData(tcoData)}
                      />
                    </>
                  )}
                </IonCol>

                <IonCol
                  size="11.5"
                  sizeXs="11.5"
                  sizeMd="11.5"
                  sizeLg="12"
                  sizeXl="3.8"
                  className="filters-tco"
                  style={{ paddingTop: "30rem", paddingLeft: "1rem" }}
                >
                  <IonRow style={{ marginTop: "20px" }}>
                    <div>
                      <span className="textDropDown">LIFE SPAN SETTINGS</span>
                    </div>
                    <br />
                    <div className="Filters">
                      <>
                        <section className="sliderContainer">
                          <span id="sliderContainerACtual">
                            <div className="digi">
                              <span className="FontMobileSpecs">
                                FROM:
                                <h1>{kmFromSlider}</h1>
                              </span>
                              <span id="digiRight">
                                TO:
                                <h1>{kmToSlider}</h1>
                              </span>
                            </div>
                            <IonRange
                              id="slider"
                              aria-label="Dual Knobs Range"
                              dualKnobs={true}
                              min={0}
                              max={
                                uiState.selectedVehicle?.data?.servicePlanTotals
                                  ?.result?.servicePlans &&
                                uiState.selectedVehicle?.data?.servicePlanTotals
                                  ?.result?.servicePlans?.length > 0
                                  ? uiState.selectedVehicle?.data
                                      ?.servicePlanTotals?.result?.servicePlans[
                                      uiState.selectedVehicle?.data
                                        ?.servicePlanTotals?.result
                                        ?.servicePlans.length - 1
                                    ].interval
                                  : 300000
                              }
                              step={1000}
                              onIonChange={(ev: any) => {
                                const lowerValue = isNaN(ev.detail.value.lower)
                                  ? 0
                                  : ev.detail.value.lower;
                                const upperValue = isNaN(ev.detail.value.upper)
                                  ? 0
                                  : ev.detail.value.upper;
                                setKmToSlider(upperValue);
                                setKmFromSlider(lowerValue);
                              }}
                              value={{
                                upper: kmToSlider,
                                lower: kmFromSlider,
                              }}
                            ></IonRange>

                            <div className="digi">
                              <span>TRAVELLING:</span>
                              <span id="digiRight">
                                <h1>
                                  {rangeValue}
                                  /YR
                                </h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              step={500}
                              min={2500}
                              max={100000}
                              value={rangeValue}
                              onIonChange={handleRangeChange}
                            ></IonRange>

                            <div className="digi">
                              <span>ANNUAL PRICE INCREASE</span>
                              <span id="digiRight">
                                <h1>{Inflation}%</h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              step={0.25}
                              min={0}
                              max={30}
                              value={Inflation}
                              onIonChange={handleInflationChange}
                            ></IonRange>
                          </span>
                        </section>
                      </>

                      <IonRow className="hiddenPlanDetails">
                        <div>
                          <span id="actualPlans" className="ActualPlanDetails">
                            The report below covers a lifespan of{" "}
                            <span style={{ color: "red" }}></span> kilometres or{" "}
                            <span style={{ color: "red" }}>
                              {Number(
                                (Number(kmToSlider) / rangeValue) * 12
                              ).toFixed(0)}{" "}
                            </span>{" "}
                            months with annual price increase of
                            <span style={{ color: "red" }}> {Inflation}%</span>
                          </span>
                        </div>
                      </IonRow>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <span className="textDropDown">
                        SERVICE & MAINTENANCE PLAN SETTINGS
                      </span>
                    </div>
                    <div className="Filters">
                      <section className="sliderContainer">
                        <span id="sliderContainerACtual">
                          <IonCol className="activeState ion-justify-content-end">
                            {showServiceData ? "ACTIVATED" : "DEACTIVATED"}
                            <div className="toggleState">
                              <IonToggle
                                checked={showServiceData}
                                className="ServiceCostToggleBtn"
                                onIonChange={(e) =>
                                  setShowServiceData(!showServiceData)
                                }
                              />
                            </div>
                          </IonCol>
                        </span>
                      </section>
                      <IonRow className="hiddenPlanDetails">
                        <div>
                          <span id="actualPlans" className="ActualPlanDetails">
                            The model follows a{" "}
                            <span style={{ color: "red" }}>
                              <span style={{ textTransform: "lowercase" }}>
                                {
                                  uiState.selectedVehicle?.data
                                    ?.modelSpecification.result
                                    .service_maintenance_warranty
                                    .service_plan_type
                                }{" "}
                                service schedule
                              </span>
                            </span>{" "}
                            and if there's a service plan included, it's{" "}
                            <span style={{ color: "red" }}>
                              {" "}
                              {showServiceData ? "activated" : "deactivated"}
                            </span>{" "}
                            in the report
                          </span>
                        </div>
                      </IonRow>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <span className="textDropDown">FINANCE SETTINGS</span>
                    </div>
                    <div className="Filters">
                      <>
                        <section className="sliderContainer">
                          <span id="sliderContainerACtual">
                            <IonCol className="activeState ion-justify-content-end">
                              {FinanceCash ? "FINANCE" : "CASH"}
                              <div className="toggleState">
                                <IonToggle
                                  checked={FinanceCash}
                                  className="ServiceCostToggleBtn"
                                  onIonChange={(e: any) =>
                                    setFinanceCash(!FinanceCash)
                                  }
                                />
                              </div>
                            </IonCol>
                            <div className="digi">
                              <span className="FontMobileSpecs">
                                DEPOSIT AMOUNT
                                <h1>
                                  {" "}
                                  <IonInput
                                    type="text"
                                    value={
                                      depositAmount !== null
                                        ? depositAmount.toLocaleString()
                                        : ""
                                    }
                                    onIonChange={(e: any) => {
                                      const value = e.detail.value
                                        ? parseFloat(
                                            e.detail.value.replace(
                                              /[^\d.]/g,
                                              ""
                                            )
                                          )
                                        : 0;
                                      setDepositAmount(value);
                                    }}
                                    className="inline-input"
                                  ></IonInput>
                                </h1>
                              </span>
                            </div>
                            <IonRange
                              id="slider"
                              value={depositAmount}
                              onIonChange={(ev: any) => {
                                setDepositAmount(Number(ev.detail.value));
                              }}
                              min={0}
                              step={500}
                              max={Number(basePrice) ?? 0}
                            ></IonRange>

                            <div className="digi">
                              <span>PAYMENT TERM</span>
                              <span id="digiRight">
                                <h1>
                                  <IonInput
                                    type="number"
                                    value={paymentTerm}
                                    onIonChange={(e: any) => {
                                      const value = e.detail.value
                                        ? parseInt(e.detail.value, 10)
                                        : 0;
                                      setPaymentTerm(value);
                                    }}
                                    className="inline-input"
                                  >
                                    {" "}
                                  </IonInput>
                                  mnt
                                </h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              min={0}
                              max={96}
                              value={paymentTerm}
                              onIonChange={(ev: any) => {
                                setPaymentTerm(Number(ev.target.value ?? "0"));
                              }}
                            ></IonRange>

                            <div className="digi">
                              <span>BALLOON PAYMENT</span>
                              <span id="digiRight">
                                <h1>
                                  {" "}
                                  <IonInput
                                    type="number"
                                    value={balloonPayment}
                                    debounce={500}
                                    onIonChange={(e: any) => {
                                      const value = e.detail.value
                                        ? parseFloat(e.detail.value)
                                        : 0;
                                      setBalloonPayment(value);
                                    }}
                                    className="inline-input"
                                  ></IonInput>
                                  {"%"}
                                </h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              onIonChange={(ev: any) => {
                                setBalloonPayment(
                                  Number(ev.target.value ?? "0")
                                );
                              }}
                              value={balloonPayment}
                              min={0}
                              max={100}
                              step={1}
                              onTouchStart={handleTouchStart}
                            ></IonRange>

                            <div className="digi">
                              <span>INTEREST RATE</span>
                              <span id="digiRight">
                                <h1>
                                  <IonInput
                                    type="number"
                                    value={interestRate}
                                    onIonChange={(e: any) => {
                                      const value = e.detail.value
                                        ? parseFloat(e.detail.value)
                                        : 0;
                                      setInterestRate(value);
                                    }}
                                    className="inline-input"
                                  ></IonInput>
                                  {"%"}
                                </h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              onIonChange={(ev: any) => {
                                setInterestRate(Number(ev.target.value ?? "0"));
                              }}
                              value={interestRate}
                              min={0}
                              max={30}
                              step={0.25}
                            ></IonRange>
                          </span>
                        </section>
                      </>

                      <IonRow className="hiddenPlanDetails">
                        <div>
                          <span id="actualPlans" className="ActualPlanDetails">
                            The report below covers a lifespan of{" "}
                            <span style={{ color: "red" }}></span> kilometres or{" "}
                            <span style={{ color: "red" }}>
                              {Number(
                                (Number(kmToSlider) / rangeValue) * 12
                              ).toFixed(0)}{" "}
                            </span>{" "}
                            months with annual price increase of
                            <span style={{ color: "red" }}> {Inflation}%</span>
                          </span>
                        </div>
                      </IonRow>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <span className="textDropDown">TCO SETTINGS</span>
                    </div>

                    <div className="Filters">
                      <>
                        <section className="sliderContainer">
                          <span id="sliderContainerACtual">
                            <div className="digi">
                              <span>FUEL PRICE</span>
                              <span id="digiRight">
                                <h1>
                                  {format(FuelPrice, Format.Currency)}
                                  {/* {FuelPrice === 0 ? `${IntialFuelPrice.toFixed(2)}%` : `${FuelPrice}%`} */}
                                </h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              value={FuelPrice}
                              onIonChange={(ev: any) => {
                                setFuelPrice(Number(ev.detail.value));
                              }}
                              min={0}
                              step={0.01}
                              max={30}
                            ></IonRange>

                            <div className="digi">
                              <span>FUEL CONSUMPTION</span>
                              <span id="digiRight">
                                <h1> {displayConsumptionValue} L/100 km</h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              min={-25}
                              step={0.25}
                              max={25}
                              value={FuelConsumption}
                              onIonChange={(ev: CustomEvent) =>
                                handlePercentageChange(
                                  ev.detail.value as number
                                )
                              }
                            ></IonRange>

                            <div className="digi">
                              <span>TYRE LIFE </span>
                              <span id="digiRight">
                                <h1>{TyreCost} Km</h1>
                              </span>
                            </div>

                            <IonRange
                              id="slider"
                              onIonChange={(ev: CustomEvent) =>
                                handleTyreChange(ev.detail.value as number)
                              }
                              value={TyreCost}
                              min={0}
                              max={
                                uiState.selectedVehicle?.data
                                  ?.maintenancePlanTotals?.result
                                  ?.maintenancePlans &&
                                uiState.selectedVehicle?.data
                                  ?.maintenancePlanTotals?.result
                                  ?.maintenancePlans?.length > 0
                                  ? uiState.selectedVehicle?.data
                                      ?.maintenancePlanTotals?.result
                                      ?.maintenancePlans[
                                      uiState.selectedVehicle?.data
                                        ?.maintenancePlanTotals?.result
                                        ?.maintenancePlans.length - 1
                                    ].interval
                                  : 0
                              }
                              step={1000}
                            ></IonRange>
                          </span>
                        </section>
                      </>
                    </div>
                  </IonRow>
                </IonCol>
              </IonRow>

              <DataAssurance />
              <Whatsapp shareClicked={handleShareClick} />
            </IonGrid>
            {/* <Pie options={options} data={getChartData()} /> */}

            {/*
                   <div className="TCOWhatsApp">
                    <img
                      src={icons.mnewWhatsApp}
                      className="SupportWhatsApp"
                      onClick={handleShareClick}
                    />
                  </div>
                  */}
          </div>
          <div
            className={`totalsDisplay ${
              slideDirection === "in" ? "slideIn" : "slideOut"
            }`}
            onTouchStart={(e) => {
              touchStartX = e.touches[0].clientX;
            }}
            onTouchEnd={(e) => {
              const touchEndX = e.changedTouches[0].clientX;
              const swipeDistance = touchEndX - touchStartX;
              const swipeThreshold = 50;

              if (Math.abs(swipeDistance) > swipeThreshold) {
                if (
                  slideDirection === "in" ||
                  slideDirection === "in-after-out"
                ) {
                  setShowWithSlider(true);
                  setSlideDirection("out");
                } else {
                  setShowWithSlider(false);
                  setSlideDirection("in-after-out");
                }
              }
            }}
          >
            <span> TOTAL COST OF OWNERSHIP</span>
            <span>
              <IonIcon
                src={chevronBack}
                id="Total-prev-arrow"
                onClick={(e) => {
                  handleSwipe();
                }}
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
              <div className="ExcVat ExcVat-tco">
                <span></span>
                {/* <IonIcon src={caretBackOutline} id="Total-prev-arrow"></IonIcon> */}
                {tcoData.length > 0
                  ? tcoData.map((item, index) => (
                      <>
                        {showWithSlider
                          ? format(
                              (FinanceToggle
                                ? FinanceCash
                                  ? -getFinancedAmount()
                                  : -Number(cashPrice * (1 + Inflation / 100))
                                : 0) +
                                (ServiceToggle ? -getGrandTotalMob(0) : 0) +
                                (MaintenanceToggle ? -MPgetGrandTotal(0) : 0) +
                                (InsuranceToggle
                                  ? (item.result && item.result.insuranceCost) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle ? -GetTotalFuelCost() : 0) +
                                (TyreToggle ? tireCost : 0) +
                                (ResidualToggle
                                  ? (item.result && item.result.residualValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? -InterestCost * (1 + Inflation / 100)
                                  : 0) +(AdditionalToggle ?  -Number(AdditMaint): 0),
                              Format.Currency
                            )
                          : format(
                              (FinanceToggle
                                ? FinanceCash
                                  ? (-getFinancedAmount() / kmToSlider) *
                                    (1 + Inflation / 100)
                                  : -Number(
                                      (cashPrice / kmToSlider) *
                                        (1 + Inflation / 100)
                                    )
                                : 0) +
                                (ServiceToggle
                                  ? -getGrandTotalMob(0) / kmToSlider
                                  : 0) +
                                (MaintenanceToggle
                                  ? 0
                                  : -MPgetGrandTotal(0) / kmToSlider) +
                                (InsuranceToggle
                                  ? (item.result &&
                                      item.result.insuranceCostPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle
                                  ? -GetTotalFuelCost() / kmToSlider
                                  : 0) *
                                  (1 + Inflation / 100) +
                                (TyreToggle ? tireCost / kmToSlider : 0) +
                                (ResidualToggle
                                  ? (item.result &&
                                      item.result.residualValuePerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? (-InterestCost / kmToSlider) *
                                    (1 + Inflation / 100)
                                  : 0) + (AdditionalToggle ? -Number(AdditMaint) / kmToSlider: 0),
                              Format.Currency
                            )}
                      </>
                    ))
                  : null}
                <small className="Text-excvat">EXC VAT</small>
              </div>
              <div className="InclVat">
                <span></span>{" "}
                {tcoData.length > 0
                  ? tcoData.map((item, index) => (
                      <>
                        {showWithSlider
                          ? format(
                              (((FinanceToggle
                                ? FinanceCash
                                  ? -getFinancedAmount() * (1 + Inflation / 100)
                                  : -Number(cashPrice * (1 + Inflation / 100))
                                : 0) +
                                (ServiceToggle ? -getGrandTotalMob(0) : 0) +
                                (MaintenanceToggle ? -MPgetGrandTotal(0) : 0) +
                                (InsuranceToggle
                                  ? (item.result && item.result.insuranceCost) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle ? -GetTotalFuelCost() : 0) +
                                (TyreToggle ? tireCost : 0) +
                                (ResidualToggle
                                  ? (item.result && item.result.residualValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? -InterestCost * (1 + Inflation / 100)
                                  : 0)) + (AdditionalToggle ?  -Number(AdditMaint) : 0)  *
                                15) /
                                100 +
                                (FinanceToggle
                                  ? FinanceCash
                                    ? -getFinancedAmount() *
                                      (1 + Inflation / 100)
                                    : -Number(cashPrice * (1 + Inflation / 100))
                                  : 0) +
                                (ServiceToggle ? -getGrandTotalMob(0) : 0) +
                                (MaintenanceToggle ? -MPgetGrandTotal(0) : 0) +
                                (InsuranceToggle
                                  ? (item.result && item.result.insuranceCost) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle ? -GetTotalFuelCost() : 0) +
                                (TyreToggle ? tireCost : 0) +
                                (ResidualToggle
                                  ? (item.result && item.result.residualValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationValue) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? -InterestCost * (1 + Inflation / 100)
                                  : 0)  +(AdditionalToggle ? -Number(AdditMaint) : 0) ,
                              Format.Currency
                            )
                          : format(
                              ((FinanceToggle
                                ? FinanceCash
                                  ? -Number(getFinancedAmount() / kmToSlider) *
                                    (1 + Inflation / 100)
                                  : -Number(
                                      (cashPrice / kmToSlider) *
                                        (1 + Inflation / 100)
                                    )
                                : 0) +
                                (ServiceToggle
                                  ? -getGrandTotalMob(0) / kmToSlider
                                  : 0) +
                                (MaintenanceToggle
                                  ? -MPgetGrandTotal(0) / kmToSlider
                                  : 0) +
                                (InsuranceToggle
                                  ? (item.result &&
                                      item.result.insuranceCostPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle
                                  ? -GetTotalFuelCost() / kmToSlider
                                  : 0) *
                                  (1 + Inflation / 100) +
                                (TyreToggle ? tireCost / kmToSlider : 0) +
                                (ResidualToggle
                                  ? (item.result &&
                                      item.result.residualValuePerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? (-InterestCost / kmToSlider) *
                                    (1 + Inflation / 100)
                                  : 0) + (AdditionalToggle ? -Number(AdditMaint)/ kmToSlider :0) *
                                  15) /
                                100 +
                                (FinanceToggle
                                  ? FinanceCash
                                    ? -Number(
                                        getFinancedAmount() / kmToSlider
                                      ) *
                                      (1 + Inflation / 100)
                                    : -Number(
                                        (cashPrice / kmToSlider) *
                                          (1 + Inflation / 100)
                                      )
                                  : 0) +
                                (ServiceToggle
                                  ? -getGrandTotalMob(0) / kmToSlider
                                  : 0) +
                                (MaintenanceToggle
                                  ? -MPgetGrandTotal(0) / kmToSlider
                                  : 0) +
                                (InsuranceToggle
                                  ? (item.result &&
                                      item.result.insuranceCostPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (FuelToggle
                                  ? -GetTotalFuelCost() / kmToSlider
                                  : 0) *
                                  (1 + Inflation / 100) +
                                (TyreToggle ? tireCost / kmToSlider : 0) +
                                (ResidualToggle
                                  ? (item.result &&
                                      item.result.residualValuePerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (DepreciationToggle
                                  ? (item.result &&
                                      item.result.depreciationPerKm) *
                                    (1 + Inflation / 100)
                                  : 0) +
                                (InterestToggle
                                  ? (-InterestCost / kmToSlider) *
                                    (1 + Inflation / 100)
                                  : 0)  +(AdditionalToggle ?  -Number(AdditMaint) / kmToSlider : 0) ,
                              Format.Currency
                            )}
                      </>
                    ))
                  : null}
              </div>
              <p className="InclVat-per-km">{totalServiceCostPerKm}</p>
              <p className="ExcVat">{totalServiceCosPerKm}</p>
              <IonIcon
                src={chevronForward}
                id="Total-next-arrow"
                onClick={(e) => {
                  handleSwipe();
                }}
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
            </span>
            <span className="Text-incvat-tco">INC VAT</span>
          </div>
        </GlobalGrid>
      )}

      <MessageModal
        visible={modalVisible}
        tcoInfoModal={true}
        onPress={onModalPress}
      />
    </>
  );
};

export default Tco;
