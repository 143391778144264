import {
  IonAccordion,
  IonAccordionGroup,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRouterContext,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./style.css";
import "../../Global.css";
import { desktopImages, icons, images } from "../../assets/index";
import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
  SetStateAction,
} from "react";
import Button from "../../components/button";
import SearchResultView from "./search-result-view-vin";
import { useDispatch } from "react-redux";
import UiSlice, {
  hideLoader,
  setFilteredSearchVisible,
  setScreen,
  setUndentifiedVinSearchVisible,
  showLoader,
} from "../../redux/ui/uiSlice";
import {
  getAllVehicles,
  groupsFetcher,
  manualSearch,
  modelClass,
  modelsFetcher,
  parameterSearch,
} from "../../api/mapo";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AllVehicleParam } from "../../interfaces/allVehicleParam";
import { ParamSearch } from "../../interfaces/paramSearch";
import { Vehicle } from "../../interfaces/vehicle";
import moment, { max, min } from "moment";
import ReactSlider from "react-slider";
import {
  arrowBack,
  checkbox,
  chevronBack,
  chevronBackCircleSharp,
  chevronBackOutline,
  closeOutline,
  filterCircle,
  handLeft,
  iceCreamSharp,
  map,
  menuOutline,
  micOffSharp,
  micSharp,
  search,
  volumeHighOutline,
} from "ionicons/icons";
import { parse } from "querystring";
import { count } from "console";
import { setSelectedOem } from "../../redux/session/sessionSlice";
import { current } from "@reduxjs/toolkit";
import Heade from "../../components/heade";
import { AppScreens } from "../../enums";
import Header from "../../components/header";
import SearchResultViewVin from "./search-result-view-vin";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import VinVoice from "../../components/vinScanVoice/vinScanVoice";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

interface group {
  g_id: number;
  g_desc: string;
}
interface model {
  m_id: number;
  m_desc: string;
}

interface varients {
  v_id: number;
  v_desc: string;
}

interface modelClasses {
  modelclass: string;
  description: string;
}

export enum UndetinfiedVinSearchViewType {
  SearchResult,
  SwapOut,
}

interface FilteredSearchProps {
  isVisible: boolean;
  onClosed: () => void;
  viewType: UndetinfiedVinSearchViewType;
}
const FilteredSearch: React.FC<FilteredSearchProps> = ({
  isVisible,
  onClosed,
  viewType,
}: FilteredSearchProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<Array<AllVehicleParam>>();
  const isLoaded = useRef(false);
  const [searchResults, setSearchResults] = useState<Array<Vehicle>>();
  const [selectedVGroup, setSelectedVGroup] = useState("");
  const [selectedVYear, setSelectedVYear] = useState("");
  const [selectedVModel, setSelectedVModel] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(
    sessionStorage.getItem("ScannedGroup")
  );

  const [filter, setFilter] = useState<ParamSearch>({});
  const yearsArray = Array.from({ length: 2025 - 1990 }, (_, index) =>
    (1990 + index).toString()
  );
  yearsArray.reverse();
  const [selectedSeats, setSelectedSeats] = useState(Number);
  const [selectedModel, setSelectedModel] = useState(
    sessionStorage.getItem("ScannedModel")
  );
  const [selectedStyle, setSelectedStyle] = useState(String);
  const [selectedModelPlaceholder, setSelectedModelPlaceholder] =
    useState("MODELS ");
  const [selectedVarPlaceholder, setSelectedVarPlaceholder] =
    useState("VARIANTS ");
  const [transPlaceholder, setTransPlaceholder] = useState("TRANSMISSION ");
  const [fuelPlaceholder, setFuellaceholder] = useState("FUEL TYPE ");
  const [yearPlaceholder, setYearPlaceholder] = useState("YEAR");
  const [doorsPlaceholder, setDoorsPlaceholder] = useState("DOORS");
  const [selectedTransType, setSelectedTransType] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [enteredVarID, setEnteredVarID] = useState("");
  const [enteredStyle, setEnteredStyle] = useState("");
  const [selectedDriveType, setSelectedDriveType] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDoors, setSelectedDoors] = useState(Number);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
  const MINSLIDER = 0;
  const MAXSLIDER = 1500000;
  const MINKW = 0;
  const MAXKW = 1000;
  const MINNM = 0;
  const MAXNM = 1000;
  const MINDIS = 0;
  const MAXDIS = 5000;
  const [sliderValues, setSliderValues] = useState([MINSLIDER, MAXSLIDER]);
  const [selectedKw, setSelectedKW] = useState([MINKW, MAXKW]);
  const [selectedNm, setSelectedNm] = useState([MINNM, MAXNM]);
  const [selectedDisplacement, setSelectedDisplacement] = useState(0);
  const [specificVariantsByModel, setSpecificVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [specificVariantsId, setSpecificVariantsId] = useState([]);
  const userName = JSON.stringify(sessionState.user?.username);
  const gCode = Number(sessionState.selectedOem?.g_id);
  //checkbox handlers
  const [selectedCheckbox, setSelectedCheckbox] = useState(String);
  const [selectedVariantCheckbox, setSelectedVariantCheckbox] =
    useState(String);
  const [selectedStyleCheckbox, setSelectedStyleCheckbox] = useState(String);
  const [selectedModelCheckbox, setSelectedModelCheckbox] = useState(String);
  const [selectedTransCheckbox, setSelectedTransCheckbox] = useState(String);
  const [selectedFuelCheckbox, setSelectedFuelCheckbox] = useState(String);
  const [selectedYearCheckbox, setSelectedYearCheckbox] = useState(String);
  const [statusArr, setStatusArr] = useState(["CURRENT", "DISCONTINUED"]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [modelsDropDown, setModelsDropDown] = useState<model[]>([]);

  const [addModelsByGroup, setAddModelsByGroup] = useState<
    Array<String | Number> | []
  >();
  const [groupDropDown, setGroupDropDown] = useState<group[]>([]);
  const [addVariantsByModel, setAddVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [showVideo, setShowVideo] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [varientsDropDown, setVarientsDropDown] = useState<varients[]>([]);
  const [typedGroup, setTypedGroup] = useState(String);
  const [typedModel, setTypedModel] = useState("");
  const [typedStyle, setTypedStyle] = useState("");
  const [typedVariant, setTypedVariant] = useState("");
  const [finderUpdate, setFinderUpdate] = useState(" ");

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
  };
  //loads all vehiccles based on user
  const loadVehicles = useCallback(() => {
    if ((sessionState.user?.username ?? "") !== "") {
      dispatch(showLoader());
      getAllVehicles(uiState?.isDesktop ?? false)
        .then((vehicleResponse) => {
          dispatch(hideLoader());
          if (vehicleResponse.status === 200) {
            setVehicles(vehicleResponse.data);
            if (viewType === UndetinfiedVinSearchViewType.SearchResult) {
              setFilter({
                ...filter,
                group: sessionState.selectedOem?.g_desc.toString(),
              });
            }
          } else {
            alert(vehicleResponse.statusText);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, sessionState, uiState, filter, viewType]);

  //defines action of reset button
  const onResetFilters = useCallback(() => {
    setSelectedVGroup("");
    setSelectedVModel("");
    setSelectedStatus("");
    setSelectedCheckbox("");
    setSelectedModelCheckbox("");
    setSelectedVariantCheckbox("");
    setSelectedYearCheckbox("");
    setSelectedStyleCheckbox("");
    setSpecificVariantsByModel([]);
    setEnteredVarID("");
    setSelectedStyle("");
    setSelectedSeats(0);
    setSelectedNm([MINNM, MAXNM]);
    setSelectedKW([MINKW, MAXKW]);
    setSelectedDisplacement(0);
    setSliderValues([]);
    selectedKw[0] = MINKW;
    selectedKw[1] = MAXKW;
    selectedNm[0] = MINNM;
    selectedNm[1] = MAXNM;
    sliderValues[0] = MINSLIDER;
    sliderValues[1] = MAXSLIDER;
    setSelectedDriveType("");
    setSelectedModel("");
    setSelectedTransType("");
    setAddStylesAvail(undefined);
    setFilter({});
    setSelectedYear("");
    setSelectedGroup("");
    setSelectedVariant("");
    setSelectedFuelType("");
    setSearchResults(undefined);
    setSliderValues([MINSLIDER, MAXSLIDER]);
    sessionStorage.clear();
    stopListening();
  }, []);
  //checks if vehicles is empty if so , gets all vehicles
  useEffect(() => {
    if (isVisible && vehicles === undefined && !isLoaded.current) {
      isLoaded.current = true;
      loadVehicles();
    }
  }, [vehicles, isVisible, loadVehicles]);
  //filters vehicles
  const getFilteredVehicles = useCallback(() => {
    let newFilteredList: Array<AllVehicleParam> | undefined = [];
    if (
      (filter?.variant ?? "") &&
      (filter?.model ?? "") !== "" &&
      (filter?.group ?? "") !== ""
    ) {
      newFilteredList = vehicles?.filter(
        (item) =>
          item.g_desc === filter.group &&
          item.m_desc === filter.model &&
          item.v_desc === filter.variant
      );
    } else if ((filter?.model ?? "") !== "" && (filter?.group ?? "") !== "") {
      newFilteredList = vehicles?.filter(
        (item) => item.g_desc === filter.group && item.m_desc === filter.model
      );
    } else if ((filter?.group ?? "") !== "") {
      newFilteredList = vehicles?.filter(
        (item) => item.g_desc === filter.group
      );
    } else if (vehicles) {
      newFilteredList = [...vehicles];
    }

    return newFilteredList;
  }, [filter, vehicles]);

  //this function defines action of search button
  const onSearch = useCallback(() => {
    const scannedGroup = sessionStorage.getItem("ScannedGroup")
      ? sessionStorage.getItem("ScannedGroup")
      : selectedVGroup;
    const ScannedModel = sessionStorage.getItem("ScannedModel")
      ? sessionStorage.getItem("ScannedModel")
      : selectedVModel;

    if (
      (filter.GMV ?? "" === "") &&
      (filter.drive ?? "" === "") &&
      (filter.fuelType ?? "" === "") &&
      (filter.group ?? "" === "") &&
      (filter.listType ?? "" === "") &&
      (filter.maxKw ?? "" === "") &&
      (filter.maxNm ?? "" === "") &&
      (filter.maxPrice ?? "" === "") &&
      (filter.minDisplacement ?? "" === "") &&
      (filter.minDoors ?? "" === "") &&
      (filter.minKw ?? "" === "") &&
      (filter.minNm ?? "" === "") &&
      (filter.minPrice ?? "" === "") &&
      (filter.minSeats ?? "" === "") &&
      (filter.model ?? "" === "") &&
      (filter.modelClass ?? "" === "") &&
      (filter.modelYear ?? "" === "") &&
      (filter.transType ?? "" === "") &&
      (filter.variant ?? "" === "") &&
      (filter.variantId ?? "" === "") &&
      (filter.style ?? "" === "") &&
      viewType === UndetinfiedVinSearchViewType.SearchResult
    ) {
      dispatch(showLoader());

      setFilter({ group: sessionState.selectedOem?.g_desc.toString() });
      parameterSearch({
        group: scannedGroup ? scannedGroup : selectedVGroup, //this is the search API Undetinfied
        model: ScannedModel ? ScannedModel : selectedVModel,
        variant: selectedVariant,
        style: enteredStyle,
        transType: selectedTransType,
        fuelType: selectedFuelType,
        drive: selectedDriveType,
        minPrice: sliderValues[0],
        maxPrice: sliderValues[1],
        minKw: selectedKw[0],
        maxKw: selectedKw[1],
        maxNm: selectedNm[1],
        minNm: selectedNm[0],
        modelYear: selectedYear ? selectedYear : selectedVYear,
        minDoors: selectedDoors,
        minSeats: selectedSeats,
        minDisplacement: selectedDisplacement,
        GMV: enteredVarID,
        listType: selectedStatus,
      })
        .then((paramSearchResponse) => {
          dispatch(hideLoader());
          if (paramSearchResponse.status === 200) {
            setSearchResults(paramSearchResponse.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [
    dispatch,
    filter,
    selectedGroup,
    selectedVGroup,
    selectedVModel,
    selectedModel,
    selectedDriveType,
    selectedFuelType,
    selectedStyle,
    selectedTransType,
    selectedVariant,
    selectedYear,
    enteredVarID,
    selectedDoors,
    selectedDisplacement,
    selectedStatus,
    selectedSeats,
    selectedKw[0],
    selectedKw[1],
    selectedNm[0],
    selectedNm[1],
    sliderValues[0],
    sliderValues[1],
    enteredStyle,
  ]);

  //this method returns all available models based on your OEM
  const addGroups: Array<string> = [];
  const getAddGroups = () => {
    groupDropDown?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addGroups.indexOf(v.g_desc) === -1
      ) {
        addGroups.push(v.g_desc);
        addGroups.sort();
      }
      return "";
    });
  };
  getAddGroups();

  const [addStylesAvail, setAddStylesAvail] = useState<
    Array<String | Number> | []
  >();
  const stylesAvail: Array<string> = [];
  const handleClickedOEM = (v: String | Number) => {
    vehicles?.forEach((value) => {
      if (value.g_desc == v) {
        if (stylesAvail.indexOf(value.style) == -1) {
          stylesAvail.push(value.style);
          stylesAvail.sort();
          setAddStylesAvail(stylesAvail);
        }
      }

      return stylesAvail;
    });
  };

  // Fetch groups dropdown vals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsFetcher();
        if (response.status === 200) {
          setGroupDropDown(response.data);
        } else {
          console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Funtion for getting the Models with the GroupId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await modelsFetcher(selectedGroupId?.toString() ?? "");
        if (response.status === 200) {
          setModelsDropDown(response.data);
        } else {
          //console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        //console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, [selectedGroupId]);

  //const addModels: Array<string> = [];
  const handleClickedGroup = (clickedGroup: string) => {
    vehicles?.forEach((v) => {
      if (v.g_desc == clickedGroup) {
        if (addModels.indexOf(v.m_desc) == -1) {
          addModels.push(v.m_desc);
          addModels.sort();
          setAddModelsByGroup(addModels);
        }
      }
      return addModels;
    });
  };

  let addModels: Array<string> = [];

  const getModelsGroup = () => {
    modelsDropDown?.map((m) => {
      if (addModels.indexOf(m.m_desc) === -1) {
        addModels.push(m.m_desc);

        addModels.sort();
      }
      return "";
    });
  };
  getModelsGroup();

  //Function  mapping all the varients i.e the localStorage checking if  the g_desc is equal to Scanned Model.
  const AddModel = () => {
    const gDescFromSessionStorage = sessionStorage.getItem("ScannedGroup");

    if (gDescFromSessionStorage) {
      vehicles?.forEach((v) => {
        // Check if the g_desc of the vehicle matches the one in sessionStorage
        if (v.g_desc === gDescFromSessionStorage) {
          // Check if the variant is not already in the array
          if (addModels.indexOf(v.m_desc) === -1) {
            addModels.push(v.m_desc);
            addModels.sort();
          }
        }
      });
    }
  };

  AddModel();

  const addVariants: Array<string> = [];
  const handleClickedModel = (clickedModel: string) => {
    vehicles?.map((v) => {
      if (v.m_desc !== clickedModel) {
        if (addVariants.indexOf(v.v_desc) == -1) {
          addVariants.push(v.v_desc);
          setAddVariantsByModel(addVariants);
        }
      }

      return addVariants;
    });
  };

  const getVariantsGroup = () => {
    varientsDropDown?.map((v) => {
      if (addVariants.indexOf(v.v_desc) === -1) {
        addVariants.push(v.v_desc);
        addVariants.sort();
      }
      return "";
    });
  };
  getVariantsGroup();

  //Function  mapping all the varients i.e the localStorage checking if  the g_desc is equal to Scanned Model.
  const AddVarients = () => {
    const gDescFromSessionStorage = sessionStorage.getItem("ScannedModel");

    if (gDescFromSessionStorage) {
      vehicles?.forEach((v) => {
        // Check if the g_desc of the vehicle matches the one in sessionStorage
        if (v.m_desc === gDescFromSessionStorage) {
          // Check if the variant is not already in the array
          if (addVariants.indexOf(v.v_desc) === -1) {
            addVariants.push(v.v_desc);
            addVariants.sort();
          }
        }
      });
    }
  };

  AddVarients();

  //this method gets specific vaariants based on your selected model
  const specificVariants: Array<string> = [];
  const VariantsId: Array<number> = [];
  const variantYear: Array<Date> = [];
  const handleClikedModels = (checkedModel: string) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == checkedModel) {
        specificVariants.push(value.v_desc);
        specificVariants.sort();
        setSpecificVariantsByModel(specificVariants);
      }

      return specificVariants && specificVariantsId && variantYear;
    });
  };

  const accordGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  const toggleModelsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "modelsAccord";
    }
  };

  const toggleStyleAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "styleAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "styleAccord";
    }
  };

  const toggleFuelAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "fuelAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "fuelAccord";
    }
  };

  const toggleTransAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "transAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "transAccord";
    }
  };

  const toggleYearAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "yearAccord") {
      natEl.value = undefined;
      onSearch();
    } else {
      natEl.value = "yearAccord";
    }
  };

  const toggleSeatsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "seatsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "seatsAccord";
    }
  };

  const toggleDoorsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "doorsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "doorsAccord";
    }
  };

  const toggleDriveAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "driveAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "driveAccord";
    }
  };
  const toggleStatusAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
  };

  const toggleVariantsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "varAccord") {
      natEl.value = undefined;
      onSearch();
      stopListening();
    } else {
      natEl.value = "varAccord";
    }
  };

  const handleModalDismiss = () => {
    onClosed();
    onResetFilters();
  };

  const toggleGroupsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "groupsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "groupsAccord";
    }
  };

  const handleActiveCheckBox = (key: string) => {
    setSelectedCheckbox(key);
  };

  const handleActiveVariantCheckBox = (key: string) => {
    setSelectedVariantCheckbox(key);
  };

  const handleActiveStyleCheckBox = (key: string) => {
    setSelectedStyleCheckbox(key);
  };

  const handleActiveModelCheckBox = (key: string) => {
    setSelectedModelCheckbox(key);
  };

  const handleActiveFuelCheckbox = (key: string) => {
    setSelectedFuelCheckbox(key);
  };
  const handleActiveYearCheckBox = (key: string) => {
    setSelectedYearCheckbox(key);
  };

  const handleActiveTransCheckBox = (key: string) => {
    setSelectedTransCheckbox(key);
  };

  const onBackPress = useCallback(() => {
    dispatch(setUndentifiedVinSearchVisible(false));
    sessionStorage.clear();
    onResetFilters();
  }, [dispatch]);

  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [listeningStatus, setListeningStatus] = useState(false);

  const startListening = () => {
    setListeningStatus(true);

    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    setListeningStatus(false);
    resetTranscript();
    SpeechRecognition.stopListening();
  };

  //The voice recognition for the Vehicles Groups
  const [prevTranscript, setPrevTranscript] = useState("");

  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for single word match
        const singleWordMatchIndex = addGroups.findIndex(
          (label) => label === words[i]
        );

        if (singleWordMatchIndex !== -1) {
          setSelectedVGroup(addGroups[singleWordMatchIndex]);
          setSelectedCheckbox(singleWordMatchIndex.toString());
          handleActiveCheckBox(singleWordMatchIndex.toString());
          handleClickedOEM(addGroups[singleWordMatchIndex]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[singleWordMatchIndex]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }

          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatchIndex = addGroups.findIndex(
            (label) => label === twoWords
          );

          if (twoWordsMatchIndex !== -1) {
            setSelectedVGroup(addGroups[twoWordsMatchIndex]);
            setSelectedCheckbox(twoWordsMatchIndex.toString());
            handleActiveCheckBox(twoWordsMatchIndex.toString());
            handleClickedOEM(addGroups[twoWordsMatchIndex]);
            const selectedGroup = groupDropDown.find(
              (group) => group.g_desc === addGroups[twoWordsMatchIndex]
            );

            if (selectedGroup) {
              setSelectedGroupId(selectedGroup.g_id);
            }

            return;
          }
        }
      }

      words.forEach((word) => {
        // Check for "manual" and avoid setting the state
        if (word === "MANUAL") {
          return;
        }

        const indexInGroups = addGroups.findIndex((label) => label === word);
        const indexInYears = yearsArray.findIndex((label) => label === word);
        const indexInModels = addModels.findIndex((label) => label === word);
        const indexInVariants = addVariants.findIndex(
          (label) => label === word
        );
        const indexInStyles = addStylesAvail?.findIndex(
          (label) => label === word
        );

        if (indexInGroups !== -1) {
          setSelectedVGroup(addGroups[indexInGroups]);

          setSelectedCheckbox(indexInGroups.toString());
          handleActiveCheckBox(indexInGroups.toString());
          handleClickedOEM(addGroups[indexInGroups]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[indexInGroups]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }
        }
      });
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addGroups,
    setSelectedCheckbox,
    handleActiveCheckBox,
    handleClickedOEM,
    setSelectedVGroup,
    handleActiveVariantCheckBox,
    groupDropDown,
    stopListening,
    setFinderUpdate,
  ]);

  //The models voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        const checkMatch = (label: string) => {
          const matchIndex = addModels.findIndex((model) => model === label);
          return matchIndex !== -1 ? addModels[matchIndex] : null;
        };

        // Check for single word match
        const singleWordMatch = checkMatch(words[i]);
        if (singleWordMatch) {
          handleMatch(singleWordMatch);
          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatch = checkMatch(twoWords);
          if (twoWordsMatch) {
            handleMatch(twoWordsMatch);
            return;
          }
        }
      }
    };

    const handleMatch = (matchedModel: SetStateAction<string>) => {
      setSelectedVModel(matchedModel);
      handleActiveModelCheckBox(matchedModel.toString());

      const modelString = modelsDropDown.find(
        (model) => model.m_desc === matchedModel
      );
      if (modelString) {
        setSelectedModelId(modelString.m_id);
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addModels,
    setSelectedModelId,
    handleActiveModelCheckBox,
    setSelectedVModel,
    modelsDropDown,
    stopListening,
  ]);

  //The models voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        const checkMatch = (label: string) => {
          const matchIndex = addModels.findIndex((model) => model === label);
          return matchIndex !== -1 ? addModels[matchIndex] : null;
        };

        // Check for single word match
        const singleWordMatch = checkMatch(words[i]);
        if (singleWordMatch) {
          handleMatch(singleWordMatch);
          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatch = checkMatch(twoWords);
          if (twoWordsMatch) {
            handleMatch(twoWordsMatch);
            return;
          }
        }
      }
    };

    const handleMatch = (matchedModel: SetStateAction<string>) => {
      setSelectedVModel(matchedModel);
      handleActiveModelCheckBox(matchedModel.toString());

      const modelString = modelsDropDown.find(
        (model) => model.m_desc === matchedModel
      );
      if (modelString) {
        setSelectedModelId(modelString.m_id);
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addModels,
    setSelectedModelId,
    handleActiveModelCheckBox,
    setSelectedVModel,
    modelsDropDown,
    stopListening,
  ]);

  const [unidentifiedVinNotify, setUnidentifiedVinNotify] = useState(true);
  const [notifierClassName, setNotifierClassName] = useState("notifyShow");
  const [listeningActive, setListeningActive] = useState(false);

  return (
    <IonModal
      className="mod"
      onDidDismiss={handleModalDismiss}
      isOpen={isVisible}
      style={{ background: "transparent" }}
    >
      <GlobalGrid
        currentPageTitle={searchResults ? "SEARCH RESULTS" : "UNIDENTIFIED VIN"}
        closeCurrentPage={() => {
          dispatch(setUndentifiedVinSearchVisible(false));
          resetTranscript();
          stopListening();
        }}
        videoSource="../UndentifiedVin/APP-VINScanResult.mp4"
      >
        <div className="filterSearchMobileCont">
          <div className="topSearch">
            <div className="accordians">
              <IonGrid>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>VIN</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("Vin Scanned") || ""}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>ENGINE</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("ScannedEngine") || ""}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>REGISTRATION</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("ScannedRegistration") || ""}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>GROUP</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("ScannedGroup") ||
                      selectedVGroup ||
                      "undefined"}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>MODEL</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("ScannedModel") ||
                      selectedVModel ||
                      "undefined"}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>VARIANT</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    UNKNOWN
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-justify-content-end desktopLabelLower">
                    <strong>BODY TYPE</strong>
                  </IonCol>
                  <IonCol className="ion-justify-content-start padding-table desktopValue">
                    {sessionStorage.getItem("ScannedType") || ""}
                  </IonCol>
                </IonRow>

                <IonRow>
                  {" "}
                  {searchResults != null ? (
                    <></>
                  ) : (
                    <IonCol className="col-border">
                      <p className="vinResultsTxt">
                        The information obtained underwent cross-referencing
                        with our database, but the model{"  "}
                        <u>
                          <b>could not be identified. </b>
                        </u>
                        Please utilize the filter options below to provide
                        alternative means of identification
                      </p>
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>

              {/* {searchResults != null ? (
              <></>
            ) : (
              <>
                <div className=" voice">
                  <>
                    <div className="box">
                      <div className="innerBox">
                        <div className="top">
                          <img
                            src={icons.voiceRec}
                            className="img"
                            alt="Camera Scan"
                            onClick={() => {
                              startListening();
                            }}
                          />
                        </div>
                        <div className="bottom">
                          <span className="inputHolder"></span>
                          Our mobile app includes a fantastic voice recognition
                          feature. All you need to do is say the name of the
                          vehicle you're searching for, and voila! The filter
                          options will be automatically selected for you. It's
                          that easy! Happy browsing!
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </>
            )} */}
              <IonRow id="filterSearchContent">
                <IonCol
                  size="12"
                  sizeXs="12"
                  sizeMd="12"
                  sizeLg="10"
                  sizeXl="10"
                  id="filterSearchContentRow"
                >
                  <IonAccordionGroup
                    id="gridAccordGroup"
                    ref={accordGroup}
                    style={{ justifyContent: "flex-start" }}
                  >
                    {listeningStatus ? (
                      <>
                        <VinVoice
                          isVisible={listeningStatus === true ? true : false}
                        />
                      </>
                    ) : (
                      <IonAccordion id="gridAccord" value="groupsAccord">
                        <IonItem slot="header" id="gridAccordTitle">
                          {selectedGroup && (
                            <IonIcon
                              src={checkbox}
                              id="selectionConfirmationBox"
                            ></IonIcon>
                          )}
                          GROUP
                          <span className="selectionDisplay">
                            {sessionStorage.getItem("ScannedGroup")
                              ? sessionStorage.getItem("ScannedGroup")
                              : selectedGroup}
                          </span>
                        </IonItem>

                        <IonList slot="content" id="gridAccordItemsContainer">
                          {addGroups.map((e, index) => {
                            return (
                              <IonItem
                                title="variant"
                                id="gridAccordItems"
                                key={index}
                              >
                                <IonLabel id="gridAccordItemsLabel">
                                  <input
                                    className="radio"
                                    checked={
                                      e.toString() ===
                                      (sessionStorage.getItem("ScannedGroup") ||
                                        "")
                                    }
                                    id={
                                      sessionStorage.getItem("ScannedGroup") ||
                                      ""
                                    }
                                    type="checkbox"
                                    value={e.toString()}
                                    onClick={() => toggleGroupsAccordian()}
                                    onChange={(event) => {
                                      const selectedGroupValue =
                                        event.target.value;
                                      const selectedGroup = groupDropDown.find(
                                        (group) => group.g_desc === e
                                      );
                                      if (selectedGroup) {
                                        setSelectedCheckbox(index.toString());
                                        setSelectedGroup(selectedGroup.g_desc);
                                        setSelectedGroupId(selectedGroup.g_id); // assuming setSelectedGroupId is a state updater for the selected g_id
                                        // handleClickedGroup(e);
                                        handleActiveCheckBox(index.toString());
                                        handleClickedOEM(e);
                                      }

                                      // Store the selected group in sessionStorage
                                      sessionStorage.setItem(
                                        "ScannedGroup",
                                        selectedGroupValue
                                      );

                                      // Get the selected group from sessionStorage with a default value
                                      const selectedGroupFromStorage =
                                        sessionStorage.getItem(
                                          "ScannedGroup"
                                        ) || "";

                                      setSelectedGroup(
                                        selectedGroupFromStorage
                                      );
                                      handleClickedGroup(
                                        selectedGroupFromStorage
                                      );

                                      handleActiveCheckBox(index.toString());
                                    }}
                                  ></input>
                                  {e}
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonAccordion>
                    )}

                    {listeningStatus ? (
                      <></>
                    ) : (
                      <IonAccordion id="gridAccord" value="modelsAccord">
                        <IonItem slot="header" id="gridAccordTitle">
                          MODEL
                          <span className="selectionDisplay">
                            {/* {sessionStorage.getItem("ScannedModel")} */}
                            {vehicles?.some(
                              (v) =>
                                v.m_desc ===
                                sessionStorage.getItem("ScannedModel")
                            )
                              ? sessionStorage.getItem("ScannedModel")
                              : "Please select the model."}
                          </span>
                        </IonItem>
                        <IonList slot="content" id="gridAccordItemsContainer">
                          {addModels?.map((e, index) => {
                            return (
                              <IonItem
                                title="variant"
                                id="gridAccordItems"
                                key={index}
                              >
                                <IonLabel id="gridAccordItemsLabel">
                                  <input
                                    className="radio"
                                    id={index.toString()}
                                    checked={
                                      e.toString() ===
                                      (sessionStorage.getItem("ScannedModel") ||
                                        "")
                                    }
                                    type="checkbox"
                                    value={e.toString()}
                                    onClick={() => toggleModelsAccordian()}
                                    onChange={(event) => {
                                      const selectedModelValue =
                                        event.target.value;

                                      const modelString = modelsDropDown.find(
                                        (model) => model.m_desc === e
                                      );
                                      if (modelString) {
                                        setSelectedModel(event.target.value);
                                        setSelectedModelId(modelString.m_id);
                                        handleActiveModelCheckBox(
                                          index.toString()
                                        );
                                        handleClickedModel(e.toString());
                                      }

                                      // Store the selected model in sessionStorage
                                      sessionStorage.setItem(
                                        "ScannedModel",
                                        selectedModelValue
                                      );

                                      // Get the selected model from sessionStorage with a default value
                                      const selectedModelFromStorage =
                                        sessionStorage.getItem(
                                          "ScannedModel"
                                        ) || "";

                                      setSelectedModel(
                                        sessionStorage.getItem(
                                          "ScannedModel"
                                        ) || ""
                                      );
                                      handleActiveModelCheckBox(
                                        index.toString()
                                      );
                                      handleClickedModel(
                                        selectedModelFromStorage
                                      );
                                      handleClickedOEM(e);
                                    }}
                                  ></input>
                                  {e}
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonAccordion>
                    )}

                    <IonAccordion id="gridAccord" value="varAccord">
                      <IonItem slot="header" id="gridAccordTitle">
                        VARIANT
                        {selectedVariant ? (
                          <span className="selectionDisplay">
                            {selectedVariant}
                          </span>
                        ) : (
                          <span className="selectionDisplay">
                            Please select variant
                          </span>
                        )}
                      </IonItem>
                      <IonList slot="content" id="gridAccordItemsContainer">
                        {addVariants
                          .filter((variant) => variant.includes(selectedStyle))
                          .map((e, index) => {
                            return (
                              <IonItem
                                title="variant"
                                id="gridAccordItems"
                                key={index}
                              >
                                <IonLabel id="gridAccordItemsLabel">
                                  <input
                                    className="radio"
                                    id={index.toString()}
                                    checked={
                                      selectedVariantCheckbox ===
                                      index.toString()
                                    }
                                    type="checkbox"
                                    value={e.toString()}
                                    onClick={() => toggleVariantsAccordian()}
                                    onChange={(event) => {
                                      setSelectedVariant(event.target.value);
                                      handleActiveVariantCheckBox(
                                        index.toString()
                                      );
                                    }}
                                  ></input>
                                  {e}
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                      </IonList>
                    </IonAccordion>

                    <IonAccordion id="gridAccord" value="yearAccord">
                      <IonItem slot="header" id="gridAccordTitle">
                        {yearPlaceholder}{" "}
                        <span className="selectionDisplay">{selectedYear}</span>
                      </IonItem>

                      <IonList slot="content" id="gridAccordItemsContainer">
                        {yearsArray.map((year, index) => (
                          <IonItem id="gridAccordItems" key={index.toString()}>
                            <IonLabel id="gridAccordItemsLabel">
                              <input
                                id={index.toString()}
                                checked={
                                  selectedYearCheckbox === index.toString()
                                }
                                value={year}
                                onClick={toggleYearAccordian}
                                onChange={(event) => {
                                  setSelectedYear(event.target.value);
                                  handleActiveYearCheckBox(index.toString());
                                }}
                                className="radio"
                                type="checkbox"
                              />
                              {year}
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </IonAccordion>

                    <div className="resultCont">
                      <SearchResultViewVin
                        viewType={viewType}
                        searchResultsVin={searchResults}
                      ></SearchResultViewVin>
                    </div>
                  </IonAccordionGroup>
                </IonCol>
              </IonRow>
            </div>
          </div>
        </div>
      </GlobalGrid>
    </IonModal>
  );
};
export default FilteredSearch;
