import {
  IonCol,
  IonContent,
  IonInput,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useCallback, useRef, useState, useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/header';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import { RootState } from '../../redux/store';
import {
  hideLoader,
  setLargeHeader,
  setMobileSearchType,
  setScreen,
  setSearchKeyword,
  setSearchResults,
  showLoader,
} from '../../redux/ui/uiSlice';
import { manualSearch, parameterSearch, getAllVehicles } from '../../api/mapo';
import { AllVehicleParam } from '../../interfaces/allVehicleParam';
import { ParamSearch } from '../../interfaces/paramSearch';
import Button from '../../components/button';
import { desktopImages } from '../../assets';

const FilteredSearchMobile: React.FC = () => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<
    Array<AllVehicleParam> | undefined
  >();
  const isLoaded = useRef(false);
  const [keywords, setKeywords] = useState('');
  const [filter, setFilter] = useState<ParamSearch>({});

  const loadVehicles = useCallback(() => {
    if ((sessionState.user?.username ?? '') !== '') {
      dispatch(showLoader());
      getAllVehicles(uiState?.isDesktop ?? false)
        .then((vehicleResponse) => {
          dispatch(hideLoader());
          if (vehicleResponse.status === 200) {
            setVehicles(vehicleResponse.data);
          } else {
            alert(vehicleResponse.statusText);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, sessionState.user, uiState]);

  const onResetFilters = useCallback(() => {
    setKeywords('');
    setFilter({});
    dispatch(setSearchResults(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (vehicles === undefined && !isLoaded.current) {
      isLoaded.current = true;
      loadVehicles();
    }
  }, [vehicles, loadVehicles]);

  const getFilteredVehicles = useCallback(() => {
    let newFilteredList: Array<AllVehicleParam> | undefined = [];
    if (
      (filter?.variant ?? '') &&
      (filter?.model ?? '') !== '' &&
      (filter?.group ?? '') !== ''
    ) {
      newFilteredList = vehicles?.filter(
        (item) =>
          item.g_desc === filter.group &&
          item.m_desc === filter.model &&
          item.v_desc === filter.variant
      );
    } else if ((filter?.model ?? '') !== '' && (filter?.group ?? '') !== '') {
      newFilteredList = vehicles?.filter(
        (item) => item.g_desc === filter.group && item.m_desc === filter.model
      );
    } else if ((filter?.group ?? '') !== '') {
      newFilteredList = vehicles?.filter(
        (item) => item.g_desc === filter.group
      );
    } else if (vehicles) {
      newFilteredList = [...vehicles];
    }

    return newFilteredList;
  }, [filter, vehicles]);

  const getModelFilteredList = useCallback(() => {
    let newFilteredList: Array<AllVehicleParam> | undefined =
      (filter?.group ?? '') !== ''
        ? vehicles?.filter((item) => item.g_desc === filter.group)
        : vehicles;
    return newFilteredList;
  }, [vehicles, filter]);

  const getVariantFilteredList = useCallback(() => {
    let newFilteredList: Array<AllVehicleParam> | undefined =
      (filter?.group ?? '') !== '' && (filter?.model ?? '') !== ''
        ? vehicles?.filter(
            (item) =>
              item.g_desc === filter.group && item.m_desc === filter.model
          )
        : (filter?.group ?? '') !== ''
        ? vehicles?.filter((item) => item.g_desc === filter.group)
        : vehicles;
    return newFilteredList;
  }, [vehicles, filter]);

  const getColumnOptions = useCallback(
    (column: string): ReactNode => {
      const values: Array<string> = [''];

      let filteredList: Array<AllVehicleParam> | undefined =
        getFilteredVehicles();

      switch (column) {
        case 'Group':
          vehicles?.map((vehicle) => {
            if (values.indexOf(vehicle.g_desc) === -1) {
              values.push(vehicle.g_desc);
            }
            return '';
          });
          values.sort();
          break;
        case 'Model':
          let modelList = getModelFilteredList();
          modelList?.map((vehicle) => {
            if (values.indexOf(vehicle.m_desc) === -1) {
              values.push(vehicle.m_desc);
            }
            return '';
          });
          values.sort();
          break;
        case 'Model Class':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.modelclass) === -1) {
              values.push(vehicle.modelclass);
            }
            return '';
          });
          values.sort();
          break;
        case 'GMV':
          filteredList?.map((vehicle) => {
            const gmv =
              vehicle.g_id.toString() +
              vehicle.m_id.toString() +
              vehicle.v_id.toString();
            if (values.indexOf(gmv) === -1) {
              values.push(gmv);
            }
            return '';
          });
          values.sort();
          break;
        case 'Variant ID':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.v_id.toString()) === -1) {
              values.push(vehicle.v_id.toString());
            }
            return '';
          });
          values.sort();
          break;
        case 'Variant':
          let variantList = getVariantFilteredList();
          variantList?.map((vehicle) => {
            if (values.indexOf(vehicle.v_desc) === -1) {
              values.push(vehicle.v_desc);
            }
            return '';
          });
          values.sort();
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.v_desc) === -1) {
              values.push(vehicle.v_desc);
            }
            return '';
          });
          values.sort();
          break;
        case 'Doors':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.doors.toString()) === -1) {
              values.push(vehicle.doors.toString());
            }
            return '';
          });
          values.sort();
          break;
        case 'Seats':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.seats.toString()) === -1) {
              values.push(vehicle.seats.toString());
            }
            return '';
          });
          values.sort();
          break;
        case 'Fuel Type':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.fueltype) === -1) {
              values.push(vehicle.fueltype);
            }
            return '';
          });
          values.sort();
          break;
        case 'Transmission':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.transdesc) === -1) {
              values.push(vehicle.transdesc);
            }
            return '';
          });
          values.sort();
          break;
        case 'KW':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.kw + '') === -1) {
              values.push(vehicle.kw + '');
            }
            return '';
          });
          values.sort();
          break;
        case 'NM':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.nm + '') === -1) {
              values.push(vehicle.nm + '');
            }
            return '';
          });
          values.sort();
          break;
        case 'Displacement':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.discc + '') === -1) {
              values.push(vehicle.discc + '');
            }
            return '';
          });
          values.sort();
          break;
        case 'Drive':
          filteredList?.map((vehicle) => {
            if (values.indexOf(vehicle.drive + '') === -1) {
              values.push(vehicle.drive + '');
            }
            return '';
          });
          values.sort();
          break;
        case 'Current':
          values.push('Current');
          values.push('Discontinued');
          values.push('Future');
          break;
        case 'Base Price':
          filteredList?.map((vehicle) => {
            if (values.indexOf('R ' + vehicle.baseprice) === -1) {
              if (vehicle.baseprice === 0) {
                values.push('');
              } else {
                values.push('R ' + vehicle.baseprice);
              }
            }
            return '';
          });
          values.sort();
          break;
      }

      return (
        <>
          {values.map((value) => {
            return (
              <IonSelectOption value={value}>
                {value === ''
                  ? '(All)'
                  : value === 'd' && column === 'Fuel Type'
                  ? 'Diesel'
                  : value === 'p' && column === 'Fuel Type'
                  ? 'Petrol'
                  : value}
              </IonSelectOption>
            );
          })}
        </>
      );
    },
    [
      getFilteredVehicles,
      vehicles,
      getModelFilteredList,
      getVariantFilteredList,
    ]
  );

  const onKeywordSearch = useCallback(() => {
    dispatch(showLoader());
    manualSearch(keywords, uiState?.isDesktop ?? false)
      .then((keywordSearchResponse) => {
        dispatch(hideLoader());
        if (keywordSearchResponse.status === 200) {
          dispatch(setSearchResults(keywordSearchResponse.data));

          dispatch(setMobileSearchType('MANUAL SEARCH'));
          dispatch(setSearchKeyword(keywords));
          dispatch(setSearchResults(keywordSearchResponse.data));
          dispatch(setScreen(AppScreens.VehicleLanding));
          dispatch(setLargeHeader(false));
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [dispatch, keywords, uiState]);

  const onSearch = useCallback(() => {
    dispatch(showLoader());
    parameterSearch(filter)
      .then((paramSearchResponse) => {
        dispatch(hideLoader());
        if (paramSearchResponse.status === 200) {
          dispatch(setSearchResults(paramSearchResponse.data));

          dispatch(setMobileSearchType('FILTERED SEARCH'));
          dispatch(setSearchResults(paramSearchResponse.data));
          dispatch(setScreen(AppScreens.VehicleLanding));
          dispatch(setLargeHeader(false));
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [dispatch, filter, uiState.isDesktop]);

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      dispatch(setScreen(AppScreens.SearchResult));
    } else {
      dispatch(setScreen(AppScreens.Home));
    }
  }, [dispatch, uiState]);

  if (uiState.isDesktop) {
    return <></>;
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header ></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
               
              ></SubPageHeading>
              <IonRow style={{ width: '100%' }}>
                <IonCol size='10'>
                  <IonInput
                    className='inputFilter'
                    placeholder='KEYWORDS...'
                    value={keywords}
                    onIonChange={(ev: any) => setKeywords(ev.target.value)}
                    // onSubmit={onVinSearch}
                    onKeyUp={(ev: any) => {
                      // if (ev.keyCode === 13) onVinSearch();
                    }}
                  ></IonInput>
                </IonCol>
                <IonCol size='2' className='ion-align-self-end'>
                  <img
                    src={desktopImages.manualSearch}
                    className='customFilterButton'
                    alt='Search'
                    onClick={onKeywordSearch}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.group}
                    placeholder={'GROUP'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, group: val.detail.value })
                    }
                  >
                    {getColumnOptions('Group')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.model}
                    placeholder={'MODEL'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, model: val.detail.value })
                    }
                  >
                    {getColumnOptions('Model')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.variant}
                    placeholder={'VARIANT'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, variant: val.detail.value })
                    }
                  >
                    {getColumnOptions('Variant')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='GMV CODE'
                    value={filter?.GMV}
                    onIonChange={(ev: any) =>
                      setFilter({ ...filter, GMV: ev.target.value })
                    }
                  ></IonInput>
                </IonCol>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='VARIANT ID'
                    value={filter?.variantId}
                    onIonChange={(ev: any) =>
                      setFilter({ ...filter, variantId: ev.target.value })
                    }
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.modelClass}
                    placeholder={'MODEL CLASS'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, modelClass: val.detail.value })
                    }
                  >
                    {getColumnOptions('Model Class')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.listType}
                    placeholder={'CURRENT / DISCONTINUED'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, listType: val.detail.value })
                    }
                  >
                    {getColumnOptions('Current')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MIN PRICE'
                    value={filter?.minPrice}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        minPrice: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MAX PRICE'
                    value={filter?.maxPrice}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        maxPrice: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.fuelType}
                    placeholder={'FUEL TYPE'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, fuelType: val.detail.value })
                    }
                  >
                    {getColumnOptions('Fuel Type')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.transType}
                    placeholder={'TRANSMISSION TYPE'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, transType: val.detail.value })
                    }
                  >
                    {getColumnOptions('Transmission')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MIN KW'
                    value={filter?.minKw}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        minKw: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MAX KW'
                    value={filter?.maxKw}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        maxKw: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MIN NM'
                    value={filter?.minNm}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        minNm: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
                <IonCol>
                  <IonInput
                    className='inputFilter'
                    placeholder='MAX NM'
                    value={filter?.maxNm}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        maxNm: Number(val.detail.value),
                      })
                    }
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    value={filter?.minDoors}
                    placeholder={'MIN DOORS'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, minDoors: val.detail.value })
                    }
                  >
                    {getColumnOptions('Doors')}
                  </IonSelect>
                </IonCol>
                <IonCol>
                  <IonSelect
                    value={filter?.minSeats}
                    placeholder={'MIN SEATS'}
                    className='selectInput'
                    onIonChange={(val) =>
                      setFilter({ ...filter, minSeats: val.detail.value })
                    }
                  >
                    {getColumnOptions('Seats')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    className='selectInput'
                    placeholder='MIN DISPLACEMENT'
                    value={filter?.minDisplacement}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        minDisplacement: val.detail.value,
                      })
                    }
                  >
                    {getColumnOptions('Displacement')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonSelect
                    className='selectInput'
                    placeholder='DRIVE'
                    value={filter?.drive}
                    onIonChange={(val) =>
                      setFilter({
                        ...filter,
                        drive: val.detail.value,
                      })
                    }
                  >
                    {getColumnOptions('Drive')}
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Button
                    onPress={onResetFilters}
                    title='Reset Filters'
                    customClass='greenButton'
                  />
                </IonCol>
                <IonCol>
                  <Button onPress={onSearch} title='Search' />
                </IonCol>
              </IonRow>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default FilteredSearchMobile;
