import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSpeechRecognition } from "react-speech-recognition";
import {
  getAllVehicles,
  parameterSearch,
  groupsFetcher,
  modelsFetcher,
  modelClassFetcher,
  VarientsFetcher,
} from "../../api/mapo";
import { Vehicle } from "../../interfaces";
import { AllVehicleParam } from "../../interfaces/allVehicleParam";
import { ParamSearch } from "../../interfaces/paramSearch";
import { FilteredSearchViewType } from "../../modals/filtered-search";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  setFilteredSearchVisible,
  showLoader,
} from "../../redux/ui/uiSlice";
import {
  IonModal,
  IonIcon,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
} from "@ionic/react";
import {
  chevronBackCircleSharp,
  search,
  micSharp,
  micOffSharp,
  checkbox,
} from "ionicons/icons";
import { icons } from "../../assets";
import Header from "../header";
import VinVoiceResults from "./vinScanSearchResult";
import SearchResultViewVin from "../../modals/UndentifiedVin/search-result-view-vin";

interface VinVoiceProps {
  isVisible: boolean;
}

interface group {
  g_id: number;
  g_desc: string;
}

interface model {
  m_id: number;
  m_desc: string;
}

interface varients {
  v_id: number;
  v_desc: string;
}

interface modelClasses {
  modelclass: string;
  description: string;
}

const VinVoice: React.FC<VinVoiceProps> = ({ isVisible }) => {
  const [visibility, setVisibility] = useState(Boolean);

  useEffect(() => {
    setVisibility(isVisible);
  }, []);
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<Array<AllVehicleParam>>();
  const isLoaded = useRef(false);
  const [searchResults, setSearchResults] = useState<Array<Vehicle>>();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [filter, setFilter] = useState<ParamSearch>({});
  const yearsArray = Array.from({ length: 2025 - 1990 }, (_, index) =>
    (1990 + index).toString()
  );
  yearsArray.reverse();
  const [selectedSeats, setSelectedSeats] = useState(Number);
  const [selectedModel, setSelectedModel] = useState("");
  const modelString = selectedModel ? selectedModel.toString() : "";
  const [selectedStyle, setSelectedStyle] = useState(String);
  const [selectedModelPlaceholder, setSelectedModelPlaceholder] =
    useState("MODELS ");
  const [selectedVarPlaceholder, setSelectedVarPlaceholder] =
    useState("VARIANTS ");
  const [transPlaceholder, setTransPlaceholder] = useState("TRANSMISSION ");
  const [fuelPlaceholder, setFuellaceholder] = useState("FUEL TYPE ");
  const [yearPlaceholder, setYearPlaceholder] = useState("YEAR");
  const [doorsPlaceholder, setDoorsPlaceholder] = useState("DOORS");
  const [selectedTransType, setSelectedTransType] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [enteredVarID, setEnteredVarID] = useState("");
  const [enteredStyle, setEnteredStyle] = useState("");
  const [selectedDriveType, setSelectedDriveType] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDoors, setSelectedDoors] = useState(Number);
  const [selectedModelClass, setselectedModelClass] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const MINSLIDER = 0;
  const MAXSLIDER = 1500000;
  const MINKW = 0;
  const MAXKW = 1000;
  const MINNM = 0;
  const MAXNM = 1000;
  const MINDIS = 0;
  const MAXDIS = 5000;
  const [sliderValues, setSliderValues] = useState([MINSLIDER, MAXSLIDER]);
  const [selectedKw, setSelectedKW] = useState([MINKW, MAXKW]);
  const [selectedNm, setSelectedNm] = useState([MINNM, MAXNM]);
  const [selectedDisplacement, setSelectedDisplacement] = useState(0);
  const [fuelTypeArr, setFuelTypeArr] = useState(["Diesel", "Petrol"]);
  const [driveTypeArr, setTransType] = useState(["4x2", "4x4"]);
  const [transArr, setTransArr] = useState(["Manual", "Automatic"]);
  const [typedGroup, setTypedGroup] = useState(String);
  const [typedModel, setTypedModel] = useState("");
  const [typedStyle, setTypedStyle] = useState("");
  const [typedVariant, setTypedVariant] = useState("");
  const [finderUpdate, setFinderUpdate] = useState(" ");
  const [specificVariantsByModel, setSpecificVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [specificVariantsId, setSpecificVariantsId] = useState([]);
  const userName = JSON.stringify(sessionState.user?.username);
  const gCode = Number(sessionState.selectedOem?.g_id);
  //checkbox handlers
  const [selectedCheckbox, setSelectedCheckbox] = useState(String);
  const [selectedModelClassCheckbox, setSelectedModelClassCheckbox] =
    useState(String);
  const [selectedVariantCheckbox, setSelectedVariantCheckbox] =
    useState(String);
  const [selectedStyleCheckbox, setSelectedStyleCheckbox] = useState(String);

  const [selectedModelCheckbox, setSelectedModelCheckbox] = useState(String);
  const [selectedTransCheckbox, setSelectedTransCheckbox] = useState(String);
  const [selectedDriveCheckbox, setSelectedDriveCheckbox] = useState(String);
  const [selectedFuelCheckbox, setSelectedFuelCheckbox] = useState(String);
  const [selectedStatusCheckbox, setSelectedStatusCheckbox] = useState(String);
  const [selectedYearCheckbox, setSelectedYearCheckbox] = useState(String);
  const [statusArr, setStatusArr] = useState(["CURRENT", "DISCONTINUED"]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [addModelsByGroup, setAddModelsByGroup] = useState<
    Array<String | Number> | []
  >();

  const [addVariantsByModel, setAddVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [showVideo, setShowVideo] = useState(false);
  const [groupDropDown, setGroupDropDown] = useState<group[]>([]);
  const [modelsDropDown, setModelsDropDown] = useState<model[]>([]);
  const [modelClassDropDown, setModelClassDropDown] = useState<modelClasses[]>(
    []
  );
  const [notifierClassName, setNotifierClassName] = useState("notifyShow");
  const [varientsDropDown, setVarientsDropDown] = useState<varients[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
  };

  const [showVideos, setShowVideos] = useState(false);

  const toggleButtons = () => {
    setShowVideos(!showVideos);
  };

  const handleVideoEnds = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
  };

  const loadVehicles = useCallback(() => {
    if ((sessionState.user?.username ?? "") !== "") {
      getAllVehicles(uiState?.isDesktop ?? false)
        .then((vehicleResponse) => {
          dispatch(hideLoader());
          if (vehicleResponse.status === 200) {
            setVehicles(vehicleResponse.data);
          } else {
            alert(vehicleResponse.statusText);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, sessionState, uiState, filter]);

  useEffect(() => {
    if (isVisible && vehicles === undefined && !isLoaded.current) {
      isLoaded.current = true;
      loadVehicles();
    }
  }, [vehicles, isVisible, loadVehicles]);

  //defines action of reset button
  const onResetFilters = useCallback(() => {
    setTypedGroup("");
    setTypedVariant("");
    setTypedModel("");
    setSelectedStatus("");
    setSelectedCheckbox("");
    setSelectedModelClassCheckbox("");
    setSelectedModelCheckbox("");
    setselectedModelClass("");
    setSelectedVariantCheckbox("");
    setSelectedYearCheckbox("");
    setSelectedStyleCheckbox("");
    setSpecificVariantsByModel([]);
    setEnteredVarID("");
    setSelectedStyle("");
    setSelectedSeats(0);
    setSelectedNm([MINNM, MAXNM]);
    setSelectedKW([MINKW, MAXKW]);
    setSelectedDisplacement(0);
    setSliderValues([]);
    selectedKw[0] = MINKW;
    selectedKw[1] = MAXKW;
    selectedNm[0] = MINNM;
    selectedNm[1] = MAXNM;
    sliderValues[0] = MINSLIDER;
    sliderValues[1] = MAXSLIDER;
    setSelectedDriveType("");
    setSelectedModel("");
    setSelectedTransType("");
    setAddStylesAvail(undefined);
    setFilter({});
    setSelectedYear("");
    setShowVideo(false);
    setSelectedGroup("");
    setSelectedVariant("");
    setSelectedFuelType("");
    setSearchResults(undefined);
    setSliderValues([MINSLIDER, MAXSLIDER]);
    resetTranscript();
  }, []);
  //checks if vehicles is empty if so , gets all vehicles
  useEffect(() => {
    if (
      selectedGroup.length > 0 &&
      selectedModel.length > 0 &&
      selectedVariant.length > 0
    ) {
      onSearch();
    }
  }, [selectedGroup, selectedModel, selectedVariant]);
  //this function defines action of search button
  const onSearch = useCallback(() => {
    if (
      (filter.GMV ?? "" === "") &&
      (filter.drive ?? "" === "") &&
      (filter.fuelType ?? "" === "") &&
      (filter.group ?? "" === "") &&
      (filter.listType ?? "" === "") &&
      (filter.maxKw ?? "" === "") &&
      (filter.maxNm ?? "" === "") &&
      (filter.maxPrice ?? "" === "") &&
      (filter.minDisplacement ?? "" === "") &&
      (filter.minDoors ?? "" === "") &&
      (filter.minKw ?? "" === "") &&
      (filter.minNm ?? "" === "") &&
      (filter.minPrice ?? "" === "") &&
      (filter.minSeats ?? "" === "") &&
      (filter.model ?? "" === "") &&
      (filter.modelClass ?? "" === "") &&
      (filter.modelYear ?? "" === "") &&
      (filter.transType ?? "" === "") &&
      (filter.variant ?? "" === "") &&
      (filter.variantId ?? "" === "") &&
      (filter.style ?? "" === "")
    ) {
      dispatch(showLoader());

      parameterSearch({
        group: selectedGroup,
        modelClass: selectedModelClass,
        model: selectedModel,
        variant: selectedVariant,
        style: selectedStyle,
        transType: selectedTransType,
        fuelType: selectedFuelType,
        drive: selectedDriveType,
        minPrice: undefined,
        maxPrice: undefined,
        minKw: undefined,
        maxKw: undefined,
        maxNm: undefined,
        minNm: undefined,
        modelYear: selectedYear,
        minDoors: undefined,
        minSeats: selectedSeats,
        minDisplacement: undefined,
        GMV: enteredVarID,
        listType: selectedStatus,
      })
        .then((paramSearchResponse) => {
          dispatch(hideLoader());
          if (paramSearchResponse.status === 200) {
            setSearchResults(paramSearchResponse.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [
    dispatch,
    filter,
    selectedGroup,
    selectedModel,
    selectedDriveType,
    selectedFuelType,
    selectedStyle,
    selectedTransType,
    selectedVariant,
    selectedYear,
    enteredVarID,
    selectedDoors,
    selectedDisplacement,
    selectedStatus,
    selectedSeats,
    // selectedKw[0],
    //selectedKw[1],
    // selectedNm[0],
    // selectedNm[1],
    //sliderValues[0],
    //sliderValues[1],
    selectedStyle,
    selectedModelClass,
  ]);

  // Fetch groups dropdown vals
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsFetcher();
        if (response.status === 200) {
          setGroupDropDown(response.data);
        } else {
          //console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        // console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //this method returns all available models based on your OEM
  const addGroups: Array<string> = [];
  const getAddGroups = () => {
    groupDropDown?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addGroups.indexOf(v.g_desc) === -1
      ) {
        addGroups.push(v.g_desc);
        addGroups.sort();
      }
      return "";
    });
  };
  getAddGroups();

  //Funtion for getting the Models with the GroupId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await modelsFetcher(selectedGroupId?.toString() ?? "");
        if (response.status === 200) {
          setModelsDropDown(response.data);
        } else {
          //console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        //console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, [selectedGroupId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await modelClassFetcher(
          selectedGroupId?.toString() ?? ""
        );
        if (response.status === 200) {
          //console.log("classes fetched", response.data);
          setModelClassDropDown(response.data);
        } else {
          //console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        //console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, [selectedGroupId, setModelClassDropDown]);

  let addModels: Array<string> = [];

  const getModelsGroup = () => {
    modelsDropDown?.map((m) => {
      if (addModels.indexOf(m.m_desc) === -1) {
        addModels.push(m.m_desc);

        addModels.sort();
      }
      return "";
    });
  };
  getModelsGroup();

  const addModelClasses: Array<string> = [];

  const getModelClassByGroup = () => {
    modelClassDropDown?.map((m) => {
      if (addModelClasses.indexOf(m.description) === -1) {
        addModelClasses.push(m.description);
        addModelClasses.sort();
      }
      return "";
    });
  };
  getModelClassByGroup();

  //The Function to get the Variants

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await VarientsFetcher(
          Number(selectedGroupId),
          Number(selectedModelId)
        );
        if (response.status === 200) {
          setVarientsDropDown(response.data);
        } else {
          //console.error(`Failed to fetch data. Status: ${response.status}`);
        }
      } catch (error) {
        //console.error("An error occurred while fetching data varients:", error);
      }
    };
    fetchData();
  }, [selectedGroupId, selectedModelId]);

  const addVariants: Array<string> = [];
  const getVariantsGroup = () => {
    varientsDropDown?.map((v) => {
      if (addVariants.indexOf(v.v_desc) === -1) {
        addVariants.push(v.v_desc);
        addVariants.sort();
      }
      return "";
    });
  };
  getVariantsGroup();

  //The Function for getting the styles
  const [addStylesAvail, setAddStylesAvail] = useState<Array<String> | []>();

  const stylesAvail: Array<string> = [];
  const handleClickedOEM = (v: String) => {
    vehicles?.forEach((value) => {
      if (value.g_desc == v) {
        if (
          stylesAvail.indexOf(value.style) == -1 &&
          value.style != undefined &&
          value.style != null &&
          value.g_desc == selectedGroup
        ) {
          stylesAvail.push(value.style);
          stylesAvail.sort();
          setAddStylesAvail(stylesAvail);
        }
      }

      return stylesAvail;
    });
  };

  //For filtering with the
  const handleClickedModel = (v: string | number) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == v) {
        if (stylesAvail.indexOf(value.style) == -1) {
          stylesAvail.push(value.style);
          stylesAvail.sort();
          setAddStylesAvail(stylesAvail);
        }
      }

      return stylesAvail;
    });
  };

  //   filteredStyles.sort();
  //   setAddStylesAvail(filteredStyles);

  const addModelClass: Array<string> = [];
  const getModelClass = () => {
    vehicles?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addModelClass.indexOf(v.modelclass) === -1
      ) {
        addModelClass.push(v.modelclass);
        addModelClass.sort();
      }
      return "";
    });
  };
  getModelClass();

  //this method gets specific vaariants based on your selected model
  const specificVariants: Array<string> = [];
  const VariantsId: Array<number> = [];
  const variantYear: Array<Date> = [];
  const handleClikedModels = (checkedModel: string) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == checkedModel) {
        specificVariants.push(value.v_desc);
        specificVariants.sort();
        setSpecificVariantsByModel(specificVariants);
      }

      return specificVariants && specificVariantsId && variantYear;
    });
  };

  const accordGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  const toggleModelsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelsAccord") {
      natEl.value = undefined;
      //add here the call
    } else {
      natEl.value = "modelsAccord";
    }
  };

  const toggleModelClassAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelClassAccord") {
      natEl.value = undefined;
      //add here the call
    } else {
      natEl.value = "modelClassAccord";
    }
  };

  const toggleStyleAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "styleAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "styleAccord";
    }
  };

  const modelClassAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "ModelClassAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "ModelClassAccord";
    }
  };

  const toggleFuelAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "fuelAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "fuelAccord";
    }
  };

  const toggleTransAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "transAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "transAccord";
    }
  };

  const toggleYearAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "yearAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "yearAccord";
    }
  };

  const toggleSeatsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "seatsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "seatsAccord";
    }
  };

  const toggleDoorsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "doorsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "doorsAccord";
    }
  };

  const toggleDriveAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "driveAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "driveAccord";
    }
  };
  const toggleStatusAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
  };

  const toggleVariantsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "varAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "varAccord";
    }
  };

  const toggleGroupsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "groupsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "groupsAccord";
    }
  };

  const handleActiveCheckBox = (key: string) => {
    setSelectedCheckbox(key);
  };

  const handleActiveModelCassCheckBox = (key: string) => {
    setSelectedModelClassCheckbox(key);
  };

  const handleActiveVariantCheckBox = (key: string) => {
    setSelectedVariantCheckbox(key);
  };

  const handleActiveStyleCheckBox = (key: string) => {
    setSelectedStyleCheckbox(key);
  };

  const handleActiveModelCheckBox = (key: string) => {
    setSelectedModelCheckbox(key);
  };

  const handleActiveFuelCheckbox = (key: string) => {
    setSelectedFuelCheckbox(key);
  };
  const handleActiveYearCheckBox = (key: string) => {
    setSelectedYearCheckbox(key);
  };

  const handleActiveTransCheckBox = (key: string) => {
    setSelectedTransCheckbox(key);
  };
  const handleActiveDriveCheckBox = (key: string) => {
    setSelectedDriveCheckbox(key);
  };

  const handleActiveStatusCheckBox = (key: string) => {
    setSelectedStatusCheckbox(key);
  };

  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [listeningStatus, setListeningStatus] = useState(false);

  //The voice recognition for the Vehicles Groups
  const [prevTranscript, setPrevTranscript] = useState("");
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for single word match
        const singleWordMatchIndex = addGroups.findIndex(
          (label) => label === words[i]
        );

        if (singleWordMatchIndex !== -1) {
          setSelectedGroup(addGroups[singleWordMatchIndex]);
          setSelectedCheckbox(singleWordMatchIndex.toString());
          handleActiveCheckBox(singleWordMatchIndex.toString());
          handleClickedOEM(addGroups[singleWordMatchIndex]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[singleWordMatchIndex]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }

          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatchIndex = addGroups.findIndex(
            (label) => label === twoWords
          );

          if (twoWordsMatchIndex !== -1) {
            setSelectedGroup(addGroups[twoWordsMatchIndex]);
            setSelectedCheckbox(twoWordsMatchIndex.toString());
            handleActiveCheckBox(twoWordsMatchIndex.toString());
            handleClickedOEM(addGroups[twoWordsMatchIndex]);
            const selectedGroup = groupDropDown.find(
              (group) => group.g_desc === addGroups[twoWordsMatchIndex]
            );

            if (selectedGroup) {
              setSelectedGroupId(selectedGroup.g_id);
            }

            return;
          }
        }
      }

      words.forEach((word) => {
        // Check for "manual" and avoid setting the state
        if (word === "MANUAL") {
          return;
        }

        const indexInGroups = addGroups.findIndex((label) => label === word);
        const indexInYears = yearsArray.findIndex((label) => label === word);
        const indexInModels = addModels.findIndex((label) => label === word);
        const indexInVariants = addVariants.findIndex(
          (label) => label === word
        );
        const indexInStyles = addStylesAvail?.findIndex(
          (label) => label === word
        );

        if (indexInGroups !== -1) {
          setSelectedGroup(addGroups[indexInGroups]);

          setSelectedCheckbox(indexInGroups.toString());
          handleActiveCheckBox(indexInGroups.toString());
          handleClickedOEM(addGroups[indexInGroups]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[indexInGroups]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }
        }
      });
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addGroups,
    setSelectedCheckbox,
    handleActiveCheckBox,
    handleClickedOEM,
    setSelectedGroup,
    handleActiveVariantCheckBox,
    groupDropDown,

    setFinderUpdate,
  ]);

  //The models voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        const checkMatch = (label: string) => {
          const matchIndex = addModels.findIndex((model) => model === label);
          return matchIndex !== -1 ? addModels[matchIndex] : null;
        };

        // Check for single word match
        const singleWordMatch = checkMatch(words[i]);
        if (singleWordMatch) {
          handleMatch(singleWordMatch);
          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatch = checkMatch(twoWords);
          if (twoWordsMatch) {
            handleMatch(twoWordsMatch);
            return;
          }
        }
      }
    };

    const handleMatch = (matchedModel: SetStateAction<string>) => {
      setSelectedModel(matchedModel);
      handleActiveModelCheckBox(matchedModel.toString());

      const modelString = modelsDropDown.find(
        (model) => model.m_desc === matchedModel
      );
      if (modelString) {
        setSelectedModelId(modelString.m_id);
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addModels,
    setSelectedModelId,
    handleActiveModelCheckBox,
    setSelectedModel,
    modelsDropDown,
  ]);

  //styles voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for single word match
        const singleWordMatchIndex = stylesAvail?.findIndex(
          (label) => label === words[i]
        );
        if (singleWordMatchIndex !== -1) {
          setSelectedStyle(stylesAvail[singleWordMatchIndex]);
          handleActiveStyleCheckBox(stylesAvail[singleWordMatchIndex]);
          const modelString = vehicles?.find(
            (model) => model.style === stylesAvail[singleWordMatchIndex]
          );

          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatchIndex = stylesAvail.findIndex(
            (label) => label === twoWords
          );
          if (twoWordsMatchIndex !== -1) {
            setSelectedStyle(stylesAvail[singleWordMatchIndex]);
            handleActiveStyleCheckBox(stylesAvail[singleWordMatchIndex]);
            const modelString = vehicles?.find(
              (model) => model.style === stylesAvail[singleWordMatchIndex]
            );

            return;
          }
        }
      }
    };
    // Check if there's a new transcript and its different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    stylesAvail,
    setSelectedStyle,
    handleActiveStyleCheckBox,
    setSelectedStyle,
  ]);

  useEffect(() => {
    const handleSpeechRecognition = () => {
      const transcriptUpperCase = transcript.toUpperCase();

      // Check for phrases indicating variant selection
      const variantPhrases = ["SELECT VARIANT", "VARIANT IS", "CHOOSE VARIANT"];
      const hasVariantPhrase = variantPhrases.some((phrase) =>
        transcriptUpperCase.includes(phrase)
      );

      if (hasVariantPhrase) {
        // Extract the variant part of the transcript
        const variantIndex = variantPhrases.findIndex((phrase) =>
          transcriptUpperCase.includes(phrase)
        );
        const variantStartIndex =
          transcriptUpperCase.indexOf(variantPhrases[variantIndex]) +
          variantPhrases[variantIndex].length;
        const selectedVariant = transcript.slice(variantStartIndex).trim();

        // Find the corresponding variant in the list
        const variantMatchIndex = addVariants?.findIndex((label) =>
          label.includes(selectedVariant)
        );

        if (variantMatchIndex !== -1) {
          // Introduce a delay before making the final decision
          setTimeout(() => {
            setSelectedVariant(addVariants[variantMatchIndex]);
            handleActiveVariantCheckBox(addVariants[variantMatchIndex]);
            const modelString = vehicles?.find(
              (model) => model.v_desc === addVariants[variantMatchIndex]
            );
          }, 3000); // 3000 milliseconds (3 seconds) delay
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addVariants,
    setSelectedVariant,
    handleActiveVariantCheckBox,
    toggleVariantsAccordian,
  ]);

  //status speech search drive type
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for drive type matches
        const driveTypeMatch = getDriveTypeMatch(words, i);

        if (driveTypeMatch) {
          setSelectedDriveType(driveTypeMatch);
          handleActiveDriveCheckBox(driveTypeMatch);
          return;
        }
      }
    };

    const getDriveTypeMatch = (words: any[], index: number) => {
      const currentWord = words[index];

      // Check for specific drive types
      switch (currentWord) {
        case "FOUR":
          return "4x4";
        case "4X2":
          return "4x2";
        case "FRONT":
          return "4x2";
        case "REAR":
          return "4x2 ";
        case "ALL":
          return "4x4";
        default:
          // Check for multi-word matches
          const currentPhrase = words.slice(index, index + 2).join(" ");
          const multiWordMatch = driveTypeArr.find(
            (label) => label === currentPhrase
          );
          return multiWordMatch || null;
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    driveTypeArr,
    setSelectedDriveType,
    handleActiveDriveCheckBox,
  ]);

  //speech rec fuel
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for "diesel" match
        if (words[i] === "DIESEL") {
          setSelectedFuelType(words[i][0]);
          handleActiveFuelCheckbox(words[i][0]);
          return;
        }

        // Check for "petrol" match
        if (words[i] === "PETROL") {
          setSelectedFuelType(words[i][0]);
          handleActiveFuelCheckbox(words[i][0]);
          return;
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [transcript, setSelectedFuelType, handleActiveFuelCheckbox]);

  //speech rec fuel
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for "diesel" match
        if (words[i] === "AUTOMATIC") {
          setSelectedTransType(words[i][0]);
          handleActiveTransCheckBox(words[i][0]);
          return;
        }

        // Check for "petrol" match
        if (words[i] === "MANUAL") {
          setSelectedTransType(words[i][0]);
          handleActiveTransCheckBox(words[i][0]);
          return;
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [transcript, setSelectedTransType, handleActiveTransCheckBox]);

  //doors

  const [mikeOn, setMikeOn] = useState(false);

  // Define a function to process the names of modelClass
  const processName = (name: any) => {
    switch (true) {
      case name.includes("CARS - PICKUP & TRUCKS DC"):
        return "PICKUP DOUBLE AB";
      case name.includes("CARS - PICKUP & TRUCKS SC"):
        return "PICKUP SINGLE CAB";

      case name.includes("COMM - UTILITY VAN SMALL-SIZE"):
        return "UTILITY VAN SMALL-SIZE";
      case name.includes("CARS - COUPE"):
        return "COUPE";
      case name.includes("CARS - SPORT UTILITY VEHICLE"):
        return "SUV";
      case name.includes("CARS - FAMILY SEDAN"):
        return "FAMILY SEDAN";
      case name.includes("COMM - LIGHT PASSENGER"):
        return "LIGHT PASSENGER";
      case name.includes("CARS - MULTY PURPOSE VEHICLE"):
        return "MULTY PURPOSE VEHICLE";
      case name.includes("CARS - HATCHBACK"):
        return "HATCHBACK";
      case name.includes("CARS - SPORT WAGEN"):
        return "SPORT WAGEN";

      case name.includes("CARS - COUPE-CABRIOLET"):
        return "CABRIOLET";
      case name.includes("CARS - CROSSOVER"):
        return "CROSSOVER";
      case name.includes("CARS - SMALL ECONOMY HATCH"):
        return "SMALL ECONOMY HATCH";
      case name.includes("CARS - SMALL ECONOMY SEDAN"):
        return "SMALL ECONOMY SEDAN";
      case name.includes("CARS - LUXURY"):
        return "LUXURY";
      case name.includes("CARS - PERFORMANCE HATCH"):
        return "PERFORMANCE HATCH";
      case name.includes("CARS - PICKUP & TRUCKS EC"):
        return "PICKUP EXTENDED CAB";
      case name.includes("CARS - SPORT"):
        return "SPORT";
      // case name.includes("CARS-SPORT CONVERTIBLE"):
      //  return "SPORT CONVERTIBLE";
      case name.includes("BIKES - FOURWHEELERS & ATV"):
        return "FOURWHEELERS & ATV";
      case name.includes("BIKES - MOTORCROSS COMPETITION"):
        return "MOTORCROSS COMPETITION";
      case name.includes("BIKES - OFF ROAD & AGRI"):
        return "OFF ROAD & AGRICULTURAL";
      case name.includes("BIKES - SCOOTERS & COMMUTER"):
        return "SCOOTERS & COMMUTER";
      case name.includes("BIKES - SUPER BIKES"):
        return "SUPER BIKES";
      case name.includes("BIKES - TOURING & ADVENTURE"):
        return "TOURING & ADVENTURE";
      case name.includes("MARINE & WATER - FREIGHT CARRIERS"):
        return "FREIGHT CARRIERS";
      case name.includes("FARM/AGRI - TRACTOR"):
        return "TRACTOR";

      default:
        return name;
    }
  };

  const [searchLoad, setSearchLoad] = useState(false);

  useEffect(() => {
    groupDropDown?.forEach((value, ind) => {
      if (value.g_desc === typedGroup) {
        setSelectedGroup(value.g_desc);
        setSelectedGroupId(value.g_id);
        setSelectedCheckbox(ind.toString());
        handleActiveCheckBox(ind.toString());
      }
    });

    addGroups?.forEach((v, i) => {
      if (v === typedGroup) {
        setSelectedCheckbox(i.toString());
        handleActiveCheckBox(i.toString());
        handleClickedOEM(v);
      }
    });
  }, [addGroups, typedGroup, handleClickedOEM, groupDropDown]);

  useEffect(() => {
    modelsDropDown?.forEach((value, ind) => {
      if (value.m_desc === typedModel) {
        setSelectedModelId(value.m_id);
        setSelectedModel(value.m_desc);
        setSelectedModelCheckbox(ind.toString());
        handleActiveModelCheckBox(ind.toString());
      }
    });

    addModels?.forEach((v, i) => {
      if (v === typedGroup) {
        handleClickedModel(v);
      }
    });
  }, [
    addModels,
    typedModel,
    handleActiveModelCheckBox,
    setSelectedModelCheckbox,
    setSelectedModelId,
    setSelectedModel,
    modelsDropDown,
  ]);

  useEffect(() => {
    vehicles?.forEach((value, ind) => {
      if (value.style === typedStyle) {
        setSelectedStyle(value.style);
        setSelectedModelCheckbox(ind.toString());
        handleActiveStyleCheckBox(ind.toString());
      }
    });
  }, [
    addStylesAvail,
    vehicles,
    typedStyle,
    setSelectedStyle,
    handleActiveStyleCheckBox,
  ]);

  const handleBackClick = () => {
    setVisibility(false);
  };
  //start of JSX/TSX
  return visibility ? (
    <>
      <IonAccordionGroup
        className="accordCont"
        ref={accordGroup}
        style={{
          justifyContent: "flex-start",
        }}
      >
        <IonAccordion className="accord" value="groupsAccord">
          <span className="searchCont" slot="content">
            <label htmlFor="#">
              <IonIcon id="icon" src={search}></IonIcon>
              <input
                onChange={(e) => {
                  setTypedGroup(e.target.value.toUpperCase());
                }}
                type="text"
                placeholder={
                  addGroups?.includes(finderUpdate)
                    ? finderUpdate
                    : "Quick Search..."
                }
              />
              <IonIcon
                id="icon"
                src={listeningStatus ? micSharp : micOffSharp}
              ></IonIcon>
            </label>
          </span>
          <IonItem slot="header" className="filterHeader">
            {selectedGroup && (
              <IonIcon
                src={checkbox}
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "20px",
                  color: "green",
                }}
              ></IonIcon>
            )}
            GROUP
            <span className="selectionDisplay">
              {"  "}
              {selectedGroup}
            </span>
            {searchLoad && (
              <div className="LoadingClassSearch">
                <div className="loadingData"></div>
              </div>
            )}
          </IonItem>

          <ul slot="content" className="listCont">
            {addGroups.map((e, index) => (
              <>
                <li
                  title="variant"
                  className="variantsItems"
                  id="varItems"
                  key={index}
                >
                  <label className="listContVals">
                    <input
                      className="radio"
                      checked={selectedCheckbox === index.toString()}
                      id={index.toString()}
                      type="checkbox"
                      value={e.toString()}
                      onClick={() => toggleGroupsAccordian()}
                      onChange={(event) => {
                        const selectedGroup = groupDropDown.find(
                          (group) => group.g_desc === e
                        );
                        if (selectedGroup) {
                          setSelectedCheckbox(index.toString());
                          setSelectedGroup(selectedGroup.g_desc);
                          setSelectedGroupId(selectedGroup.g_id); // assuming setSelectedGroupId is a state updater for the selected g_id
                          // handleClickedGroup(e);
                          handleActiveCheckBox(index.toString());
                          handleClickedOEM(e);
                        }
                      }}
                    />
                    {e}
                  </label>
                </li>
              </>
            ))}
          </ul>
        </IonAccordion>

        <IonAccordion className="accord" value="modelsAccord">
          <IonItem slot="header" className="filterHeader">
            {selectedModel && (
              <IonIcon
                src={checkbox}
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "20px",

                  color: "green",
                }}
              ></IonIcon>
            )}
            MODEL
            <span className="selectionDisplay">{selectedModel}</span>
          </IonItem>
          <ul slot="content" className="listCont">
            {addModels?.map((e, index) => {
              return (
                <li
                  title="variant"
                  className="variantsItems"
                  id="varItems"
                  key={index}
                >
                  <label className="listContVals">
                    <input
                      className="radio"
                      id={index.toString()}
                      checked={selectedModelCheckbox === index.toString()}
                      type="checkbox"
                      value={e != null ? e.toString() : ""}
                      onClick={() => toggleModelsAccordian()}
                      onChange={(event) => {
                        const modelString = modelsDropDown.find(
                          (model) => model.m_desc === e
                        );
                        if (modelString) {
                          setSelectedModel(event.target.value);
                          setSelectedModelId(modelString.m_id);
                          handleActiveModelCheckBox(index.toString());
                          handleClickedModel(e.toString());
                        }
                      }}
                    ></input>
                    {e}
                  </label>
                </li>
              );
            })}
          </ul>
        </IonAccordion>

        <IonAccordion className="accord" value="varAccord">
          <span className="searchCont" slot="content">
            <label htmlFor="#">
              <IonIcon id="icon" src={search}></IonIcon>
              <input
                onChange={(e) => {
                  setTypedVariant(e.target.value.toUpperCase());
                }}
                type="text"
                placeholder={"Quick Search..."}
              />
              <IonIcon
                id="icon"
                src={listeningStatus ? micSharp : micOffSharp}
              ></IonIcon>
            </label>
          </span>
          <IonItem slot="header" className="filterHeader">
            {selectedVariant && (
              <IonIcon
                src={checkbox}
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "20px",

                  color: "green",
                }}
              ></IonIcon>
            )}
            VARIANT
            <span className="selectionDisplay">{selectedVariant}</span>
          </IonItem>
          <ul slot="content" className="listCont">
            {addVariants
              .filter((variant) => variant.includes(selectedStyle))
              .map((e, index) => {
                return (
                  <li
                    title="variant"
                    className="variantsItems"
                    id="varItems"
                    key={index}
                  >
                    <label className="listContVals">
                      <input
                        className="radio"
                        id={index.toString()}
                        checked={selectedVariantCheckbox === index.toString()}
                        type="checkbox"
                        value={e.toString()}
                        onClick={() => toggleVariantsAccordian()}
                        onChange={(event) => {
                          setSelectedVariant(event.target.value);
                          handleActiveVariantCheckBox(index.toString());
                        }}
                      ></input>
                      {e}
                    </label>
                  </li>
                );
              })}
          </ul>
        </IonAccordion>
        <IonAccordion className="accord" value="yearAccord">
          <IonItem slot="header" className="filterHeader">
            {selectedYear && (
              <IonIcon
                src={checkbox}
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "20px",

                  color: "green",
                }}
              ></IonIcon>
            )}
            {yearPlaceholder}{" "}
            <span className="selectionDisplay">{selectedYear}</span>
          </IonItem>

          <ul slot="content" className="listCont">
            {yearsArray.map((year, index) => (
              <li className="variantsItems" key={index.toString()}>
                <label className="listContVals">
                  <input
                    id={index.toString()}
                    checked={selectedYearCheckbox === index.toString()}
                    value={year}
                    onClick={toggleYearAccordian}
                    onChange={(event) => {
                      setSelectedYear(event.target.value);
                      handleActiveYearCheckBox(index.toString());
                    }}
                    className="radio"
                    type="checkbox"
                  />
                  {year}
                </label>
              </li>
            ))}
          </ul>
        </IonAccordion>
        <div className="resultCont">
          <SearchResultViewVin
            viewType={undefined}
            searchResultsVin={searchResults}
          ></SearchResultViewVin>
        </div>
      </IonAccordionGroup>
    </>
  ) : null;
};

export default VinVoice;
