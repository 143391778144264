import { IonRouterLink } from '@ionic/react';
import './style.css';

interface LinkProps {
  onPress: () => void;
  title: string;
  color: string;
  bold?: boolean;
  image?: any;
}

const Link: React.FC<LinkProps> = ({
  onPress,
  title,
  color,
  bold,
  image,
}: LinkProps) => {
  return (
    <IonRouterLink
      onClick={onPress}
      style={color ? { color: color } : {}}
      className={bold ? 'linkBold' : 'link'}
    >
      {image ? (
        <div className='iconAndTextContainer'>
          <img className='icon' src={image} alt={title} />
          {title}
        </div>
      ) : (
        <>{title}</>
      )}
    </IonRouterLink>
  );
};

export default Link;
