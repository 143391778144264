import { IonCol, IonContent, IonLabel, IonPage, IonRow } from "@ionic/react";
import Header from "../../components/header";
import { useCallback, useEffect, useRef, useState } from "react";
import SubPageHeading from "../../components/sub-page-heading";
import { useDispatch, useSelector } from "react-redux";
import { AppScreens } from "../../enums";
import { images } from "../../assets";
import { setScreen } from "../../redux/ui/uiSlice";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import axios from "axios";
import { RootState } from "../../redux/store";


const Subscription: React.FC = () => {
  const sessionState = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();
  const onBackPress = useCallback(() => {
    dispatch(setScreen(AppScreens.VehicleDetail));
  }, [dispatch]);

  useEffect(() => {
    const url = `https://api.payfast.co.za/transactions/history/monthly?date=2023-09&testing=true`;
    const config = {
      headers: {
        "merchant-id": "10030991",
        version: "v1",
        timestamp: "2023-09",
        signature: "MD5Signature",
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        console.log("the respo 1", response);
      })
      .catch((err) => {
        console.log("the respo 2", err.response);
      });
  }, []);

  return (
    <>
      <IonPage className="pageBg">
        <Header></Header>

        <IonContent className="contentPage">
          <div className="innerContent">
            <SubPageHeading
              onBack={onBackPress}
           
            ></SubPageHeading>

            
            <form
              action="https://sandbox.payfast.co.za/eng/process"
              method="post"
            >
              <input type="hidden" name="merchant_id" value="10030991" />
              <input type="hidden" name="merchant_key" value="pit1fhk733s3b" />
              <input type="hidden" name="amount" value="100.00" />
              <input type="hidden" name="item_name" value="Test Product" />
              <input
                type="hidden"
                name="m_payment_id"
                value={sessionState.user?.username ?? ""}
              />

              <input
                type="hidden"
                name="return_url"
                value="http://localhost:8100/"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="http://localhost:8100/"
              />
              <input
                type="hidden"
                name="notify_url"
                value="http://localhost:8100/"
              />

              <button>
                <IonCard typeof="submit">
                  <img alt="Silhouette of mountains" src={images.sales} />
                  <IonCardHeader>
                    <IonCardTitle className="label">R100</IonCardTitle>
                    <IonCardSubtitle className="describeText">
                      1 month Plan
                    </IonCardSubtitle>
                  </IonCardHeader>

                  <IonCardContent className="FontMobileService">
                    You will be subscribed for a month
                  </IonCardContent>
                </IonCard>
              </button>
            </form>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Subscription;
