import {
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRange,
  IonToggle,
  IonRow,
  IonIcon,
} from "@ionic/react";
import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import Header from "../../components/header";
import MessageModal from "../../components/message-modal";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router-dom";
import {
  caretBackOutline,
  caretForwardOutline,
  chevronBack,
  chevronBackCircleSharp,
  chevronBackOutline,
  chevronDown,
  chevronDownCircleSharp,
  chevronForward,
} from "ionicons/icons";
import { setCustomReportParams, setScreen } from "../../redux/ui/uiSlice";
import { useMediaQuery } from "react-responsive";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import { SelectedVehicle } from "../../interfaces";
import { Attributes } from "../../interfaces/modelSpecification";
import DesktopChartLegend from "../../components/desktop-chart-legend";
import { CompetitorData } from "../../interfaces/competitorData";
import { format } from "../../utils";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { partsInfo } from "../../interfaces/maintenancePlanItems";
import { MaintenancePlan } from "../../interfaces/maintenancePlanTotals";
import DataAssurance from "../../components/dataAssurance";
import { icons, images } from "../../assets";
import Whatsapp from "../../components/whatsapp/whatsapp";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

type PriceInfoItem = {
  iteration: any;
  partName: string;
  Name: string;
  nextPrice: number;
  interval: any;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 45,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const desktopChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  spanGaps: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const Desktopoptions = {
  // responsive: false,
  // maintainAspectRatio: false,
  // aspectRatio: 0,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const DesktopChartOptions = {
  // responsive: true,
  // maintainAspectRatio: true,
  spanGaps: true,
  // aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const Maintenance: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const [kmFromSlider, setKmFromSlider] = useState(0);
  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
      ?.maintenancePlans &&
      uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
        ?.maintenancePlans?.length > 0
      ? uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
          ?.maintenancePlans[
          uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
            ?.maintenancePlans.length - 1
        ].interval
      : 0
  );

  const [kmFromSlide, setKmFromSlide] = useState(
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result
      ?.maintenanceInfo &&
      uiState.selectedVehicle?.data?.maintenancePlanItem?.result
        ?.maintenanceInfo?.length > 0
      ? uiState.selectedVehicle?.data?.maintenancePlanItem?.result
          ?.maintenanceInfo[0].attributes?.interval
      : 0
  );
  const [kmToSlide, setKmToSlide] = useState(
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result
      ?.maintenanceInfo &&
      uiState.selectedVehicle?.data?.maintenancePlanItem?.result
        ?.maintenanceInfo?.length > 0
      ? uiState.selectedVehicle?.data?.maintenancePlanItem?.result
          ?.maintenanceInfo[
          uiState.selectedVehicle?.data?.maintenancePlanItem?.result
            ?.maintenanceInfo.length - 1
        ].attributes?.interval
      : 0
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [tabVisible, setTabVisible] = useState("TABLE");
  const [initialised, setInitialised] = useState(false);
  const [showServiceItem, setShowServiceItem] = useState(false);
  const [Inflation, setInflationValue] = useState<number>(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onModalPress = useCallback(() => {
    setModalVisible(false);
  }, []);

  // const onMorePress = useCallback(() => {
  //   setModalVisible(true);
  // }, []);
  const servicePlanMonths =
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.maintenance_plan_months ?? "0";
  const ServicePlanKm =
    Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.maintenance_plan ?? "0"
    ) || 0;

  const adjustedServicePlanKm = ServicePlanKm === 999999 ? 0 : ServicePlanKm;
  const servicePlanYears = Number(servicePlanMonths) / 12;

  const [TotalDistanceTravelKm, setTravelKm] = useState(0);
  const [rangeValue, setRangeValue] = useState(25000);

  const handleRangeChange = (event: any) => {
    const newRangeValue = parseInt(event.target.value, 10);
    setRangeValue(newRangeValue);

    const servicePlanMonths =
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan_months;
    const ServicePlanKm = Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    );

    // const remainingServiceKm = Number(servicePlanMonths) / 12;
    const servicePlanYears = Number(servicePlanMonths) / 12;
    const distanceTravelled = servicePlanYears * rangeValue;

    //The Km travelled in a year

    setTravelKm(distanceTravelled);
  };

  useEffect(() => {
    const distanceTravelled = servicePlanYears * rangeValue;
    setTravelKm(distanceTravelled);
  }, [servicePlanYears, rangeValue]);

  const onBackPress = useCallback(() => {
    // dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const getIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 50; i++) {
      const fromValue = i * 5000;
      if (fromValue <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(fromValue);
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getChartIntervals = useCallback(() => {
    const list: Array<string> = [];
    const intervalList = getIntervals();

    intervalList.forEach((interval, index) => {
      let label;

      if (index < intervalList.length - 1) {
        // If it's not the last interval, construct a range label
        label = `${intervalList[index]}`;
      } else {
        // If it's the last interval, construct a single value label
        label = `${intervalList[index]}`;
      }

      list.push(label);
    });

    // Convert kmToSlider to string and include it in the labels
    const kmToSliderString = `${kmToSlider}`;
    if (!list.includes(kmToSliderString)) {
      list.push(kmToSliderString);
    }

    return list;
  }, [getIntervals, kmToSlider]);

  const underMaintenance = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return "MP";
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return "MP";
        } else {
          return "";
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.maintenanceadditionalkm) ??
            0) >=
          interval * 5000
        ) {
          return "MP";
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.maintenance_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.maintenance_plan ?? "0"
          ) >=
            interval * 5000
        ) {
          return "MP";
        } else {
          return "";
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getDataset = useCallback(
    (col: number) => {
      const data: Array<number> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(
          col
        )?.maintenancePlanTotals?.result?.maintenancePlans?.filter(
          (item) => item.interval === interval
        );
        if (plans && plans.length > 0) {
          data.push(underMaintenance(col, index) === "" ? plans[0].total : 0);
        } else {
          data.push(0);
        }
      });
      return data;
    },
    [getData, underMaintenance, getIntervals]
  );

  const getDesktopChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
        },
      ],
    };
    return desktopChartData;
  }, [getDataset, getData, getChartIntervals]);

  const getAccumulativeDataset = useCallback(
    (col: number) => {
      const data: Array<number> = [];
      let total = 0;
      getIntervals().map((interval, index) => {
        const plans = getData(
          col
        )?.maintenancePlanTotals?.result?.maintenancePlans?.filter(
          (item) => item.interval === interval
        );
        if (plans && plans.length > 0) {
          total =
            total + (underMaintenance(col, index) === "" ? plans[0].total : 0);
        }
        data.push(total);
      });
      return data;
    },
    [getData, getIntervals, underMaintenance]
  );

  //Make this one similar to the on e above
  const getChartDta = useCallback(() => {
    const labels: Array<String> = [];
    const brakes: Array<Number> = [];
    const wipers: Array<Number> = [];
    const shocks: Array<Number> = [];
    const clutch: Array<Number> = [];
    const total: Array<Number> = [];
    uiState.selectedVehicle?.data?.maintenancePlanTotals?.result?.maintenancePlans?.map(
      (maintenancePlan) => {
        if (
          maintenancePlan.interval >= kmFromSlider &&
          maintenancePlan.interval <= kmToSlider
        ) {
          labels.push(maintenancePlan.interval.toString());

          const selectedVehicleData =
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.maintenance_plan;

          const isServiceDataValid =
            (showServiceData &&
              Number(selectedVehicleData ?? "0") >= maintenancePlan.interval) ||
            (showServiceData &&
              TotalDistanceTravelKm >= maintenancePlan.interval);

          let brakesValue: number | 0;
          let wipersValue: number | 0;
          let shocksValue: number | 0;
          let clutchValue: number | 0;

          console.log("The total wipers", maintenancePlan.wipers);

          if (
            selectedVehicleData === "999999" &&
            TotalDistanceTravelKm <= maintenancePlan.interval
          ) {
            brakesValue = Number(maintenancePlan.brakes);
            wipersValue = Number(maintenancePlan.wipers);
            shocksValue = Number(maintenancePlan.shocks);
            clutchValue = Number(maintenancePlan.clutch);
          } else if (isServiceDataValid) {
            brakesValue = 0;
            wipersValue = 0;
            shocksValue = 0;
            clutchValue = 0;
          } else {
            brakesValue = Number(maintenancePlan.brakes);
            wipersValue = Number(maintenancePlan.wipers);
            shocksValue = Number(maintenancePlan.shocks);
            clutchValue = Number(maintenancePlan.clutch);
          }

          brakes.push(brakesValue);
          wipers.push(wipersValue);
          shocks.push(shocksValue);
          clutch.push(clutchValue);
          // total.push(maintenancePlan.total ?? 0);
        }
        return "";
      }
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Brakes",
          backgroundColor: "rgb(106, 178, 44)",
          borderWidth: 0,
          data: brakes,
        },
        {
          label: "Wipers",
          backgroundColor: "rgb(17, 38, 150)",
          borderWidth: 0,
          data: wipers,
        },
        {
          label: "Shocks",
          backgroundColor: "rgb(158, 116, 4)",
          borderWidth: 0,
          data: shocks,
        },
        {
          label: "Clutch",
          backgroundColor: "rgb(52, 61, 235)",
          borderWidth: 0,
          data: clutch,
        },
        // {
        //   label: "Total",
        //   backgroundColor: "rgb(213, 0, 27)",
        //   borderWidth: 0,
        //   data: total,
        // },
      ],
    };
    return data;
  }, [
    uiState.selectedVehicle?.data?.maintenancePlanTotals,
    kmFromSlider,
    kmToSlider,
    TotalDistanceTravelKm,
  ]);

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: "",
    };
    return vehicle;
  };

  const getTotal = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        const intervalPlans =
          uiState.selectedVehicle?.data?.maintenancePlanTotals?.result.maintenancePlans.filter(
            (plan) => plan.interval === interval
          );

        if (
          intervalPlans &&
          intervalPlans?.length > 0 &&
          underMaintenance(colIndex, interval / 5000) === ""
        ) {
          return intervalPlans[0].total.toFixed(2);
        } else {
          return "0.00";
        }
      } else {
        if (uiState.competitors && uiState.competitors?.length > colIndex - 1) {
          const intervalPlans = uiState.competitors[
            colIndex - 1
          ].maintenancePlanTotals?.result.maintenancePlans.filter(
            (plan) => plan.interval === interval
          );

          if (
            intervalPlans &&
            intervalPlans?.length > 0 &&
            underMaintenance(colIndex, interval / 5000) === ""
          ) {
            return intervalPlans[0].total.toFixed(2);
          } else {
            return "0.00";
          }
        } else {
          return "0.00";
        }
      }
    },
    [
      uiState.selectedVehicle?.data?.maintenancePlanTotals,
      uiState.competitors,
      underMaintenance,
    ]
  );

  const getMarketAvg = useCallback(
    (col0: string, col1: string, col2: string, col3: string) => {
      let total = 0;
      let divider = 0;

      if (col0 !== "") {
        total += Number(col0);
        divider++;
      }

      if (col1 !== "") {
        total += Number(col1);
        divider++;
      }

      if (col2 !== "") {
        total += Number(col2);
        divider++;
      }

      if (col3 !== "") {
        total += Number(col3);
        divider++;
      }

      if (total !== 0) {
        return (total / divider).toFixed(2);
      } else {
      }
    },
    []
  );

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const dataSourceFile = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].dataSource;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const getMarketPos = useCallback(
    (col0: string, col1: string, col2: string, col3: string) => {
      let total = 0;

      if (col0 !== "") {
        total += Number(col0);
      }

      if (col1 !== "") {
        total += Number(col1);
      }

      if (col2 !== "") {
        total += Number(col2);
      }

      if (col3 !== "") {
        total += Number(col3);
      }

      const selectedTotal =
        uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
          .maintenancePlans[0].total ?? 0;
      const position = (selectedTotal / total) * 100;

      if (total !== 0 && selectedTotal !== 0) {
        if (position > 0) {
          return (
            <td width={130} className="analysisTable bestValue">
              {position.toFixed(2)}%
            </td>
          );
        } else {
          return (
            <td width={130} className="analysisTable">
              {position.toFixed(2)}%
            </td>
          );
        }
      } else {
        return <td></td>;
      }
    },
    [uiState.selectedVehicle?.data?.maintenancePlanTotals]
  );

  const getGrandTotals = useCallback(
    (col: number) => {
      let total0 = 0;
      let total2 = 0;
      let total3 = 0;
      let total4 = 0;
      let total5 = 0;
      getIntervals().map((i, index) => {
        total0 += Number(
          getMarketAvg(
            getTotal(0, i),
            getTotal(1, i),
            getTotal(2, i),
            getTotal(3, i)
          ) ?? 0
        );

        total2 += Number(getTotal(0, i));

        total3 += Number(getTotal(1, i));

        total4 += Number(getTotal(2, i));

        total5 += Number(getTotal(3, i));
      });

      if (col === 0) {
        return total0;
      } else if (col === 2) {
        return total2;
      } else if (col === 3) {
        return total3;
      } else if (col === 4) {
        return total4;
      } else if (col === 5) {
        return total5;
      }
    },
    [getTotal, getIntervals, getMarketAvg]
  );

  const getGrandTotalMobile = useCallback(
    (index: number) => {
      let total = 0;
      uiState.selectedVehicle?.data?.maintenancePlanTotals?.result?.maintenancePlans?.map(
        (maintenancePlan) => {
          if (
            maintenancePlan.interval >= kmFromSlider &&
            maintenancePlan.interval <= kmToSlider &&
            TotalDistanceTravelKm <= maintenancePlan.interval
          ) {
            total +=
              underMaintenance(index, maintenancePlan.interval) === ""
                ? maintenancePlan.total
                : 0;
          }

          return "";
        }
      );

      return total;
    },
    [
      getData,
      underMaintenance,
      uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
        ?.maintenancePlans,
      kmFromSlider,
      kmToSlider,
      TotalDistanceTravelKm,
    ]
  );

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
    }
  }, [initialised]);

  const onLogOut = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      dispatch(setScreen(AppScreens.Login));
    }, 300);
  }, [dispatch]);

  const LabourRates = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].hourlyRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const SundriesRate = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].sundriesRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  //logic for filtering
  const [allPlans, setAllPlans] = useState<Array<MaintenancePlan>>();
  const [allPlanInfo, setPlanInfo] = useState<Array<partsInfo>>();
  const [showServiceData, setShowServiceData] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(null);

  const handleIntervalClick = (interval: any) => {
    setSelectedInterval(selectedInterval === interval ? null : interval);
  };
  useEffect(() => {
    setAllPlans(
      uiState.selectedVehicle?.data?.maintenancePlanTotals?.result
        ?.maintenancePlans
    );
    setPlanInfo(
      uiState.selectedVehicle?.data?.maintenancePlanItem?.result
        ?.maintenanceInfo
    );
  }, [uiState]);

  //To calculate the months next to intervals

  const calculateMonth = (interval: any) => {
    const distancePerYear = rangeValue;

    const months = Math.floor(interval / distancePerYear) * 12;

    return months;
  };

  // Calculate the years
  function calculateYearsLabel(interval: any) {
    const months = calculateMonth(interval);

    return months;
  }

  //calculating the Inflation rate

  const intervalHolder: Array<number> = [];

  uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo.forEach(
    (v, i) => {
      intervalHolder.push(v.attributes["interval"]);
    }
  );

  const [priceInfo, setPriceInfo] = useState<PriceInfoItem[][]>([]);

  //console.log("The maintaince parts price", priceInfo);

  const initialPartsArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;
            const PartSubName = part.basketSubName;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(priceInfo)) {
        setPriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialPartsArray]);

  const [LabourInfo, setLabourInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The labour rate",LabourInfo)

  const initialLabourArray =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              const PartSubName = part.basketSubName;
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  Name: PartSubName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(LabourInfo)) {
        setLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  const filteredPlanInfo =
    uiState.selectedVehicle?.data?.maintenancePlanItem?.result?.maintenanceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo
          ?.map((part: any) => {
            const matchingPriceInfo = priceInfo.flat().filter((priceItem) => {
              return (
                priceItem.partName === part?.partNumber &&
                priceItem.interval === planItem.attributes?.interval
              );
            });

            return matchingPriceInfo.map((item) => ({
              nextPrice: item.nextPrice,
              Name: item.Name, // Adding Name next to the nextPrice
            }));
          })
          .flat();
        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price.nextPrice, // Change here to access nextPrice
          0
        );

        //For the labour

        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = LabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          totalPartPrices: totalPartPrices,
          totalLabourPrices: totalLabourPrices,
        };
      }
    );

  //console.log("The maintenance ready for table ", filteredPlanInfo);

  const intervalsInfo: Array<MaintenancePlan> = [];
  const [intervalValues, setIntervalValues] = useState<MaintenancePlan>();

  const filterByInterval = (plan: MaintenancePlan) => {
    allPlans?.forEach((val, index) => {
      if (val.interval === plan.interval) {
        setIntervalValues(plan);
      }

      return intervalValues;
    });

    setShowServiceItem(!showServiceItem);
  };

  const [showPlan, setShowPlan] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showBarDetail, setShowBarDetail] = useState(false);
  const [showLineDetail, setShowLineDetail] = useState(false);
  const [kilos, setKilos] = useState(Number);
  const [months, setMonths] = useState(Number);
  const [serviceType, setServiceTypes] = useState<string>("");
  const [activeText, setActiveText] = useState("");

  useEffect(() => {
    setKilos(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.maintenance_plan
      )
    );

    setMonths(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.maintenance_plan_months
      )
    );

    setServiceTypes(
      JSON.stringify(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan_type
      ).replace(/"/g, "")
    );
  }, [uiState.selectedVehicle, kilos, months, serviceType]);

  //For the accumulative chart
  const getAccumulativeDatset = useCallback(
    (col: number) => {
      const data: number[] = [];
      const intervals = new Set(
        filteredPlanInfo
          ?.filter(
            (servicePlan) =>
              servicePlan?.attributes?.interval >= kmFromSlider &&
              servicePlan?.attributes?.interval <= kmToSlider
          )
          .map((servicePlan) => servicePlan?.attributes?.interval)
          .filter((interval) => interval)
      );

      let cumulativeTotal = 0;

      intervals.forEach((interval) => {
        let total = 0;
        filteredPlanInfo?.forEach((servicePlan) => {
          if (servicePlan?.attributes?.interval === interval) {
            const totalPartPrices = servicePlan.totalPartPrices || 0;
            const totalLabourPrices = servicePlan.totalLabourPrices || 0;

            const laborQuantity =
              servicePlan.labourInfo[0]?.labourQuantity || 0;
            const totalLaborPrice = totalLabourPrices * laborQuantity;

            //Adding the total of all the values
            const totalServicePlanPrice = totalPartPrices + totalLaborPrice;

            let addToTotal = totalServicePlanPrice;

            if (showServiceData) {
              if (
                Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.maintenance_plan ?? "0"
                ) >= servicePlan?.attributes.interval &&
                Number(
                  (Number(servicePlan?.attributes.interval) / rangeValue) * 12
                ) <=
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan_months
                  )
              ) {
                addToTotal = 0;
              }
            }

            total += addToTotal;
          }
        });
        cumulativeTotal += total;

        data.push(Number(cumulativeTotal));
      });

      return data;
    },
    [
      filteredPlanInfo,
      uiState.selectedVehicle?.data?.servicePlanTotals,
      showServiceData,
      TotalDistanceTravelKm,
    ]
  );

  const getDesktopAccumaltiveChartData = useCallback(() => {
    const labels: string[] = [];

    filteredPlanInfo?.forEach((servicePlan) => {
      if (
        servicePlan?.attributes?.interval >= kmFromSlider &&
        servicePlan?.attributes?.interval <= kmToSlider
      ) {
        labels.push(servicePlan?.attributes?.interval.toString());
      }
    });

    const desktopChartData = {
      labels,
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getAccumulativeDatset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };

    return desktopChartData;
  }, [
    getAccumulativeDatset,
    getData,
    kmFromSlider,
    filteredPlanInfo,
    kmToSlider,
    showServiceData,
    filteredPlanInfo,
  ]);

  const getGrandTotalMob = useCallback(
    (index: number) => {
      let total = 0;
      if (filteredPlanInfo) {
        filteredPlanInfo.forEach((maintenancePlan) => {
          if (
            maintenancePlan?.attributes?.interval >= kmFromSlider &&
            maintenancePlan?.attributes?.interval <= kmToSlider
          ) {
            total += showServiceData
              ? Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.maintenance_plan ?? "0"
                ) >= maintenancePlan?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.maintenance_plan ?? "0"
                  ) === 999999
                  ? maintenancePlan.totalPartPrices +
                    maintenancePlan.totalLabourPrices *
                      maintenancePlan.labourInfo[0]?.labourQuantity
                  : Number(
                      (Number(maintenancePlan?.attributes.interval) /
                        rangeValue) *
                        12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.service_plan_months
                    )
                  ? maintenancePlan.totalPartPrices +
                    maintenancePlan.totalLabourPrices *
                      maintenancePlan.labourInfo[0]?.labourQuantity
                  : 0
                : maintenancePlan.totalPartPrices +
                  maintenancePlan.totalLabourPrices *
                    maintenancePlan.labourInfo[0]?.labourQuantity
              : maintenancePlan.totalPartPrices +
                maintenancePlan.totalLabourPrices *
                  maintenancePlan.labourInfo[0]?.labourQuantity;
          }

          return "";
        });
      }
      return total;
    },
    [filteredPlanInfo, kmFromSlider, kmToSlider, TotalDistanceTravelKm]
  );

  const getChartData = useCallback(() => {
    const labels: Array<String> = [];
    const brakes: Array<Number> = [];
    const wipers: Array<Number> = [];
    const shocks: Array<Number> = [];
    const clutch: Array<Number> = [];
    const total: Array<Number> = [];
    filteredPlanInfo?.forEach((maintenancePlan: any) => {
      if (
        maintenancePlan?.attributes?.interval >= kmFromSlider &&
        maintenancePlan?.attributes?.interval <= kmToSlider
      ) {
        labels.push(maintenancePlan?.attributes?.interval.toString());

        const selectedVehicleData =
          uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.maintenance_plan;

        const isServiceDataValid =
          showServiceData &&
          Number(selectedVehicleData ?? "0") >=
            maintenancePlan?.attributes?.interval &&
          Number(
            (Number(maintenancePlan?.attributes.interval) / rangeValue) * 12
          ) <=
            Number(
              uiState.selectedVehicle?.data?.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan_months
            );

        let brakesValue: number | 0;
        let wipersValue: number | 0;
        let shocksValue: number | 0;
        let clutchValue: number | 0;

        const totalNextPriceForWipers = maintenancePlan.nextPrices.reduce(
          (total: any, part: any) => {
            if (part.Name === "WIPERS") {
              return total + part.nextPrice;
            }
            return total;
          },
          null
        );

        const totalNextPriceForBrakes = maintenancePlan.nextPrices.reduce(
          (total: any, part: any) => {
            if (part.Name === "DISCS" || part.Name === "DRUMS") {
              return total + part.nextPrice;
            }
            return total;
          },
          null
        );

        const totalNextPriceForShocks = maintenancePlan.nextPrices.reduce(
          (total: any, part: any) => {
            if (part.Name === "ABSORBER SHOCKS") {
              return total + part.nextPrice;
            }
            return total;
          },
          null
        );

        const totalNextPriceForClutch = maintenancePlan.nextPrices.reduce(
          (total: any, part: any) => {
            if (part.Name === "CLUTCH / TORQUE CONVERTER") {
              return total + part.nextPrice;
            }
            return total;
          },
          null
        );

        if (
          selectedVehicleData === "999999" &&
          Number(
            (Number(maintenancePlan?.attributes.interval) / rangeValue) * 12
          ) >
            Number(
              uiState.selectedVehicle?.data?.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan_months
            )
        ) {
          brakesValue = Number(totalNextPriceForBrakes);
          wipersValue = Number(totalNextPriceForWipers);
          shocksValue = Number(totalNextPriceForShocks);
          clutchValue = Number(totalNextPriceForClutch);
        } else if (isServiceDataValid) {
          brakesValue = 0;
          wipersValue = 0;
          shocksValue = 0;
          clutchValue = 0;
        } else {
          brakesValue = Number(totalNextPriceForBrakes);
          wipersValue = Number(totalNextPriceForWipers);
          shocksValue = Number(totalNextPriceForShocks);
          clutchValue = Number(totalNextPriceForClutch);
        }

        brakes.push(brakesValue);
        wipers.push(wipersValue);
        shocks.push(shocksValue);
        clutch.push(clutchValue);
        // total.push(maintenancePlan.total ?? 0);
      }
      return "";
    });
    const data = {
      labels,
      datasets: [
        {
          label: "Brakes",
          backgroundColor: "rgb(106, 178, 44)",
          borderWidth: 0,
          data: brakes,
        },
        {
          label: "Wipers",
          backgroundColor: "rgb(17, 38, 150)",
          borderWidth: 0,
          data: wipers,
        },
        {
          label: "Shocks",
          backgroundColor: "rgb(52, 61, 235)",
          borderWidth: 0,
          data: shocks,
        },
        {
          label: "Clutch",
          backgroundColor: "rgb(158, 116, 4)",
          borderWidth: 0,
          data: clutch,
        },
        // {
        //   label: "Total",
        //   backgroundColor: "rgb(213, 0, 27)",
        //   borderWidth: 0,
        //   data: total,
        // },
      ],
    };
    return data;
  }, [
    uiState.selectedVehicle?.data?.maintenancePlanTotals,
    kmFromSlider,
    kmToSlider,
    filteredPlanInfo,
    TotalDistanceTravelKm,
  ]);

  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  let touchStartX = 0;

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCost = showWithSlider
    ? getGrandTotalMob(0)
    : getGrandTotalMob(0) / Number(kmToSlider);

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );
  const totalServiceCosPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  //Timeout to update th e
  const [range, setRange] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setRange({ lower: 10, upper: 20 });
      }, 10);

      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const handleInflationChange = (e: any) => {
    const Value = e.detail.value;
    setInflationValue(Value);
  };

  const [calcDuration, setCalcDuration] = useState(0);

  useEffect(() => {
    setCalcDuration(Number(kmToSlider - kmFromSlider));
  }, [kmFromSlider, kmToSlider]);

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team. I have a question on the Maintenance Cost screen of the  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      }  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} *My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <>
      {sessionState.user?.username === "" ? (
        <IonContent className="contentPage">
          <div className="innerContent">
            <SubPageHeading
              onBack={onBackPress}
              vehicle={uiState.selectedVehicle}
            ></SubPageHeading>
            <p className="Subscription" onClick={onLogOut}>
              Create Account or Log in
            </p>
          </div>
        </IonContent>
      ) : (
        <GlobalGrid
          currentPageTitle="MAINTENANCE COST"
          closeCurrentPage={() => onBackPress()}
        >
          <div className="detailContents">
            {sessionState.user?.username === "" ? (
              <IonContent className="contentPage">
                <div className="innerContent">
                  <SubPageHeading
                    onBack={onBackPress}
                    vehicle={uiState.selectedVehicle}
                  ></SubPageHeading>
                  <p className="Subscription" onClick={onLogOut}>
                    Create Account or Log in
                  </p>
                </div>
              </IonContent>
            ) : (
              <>
                <IonRow style={{ width: "100%" }}>
                  <SubPageHeading
                    onBack={onBackPress}
                    vehicle={uiState.selectedVehicle}
                  ></SubPageHeading>
                </IonRow>
                <div className="genericHolder">
                  <IonRow className="planDropDown">
                    <span className="dropDownIcon">
                      <IonIcon
                        icon={showPlan == false ? chevronForward : chevronDown}
                        onClick={() => {
                          setShowPlan(!showPlan);
                          handleButtonClick();
                        }}
                      ></IonIcon>
                    </span>
                    <span className="textDropDown">LIFE SPAN SETTINGS</span>
                    <span className="activeState">
                      {showServiceData ? "ACTIVATED" : "DEACTIVATED"}
                    </span>
                    <div className="toggleState">
                      <IonToggle
                        checked={showServiceData}
                        className="ServiceCostToggleBtn"
                        onIonChange={(e) =>
                          setShowServiceData(e.detail.checked)
                        }
                      />
                    </div>
                  </IonRow>
                  {showPlan && (
                    <section className="sliderContainer">
                      <span id="sliderContainerACtual">
                        <div className="digi">
                          <span>
                            FROM:
                            <h1>{kmFromSlider}</h1>
                          </span>
                          <span id="digiRight">
                            TO:
                            <h1>{kmToSlider}</h1>
                          </span>
                        </div>

                        <IonRange
                          id="slider"
                          aria-label="Dual Knobs Range"
                          dualKnobs={true}
                          min={
                            // uiState.selectedVehicle?.data?.servicePlanTotals
                            //   ?.result?.servicePlans &&
                            // uiState.selectedVehicle?.data?.servicePlanTotals
                            //   ?.result?.servicePlans?.length > 0
                            //   ? uiState.selectedVehicle?.data
                            //       ?.servicePlanTotals?.result?.servicePlans[0]
                            //       .interval
                            //   : 0
                            0
                          }
                          max={
                            uiState.selectedVehicle?.data?.maintenancePlanTotals
                              ?.result?.maintenancePlans &&
                            uiState.selectedVehicle?.data?.maintenancePlanTotals
                              ?.result?.maintenancePlans?.length > 0
                              ? uiState.selectedVehicle?.data
                                  ?.maintenancePlanTotals?.result
                                  ?.maintenancePlans[
                                  uiState.selectedVehicle?.data
                                    ?.maintenancePlanTotals?.result
                                    ?.maintenancePlans.length - 1
                                ].interval
                              : 0
                          }
                          step={1000}
                          onIonChange={(ev: any) => {
                            const lowerValue = isNaN(ev.detail.value.lower)
                              ? 0
                              : ev.detail.value.lower;
                            const upperValue = isNaN(ev.detail.value.upper)
                              ? 0
                              : ev.detail.value.upper;
                            setKmToSlider(upperValue);
                            setKmFromSlider(lowerValue);
                          }}
                          value={{
                            upper: kmToSlider,
                            lower: kmFromSlider,
                          }}
                        ></IonRange>
                        <div className="digi">
                          <span>TRAVELLING:</span>
                          <span id="digiRight">
                            <h1>{rangeValue}</h1>
                            /YR
                          </span>
                        </div>

                        <IonRange
                          id="slider"
                          step={500}
                          min={2500}
                          max={100000}
                          value={rangeValue}
                          onIonChange={handleRangeChange}
                        ></IonRange>
                        <div className="digi">
                          <span>ANNUAL PRICE INCREASE</span>
                          <span id="digiRight">
                            <h1>{Inflation}</h1>%
                          </span>
                        </div>

                        <IonRange
                          id="slider"
                          step={0.25}
                          min={0}
                          max={30}
                          value={Inflation}
                          onIonChange={handleInflationChange}
                        ></IonRange>
                      </span>
                    </section>
                  )}
                  <IonRow className="hiddenPlanDetails">
                    <div>
                      <span id="actualPlans" className="ActualPlanDetails">
                        The report below covers a lifespan of{" "}
                        <span style={{ color: "red" }}>{calcDuration}</span>{" "}
                        kilometres or{" "}
                        <span style={{ color: "red" }}>
                          {Number(
                            (Number(calcDuration) / rangeValue) * 12
                          ).toFixed(0)}
                        </span>{" "}
                        months. The model adheres to a{" "}
                        <span style={{ color: "red" }}>
                          <span style={{ textTransform: "lowercase" }}>
                            {
                              uiState.selectedVehicle?.data?.modelSpecification
                                .result.service_maintenance_warranty
                                .service_plan_type
                            }
                          </span>{" "}
                          service schedule
                        </span>{" "}
                        , and includes a servicing plan covering costs up to
                        <span
                          style={{ textTransform: "lowercase", color: "red" }}
                        >
                          {" "}
                          {format(
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty
                              .maintenance_plan,
                            Format.Number
                          )}
                        </span>{" "}
                        kms or
                        <span
                          style={{ textTransform: "lowercase", color: "red" }}
                        >
                          {" "}
                          {
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty
                              .maintenance_plan_months
                          }
                        </span>{" "}
                        mnths. The cost calculation below has the mentioned
                        service plan{" "}
                        <span style={{ color: "red" }}>
                          {showServiceData ? "activated" : "deactivated"}
                        </span>{" "}
                        and includes an annual price increase of
                        <span style={{ color: "red" }}> {Inflation}%</span>
                      </span>
                    </div>
                  </IonRow>
                </div>

                <div className="scrollable">
                  <section className="costsContainers">
                    {/*TABLE STARTS HERE  */}
                    <div className="coststTables" id="tableSection">
                      <IonGrid>
                        {/* On mobile, display components in a row */}
                        <IonRow>
                          <IonCol
                            size="11.5"
                            sizeXs="11.5"
                            sizeMd="11.5"
                            sizeLg="12"
                            sizeXl="3.8"
                          >
                            <IonGrid className="sp-table">
                              {!showServiceItem && (
                                <IonRow>
                                  <IonCol size="0.1"></IonCol>
                                  <IonCol size="2.2" className="gridhead">
                                    <span className="cell"> INTERVAL </span>
                                  </IonCol>
                                  <IonCol size="2" className="gridhead">
                                    <span className="cell"> Brakes </span>
                                  </IonCol>
                                  <IonCol size="1.8" className="gridhead">
                                    <span className="cell"> Wipers </span>
                                  </IonCol>
                                  <IonCol size="2" className="gridhead">
                                    <span className="cell"> Shocks </span>
                                  </IonCol>
                                  <IonCol size="1.9" className="gridhead">
                                    <span className="cell"> Clutch </span>
                                  </IonCol>
                                  <IonCol size="2" className="gridhead">
                                    <span className="cell"> Total </span>
                                  </IonCol>
                                </IonRow>
                              )}
                              <>
                                {filteredPlanInfo?.map(
                                  (maintenancePlan, index) => {
                                    const totalNextPriceForWipers =
                                      maintenancePlan.nextPrices.reduce(
                                        (total: any, part: any) => {
                                          if (part.Name === "WIPERS") {
                                            return total + part.nextPrice;
                                          }
                                          return total;
                                        },
                                        0
                                      );

                                    const totalNextPriceForBrakes =
                                      maintenancePlan.nextPrices.reduce(
                                        (total: any, part: any) => {
                                          if (
                                            part.Name === "DISCS" ||
                                            part.Name === "DRUMS"
                                          ) {
                                            return total + part.nextPrice;
                                          }
                                          return total;
                                        },
                                        0
                                      );

                                    const totalNextPriceForShocks =
                                      maintenancePlan.nextPrices.reduce(
                                        (total: any, part: any) => {
                                          if (part.Name === "ABSORBER SHOCKS") {
                                            return total + part.nextPrice;
                                          }
                                          return total;
                                        },
                                        0
                                      );

                                    const totalNextPriceForClutch =
                                      maintenancePlan.nextPrices.reduce(
                                        (total: any, part: any) => {
                                          if (
                                            part.Name ===
                                            "CLUTCH / TORQUE CONVERTER"
                                          ) {
                                            return total + part.nextPrice;
                                          }
                                          return total;
                                        },
                                        0
                                      );
                                    return (
                                      <React.Fragment key={"row" + index}>
                                        {maintenancePlan?.attributes.interval >=
                                          kmFromSlider &&
                                        maintenancePlan?.attributes.interval <=
                                          kmToSlider ? (
                                          <>
                                            {selectedInterval !==
                                              maintenancePlan?.attributes
                                                .interval && (
                                              <IonRow>
                                                <IonCol size="0.1"></IonCol>

                                                <IonCol
                                                  size="2.2"
                                                  className="gridfirst"
                                                  onClick={() => {
                                                    handleIntervalClick(
                                                      maintenancePlan
                                                        ?.attributes.interval
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    className="FontMobileService"
                                                    id="intervalValue"
                                                  >
                                                    {/* {maintenancePlan.interval}{" "} */}
                                                    {selectedInterval !==
                                                      maintenancePlan
                                                        ?.attributes.interval &&
                                                      //The selectedInterval to animate smooth and nicely
                                                      maintenancePlan
                                                        ?.attributes.interval}
                                                  </span>
                                                </IonCol>
                                                <IonCol
                                                  size="2"
                                                  className="grid"
                                                >
                                                  <span className="FontMobileService">
                                                    {showServiceData
                                                      ? Number(
                                                          uiState
                                                            .selectedVehicle
                                                            ?.data
                                                            ?.modelSpecification
                                                            .result
                                                            .service_maintenance_warranty
                                                            .maintenance_plan ??
                                                            "0"
                                                        ) >=
                                                        maintenancePlan
                                                          ?.attributes.interval
                                                        ? 0 ||
                                                          Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .maintenance_plan ??
                                                              "0"
                                                          ) === 999999
                                                          ? format(
                                                              totalNextPriceForBrakes,
                                                              Format.Currency
                                                            )
                                                          : Number(
                                                              (Number(
                                                                maintenancePlan
                                                                  ?.attributes
                                                                  .interval
                                                              ) /
                                                                rangeValue) *
                                                                12
                                                            ) >
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .maintenance_plan_months
                                                            )
                                                          ? format(
                                                              totalNextPriceForBrakes,
                                                              Format.Currency
                                                            )
                                                          : 0
                                                        : format(
                                                            totalNextPriceForBrakes,
                                                            Format.Currency
                                                          )
                                                      : format(
                                                          totalNextPriceForBrakes,
                                                          Format.Currency
                                                        )}
                                                  </span>
                                                </IonCol>
                                                <IonCol
                                                  size="1.8"
                                                  className="grid"
                                                >
                                                  <span className="FontMobileService">
                                                    {showServiceData
                                                      ? Number(
                                                          uiState
                                                            .selectedVehicle
                                                            ?.data
                                                            ?.modelSpecification
                                                            .result
                                                            .service_maintenance_warranty
                                                            .maintenance_plan ??
                                                            "0"
                                                        ) >=
                                                        maintenancePlan
                                                          ?.attributes.interval
                                                        ? 0 ||
                                                          Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .maintenance_plan ??
                                                              "0"
                                                          ) === 999999
                                                          ? format(
                                                              totalNextPriceForWipers,
                                                              Format.Currency
                                                            )
                                                          : Number(
                                                              (Number(
                                                                maintenancePlan
                                                                  ?.attributes
                                                                  .interval
                                                              ) /
                                                                rangeValue) *
                                                                12
                                                            ) >
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .maintenance_plan_months
                                                            )
                                                          ? format(
                                                              totalNextPriceForWipers,
                                                              Format.Currency
                                                            )
                                                          : 0
                                                        : format(
                                                            totalNextPriceForWipers,
                                                            Format.Currency
                                                          )
                                                      : format(
                                                          totalNextPriceForWipers,
                                                          Format.Currency
                                                        )}
                                                  </span>
                                                </IonCol>
                                                <IonCol
                                                  size="2"
                                                  className="grid"
                                                >
                                                  <span className="FontMobileService">
                                                    {showServiceData
                                                      ? Number(
                                                          uiState
                                                            .selectedVehicle
                                                            ?.data
                                                            ?.modelSpecification
                                                            .result
                                                            .service_maintenance_warranty
                                                            .maintenance_plan ??
                                                            "0"
                                                        ) >=
                                                        maintenancePlan
                                                          ?.attributes.interval
                                                        ? 0 ||
                                                          Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .maintenance_plan ??
                                                              "0"
                                                          ) === 999999
                                                          ? format(
                                                              totalNextPriceForShocks,
                                                              Format.Currency
                                                            )
                                                          : Number(
                                                              (Number(
                                                                maintenancePlan
                                                                  ?.attributes
                                                                  .interval
                                                              ) /
                                                                rangeValue) *
                                                                12
                                                            ) >
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .maintenance_plan_months
                                                            )
                                                          ? format(
                                                              totalNextPriceForShocks,
                                                              Format.Currency
                                                            )
                                                          : 0
                                                        : format(
                                                            totalNextPriceForShocks,
                                                            Format.Currency
                                                          )
                                                      : format(
                                                          totalNextPriceForShocks,
                                                          Format.Currency
                                                        )}
                                                  </span>
                                                </IonCol>
                                                <IonCol
                                                  size="1.9"
                                                  className="grid"
                                                >
                                                  <span className="FontMobileService">
                                                    {showServiceData
                                                      ? Number(
                                                          uiState
                                                            .selectedVehicle
                                                            ?.data
                                                            ?.modelSpecification
                                                            .result
                                                            .service_maintenance_warranty
                                                            .maintenance_plan ??
                                                            "0"
                                                        ) >=
                                                        maintenancePlan
                                                          ?.attributes.interval
                                                        ? 0 ||
                                                          Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .maintenance_plan ??
                                                              "0"
                                                          ) === 999999
                                                          ? format(
                                                              totalNextPriceForClutch,
                                                              Format.Currency
                                                            )
                                                          : Number(
                                                              (Number(
                                                                maintenancePlan
                                                                  ?.attributes
                                                                  .interval
                                                              ) /
                                                                rangeValue) *
                                                                12
                                                            ) >
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .maintenance_plan_months
                                                            )
                                                          ? format(
                                                              totalNextPriceForClutch,
                                                              Format.Currency
                                                            )
                                                          : 0
                                                        : format(
                                                            totalNextPriceForClutch,
                                                            Format.Currency
                                                          )
                                                      : format(
                                                          totalNextPriceForClutch,
                                                          Format.Currency
                                                        )}
                                                  </span>
                                                </IonCol>
                                                <IonCol
                                                  size="2"
                                                  className="grid"
                                                >
                                                  <span className="FontMobileService">
                                                    {showServiceData
                                                      ? Number(
                                                          uiState
                                                            .selectedVehicle
                                                            ?.data
                                                            ?.modelSpecification
                                                            .result
                                                            .service_maintenance_warranty
                                                            .maintenance_plan ??
                                                            "0"
                                                        ) >=
                                                        maintenancePlan
                                                          ?.attributes.interval
                                                        ? 0 ||
                                                          Number(
                                                            uiState
                                                              .selectedVehicle
                                                              ?.data
                                                              ?.modelSpecification
                                                              .result
                                                              .service_maintenance_warranty
                                                              .maintenance_plan ??
                                                              "0"
                                                          ) === 999999
                                                          ? format(
                                                              maintenancePlan.totalPartPrices +
                                                                maintenancePlan.totalLabourPrices *
                                                                  maintenancePlan
                                                                    .labourInfo[0]
                                                                    ?.labourQuantity,
                                                              Format.Currency
                                                            )
                                                          : Number(
                                                              (Number(
                                                                maintenancePlan
                                                                  ?.attributes
                                                                  .interval
                                                              ) /
                                                                rangeValue) *
                                                                12
                                                            ) >
                                                            Number(
                                                              uiState
                                                                .selectedVehicle
                                                                ?.data
                                                                ?.modelSpecification
                                                                .result
                                                                .service_maintenance_warranty
                                                                .maintenance_plan_months
                                                            )
                                                          ? format(
                                                              maintenancePlan.totalPartPrices +
                                                                maintenancePlan.totalLabourPrices *
                                                                  maintenancePlan
                                                                    .labourInfo[0]
                                                                    ?.labourQuantity,
                                                              Format.Currency
                                                            )
                                                          : 0
                                                        : format(
                                                            maintenancePlan.totalPartPrices +
                                                              maintenancePlan.totalLabourPrices *
                                                                maintenancePlan
                                                                  .labourInfo[0]
                                                                  ?.labourQuantity,
                                                            Format.Currency
                                                          )
                                                      : format(
                                                          maintenancePlan.totalPartPrices +
                                                            maintenancePlan.totalLabourPrices *
                                                              maintenancePlan
                                                                .labourInfo[0]
                                                                ?.labourQuantity,
                                                          Format.Currency
                                                        )}
                                                  </span>
                                                </IonCol>
                                              </IonRow>
                                            )}
                                            {/**HIDDEN TABLE */}

                                            {selectedInterval ===
                                            maintenancePlan.attributes
                                              .interval ? (
                                              <table id="servicePlanDetails">
                                                <thead>
                                                  <tr>
                                                    <td>INTERVAL</td>
                                                    <td>PRICE</td>
                                                    <td>QTY</td>
                                                    <td>TOTAL</td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      {" "}
                                                      <span
                                                        onClick={() => {
                                                          handleIntervalClick(
                                                            maintenancePlan
                                                              ?.attributes
                                                              .interval
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            chevronBackCircleSharp
                                                          }
                                                          alt=""
                                                          className="backHidden"
                                                        />
                                                        {
                                                          maintenancePlan
                                                            ?.attributes
                                                            .interval
                                                        }
                                                      </span>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                  <tr id="headerRow">
                                                    <td>PARTS</td>
                                                  </tr>

                                                  {maintenancePlan.partsInfo.map(
                                                    (
                                                      sp: {
                                                        price: any;
                                                        quantity: any;
                                                        basketItemName: any;
                                                        partNumber: any;
                                                      },
                                                      index: number
                                                    ) => {
                                                      const PartsnextPrice =
                                                        maintenancePlan
                                                          .nextPrices[index];

                                                      return (
                                                        <tr className="partDetailsRow">
                                                          <span id="left">
                                                            {sp.basketItemName}{" "}
                                                            {sp.partNumber}
                                                          </span>
                                                          <span
                                                            id="right
                                                  "
                                                          >
                                                            <p>
                                                              {" "}
                                                              {Number(
                                                                PartsnextPrice.nextPrice
                                                              ).toFixed(2)}
                                                            </p>
                                                            <p>{sp.quantity}</p>
                                                            <p>
                                                              {format(
                                                                PartsnextPrice.nextPrice *
                                                                  sp.quantity,
                                                                Format.Currency
                                                              )}
                                                            </p>
                                                          </span>
                                                        </tr>
                                                      );
                                                    }
                                                  )}

                                                  <tr id="headerRow">
                                                    <td>LABOUR</td>
                                                  </tr>
                                                  {maintenancePlan.labourInfo.map(
                                                    (sp: {
                                                      labourQuantity: any;
                                                    }) => {
                                                      // const FluidnextPrice =
                                                      // maintenancePlan.fluidNextPrices[
                                                      //     index
                                                      //   ];
                                                      return (
                                                        <>
                                                          <tr className="partDetailsRow">
                                                            <span id="left">
                                                              LABOUR RATE
                                                            </span>
                                                            <span
                                                              id="right
                                                  "
                                                            >
                                                              <p>
                                                                {" "}
                                                                {format(
                                                                  maintenancePlan.totalLabourPrices,
                                                                  Format.Currency
                                                                )}
                                                              </p>
                                                              <p>
                                                                {
                                                                  sp.labourQuantity
                                                                }
                                                              </p>
                                                              <p>
                                                                {" "}
                                                                {format(
                                                                  maintenancePlan
                                                                    .labourInfo[0]
                                                                    ?.labourQuantity *
                                                                    maintenancePlan.totalLabourPrices,
                                                                  Format.Currency
                                                                )}
                                                              </p>
                                                            </span>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  <tr id="headerRow">
                                                    <td>TOTAL</td>
                                                  </tr>
                                                  <tr className="partDetailsRow-vat">
                                                    <span id="left"></span>
                                                    <span
                                                      id="right
                                                  "
                                                    >
                                                      <p></p>
                                                      <p>EXC VAT</p>
                                                      <p className="text-nowrap">
                                                        {format(
                                                          maintenancePlan.totalPartPrices +
                                                            maintenancePlan.totalLabourPrices *
                                                              maintenancePlan
                                                                .labourInfo[0]
                                                                ?.labourQuantity,
                                                          Format.Currency
                                                        )}
                                                      </p>
                                                    </span>
                                                  </tr>
                                                  <tr className="partDetailsRow-vat">
                                                    <span id="left"></span>
                                                    <span
                                                      id="right
                                                  "
                                                    >
                                                      <p></p>
                                                      <p>INC VAT</p>
                                                      <p className="text-nowrap-vat">
                                                        {format(
                                                          ((maintenancePlan.totalPartPrices +
                                                            maintenancePlan.totalLabourPrices *
                                                              maintenancePlan
                                                                .labourInfo[0]
                                                                ?.labourQuantity) *
                                                            15) /
                                                            100 +
                                                            (maintenancePlan.totalPartPrices +
                                                              maintenancePlan.totalLabourPrices *
                                                                maintenancePlan
                                                                  .labourInfo[0]
                                                                  ?.labourQuantity),
                                                          Format.Currency
                                                        )}
                                                      </p>
                                                    </span>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </>
                            </IonGrid>
                          </IonCol>
                          <IonCol
                            size="11.5"
                            sizeXs="11.5"
                            sizeMd="11.5"
                            sizeLg="12"
                            sizeXl="3.8"
                          >
                            <IonRow>
                              {isDesktop ? (
                                <>
                                  <Bar
                                    options={Desktopoptions}
                                    height={300}
                                    width={500}
                                    data={getChartData()}
                                  />

                                  <Line
                                    options={DesktopChartOptions}
                                    height={300}
                                    width={500}
                                    data={getDesktopAccumaltiveChartData()}
                                  />
                                </>
                              ) : (
                                <>
                                  <Bar
                                    options={options}
                                    width={100}
                                    height={850}
                                    data={getChartData()}
                                  />
                                  <Line
                                    options={desktopChartOptions}
                                    width={100}
                                    height={850}
                                    data={getDesktopAccumaltiveChartData()}
                                  />{" "}
                                </>
                              )}
                            </IonRow>
                          </IonCol>

                          <IonCol
                            size="11.5"
                            sizeXs="11.5"
                            sizeMd="11.5"
                            sizeLg="12"
                            sizeXl="3.8"
                            className="filters-desktop"
                          >
                            <IonRow className="ion-justify-content-end">
                              <div>
                                <span className="">LIFESPAN SETTINGS</span>
                              </div>
                              <br />
                              <div className="Filters">
                                <>
                                  <section className="sliderContainer">
                                    <span id="sliderContainerACtual">
                                      <div className="digi">
                                        <span className="FontMobileSpecs">
                                          FROM:
                                          <h1>{kmFromSlider}</h1>
                                        </span>
                                        <span id="digiRight">
                                          TO:
                                          <h1>{kmToSlider}</h1>
                                        </span>
                                      </div>
                                      <IonRange
                                        id="slider"
                                        aria-label="Dual Knobs Range"
                                        dualKnobs={true}
                                        min={0}
                                        max={
                                          uiState.selectedVehicle?.data
                                            ?.servicePlanTotals?.result
                                            ?.servicePlans &&
                                          uiState.selectedVehicle?.data
                                            ?.servicePlanTotals?.result
                                            ?.servicePlans?.length > 0
                                            ? uiState.selectedVehicle?.data
                                                ?.servicePlanTotals?.result
                                                ?.servicePlans[
                                                uiState.selectedVehicle?.data
                                                  ?.servicePlanTotals?.result
                                                  ?.servicePlans.length - 1
                                              ].interval
                                            : 300000
                                        }
                                        step={1000}
                                        onIonChange={(ev: any) => {
                                          const lowerValue = isNaN(
                                            ev.detail.value.lower
                                          )
                                            ? 0
                                            : ev.detail.value.lower;
                                          const upperValue = isNaN(
                                            ev.detail.value.upper
                                          )
                                            ? 0
                                            : ev.detail.value.upper;
                                          setKmToSlider(upperValue);
                                          setKmFromSlider(lowerValue);
                                        }}
                                        value={{
                                          upper: kmToSlider,
                                          lower: kmFromSlider,
                                        }}
                                      ></IonRange>

                                      <div className="digi">
                                        <span>TRAVELLING:</span>
                                        <span id="digiRight">
                                          <h1>
                                            {rangeValue}
                                            /YR
                                          </h1>
                                        </span>
                                      </div>

                                      <IonRange
                                        id="slider"
                                        step={500}
                                        min={2500}
                                        max={100000}
                                        value={rangeValue}
                                        onIonChange={handleRangeChange}
                                      ></IonRange>

                                      <div className="digi">
                                        <span>ANNUAL PRICE INCREASE</span>
                                        <span id="digiRight">
                                          <h1>{Inflation}%</h1>
                                        </span>
                                      </div>

                                      <IonRange
                                        id="slider"
                                        step={0.25}
                                        min={0}
                                        max={30}
                                        value={Inflation}
                                        onIonChange={handleInflationChange}
                                      ></IonRange>
                                    </span>
                                  </section>
                                </>

                                <IonRow className="hiddenPlanDetails">
                                  <div>
                                    <span
                                      id="actualPlans"
                                      className="ActualPlanDetails"
                                    >
                                      The report below covers a lifespan of{" "}
                                      <span style={{ color: "red" }}>
                                        {calcDuration}
                                      </span>{" "}
                                      kilometres or{" "}
                                      <span style={{ color: "red" }}>
                                        {Number(
                                          (Number(kmToSlider) / rangeValue) * 12
                                        ).toFixed(0)}{" "}
                                      </span>{" "}
                                      months with annual price increase of
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {Inflation}%
                                      </span>
                                    </span>
                                  </div>
                                </IonRow>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                <span className="">
                                  MAINTENANCE PLAN SETTINGS
                                </span>
                              </div>
                              <div className="Filters">
                                <section className="sliderContainer">
                                  <span id="sliderContainerACtual">
                                    <IonCol className="activeState ion-justify-content-end">
                                      {showServiceData
                                        ? "ACTIVATED"
                                        : "DEACTIVATED"}
                                      <div className="toggleState">
                                        <IonToggle
                                          checked={showServiceData}
                                          className="ServiceCostToggleBtn"
                                          onIonChange={(e) =>
                                            setShowServiceData(!showServiceData)
                                          }
                                        />
                                      </div>
                                    </IonCol>
                                  </span>
                                </section>
                                <IonRow className="hiddenPlanDetails">
                                  <div>
                                    <span
                                      id="actualPlans"
                                      className="ActualPlanDetails"
                                    >
                                      The model follows a{" "}
                                      <span style={{ color: "red" }}>
                                        <span
                                          style={{ textTransform: "lowercase" }}
                                        >
                                          {
                                            uiState.selectedVehicle?.data
                                              ?.modelSpecification.result
                                              .service_maintenance_warranty
                                              .service_plan_type
                                          }{" "}
                                          service schedule
                                        </span>
                                      </span>{" "}
                                      and if there's a service plan included,
                                      it's{" "}
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {showServiceData
                                          ? "activated"
                                          : "deactivated"}
                                      </span>{" "}
                                      in the report
                                    </span>
                                  </div>
                                </IonRow>
                              </div>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                    {/*DISPLAY CHART HERE */}

                    <DataAssurance />
                    <Whatsapp shareClicked={handleShareClick} />
                  </section>
                </div>
              </>
            )}
          </div>

          <div
            className={`totalsDisplay ${
              slideDirection === "in" ? "slideIn" : "slideOut"
            }`}
            onTouchStart={(e) => {
              touchStartX = e.touches[0].clientX;
            }}
            onTouchEnd={(e) => {
              const touchEndX = e.changedTouches[0].clientX;
              const swipeDistance = touchEndX - touchStartX;
              const swipeThreshold = 50;

              if (Math.abs(swipeDistance) > swipeThreshold) {
                handleSwipe();
              }
            }}
          >
            <span> TOTAL MAINTENANCE COST</span>
            <span>
              <IonIcon
                src={chevronBack}
                id="Total-prev-arrow"
                onClick={(e) => {
                  handleSwipe();
                }}
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
              <div className="ExcVat">
                <span></span>
                {format(totalServiceCost, Format.Currency)}{" "}
                <small className="Text-excvat">EXC VAT</small>
              </div>
              <div className="InclVat">
                <span></span>{" "}
                {format(
                  (totalServiceCost * 15) / 100 + totalServiceCost,
                  Format.Currency
                )}{" "}
              </div>
              <p className="InclVat-per-km">{totalServiceCostPerKm}</p>
              <p className="ExcVat">{totalServiceCosPerKm}</p>

              <IonIcon
                src={chevronForward}
                id="Total-next-arrow"
                onClick={(e) => {
                  handleSwipe();
                }}
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
            </span>
            <span className="Text-incvat">INC VAT</span>
          </div>
        </GlobalGrid>
      )}

      <MessageModal
        visible={modalVisible}
        message={
          "Recommended Intervals: Dependent on driver behaviour and operating enviroment"
        }
        onPress={onModalPress}
      />
    </>
  );
};

export default Maintenance;
