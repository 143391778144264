import { useState, useEffect } from "react";
import "./install.css";
import { icons } from "../../assets";
import { IonIcon } from "@ionic/react";
import {
  chevronForward,
  chevronForwardOutline,
  downloadOutline,
} from "ionicons/icons";
import { useMediaQuery } from "react-responsive";

const InstallPrompt: React.FC = () => {
  interface BeforeInstallPromptEvent extends Event {
    readonly platforms: ReadonlyArray<string>;
    readonly userChoice: Promise<{
      outcome: "accepted" | "dismissed";
      platform: string;
    }>;
    prompt(): Promise<void>;
  }

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [showInstallButton, setShowInstallButton] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] =
  useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      setDeferredPrompt(beforeInstallPromptEvent);
      setShowInstallButton(true);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          setDeferredPrompt(null);
          setShowInstallButton(false);
        }
      });
    }
  };

  return (
    <>
      {showInstallButton && (
        <div className="installBg">
          {isDesktop ? (
            <div className="installPromtDesktop">
              <div className=" voice">
                <>
                  <div className="box">
                    <div className="innerBox">
                      <div className="top">
                        <img
                          src={icons.voiceRec}
                          className="img"
                          alt="Camera Scan"
                        />
                      </div>
                      <div className="bottom">
                        Our app is optimized for mobile phones, ensuring the
                        best performance and usability. Take the next step and
                        install it now for an optimized user experience.
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="installBtns">
                <button onClick={handleInstallClick}>
                  {" "}
                  INSTALL{" "}
                  <IonIcon
                    onClick={handleInstallClick}
                    src={downloadOutline}
                  ></IonIcon>
                </button>

                <button onClick={() => setShowInstallButton(false)}>
                  CONTINUE <IonIcon src={chevronForwardOutline}></IonIcon>
                </button>
              </div>
            </div>
          ) : (
            <div className="installPromt">
              <div className=" voice">
                <>
                  <div className="box">
                    <div className="innerBox">
                      <div className="top">
                        <img
                          src={icons.voiceRec}
                          className="img"
                          alt="Camera Scan"
                        />
                      </div>
                      <div className="bottom">
                        Our app is optimized for mobile phones, ensuring the
                        best performance and usability. Take the next step and
                        install it now for an optimized user experience.
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="installBtns">
                <button onClick={handleInstallClick}>
                  {" "}
                  INSTALL{" "}
                  <IonIcon
                    onClick={handleInstallClick}
                    src={downloadOutline}
                  ></IonIcon>
                </button>
                <button onClick={() => setShowInstallButton(false)}>
                  CONTINUE <IonIcon src={chevronForwardOutline}></IonIcon>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InstallPrompt;
