import {
  IonInput,
  IonButton,
  IonIcon,
  IonLabel,
  IonCard,
  IonCol,
  IonRow,
  IonText,
  IonGrid,
  IonImg,
} from "@ionic/react";
import { useCallback, useState } from "react";
import Button from "../../components/button";
import "../../Global.css";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  setFileAgeData,
  setLabourRatesData,
  showLoader,
} from "../../redux/ui/uiSlice";
import "./style.css";
import { authUser, registerUser } from "../../api/auth";
import { User } from "../../interfaces";
import { icons, images } from "../../assets";
import { Link, useHistory } from "react-router-dom";
import { eye, eyeOff } from "ionicons/icons";
import { LabourRates, getPriceFileAge, getTheme } from "../../api/mapo";
import {
  setSelectedOem,
  sessionLogin,
  setTheme,
  setBranded,
  setTermsAccepted,
} from "../../redux/session/sessionSlice";
import { RootState } from "../../redux/store";
import { useMediaQuery } from "react-responsive";

// Function to validate email format
const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// Function to generate a random password based on regex pattern
const generateRandomPassword = (): string => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  const specialChars = "!@#$%^&*()";
  const numbers = "0123456789";
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";

  let password = "";

  // Ensure at least one special character
  password += specialChars.charAt(
    Math.floor(Math.random() * specialChars.length)
  );

  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  password += uppercaseLetters.charAt(
    Math.floor(Math.random() * uppercaseLetters.length)
  );
  password += lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );
  for (let i = 0; i < 8 - 4; i++) {
    // 4 characters are already added (1 special, 1 number, 1 uppercase, 1 lowercase)
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return password;
};

interface RegistrationFormProps {
  onSigninMode: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onSigninMode,
}: RegistrationFormProps) => {
  const [user, setUser] = useState<User>({
    username: "",
    password: "",
  });
  const uiState = useSelector((state: RootState) => state.ui);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [passwordUppercase, setpasswordUppercase] = useState<string>("");
  const [passwordLowercase, setpasswordLowercase] = useState<string>("");
  const [passwordNumbercase, setpasswordNumbercase] = useState<string>("");
  const [passwordSpecialcase, setpasswordSpecialcase] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [termsVisible, setTermsVisible] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSignUpPress = useCallback(() => {
    const passwordRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const password = user.password || "";

    const username = user.username || "";

    if (!validateEmail(username)) {
      setEmailError("Please enter a valid email address.");
      setTimeout(() => {
        setEmailError("");
      }, 9000);
      return;
    }

    if (!passwordRegex.test(password)) {
      // setPasswordError(
      //   "Password must be 8 characters long and contain at least one special character."
      // );
      setPasswordError("Password must be atleast 8 characters long");
      setpasswordUppercase("Must contain atleast one uppercase letter");
      setpasswordLowercase("Must contain atleast one lowercase letter");
      setpasswordSpecialcase(
        "Must contain atleast one special character (&%@$*^#)"
      );
      setpasswordNumbercase("Must contain atleast one number");
      setTimeout(() => {
        setPasswordError("");
        setpasswordUppercase("");
        setpasswordLowercase("");
        setpasswordSpecialcase("");
        setpasswordNumbercase("");
      }, 40000);
      return;
    }

    setPasswordError("");
    setpasswordUppercase("");
    setpasswordLowercase("");
    setpasswordSpecialcase("");
    setpasswordNumbercase("");
    setEmailError("");

    dispatch(showLoader());
    registerUser({ ...user, password })
      .then((regResponse) => {
        // dispatch(hideLoader());
        if (regResponse.status === 200) {
          dispatch(showLoader());
          authUser(user)
            .then((regResponse) => {
              if (regResponse.status === 200) {
                LabourRates()
                  .then((LabourRatesResponse) => {
                    dispatch(
                      setLabourRatesData(LabourRatesResponse.data?.result)
                    );
                    getPriceFileAge()
                      .then((priceAgeResponse) => {
                        dispatch(setFileAgeData(priceAgeResponse.data));
                        getTheme(user.username ?? "")
                          .then((themeResponse) => {
                            dispatch(hideLoader());
                            const newUser: User = {
                              ...user,
                              loginResponse: regResponse.data,
                            };
                            if (
                              newUser?.loginResponse?.oems &&
                              newUser?.loginResponse?.oems?.length > 0
                            ) {
                              dispatch(
                                setSelectedOem(newUser?.loginResponse?.oems[0])
                              );
                            }
                            dispatch(sessionLogin(newUser));
                            dispatch(setTheme(themeResponse.data));
                            dispatch(setBranded(true));

                            if (
                              newUser.loginResponse?.message?.indexOf(
                                "Terms and conditions need to be accepted"
                              ) !== -1
                            ) {
                              if (uiState.isDesktop) {
                                dispatch(setTermsAccepted(false));
                                //dispatch(setScreen(AppScreens.Welcome));
                                history.push("/home");
                              } else {
                                setTermsVisible(true);
                              }
                            } else {
                              dispatch(setTermsAccepted(true));
                              //dispatch(setScreen(AppScreens.Home));
                              history.push("/home");
                            }
                          })
                          .catch((err) => {
                            dispatch(hideLoader());
                            console.log(err);
                            alert(JSON.stringify(err));
                          });
                      })
                      .catch((err) => {
                        dispatch(hideLoader());
                        console.log(err);
                        alert(JSON.stringify(err));
                      });
                  })
                  .catch((err) => {
                    dispatch(hideLoader());
                    console.log(err);
                    alert(JSON.stringify(err));
                  });
              } else {
                alert(regResponse.statusText);
              }
            })
            .catch((err) => {
              dispatch(hideLoader());
              console.log(err);
              alert(JSON.stringify(err));
            });
        } else {
          //alert(JSON.stringify(regResponse));
          toggleShowPassword();
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        //alert(JSON.stringify(err));
        setErrorMessage("Email address already exists.");
      });
  }, [dispatch, user, history]);

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setUser({ ...user, password: newPassword });
    setPasswordError("");
  };

  return (
    <>
      <IonGrid className="loginPageCont">
        <IonRow>
          <IonCol size="12" className="guestOption">
            <IonCard className="guest-card">
              <IonCol size="12" className="guest-btn">
                <button
                  className="guest-btn"
                  // onClick={() => {
                  //   onSignUpPress();
                  // }}
                  title="Sign In"
                >
                  Sign Up
                </button>
              </IonCol>

              <IonCol size="12" className="formLabels">
                <IonLabel className="label">
                  Your username
                  <IonInput
                    className="input"
                    placeholder="Enter a 6-10 character username"
                    value={user.username}
                    onIonChange={(ev: any) =>
                      setUser({ ...user, username: ev.target.value })
                    }
                  ></IonInput>
                  <IonRow>
                    <IonCol className="ion-justify-content-center login-font">
                      {emailError && (
                        <div style={{ color: "red" }}>{emailError}</div>
                      )}
                      <div className="error">{errorMessage}</div>
                    </IonCol>
                  </IonRow>
                </IonLabel>
                <IonLabel className="label">
                  Password
                  <span id="labelWithEye">
                    <IonInput
                      className="input"
                      placeholder="Fill your password"
                      type={showPassword ? "text" : "password"}
                      value={user.password || ""}
                      onIonChange={(ev: any) =>
                        setUser({ ...user, password: ev.target.value })
                      }
                    ></IonInput>
                    <IonImg
                      className="eye-icon"
                      src={showPassword ? eye : eyeOff}
                      onClick={toggleShowPassword}
                    />
                  </span>
                  <IonRow>
                    <IonCol className="ion-justify-content-center">
                      {passwordError && (
                        <div style={{ color: "red" }} className="login-font">
                          - {passwordError} <br />- {passwordUppercase} <br /> -{" "}
                          {passwordLowercase} <br /> - {passwordNumbercase}{" "}
                          <br /> - {passwordSpecialcase}
                          <br />{" "}
                        </div>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-justify-content-center login-font">
                      {passwordError && (
                        <IonCol size="12" sizeXl="1" className="seperator-txt">
                          OR
                        </IonCol>
                      )}
                    </IonCol>
                  </IonRow>
                </IonLabel>

                <IonLabel className="label">
                  {passwordError && (
                    <button
                      title="Generate password"
                      onClick={handleGeneratePassword}
                      className="no-wrap"
                    >
                      Generate Password{" "}
                    </button>
                  )}
                </IonLabel>

                <IonLabel className="label">
                  <button onClick={onSignUpPress} title="Sign In">
                    Sign Up
                  </button>
                </IonLabel>

                <IonCol size="12" onClick={onSigninMode} className="noAccount">
                  Already have a login?{" "}
                  <strong onClick={onSigninMode} style={{ color: "black" }}>
                    SIGN IN
                  </strong>
                </IonCol>
              </IonCol>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default RegistrationForm;
