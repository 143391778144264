import { Format } from '../enums';

const formatCurrency = (
  val: string | number | undefined,
  noZeros?: boolean
) => {
  if (val !== undefined) {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
    })
      .format(Number(val))
      .replace('R', '')
      .replace(',', ' ');
  } else {
    if (noZeros) {
      return '';
    } else {
      return '0.00';
    }
  }
};

const formatNumber = (val: string | number | undefined, noZeros?: boolean) => {
  if (val !== undefined) {
    return new Intl.NumberFormat('en-ZA', {
      maximumSignificantDigits: 2,
    })
      .format(Number(val))
      .replace(',', ' ');
  } else {
    if (noZeros) {
      return '';
    } else {
      return '0.00';
    }
  }
};

export const format = (
  val: string | number | undefined,
  fmt: Format | undefined,
  noZeros?: boolean
) => {
  if (fmt === Format.Number) {
    return formatNumber(val, noZeros);
  } else if (fmt === Format.Currency) {
    return formatCurrency(val, noZeros);
  } else if (fmt === Format.Decimal) {
    return (Math.round(Number(val) * 100) / 100).toFixed(2);
  }
};

export const decVal = (value?: string) => {
  let newValue = value ?? '0';
  newValue = newValue.replace('R', '');
  newValue = newValue.replaceAll(' ', '');
  return Number(newValue);
};
