import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonIcon,
  IonGrid,
} from "@ionic/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getImage, modelClass } from "../../../api/mapo";

import DesktopVehicleLabel from "../../../components/desktop-vehicle-label";
import Header from "../../../components/header";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens, Format } from "../../../enums";
import "../../../Global.css";
import { RootState } from "../../../redux/store";
import { setScreen } from "../../../redux/ui/uiSlice";
import { format } from "../../../utils";
import { useHistory } from "react-router-dom";
import { CompetitorData } from "../../../interfaces/competitorData";
import { VehicleCustomReportParams } from "../../../interfaces/customReportParams";
import {
  chevronBackCircleSharp,
  chevronBackOutline,
  thumbsUpSharp,
} from "ionicons/icons";
import CompetitorView from "../../../components/competitorsView/competitorView";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";
import DesktopContainer from "../../../components/desktop-container";

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}

const Competitor: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [tableView, setTableView] = useState(true);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [CartClicked, setIsClicked] = useState(false);
  const [addCartAlert, setAddCartAlert] = useState(false);
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );
  // const [values, setValues] = useState({
  //   totalLifeSpan: uiState.customReportParams.totalLifeSpan ?? 120000,
  //   annualMileage: uiState.customReportParams.annualMileage ?? 20000,
  // });

  // const mileageOptions = () => {
  //   const options = [];
  //   for (let i = 0; i <= 30; i++) {
  //     options.push(i * 10000);
  //   }
  //   return options;
  // };

  // const anualMileageOptions = () => {
  //   const options = [];
  //   for (let i = 0; i <= 10; i++) {
  //     options.push(i * 5000);
  //   }
  //   return options;
  // };

  const history = useHistory();

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push("/OverViewDetail");
  }, [dispatch]);

  const renderLowestValue = useCallback(
    (
      index: number,
      val?: string,
      val1?: string,
      val2?: string,
      val3?: string,
      fmt?: Format
    ) => {
      let valInt = val ?? 0;

      if (index === 1) {
        valInt = val1 ?? 0;
      } else if (index === 2) {
        valInt = val2 ?? 0;
      } else if (index === 3) {
        valInt = val3 ?? 0;
      }

      if (fmt !== undefined) {
        return <span>{format(valInt, fmt)}</span>;
      } else {
        return <span>{valInt}</span>;
      }
    },
    []
  );

  const renderHighestValue = useCallback(
    (
      index: number,
      val?: string,
      val1?: string,
      val2?: string,
      val3?: string,
      fmt?: Format
    ) => {
      let valInt = val ?? 0;

      if (index === 1) {
        valInt = val1 ?? 0;
      } else if (index === 2) {
        valInt = val2 ?? 0;
      } else if (index === 3) {
        valInt = val3 ?? 0;
      }

      if (fmt !== undefined) {
        return <span>{format(valInt, fmt)}</span>;
      } else {
        return <span>{valInt}</span>;
      }
    },
    []
  );

  const getAdditionalServicePlanKm = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalkm
          ? customParams.serviceadditionalkm ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalServicePlanMonths = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalmnt
          ? customParams.serviceadditionalmnt ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan_months ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalMaintenancePlanKm = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.maintenanceadditionalkm
          ? customParams.maintenanceadditionalkm ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalMaintenancePlanMonths = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.maintenanceadditionalmnt
          ? customParams.maintenanceadditionalmnt ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.maintenance_plan_months ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const addToCart = (competitor: any) => {
    const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");

    // Check if 'modelClass.result' exists and has at least one element
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        modelClass,
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      // Check if the competitor is already in the cart
      const isInCart = cartItems.some(
        (item: any) => item.modelCode === modelCode
      );

      if (!isInCart) {
        // Add the competitor details to the cart
        cartItems.push({
          modelClass,
          make,
          modelCode,
          groupCode,
          variantCode,
          introDate,
          mapoCode,
          modelClassShortDescription,
          variant,
          variantId,
          status,
          model,
        });

        // Update localStorage with the new cart items
        localStorage.setItem("cart", JSON.stringify(cartItems));

        // The console
        //console.log("Cart Data:", cartItems);

        //alert("Item added to cart!");
        setIsClicked(true);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ADDED TO CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      } else {
        setDuplicateItemIndex(competitor);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ALREADY IN CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      }
    } else {
      console.error("Error: No result found in competitor.modelClass object.");
    }
  };

  //The Date for the vehicle in modelClass
  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <>
      <GlobalGrid
        currentPageTitle="Competitors"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <div className="headHolder HeaderStick">
            <IonRow id="selectedVehicleGlobal">
              {uiState.selectedVehicle ? (
                <>
                  <IonCol
                    sizeXs="6"
                    sizeMd="4"
                    sizeLg="3"
                    id="gridOverviewImageCont"
                  >
                    <img
                      id="gridOverviewImage"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />{" "}
                  </IonCol>

                  <IonCol
                    sizeXs="6"
                    sizeMd="8"
                    sizeLg="9"
                    id="gridOverviewDetails"
                  >
                    <h1>{uiState.selectedVehicle.make}</h1>
                    <h1>{uiState.selectedVehicle.model}</h1>
                    <h1 id="gridOverviewVariant">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <>{details.variant}</>
                        ))}
                    </h1>
                    <span id="overViewCluster">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <h1 key={index}>
                            MY{" "}
                            {moment(new Date(details.introDate ?? "")).format(
                              "YYYY"
                            )}
                          </h1>
                        ))}
                      <h1>
                        G{uiState.selectedVehicle.g_id.toString()}M
                        {uiState.selectedVehicle.m_id.toString()}V
                        {uiState.selectedVehicle.v_id.toString()}
                      </h1>
                      <h1>{uiState.selectedVehicle.variant_id}</h1>{" "}
                    </span>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </div>
        </div>

        {uiState.competitors?.length === 0 ? (
          <span className="resultSubtitle">No competitors data available.</span>
        ) : null}
        <DesktopContainer>
          <IonGrid className="gridCompHolder">
            {uiState.competitors?.map((result, index) => {
              return (
                <CompetitorView
                  competitor={result}
                  compIndex={index}
                  Addtocart={() => addToCart(result)}
                />
              );
            })}
          </IonGrid>
        </DesktopContainer>

        {addCartAlert && (
          <div className="addToCartAlertCont">
            <div className="thumb">
              <IonIcon src={thumbsUpSharp}></IonIcon>
            </div>
            <p>{addCartMsg}</p>
          </div>
        )}
      </GlobalGrid>
    </>
  );
};

export default Competitor;
