import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import Header from '../../components/header';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import { RootState } from '../../redux/store';
import { setScreen } from '../../redux/ui/uiSlice';
import { images } from '../../assets/index';
import { useHistory } from 'react-router-dom';

const Preowned: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push('/VehicleDetail');
  }, [dispatch]);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          <IonRow className='desktopContent'>
            {/* <IonCol size='1' className='ion-no-padding ion-no-margin'>
              <SideMenu></SideMenu>
            </IonCol> */}
            <IonCol size='12' className='ion-no-padding ion-no-margin'>
              <DesktopContainer>
                <div className='detailContent'>Coming soon...</div>
              </DesktopContainer>
            </IonCol>
          </IonRow>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
              
                vehicle={uiState.selectedVehicle}
              ></SubPageHeading>
              &nbsp;
              <br />
              <IonRow>
                <IonCol>
                  <a href='https://www.changecars.co.za/' target='_blank'>
                    <img src={images.changecars} alt='' />
                  </a>
                  <br />
                  <small>https://www.changecars.co.za/</small>
                </IonCol>
              </IonRow>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Preowned;
