import "./style.css";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  setCompetitors,
  setCreateReportVisible,
  setCustomReportParams,
  setMenuCollapsed,
  setScreen,
  setSelectedVehicle,
} from "../../redux/ui/uiSlice";
import { AppScreens } from "../../enums";
import Link from "../../components/link";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { icons } from "../../assets/index";

interface SideMenuProps {}

const SideMenu: React.FC<SideMenuProps> = () => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);

  const dispatch = useDispatch();

  const onModelSelection = useCallback(() => {
    dispatch(setSelectedVehicle(undefined));
    dispatch(setCompetitors(undefined));
    dispatch(
      setCustomReportParams({
        deposit: 0,
        interest: 9.5,
        term: 60,
        residual: 0,
        toggles: {
          finance: true,
          service: true,
          maintenance: true,
          fuel: true,
          insurance: true,
          tyre: true,
          depreciation: true,
        },
        totalLifeSpan: 120000,
        annualMileage: 20000,
        tyreChangeInterval: 60000,
      })
    );
    dispatch(setScreen(AppScreens.Home));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onCompetitorAnalysis = useCallback(() => {
    dispatch(setScreen(AppScreens.Competitors));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onTCO = useCallback(() => {
    dispatch(setScreen(AppScreens.TCO));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onMaintenanceCost = useCallback(() => {
    dispatch(setScreen(AppScreens.Maintenance));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onServiceCost = useCallback(() => {
    dispatch(setScreen(AppScreens.Services));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onDepreciation = useCallback(() => {
    dispatch(setScreen(AppScreens.Depreciation));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  // const onPartsBasket = useCallback(() => {
  //   dispatch(setScreen(AppScreens.Parts));
  // }, [dispatch]);

  const on10KRates = useCallback(() => {
    dispatch(setScreen(AppScreens.Rates10K));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onSpecs = useCallback(() => {
    dispatch(setScreen(AppScreens.Specs));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onCustomiseReport = useCallback(() => {
    dispatch(setScreen(AppScreens.CustomizeReport));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onSendReport = useCallback(() => {
    dispatch(setCreateReportVisible(true));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onTraining = useCallback(() => {
    dispatch(setScreen(AppScreens.Tutorial));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  const onSupport = useCallback(() => {
    dispatch(setScreen(AppScreens.Support));
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  return (
    <div
      className="sideContainer"
      style={
        sessionState.branded
          ? { backgroundColor: sessionState.theme?.sidebar_background }
          : {}
      }
    >
      <div className="innerMenu">
        {uiState.screen !== AppScreens.BrandSelection ? (
          <Link
            color={
              sessionState.branded && sessionState.theme
                ? sessionState.theme?.sidebar_text
                : "white"
            }
            onPress={onModelSelection}
            title="Model Selection"
            image={icons.modelSelection}
            bold={uiState.screen === AppScreens.Home}
          />
        ) : null}
        {uiState.selectedVehicle ? (
          <>
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onCompetitorAnalysis}
              title="Competitor Analysis"
              image={icons.competitorAnalysis}
              bold={uiState.screen === AppScreens.Competitors}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onSpecs}
              title="Detailed Specifications"
              image={icons.specifications}
              bold={uiState.screen === AppScreens.Specs}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onServiceCost}
              title="Service Costs"
              image={icons.serviceCosts}
              bold={uiState.screen === AppScreens.Services}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onMaintenanceCost}
              title="Maintenance Costs"
              image={icons.maintenanceCosts}
              bold={uiState.screen === AppScreens.Maintenance}
            />
            <br />
            {/* <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : 'white'
              }
              onPress={onPartsBasket}
              title='Parts Basket'
              bold={uiState.screen === AppScreens.Parts}
            />
            <br /> */}
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={on10KRates}
              title="10K Rates Table"
              image={icons.rates10k}
              bold={uiState.screen === AppScreens.Rates10K}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onDepreciation}
              title="Depreciation Value"
              image={icons.depreciation}
              bold={uiState.screen === AppScreens.Depreciation}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onTCO}
              title="Total Cost Analysis"
              image={icons.totalCostAnalysis}
              bold={uiState.screen === AppScreens.TCO}
            />
            <br />
            &nbsp;
            <br />
            &nbsp;
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onCustomiseReport}
              title="Customise Report"
              image={icons.customizeReport}
              bold={uiState.screen === AppScreens.CustomizeReport}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onSendReport}
              title="Send Report"
              image={icons.sendReport}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onSupport}
              title="Support"
              image={icons.support}
              bold={uiState.screen === AppScreens.Support}
            />
            <br />
            <Link
              color={
                sessionState.branded && sessionState.theme
                  ? sessionState.theme?.sidebar_text
                  : "white"
              }
              onPress={onTraining}
              title="Training"
              image={icons.training}
              bold={uiState.screen === AppScreens.Tutorial}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SideMenu;
