import {
  IonAccordion,
  IonAccordionGroup,
  IonCheckbox,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRouterContext,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./style.css";
import "../../Global.css";
import { desktopImages } from "../../assets/index";
import { ReactNode, useCallback, useEffect, useState, useRef } from "react";
import Button from "../../components/button";
import ParameterResultView from "./parameter-result-view";
import { useDispatch } from "react-redux";
import UiSlice, {
  hideLoader,
  setParameterSearchVisible,
  showLoader,
} from "../../redux/ui/uiSlice";
import {
  getAllVehicles,
  manualSearch,
  modelClass,
  parameterSearch,
} from "../../api/mapo";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AllVehicleParam } from "../../interfaces/allVehicleParam";
import { ParamSearch } from "../../interfaces/paramSearch";
import { Vehicle } from "../../interfaces/vehicle";
import moment, { max, min } from "moment";
import ReactSlider from "react-slider";
import {
  chevronBack,
  chevronBackCircleSharp,
  chevronBackOutline,
  close,
  closeOutline,
  filterCircle,
  filterOutline,
  handLeft,
  iceCreamSharp,
  map,
} from "ionicons/icons";
import { parse } from "querystring";
import { count } from "console";
import { setSelectedOem } from "../../redux/session/sessionSlice";
import { current } from "@reduxjs/toolkit";
import { images } from "../../assets";
import Header from "../../components/header";

export enum ParameterSearchViewType {
  SearchResult,
  SwapOut,
  ParameterResultView,
}

interface FilteredSearchProps {
  isVisible: boolean;
  onClosed: () => void;
  viewType: ParameterSearchViewType;
}
const ParameterSearch: React.FC<FilteredSearchProps> = ({
  isVisible,
  onClosed,
  viewType,
}: FilteredSearchProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<Array<AllVehicleParam>>();
  const isLoaded = useRef(false);
  const [searchResults, setSearchResults] = useState<Array<Vehicle>>();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [filter, setFilter] = useState<ParamSearch>({});
  const yearsArray = Array.from({ length: 2025 - 1990 }, (_, index) =>
    (1990 + index).toString()
  );
  yearsArray.reverse();
  const [selectedSeats, setSelectedSeats] = useState(Number);
  const [selectedModel, setSelectedModel] = useState(" ");
  const [selectedModelClass, setselectedModelClass] = useState("");
  const [selectedModelPlaceholder, setSelectedModelPlaceholder] =
    useState("MODELS ");
  const [selectedVarPlaceholder, setSelectedVarPlaceholder] =
    useState("VARIANTS ");
  const [modelYearDiff, setModelYearDiff] = useState(0);

  const [transPlaceholder, setTransPlaceholder] = useState("TRANSMISSION ");
  const [fuelPlaceholder, setFuellaceholder] = useState("FUEL TYPE ");
  const [yearPlaceholder, setYearPlaceholder] = useState("YEAR");
  const [doorsPlaceholder, setDoorsPlaceholder] = useState("DOORS");
  const [selectedTransType, setSelectedTransType] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [enteredVarID, setEnteredVarID] = useState("");
  const [selectedDriveType, setSelectedDriveType] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDoors, setSelectedDoors] = useState(Number);
  const [isChecked, setIsChecked] = useState(false);
  const MINSLIDER = 0;
  const MAXSLIDER = 1500000;
  const MINKW = 0;
  const MAXKW = 1000;
  const MINNM = 0;
  const MAXNM = 1000;
  const MINDIS = 0;
  const MAXDIS = 5000;
  const [kwDiff, setKwDiff] = useState(0);
  const [nmDiff, setNmDiff] = useState(0);
  const [priceDiff, setPriceDiff] = useState(0);
  const [disDiff, setDisDiff] = useState(0);
  const [ccDiff, setCcDiff] = useState(0);
  const [sliderValues, setSliderValues] = useState([MINSLIDER, MAXSLIDER]);
  const [selectedKw, setSelectedKW] = useState([MINKW, MAXKW]);
  const [selectedNm, setSelectedNm] = useState([MINNM, MAXNM]);
  const [selectedDisplacement, setSelectedDisplacement] = useState(0);
  const [specificVariantsByModel, setSpecificVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [specificVariantsId, setSpecificVariantsId] = useState([]);
  const userName = JSON.stringify(sessionState.user?.username);
  const gCode = Number(sessionState.selectedOem?.g_id);
  //checkbox handlers
  const [selectedCheckbox, setSelectedCheckbox] = useState(String);
  const [selectedVariantCheckbox, setSelectedVariantCheckbox] =
    useState(String);
  const [selectedModelCheckbox, setSelectedModelCheckbox] = useState(String);
  const [selectedTransCheckbox, setSelectedTransCheckbox] = useState(String);
  const [selectedFuelCheckbox, setSelectedFuelCheckbox] = useState(String);
  const [selectedYearCheckbox, setSelectedYearCheckbox] = useState(String);
  const [statusArr, setStatusArr] = useState(["CURRENT", "DISCONTINUED"]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [addModelsByGroup, setAddModelsByGroup] = useState<
    Array<String | Number> | []
  >();
  const [addVariantsByModel, setAddVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  //loads all vehiccles based on user
  const loadVehicles = useCallback(() => {
    if ((sessionState.user?.username ?? "") !== "") {
      dispatch(showLoader());
      getAllVehicles(uiState?.isDesktop ?? false)
        .then((vehicleResponse) => {
          dispatch(hideLoader());
          if (vehicleResponse.status === 200) {
            setVehicles(vehicleResponse.data);
            if (viewType === ParameterSearchViewType.SearchResult) {
              setFilter({
                ...filter,
                group: sessionState.selectedOem?.g_desc.toString(),
              });
            }
          } else {
            alert(vehicleResponse.statusText);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, sessionState, uiState, filter, viewType]);

  //defines action of reset button
  const onResetFilters = useCallback(() => {
    setSelectedStatus("");
    setSelectedCheckbox("");
    setSelectedModelCheckbox("");
    setSelectedVariantCheckbox("");
    setSelectedYearCheckbox("");
    setSpecificVariantsByModel([]);
    setEnteredVarID("");
    setSelectedSeats(0);
    setSelectedNm([MINNM, MAXNM]);
    setSelectedKW([MINKW, MAXKW]);
    setSelectedDisplacement(0);
    setSliderValues([]);
    selectedKw[0] = MINKW;
    selectedKw[1] = MAXKW;
    selectedNm[0] = MINNM;
    selectedNm[1] = MAXNM;
    sliderValues[0] = MINSLIDER;
    sliderValues[1] = MAXSLIDER;
    setSelectedDriveType("");
    setSelectedModel("");
    setSelectedStatus("");
    setSelectedTransType("");
    setFilter({});
    setSelectedYear("");
    setSelectedGroup("");
    setSelectedVariant("");
    setSelectedFuelType("");
    setSearchResults(undefined);
    setSliderValues([MINSLIDER, MAXSLIDER]);
    setModelYearDiff(0);
  }, []);
  //checks if vehicles is empty if so , gets all vehicles
  useEffect(() => {
    if (isVisible && vehicles === undefined && !isLoaded.current) {
      isLoaded.current = true;
      loadVehicles();
    }
  }, [vehicles, isVisible, loadVehicles]);
  //filters vehicles
  // const getFilteredVehicles = useCallback(() => {
  //   let newFilteredList: Array<AllVehicleParam> | undefined = [];
  //   if (
  //     (filter?.variant ?? "") &&
  //     (filter?.model ?? "") !== "" &&
  //     (filter?.group ?? "") !== ""
  //   ) {
  //     newFilteredList = vehicles?.filter(
  //       (item) =>
  //         item.g_desc === filter.group &&
  //         item.m_desc === filter.model &&
  //         item.v_desc === filter.variant
  //     );
  //   } else if ((filter?.model ?? "") !== "" && (filter?.group ?? "") !== "") {
  //     newFilteredList = vehicles?.filter(
  //       (item) => item.g_desc === filter.group && item.m_desc === filter.model
  //     );
  //   } else if ((filter?.group ?? "") !== "") {
  //     newFilteredList = vehicles?.filter(
  //       (item) => item.g_desc === filter.group
  //     );
  //   } else if (vehicles) {
  //     newFilteredList = [...vehicles];
  //   }

  //   return newFilteredList;
  // }, [filter, vehicles]);

  //this function defines action of search button
  const onSearch = useCallback(() => {
    if (
      (filter.GMV ?? "" === "") &&
      (filter.drive ?? "" === "") &&
      (filter.fuelType ?? "" === "") &&
      (filter.group ?? "" === "") &&
      (filter.listType ?? "" === "") &&
      (filter.maxKw ?? "" === "") &&
      (filter.maxNm ?? "" === "") &&
      (filter.maxPrice ?? "" === "") &&
      (filter.minDisplacement ?? "" === "") &&
      (filter.minDoors ?? "" === "") &&
      (filter.minKw ?? "" === "") &&
      (filter.minNm ?? "" === "") &&
      (filter.minPrice ?? "" === "") &&
      (filter.minSeats ?? "" === "") &&
      (filter.model ?? "" === "") &&
      (filter.modelClass ?? "" === "") &&
      (filter.modelYear ?? "" === "") &&
      (filter.transType ?? "" === "") &&
      (filter.variant ?? "" === "") &&
      (filter.variantId ?? "" === "") &&
      viewType === ParameterSearchViewType.SearchResult
    ) {
      dispatch(showLoader());
      console.log("here");
      setFilter({ group: sessionState.selectedOem?.g_desc.toString() });
      parameterSearch({
        group: selectedGroup, //this is the search API
        modelClass: selectedModelClass,
        model: selectedModel,
        variant: selectedVariant,
        transType: selectedTransType,
        fuelType: selectedFuelType,
        drive: selectedDriveType,
        minPrice: sliderValues[0],
        maxPrice: sliderValues[1],
        minKw: selectedKw[0],
        maxKw: selectedKw[1],
        maxNm: selectedNm[1],
        minNm: selectedNm[0],
        modelYear: selectedYear,
        minDoors: selectedDoors,
        minSeats: selectedSeats,
        minDisplacement: selectedDisplacement,
        GMV: enteredVarID,
        listType: selectedStatus,
      })
        .then((paramSearchResponse) => {
          dispatch(hideLoader());
          if (paramSearchResponse.status === 200) {
            setSearchResults(paramSearchResponse.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [
    dispatch,
    filter,
    selectedGroup,
    selectedModelClass,
    selectedModel,
    selectedDriveType,
    selectedFuelType,
    selectedTransType,
    selectedVariant,
    selectedYear,
    enteredVarID,
    selectedDoors,
    selectedDisplacement,
    selectedStatus,
    selectedSeats,
    selectedKw[0],
    selectedKw[1],
    selectedNm[0],
    selectedNm[1],
    sliderValues[0],
    sliderValues[1],
    modelYearDiff,
  ]);

  //this method returns all available models based on your OEM
  const addGroups: Array<string> = [];
  const getAddGroups = () => {
    vehicles?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addGroups.indexOf(v.g_desc) === -1
      ) {
        addGroups.push(v.g_desc);
        addGroups.sort();
      }
      return "";
    });
  };
  getAddGroups();

  const addModelClass: Array<string> = [];
  const getModelClass = () => {
    vehicles?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addModelClass.indexOf(v.modelclass) === -1
      ) {
        addModelClass.push(v.modelclass);
        addModelClass.sort();
      }
      return "";
    });
  };
  getModelClass();

  const addModels: Array<string> = [];
  const handleClickedGroup = (clickedGroup: string) => {
    vehicles?.forEach((v) => {
      if (v.g_desc == clickedGroup) {
        if (addModels.indexOf(v.m_desc) == -1) {
          addModels.push(v.m_desc);
          setAddModelsByGroup(addModels);
        }
      }
      return addModels;
    });
  };

  const addVariants: Array<string> = [];
  const handleClickedModel = (clickedModel: string) => {
    vehicles?.forEach((v) => {
      if (v.m_desc == clickedModel) {
        if (addVariants.indexOf(v.v_desc) == -1) {
          addVariants.push(v.v_desc);
          setAddVariantsByModel(addVariants);
        }
      }
      return addVariants;
    });
  };

  //this method gets specific vaariants based on your selected model
  const specificVariants: Array<string> = [];
  const VariantsId: Array<number> = [];
  const variantYear: Array<Date> = [];
  const handleClikedModels = (checkedModel: string) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == checkedModel) {
        specificVariants.push(value.v_desc);
        setSpecificVariantsByModel(specificVariants);
      }

      return specificVariants && specificVariantsId && variantYear;
    });
  };

  const accordGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  const toggleModelsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "modelsAccord";
    }
  };

  const toggleVariantsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "varAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "varAccord";
    }
  };

  const toggleFuelAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "fuelAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "fuelAccord";
    }
  };

  const toggleTransAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "transAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "transAccord";
    }
  };

  const toggleYearAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "yearAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "yearAccord";
    }
  };

  const toggleSeatsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "seatsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "seatsAccord";
    }
  };

  const toggleDoorsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "doorsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "doorsAccord";
    }
  };

  const toggleDriveAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "driveAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "driveAccord";
    }
  };
  const toggleStatusAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
  };

  const togglePowerAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "powerAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "powerAccord";
    }
  };

  const handleModalDismiss = () => {
    onClosed();
    onResetFilters();
  };

  const toggleGroupsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "groupsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "groupsAccord";
    }
  };

  const handleActiveCheckBox = (key: string) => {
    setSelectedCheckbox(key);
  };

  const handleActiveVariantCheckBox = (key: string) => {
    setSelectedVariantCheckbox(key);
  };

  const handleActiveModelCheckBox = (key: string) => {
    setSelectedModelCheckbox(key);
  };

  const handleActiveFuelCheckbox = (key: string) => {
    setSelectedFuelCheckbox(key);
  };
  const handleActiveYearCheckBox = (key: string) => {
    setSelectedYearCheckbox(key);
  };

  const handleActiveTransCheckBox = (key: string) => {
    setSelectedTransCheckbox(key);
  };

  const onClosePress = useCallback(() => {
    dispatch(setParameterSearchVisible(false));
  }, [dispatch]);

  const [additional, setAdditional] = useState(false);

  //start of JSX/TSX setParameterSearchVisible(false)
  return (
    <IonModal
      className="mod"
      onDidDismiss={handleModalDismiss}
      isOpen={isVisible}
    >
      <div className="filterSearchMobileCont">
        <div className="header-mobile ">
          <div className="toggle ">
            <div className="iconCont">
              {/* <IonIcon icon={menuOutline} className="icon" onClick={()=>{onToggleMenu()}}></IonIcon> */}
            </div>
            <div className="logoCont">
              <img className="logo" src={images.logo} alt="" />
            </div>
          </div>
        </div>

        {searchResults == undefined ? (
          <div className="paraTopSearch">
            <div className="garageBackNav LeftHeading">
              <div className="IconGarageback">
                <IonIcon
                  icon={chevronBackCircleSharp}
                  className=""
                  onClick={() => dispatch(setParameterSearchVisible(false))}
                ></IonIcon>
              </div>
              <div className="GaragePageTitle">
                <span className="ParameterTitle">PARAMETER SEARCH</span>
              </div>

              <IonIcon
                    className="additionalIcon"
                    icon={filterOutline}
                    onClick={() => setAdditional(true)}
                  ></IonIcon>

              <div className="right">
                <div className="AdditionalIconSize">
                  <IonIcon
                    className="additionalIcon"
                    icon={filterOutline}
                    onClick={() => setAdditional(true)}
                  ></IonIcon>
                </div>
              </div>
            </div>
            <div className="params">
              <div slot="content" className="paraPriceRangeCont">
                {additional && (
                  <div className="additionalCont">
                    <p>Additional Options{""} </p>
                    <div className="accordHolder">
                      <IonAccordionGroup
                        className="filterContParameter"
                        ref={accordGroup}
                      >
                        <IonAccordion
                          className="accordParameter"
                          value="fuelAccord"
                        >
                          <IonItem
                            slot="header"
                            className="filterHeaderParameterFuel"
                          >
                            {fuelPlaceholder}
                            <span className="selectionDisplay">
                              {selectedFuelType}
                            </span>
                          </IonItem>

                          <ul
                            slot="content"
                            className="variantsItemCont"
                            id="modelsListPara"
                          >
                            <li className="listContItems" key={"diesel"}>
                              <label className="listContVals">
                                <input
                                  className="radio"
                                  type="checkbox"
                                  checked={selectedFuelType === "d"}
                                  onChange={() => {
                                    setSelectedFuelType("d");
                                    handleActiveFuelCheckbox("diesel");
                                  }}
                                  onClick={() => toggleFuelAccordian()}
                                />
                                DIESEL
                              </label>
                            </li>
                            <li className="listContItems" key={"petrol"}>
                              <label className="listContVals">
                                <input
                                  className="radio"
                                  type="checkbox"
                                  checked={selectedFuelType === "p"}
                                  onChange={() => {
                                    setSelectedFuelType("p");
                                    handleActiveFuelCheckbox("petrol");
                                  }}
                                  onClick={() => toggleFuelAccordian()}
                                />
                                PETROL
                              </label>
                            </li>
                          </ul>
                        </IonAccordion>

                        <IonAccordion
                          className="accordParameter"
                          value="transAccord"
                        >
                          <IonItem
                            slot="header"
                            className="filterHeaderParameterFuel"
                          >
                            {/* {transPlaceholder} */}TRANS
                            <span className="selectionDisplay">
                              {selectedTransType}
                            </span>
                          </IonItem>
                          <ul
                            slot="content"
                            className="variantsItemCont"
                            id="modelsListPara"
                          >
                            <li className="listContItems">
                              <label className="listContVals">
                                <input
                                  className="radio"
                                  type="checkbox"
                                  checked={selectedTransType === "Automatic"}
                                  onClick={toggleTransAccordian}
                                  onChange={(event) => {
                                    setSelectedTransType(event.target.value);
                                  }}
                                  value={"Automatic"}
                                />
                                AUTO
                              </label>
                            </li>
                            <li className="listContItems">
                              <label className="listContVals">
                                <input
                                  className="radio"
                                  type="checkbox"
                                  checked={selectedTransType === "Manual"}
                                  onClick={toggleTransAccordian}
                                  onChange={(event) => {
                                    setSelectedTransType(event.target.value);
                                  }}
                                  value={"Manual"}
                                />
                                MANUAL
                              </label>
                            </li>
                          </ul>
                        </IonAccordion>

                        <IonAccordion className="accordParameter" value="">
                          <IonItem
                            slot="header"
                            className="filterHeaderParameterFuel"
                          >
                            MODEL CLASS
                          </IonItem>

                          <ul
                            slot="content"
                            className="variantsItemCont"
                            id="modelsListPara"
                          >
                            {addModelClass.map((e, index) => {
                              return (
                                <li
                                  title="variant"
                                  className="listContItems"
                                  id="modelsListPara"
                                  key={index}
                                >
                                  <label className="listContVals">
                                    <input
                                      className="radio"
                                      checked={
                                        selectedCheckbox === index.toString()
                                      }
                                      id={index.toString()}
                                      type="checkbox"
                                      value={e.toString()}
                                      onClick={() => toggleGroupsAccordian()}
                                      onChange={(event) => {
                                        setselectedModelClass(
                                          event.target.value
                                        );
                                        handleClickedGroup(e);
                                        handleActiveCheckBox(index.toString());
                                      }}
                                    ></input>
                                    {e}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </IonAccordion>
                        <button
                          id="closeAdditonalFilters"
                          onClick={() => setAdditional(false)}
                        >
                          CLOSE
                        </button>
                      </IonAccordionGroup>
                    </div>
                  </div>
                )}
                <div className="options">
                  <div className="optChild">
                    <div className="field">
                      <span className="">
                        MIN PRICE
                        <input
                          type="text"
                          value={sliderValues[0]}
                          className="minInput priceInput"
                          onClick={() => toggleTransAccordian()}
                        />
                      </span>
                    </div>
                    <div className="field">
                      <span>
                        MAX PRICE
                        <input
                          type="text"
                          value={sliderValues[1]}
                          className="maxInput priceInput"
                          onClick={() => toggleTransAccordian()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="sliderCont Slidertop">
                    <ReactSlider
                      onChange={setSliderValues}
                      value={sliderValues}
                      min={MINSLIDER}
                      max={MAXSLIDER}
                    />
                  </div>
                </div>

                <div className="options">
                  <div className="optChild">
                    <div className="field">
                      <span>
                        MIN KW
                        <input
                          className="minInput priceInput"
                          value={selectedKw[0]}
                          type="number"
                        />
                      </span>
                    </div>
                    <div className="field">
                      <span>
                        MAX KW
                        <input
                          className="minInput priceInput"
                          value={selectedKw[1]}
                          type="number"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="sliderCont SliderTop">
                    <ReactSlider
                      onChange={setSelectedKW}
                      value={selectedKw}
                      min={0}
                      max={1000}
                    />
                  </div>
                </div>

                <div className="options">
                  <div className="optChild">
                    <div className="field">
                      <span>
                        MIN NM
                        <input
                          className="minInput priceInput"
                          value={selectedNm[0]}
                          type="number"
                        />
                      </span>
                    </div>
                    <div className="field">
                      <span>
                        MAX NM
                        <input
                          className="minInput priceInput"
                          value={selectedNm[1]}
                          type="number"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="sliderCont SliderTop">
                    <ReactSlider
                      onChange={setSelectedNm}
                      value={selectedNm}
                      min={0}
                      max={1000}
                    />
                  </div>
                </div>

                <div className="options">
                  <div className="optChild">
                    <div className="field" id="displacementField">
                      <span>
                        DISPLCEMENT
                        <input
                          className="minInput priceInput"
                          id="displcement"
                          value={selectedDisplacement}
                          type="number"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="sliderCont SliderTop">
                    <ReactSlider
                      onChange={setSelectedDisplacement}
                      value={selectedDisplacement}
                      min={MINDIS}
                      max={MAXDIS}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="paarambuttonContainer">
              <button className="filterBtns" onClick={onResetFilters}>
                RESET FILTER
              </button>
              <button className="filterBtns search" onClick={onSearch}>
                SEARCH
              </button>
            </div>
          </div>
        ) : (
          <div className="topSearch">
            <div className="garageBackNav LeftHeading">
              <div className="IconGarageback">
                <IonIcon
                  icon={chevronBackCircleSharp}
                  className=""
                  onClick={() => onResetFilters()}
                ></IonIcon>
              </div>
              <div className="GaragePageTitle">
                <span className="GaragePageTitle">SEARCH RESULTS</span>
              </div>
            </div>
            <div className="resultCont">
              <ParameterResultView
                viewType={viewType}
                searchResults={searchResults}
              ></ParameterResultView>
            </div>
          </div>
        )}
      </div>
    </IonModal>
  );
};
export default ParameterSearch;
