import { IonCol, IonModal, IonRow, IonIcon, IonGrid } from "@ionic/react";
import "./style.css";
import "../../Global.css";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { icons, images } from "../../assets";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

interface ShareSocialProps {
  isVisible: boolean;
  onClosed: () => void;
}

const ShareSocial: React.FC<ShareSocialProps> = ({
  isVisible,
  onClosed,
}: ShareSocialProps) => {
  const fixedText = `Hey there!\n\nAre you dreaming of purchasing the perfect vehicle? Check out MAPO's awesome mobile app at https://mobile.mapo-int.com.\n\nMy advice to you... Stay informed, plan effectively, and approach your next vehicle purchase with confidence. This app is packed with amazing features. I easily researched the vehicle that perfectly matched my needs. Effortlessly access precise and up-to-date service costs, maintenance expenses, total cost of ownership, residual values, and even parts pricing — all conveniently from my phone!\n\nAlso, have a look at this YouTube video that shows some of the amazing features included in the app: https://youtu.be/kZyboi_vQZY\n\nYour dream car is closer than you think. Get the MAPO mobile app now.\n\nCheers`;

  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextToShare(e.target.value);
  };

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <>
      {/* {isDesktop? (
      <IonModal className="Desktopmods" onDidDismiss={onClosed} isOpen={isVisible}>
         <div className="shareCont">
          <p className="DesktopTitle"> SHARE THIS APP</p>
          <span className="DesktopScanSection ">
          
            <h6>SCAN:</h6>
            <p>
              Use a mobile device and scan the QR code to direct to the MAPO
              Mobile app
            </p>
          </span>
          <img src={icons.newShare} className="DesktopBarcode-img" />
          <br />
          <span className="DesktopScanSection margintop">
            <h6>SOCIAL MEDIA:</h6>
            <p>
              Select the platform you wish to share details of the MAPO Mobile
              app on.
            </p>
          </span>

          <div className="DesktopallIconHolder">
            <div className="DesktopIcon">
              <LinkedinShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newLinkedin} className="DesktopMenuImg" />
              </LinkedinShareButton>
            </div>
            <div className="DesktopIcon">
              <EmailShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newMail} className="DesktopMenuImg" />
              </EmailShareButton>
            </div>
            <div className="DesktopIcon">
              <div>
                <img
                  src={icons.mnewWhatsApp}
                  className="DesktopMenuImg"
                  onClick={handleShareClick}
                />
              </div>
            </div>
            <div className="DesktopIcon">
              <FacebookShareButton
                url={"https://mobile.mapo-int.com/"}
                quote={"MAKE THE INFORMED DECISION"}
                hashtag="#muo"
                className="img"
              >
                <img src={icons.newFacebook} className="DesktopMenuImg" />
              </FacebookShareButton>
            </div>

            <div className="DesktopIcon">
              <TwitterShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newTwittere} className="DesktopMenuImg" />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </IonModal>): (
      <> */}

      <IonModal className="mods" onDidDismiss={onClosed} isOpen={isVisible}>
        <IonGrid id="shareCont">
          <IonRow>
            <IonCol size="12" id="shareHeading">
              SHARE THIS APP
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" id="shareSectionTitle">
              SCAN:
            </IonCol>
            <IonCol size="12" id="shareSectionText">
              Use a mobile device and scan the QR code to direct to the MAPO
              Mobile app.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" id="shareSectionBarcode">
              <img src={icons.newShare} style={{ width: "80%" }} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" id="shareSectionTitle">
              SOCIAL MEDIA:
            </IonCol>
            <IonCol size="12" id="shareSectionText">
              Select the platform you wish to share details of the MAPO Mobile
              app on.
            </IonCol>
          </IonRow>
          <IonRow className="shareSectionIcons">
            <IonCol>
              {" "}
              <LinkedinShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newLinkedin} className="shareSectionImg" />
              </LinkedinShareButton>
            </IonCol>
            <IonCol>
              <EmailShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newMail} className="shareSectionImg" />
              </EmailShareButton>
            </IonCol>
            <IonCol>
              <img
                src={icons.mnewWhatsApp}
                className="MenuImg"
                onClick={handleShareClick}
              />
            </IonCol>
            <IonCol>
              <FacebookShareButton
                url={"https://mobile.mapo-int.com/"}
                quote={"MAKE THE INFORMED DECISION"}
                hashtag="#muo"
                className="img"
              >
                <img src={icons.newFacebook} className="MenuImg" />
              </FacebookShareButton>
            </IonCol>
            <IonCol>
              <TwitterShareButton url={"https://mobile.mapo-int.com/"}>
                <img src={icons.newTwittere} className="MenuImg" />
              </TwitterShareButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </>
    //   )}
    // </>
  );
};

export default ShareSocial;
