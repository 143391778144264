import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { desktopImages, icons, images } from "../../../assets";
import Button from "../../../components/button";
import DesktopContainer from "../../../components/desktop-container";
import Header from "../../../components/header";
import MessageModal from "../../../components/message-modal";
import SideMenu from "../../../components/side-menu";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens, Format } from "../../../enums";
import "../../../Global.css";
import { RootState } from "../../../redux/store";
import {
  hideLoader,
  hideSideMenu,
  setFilteredSearchVisible,
  setLargeHeader,
  setScreen,
  setShareSocialVisible,
  setUndentifiedVinSearchVisible,
  showLoader,
  showSideMenu,
} from "../../../redux/ui/uiSlice";
import { DataCompleteness, SelectedVehicle } from "../../../interfaces";
import {
  Attributes,
  ModelSpecification,
} from "../../../interfaces/modelSpecification";
import { useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import DesktopHeader from "../../../components/desktop-header";
import DesktopVehicleLabel from "../../../components/desktop-vehicle-label";
import { CompetitorData } from "../../../interfaces/competitorData";
import DesktopChartLegend from "../../../components/desktop-chart-legend";
import { format } from "../../../utils";
import { VehicleCustomReportParams } from "../../../interfaces/customReportParams";
import Tutorial from "../../Tutorial";
import axios from "axios";
import { sessionLogout } from "../../../redux/session/sessionSlice";
import {
  addVehicle,
  createTransaction,
  dataCompleteness,
  getImage,
  modelClass,
  modelSpecificationDetails,
  queryTransaction,
} from "../../../api/mapo";
import { Config } from "../../../Config";
import { Transaction } from "../../../interfaces/transaction";
import { ServicePlan } from "../../../interfaces/servicePlanTotals";
import {
  chevronBackCircleSharp,
  cartSharp,
  chevronDown,
  chevronForward,
  thumbsUpSharp,
} from "ionicons/icons";
import moment from "moment";
import { partsInfo } from "../../../interfaces/maintenancePlanItems";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}

const ServicePlans: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const [showServiceData, setShowServiceData] = useState(true);
  const [showPlan, setShowPlan] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showBarDetail, setShowBarDetail] = useState(false);
  const [showLineDetail, setShowLineDetail] = useState(false);
  const [kilos, setKilos] = useState(Number);
  const [months, setMonths] = useState(Number);
  const [serviceType, setServiceTypes] = useState<string>("");
  const [activeText, setActiveText] = useState("");
  const [showServiceItem, setShowServiceItem] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [completeness, setCompleteness] = useState<
    DataCompleteness | undefined
  >();
  const [modelSpecification, setModelSpecification] = useState<
    ModelSpecification | undefined
  >();

  const handleIntervalClick = (interval: any) => {
    setSelectedInterval(selectedInterval === interval ? null : interval);
  };
  const [kmFromSlider, setKmFromSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result
          ?.servicePlans[0].interval
      : 0
  );
  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
            .length - 1
        ].interval
      : 0
  );
  const [kmFromSlide, setKmFromSlide] = useState(
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[0]
          .attributes?.interval
      : 0
  );
  const [kmToSlide, setKmToSlide] = useState(
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[
          uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
            .length - 1
        ].attributes?.interval
      : 0
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push("/OverViewDetail");
  }, [dispatch]);

  // const onAddToGarage = useCallback(() => {
  //   if (sessionState.user && uiState.selectedVehicle) {
  //     dispatch(showLoader());
  //     addVehicle(
  //       sessionState.user?.username ?? "",
  //       uiState.selectedVehicle?.g_id,
  //       uiState.selectedVehicle?.m_id,
  //       uiState.selectedVehicle?.v_id
  //     )
  //       .then((addVehicleResponse) => {
  //         dispatch(hideLoader());
  //         if (addVehicleResponse.status === 200) {
  //           //dispatch(setScreen(AppScreens.Garage));
  //           history.push('/Garage');
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(hideLoader());
  //         alert(JSON.stringify(err));
  //       });
  //   }
  // }, [dispatch, sessionState.user, uiState.selectedVehicle]);

  //To save the selectedVehicle in the cart
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const handleSaveSelectedVehicleToCart = () => {
    if (uiState.selectedVehicle) {
      // Retrieve existing cart data from localStorage
      const storedCartString = localStorage.getItem("cart");
      const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

      // Check if the item with the same 'gmv' is already in the cart
      const isDuplicate = existingCart.some(
        (item: any) => item.variant === uiState.selectedVehicle?.variant
      );

      if (!isDuplicate) {
        // Add the current model to the cart
        existingCart.push(uiState.selectedVehicle);

        localStorage.setItem("cart", JSON.stringify(existingCart));
        // Show the "Item added to cart" message
        showMessageWithTimeout("Model added to cart");
      } else {
        // Show the "Item already in cart" message
        showMessageWithTimeout("Model already in cart");
      }
    }
  }; //text doesn't go away it just stays there

  useEffect(() => {
    // Hide the message after 3 seconds (adjust as needed)
    const timeoutId = setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, 3000);

    return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or state change
  }, [showMessage]);

  const showMessageWithTimeout = (text: any) => {
    setMessage(text);
    setShowMessage(true);
  };

  useEffect(() => {
    setKilos(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan
      )
    );

    setMonths(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan_months
      )
    );

    setServiceTypes(
      JSON.stringify(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan_type
      ).replace(/"/g, "")
    );
  }, [uiState.selectedVehicle, kilos, months, serviceType]);

  useEffect(() => {
    if (showServiceData == false) {
      setActiveText("DEACTIVATED");
    } else {
      setActiveText("ACTIVATED");
    }
  }, [activeText, showServiceData]);

  //logic for filtering
  const [allPlans, setAllPlans] = useState<Array<ServicePlan>>();
  const [allPlanInfo, setPlanInfo] = useState<Array<partsInfo>>();

  useEffect(() => {
    setAllPlans(
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    );
    setPlanInfo(
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
    );
  }, [uiState]);

  const intervalsInfo: Array<ServicePlan> = [];
  const [intervalValues, setIntervalValues] = useState<ServicePlan>();

  const filterByInterval = (plan: ServicePlan) => {
    allPlans?.forEach((val, index) => {
      if (val.interval === plan.interval) {
        setIntervalValues(plan);
      }
      return intervalValues;
    });

    setShowServiceItem(!showServiceItem);
  };

  //Model Date
  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  //Data completeness
  useEffect(() => {
    if (completeness === undefined && uiState.selectedVehicle) {
      dispatch(showLoader());
      dataCompleteness(
        uiState.selectedVehicle?.g_id,
        uiState.selectedVehicle?.m_id,
        uiState.selectedVehicle?.v_id
      )
        .then((dataCompletenessResponse) => {
          if (
            dataCompletenessResponse.status === 200 &&
            uiState.selectedVehicle
          ) {
            const data: DataCompleteness = dataCompletenessResponse.data;
            setCompleteness(data);
            modelSpecificationDetails(
              uiState.selectedVehicle?.g_id,
              uiState.selectedVehicle?.m_id,
              uiState.selectedVehicle?.v_id
            )
              .then((modelClassResponse) => {
                if (modelClassResponse.status === 200) {
                  setModelSpecification(modelClassResponse.data);
                  dispatch(hideLoader());
                }
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert(JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
            alert(dataCompletenessResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [completeness, dispatch, uiState.selectedVehicle]);
  const hasData = useCallback(
    (type: string) => {
      switch (type) {
        case "serviceChecks": {
          if (
            ((Number(completeness?.serviceChecks) /
              Number(completeness?.serviceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLabour": {
          if (
            ((Number(completeness?.serviceLabour) /
              Number(completeness?.serviceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLubes": {
          if (
            ((Number(completeness?.serviceLubes) /
              Number(completeness?.serviceLubes_Max)) *
              100 ?? 0) > 70
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceParts": {
          if (
            ((Number(completeness?.serviceParts) /
              Number(completeness?.serviceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceChecks": {
          if (
            ((Number(completeness?.maintenanceChecks) /
              Number(completeness?.maintenanceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceLabour": {
          if (
            ((Number(completeness?.maintenanceLabour) /
              Number(completeness?.maintenanceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceParts": {
          if (
            ((Number(completeness?.maintenanceParts) /
              Number(completeness?.maintenanceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    [completeness]
  );

  const onToggleMenu = useCallback(() => {
    if (!uiState.sideMenuOpen) {
      dispatch(showSideMenu());
    } else {
      dispatch(hideSideMenu());
    }
  }, [dispatch, uiState.sideMenuOpen]);

  // const onLogout = useCallback(() => {
  //   dispatch(setLargeHeader(false));
  //   dispatch(hideSideMenu());
  //   dispatch(sessionLogout());
  //   dispatch(setScreen(AppScreens.Login));
  // }, [dispatch]);

  const onHome = useCallback(() => {
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Home));
    //history.push("/home");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));

    setTimeout(() => {
      window.location.reload();
      history.push("/home");
    }, 500);
  }, [dispatch]);

  const onGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Garage));
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.VehicleLanding));
      history.push("/Garage");
    }, 500);
    // history.push("/Garage");
  }, [dispatch]);

  const onTutorial = useCallback(() => {
    //dispatch(setScreen(AppScreens.Tutorial));
    //history.push("/Tutorial");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Tutorial");
    }, 500);
  }, [dispatch]);

  const onAbout = useCallback(() => {
    //dispatch(setScreen(AppScreens.About));
    //history.push("/About");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/About");
    }, 500);
  }, [dispatch]);

  const Myprofile = useCallback(() => {
    //dispatch(setScreen(AppScreens.UserProfile));
    // history.push("/UserProfile");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/UserProfile");
    }, 500);
  }, [dispatch]);

  const onSupport = useCallback(() => {
    // dispatch(setScreen(AppScreens.Support));
    // history.push("/Support");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Support");
    }, 500);
  }, [dispatch]);

  const onContact = useCallback(() => {
    //dispatch(setScreen(AppScreens.Contact));
    //history.push("/Contact");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Contact");
    }, 500);
  }, [dispatch]);

  const OnShare = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    dispatch(setShareSocialVisible(true));
  }, [dispatch]);

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    //history.push("/login");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  const OnCart = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Cart");
    }, 500);
  }, [dispatch]);
  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  const sideMenuItems = [
    {
      title: "model search",
      func: onHome,
      icon: icons.mobileSearchIcon,
    },
    {
      title: "my garage",
      func: onGarage,
      icon: icons.myGarageIcon,
    },
    {
      title: "my profile",
      func: Myprofile,
      icon: icons.userProfileIcon,
    },
    {
      title: "SUPPORT",
      func: undefined,
      icon: undefined,
    },
    {
      title: "app tutorial",
      func: onTutorial,
      icon: icons.appTutorialIcon,
    },
    {
      title: "MAPO ",
      func: onAbout,
      icon: icons.mobileMapoIcon,
    },
    {
      title: "CONTACT US",
      func: onContact,
      icon: icons.appContactIcon,
    },
    {
      title: "Share ",
      func: OnShare,
      icon: icons.appShareIcon,
    },
    {
      title: " ",
      func: undefined,
      icon: undefined,
    },
    {
      title: "Log out ",
      func: onLogout,
      icon: icons.appLeaveIcon,
    },
  ];

  const renderSideMenu = useCallback(() => {
    return (
      <>
        {uiState.sideMenuOpen ? (
          <div className="desktopSideMenu">
            {sideMenuItems?.map((value, index) => {
              return (
                <div className="MenuItem" onClick={value.func}>
                  <span className="MenuImg" onClick={value.func}>
                    {value.icon ? (
                      <img onClick={value.func} src={value.icon} />
                    ) : (
                      <></>
                    )}
                  </span>
                  <span onClick={value.func}>{value.title}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }, [
    uiState.sideMenuOpen,
    onHome,
    onGarage,
    onToggleMenu,
    onAbout,
    onTutorial,
    onContact,
    onSupport,
    sessionState.user,
  ]);

  const onDetailedSpecs = useCallback(() => {
    //dispatch(setScreen(AppScreens.Specs));
    history.push("/DetailSpecs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Services));
    history.push("/ServicePlan");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Maintenance));
    history.push("/MaintenancePlan");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    //dispatch(setScreen(AppScreens.TCO));
    history.push("/TotalCostOwner");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    //dispatch(setScreen(AppScreens.Parts));
    history.push("/Basket");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    //dispatch(setScreen(AppScreens.Rates10K));
    history.push("/RatesTenK");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitors));
    history.push("/Competitor");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    // dispatch(setScreen(AppScreens.Reviews));
    history.push("/Reviews");
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResidualValue));
    history.push("/ResellValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculator));
    history.push("/FinanceCalculate");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    //dispatch(setScreen(AppScreens.Preowned));
    // history.push("/Preowned");
  }, [dispatch]);

  const menuLoadText = [
    "SERVICE SCHEDULES",
    "MAINTENANCE SCHEDULES",
    "TCO INFORMATION",
    "DETAILED SPECIFICATIONS",
    "PARTS BASKET",
    "10K RATES",
    "RESIDUAL VALUE",
  ];

  const [menuLoadTextIndex, setMenuLoadTextIndex] = useState(0);
  const [menuLoadTextDisplayer, setMenuLoadTextDisplayer] =
    useState(" LOADING MENUS...");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the menuLoadTextDisplayer with the current menu item
      setMenuLoadTextDisplayer(menuLoadText[menuLoadTextIndex]);

      // Move to the next menu item index
      setMenuLoadTextIndex(
        (prevIndex) => (prevIndex + 1) % menuLoadText.length
      );
    }, 1000);

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [menuLoadTextIndex, menuLoadText]);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const pageTilte = "SERVICE COST";
  return (
    <>
      <GlobalGrid
        currentPageTitle="SERVICE PLAN"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <div className="headHolder HeaderStick">
            <IonRow id="selectedVehicleGlobal">
              {uiState.selectedVehicle ? (
                <>
                  <IonCol
                    sizeXs="6"
                    sizeMd="4"
                    sizeLg="3"
                    id="gridOverviewImageCont"
                  >
                    <img
                      id="gridOverviewImage"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />{" "}
                  </IonCol>

                  <IonCol
                    sizeXs="6"
                    sizeMd="8"
                    sizeLg="9"
                    id="gridOverviewDetails"
                  >
                    <h1>{uiState.selectedVehicle.make}</h1>
                    <h1>{uiState.selectedVehicle.model}</h1>
                    <h1 id="gridOverviewVariant">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <>{details.variant}</>
                        ))}
                    </h1>
                    <span id="overViewCluster">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <h1 key={index}>
                            MY{" "}
                            {moment(new Date(details.introDate ?? "")).format(
                              "YYYY"
                            )}
                          </h1>
                        ))}
                      <h1>
                        G{uiState.selectedVehicle.g_id.toString()}M
                        {uiState.selectedVehicle.m_id.toString()}V
                        {uiState.selectedVehicle.v_id.toString()}
                      </h1>
                      <h1>{uiState.selectedVehicle.variant_id}</h1>{" "}
                    </span>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </div>

          <div className="DataCompleteness-sec">
            {/* <span className="AddVehicleText">
                <strong>
                  Add Vehicle in Garage <br />
                  To View Service Costs
                </strong>//
              </span> */}

            <p>
              <strong className="FontMobileSpcs MapoDataReport">
                MAPO DATA REPORT
              </strong>
              <br />
              <span className="FontMobileSpcs">
                The following data is available on the searched
              </span>
            </p>
            <br />

            <>
              {hasData("serviceChecks") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.serviceChecks} alt="loading" />
                  </span>
                  <p> Service checks data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.serviceChecks} alt="loading" />
                  </span>
                  <p> Service checks data unavailable</p>
                </IonCol>
              )}

              {hasData("serviceLabour") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.serviceLabour} alt="loading" />
                  </span>
                  <p> Service labour data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.serviceLabour} alt="loading" />
                  </span>
                  <p> Service labour data unavailable</p>
                </IonCol>
              )}

              {hasData("serviceLubes") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.serviceLube} alt="loading" />
                  </span>
                  <p> Fluids data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.serviceLube} alt="loading" />
                  </span>
                  <p> Fluids data unavailable</p>
                </IonCol>
              )}

              {hasData("serviceChecks") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.serviceChecks} alt="loading" />
                  </span>
                  <p> Service parts data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.serviceChecks} alt="loading" />
                  </span>
                  <p> Service parts data unavailable.</p>
                </IonCol>
              )}

              {hasData("serviceChecks") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.maintenanceChecks} alt="loading" />
                  </span>
                  <p>Replacement parts data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.maintenanceChecks} alt="loading" />
                  </span>
                  <p> Replacement parts data unavailable</p>
                </IonCol>
              )}

              {hasData("maintenanceLabour") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.maintenanceLabour} alt="loading" />
                  </span>
                  <p> Maintenance labour data available</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.maintenanceLabour} alt="loading" />
                  </span>
                  <p> Maintenance labour data unavailable</p>
                </IonCol>
              )}

              {hasData("maintenanceParts") ? (
                <IonCol size="12" className="completenesLabels">
                  <span className="greenData">
                    <IonImg src={icons.maintenanceParts} alt="loading" />
                  </span>
                  <p> Maintenance parts data available.</p>
                </IonCol>
              ) : (
                <IonCol size="12" className="completenesLabels">
                  <span className="redData">
                    <IonImg src={icons.maintenanceParts} alt="loading" />
                  </span>
                  <p> Maintenance parts data unavailable.</p>
                </IonCol>
              )}
            </>
            <br />
           
          </div>
        </div>

        <IonRow className="btnServiceOpt">
          {/* <button onClick={onAddToGarage} className="GarageIconBtn">
              ADD TO GARAGE
            </button> */}

          <button
            onClick={handleSaveSelectedVehicleToCart}
            id="addToCartCompetitors"
          >
            Add to Cart
            <img
              id="icon"
              src="/static/media/WhiteIconCart(500x500) 1.77adbbb3129a159a85ef.png"
            ></img>{" "}
          </button>
        </IonRow>

        {showMessage ? (
          <div className="addToCartAlertCont">
            <div className="thumb">
              <IonIcon src={thumbsUpSharp}></IonIcon>
            </div>
            <p>{message}</p>
          </div>
        ) : null}
      </GlobalGrid>
    </>
  );
};

export default ServicePlans;
