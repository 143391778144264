import {
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonModal,
  IonPage,
} from "@ionic/react";
import "./style.css";
import Button from "../../components/button";
import "../../Global.css";
import { useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import { chevronBackCircleSharp, trash } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router";
import { getImage, promoCodes } from "../../api/mapo";
import { hideLoader, showLoader } from "../../redux/ui/uiSlice";
import { format } from "../../utils";
import { Format } from "../../enums";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import "bootstrap/dist/css/bootstrap.css";

interface PaymentProps {
  onClose: () => void;
  onPaymentCofirmed: () => void;
  isVisible: boolean;
  cartTotal: number;
  savedCart: any;
  selectedOptions: any;
  garageCostResponses: any;
  updateDiscountPercentage: (discountPercentage: number) => void;
}

interface PromoCodeDetail {
  isValid: boolean;
  discountPercentage: number;
  message: string;
  gmv: any;
  g_id: string;
  m_id: string;
  v_id: string;
}

const Payment: React.FC<PaymentProps> = ({
  onClose,
  onPaymentCofirmed,
  isVisible,
  savedCart,
  cartTotal,
  selectedOptions,
  garageCostResponses,
  updateDiscountPercentage,
}: PaymentProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const [promoCodeDetail, setPromoCodeDetail] = useState<PromoCodeDetail[]>([]);
  const [discountPercentage, setdiscountPercentage] = useState(0);

  const [PromoMessage, setPromoMessage] = useState("");
  const userName = sessionState.user?.username ?? "0";
  const [productName, setProductName] = useState("Mapo-App-Purchase");

  const [CheckoutTotal, setCheckoutTotal] = useState(0);
  const [UserEmail, setUserEmail] = useState("");
  const [payFastview, setpayFastview] = useState("");
  const [loading, setLoading] = useState(false);
  const [PromoG_id, setG_id] = useState<number>(0);
  const [PromoM_id, setM_id] = useState<number>(0);
  const [PromoV_id, setV_id] = useState<number>(0);

  console.log("The checkout amount", CheckoutTotal);

  const handlePayment = async () => {
    if (CheckoutTotal == 0) {
      onPaymentCofirmed();
    } else if (CheckoutTotal > 1) {
      setLoading(true);
      try {
        await initiatePayment();
      } catch (error) {
        console.error("Error handling payment:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const initiatePayment = async () => {
    dispatch(showLoader());
    try {
      const response = await fetch(
        "https://mapo-payfast-app-eosin.vercel.app/initiate-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productName,
            CheckoutTotal,
            UserEmail,
          }),
        }
      );

      if (response.ok) {
        const paymentData = await response.json();
        //console.log("Initiated payment:", paymentData);

        setpayFastview(paymentData.paymentId.uuid);
      } else {
        throw new Error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  //The payfast to active the modal to pay
  useEffect(() => {
    if (payFastview) {
      // Remove existing viewport meta tags
      const existingViewportMetaTags = document.head.querySelectorAll(
        'meta[name="viewport"]'
      );
      existingViewportMetaTags.forEach((tag) => tag.remove());

      // Add viewport meta tag for PayFast
      const payFastMeta = document.createElement("meta");
      payFastMeta.name = "viewport";
      payFastMeta.content = "width=device-width, initial-scale=1.0";
      document.head.appendChild(payFastMeta);

      const script = document.createElement("script");
      script.src = "https://www.payfast.co.za/onsite/engine.js";
      script.async = true;
      script.onload = () => {
        // @ts-ignore
        window.payfast_do_onsite_payment(
          { uuid: payFastview },
          function (result: any) {
            if (result === true) {
              // Payment Completed
              onPaymentCofirmed();
              payFastMeta.content = "width=device-width, initial-scale=0.4";
            } else {
              // Payment Window Closed
              payFastMeta.content = "width=device-width, initial-scale=0.4";
            }
          }
        );
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        // Remove PayFast viewport meta tag
        document.head.removeChild(payFastMeta);
      };
    }
    // Set user email
    setUserEmail(sessionState.user?.username ?? "");
  }, [payFastview]);

  // console.log("The total checkout total to payfast", CheckoutTotal);
  const dispatch = useDispatch();

  const onClosePress = useCallback(() => {
    onClose();
  }, [onClose]);

  const onBackPress = useCallback(() => {
    onClose();
  }, []);

  const [enteredPromo, setEnteredPromo] = useState("");
  const [g, setg] = useState(0);
  const [m, setm] = useState(0);
  const [v, setv] = useState(0);

  //handlePromo
  const renderCost = (selectedOptions: any, data: any) => {
    switch (selectedOptions) {
      case "1":
        return data.cost_Per_Day;
      case "7":
        return data.cost_Per_Week;
      case "30":
        return data.cost_Per_Month;
      case "365":
        return data.cost_Per_Year;
      default:
        return "";
    }
  };
  // useEffect(() => {
  //   {
  //     promoCodeDetail.length > 0 &&
  //       setdiscountPercentage(promoCodeDetail[0].discountPercentage);
  //   }
  // }, [promoCodeDetail]);

  useEffect(() => {
    if (promoCodeDetail.length > 0) {
      if (promoCodeDetail[0].isValid) {
        setdiscountPercentage(promoCodeDetail[0].discountPercentage);
        setG_id(Number(promoCodeDetail[0].g_id));
        setM_id(Number(promoCodeDetail[0].m_id));
        setV_id(Number(promoCodeDetail[0].v_id));
      } else {
        setdiscountPercentage(0);
      }
    }
  }, [promoCodeDetail]);

  const handleApplyPromo = useCallback(async () => {
    dispatch(showLoader());

    try {
      const response = await promoCodes(enteredPromo, userName, g, m, v);
      if (response.status === 200) {
        const promoData = response.data;
        const promoDetails = {
          isValid: promoData.isValid,
          discountPercentage: promoData.discountPercentage,
          message: promoData.message,
          gmv: promoData.gmv,
          g_id: promoData.g_id,
          m_id: promoData.m_id,
          v_id: promoData.v_id,
        };
        setPromoCodeDetail([promoDetails]);
      } else {
        // Handle other responses
      }
    } catch (error) {
      // Handle error
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch, enteredPromo, userName, g, m, v, CheckoutTotal]);

  const handleInputChange = (event: any) => {
    setEnteredPromo(event.target.value);
  };

  const calculateDiscountedCost = (cost: any) => {
    return cost - (discountPercentage / 100) * cost; // Apply discount percentage
  };

  let discountApplied = false;
  let totalCost = 0;

  useEffect(() => {
    //Converted to 2 decimal places
    const roundedTotalCost = Math.round(totalCost * 100) / 100;
    setCheckoutTotal(roundedTotalCost);
  }, [totalCost]);

  useEffect(() => {
    // Update the discount percentage state
    setdiscountPercentage(discountPercentage);

    // Call the callback function to pass the updated discount percentage to the parent component
    updateDiscountPercentage(discountPercentage);
  }, [discountPercentage]);
  const isPromoApplied =
    promoCodeDetail.length > 0 && promoCodeDetail[0].isValid;

  useEffect(() => {
    let totalCost = 0;
    let discountApplied = false;

    savedCart.forEach(
      (
        savedModel: {
          groupCode: any;
          g_id: any;
          modelCode: number;
          m_id: number;
          varientCode: number;
          v_id: number;
        },
        index: string | number
      ) => {
        garageCostResponses
          .filter(
            (data: { gCode: any; mCode: any; vCode: any }) =>
              data.gCode === savedModel.groupCode ||
              (data.gCode === savedModel.g_id &&
                data.mCode === savedModel.modelCode) ||
              (data.mCode === savedModel.m_id &&
                data.vCode === savedModel.varientCode) ||
              data.vCode === savedModel.v_id
          )
          .forEach((filteredData: any, innerIndex: number) => {
            const costBeforeDiscount = renderCost(
              selectedOptions[index],
              filteredData
            );

            let discountedCost;
            if (
              (PromoM_id === savedModel.m_id ||
                PromoM_id === savedModel.modelCode) &&
              (PromoV_id === savedModel.v_id ||
                PromoV_id === savedModel.varientCode)
            ) {
              discountedCost = !discountApplied
                ? ((discountApplied = true),
                  calculateDiscountedCost(costBeforeDiscount))
                : costBeforeDiscount;
            } else if (
              innerIndex === 0 &&
              PromoG_id === 0 &&
              PromoM_id === 0 &&
              PromoV_id === 0
            ) {
              discountedCost = !discountApplied
                ? ((discountApplied = true),
                  calculateDiscountedCost(costBeforeDiscount))
                : costBeforeDiscount;
            } else {
              discountedCost = costBeforeDiscount;
            }

            totalCost += discountedCost;
          });
      }
    );

    setCheckoutTotal(Math.round(totalCost * 100) / 100);
  }, [
    garageCostResponses,
    savedCart,
    PromoG_id,
    PromoM_id,
    PromoV_id,
    renderCost,
    calculateDiscountedCost,
  ]);

  return (
    <>
      {isVisible && (
        <IonPage>
          <GlobalGrid
            currentPageTitle="PAYMENT"
            closeCurrentPage={() => onBackPress()}
          >
            <br/>
            <p className="Head-txt-payment text-left">
          <b>Purchasing comprehensive data for vehicles</b> 
         </p>
            <div className="paymentSection">
              
              <table className="paymentSummarySection">
                <thead>
                  <tr>
                    <td>GMV</td>
                    <td>Group</td>
                    <td>Model</td>
                    <td>Variant</td>
                    <td>Price</td>
                  </tr>
                </thead>
                <tbody>
                  {savedCart.map((savedModel: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          G{savedModel.g_id || savedModel.groupCode}M
                          {savedModel.m_id || savedModel.modelCode}V
                          {savedModel.v_id || savedModel.variantCode}
                        </td>
                        <td>{savedModel.g_desc || savedModel.make}</td>

                        <td>
                          {((savedModel.m_desc &&
                            savedModel.m_desc.slice(0, 10)) ||
                            (savedModel.model &&
                              savedModel.model.slice(0, 10))) +
                            (savedModel.m_desc && savedModel.m_desc.length > 10
                              ? "..."
                              : "") +
                            (savedModel.model && savedModel.model.length > 10
                              ? "..."
                              : "")}
                        </td>
                        <td>
                          {savedModel.v_desc?.slice(0, 10) ||
                            savedModel.variant?.slice(0, 10) +
                              (savedModel.v_desc?.length > 10 ? "..." : "") +
                              (savedModel.variant?.length > 10 ? "..." : "")}
                        </td>

                        {garageCostResponses
                          .filter(
                            (data: any) =>
                              data.gCode === savedModel.groupCode ||
                              (data.gCode === savedModel.g_id &&
                                data.mCode === savedModel.modelCode) ||
                              (data.mCode === savedModel.m_id &&
                                data.vCode === savedModel.varientCode) ||
                              data.vCode === savedModel.v_id
                          )
                          .map((filteredData: any, innerIndex: any) => {
                            // Calculate the cost before discount
                            const costBeforeDiscount = renderCost(
                              selectedOptions[index],
                              filteredData
                            );

                            let discountedCost;
                            const model = savedModel.modelCode;
                            // Check if PromoG_id, PromoM_id, and PromoV_id match specific values
                            if (
                              (PromoM_id === savedModel.m_id ||
                                PromoM_id === savedModel.modelCode) &&
                              (PromoV_id === savedModel.v_id ||
                                PromoV_id === savedModel.varientCode)
                            ) {
                              // Apply discount only to the specific items matching the Promo values
                              discountedCost = !discountApplied
                                ? ((discountApplied = true),
                                  calculateDiscountedCost(costBeforeDiscount))
                                : costBeforeDiscount;
                            } else if (
                              innerIndex === 0 &&
                              PromoG_id === 0 &&
                              PromoM_id === 0 &&
                              PromoV_id === 0
                            ) {
                              // Apply discount only to the first item if Promo values are all 0

                              discountedCost = !discountApplied
                                ? ((discountApplied = true),
                                  calculateDiscountedCost(costBeforeDiscount))
                                : costBeforeDiscount;
                            } else {
                              // Otherwise, no discount applied
                              discountedCost = costBeforeDiscount;
                            }

                            totalCost += discountedCost;
                            const formattedCost = format(
                              discountedCost,
                              Format.Currency
                            );

                            return (
                              <td key={`filteredData-${innerIndex}`}>
                                {formattedCost}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <strong className="TotalCheckoutPrice">
                        {format(totalCost, Format.Currency)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />

              <div className="paymentCapture">
                <label htmlFor="#">
                  Promo Code?
                  <input type="text" onChange={handleInputChange} />
                  <button onClick={handleApplyPromo} disabled={isPromoApplied}>
                    Apply Promo
                  </button>
                  {promoCodeDetail.length > 0 && (
                    <p
                      style={{
                        fontSize: "30px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {promoCodeDetail[0].message}
                    </p>
                  )}
                  <button
                    onClick={handlePayment}
                    // onClick={onPaymentCofirmed}
                    className="paymentBtn"
                  >
                    Pay Now
                  </button>
                </label>
              </div>
            </div>
          </GlobalGrid>
        </IonPage>
      )}
    </>
  );
};

export default Payment;
