import { IonCol, IonInput, IonModal, IonRow } from '@ionic/react';
import './style.css';
import '../../Global.css';
import { useState, useCallback } from 'react';
import Button from '../../components/button';
import { useDispatch } from 'react-redux';
import {
  hideLoader,
  setCreateReportVisible,
  showLoader,
} from '../../redux/ui/uiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CreateReportData, ReportVehicle } from '../../interfaces/createReport';
import { VehicleCustomReportParams } from '../../interfaces/customReportParams';
import { createReport } from '../../api/mapo';

interface CreateReportProps {
  isVisible: boolean;
  onClosed: () => void;
}

const CreateReport: React.FC<CreateReportProps> = ({
  isVisible,
  onClosed,
}: CreateReportProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const [customerEmail, setCustomerEmail] = useState('');
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setCreateReportVisible(false));
  }, [dispatch]);

  const onCreateReport = useCallback(() => {
    if (customerEmail === '') {
      alert('Please enter the customers email address.');
    } else {
      const selectedVehicle: ReportVehicle = {
        GMV:
          'G' +
          uiState.selectedVehicle?.g_id +
          'M' +
          uiState.selectedVehicle?.m_id +
          'V' +
          uiState.selectedVehicle?.v_id,
        RetailPrice:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.retailprice ?? 0,
        Discount:
          uiState.customReportParams?.selectedVehicleCustomParams?.discount ??
          0,
        OptionalExtras:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.optionalextras ?? 0,
        Deposit:
          uiState.customReportParams?.selectedVehicleCustomParams?.deposit ?? 0,
        SubTotal:
          uiState.customReportParams?.selectedVehicleCustomParams?.subtotal ??
          0,
        CO2Tax:
          uiState.customReportParams?.selectedVehicleCustomParams?.co2tax ?? 0,
        VAT: uiState.customReportParams?.selectedVehicleCustomParams?.vat ?? 0,
        FinancedAmount:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.financeamount ?? 0,
        Balloon:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.baloonpayment ?? 0,
        StandardServicePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.servicestandardkm ?? 0,
        AdditionalServicePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.serviceadditionalkm ?? 0,
        AdditionalServicePlanCost:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.serviceadditionalcost ?? 0,
        StandardMaintenancePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenancestandardkm ?? 0,
        AdditionalMaintenancePlan:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenanceadditionalkm ?? 0,
        AdditionalMaintenancePlanCost:
          uiState.customReportParams?.selectedVehicleCustomParams
            ?.maintenanceadditionalcost ?? 0,
      };

      const competitors: Array<ReportVehicle> = [];
      uiState.customReportParams?.competitorCustomParams?.map(
        (customReportParams: VehicleCustomReportParams, index: number) => {
          if (uiState.competitors && uiState.competitors.length > index) {
            const competitorReport: ReportVehicle = {
              GMV:
                'G' +
                uiState.competitors[index].competitorBase?.g_id +
                'M' +
                uiState.competitors[index].competitorBase?.m_id +
                'V' +
                uiState.competitors[index].competitorBase?.v_id,
              RetailPrice: customReportParams?.retailprice ?? 0,
              Discount: customReportParams?.discount ?? 0,
              OptionalExtras: customReportParams?.optionalextras ?? 0,
              Deposit: customReportParams?.deposit ?? 0,
              SubTotal: customReportParams?.subtotal ?? 0,
              CO2Tax: customReportParams.co2tax ?? 0,
              VAT: customReportParams?.vat ?? 0,
              FinancedAmount: customReportParams?.financeamount ?? 0,
              Balloon: customReportParams?.baloonpayment ?? 0,
              StandardServicePlan: customReportParams?.servicestandardkm ?? 0,
              AdditionalServicePlan:
                customReportParams?.serviceadditionalkm ?? 0,
              AdditionalServicePlanCost:
                customReportParams?.serviceadditionalcost ?? 0,
              StandardMaintenancePlan:
                customReportParams?.maintenancestandardkm ?? 0,
              AdditionalMaintenancePlan:
                customReportParams?.maintenanceadditionalkm ?? 0,
              AdditionalMaintenancePlanCost:
                customReportParams?.maintenanceadditionalcost ?? 0,
            };
            competitors.push(competitorReport);
          }
        }
      );

      const createReportData: CreateReportData = {
        UserEmail: sessionState.user?.username ?? '',
        CustomerEmail: customerEmail,
        IncludeFinance: uiState.customReportParams?.toggles?.finance ?? true,
        DealType: uiState.customReportParams?.toggles?.finance
          ? 'Finance'
          : 'Cash',
        Lifespan: uiState.customReportParams?.totalLifeSpan ?? 0,
        AnnualMileage: uiState.customReportParams?.annualMileage ?? 0,
        InterestRate: uiState.customReportParams?.interest ?? 0,
        InterestTerm: uiState.customReportParams?.term ?? 0,
        IncludeService: uiState.customReportParams?.toggles?.service ?? true,
        IncludeMaintenance:
          uiState.customReportParams?.toggles?.maintenance ?? true,
        IncludeFuel: uiState.customReportParams?.toggles?.fuel ?? true,
        IncludeResidual:
          uiState.customReportParams?.toggles?.depreciation ?? true,
        IncludeInsurance:
          uiState.customReportParams?.toggles?.insurance ?? true,
        SelectedVehicle: selectedVehicle,
        Competitors: competitors,
      };

      dispatch(showLoader());
      createReport(createReportData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(hideLoader());
            dispatch(setCreateReportVisible(false));
            alert(response.data);
          } else {
            alert(response.data);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log('createReport 1', err);
        });
    }
  }, [dispatch, uiState, customerEmail, sessionState]);

  return (
    <IonModal
      className='auto-height'
      onDidDismiss={onClosed}
      isOpen={isVisible}
    >
      <IonRow>
        <IonCol className='ion-no-padding ion-no-margin titlebar'>
          CREATE REPORT
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='1'></IonCol>
        <IonCol size='10'>
          <IonRow style={{ paddingTop: 30 }}>
            <IonCol size='4' className='desktopLabel'>
              EMAIL ADDRESS:
            </IonCol>
            <IonCol size='6' className='ion-no-padding'>
              <IonInput
                className='inputCustomize'
                value={customerEmail}
                placeholder='customer@domain.com'
                onIonChange={(ev: any) => setCustomerEmail(ev.target.value)}
              ></IonInput>
            </IonCol>
          </IonRow>
          <IonRow style={{ paddingTop: 10 }}>
            <IonCol size='2'></IonCol>
            <IonCol size='4'>
              <Button
                onPress={onCancel}
                title='CANCEL'
                customClass='redButton'
              />
            </IonCol>
            <IonCol size='4'>
              <Button
                onPress={onCreateReport}
                title='CREATE REPORT'
                customClass='greenButton'
              />
            </IonCol>
            <IonCol size='2'></IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='1'></IonCol>
      </IonRow>
    </IonModal>
  );
};

export default CreateReport;
