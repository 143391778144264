import {
  IonBadge,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addVehicle,
  createTransaction,
  dataCompleteness,
  getImage,
  modelSpecificationDetails,
  queryTransaction,
} from "../../api/mapo";
import { desktopImages, images } from "../../assets";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import MessageModal from "../../components/message-modal";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { Config } from "../../Config";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { DataCompleteness, Vehicle } from "../../interfaces";
import { ModelSpecification } from "../../interfaces/modelSpecification";
import { Transaction } from "../../interfaces/transaction";
import { RootState } from "../../redux/store";
import { hideLoader, setScreen, showLoader } from "../../redux/ui/uiSlice";
import "./style.css";
import { format } from "../../utils";
import moment from "moment";
import { chevronBackCircleSharp, chevronBackOutline } from "ionicons/icons";
import { useHistory } from 'react-router-dom';

const VehicleLanding: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();
  const history = useHistory();

  
  const [completeness, setCompleteness] = useState<
    DataCompleteness | undefined
  >();
  const [modelSpecification, setModelSpecification] = useState<
    ModelSpecification | undefined
  >();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [transaction, setTransaction] = useState<Transaction | undefined>();
  const transactionProcessing = useRef(false);
  const transactionStartTime = useRef<Date | undefined>();

  const onModalPress = useCallback((buttonIndex: number) => {
    setModalVisible(false);
  }, []);

  const onMorePress = useCallback(() => {
    setModalMessage(
      "The following data on this model is populated and available on the App. Click on “ADD TO GARAGE” button to use available credits if the data is sufficient for your requirements."
    );
    setModalVisible(true);
  }, []);

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      //dispatch(setScreen(AppScreens.SearchResult));
      history.push('/SearchResult')
    } else {
      //dispatch(setScreen(AppScreens.Home));
      history.push('/home')
    }
  }, [dispatch, uiState]);

  const onViewDetails = useCallback(() => {
    dispatch(setScreen(AppScreens.VehicleDetail));
    history.push('/VehicleDetail')
  }, [dispatch]);

  const onViewDetail = useCallback(() => {
    //dispatch(setScreen(AppScreens.OverViewDetail));
    history.push('/OverViewDetail');
  }, [dispatch]);
  // const onSelectVehicle = useCallback(
  //   (modelClass: ModelClass) => {
  //     dispatch(showLoader());

  //     const gmvCode =
  //       "G" +
  //       modelClass.result[0].groupCode +
  //       "M" +
  //       modelClass.result[0].modelCode +
  //       "V" +
  //       modelClass.result[0].variantCode;

  //     manualSearch(gmvCode, uiState.isDesktop ?? false)
  //       .then((searchResult) => {
  //         if (searchResult.status === 200) {
  //           const searchResults: Array<Vehicle> = searchResult.data;

  //           getAllSelectedData(
  //             searchResults[0].g_id,
  //             searchResults[0].m_id,
  //             searchResults[0].v_id,
  //             uiState.customReportParams.totalLifeSpan ?? 0,
  //             uiState.customReportParams.tyreChangeInterval ?? 0,
  //             uiState.customReportParams.annualMileage ?? 0,
  //             uiState.customReportParams.deposit ?? 0,
  //             uiState.customReportParams.interest ?? 0,
  //             uiState.customReportParams.term ?? 0,
  //             uiState.customReportParams.residual ?? 0
  //           )
  //             .then((allData) => {

  //               dispatch(
  //                 setSelectedVehicle({
  //                   ...searchResults[0],
  //                   make: searchResults[0].g_desc,
  //                   model: searchResults[0].m_desc,
  //                   data: allData.selected,
  //                   vehicleData: searchResults[0],
  //                 })
  //               );
  //               dispatch(setCompetitors(allData.competitors ?? []));

  //               const selectedVehicleCustomParams: VehicleCustomReportParams = {
  //                 retailprice: decVal(
  //                   allData.selected?.modelSpecification.result?.basic_specs
  //                     ?.base_price
  //                 ),
  //                 co2tax: Number(
  //                   allData.selected?.modelSpecification.result?.engine_specs
  //                     .co2 ?? ""
  //                 ),

  //                 // Service
  //                 servicestandardkm: Number(
  //                   allData.selected?.modelSpecification.result
  //                     ?.service_maintenance_warranty.service_plan ?? "0"
  //                 ),
  //                 servicestandardmnt: Number(
  //                   allData.selected?.modelSpecification.result
  //                     ?.service_maintenance_warranty.service_plan_months ?? "0"
  //                 ),

  //                 // Maintenance
  //                 maintenancestandardkm: Number(
  //                   allData.selected?.modelSpecification.result
  //                     ?.service_maintenance_warranty.maintenance_plan ?? "0"
  //                 ),
  //                 maintenancestandardmnt: Number(
  //                   allData.selected?.modelSpecification.result
  //                     ?.service_maintenance_warranty.maintenance_plan_months ??
  //                     "0"
  //                 ),

  //                 // baloonpayment: 0,
  //               };

  //               const competitorCustomParams: Array<VehicleCustomReportParams> =
  //                 [];

  //               allData.competitors?.map((competitor) => {
  //                 const competitorCustomParam: VehicleCustomReportParams = {
  //                   retailprice: decVal(
  //                     competitor.modelSpecification.result?.basic_specs
  //                       ?.base_price
  //                   ),
  //                   co2tax: Number(
  //                     competitor.modelSpecification.result?.engine_specs.co2 ??
  //                       ""
  //                   ),

  //                   // Service
  //                   servicestandardkm: Number(
  //                     competitor.modelSpecification.result
  //                       ?.service_maintenance_warranty.service_plan ?? "0"
  //                   ),
  //                   servicestandardmnt: Number(
  //                     competitor.modelSpecification.result
  //                       ?.service_maintenance_warranty.service_plan_months ??
  //                       "0"
  //                   ),

  //                   // Maintenance
  //                   maintenancestandardkm: Number(
  //                     competitor.modelSpecification.result
  //                       ?.service_maintenance_warranty.maintenance_plan ?? "0"
  //                   ),
  //                   maintenancestandardmnt: Number(
  //                     competitor.modelSpecification.result
  //                       ?.service_maintenance_warranty
  //                       .maintenance_plan_months ?? "0"
  //                   ),

  //                   baloonpayment: 0,
  //                 };
  //                 competitorCustomParams.push(competitorCustomParam);
  //               });

  //               dispatch(
  //                 setCustomReportParams({
  //                   ...uiState.customReportParams,
  //                   selectedVehicleCustomParams: selectedVehicleCustomParams,
  //                   competitorCustomParams: competitorCustomParams,
  //                 })
  //               );

  //               dispatch(hideLoader());
  //               //dispatch(setScreen(AppScreens.VehicleDetail));
  //               dispatch(showSideMenuDisabledItems());
  //             })
  //             .catch((err) => {
  //               dispatch(hideLoader());
  //               alert("API Error 1: " + JSON.stringify(err));
  //             });
  //         } else {
  //           dispatch(hideLoader());
  //         }

  //         dispatch(hideSideMenuDisabledItems());
  //       })
  //       .catch((err) => {
  //         dispatch(hideLoader());
  //         alert("API Error 2: " + JSON.stringify(err));
  //       });
  //   },
  //   [dispatch, uiState.customReportParams, uiState.isDesktop]
  // );

  const startTransaction = useCallback(
    (discountCode: string) => {
      if (sessionState.user && uiState.selectedVehicle) {
        dispatch(showLoader());
        createTransaction(
          sessionState.user?.username ?? "",
          uiState.selectedVehicle?.g_id,
          uiState.selectedVehicle?.m_id,
          uiState.selectedVehicle?.v_id,
          discountCode
        )
          .then((createTransResponse) => {
            dispatch(hideLoader());
            if (createTransResponse.status === 200) {
              setTransaction(createTransResponse.data);
            } else {
              alert(createTransResponse.statusText);
            }
          })
          .catch((err) => {
            dispatch(hideLoader());
            alert(JSON.stringify(err));
          });
      }
    },
    [dispatch, sessionState.user, uiState.selectedVehicle]
  );

  // const onAddToGarage = useCallback(() => {
  //   if (sessionState.user && uiState.selectedVehicle) {
  //     dispatch(showLoader());
  //     addVehicle(
  //       sessionState.user?.username ?? "",
  //       uiState.selectedVehicle?.g_id,
  //       uiState.selectedVehicle?.m_id,
  //       uiState.selectedVehicle?.v_id,
  //       uiState.selectedVehicle?.make
  //     )
  //       .then((addVehicleResponse) => {
  //         dispatch(hideLoader());
  //         if (addVehicleResponse.status === 200) {
  //           //dispatch(setScreen(AppScreens.Garage));
  //           history.push('/Garage')
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(hideLoader());
  //         alert(JSON.stringify(err));
  //       });
  //   }
  // }, [dispatch, sessionState.user, uiState.selectedVehicle]);

  // const onAddToGarage = useCallback(() => {
  //   startTransaction("");
  // }, [startTransaction]);

  const pollQueryTransaction = useCallback(() => {
    if (
      transaction?.transactionId &&
      transactionProcessing &&
      transactionStartTime
    ) {
      queryTransaction(transaction?.transactionId)
        .then((queryResponse) => {
          if (queryResponse.data === "Pending") {
            if (transactionStartTime.current !== undefined) {
              const passedSeconds =
                (new Date().getTime() -
                  transactionStartTime.current?.getTime()) /
                1000;
              if (passedSeconds > 120) {
                dispatch(hideLoader());
                transactionProcessing.current = false;
                transactionStartTime.current = undefined;
                setTransaction(undefined);
                alert(
                  "Payment could not be processed. Please try again later."
                );
                return;
              }
            }
            setTimeout(() => {
              pollQueryTransaction();
            }, 3000);
          } else if (queryResponse.data === "COMPLETE") {
            dispatch(showLoader());
            transactionProcessing.current = false;
            setTransaction(undefined);
            // addToGaragePaid();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [transaction, transactionProcessing, dispatch]);

  const onModalButtonPress = useCallback(
    (buttonIndex: number) => {
      if (buttonIndex === 1) {
        transactionProcessing.current = true;
        transactionStartTime.current = new Date();
        dispatch(showLoader());
        window.open(
          Config.APP_SERVER +
            "/api/payment/" +
            transaction?.transactionId +
            "/" +
            transaction?.cost
        );
        setTimeout(() => {
          pollQueryTransaction();
        }, 3000);
      } else {
        setTransaction(undefined);
      }
      setModalConfirm(false);
      setModalVisible(false);
    },
    [dispatch, transaction, pollQueryTransaction]
  );

  const hasData = useCallback(
    (type: string) => {
      switch (type) {
        case "serviceChecks": {
          if (
            ((Number(completeness?.serviceChecks) /
              Number(completeness?.serviceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLabour": {
          if (
            ((Number(completeness?.serviceLabour) /
              Number(completeness?.serviceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLubes": {
          if (
            ((Number(completeness?.serviceLubes) /
              Number(completeness?.serviceLubes_Max)) *
              100 ?? 0) > 70
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceParts": {
          if (
            ((Number(completeness?.serviceParts) /
              Number(completeness?.serviceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceChecks": {
          if (
            ((Number(completeness?.maintenanceChecks) /
              Number(completeness?.maintenanceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceLabour": {
          if (
            ((Number(completeness?.maintenanceLabour) /
              Number(completeness?.maintenanceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceParts": {
          if (
            ((Number(completeness?.maintenanceParts) /
              Number(completeness?.maintenanceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    [completeness]
  );

  useEffect(() => {
    if (completeness === undefined && uiState.selectedVehicle) {
      dispatch(showLoader());
      dataCompleteness(
        uiState.selectedVehicle?.g_id,
        uiState.selectedVehicle?.m_id,
        uiState.selectedVehicle?.v_id
      )
        .then((dataCompletenessResponse) => {
          if (
            dataCompletenessResponse.status === 200 &&
            uiState.selectedVehicle
          ) {
            const data: DataCompleteness = dataCompletenessResponse.data;
            setCompleteness(data);
            modelSpecificationDetails(
              uiState.selectedVehicle?.g_id,
              uiState.selectedVehicle?.m_id,
              uiState.selectedVehicle?.v_id
            )
              .then((modelClassResponse) => {
                if (modelClassResponse.status === 200) {
                  setModelSpecification(modelClassResponse.data);
                  dispatch(hideLoader());
                }
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert(JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
            alert(dataCompletenessResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [completeness, dispatch, uiState.selectedVehicle]);

  const onNewSearch = useCallback(() => {
    //dispatch(setScreen(AppScreens.Home));
    history.push('/home')
  }, [dispatch]);

  useEffect(() => {
    if (
      transaction !== undefined &&
      !modalVisible &&
      !transactionProcessing.current
    ) {
      setModalConfirm(true);
      setModalMessage(
        "Are you sure you want to use continue to add this vehicle to your garage at a cost of R" +
          format(transaction.cost, Format.Currency) +
          "? \n\nA seperate browser window will open to Payfast where you need to complete the payment before the vehicle will be added to your garage."
      );
      setModalVisible(true);
    }
  }, [transaction, modalVisible, transactionProcessing]);

  return (
    <>
      <IonPage className="pageBg">
      <Header></Header>

        <IonContent className="contentPage">
          <div className="innerContent">
            
            <IonIcon
              icon={chevronBackCircleSharp}
              onClick={onBackPress}
              className="VehicleTitleIcon"
            ></IonIcon>

            <span className="VehicleTitleLand">
              {uiState.selectedVehicle ? (
                <>{uiState.selectedVehicle.make}</>
              ) : null}
            </span>
            {uiState.selectedVehicle ? (
              <>
                <IonRow>
                  <IonCol size="5">
                    <img
                      className="carImageCompetitor"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />
                  </IonCol>
                  <IonCol size="" className="detail Competitorleft">
                    <h1 className="garageMake">
                      {uiState.selectedVehicle.make}
                    </h1>

                    <h3 className="garageModel">
                      {uiState.selectedVehicle.model}
                    </h3>

                    <h5 className="garageVariant">
                      {uiState.selectedVehicle.variant}
                    </h5>

                    <h5 className="garageDate">
                        MY{" "}
                        {moment(
                          new Date(
                            uiState.selectedVehicle.vehicleData?.intro_date ??
                              ""
                          )
                        ).format("YYYY")}
                      </h5>

                    <h5 className="garageDate">
                      G{uiState.selectedVehicle.g_id.toString()}M
                      {uiState.selectedVehicle.m_id.toString()}V
                      {uiState.selectedVehicle.v_id.toString()}
                    </h5>

                    <h5 className="garageDate">
                      {uiState.selectedVehicle.variant_id}
                    </h5>
                  
                  </IonCol>
              
                </IonRow>
              </>
            ) : null}
            <div className="resultDivider"></div>
            <br />
            <br />
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Model Class
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {modelSpecification?.result.basic_specs.model_class}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Body Style
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                ???
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Status
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {modelSpecification?.result.basic_specs.list_type}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Fuel Type
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {modelSpecification?.result.basic_specs.fuel_type}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Transmission
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {
                  modelSpecification?.result.drivetrain_transmission
                    .transmission_description
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                List Price
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                R {modelSpecification?.result.basic_specs.base_price}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                KW Range
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {
                  modelSpecification?.result.performance_consumption
                    .max_power_kw
                }{" "}
                Kw @{" "}
                {
                  modelSpecification?.result.performance_consumption
                    .max_power_kw_rpm
                }{" "}
                rpm
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                NM Range
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {modelSpecification?.result.performance_consumption.torque_nm}{" "}
                Nm @{" "}
                {
                  modelSpecification?.result.performance_consumption
                    .torque_nm_rpm
                }{" "}
                rpm
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className="FontMobileSpcs rightAlign">
                Drive
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {modelSpecification?.result.drivetrain_transmission.wheel_drive}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" className=" FontMobileSpcs  rightAlign">
                Service Intervals
              </IonCol>
              <IonCol size="8" className="subtitleLanding">
                {
                  modelSpecification?.result.service_maintenance_warranty
                    .service_interval
                }
                km /{" "}
                {
                  modelSpecification?.result.service_maintenance_warranty
                    .service_interval_months
                }
                mnt
              </IonCol>
            </IonRow>
            <br />
            <p>
              <strong className="FontMobileSpcs MapoDataReport">
                MAPO DATA REPORT
              </strong>
              <br />
              <span className="FontMobileSpcs">
                The following data is available on the searched
              </span>
            </p>
            <br />

            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                <div className="HasDataIcon">
                  <div
                    className={
                      completeness?.basicSpecifications === 0
                        ? "dataAvailBadge red"
                        : "dataAvailBadge green"
                    }
                  />
                </div>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Specifications - Basic
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                <div className="HasDataIcon">
                  <div
                    className={
                      completeness?.detailedSpecifications === 0
                        ? "dataAvailBadge red"
                        : "dataAvailBadge green"
                    }
                  />
                </div>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Specifications - Detailed
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                {/* <div
                  className={hasData("serviceChecks") ? "dataGreen" : "dataRed"}
                >
                  
                </div> */}
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon1}
                  alt=""
                />

                <span className="IconData">
                  <div
                    className={
                      hasData("serviceChecks")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Service - Checks
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                {/* <div
                  className={hasData("serviceParts") ? "dataGreen" : "dataRed"}
                >
                  
                </div> */}
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon4}
                  alt=""
                />
                <span className="IconData">
                  <div
                    className={
                      hasData("serviceParts")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />{" "}
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Service - Parts
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                {/* <div
                  className={hasData("serviceLabour") ? "dataGreen" : "dataRed"}
                >
                  
                </div> */}
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon2}
                  alt=""
                />
                <span className="IconData">
                  <div
                    className={
                      hasData("serviceLabour")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Service - Labour
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                {/* <div
                  className={hasData("serviceLubes") ? "dataGreen" : "dataRed"}
                >
                  
                </div> */}

                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon3}
                  alt=""
                />
                <span className="IconData">
                  <div
                    className={
                      hasData("serviceLubes")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Service - Lubricants
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                {/* <div
                  className={
                    hasData("maintenanceParts") ? "dataGreen" : "dataRed"
                  }
                >
                  
                </div> */}
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon7}
                  alt=""
                />
                <span className="IconData">
                  <div
                    className={
                      hasData("maintenanceParts")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Maintenance - Parts
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                <div
                  className={
                    hasData("maintenanceLabour") ? "dataGreen" : "dataRed"
                  }
                ></div>
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon6}
                  alt=""
                />
                <span className="IconData">
                  <div
                    className={
                      hasData("maintenanceLabour")
                        ? "dataAvailBadg green"
                        : "dataAvailBadg red"
                    }
                  />
                </span>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  Maintenance - Labour
                </span>
              </IonCol>
            </IonRow>
            <IonRow className="dataAvaildetailRow">
              <IonCol size="1" className="ion-no-padding">
                <div className="HasDataIcon">
                  <div
                    className={
                      completeness?.suspensionBasketParts === 0
                        ? "dataAvailBadge red"
                        : "dataAvailBadge green"
                    }
                  />
                </div>
              </IonCol>
              <IonCol size="10" className="ion-no-padding">
                <span className="dataAvailLabel FontMobileSpcs">
                  TCO - Total Cost of Ownership Calculation
                </span>
              </IonCol>
            </IonRow>
            <br />
            {/* <IonRow className="dataIconsContainer">
              <div
                className={hasData("serviceChecks") ? "dataGreen" : "dataRed"}
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon1}
                  alt=""
                />
              </div>
              <div
                className={hasData("serviceLabour") ? "dataGreen" : "dataRed"}
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon2}
                  alt=""
                />
              </div>
              <div
                className={hasData("serviceLubes") ? "dataGreen" : "dataRed"}
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon3}
                  alt=""
                />
              </div>
              <div
                className={hasData("serviceParts") ? "dataGreen" : "dataRed"}
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon4}
                  alt=""
                />
              </div>
              <div
                className={
                  hasData("maintenanceChecks") ? "dataGreen" : "dataRed"
                }
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon5}
                  alt=""
                />
              </div>
              <div
                className={
                  hasData("maintenanceLabour") ? "dataGreen" : "dataRed"
                }
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon6}
                  alt=""
                />
              </div>
              <div
                className={
                  hasData("maintenanceParts") ? "dataGreen" : "dataRed"
                }
              >
                <img
                  className="dataIcon"
                  src={desktopImages.dataIcon7}
                  alt=""
                />
              </div>
            </IonRow> */}
            <br />
            <IonRow>
              {sessionState.user?.username !== "GUEST" ? (
                <>
                  <IonCol>
                    {/* <Button
                      onPress={onAddToGarage}
                      customClass="greenButton"
                      title="ADD TO GARAGE"
                    /> */}
                  </IonCol>
                  <IonCol size="2"></IonCol>
                </>
              ) : null}
              <IonCol>
                <Button onPress={onNewSearch} title="NEW SEARCH" />
              </IonCol>
              {sessionState.user?.username == "GUEST" ? (
                <>
                  <IonCol>
                    <Button
                      customClass="greenButton"
                      onPress={onViewDetails}
                      title="VIEW DETAILS"
                    />
                  </IonCol>{" "}
                </>
              ) : null}
              {sessionState.user?.username !== "GUEST" ? (
                <IonCol>
                  <br />
                  <Button
                    customClass="greenButton"
                    onPress={onViewDetail}
                    title="VIEW DETAILS"
                  />
                </IonCol>
              ) : null}
            </IonRow>
          </div>
        </IonContent>
      </IonPage>
      {modalConfirm ? (
        <MessageModal
          icon={images.orangeQuestion}
          visible={modalVisible}
          message={modalMessage}
          button1="Yes, Continue"
          button2="Cancel"
          onPress={onModalButtonPress}
        />
      ) : (
        <MessageModal
          visible={modalVisible}
          message={modalMessage}
          onPress={onModalPress}
        />
      )}
    </>
  );
};

export default VehicleLanding;
