export enum AppScreens {
  Login = 'Login',
  Welcome = 'Welcome',
  Home = 'Home',
  Garage = 'Garage',
  Reviews = 'Reviews',
  Competitors = 'Competitors',
  Rates10K = 'Rates10K',
  Parts = 'Parts',
  TCO = 'TCO',
  Maintenance = 'Maintenance',
  Services = 'Services',
  Specs = 'Specs',
  VehicleLanding = 'VehicleLanding',
  VehicleDetail = 'VehicleDetail',
  SearchResult = 'SearchResult',
  ParameterResult = 'ParameterResult',
  FinanceCalculator = 'FinanceCalculator',
  Tutorial = 'Tutorial',
  About = 'About',
  Contact = 'Contact',
  ResidualValue = 'ResidualValue',
  Depreciation = 'Depreciation',
  Notifications = 'Notifications',
  UserProfile = 'UserProfile',
  Settings = 'Settings',
  Support = 'Support',
  CustomizeReport = 'CustomizeReport',
  BrandSelection = 'BrandSelection',
  FilteredSearchMobile = 'FilteredSearchMobile',
  Preowned = 'Preowned',
  VinScan = "VinScan",
  UndentifiedVin = "UndentifiedVin",
  Subscription = "Subscription",
  OverViewDetail = "OverViewDetail",
  MaintenancePlan = "MaintenancePlan",
  ServicePlan = "ServicePlan",
  TotalCostOwner = "TotalCostOwner",
  FinanceCalculate = "FinanceCalculate",
  Competitor = "Competitor",
  DetailSpecs = "DetailSpecs",
  ResellValue = "ResellValue",
  Basket = "Basket",
  RatesTenK = "RatesTenK"
}

export enum OptionalData {
  Finance,
  Service,
  Maintenance,
  Fuel,
  Insurance,
  Tyre,
  Depreciation,
}

export enum Format {
  Currency,
  Number,
  Decimal,
}
