import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonNote,
  IonPage,
  IonRange,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { icons, images } from "../../assets";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import Header from "../../components/header";
import MessageModal from "../../components/message-modal";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import "./style.css";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  setCustomReportParams,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from "../../redux/ui/uiSlice";
import { SelectedVehicle } from "../../interfaces";
import { Attributes } from "../../interfaces/modelSpecification";
// import { ListViewComponent } from "@syncfusion/ej2-react-lists";
// import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import { CompetitorData } from "../../interfaces/competitorData";
import DesktopChartLegend from "../../components/desktop-chart-legend";
import { format } from "../../utils";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import Tutorial from "../Tutorial";
import axios from "axios";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { ServicePlan } from "../../interfaces/servicePlanTotals";
import { Service } from "bonjour";
import {
  arrowBackOutline,
  caretBackOutline,
  caretForwardOutline,
  chevronBack,
  chevronBackCircleSharp,
  chevronDown,
  chevronForward,
  chevronForwardCircleSharp,
  push,
} from "ionicons/icons";
import { all } from "q";
import { partsInfo } from "../../interfaces/maintenancePlanItems";
import { TCO } from "../../api/mapo";
import { useHistory } from "react-router-dom";
import DataAssurance from "../../components/dataAssurance";
import { utimes } from "fs";
import { join } from "path";
import ReactSlider from "react-slider";
import { Form } from "react-bootstrap";
import Whatsapp from "../../components/whatsapp/whatsapp";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

type PriceInfoItem = {
  iteration: any;
  partName: string;
  nextPrice: number;
  interval: any;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const pieOptions = {
  responsive: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Service Costs By Interval",
    },
  },
  scales: {
    x: {
      stacked: false,
      ticks: {
        display: false,
      },
    },
    y: {
      stacked: false,
      ticks: {
        display: false,
      },
    },
  },
};

const Desktopoptions = {
  // responsive: false,
  // maintainAspectRatio: false,
  // aspectRatio: 0,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const DesktopChartOptions = {
  // responsive: true,
  // maintainAspectRatio: true,
  spanGaps: true,
  // aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 18,
      },
      bodyFont: {
        size: 18,
      },
      footerFont: {
        size: 18, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 18,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 18,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 18,
        },
      },
    },
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
    },

    title: {
      display: false,
      text: "Service Costs By Interval",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const desktopChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  spanGaps: true,
  aspectRatio: 2,
  plugins: {
    tooltip: {
      titleFont: {
        size: 50,
      },
      bodyFont: {
        size: 50,
      },
      footerFont: {
        size: 50, // there is no footer by default
      },
    },
    legend: {
      position: "top" as const,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 35,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    title: {
      display: false,
      text: "Accumulative Data",
      font: {
        size: 30,
      },
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 35,
        },
      },
    },
  },
};

const Services: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();
  const history = useHistory();
  const [showServiceData, setShowServiceData] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [tabVisible, setTabVisible] = useState("TABLE");
  const [initialised, setInitialised] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [kmFromSlider, setKmFromSlider] = useState(
    // uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
    //   uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    //     ?.length > 0
    //   ? uiState.selectedVehicle?.data?.servicePlanTotals?.result
    //       ?.servicePlans[0].interval
    //   : 0
    0
    // &&
    //   //start her
    //   uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
    //   uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
    //     ?.length > 0
    // ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[0]
    //     .attributes?.interval
    // : 0
  );
  const [kmToSlider, setKmToSlider] = useState(
    uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
            .length - 1
        ].interval
      : 0
    // &&
    //   //starting here now
    //   uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
    //   uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
    //     ?.length > 0
    // ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[
    //     uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
    //       .length - 1
    //   ].attributes?.interval
    // : 0
  );

  const [kmFromSlide, setKmFromSlide] = useState(
    // uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
    //   uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    //     ?.length > 0
    //   ? uiState.selectedVehicle?.data?.servicePlanTotals?.result
    //       ?.servicePlans[0].interval
    //   : 0 &&
    //start her
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[0]
          .attributes?.interval
      : 0
  );
  const [kmToSlide, setKmToSlide] = useState(
    // uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans &&
    //   uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    //     ?.length > 0
    //   ? uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans[
    //       uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    //         .length - 1
    //     ].interval
    //   : 0 &&
    //starting here now
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo &&
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
        ?.length > 0
      ? uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo[
          uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
            .length - 1
        ].attributes?.interval
      : 0
  );
  const servicePlanMonths =
    uiState.selectedVehicle?.data?.modelSpecification.result
      .service_maintenance_warranty.service_plan_months;
  const ServicePlanKm =
    Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    ) || 0;

  // If ServicePlanKm is 999999, treat it as zero
  const adjustedServicePlanKm = ServicePlanKm === 999999 ? 0 : ServicePlanKm;
  const servicePlanYears = Number(servicePlanMonths) / 12;

  const [TotalDistanceTravelKm, setTravelKm] = useState(0);
  const [rangeValue, setRangeValue] = useState(25000);
  const [Inflation, setInflationValue] = useState<number>(0);

  const handleRangeChange = (event: any) => {
    const newRangeValue = parseInt(event.target.value, 10);
    setRangeValue(newRangeValue);

    const servicePlanMonths =
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan_months;
    const ServicePlanKm = Number(
      uiState.selectedVehicle?.data?.modelSpecification.result
        .service_maintenance_warranty.service_plan
    );

    // const remainingServiceKm = Number(servicePlanMonths) / 12;
    const servicePlanYears = Number(servicePlanMonths) / 12;
    const distanceTravelled = servicePlanYears * rangeValue;

    //The Km travelled in a year

    setTravelKm(distanceTravelled);
  };

  useEffect(() => {
    const distanceTravelled = servicePlanYears * rangeValue;
    setTravelKm(distanceTravelled);
  }, [servicePlanYears, rangeValue]);

  const onModalPress = useCallback(() => {
    setModalVisible(false);
  }, []);

  // const onMorePress = useCallback(() => {
  //   setModalVisible(true);
  // }, []);

  const onBackPress = useCallback(() => {
    // dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getIntervals = useCallback(() => {
    const list: Array<number> = [];
    if (uiState?.competitors) {
      for (let i = 0; i <= 4; i++) {
        if (i === 0 || uiState?.competitors?.length > i - 1) {
          const dataSet = getData(i);
          dataSet?.servicePlanTotals?.result?.servicePlans?.map(
            (servicePlan) => {
              if (list.indexOf(servicePlan.interval) === -1) {
                if (
                  servicePlan.interval <=
                  (uiState.customReportParams.totalLifeSpan ?? 0)
                ) {
                  list.push(servicePlan.interval);
                }
              }
              return "";
            }
          );
        }
      }
    }
    return list.sort((a, b) => a - b);
  }, [uiState, getData]);

  const getAdditionalServicePlanKm = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalkm
          ? customParams.serviceadditionalkm ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalServicePlanMonths = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index - 1
          ? uiState.competitors[index - 1]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index - 1
          ? uiState.customReportParams.competitorCustomParams[index - 1]
          : undefined;
      if (competitorData && customParams) {
        const value = customParams.serviceadditionalmnt
          ? customParams.serviceadditionalmnt ?? 0
          : Number(
              competitorData.modelSpecification.result
                .service_maintenance_warranty.service_plan_months ?? "0"
            );

        return value;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const getAdditionalServicePlanCost = useCallback(
    (index: number): number => {
      const competitorData: CompetitorData | undefined =
        index === 0
          ? uiState.selectedVehicle?.data
          : uiState.competitors && uiState.competitors.length > index
          ? uiState.competitors[index]
          : undefined;
      const customParams: VehicleCustomReportParams | undefined =
        index === 0
          ? uiState.customReportParams.selectedVehicleCustomParams
          : uiState.customReportParams.competitorCustomParams &&
            uiState.customReportParams.competitorCustomParams.length > index
          ? uiState.customReportParams.competitorCustomParams[index]
          : undefined;
      if (competitorData && customParams) {
        const value =
          (competitorData.tcoData?.result?.serviceCost ?? 0) -
          (customParams.serviceadditionalcost ?? 0);

        return (value ?? 0) < 0 ? value ?? 0 : (value ?? 0) * -1;
      } else {
        return 0;
      }
    },
    [uiState.competitors, uiState.selectedVehicle, uiState.customReportParams]
  );

  const underServicePlan = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.selectedVehicleCustomParams &&
            uiState.customReportParams?.selectedVehicleCustomParams
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return "SP";
        } else if (
          (uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.selectedVehicle?.data?.modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return "SP";
        } else {
          return "";
        }
      } else if (
        uiState.competitors &&
        uiState.competitors.length > colIndex - 1
      ) {
        if (
          ((uiState.customReportParams &&
            uiState.customReportParams?.competitorCustomParams &&
            uiState.customReportParams?.competitorCustomParams[colIndex - 1]
              ?.serviceadditionalkm) ??
            0) >= interval
        ) {
          return "SP";
        } else if (
          (uiState.competitors[colIndex - 1].modelSpecification.result
            .service_maintenance_warranty.service_plan ?? "0") !== "0" &&
          Number(
            uiState.competitors[colIndex - 1].modelSpecification.result
              .service_maintenance_warranty.service_plan ?? "0"
          ) >= interval
        ) {
          return "SP";
        } else {
          return "";
        }
      }
    },
    [uiState.selectedVehicle, uiState.competitors, uiState.customReportParams]
  );

  // const getChartIntervals = useCallback(() => {
  //   const list: Array<string> = [];
  //   const intervalList = getIntervals();
  //   intervalList.map((interval, index) => {
  //     const value =
  //       interval.toString() +
  //       "k " +
  //       (index < intervalList.length - 1
  //         ? " - " + intervalList[index + 1].toString() + "k"
  //         : "");
  //     list.push(value);
  //     return "";
  //   });
  //   return list;
  // }, [getIntervals]);

  // const getChartIntervals = useCallback(() => {
  //   const list: Array<string> = [];
  //   const intervalList = getIntervals();

  //   intervalList.forEach((interval, index) => {
  //     let label;

  //     if (index < intervalList.length - 1) {
  //       // If it's not the last interval, construct a range label
  //       label = `${intervalList[index]}`;
  //     } else {
  //       // If it's the last interval, construct a single value label
  //       label = `${intervalList[index]}`;
  //     }

  //     list.push(label);
  //   });

  //   return list;
  // }, [getIntervals]);

  const getChartIntervals = useCallback(() => {
    const list: Array<string> = [];
    const kmFromSlide = kmFromSlider; //starting point
    const intervalStep = kmFromSlide;
    let currentKm = kmFromSlider;

    while (currentKm <= kmToSlider) {
      let label;

      if (currentKm + intervalStep <= kmToSlider) {
        // If adding the step doesn't exceed kmToSlider, construct a range label
        label = `${currentKm}`;
      } else {
        // If adding the step exceeds kmToSlider, construct a single value label
        label = `${currentKm}`;
      }

      list.push(label);
      currentKm += intervalStep;
    }

    return list;
  }, [kmToSlider]);

  const getDataset = useCallback(
    (col: number) => {
      const data: Array<number> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(
          col
        )?.servicePlanTotals?.result?.servicePlans?.filter(
          (item) => item.interval === interval
        );
        if (plans && plans.length > 0) {
          data.push(
            underServicePlan(col, interval) === "" ? plans[0].total : 0
          );
        } else {
          data.push(0);
        }
        return "";
      });
      return data;
    },
    [getData, underServicePlan, getIntervals]
  );

  const getDesktopChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getDataset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
        },
        {
          label:
            getData(1)?.modelSpecification.attributes.make +
            " " +
            getData(1)?.modelSpecification.attributes.model,
          data: getDataset(1),
          borderColor: "#ff2a2a",
          backgroundColor: "#ff2a2a",
        },
        {
          label:
            getData(2)?.modelSpecification.attributes.make +
            " " +
            getData(2)?.modelSpecification.attributes.model,
          data: getDataset(2),
          borderColor: "#fc8f00",
          backgroundColor: "#fc8f00",
        },
        {
          label:
            getData(3)?.modelSpecification.attributes.make +
            " " +
            getData(3)?.modelSpecification.attributes.model,
          data: getDataset(3),
          borderColor: "#00df17",
          backgroundColor: "#00df17",
        },
      ],
    };
    return desktopChartData;
  }, [getDataset, getData, getChartIntervals]);

  const getAccumulativeDataset = useCallback(
    (col: number) => {
      const data: Array<number> = [];
      let total = 0;
      getIntervals().map((interval, index) => {
        const plans = getData(
          col
        )?.servicePlanTotals?.result?.servicePlans?.filter(
          (item) => item.interval === interval
        );
        if (plans && plans.length > 0) {
          total =
            total +
            (underServicePlan(col, interval) === "" ? plans[0].total : 0);
        }
        data.push(total);
        //console.log(`Interval ${index + 1}:`, interval, "Total:", total); // Debugging console.log
        return "";
      });
      //console.log("Final Accumulative Data:", data); // Debugging console.log
      return data;
    },
    [getData, getIntervals, underServicePlan]
  );

  // const getDesktopAccumaltiveChartData = useCallback(() => {
  //   const labels: Array<String> = [];
  //   const parts: Array<Number> = [];
  //   const fluids: Array<Number> = [];
  //   const labour: Array<Number> = [];
  //   const total: Array<Number> = [];
  //   uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans?.map(
  //     (servicePlan) => {
  //       if (
  //         servicePlan.interval >= kmFromSlider &&
  //         servicePlan.interval <= kmToSlider
  //       ) {
  //         labels.push(servicePlan.interval.toString());
  //         parts.push(servicePlan.parts);
  //         fluids.push(servicePlan.fluids);
  //         labour.push(servicePlan.labour);
  //         // total.push(servicePlan.total);
  //       }
  //       return "";
  //     }
  //   );
  //   const desktopChartData = {
  //     labels,
  //     datasets: [
  //       {
  //         label:
  //           getData(0)?.modelSpecification.attributes.make +
  //           " " +
  //           getData(0)?.modelSpecification.attributes.model,
  //         data: getAccumulativeDataset(0),
  //         borderColor: "#293eff",
  //         backgroundColor: "#293eff",
  //         borderWidth: 5,
  //         point: {
  //           radius: 10,
  //         },
  //       },
  //       // {
  //       //   label:
  //       //     getData(1)?.modelSpecification.attributes.make +
  //       //     " " +
  //       //     getData(1)?.modelSpecification.attributes.model,
  //       //   data: getAccumulativeDataset(1),
  //       //   borderColor: "#ff2a2a",
  //       //   backgroundColor: "#ff2a2a",
  //       // },
  //       // {
  //       //   label:
  //       //     getData(2)?.modelSpecification.attributes.make +
  //       //     " " +
  //       //     getData(2)?.modelSpecification.attributes.model,
  //       //   data: getAccumulativeDataset(2),
  //       //   borderColor: "#fc8f00",
  //       //   backgroundColor: "#fc8f00",
  //       // },
  //       // {
  //       //   label:
  //       //     getData(3)?.modelSpecification.attributes.make +
  //       //     " " +
  //       //     getData(3)?.modelSpecification.attributes.model,
  //       //   data: getAccumulativeDataset(3),
  //       //   borderColor: "#00df17",
  //       //   backgroundColor: "#00df17",
  //       // },
  //     ],
  //   };
  //   return desktopChartData;
  // }, [getAccumulativeDataset, getData, getChartIntervals]);

  const getPartsTotal = useCallback(
    (index: number) => {
      let total = 0;
      getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
        if (item.interval <= (uiState.customReportParams.totalLifeSpan ?? 0)) {
          total +=
            underServicePlan(index, item.interval) === "" ? item.parts : 0;
        }
        return "";
      });

      return total;
    },
    [getData, uiState.customReportParams, underServicePlan]
  );

  const getFluidsTotal = useCallback(
    (index: number) => {
      let total = 0;
      getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
        if (item.interval <= (uiState.customReportParams.totalLifeSpan ?? 0)) {
          total +=
            underServicePlan(index, item.interval) === "" ? item.fluids : 0;
        }
        return "";
      });

      return total;
    },
    [getData, uiState.customReportParams, underServicePlan]
  );

  const getLabourTotal = useCallback(
    (index: number) => {
      let total = 0;
      getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
        if (item.interval <= (uiState.customReportParams.totalLifeSpan ?? 0)) {
          total +=
            underServicePlan(index, item.interval) === ""
              ? item.labour + item.sundries
              : 0;
        }
        return "";
      });

      return total;
    },
    [getData, uiState.customReportParams, underServicePlan]
  );

  const getGrandTotal = useCallback(
    (index: number) => {
      let total = 0;
      getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
        if (item.interval <= (uiState.customReportParams.totalLifeSpan ?? 0)) {
          total +=
            underServicePlan(index, item.interval) === ""
              ? item.fluids + item.parts + item.labour + item.sundries
              : 0;
        }
        return "";
      });

      return total;
    },
    [getData, uiState.customReportParams, underServicePlan]
  );

  const getGrandTotalMobile = useCallback(
    (index: number) => {
      let total = 0;
      getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
        total +=
          underServicePlan(index, item.interval) === ""
            ? item.fluids + item.parts + item.labour + item.sundries
            : 0;
        return "";
      });

      return total;
    },
    [getData, underServicePlan]
  );

  // const ServicePlanTotal = useCallback(
  //   (index: number) => {}
  // )

  const getGrandTotalMobil = useCallback(
    (index: number) => {
      let total = 0;
      //getData(index)?.servicePlanTotals?.result.servicePlans.map((item) => {
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans?.map(
        (servicePlan) => {
          if (
            servicePlan.interval >= kmFromSlider &&
            servicePlan.interval <= kmToSlider &&
            TotalDistanceTravelKm <= servicePlan.interval
          ) {
            total +=
              underServicePlan(index, servicePlan.interval) === ""
                ? servicePlan.fluids +
                  servicePlan.parts +
                  servicePlan.labour +
                  servicePlan.sundries
                : 0;
          }
          return "";
        }
      );

      return total;
    },
    [
      getData,
      underServicePlan,
      uiState.selectedVehicle?.data?.servicePlanTotals,
      kmFromSlider,
      kmToSlider,
      TotalDistanceTravelKm,
    ]
  );

  const getPieData = useCallback(
    (index: number) => {
      const data = {
        labels: ["Parts", "Fluids", "Labour"],
        datasets: [
          {
            label: "# of Votes",
            data: [
              getPartsTotal(index),
              getFluidsTotal(index),
              getLabourTotal(index),
            ],
            backgroundColor: ["#285287", "#28c92c", "#F98709"],
            borderWidth: 0,
          },
        ],
      };
      return data;
    },
    [getPartsTotal, getFluidsTotal, getLabourTotal]
  );

  //The chart data to check the condition if the service plan is available and also slide
  // const getChartData = useCallback(() => {
  //   const labels: string[] = [];
  //   const parts: number[] = [];
  //   const fluids: number[] = [];
  //   const labour: number[] = [];
  //   const total = [];

  //   uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans?.forEach(
  //     (servicePlan) => {
  //       if (
  //         servicePlan.interval >= kmFromSlider &&
  //         servicePlan.interval <= kmToSlider
  //       ) {
  //         labels.push(servicePlan.interval.toString());

  //         const selectedVehicleData =
  //           uiState.selectedVehicle?.data?.modelSpecification.result
  //             .service_maintenance_warranty.service_plan;
  //         const isServiceDataValid =
  //           (showServiceData &&
  //             Number(selectedVehicleData ?? "0") >= servicePlan.interval) ||
  //           (showServiceData && TotalDistanceTravelKm >= servicePlan.interval);

  //         // Handle parts, fluids, and labour based on conditions
  //         let partsValue: number | 0;
  //         let fluidsValue: number | 0;
  //         let labourValue: number | 0;

  //         if (
  //           selectedVehicleData === "999999" &&
  //           TotalDistanceTravelKm <= servicePlan.interval
  //         ) {
  //           // If selectedVehicleData is 999999, include fluids, parts, and labour
  //           partsValue = Number(format(servicePlan.parts, Format.Currency));
  //           fluidsValue = Number(format(servicePlan.fluids, Format.Currency));
  //           labourValue = Number(format(servicePlan.labour, Format.Currency));
  //         } else if (isServiceDataValid) {
  //           // If service data is valid, set values to 0
  //           partsValue = 0;
  //           fluidsValue = 0;
  //           labourValue = 0;
  //         } else {
  //           // Otherwise, include fluids, parts, and labour based on conditions
  //           partsValue = Number(format(servicePlan.parts, Format.Currency));
  //           fluidsValue = Number(format(servicePlan.fluids, Format.Currency));
  //           labourValue = Number(format(servicePlan.labour, Format.Currency));
  //         }

  //         parts.push(partsValue);
  //         fluids.push(fluidsValue);
  //         labour.push(labourValue);
  //       }
  //     }
  //   );

  //   const data = {
  //     labels,
  //     datasets: [
  //       {
  //         label: "Parts",
  //         backgroundColor: "rgb(106, 178, 44)",
  //         borderWidth: 0,
  //         data: parts,
  //         fontSize: 35,
  //       },
  //       {
  //         label: "Fluids",
  //         backgroundColor: "rgb(17, 38, 150)",
  //         borderWidth: 0,
  //         data: fluids,
  //       },
  //       {
  //         label: "Labour",
  //         backgroundColor: "rgb(52, 61, 235)",
  //         borderWidth: 0,
  //         data: labour,
  //       },
  //       // {
  //       //   label: "Total",
  //       //   backgroundColor: "rgb(213, 0, 27)",
  //       //   borderWidth: 0,
  //       //   data: total,
  //       // },
  //     ],
  //   };

  //   return data;
  // }, [
  //   uiState.selectedVehicle?.data?.servicePlanTotals,
  //   kmFromSlider,
  //   kmToSlider,
  //   showServiceData,
  //   TotalDistanceTravelKm,
  // ]);

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: "",
    };
    return vehicle;
  };

  const getTotal = useCallback(
    (colIndex: number, interval: number) => {
      if (colIndex === 0) {
        const intervalPlans =
          uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans.filter(
            (plan) => plan.interval === interval
          );

        if (intervalPlans && intervalPlans?.length > 0) {
          return intervalPlans[0].total.toFixed(2);
        } else {
          return "0.00";
        }
      } else {
        if (uiState.competitors && uiState.competitors?.length > colIndex - 1) {
          const intervalPlans = uiState.competitors[
            colIndex - 1
          ].servicePlanTotals?.result?.servicePlans.filter(
            (plan) => plan.interval === interval
          );
          if (intervalPlans && intervalPlans?.length > 0) {
            return intervalPlans[0].total.toFixed(2);
          } else {
            return "0.00";
          }
        } else {
          return "0.00";
        }
      }
    },
    [uiState.selectedVehicle?.data?.servicePlanTotals, uiState.competitors]
  );

  const getMarketAvg = useCallback(
    (col0: string, col1: string, col2: string, col3: string) => {
      let total = 0;
      let divider = 0;

      if (col0 !== "") {
        total += Number(col0);
        divider++;
      }

      if (col1 !== "") {
        total += Number(col1);
        divider++;
      }

      if (col2 !== "") {
        total += Number(col2);
        divider++;
      }

      if (col3 !== "") {
        total += Number(col3);
        divider++;
      }

      if (total !== 0) {
        return (total / divider).toFixed(2);
      } else {
      }
    },
    []
  );

  const getMarketPos = useCallback(
    (col0: string, col1: string, col2: string, col3: string) => {
      if (
        !uiState.selectedVehicle?.data?.servicePlanTotals?.result
          ?.servicePlans ||
        uiState.selectedVehicle?.data?.servicePlanTotals?.result.servicePlans
          .length === 0
      ) {
        return <td>SPNULL</td>;
      }

      let total = 0;

      if (col0 !== "") {
        total += Number(col0);
      }

      if (col1 !== "") {
        total += Number(col1);
      }

      if (col2 !== "") {
        total += Number(col2);
      }

      if (col3 !== "") {
        total += Number(col3);
      }

      const selectedTotal =
        uiState.selectedVehicle?.data?.servicePlanTotals?.result.servicePlans[0]
          .total ?? 0;
      const position = (selectedTotal / total) * 100;

      if (total !== 0 && selectedTotal !== 0) {
        if (position > 0) {
          return (
            <td width={130} className="analysisTable bestValue">
              {format(position, Format.Currency)}%
            </td>
          );
        } else {
          return (
            <td width={130} className="analysisTable">
              {format(position, Format.Currency)}%
            </td>
          );
        }
      } else {
        return <td></td>;
      }
    },
    [uiState.selectedVehicle?.data?.servicePlanTotals]
  );

  const LabourRates = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].hourlyRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const SundriesRate = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.LabourRatesData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].sundriesRate;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
    }
  }, [initialised]);

  const onLogOut = useCallback(() => {
    dispatch(sessionLogout());
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.Login));
      history.push("/login");
    }, 300);
  }, [dispatch]);

  //For the table to drop down
  //if (isPaid) {

  const [showServiceItem, setShowServiceItem] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);

  const handleIntervalClick = (interval: any) => {
    setSelectedInterval(selectedInterval === interval ? null : interval);
  };
  //logic for filtering
  const [allPlans, setAllPlans] = useState<Array<ServicePlan>>();
  const [allPlanInfo, setPlanInfo] = useState<Array<partsInfo>>();

  useEffect(() => {
    setAllPlans(
      uiState.selectedVehicle?.data?.servicePlanTotals?.result?.servicePlans
    );
    setPlanInfo(
      uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
    );
  }, [uiState]);

  const intervalsInfo: Array<ServicePlan> = [];
  const [intervalValues, setIntervalValues] = useState<ServicePlan>();

  const filterByInterval = (plan: ServicePlan) => {
    allPlans?.forEach((val, index) => {
      if (val.interval === plan.interval) {
        setIntervalValues(plan);
      }
      return intervalValues;
    });

    setShowServiceItem(!showServiceItem);
  };

  // let listobj: null = null;

  // const [state, SetState] = useState({
  //   selectedItemsValue: [],
  // });
  // function getSelectedItems(): void {
  //   if (listobj) {
  //     SetState({
  //       selectedItemsValue: (listobj.getSelectedItems() as any).data,
  //     });
  //   }
  // } Add the

  const reloadData = useCallback(() => {
    dispatch(showLoader());
    TCO(
      uiState.selectedVehicle?.g_id ?? 0,
      uiState.selectedVehicle?.m_id ?? 0,
      uiState.selectedVehicle?.v_id ?? 0,
      uiState.customReportParams.totalLifeSpan ?? 0,
      uiState.customReportParams.tyreChangeInterval ?? 0,
      uiState.customReportParams.annualMileage ?? 0,
      uiState.customReportParams.deposit ?? 0,
      uiState.customReportParams.interest ?? 0,
      uiState.customReportParams.term ?? 0,
      uiState.customReportParams.residual ?? 0
    )
      .then((tcoResponse) => {
        dispatch(hideLoader());
        if (tcoResponse.status === 200) {
          const newSelectedVehicle: any = {
            ...uiState.selectedVehicle,
            data: {
              ...uiState.selectedVehicle?.data,
              tcoData: tcoResponse.data,
            },
          };
          dispatch(setSelectedVehicle(newSelectedVehicle));
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  }, [uiState, dispatch]);

  const [showPlan, setShowPlan] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showBarDetail, setShowBarDetail] = useState(false);
  const [showLineDetail, setShowLineDetail] = useState(false);
  const [kilos, setKilos] = useState(Number);
  const [months, setMonths] = useState(Number);
  const [serviceType, setServiceTypes] = useState<string>("");
  const [activeText, setActiveText] = useState("");

  useEffect(() => {
    setKilos(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan
      )
    );

    setMonths(
      Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan_months
      )
    );

    setServiceTypes(
      JSON.stringify(
        uiState.selectedVehicle?.data?.modelSpecification.result
          .service_maintenance_warranty.service_plan_type
      ).replace(/"/g, "")
    );
  }, [uiState.selectedVehicle, kilos, months, serviceType]);

  useEffect(() => {
    if (showServiceData == false) {
      setActiveText("DEACTIVATED");
    } else {
      setActiveText("ACTIVATED");
    }
  }, [activeText, showServiceData]);

  {
    /*
  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  let touchStartX = 0;

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCost = showWithSlider
    ? getGrandTotalMobil(0)
    : getGrandTotalMobil(0) / Number(kmToSlider);

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  
  */
  }

  //Timeout to update th e
  const [range, setRange] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setRange({ lower: 10, upper: 20 });
      }, 10);

      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const handleInflationChange = (e: any) => {
    const Value = e.detail.value;
    setInflationValue(Value);
  };

  const calculateMonths = (interval: any) => {
    const distancePerYear = rangeValue;

    const months = Math.floor(interval / distancePerYear);

    return months;
  };

  //To calculate the months next to intervals

  function calculateMonthsLabel(interval: any) {
    const months = calculateMonths(interval);

    if (months >= 1 && months <= 12) {
      return months * 12 + " mnt";
    } else if (months > 12 && months <= 90) {
      return months * 12 + " mnt";
    } else {
      return months + " mnt";
    }
  }

  const calculateMonth = (interval: any) => {
    const distancePerYear = rangeValue;

    const months = Math.floor(interval / distancePerYear) * 12;

    return months;
  };

  // Calculate the years
  function calculateYearsLabel(interval: any) {
    const months = calculateMonth(interval);

    return months;
  }

  //calculating the Inflation rate
  const numberOfIterations = 1;

  const calculateCost = (
    initialValue: number,
    Inflation: number,
    numberOfIterations: number
  ): (string | undefined)[] => {
    const calculatedCosts: (string | undefined)[] = [
      format(initialValue * (1 + Inflation / 100), Format.Currency),
    ];

    for (let i = 1; i < numberOfIterations; i++) {
      const previousValue = parseFloat(
        calculatedCosts[i - 1]?.replace(/[^\d.-]/g, "") ?? "0"
      ); // Use nullish coalescing to handle undefined
      const newValue = previousValue * (1 + Inflation / 100);
      calculatedCosts.push(format(newValue, Format.Currency));
    }

    return calculatedCosts;
  };

  const intervalHolder: Array<number> = [];

  uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.forEach(
    (v, i) => {
      intervalHolder.push(v.attributes["interval"]);
    }
  );

  // For the parts
  const [priceInfo, setPriceInfo] = useState<PriceInfoItem[][]>([]);

  //console.log("The parts price", priceInfo);

  const initialPartsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.partsInfo,
              partName: servicePlan.basketItemName,
              interval: servicePlan.attributes["interval"],
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);
  //
  useEffect(() => {
    if (initialPartsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialPartsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const partsInfo = servicePartsResult.parts;

          partsInfo.forEach((part: any) => {
            const partName = part.partNumber;

            const PartQuantity = part.quantity;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price * PartQuantity;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(priceInfo)) {
        setPriceInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialPartsArray]);

  //For the fluids

  const [FluidsInfo, setFluidsInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The fluid price", FluidsInfo)
  const initialFluidsArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.fluidsInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialFluidsArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialFluidsArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = part.price;
              let nextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                nextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: nextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = nextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(FluidsInfo)) {
        setFluidsInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialFluidsArray]);

  //Calculating the inflation for the  Labour

  const [LabourInfo, setLabourInfo] = useState<PriceInfoItem[][]>([]);

  // console.log("The labour rate",LabourInfo)

  const initialLabourArray =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo
      ?.map((servicePlan) =>
        servicePlan
          ? {
              parts: servicePlan.labourInfo,
            }
          : undefined
      )
      ?.filter((value) => value !== undefined);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult, index) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = LabourRates(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(LabourInfo)) {
        setLabourInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  //Calculating the inflation for the  Labour

  const [SundriesInfo, setSundriesInfo] = useState<PriceInfoItem[][]>([]);

  useEffect(() => {
    if (initialLabourArray) {
      const uniquePartNumbers = new Set<string>();
      const newPriceInfo: PriceInfoItem[][] = [];

      initialLabourArray.forEach((servicePartsResult: any, index: any) => {
        if (servicePartsResult) {
          const fluidsInfo = servicePartsResult.parts;

          fluidsInfo.forEach((part: any) => {
            const partName = part.oemPartNumber;

            if (!uniquePartNumbers.has(partName)) {
              let oldPrice = SundriesRate(uiState.selectedVehicle?.g_id);
              let LabournextPrice;

              const calculatedPrices: PriceInfoItem[] = [];

              for (let i = 0; i < intervalHolder.length; i++) {
                // Calculate the currentYear based on the current interval
                const currentYear = calculateYearsLabel(intervalHolder[i]);

                // If the current year is 0, use the original price; otherwise, calculate nextPrice
                LabournextPrice =
                  currentYear === 0
                    ? oldPrice
                    : currentYear === calculateYearsLabel(intervalHolder[i - 1])
                    ? oldPrice
                    : oldPrice * (1 + Inflation / 100);

                // Push the calculated data into the calculatedPrices array
                calculatedPrices.push({
                  iteration: currentYear,
                  partName: partName,
                  nextPrice: LabournextPrice,
                  interval: intervalHolder[i],
                });

                oldPrice = LabournextPrice;
              }

              uniquePartNumbers.add(partName);
              newPriceInfo.push(calculatedPrices);
            }
          });
        } else {
          console.log(`ServicePartsresult for element ${index} is undefined.`);
        }
      });

      // Conditionally update state only if there's a change
      if (JSON.stringify(newPriceInfo) !== JSON.stringify(SundriesInfo)) {
        setSundriesInfo(newPriceInfo);
      }
    } else {
      console.log("initialPartsArray is undefined.");
    }
  }, [initialLabourArray]);

  const filteredPlanInfo =
    uiState.selectedVehicle?.data?.servicePlanItem?.result?.serviceInfo?.map(
      (planItem) => {
        const nextPricesForPart = planItem.partsInfo
          ?.map((part: any) => {
            const matchingPriceInfo = priceInfo.flat().filter((priceItem) => {
              return (
                priceItem.partName === part?.partNumber &&
                priceItem.interval === planItem.attributes?.interval
              );
            });

            return matchingPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the fluids
        const fluidNextPrices = planItem.fluidsInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = FluidsInfo.flat().filter(
              (priceItem) => {
                return (
                  priceItem.partName === fluid?.oemPartNumber &&
                  priceItem.interval === planItem.attributes?.interval
                );
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        // For the Labour
        const LabourNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = LabourInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item) => item.nextPrice);
          })
          .flat();

        //Sundries Rates
        const SundriesNextPrices = planItem.labourInfo
          ?.map((fluid: any) => {
            const matchingFluidPriceInfo = SundriesInfo.flat().filter(
              (priceItem) => {
                return priceItem.interval === planItem.attributes?.interval;
              }
            );

            return matchingFluidPriceInfo.map((item: any) => item.nextPrice);
          })
          .flat();

        const totalPartPrices = nextPricesForPart.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalFluidPrices = fluidNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalLabourPrices = LabourNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );
        const totalSundriesPrices = SundriesNextPrices.reduce(
          (total: any, price: any) => total + price,
          0
        );

        return {
          ...planItem,
          nextPrices: nextPricesForPart,
          fluidNextPrices: fluidNextPrices,
          totalPartPrices: totalPartPrices,
          totalFluidPrices: totalFluidPrices,
          totalLabourPrices: totalLabourPrices,
          totalSundriesPrices: totalSundriesPrices,
        };
      }
    );

  const getChartData = useCallback(() => {
    const labels: string[] = [];
    const parts: number[] = [];
    const fluids: number[] = [];
    const labour: number[] = [];
    const total: number[] = []; // Added type annotation for total

    filteredPlanInfo?.forEach((servicePlan) => {
      //console.log("The servicePlan in bar chart data", servicePlan); // Added console.log

      if (
        servicePlan?.attributes?.interval >= kmFromSlider &&
        servicePlan?.attributes?.interval <= kmToSlider
      ) {
        labels.push(servicePlan?.attributes?.interval.toString());

        const selectedVehicleData =
          uiState.selectedVehicle?.data?.modelSpecification.result
            .service_maintenance_warranty.service_plan;
        const isServiceDataValid =
          showServiceData &&
          Number(selectedVehicleData ?? "0") >=
            servicePlan?.attributes?.interval &&
          Number(
            (Number(servicePlan?.attributes.interval) / rangeValue) * 12
          ) <=
            Number(
              uiState.selectedVehicle?.data?.modelSpecification.result
                .service_maintenance_warranty.service_plan_months
            );

        // Handle parts, fluids, and labour based on conditions
        let partsValue: number | 0;
        let fluidsValue: number | 0;
        let labourValue: number | 0;

        if (
          selectedVehicleData === "999999" &&
          Number((Number(servicePlan?.attributes.interval) / rangeValue) * 12) >
            Number(
              uiState.selectedVehicle?.data?.modelSpecification.result
                .service_maintenance_warranty.service_plan_months
            )
        ) {
          // If selectedVehicleData is 999999, include fluids, parts, and labour
          partsValue = Number(servicePlan.totalPartPrices);
          fluidsValue = Number(servicePlan.totalFluidPrices);
          labourValue = Number(
            servicePlan.totalLabourPrices *
              servicePlan.labourInfo[0]?.labourQuantity +
              servicePlan.totalSundriesPrices
          );
        } else if (isServiceDataValid) {
          // If service data is valid, set values to 0
          partsValue = 0;
          fluidsValue = 0;
          labourValue = 0;
        } else {
          partsValue = servicePlan.totalPartPrices;
          fluidsValue = servicePlan.totalFluidPrices;

          labourValue =
            servicePlan.totalLabourPrices *
              servicePlan.labourInfo[0]?.labourQuantity +
            servicePlan.totalSundriesPrices;
        }

        parts.push(partsValue);
        fluids.push(fluidsValue);
        labour.push(labourValue);
      }
    });

    const data = {
      labels,
      datasets: [
        {
          label: "Parts",
          backgroundColor: "rgb(106, 178, 44)",
          borderWidth: 0,
          data: parts,
          fontSize: 35,
        },
        {
          label: "Fluids",
          backgroundColor: "rgb(17, 38, 150)",
          borderWidth: 0,
          data: fluids,
        },
        {
          label: "Labour",
          backgroundColor: "rgb(52, 61, 235)",
          borderWidth: 0,
          data: labour,
        },
      ],
    };

    return data;
  }, [
    filteredPlanInfo,
    uiState.selectedVehicle?.data?.servicePlanTotals,
    kmFromSlider,
    kmToSlider,
    showServiceData,
    TotalDistanceTravelKm,
  ]);

  const getAccumulativeDatset = useCallback(
    (col: number) => {
      const data: number[] = [];
      const intervals = new Set(
        filteredPlanInfo
          ?.filter(
            (servicePlan) =>
              servicePlan?.attributes?.interval >= kmFromSlider &&
              servicePlan?.attributes?.interval <= kmToSlider
          )
          .map((servicePlan) => servicePlan?.attributes?.interval)
          .filter((interval) => interval)
      );

      let cumulativeTotal = 0;

      intervals.forEach((interval) => {
        let total = 0;
        filteredPlanInfo?.forEach((servicePlan) => {
          if (servicePlan?.attributes?.interval === interval) {
            const totalPartPrices = servicePlan.totalPartPrices || 0;
            const totalLabourPrices = servicePlan.totalLabourPrices || 0;
            const totalSundriesPrices = servicePlan.totalSundriesPrices || 0;
            const laborQuantity =
              servicePlan.labourInfo[0]?.labourQuantity || 0;
            const totalLaborPrice = totalLabourPrices * laborQuantity;
            const totalFluidPrices = servicePlan.totalFluidPrices || 0;

            //Adding the total of all the values
            const totalServicePlanPrice =
              totalPartPrices +
              totalLaborPrice +
              totalSundriesPrices +
              totalFluidPrices;

            let addToTotal = totalServicePlanPrice;

            if (showServiceData) {
              if (
                Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.service_plan ?? "0"
                ) >= servicePlan?.attributes.interval &&
                Number(
                  (Number(servicePlan?.attributes.interval) / rangeValue) * 12
                ) <=
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.service_plan_months
                  )
              ) {
                addToTotal = 0;
              }
            }

            total += addToTotal;
          }
        });
        cumulativeTotal += total;

        data.push(Number(cumulativeTotal));
      });

      return data;
    },
    [
      filteredPlanInfo,
      uiState.selectedVehicle?.data?.servicePlanTotals,
      showServiceData,
      kmFromSlider,
      kmToSlider,
    ]
  );

  const getDesktopAccumaltiveChartData = useCallback(() => {
    const labels: string[] = [];
    const filteredPlans = filteredPlanInfo?.filter(
      (servicePlan) =>
        servicePlan?.attributes?.interval >= kmFromSlider &&
        servicePlan?.attributes?.interval <= kmToSlider
    );

    filteredPlans?.forEach((servicePlan) => {
      labels.push(servicePlan?.attributes?.interval.toString());
    });

    const desktopChartData = {
      labels,
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            " " +
            getData(0)?.modelSpecification.attributes.model,
          data: getAccumulativeDatset(0),
          borderColor: "#293eff",
          backgroundColor: "#293eff",
          borderWidth: 5,
          elements: {
            point: {
              radius: 5,
            },
          },
        },
      ],
    };

    return desktopChartData;
  }, [
    getAccumulativeDatset,
    getData,
    kmFromSlider,
    filteredPlanInfo,
    kmToSlider,
    showServiceData,
  ]);

  //The grand total
  const getGrandTotalMob = useCallback(
    (index: number) => {
      let total = 0;
      if (filteredPlanInfo) {
        filteredPlanInfo.forEach((servicePlan) => {
          if (
            servicePlan?.attributes?.interval >= kmFromSlider &&
            servicePlan?.attributes?.interval <= kmToSlider
          ) {
            total += showServiceData
              ? Number(
                  uiState.selectedVehicle?.data?.modelSpecification.result
                    .service_maintenance_warranty.service_plan ?? "0"
                ) >= servicePlan?.attributes.interval
                ? 0 ||
                  Number(
                    uiState.selectedVehicle?.data?.modelSpecification.result
                      .service_maintenance_warranty.service_plan ?? "0"
                  ) === 999999
                  ? servicePlan.totalFluidPrices +
                    servicePlan.totalPartPrices +
                    servicePlan.totalLabourPrices *
                      servicePlan.labourInfo[0]?.labourQuantity +
                    servicePlan.totalSundriesPrices
                  : Number(
                      (Number(servicePlan?.attributes.interval) / rangeValue) *
                        12
                    ) >
                    Number(
                      uiState.selectedVehicle?.data?.modelSpecification.result
                        .service_maintenance_warranty.service_plan_months
                    )
                  ? servicePlan.totalFluidPrices +
                    servicePlan.totalPartPrices +
                    servicePlan.totalLabourPrices *
                      servicePlan.labourInfo[0]?.labourQuantity +
                    servicePlan.totalSundriesPrices
                  : 0
                : servicePlan.totalFluidPrices +
                  servicePlan.totalPartPrices +
                  servicePlan.totalLabourPrices *
                    servicePlan.labourInfo[0]?.labourQuantity +
                  servicePlan.totalSundriesPrices
              : servicePlan.totalFluidPrices +
                servicePlan.totalPartPrices +
                servicePlan.totalLabourPrices *
                  servicePlan.labourInfo[0]?.labourQuantity +
                servicePlan.totalSundriesPrices;
          }

          return "";
        });
      }
      return total;
    },
    [
      filteredPlanInfo,
      underServicePlan,
      kmFromSlider,
      kmToSlider,
      TotalDistanceTravelKm,
    ]
  );

  //For sliding the total at the bottom of the screen

  const [showWithSlider, setShowWithSlider] = useState(true);
  const [slideDirection, setSlideDirection] = useState("in");
  let touchStartX = 0;

  const handleSwipe = () => {
    if (slideDirection === "in" || slideDirection === "in-after-out") {
      setShowWithSlider(true);
      setSlideDirection("out");
    } else {
      setShowWithSlider(false);
      setSlideDirection("in-after-out");
    }
  };

  const totalServiceCost = showWithSlider
    ? getGrandTotalMob(0)
    : getGrandTotalMob(0) / Number(kmToSlider);

  const totalServiceCostPerKm = showWithSlider ? (
    <></>
  ) : (
    <>
      <p className="PerKM">/ Km</p>
    </>
  );

  const totalServiceCosPerKm = showWithSlider ? <></> : <></>;

  const [calcDuration, setCalcDuration] = useState(0);

  useEffect(() => {
    setCalcDuration(Number(kmToSlider - kmFromSlider));
  }, [kmFromSlider, kmToSlider]);

  //The whatsApp sharing Icon TO share the selected screen

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team.\n\n I have a question on the Service Cost screen of the\n\n  ${
        uiState.selectedVehicle.make || ""
      } ${uiState.selectedVehicle.model || ""} \n\n ${
        uiState.selectedVehicle?.vehicleData?.v_desc || ""
      } \n\n  G${uiState.selectedVehicle.g_id || ""}M${
        uiState.selectedVehicle.m_id || ""
      }V${uiState.selectedVehicle.v_id || ""} \n\n*My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  const getFileAge = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].latestFile;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const dataSourceFile = useCallback(
    (gCode: number | undefined) => {
      const result = uiState.fileAgeData?.filter(
        (item) => item.gCode === gCode
      );
      if (result && result.length > 0) {
        return result[0].dataSource;
      } else {
        return 0;
      }
    },
    [uiState]
  );

  const Charts = useMediaQuery({ minWidth: 1200 });

  return (
    <>
      {sessionState.user?.username === "" ? (
        <IonContent className="contentPage">
          <div className="innerContent">
            <SubPageHeading
              onBack={onBackPress}
              vehicle={uiState.selectedVehicle}
            ></SubPageHeading>
            <p className="Subscription" onClick={onLogOut}>
              Create Account or Log in
            </p>
          </div>
        </IonContent>
      ) : (
        <GlobalGrid
          currentPageTitle="SERVICE COST"
          closeCurrentPage={() => onBackPress()}
        >
          <div className="detailContents">
            {sessionState.user?.username === "" ? (
              <IonContent className="contentPage">
                <div className="innerContent">
                  <SubPageHeading
                    onBack={onBackPress}
                    vehicle={uiState.selectedVehicle}
                  ></SubPageHeading>
                  <p className="Subscription" onClick={onLogOut}>
                    Create Account or Log in
                  </p>
                </div>
              </IonContent>
            ) : (
              <>
                <IonRow style={{ width: "100%" }}>
                  <SubPageHeading
                    onBack={onBackPress}
                    vehicle={uiState.selectedVehicle}
                  ></SubPageHeading>
                </IonRow>

                <div className="genericHolder">
                  <IonRow className="planDropDown">
                    <span className="dropDownIcon">
                      <IonIcon
                        icon={showPlan == false ? chevronForward : chevronDown}
                        onClick={() => {
                          setShowPlan(!showPlan);
                          handleButtonClick();
                        }}
                      ></IonIcon>
                    </span>
                    <span className="textDropDown">LIFESPAN SETTINGS</span>
                    <span className="activeState">
                      {showServiceData ? "ACTIVATED" : "DEACTIVATED"}
                    </span>
                    <div className="toggleState">
                      <IonToggle
                        checked={showServiceData}
                        className="ServiceCostToggleBtn"
                        onIonChange={(e) =>
                          setShowServiceData(!showServiceData)
                        }
                      />
                    </div>
                  </IonRow>
                  {showPlan && (
                    <>
                      <section className="sliderContainer">
                        <span id="sliderContainerACtual">
                          <div className="digi">
                            <span>
                              FROM:
                              <h1>{kmFromSlider}</h1>
                            </span>
                            <span id="digiRight">
                              TO:
                              <h1>{kmToSlider}</h1>
                            </span>
                          </div>
                          <IonRange
                            id="slider"
                            aria-label="Dual Knobs Range"
                            dualKnobs={true}
                            min={0}
                            max={
                              uiState.selectedVehicle?.data?.servicePlanTotals
                                ?.result?.servicePlans &&
                              uiState.selectedVehicle?.data?.servicePlanTotals
                                ?.result?.servicePlans?.length > 0
                                ? uiState.selectedVehicle?.data
                                    ?.servicePlanTotals?.result?.servicePlans[
                                    uiState.selectedVehicle?.data
                                      ?.servicePlanTotals?.result?.servicePlans
                                      .length - 1
                                  ].interval
                                : 300000
                            }
                            step={1000}
                            onIonChange={(ev: any) => {
                              const lowerValue = isNaN(ev.detail.value.lower)
                                ? 0
                                : ev.detail.value.lower;
                              const upperValue = isNaN(ev.detail.value.upper)
                                ? 0
                                : ev.detail.value.upper;
                              setKmToSlider(upperValue);
                              setKmFromSlider(lowerValue);
                            }}
                            value={{
                              upper: kmToSlider,
                              lower: kmFromSlider,
                            }}
                          ></IonRange>

                          <div className="digi">
                            <span>TRAVELLING:</span>
                            <span id="digiRight">
                              <h1>
                                {rangeValue}
                                /YR
                              </h1>
                            </span>
                          </div>

                          <IonRange
                            id="slider"
                            step={500}
                            min={2500}
                            max={100000}
                            value={rangeValue}
                            onIonChange={handleRangeChange}
                          ></IonRange>

                          <div className="digi">
                            <span>ANNUAL PRICE INCREASE</span>
                            <span id="digiRight">
                              <h1>{Inflation}%</h1>
                            </span>
                          </div>

                          <IonRange
                            id="slider"
                            step={0.25}
                            min={0}
                            max={30}
                            value={Inflation}
                            onIonChange={handleInflationChange}
                          ></IonRange>
                        </span>
                      </section>
                    </>
                  )}

                  <IonRow className="hiddenPlanDetails">
                    <div>
                      <span id="actualPlans" className="ActualPlanDetails">
                        The report below covers a lifespan of{" "}
                        <span style={{ color: "red" }}>{calcDuration}</span>{" "}
                        kilometres or{" "}
                        <span style={{ color: "red" }}>
                          {Number(
                            (Number(calcDuration) / rangeValue) * 12
                          ).toFixed(0)}
                        </span>{" "}
                        months. The model adheres to a{" "}
                        <span style={{ color: "red" }}>
                          <span style={{ textTransform: "lowercase" }}>
                            {
                              uiState.selectedVehicle?.data?.modelSpecification
                                .result.service_maintenance_warranty
                                .service_plan_type
                            }
                          </span>{" "}
                          service schedule
                        </span>{" "}
                        , and includes a servicing plan covering costs up to
                        <span
                          style={{ textTransform: "lowercase", color: "red" }}
                        >
                          {" "}
                          {format(
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty.service_plan,
                            Format.Number
                          )}
                        </span>{" "}
                        kms or
                        <span
                          style={{ textTransform: "lowercase", color: "red" }}
                        >
                          {" "}
                          {
                            uiState.selectedVehicle?.data?.modelSpecification
                              .result.service_maintenance_warranty
                              .service_plan_months
                          }
                        </span>{" "}
                        mnths. The cost calculation below has the mentioned
                        service plan{" "}
                        <span style={{ color: "red" }}>
                          {showServiceData ? "activated" : "deactivated"}
                        </span>{" "}
                        and includes an annual price increase of
                        <span style={{ color: "red" }}> {Inflation}%</span>
                      </span>
                    </div>
                  </IonRow>
                </div>
                <br />
                <div className="scrollable">
                  <IonGrid>
                    {/* On mobile, display components in a row */}
                    <IonRow>
                      <IonCol
                        size="11.5"
                        sizeXs="11.5"
                        sizeMd="11.5"
                        sizeLg="12"
                        sizeXl="3.8"
                      >
                        {" "}
                        {/* Each component takes half the width on desktop */}
                        <IonGrid className="sp-table">
                          {!showServiceItem && (
                            <IonRow>
                              <IonCol size="0.1"></IonCol>
                              <IonCol size="3.9" className="gridhead">
                                <span className="FontMobileSpecs">
                                  INTERVAL
                                </span>
                              </IonCol>
                              <IonCol size="1.9" className="gridhead">
                                <span className="FontMobileSpecs"> Parts</span>
                              </IonCol>
                              <IonCol size="1.9" className="gridhead">
                                <span className="FontMobileSpecs"> Fluids</span>
                              </IonCol>
                              <IonCol size="2" className="gridhead">
                                <span className="FontMobileSpecs">
                                  {" "}
                                  Labour{" "}
                                </span>
                              </IonCol>
                              <IonCol size="2" className="gridhead">
                                <span className="FontMobileSpecs"> Total </span>
                              </IonCol>
                            </IonRow>
                          )}
                          {filteredPlanInfo?.map((servicePlan, index) => (
                            <React.Fragment key={"row" + index}>
                              {servicePlan?.attributes.interval >=
                                kmFromSlider &&
                              servicePlan?.attributes.interval <= kmToSlider ? (
                                <>
                                  {selectedInterval !==
                                    servicePlan?.attributes.interval && (
                                    <IonRow
                                      onClick={() => {
                                        handleIntervalClick(
                                          servicePlan?.attributes.interval
                                        );
                                      }}
                                    >
                                      <IonCol size="0.1"></IonCol>
                                      <IonCol size="3.9" className="gridfirst">
                                        <span
                                          className="FontMobileService"
                                          id="intervalValue"
                                        >
                                          {/* {servicePlan.interval} */}
                                          {selectedInterval !==
                                            servicePlan?.attributes.interval &&
                                            //The selectedInterval to animate smooth and nicely
                                            servicePlan?.attributes.interval}

                                          <span className="MonthsInterval">
                                            {Number(
                                              (Number(
                                                servicePlan?.attributes.interval
                                              ) /
                                                rangeValue) *
                                                12
                                            ).toFixed(0)}{" "}
                                            mnts
                                          </span>
                                        </span>
                                      </IonCol>
                                      <IonCol
                                        size="1.9"
                                        className="grid TableHeight"
                                      >
                                        <span className="FontMobileService">
                                          {showServiceData
                                            ? Number(
                                                uiState.selectedVehicle?.data
                                                  ?.modelSpecification.result
                                                  .service_maintenance_warranty
                                                  .service_plan ?? "0"
                                              ) >=
                                              servicePlan?.attributes.interval
                                              ? 0 ||
                                                Number(
                                                  uiState.selectedVehicle?.data
                                                    ?.modelSpecification.result
                                                    .service_maintenance_warranty
                                                    .service_plan ?? "0"
                                                ) === 1000000
                                                ? format(
                                                    servicePlan.totalPartPrices,
                                                    Format.Currency
                                                  )
                                                : Number(
                                                    (Number(
                                                      servicePlan?.attributes
                                                        .interval
                                                    ) /
                                                      rangeValue) *
                                                      12
                                                  ) >
                                                  Number(
                                                    uiState.selectedVehicle
                                                      ?.data?.modelSpecification
                                                      .result
                                                      .service_maintenance_warranty
                                                      .service_plan_months
                                                  )
                                                ? format(
                                                    servicePlan.totalPartPrices,
                                                    Format.Currency
                                                  )
                                                : 0
                                              : format(
                                                  servicePlan.totalPartPrices,
                                                  Format.Currency
                                                )
                                            : format(
                                                servicePlan.totalPartPrices,
                                                Format.Currency
                                              )}
                                        </span>
                                      </IonCol>
                                      <IonCol size="1.9" className="grid">
                                        <span className="FontMobileService">
                                          {showServiceData
                                            ? Number(
                                                uiState.selectedVehicle?.data
                                                  ?.modelSpecification.result
                                                  .service_maintenance_warranty
                                                  .service_plan ?? "0"
                                              ) >=
                                              servicePlan?.attributes.interval
                                              ? 0 ||
                                                Number(
                                                  uiState.selectedVehicle?.data
                                                    ?.modelSpecification.result
                                                    .service_maintenance_warranty
                                                    .service_plan ?? "0"
                                                ) === 1000000
                                                ? format(
                                                    servicePlan.totalFluidPrices,
                                                    Format.Currency
                                                  )
                                                : Number(
                                                    (Number(
                                                      servicePlan?.attributes
                                                        .interval
                                                    ) /
                                                      rangeValue) *
                                                      12
                                                  ) >
                                                  Number(
                                                    uiState.selectedVehicle
                                                      ?.data?.modelSpecification
                                                      .result
                                                      .service_maintenance_warranty
                                                      .service_plan_months
                                                  )
                                                ? format(
                                                    servicePlan.totalFluidPrices,
                                                    Format.Currency
                                                  )
                                                : 0
                                              : format(
                                                  servicePlan.totalFluidPrices,
                                                  Format.Currency
                                                )
                                            : format(
                                                servicePlan.totalFluidPrices,
                                                Format.Currency
                                              )}
                                        </span>
                                      </IonCol>
                                      <IonCol size="2" className="grid">
                                        <span className="FontMobileService">
                                          {showServiceData
                                            ? Number(
                                                uiState.selectedVehicle?.data
                                                  ?.modelSpecification.result
                                                  .service_maintenance_warranty
                                                  .service_plan ?? "0"
                                              ) >=
                                              servicePlan?.attributes.interval
                                              ? 0 ||
                                                Number(
                                                  uiState.selectedVehicle?.data
                                                    ?.modelSpecification.result
                                                    .service_maintenance_warranty
                                                    .service_plan ?? "0"
                                                ) === 1000000
                                                ? format(
                                                    servicePlan.labourInfo[0]
                                                      ?.labourQuantity *
                                                      servicePlan.totalLabourPrices +
                                                      servicePlan.totalSundriesPrices,
                                                    Format.Currency
                                                  )
                                                : Number(
                                                    (Number(
                                                      servicePlan?.attributes
                                                        .interval
                                                    ) /
                                                      rangeValue) *
                                                      12
                                                  ) >
                                                  Number(
                                                    uiState.selectedVehicle
                                                      ?.data?.modelSpecification
                                                      .result
                                                      .service_maintenance_warranty
                                                      .service_plan_months
                                                  )
                                                ? format(
                                                    servicePlan.labourInfo[0]
                                                      ?.labourQuantity *
                                                      servicePlan.totalLabourPrices +
                                                      servicePlan.totalSundriesPrices,
                                                    Format.Currency
                                                  )
                                                : 0
                                              : format(
                                                  servicePlan.labourInfo[0]
                                                    ?.labourQuantity *
                                                    servicePlan.totalLabourPrices +
                                                    servicePlan.totalSundriesPrices,
                                                  Format.Currency
                                                )
                                            : format(
                                                servicePlan.labourInfo[0]
                                                  ?.labourQuantity *
                                                  servicePlan.totalLabourPrices +
                                                  servicePlan.totalSundriesPrices,
                                                Format.Currency
                                              )}
                                        </span>
                                      </IonCol>
                                      <IonCol
                                        size="2"
                                        className="grid TableHeight"
                                      >
                                        <span className="FontMobileService">
                                          {showServiceData
                                            ? Number(
                                                uiState.selectedVehicle?.data
                                                  ?.modelSpecification.result
                                                  .service_maintenance_warranty
                                                  .service_plan ?? "0"
                                              ) >=
                                              servicePlan?.attributes.interval
                                              ? 0 ||
                                                Number(
                                                  uiState.selectedVehicle?.data
                                                    ?.modelSpecification.result
                                                    .service_maintenance_warranty
                                                    .service_plan ?? "0"
                                                ) === 1000000
                                                ? format(
                                                    servicePlan.totalFluidPrices +
                                                      servicePlan.totalPartPrices +
                                                      servicePlan.labourInfo[0]
                                                        ?.labourQuantity *
                                                        servicePlan.totalLabourPrices +
                                                      servicePlan.totalSundriesPrices,
                                                    Format.Currency
                                                  )
                                                : Number(
                                                    (Number(
                                                      servicePlan?.attributes
                                                        .interval
                                                    ) /
                                                      rangeValue) *
                                                      12
                                                  ) >
                                                  Number(
                                                    uiState.selectedVehicle
                                                      ?.data?.modelSpecification
                                                      .result
                                                      .service_maintenance_warranty
                                                      .service_plan_months
                                                  )
                                                ? format(
                                                    servicePlan.totalFluidPrices +
                                                      servicePlan.totalPartPrices +
                                                      servicePlan.labourInfo[0]
                                                        ?.labourQuantity *
                                                        servicePlan.totalLabourPrices +
                                                      servicePlan.totalSundriesPrices,
                                                    Format.Currency
                                                  )
                                                : 0
                                              : format(
                                                  servicePlan.totalFluidPrices +
                                                    servicePlan.totalPartPrices +
                                                    servicePlan.labourInfo[0]
                                                      ?.labourQuantity *
                                                      servicePlan.totalLabourPrices +
                                                    servicePlan.totalSundriesPrices,
                                                  Format.Currency
                                                )
                                            : format(
                                                servicePlan.totalFluidPrices +
                                                  servicePlan.totalPartPrices +
                                                  servicePlan.labourInfo[0]
                                                    ?.labourQuantity *
                                                    servicePlan.totalLabourPrices +
                                                  servicePlan.totalSundriesPrices,
                                                Format.Currency
                                              )}
                                        </span>
                                      </IonCol>
                                    </IonRow>
                                  )}
                                  {/*HIDDEN TABLE */}
                                  {selectedInterval ===
                                  servicePlan.attributes.interval ? (
                                    <table id="servicePlanDetails">
                                      <thead>
                                        <tr>
                                          <td>INTERVAL</td>
                                          <td>PRICE</td>
                                          <td>QTY</td>
                                          <td>TOTAL</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {" "}
                                            <span
                                              onClick={() => {
                                                handleIntervalClick(
                                                  servicePlan?.attributes
                                                    .interval
                                                );
                                              }}
                                            >
                                              <img
                                                src={chevronBackCircleSharp}
                                                alt=""
                                                className="backHidden"
                                              />
                                              {servicePlan?.attributes.interval}
                                            </span>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr id="headerRow">
                                          <td>PARTS</td>
                                        </tr>

                                        {servicePlan.partsInfo.map(
                                          (
                                            sp: {
                                              price: any;
                                              quantity: any;
                                              basketItemName: any;
                                              partNumber: any;
                                            },
                                            index: number
                                          ) => {
                                            const PartsnextPrice =
                                              servicePlan.nextPrices[index];

                                            return (
                                              <tr className="partDetailsRow">
                                                <span id="left">
                                                  {sp.basketItemName}{" "}
                                                  {sp.partNumber}
                                                </span>
                                                <span
                                                  id="right
                                               "
                                                >
                                                  <p>
                                                    {" "}
                                                    {Number(
                                                      PartsnextPrice /
                                                        sp.quantity
                                                    ).toFixed(2)}
                                                  </p>
                                                  <p>{sp.quantity}</p>
                                                  <p>
                                                    {format(
                                                      PartsnextPrice,
                                                      Format.Currency
                                                    )}
                                                  </p>
                                                </span>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr id="headerRow">
                                          <td>FLUIDS</td>
                                        </tr>
                                        {servicePlan.fluidsInfo.map(
                                          (
                                            sp: {
                                              price: any;
                                              quantity: any;
                                              partNumber: any;
                                              description: any;
                                            },
                                            index: number
                                          ) => {
                                            const FluidnextPrice =
                                              servicePlan.fluidNextPrices[
                                                index
                                              ];
                                            return (
                                              <tr className="partDetailsRow">
                                                <span id="left">
                                                  {sp.description}{" "}
                                                  {sp.partNumber}
                                                </span>
                                                <span
                                                  id="right
                                               "
                                                >
                                                  <p>
                                                    {format(
                                                      FluidnextPrice /
                                                        sp.quantity,
                                                      Format.Currency
                                                    )}{" "}
                                                  </p>
                                                  <p>{sp.quantity}</p>
                                                  <p>
                                                    {format(
                                                      FluidnextPrice,
                                                      Format.Currency
                                                    )}
                                                  </p>
                                                </span>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr id="headerRow">
                                          <td>LABOUR</td>
                                        </tr>
                                        {servicePlan.labourInfo.map(
                                          (sp: { labourQuantity: any }) => {
                                            const FluidnextPrice =
                                              servicePlan.fluidNextPrices[
                                                index
                                              ];
                                            return (
                                              <>
                                                <tr className="partDetailsRow">
                                                  <span id="left">
                                                    LABOUR RATE
                                                  </span>
                                                  <span
                                                    id="right
                                               "
                                                  >
                                                    <p>
                                                      {" "}
                                                      {format(
                                                        servicePlan.totalLabourPrices,
                                                        Format.Currency
                                                      )}
                                                    </p>
                                                    <p>{sp.labourQuantity}</p>
                                                    <p>
                                                      {" "}
                                                      {format(
                                                        servicePlan
                                                          .labourInfo[0]
                                                          ?.labourQuantity *
                                                          servicePlan.totalLabourPrices,
                                                        Format.Currency
                                                      )}
                                                    </p>
                                                  </span>
                                                </tr>
                                                <tr className="partDetailsRow">
                                                  <span id="left">
                                                    SUNDRIES RATE
                                                  </span>
                                                  <span
                                                    id="right
                                               "
                                                  >
                                                    <p>
                                                      {" "}
                                                      {format(
                                                        servicePlan.totalSundriesPrices,
                                                        Format.Currency
                                                      )}
                                                    </p>
                                                    <p></p>
                                                    <p>
                                                      {format(
                                                        servicePlan.totalSundriesPrices,
                                                        Format.Currency
                                                      )}
                                                    </p>
                                                  </span>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                        <tr id="headerRow">
                                          <td>TOTAL</td>
                                        </tr>
                                        <tr className="partDetailsRow-vat">
                                          <span id="left"></span>
                                          <span
                                            id="right
                                               "
                                          >
                                            <p></p>
                                            <p>EXC VAT</p>
                                            <p className="text-nowrap">
                                              {format(
                                                servicePlan.totalFluidPrices +
                                                  servicePlan.totalPartPrices +
                                                  servicePlan.labourInfo[0]
                                                    ?.labourQuantity *
                                                    servicePlan.totalLabourPrices +
                                                  servicePlan.totalSundriesPrices,
                                                Format.Currency
                                              )}
                                            </p>
                                          </span>
                                        </tr>
                                        <tr className="partDetailsRow-vat">
                                          <span id="left"></span>
                                          <span
                                            id="right
                                               "
                                          >
                                            <p></p>
                                            <p>INC VAT</p>
                                            <p className="text-nowrap-vat">
                                              {format(
                                                ((servicePlan.totalFluidPrices +
                                                  servicePlan.totalPartPrices +
                                                  servicePlan.labourInfo[0]
                                                    ?.labourQuantity *
                                                    servicePlan.totalLabourPrices +
                                                  servicePlan.totalSundriesPrices) *
                                                  15) /
                                                  100 +
                                                  (servicePlan.totalFluidPrices +
                                                    servicePlan.totalPartPrices +
                                                    servicePlan.labourInfo[0]
                                                      ?.labourQuantity *
                                                      servicePlan.totalLabourPrices +
                                                    servicePlan.totalSundriesPrices),
                                                Format.Currency
                                              )}
                                            </p>
                                          </span>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : null}
                            </React.Fragment>
                          ))}
                        </IonGrid>
                      </IonCol>
                      <IonCol
                        size="11.5"
                        sizeXs="11.5"
                        sizeMd="11.5"
                        sizeLg="12"
                        sizeXl="3.8"
                      >
                        <IonRow>
                          {Charts ? (
                            <>
                              <Bar
                                data={getChartData()}
                                options={Desktopoptions}
                                height={300}
                                width={500}
                              />
                              <br />

                              <Line
                                options={DesktopChartOptions}
                                height={300}
                                width={500}
                                data={getDesktopAccumaltiveChartData()}
                              />
                            </>
                          ) : (
                            <>
                              <Bar
                                options={options}
                                height={100}
                                width={850}
                                data={getChartData()}
                              />
                              <br />
                              <Line
                                options={desktopChartOptions}
                                height={100}
                                width={850}
                                data={getDesktopAccumaltiveChartData()}
                              />
                            </>
                          )}
                        </IonRow>
                      </IonCol>

                      <IonCol
                        size="11.5"
                        sizeXs="11.5"
                        sizeMd="11.5"
                        sizeLg="12"
                        sizeXl="3.8"
                        className="filters-desktop"
                      >
                        <IonRow className="ion-justify-content-end">
                          <div>
                            <span className="textDropDown">
                              LIFE SPAN SETTINGS
                            </span>
                          </div>
                          <br />
                          <div className="Filters">
                            <>
                              <section className="sliderContainer">
                                <span id="sliderContainerACtual">
                                  <div className="digi">
                                    <span className="FontMobileSpecs">
                                      FROM:
                                      <h1>{kmFromSlider}</h1>
                                    </span>
                                    <span id="digiRight">
                                      TO:
                                      <h1>{kmToSlider}</h1>
                                    </span>
                                  </div>
                                  <IonRange
                                    id="slider"
                                    aria-label="Dual Knobs Range"
                                    dualKnobs={true}
                                    min={0}
                                    max={
                                      uiState.selectedVehicle?.data
                                        ?.servicePlanTotals?.result
                                        ?.servicePlans &&
                                      uiState.selectedVehicle?.data
                                        ?.servicePlanTotals?.result
                                        ?.servicePlans?.length > 0
                                        ? uiState.selectedVehicle?.data
                                            ?.servicePlanTotals?.result
                                            ?.servicePlans[
                                            uiState.selectedVehicle?.data
                                              ?.servicePlanTotals?.result
                                              ?.servicePlans.length - 1
                                          ].interval
                                        : 300000
                                    }
                                    step={1000}
                                    onIonChange={(ev: any) => {
                                      const lowerValue = isNaN(
                                        ev.detail.value.lower
                                      )
                                        ? 0
                                        : ev.detail.value.lower;
                                      const upperValue = isNaN(
                                        ev.detail.value.upper
                                      )
                                        ? 0
                                        : ev.detail.value.upper;
                                      setKmToSlider(upperValue);
                                      setKmFromSlider(lowerValue);
                                    }}
                                    value={{
                                      upper: kmToSlider,
                                      lower: kmFromSlider,
                                    }}
                                  ></IonRange>

                                  <div className="digi">
                                    <span>TRAVELLING:</span>
                                    <span id="digiRight">
                                      <h1>
                                        {rangeValue}
                                        /YR
                                      </h1>
                                    </span>
                                  </div>

                                  <IonRange
                                    id="slider"
                                    step={500}
                                    min={2500}
                                    max={100000}
                                    value={rangeValue}
                                    onIonChange={handleRangeChange}
                                  ></IonRange>

                                  <div className="digi">
                                    <span>ANNUAL PRICE INCREASE</span>
                                    <span id="digiRight">
                                      <h1>{Inflation}%</h1>
                                    </span>
                                  </div>

                                  <IonRange
                                    id="slider"
                                    step={0.25}
                                    min={0}
                                    max={30}
                                    value={Inflation}
                                    onIonChange={handleInflationChange}
                                  ></IonRange>
                                </span>
                              </section>
                            </>

                            <IonRow className="hiddenPlanDetails">
                              <div>
                                <span
                                  id="actualPlans"
                                  className="ActualPlanDetails"
                                >
                                  The report below covers a lifespan of{" "}
                                  <span style={{ color: "red" }}>
                                    {calcDuration}
                                  </span>{" "}
                                  kilometres or{" "}
                                  <span style={{ color: "red" }}>
                                    {Number(
                                      (Number(kmToSlider) / rangeValue) * 12
                                    ).toFixed(0)}{" "}
                                  </span>{" "}
                                  months with annual price increase of
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {Inflation}%
                                  </span>
                                </span>
                              </div>
                            </IonRow>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <span className="textDropDown">
                              SERVICE PLAN SETTINGS
                            </span>
                          </div>
                          <div className="Filters">
                            <section className="sliderContainer">
                              <span id="sliderContainerACtual">
                                <IonCol className="activeState ion-justify-content-end">
                                  {showServiceData
                                    ? "ACTIVATED"
                                    : "DEACTIVATED"}
                                  <div className="toggleState">
                                    <IonToggle
                                      checked={showServiceData}
                                      className="ServiceCostToggleBtn"
                                      onIonChange={(e) =>
                                        setShowServiceData(!showServiceData)
                                      }
                                    />
                                  </div>
                                </IonCol>
                              </span>
                            </section>
                            <IonRow className="hiddenPlanDetails">
                              <div>
                                <span
                                  id="actualPlans"
                                  className="ActualPlanDetails"
                                >
                                  The model follows a{" "}
                                  <span style={{ color: "red" }}>
                                    <span
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {
                                        uiState.selectedVehicle?.data
                                          ?.modelSpecification.result
                                          .service_maintenance_warranty
                                          .service_plan_type
                                      }{" "}
                                      service schedule
                                    </span>
                                  </span>{" "}
                                  and if there's a service plan included, it's{" "}
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {showServiceData
                                      ? "activated"
                                      : "deactivated"}
                                  </span>{" "}
                                  in the report
                                </span>
                              </div>
                            </IonRow>
                          </div>
                        </IonRow>
                      </IonCol>
                      <IonCol size="12">
                        <DataAssurance />
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <Whatsapp shareClicked={handleShareClick} />
                </div>
              </>
            )}
          </div>
          {/* <div className="totalsDisplay">
              <span> TOTAL SERVICE COST</span>
              <span>{format(getGrandTotalMobil(0), Format.Currency)}</span>
              <span className="ServiceExcluding"> EXCLUDING VAT</span>
            </div> */}
          <div
            className={`totalsDisplay ${
              slideDirection === "in" ? "slideIn" : "slideOut"
            }`}
            onTouchStart={(e) => {
              touchStartX = e.touches[0].clientX;
            }}
            onTouchEnd={(e) => {
              const touchEndX = e.changedTouches[0].clientX;
              const swipeDistance = touchEndX - touchStartX;
              const swipeThreshold = 50;

              if (Math.abs(swipeDistance) > swipeThreshold) {
                handleSwipe();
              }
            }}
          >
            <span> TOTAL SERVICE COST</span>
            <span>
              <IonIcon
                src={chevronBack}
                onClick={(e) => {
                  handleSwipe();
                }}
                id="Total-prev-arrow"
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
              <div className="ExcVat">
                <span></span>
                {format(totalServiceCost, Format.Currency)}{" "}
                <small className="Text-excvat">EXC VAT</small>
              </div>
              <div className="InclVat">
                <span></span>{" "}
                {format(
                  (totalServiceCost * 15) / 100 + totalServiceCost,
                  Format.Currency
                )}{" "}
              </div>
              <p className="InclVat-per-km">{totalServiceCostPerKm}</p>
              <p className="ExcVat">{totalServiceCosPerKm}</p>

              <IonIcon
                src={chevronForward}
                onClick={(e) => {
                  handleSwipe();
                }}
                id="Total-next-arrow"
                style={{ width: "80px", height: "80px", color: "gray" }}
              ></IonIcon>
            </span>
            <span className="Text-incvat">INC VAT</span>
          </div>
        </GlobalGrid>
      )}

      <MessageModal
        visible={modalVisible}
        serviceInfoModal={true}
        onPress={onModalPress}
      />
    </>
  );
};

export default Services;
