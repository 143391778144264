import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import DesktopVehicleLabel from '../../components/desktop-vehicle-label';
import Header from '../../components/header';
import Loading from '../../components/loading';
import SideMenu from '../../components/side-menu';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens, Format } from '../../enums';
import '../../Global.css';
import { RootState } from '../../redux/store';
import { setScreen } from '../../redux/ui/uiSlice';
import { Attributes } from '../../interfaces/modelSpecification';
import { SelectedVehicle } from '../../interfaces';
import { CompetitorData } from '../../interfaces/competitorData';
import { format } from '../../utils';
import { Line } from 'react-chartjs-2';
import DesktopChartLegend from '../../components/desktop-chart-legend';
import DesktopChartLegendModel from '../../components/desktop-chart-legend-model';
import DesktopChartLegendGroup from '../../components/desktop-chart-legend-group';

const desktopChartOptions = {
  maintainAspectRatio: false,
  tension: 0.5,
  spanGaps: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const Depreciation: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [initialised, setInitialised] = useState(false);

  const onBackPress = useCallback(() => {
    dispatch(setScreen(AppScreens.Depreciation));
  }, [dispatch]);

  const getSelectedVehicle = (modelAttributes: Attributes) => {
    const vehicle: SelectedVehicle = {
      make: modelAttributes.make,
      model: modelAttributes.model,
      variant: modelAttributes.variant,
      g_id: Number(modelAttributes.G),
      m_id: Number(modelAttributes.M),
      v_id: Number(modelAttributes.V),
      attributes: modelAttributes,
      variant_id: '',
    };
    return vehicle;
  };

  const getData = useCallback(
    (index: number): CompetitorData | undefined => {
      if (index === 0) {
        return uiState.selectedVehicle?.data;
      } else if (
        uiState.competitors &&
        uiState.competitors.length > index - 1
      ) {
        return uiState.competitors[index - 1];
      } else {
        return undefined;
      }
    },
    [uiState.selectedVehicle, uiState.competitors]
  );

  const getIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 30; i++) {
      const fromValue = i * 10000;
      if (fromValue <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(fromValue);
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getChartIntervals = useCallback(() => {
    const list = [];
    for (let i = 0; i <= 30; i++) {
      const toValue = i * 10 + 10;
      if (i * 10000 <= (uiState?.customReportParams?.totalLifeSpan ?? 0)) {
        list.push(toValue + 'k');
      }
    }
    return list;
  }, [uiState.customReportParams]);

  const getDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(col)?.depreciation;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] != 0) data.push(value[index]);
          else data.push(null);
        }
      });
      return data;
    },
    [getData, getIntervals]
  );

  const getModelDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(col)?.depreciationModel;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] != 0) data.push(value[index]);
          else data.push(null);
        }
      });
      return data;
    },
    [getData, getIntervals]
  );

  const getGroupDataset = useCallback(
    (col: number) => {
      const data: Array<any> = [];
      getIntervals().map((interval, index) => {
        const plans = getData(col)?.depreciationGroup;
        let value = Object.values(plans ?? {});
        if (index > 0) {
          if (value[index] != 0) data.push(value[index]);
          else data.push(null);
        }
      });
      return data;
    },
    [getData, getIntervals]
  );

  const getDesktopChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            ' ' +
            getData(0)?.modelSpecification.attributes.model +
            ' ' +
            getData(0)?.modelSpecification.attributes.variant,
          data: getDataset(0),
          borderColor: '#293eff',
          backgroundColor: '#293eff',
        },
        {
          label:
            getData(1)?.modelSpecification.attributes.make +
            ' ' +
            getData(1)?.modelSpecification.attributes.model +
            ' ' +
            getData(1)?.modelSpecification.attributes.variant,
          data: getDataset(1),
          borderColor: '#ff2a2a',
          backgroundColor: '#ff2a2a',
        },
        {
          label:
            getData(2)?.modelSpecification.attributes.make +
            ' ' +
            getData(2)?.modelSpecification.attributes.model +
            ' ' +
            getData(2)?.modelSpecification.attributes.variant,
          data: getDataset(2),
          borderColor: '#fc8f00',
          backgroundColor: '#fc8f00',
        },
        {
          label:
            getData(3)?.modelSpecification.attributes.make +
            ' ' +
            getData(3)?.modelSpecification.attributes.model +
            ' ' +
            getData(3)?.modelSpecification.attributes.variant,
          data: getDataset(3),
          borderColor: '#00df17',
          backgroundColor: '#00df17',
        },
      ],
    };
    return desktopChartData;
  }, [getDataset, getData, getChartIntervals]);

  const getDesktopModelChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label:
            getData(0)?.modelSpecification.attributes.make +
            ' ' +
            getData(0)?.modelSpecification.attributes.model,
          data: getModelDataset(0),
          borderColor: '#293eff',
          backgroundColor: '#293eff',
        },
        {
          label:
            getData(1)?.modelSpecification.attributes.make +
            ' ' +
            getData(1)?.modelSpecification.attributes.model,
          data: getModelDataset(1),
          borderColor: '#ff2a2a',
          backgroundColor: '#ff2a2a',
        },
        {
          label:
            getData(2)?.modelSpecification.attributes.make +
            ' ' +
            getData(2)?.modelSpecification.attributes.model,
          data: getModelDataset(2),
          borderColor: '#fc8f00',
          backgroundColor: '#fc8f00',
        },
        {
          label:
            getData(3)?.modelSpecification.attributes.make +
            ' ' +
            getData(3)?.modelSpecification.attributes.model,
          data: getModelDataset(3),
          borderColor: '#00df17',
          backgroundColor: '#00df17',
        },
      ],
    };
    return desktopChartData;
  }, [getData, getChartIntervals, getModelDataset]);

  const getDesktopGroupChartData = useCallback(() => {
    const desktopChartData = {
      labels: getChartIntervals(),
      datasets: [
        {
          label: getData(0)?.modelSpecification.attributes.make,
          data: getGroupDataset(0),
          borderColor: '#293eff',
          backgroundColor: '#293eff',
        },
        {
          label: getData(1)?.modelSpecification.attributes.make,
          data: getGroupDataset(1),
          borderColor: '#ff2a2a',
          backgroundColor: '#ff2a2a',
        },
        {
          label: getData(2)?.modelSpecification.attributes.make,
          data: getGroupDataset(2),
          borderColor: '#fc8f00',
          backgroundColor: '#fc8f00',
        },
        {
          label: getData(3)?.modelSpecification.attributes.make,
          data: getGroupDataset(3),
          borderColor: '#00df17',
          backgroundColor: '#00df17',
        },
      ],
    };
    return desktopChartData;
  }, [getData, getChartIntervals, getGroupDataset]);

  const getDepreciation = useCallback(
    (index: number) => {
      switch (uiState.customReportParams.totalLifeSpan) {
        case 10000:
          return getData(index)?.depreciation?.km_10k;
        case 20000:
          return getData(index)?.depreciation?.km_20k;
        case 30000:
          return getData(index)?.depreciation?.km_30k;
        case 40000:
          return getData(index)?.depreciation?.km_40k;
        case 50000:
          return getData(index)?.depreciation?.km_50k;
        case 60000:
          return getData(index)?.depreciation?.km_60k;
        case 70000:
          return getData(index)?.depreciation?.km_70k;
        case 80000:
          return getData(index)?.depreciation?.km_80k;
        case 90000:
          return getData(index)?.depreciation?.km_90k;
        case 100000:
          return getData(index)?.depreciation?.km_100k;
        case 110000:
          return getData(index)?.depreciation?.km_110k;
        case 120000:
          return getData(index)?.depreciation?.km_120k;
        case 130000:
          return getData(index)?.depreciation?.km_130k;
        case 140000:
          return getData(index)?.depreciation?.km_140k;
        case 150000:
          return getData(index)?.depreciation?.km_150k;
        case 160000:
          return getData(index)?.depreciation?.km_160k;
        case 170000:
          return getData(index)?.depreciation?.km_170k;
        case 180000:
          return getData(index)?.depreciation?.km_180k;
        case 190000:
          return getData(index)?.depreciation?.km_190k;
        case 210000:
          return getData(index)?.depreciation?.km_210k;
        case 220000:
          return getData(index)?.depreciation?.km_220k;
        case 230000:
          return getData(index)?.depreciation?.km_230k;
        case 240000:
          return getData(index)?.depreciation?.km_240k;
        case 250000:
          return getData(index)?.depreciation?.km_250k;
        case 260000:
          return getData(index)?.depreciation?.km_260k;
        case 270000:
          return getData(index)?.depreciation?.km_270k;
        case 280000:
          return getData(index)?.depreciation?.km_280k;
        case 290000:
          return getData(index)?.depreciation?.km_290k;
        case 300000:
          return getData(index)?.depreciation?.km_300k;
        default:
          return 0;
      }
    },
    [getData, uiState]
  );

  const getDepreciationEstimated = useCallback(
    (index: number, field: string) => {
      switch (field) {
        case 'vcode': {
          return getData(index)?.depreciation?.estimated_value === true
            ? '*'
            : ' ';
        }
        case 'mcode': {
          return getData(index)?.depreciationModel?.estimated_value === true
            ? '*'
            : ' ';
        }
        case 'gcode': {
          return getData(index)?.depreciationGroup?.estimated_value === true
            ? '*'
            : ' ';
        }
        default: {
          return getData(index)?.depreciation?.estimated_value === true
            ? '*'
            : ' ';
        }
      }
    },
    [getData]
  );

  const getDepreciationModel = useCallback(
    (index: number) => {
      switch (uiState.customReportParams.totalLifeSpan) {
        case 10000:
          return getData(index)?.depreciationModel?.km_10k;
        case 20000:
          return getData(index)?.depreciationModel?.km_20k;
        case 30000:
          return getData(index)?.depreciationModel?.km_30k;
        case 40000:
          return getData(index)?.depreciationModel?.km_40k;
        case 50000:
          return getData(index)?.depreciationModel?.km_50k;
        case 60000:
          return getData(index)?.depreciationModel?.km_60k;
        case 70000:
          return getData(index)?.depreciationModel?.km_70k;
        case 80000:
          return getData(index)?.depreciationModel?.km_80k;
        case 90000:
          return getData(index)?.depreciationModel?.km_90k;
        case 100000:
          return getData(index)?.depreciationModel?.km_100k;
        case 110000:
          return getData(index)?.depreciationModel?.km_110k;
        case 120000:
          return getData(index)?.depreciationModel?.km_120k;
        case 130000:
          return getData(index)?.depreciationModel?.km_130k;
        case 140000:
          return getData(index)?.depreciationModel?.km_140k;
        case 150000:
          return getData(index)?.depreciationModel?.km_150k;
        case 160000:
          return getData(index)?.depreciationModel?.km_160k;
        case 170000:
          return getData(index)?.depreciationModel?.km_170k;
        case 180000:
          return getData(index)?.depreciationModel?.km_180k;
        case 190000:
          return getData(index)?.depreciationModel?.km_190k;
        case 210000:
          return getData(index)?.depreciationModel?.km_210k;
        case 220000:
          return getData(index)?.depreciationModel?.km_220k;
        case 230000:
          return getData(index)?.depreciationModel?.km_230k;
        case 240000:
          return getData(index)?.depreciationModel?.km_240k;
        case 250000:
          return getData(index)?.depreciationModel?.km_250k;
        case 260000:
          return getData(index)?.depreciationModel?.km_260k;
        case 270000:
          return getData(index)?.depreciationModel?.km_270k;
        case 280000:
          return getData(index)?.depreciationModel?.km_280k;
        case 290000:
          return getData(index)?.depreciationModel?.km_290k;
        case 300000:
          return getData(index)?.depreciationModel?.km_300k;
        default:
          return 0;
      }
    },
    [getData, uiState]
  );

  const getDepreciationGroup = useCallback(
    (index: number) => {
      switch (uiState.customReportParams.totalLifeSpan) {
        case 10000:
          return getData(index)?.depreciationGroup?.km_10k;
        case 20000:
          return getData(index)?.depreciationGroup?.km_20k;
        case 30000:
          return getData(index)?.depreciationGroup?.km_30k;
        case 40000:
          return getData(index)?.depreciationGroup?.km_40k;
        case 50000:
          return getData(index)?.depreciationGroup?.km_50k;
        case 60000:
          return getData(index)?.depreciationGroup?.km_60k;
        case 70000:
          return getData(index)?.depreciationGroup?.km_70k;
        case 80000:
          return getData(index)?.depreciationGroup?.km_80k;
        case 90000:
          return getData(index)?.depreciationGroup?.km_90k;
        case 100000:
          return getData(index)?.depreciationGroup?.km_100k;
        case 110000:
          return getData(index)?.depreciationGroup?.km_110k;
        case 120000:
          return getData(index)?.depreciationGroup?.km_120k;
        case 130000:
          return getData(index)?.depreciationGroup?.km_130k;
        case 140000:
          return getData(index)?.depreciationGroup?.km_140k;
        case 150000:
          return getData(index)?.depreciationGroup?.km_150k;
        case 160000:
          return getData(index)?.depreciationGroup?.km_160k;
        case 170000:
          return getData(index)?.depreciationGroup?.km_170k;
        case 180000:
          return getData(index)?.depreciationGroup?.km_180k;
        case 190000:
          return getData(index)?.depreciationGroup?.km_190k;
        case 210000:
          return getData(index)?.depreciationGroup?.km_210k;
        case 220000:
          return getData(index)?.depreciationGroup?.km_220k;
        case 230000:
          return getData(index)?.depreciationGroup?.km_230k;
        case 240000:
          return getData(index)?.depreciationGroup?.km_240k;
        case 250000:
          return getData(index)?.depreciationGroup?.km_250k;
        case 260000:
          return getData(index)?.depreciationGroup?.km_260k;
        case 270000:
          return getData(index)?.depreciationGroup?.km_270k;
        case 280000:
          return getData(index)?.depreciationGroup?.km_280k;
        case 290000:
          return getData(index)?.depreciationGroup?.km_290k;
        case 300000:
          return getData(index)?.depreciationGroup?.km_300k;
        default:
          return 0;
      }
    },
    [getData, uiState]
  );

  useEffect(() => {
    if (!initialised) {
      setInitialised(true);
    }
  }, [initialised]);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          <IonRow className='desktopContent'>
            {/* <IonCol size='1' className='menu-col ion-no-padding ion-no-margin'>
              <SideMenu></SideMenu>
            </IonCol> */}
            <IonCol size='12' className='ion-no-padding ion-no-margin'>
              <DesktopContainer>
                <div className='detailContent'>
                  <IonRow className='spaceitems'>
                    <IonCol size='12' className='ion-no-padding'>
                      <span
                        className='desktopHeadingText'
                        style={{
                          color: sessionState.theme?.sidebar_background,
                        }}
                      >
                        DEPRECIATION VALUE
                      </span>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size='2'></IonCol>
                    <IonCol size='2.5' className='colBorderLeft'>
                      <DesktopVehicleLabel
                        vertical={true}
                        vehicle={uiState.selectedVehicle}
                      />
                    </IonCol>
                    {uiState.competitors?.map((competitor) => {
                      return (
                        <IonCol size='2.5' className='colBorderLeft'>
                          <DesktopVehicleLabel
                            vertical={true}
                            vehicle={getSelectedVehicle(
                              competitor.modelSpecification.attributes
                            )}
                          />
                        </IonCol>
                      );
                    })}
                  </IonRow>
                  <IonRow>
                    <IonCol size='2' className='colBorderTop'>
                      <p className='alignRight desktopLabelLower'>
                        <p>BASE PRICE</p>
                        <br />
                        <p>
                          <strong>PARAMETERS</strong>
                          <br />
                          TOTAL LIVE SPAN IN KM
                          <br />
                          TRAVELING KM/YR
                        </p>
                        <br />

                        <p>
                          <strong>DEPRECIATION %</strong>
                          <br />
                          VARIANT (V_CODE)
                          <br />
                          MODEL (M_CODE)
                          <br />
                          GROUP (G_CODE)
                        </p>
                        <br />

                        <p>
                          DEPRECIATION VALUE
                          <br />
                          RESIDUAL VALUE
                          <br /> DEPRECIATION VALUE / KM
                        </p>
                      </p>
                    </IonCol>
                    <IonCol size='2.5' className='colBorderLeft colBorderTop'>
                      <p className='alignRight desktopLabelLower'>
                        <p>
                          {format(
                            getData(0)?.modelSpecification.result.basic_specs
                              .base_price,
                            Format.Currency
                          )}
                        </p>
                        <br />
                        <p>
                          <strong>PARAMETERS</strong>
                          <br />
                          {format(
                            uiState.customReportParams.totalLifeSpan,
                            Format.Number
                          )}{' '}
                          km
                          <br />
                          {format(
                            uiState.customReportParams.annualMileage,
                            Format.Number
                          )}{' '}
                          km
                        </p>
                        <br />

                        <p>
                          <strong>
                            DEPRECIATION % at{' '}
                            {format(
                              uiState.customReportParams.totalLifeSpan,
                              Format.Number
                            )}{' '}
                            km
                          </strong>
                          <br />
                          {format(getDepreciation(0), Format.Decimal)}%{' '}
                          {getDepreciationEstimated(0, 'vcode')}
                          <br />
                          {format(
                            getDepreciationModel(0),
                            Format.Decimal
                          )}% {getDepreciationEstimated(0, 'mcode')}
                          <br />
                          {format(
                            getDepreciationGroup(0),
                            Format.Decimal
                          )}% {getDepreciationEstimated(0, 'gcode')}
                          <br />
                        </p>
                        <br />
                        <p>
                          {format(
                            Number(
                              getData(0)?.modelSpecification.result.basic_specs
                                .base_price ?? 0
                            ) *
                              (((getDepreciation(0) ?? 0) * 1) / 100),
                            Format.Currency
                          )}
                          <br />
                          {format(
                            Number(
                              getData(0)?.modelSpecification.result.basic_specs
                                .base_price ?? 0
                            ) -
                              Number(
                                getData(0)?.modelSpecification.result
                                  .basic_specs.base_price ?? 0
                              ) *
                                (((getDepreciation(0) ?? 0) * 1) / 100),
                            Format.Currency
                          )}
                          <br />
                          {format(
                            (Number(
                              getData(0)?.modelSpecification.result.basic_specs
                                .base_price ?? 0
                            ) *
                              (((getDepreciation(0) ?? 0) * 1) / 100)) /
                              (uiState.customReportParams.totalLifeSpan ?? 0),
                            Format.Currency
                          )}
                          /km
                        </p>
                      </p>
                    </IonCol>
                    {uiState.competitors?.map((_, index) => {
                      return (
                        <IonCol
                          size='2.5'
                          className='colBorderLeft colBorderTop'
                        >
                          <p className='alignRight desktopLabelLower'>
                            <p>
                              {format(
                                getData(index + 1)?.modelSpecification.result
                                  .basic_specs.base_price,
                                Format.Currency
                              )}
                            </p>
                            <br />
                            <p>
                              <strong>PARAMETERS</strong>
                              <br />
                              {format(
                                uiState.customReportParams.totalLifeSpan,
                                Format.Number
                              )}{' '}
                              km
                              <br />
                              {format(
                                uiState.customReportParams.annualMileage,
                                Format.Number
                              )}{' '}
                              km
                            </p>
                            <br />

                            <p>
                              <strong>
                                DEPRECIATION % at{' '}
                                {format(
                                  uiState.customReportParams.totalLifeSpan,
                                  Format.Number
                                )}{' '}
                                km
                              </strong>
                              <br />
                              {format(
                                getDepreciation(index + 1),
                                Format.Decimal
                              )}
                              % {getDepreciationEstimated(index + 1, 'vcode')}
                              <br />
                              {format(
                                getDepreciationModel(index + 1),
                                Format.Decimal
                              )}
                              % {getDepreciationEstimated(index + 1, 'mcode')}
                              <br />
                              {format(
                                getDepreciationGroup(index + 1),
                                Format.Decimal
                              )}
                              % {getDepreciationEstimated(index + 1, 'gcode')}
                              <br />
                            </p>
                            <br />

                            <p>
                              {format(
                                Number(
                                  getData(index + 1)?.modelSpecification.result
                                    .basic_specs.base_price ?? 0
                                ) *
                                  (((getDepreciation(index + 1) ?? 0) * 1) /
                                    100),
                                Format.Currency
                              )}
                              <br />
                              {format(
                                Number(
                                  getData(index + 1)?.modelSpecification.result
                                    .basic_specs.base_price ?? 0
                                ) -
                                  Number(
                                    getData(index + 1)?.modelSpecification
                                      .result.basic_specs.base_price ?? 0
                                  ) *
                                    (((getDepreciation(index + 1) ?? 0) * 1) /
                                      100),
                                Format.Currency
                              )}
                              <br />
                              {format(
                                (Number(
                                  getData(index + 1)?.modelSpecification.result
                                    .basic_specs.base_price ?? 0
                                ) *
                                  (((getDepreciation(index + 1) ?? 0) * 1) /
                                    100)) /
                                  (uiState.customReportParams.totalLifeSpan ??
                                    0),
                                Format.Currency
                              )}
                              /km
                            </p>
                          </p>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                  <p>
                    <strong>* - estimated values</strong>
                  </p>
                  <IonRow text-center style={{ paddingTop: 30 }}>
                    <strong>VARIANT DEPRECIATION</strong>
                  </IonRow>
                  <IonCol size='12' style={{ paddingTop: 30, height: 400 }}>
                    {initialised ? (
                      <Line
                        options={desktopChartOptions}
                        data={getDesktopChartData()}
                      />
                    ) : null}
                  </IonCol>
                  <IonCol size='12'>
                    <DesktopChartLegend />
                  </IonCol>
                  <IonRow text-center style={{ paddingTop: 30 }}>
                    <strong>MODEL DEPRECIATION</strong>
                  </IonRow>
                  <IonCol size='12' style={{ paddingTop: 30, height: 400 }}>
                    {initialised ? (
                      <Line
                        options={desktopChartOptions}
                        data={getDesktopModelChartData()}
                      />
                    ) : null}
                  </IonCol>
                  <IonCol size='12'>
                    <DesktopChartLegendModel />
                  </IonCol>
                  <IonRow text-center style={{ paddingTop: 30 }}>
                    <strong>GROUP DEPRECIATION</strong>
                  </IonRow>
                  <IonCol size='12' style={{ paddingTop: 30, height: 400 }}>
                    {initialised ? (
                      <Line
                        options={desktopChartOptions}
                        data={getDesktopGroupChartData()}
                      />
                    ) : null}
                  </IonCol>
                  <IonCol size='12'>
                    <DesktopChartLegendGroup />
                  </IonCol>
                </div>
              </DesktopContainer>
            </IonCol>
          </IonRow>
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
              
                vehicle={uiState.selectedVehicle}
              ></SubPageHeading>
            </div>
            <div className='detailContent'>
              <p>Coming soon...</p>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default Depreciation;
