import {
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getImage, manualSearch } from '../../api/mapo';
import DesktopContainer from '../../components/desktop-container';
import Header from '../../components/header';
import Loading from '../../components/loading';
import SideMenu from '../../components/side-menu';
import SubPageHeading from '../../components/sub-page-heading';
import { AppScreens } from '../../enums';
import '../../Global.css';
import { SelectedVehicle, Vehicle } from '../../interfaces';
import { RootState } from '../../redux/store';
import {
  hideLoader,
  setCompetitors,
  setCustomReportParams,
  setFromSearch,
  setScreen,
  setSearchKeyword,
  setSearchResults,
  setSelectedVehicle,
  showLoader,
} from '../../redux/ui/uiSlice';
import './style.css';
import moment from 'moment';
import DesktopHeader from '../../components/desktop-header';
import { images } from '../../assets';
import { getAllSelectedData } from '../../api/getAll';
import { decVal } from '../../utils';
import { VehicleCustomReportParams } from '../../interfaces/customReportParams';

const SearchResult: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(uiState.searchKeyword);

  // const onResultTap = useCallback(
  //   (result: Vehicle) => {
  //     const selectedVehicle: SelectedVehicle = {
  //       make: result.g_desc,
  //       model: result.m_desc,
  //       variant: result.v_desc,
  //       g_id: result.g_id,
  //       m_id: result.m_id,
  //       v_id: result.v_id,
  //       vehicleData: result,
  //     };
  //     dispatch(setFromSearch(true));
  //     dispatch(setSelectedVehicle(selectedVehicle));
  //     dispatch(setScreen(AppScreens.VehicleLanding));
  //   },
  //   [dispatch]
  // );

  const onResultTap = useCallback(
    (result: Vehicle) => {
      dispatch(showLoader());

      const gmvCode = 'G' + result.g_id + 'M' + result.m_id + 'V' + result.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competitors ?? []));

                const selectedVehicleCustomParams: VehicleCustomReportParams = {
                  retailprice: decVal(
                    allData.selected?.modelSpecification.result?.basic_specs
                      ?.base_price
                  ),
                  co2tax:0,
                  // co2tax: Number(
                  //   allData.selected?.modelSpecification.result?.engine_specs
                  //     .co2 ?? ''
                  // ),

                  // Service
                  servicestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan ?? '0'
                  ),
                  servicestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan_months ?? '0'
                  ),

                  // Maintenance
                  maintenancestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan ?? '0'
                  ),
                  maintenancestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan_months ??
                      '0'
                  ),

                  // baloonpayment: 0,
                };

                const competitorCustomParams: Array<VehicleCustomReportParams> =
                  [];

                allData.competitors?.map((competitor) => {
                  const competitorCustomParam: VehicleCustomReportParams = {
                    retailprice: decVal(
                      competitor.modelSpecification.result?.basic_specs
                        ?.base_price
                    ),
                    co2tax:0,
                    // co2tax: Number(
                    //   competitor.modelSpecification.result?.engine_specs.co2 ??
                    //     ''
                    // ),

                    // Service
                    servicestandardkm: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.service_plan ?? '0'
                    ),
                    servicestandardmnt: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.service_plan_months ??
                        '0'
                    ),

                    // Maintenance
                    maintenancestandardkm: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty.maintenance_plan ?? '0'
                    ),
                    maintenancestandardmnt: Number(
                      competitor.modelSpecification.result
                        ?.service_maintenance_warranty
                        .maintenance_plan_months ?? '0'
                    ),

                    baloonpayment: 0,
                  };
                  competitorCustomParams.push(competitorCustomParam);
                });

                dispatch(
                  setCustomReportParams({
                    ...uiState.customReportParams,
                    selectedVehicleCustomParams: selectedVehicleCustomParams,
                    competitorCustomParams: competitorCustomParams,
                  })
                );

                dispatch(hideLoader());
                dispatch(setScreen(AppScreens.VehicleLanding));
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert('API Error 1: ' + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert('API Error 2: ' + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  const onBackPress = useCallback(() => {
    if (uiState.mobileSearchType ==='Filtered Search') {
      dispatch(setScreen(AppScreens.FilteredSearchMobile));
    } else {
      dispatch(setScreen(AppScreens.Home));
    }
  }, [dispatch, uiState.mobileSearchType]);

  const onManualSearch = useCallback(() => {
    if (searchText) {
      dispatch(showLoader());
      manualSearch(searchText, uiState?.isDesktop ?? false)
        .then((manResponse) => {
          dispatch(hideLoader());
          if (manResponse.status === 200) {
            const vehicles: Array<Vehicle> = manResponse.data;
            dispatch(setSearchKeyword(searchText));
            dispatch(setSearchResults(vehicles));
          } else {
            alert(JSON.stringify(manResponse));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [dispatch, searchText, uiState?.isDesktop]);

  // if (uiState.isDesktop) {
  //   return (
  //     <>
  //       <IonPage className='pageDesktopBg'>
  //         <DesktopHeader></DesktopHeader>
  //         <IonRow className='desktopContent'>
  //           {/* <IonCol size='1' className='ion-no-padding ion-no-margin'>
  //             <SideMenu></SideMenu>
  //           </IonCol> */}
  //           <IonCol size='12' className='ion-no-padding ion-no-margin'>
  //             <DesktopContainer>
  //               <div className='innerContent'>
  //                 <IonInput
  //                   className='input'
  //                   placeholder='Enter car make or model here'
  //                   value={searchText}
  //                   onIonChange={(ev: any) => setSearchText(ev.target.value)}
  //                   onSubmit={onManualSearch}
  //                   onKeyUp={(ev: any) => {
  //                     if (ev.keyCode === 13) onManualSearch();
  //                   }}
  //                 ></IonInput>
  //                 <br />
  //                 <IonLabel className='label'>
  //                   {uiState.searchResults?.length} Related results
  //                 </IonLabel>
  //               </div>
  //             </DesktopContainer>
  //           </IonCol>
  //         </IonRow>
  //       </IonPage>
  //     </>
  //   );
  // } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header>test</Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>
              <SubPageHeading
                onBack={onBackPress}
             
              ></SubPageHeading>
              {uiState.mobileSearchType === 'MANUAL SEARCH' ? (
                <>
                  <IonRow>
                    <IonCol size='2.5'>
                      <img className='SolutionsImg' src={images.passenger} alt='' />
                    </IonCol>
                    <IonCol size='2'>
                      <img className='SolutionsImg' src={images.agri} alt='' />
                    </IonCol>
                    <IonCol size='1.5'>
                      <img className='SolutionsImg' src={images.air} alt='' />
                    </IonCol>
                    <IonCol size='1.5'>
                      <img className='SolutionsImg' src={images.mining} alt='' />
                    </IonCol>
                    <IonCol size='1.5'>
                      <img className='SolutionsImg' src={images.commercial} alt='' />
                    </IonCol>
                    <IonCol size='1.5'>
                      <img className='SolutionsImg' src={images.bikes} alt='' />
                    </IonCol>
                    <IonCol size='1.5'>
                      <img className='SolutionsImg' src={images.forestry} alt='' />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ width: '100%' }}>
                    <IonCol size='9' className='ion-no-padding'>
                      <IonInput
                        className='input'
                        placeholder='Enter car make or model here'
                        value={searchText}
                        onIonChange={(ev: any) =>
                          setSearchText(ev.target.value)
                        }
                        onSubmit={onManualSearch}
                        onKeyUp={(ev: any) => {
                          if (ev.keyCode === 13) onManualSearch();
                        }}
                      ></IonInput>
                    </IonCol>
                    <IonCol
                      size='3'
                      className='ion-no-padding ion-align-items-end ion-align-self-end'
                    >
                      <img
                        src={images.modelSearch}
                        className='scanButton'
                        alt='Camera Scan'
                        onClick={onManualSearch}
                      />
                    </IonCol>
                  </IonRow>
                  <p>
                    <small>
                      Please provide more details to narrow the search.
                    </small>
                  </p>
                  <br />
                </>
              ) : null}
              <IonRow className='resultDivider'>
                <IonCol size='12'>
                  <IonLabel className='resultHeading'>
                    {uiState.searchResults?.length} Related result
                  </IonLabel>
                </IonCol>
              </IonRow>
              <br />
              {uiState.searchResults?.map((result, index) => {
                return (
                  <IonRow
                    onClick={() => onResultTap(result)}
                    className='resultsRow resultDivider'
                    key={'resultItem' + index}
                  >
                    <IonCol size='3' className='ion-no-padding'>
                      <img
                        className='resultImage'
                        src={getImage(
                          result.g_id.toString(),
                          result.m_id.toString(),
                          result.v_id.toString()
                        )}
                        alt=''
                      />
                    </IonCol>
                    <IonCol
                      size='9'
                      className='ion-no-padding detailColumn detail'
                    >
                      <strong>{result.g_desc}</strong>
                      <br />
                      {result.m_desc}
                      <br />
                      {result.v_desc}
                      <br />
                      {result.intro_date}
                      <br />
                      <span className='dvlModelMobile'>
                        G{result?.g_id}M{result?.m_id}V{result?.v_id}{' '}
                      </span>
                      <br />
                      <span className='dvlModelMobile'>
                        {result?.oem_code ?? 'OEM Variant ID - N/A'}
                      </span>
                      {/* <span className='resultTitle'>
                        {result.g_desc} {result.m_desc}
                      </span>
                      <br />
                      <span className='resultSubtitle'>{result.v_desc}</span>
                      <IonRow>
                        <IonCol size='8' className='ion-no-padding'>
                          <span className='resultSubtitle'>
                            {moment(new Date(result.intro_date)).format(
                              'YYYY-MM-DD'
                            )}
                          </span>
                        </IonCol>
                        <IonCol size='4' className='ion-no-padding'>
                          <span className='resultSubtitle'>
                            G{result.g_id}M{result.m_id}V{result.v_id}
                          </span>
                        </IonCol>
                      </IonRow> */}
                    </IonCol>
                  </IonRow>
                );
              })}
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  }
//};

export default SearchResult;
