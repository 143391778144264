import {  useSelector } from 'react-redux';
import SideMenu from '../side-menu';
import './style.css';
import { RootState } from '../../redux/store';
import { AppScreens } from '../../enums';
import { setMenuCollapsed, hideSideMenu } from '../../redux/ui/uiSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

interface DesktopContainerProps {
  children?: React.ReactNode;
  noScroll?: boolean;
}




const DesktopContainer: React.FC<DesktopContainerProps> = ({
  children,
  noScroll,
}: DesktopContainerProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const hideSideMenu = useCallback(() => {
    dispatch(setMenuCollapsed(false));
  }, [dispatch]);

  return (
    <div className='rootDesktopContainer' onClick={hideSideMenu}>
      {uiState.screen !== AppScreens.BrandSelection && uiState.menuCollapsed ? (
        <div className='sideMenuContainer'>
          <SideMenu></SideMenu>
        </div>
      ) : null}
      <div className='rootContentContainer'>
        <div
          className={noScroll ? 'desktopContainerNoScroll' : 'desktopContainer'}
        >
          <div className='desktopInnerContainer'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DesktopContainer;
