import { useCallback, useEffect, useReducer, useRef, useState } from "react";

import {
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonRow,
  IonCard,
  IonCol,
  IonText,
  IonImg,
  IonLabel,
} from "@ionic/react";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router";
import {
  chevronBackCircleSharp,
  chevronDown,
  chevronDownCircle,
  chevronDownCircleSharp,
  chevronUpCircle,
  save,
  trash,
} from "ionicons/icons";
import moment from "moment";
import {
  addVehicle,
  createTransaction,
  dataCompleteness,
  garageCost,
  getGarage,
  getImage,
  modelClass,
  queryTransaction,
} from "../../api/mapo";
import "./style.css";
import { AxiosResponse } from "axios";
import { format } from "../../utils";
import { Format } from "../../enums";
import { Vehicle } from "../../interfaces/vehicle";
import { desktopImages, icons, images } from "../../assets";
import { hideLoader, showLoader } from "../../redux/ui/uiSlice";
import { Transaction } from "../../interfaces/transaction";
import { Config } from "../../Config";
import MessageModal from "../../components/message-modal";
import { ModelClass } from "../../interfaces/modelClass";
import Payment from "../../modals/payment";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

interface SavedModel {
  g_id: number;
  m_id: number;
  v_id: number;
  g_desc: string;
  m_desc: string;
  v_desc: string;
  intro_date: string;
  // Add other properties as needed
}

interface DataType {
  serviceChecks: number;
  serviceChecks_Max: number;
  serviceLabour: number;
  serviceLabour_Max: number;
  serviceLubes: number;
  serviceLubes_Max: number;
  serviceParts: number;
  serviceParts_Max: number;
  maintenanceChecks: number;
  maintenanceChecks_Max: number;
  maintenanceLabour: number;
  maintenanceLabour_Max: number;
  maintenanceParts: number;
  maintenanceParts_Max: number;
  gCode: number;
  mCode: number;
  vCode: number;
}

interface VehicleDate {
  g_id: string;
  m_id: string;
  v_id: string;
  date: string; // or Date, depending on your preference
}

interface Window {
  payfast_do_onsite_payment: (
    params: any,
    callback: (result: boolean) => void
  ) => void;
}

const Cart: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const [showCouponSec, setShowCouponSec] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [transaction, setTransaction] = useState<Transaction | undefined>();
  const transactionProcessing = useRef(false);
  const transactionStartTime = useRef<Date | undefined>();
  const onModalPress = useCallback((buttonIndex: number) => {
    setModalVisible(false);
  }, []);
  //Cart stored in localStorage
  const storedCart = localStorage.getItem("cart");
  const storedCartString = localStorage.getItem("cart");

  const savedCart = storedCartString ? JSON.parse(storedCartString) : [];
  const initialCart = storedCart ? JSON.parse(storedCart) : [];
  const [expandedItems, setExpandedItems] = useState<boolean[]>([]);
  //For the purchase
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [purchaseConfirmed, setPurchaseConfirmed] = useState<boolean>(false);

  const [selectedModels, setSelectedModels] = useState<SavedModel[]>([]);
  const [localStorageLength, setLocalStorageLength] = useState<number>(0);
  const [garageData, setGarageData] = useState<Array<ModelClass> | undefined>();
  const [showCircles, setShowCircles] = useState(false);

  const convertToFutureDate = (selectedValue: string) => {
    const today = new Date();
    let futureDate;

    switch (selectedValue) {
      case "1":
        futureDate = new Date(today.getTime() + 1 * 24 * 60 * 60 * 1000); // Add 1 day
        break;
      case "7":
        futureDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 7 days
        break;
      case "30":
        futureDate = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000); // Add 30 days
        break;
      case "365":
        futureDate = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000); // Add 365 days (1 year)
        break;
      default:
        return "Invalid duration";
    }
    // Set the time part to 23:59:59
    futureDate.setHours(23);
    futureDate.setMinutes(59);
    futureDate.setSeconds(59);

    // Format the date string as "YYYY-MM-DDTHH:mm:ss"
    const formattedDate = futureDate
      .toISOString()
      .replace(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}).*/, "$1T23:59:59");

    return formattedDate;
  };
  const [selectedDates, setSelectedDates] = useState<VehicleDate[]>(() => {
    return savedCart.map((item: any) => ({
      g_id: item.g_id || item.groupCode,
      m_id: item.m_id || item.modelCode,
      v_id: item.v_id || item.variantCode,
      date: convertToFutureDate("30"), // Default to 30 days
    }));
  });
  const history = useHistory();
  const dispatch = useDispatch;
  const [selectedOptions, setSelectedOptions] = useState(
    Array(savedCart.length).fill("30")
  );

  const [completeness, setCompleteness] = useState<DataType | undefined>(
    undefined
  );

  {
    /**To compare the dates and  */
  }
  //The selecting option/ checkbox for single and all model in cart
  const handleSelectOption = (index: any, value: any) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);

    //We are selecting the date and gmv to compare in the addVehicle then add date with matching gmv
    const newSelectedDates = [...selectedDates];
    newSelectedDates[index] = {
      g_id: savedCart[index].g_id || savedCart[index].groupCode,
      m_id: savedCart[index].m_id || savedCart[index].modelCode,
      v_id: savedCart[index].v_id || savedCart[index].variantCode,
      date: convertToFutureDate(value),
    };
    setSelectedDates(newSelectedDates);
  };

  const [productName, setProductName] = useState("MapoAppVehicle");
  const [productPrice, setProductPrice] = useState(184);
  const [UserEmail, setUserEmail] = useState("");
  const [payFastview, setpayFastview] = useState("");
  const [loading, setLoading] = useState(false);

  const initiatePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:3000/initiate-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productName,
          productPrice,
          UserEmail,
        }),
      });

      if (response.ok) {
        const paymentData = await response.json();
        console.log("Initiated payment:", paymentData);

        setpayFastview(paymentData.paymentId.uuid);
      } else {
        throw new Error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoading(false);
    }
  };

  //The payfast to active the modal to pay
  useEffect(() => {
    if (payFastview) {
      const script = document.createElement("script");
      script.src = "https://www.payfast.co.za/onsite/engine.js";
      script.async = true;
      script.onload = () => {
        // @ts-ignore
        window.payfast_do_onsite_payment(
          { uuid: payFastview },
          function (result: any) {
            if (result === true) {
              // Payment Completed
              onAddToGarage();
            } else {
              // Payment Window Closed
            }
          }
        );
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    setUserEmail(sessionState.user?.username ?? "");
  }, [payFastview]);

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      history.push("/home");
    } else {
      history.push("/home");
    }
  }, [dispatch, uiState]);

  //The garage data fetched to compare it with other gmv to show that
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setSelectedModels(JSON.parse(storedCart));
    }

    if (garageData === undefined) {
      getGarage(sessionState.user?.username ?? "")
        .then((getGarageResponse) => {
          if (getGarageResponse.status === 200) {
            const newList: Array<ModelClass> = [];
            const filteredGarageItems = getGarageResponse.data.filter(
              (garageItem: { g_id: number; m_id: number; v_id: number }) =>
                garageItem.g_id !== 0 &&
                garageItem.m_id !== 0 &&
                garageItem.v_id !== 0
            );

            if (filteredGarageItems.length === 0) {
              setGarageData([]);
            } else {
              filteredGarageItems.forEach((garageItem: any) => {
                modelClass(garageItem.g_id, garageItem.m_id, garageItem.v_id)
                  .then((modelClassResponse) => {
                    if (modelClassResponse.status === 200) {
                      newList.push(modelClassResponse.data);
                      if (newList.length === filteredGarageItems.length) {
                        setGarageData(newList);
                      }
                    } else {
                      alert(modelClassResponse.statusText);
                      return;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(JSON.stringify(err));
                  });
              });
            }
          } else {
            alert(getGarageResponse.statusText);
          }
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        });
    }
  }, [garageData, sessionState.user]);

  {
    garageData?.map((result, index) => {
      return <div key={"resultItem" + index}>{result.result[0].groupCode}</div>;
    });
  }

  //Getting the saved data in localStorage
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
      setLocalStorageLength(parsedCart.length);
    } else {
      setLocalStorageLength(0);
    }
  }, []);

  const removeItemByGMV = (g: number, m: number, v: number) => {
    try {
      selectedModels.forEach((val, ind) => {
        if (val.g_id == g && val.m_id == m && val.v_id == v) {
          handleRemoveItem(ind);
        }
      });
    } catch (error) {
      console.error("Error in removeItemByGMV:", error);
    }
  };

  const handleRemoveItem = (index: number): void => {
    try {
      setSelectAll(false);

      const updatedCart = [...selectedModels];
      updatedCart.splice(index, 1);
      setSelectedModels(updatedCart);

      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(index, 1);
      setSelectedItems(updatedSelectedItems);

      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } catch (error) {
      console.error("Error in handleRemoveItem:", error);
    }
  };

  const [garageCostResponses, setGarageCostResponses] = useState<any[]>([]);
  const [fetchingComplete, setFetchingComplete] = useState(false);

  //The data in the localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (savedCart.length > 0 && !fetchingComplete) {
          // Create a Set to store unique identifiers (combination of g_id, m_id, v_id)
          const uniqueIdentifiers = new Set();

          // Filter out items with the same identifiers
          const filteredCart = savedCart.filter((savedModel: any) => {
            const identifier = `${savedModel.g_id || savedModel.groupModel}-${
              savedModel.m_id || savedModel.modelCode
            }-${savedModel.v_id || savedModel.variantCode}`;
            if (!uniqueIdentifiers.has(identifier)) {
              uniqueIdentifiers.add(identifier);
              return true;
            }
            return false;
          });

          const fetchedDataPromises = filteredCart.map(
            async (savedModel: any) => {
              let response;

              if (
                savedModel.g_id !== undefined &&
                savedModel.m_id !== undefined &&
                savedModel.v_id !== undefined
              ) {
                response = await garageCost(
                  savedModel.g_id,
                  savedModel.m_id,
                  savedModel.v_id
                );
              } else if (
                (savedModel.groupCode !== undefined &&
                  savedModel.variantCode !== undefined) ||
                savedModel.variantCode !== undefined
              ) {
                response = await garageCost(
                  savedModel.groupCode,
                  savedModel.modelCode,
                  savedModel.variantCode
                );
              }

              return response?.data;
            }
          );

          const fetchedData = await Promise.all(fetchedDataPromises);
          setGarageCostResponses(fetchedData);
          setFetchingComplete(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [savedCart, fetchingComplete]);

  //For the Dropdown for purchaisng model
  const renderCost = (selectedOptions: any, data: any) => {
    switch (selectedOptions) {
      case "1":
        return data.cost_Per_Day;
      case "7":
        return data.cost_Per_Week;
      case "30":
        return data.cost_Per_Month;
      case "365":
        return data.cost_Per_Year;
      default:
        return "";
    }
  };

  //To toggle the data Completeness
  const toggleItem = (index: number) => {
    setExpandedItems((prevExpandedItems) => {
      const updatedItems = [...prevExpandedItems];
      updatedItems[index] = !updatedItems[index]; // Toggle the state for the clicked item
      return updatedItems;
    });
  };

  //The ticking of the items

  useEffect(() => {
    if (selectAll) {
      // Select all items when the "Select All" checkbox is checked
      const allItems = savedCart.map((_: any, index: any) => index);
      setSelectedItems(allItems);
    } else {
      // Deselect all items when the "Select All" checkbox is unchecked
      setSelectedItems([]);
    }
  }, [selectAll]);

  const handleSelectItem = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = [...prevSelectedItems];
      const itemIndex = updatedItems.indexOf(index);

      if (itemIndex !== -1) {
        // If already selected, remove from the list
        updatedItems.splice(itemIndex, 1);
      } else {
        // If not selected, add to the list
        updatedItems.push(index);
      }

      return updatedItems;
    });
  };

  const calculateTotal = () => {
    // Calculate the total based on the selected items
    let total = 0;

    selectedItems.forEach((selectedIndex) => {
      // Use your logic to calculate the cost for each selected item
      const cost = garageCostResponses
        .filter(
          (data) =>
            (data.gCode === savedCart[selectedIndex]?.g_id ||
              data.gCode === savedCart[selectedIndex]?.groupCode) &&
            (data.mCode === savedCart[selectedIndex]?.m_id ||
              data.mCode === savedCart[selectedIndex]?.modelCode) &&
            (data.vCode === savedCart[selectedIndex]?.v_id ||
              data.vCode === savedCart[selectedIndex]?.variantCode)
        )
        .map((filteredData) =>
          renderCost(selectedOptions[selectedIndex], filteredData)
        )
        .reduce((acc, current) => acc + current, 0);

      total += cost;
    });

    return total;
  };

  //Setting the price for the payfast in the state
  useEffect(() => {
    const total = calculateTotal();
    const roundedTotal = Math.round(total);
    setProductPrice(roundedTotal);
  }, [selectedItems, savedCart, garageCostResponses, selectedOptions]);

  //Add to vehicle to garage

  const promptForCoupon = () => {
    setShowCouponSec(true);
  };

  const [discountPercentage, setDiscountPercentage] = useState(0);

  // Callback function to receive the updated discount percentage from Payment component
  const handleUpdateDiscountPercentage = (newDiscount: number) => {
    setDiscountPercentage(newDiscount);
  };

  const onAddToGarage = useCallback(() => {
    if (sessionState.user && savedCart.length > 0 && selectedItems.length > 0) {
      const selectedVehicles = selectedItems.map((index) => savedCart[index]);

      Promise.all(
        selectedVehicles.map((selectedVehicle, index) => {
          const { g_id, m_id, v_id, groupCode, variantCode, modelCode } =
            selectedVehicle;

          // Find corresponding date from selectedDates
          const matchingDate = selectedDates.find(
            (date) =>
              (g_id || groupCode) === date.g_id &&
              (m_id || modelCode) === date.m_id &&
              (v_id || variantCode) === date.v_id
          );

          const Recentdate = matchingDate?.date ?? convertToFutureDate("30");

          return addVehicle(
            sessionState.user?.username ?? "",
            g_id || groupCode,
            m_id || modelCode,
            v_id || variantCode,
            discountPercentage === 100 ? convertToFutureDate("7") : Recentdate,
            [] 
          ).then((response) => {
            if (response.status === 200) {
              // Remove the item from savedCart
              const updatedCart = savedCart.filter(
                (_: any, idx: any) => !selectedItems.includes(idx)
              );
              localStorage.setItem("cart", JSON.stringify(updatedCart));
            }

            return response;
          });
        })
      )
        .then((addVehicleResponses) => {
          const allRequestsSuccessful = addVehicleResponses.every(
            (response) => response.status === 200
          );

          if (allRequestsSuccessful) {
            history.push("/Garage");
          } else {
            alert("Not all vehicles were added successfully");
          }
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        });
    }
  }, [dispatch, history, sessionState.user, savedCart, selectedItems]);

  //For the start  Transaction to add all the selected GMV in Garage
  const startTransaction = useCallback(
    (discountCode: string) => {
      if (
        sessionState.user &&
        savedCart.length > 0 &&
        selectedItems.length > 0
      ) {
        const selectedVehicles = selectedItems.map((index) => savedCart[index]);

        Promise.all(
          selectedVehicles.map((selectedVehicle) => {
            const { g_id, m_id, v_id } = selectedVehicle;

            return createTransaction(
              sessionState.user?.username ?? "",
              g_id,
              m_id,
              v_id,
              discountCode
            );
          })
        )

          .then((createTransResponse) => {
            // Check if all requests were successful
            const allRequestsSuccessful = createTransResponse.every(
              (response) => response.status === 200
            );
            if (allRequestsSuccessful) {
              // Navigate to the Garage page after adding all selected vehicles
              history.push("/Garage");
            } else {
              // Handle the case where at least one vehicle was not added
              alert("Not all vehicles were added successfully");
            }
          })
          .catch((err) => {
            // dispatch(hideLoader());
            alert(JSON.stringify(err));
          });
      }
    },
    [dispatch, history, sessionState.user, uiState.selectedVehicle]
  );

  //The dataCompleteness

  const [dataArray, setDataArray] = useState<DataType[]>([]);

  useEffect(() => {
    if (!savedCart) {
      return; // Do nothing if garageData is undefined
    }

    savedCart.forEach(async (result: any) => {
      if (completeness === undefined) {
        //dispatch(showLoader());

        try {
          const dataCompletenessResponse = await dataCompleteness(
            result.groupCode || result.g_id,
            result.modelCode || result.m_id,
            result.variantCode || result.v_id
          );

          if (dataCompletenessResponse.status === 200) {
            const data = dataCompletenessResponse.data;
            // console.log("The data fetched", data);

            // Add the new data to the array
            setDataArray((prevDataArray) => [...prevDataArray, data]);

            setCompleteness(data);
          } else {
            console.error(dataCompletenessResponse.statusText);
          }
        } catch (err) {
          console.error(err);
        } finally {
          //dispatch(hideLoader());
        }
      }
    });
  }, [completeness, dispatch, garageData]);

  return (
    <>
      {showCouponSec ? (
        <Payment
          isVisible={showCouponSec}
          onClose={() => setShowCouponSec(false)}
          cartTotal={Number(format(calculateTotal(), Format.Currency))}
          savedCart={savedCart.filter((_: any, index: any) =>
            selectedItems.includes(index)
          )}
          selectedOptions={selectedOptions.filter((_: any, index: any) =>
            selectedItems.includes(index)
          )}
          garageCostResponses={garageCostResponses}
          onPaymentCofirmed={() => onAddToGarage()}
          updateDiscountPercentage={handleUpdateDiscountPercentage}
        />
      ) : null}
      <GlobalGrid
        currentPageTitle="CART"
        closeCurrentPage={() => onBackPress()}
      >
        <IonRow className="garageRow">
          {savedCart.map((savedModel: any, index: any) => (
            <IonCol
              key={index}
              size="11.5"
              sizeXs="11.5"
              sizeMd="11.5"
              sizeLg="12"
              sizeXl="3.8"
              id="sliderCol"
              style={{
                background: "white",
              }}
            >
              <IonItemSliding key={"resultItem" + index}>
                <IonItemOptions side="end">
                  <IonItemOption
                    onClick={() =>
                      removeItemByGMV(
                        savedModel.g_id,
                        savedModel.m_id,
                        savedModel.v_id
                      )
                    }
                  >
                    <IonIcon slot="start" className="delete-txt" icon={trash} />
                    <br />
                    <span className="delete-txt"> Delete</span>
                  </IonItemOption>
                </IonItemOptions>

                <IonItem>
                  <div className="visitorCard">
                    <IonCol size="12" className="vistorImgCont">
                      <IonCol size="6" id="img">
                        <img
                          src={getImage(
                            savedModel.g_id || savedModel.groupCode,
                            savedModel.m_id || savedModel.modelCode,
                            savedModel.v_id || savedModel.variantCode
                          )}
                          alt=""
                        />
                        <input
                          type="checkbox"
                          className="Tick-model"
                          checked={selectedItems.includes(index)}
                          onChange={() => handleSelectItem(index)}
                        />
                      </IonCol>

                      <IonCol size="6" id="details">
                        <IonCol size="12" id="row">
                          {savedModel.g_desc || savedModel.make}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {savedModel.m_desc || savedModel.model}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {savedModel.v_desc || savedModel.variant}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {" "}
                          G{savedModel.g_id || savedModel.groupCode}M
                          {savedModel.m_id || savedModel.modelCode}V
                          {savedModel.v_id || savedModel.variantCode}
                        </IonCol>
                      </IonCol>
                    </IonCol>
                    <IonCol size="12" className="comprehensiveDropDown">
                      <IonCol size="11">Comprehensive data available</IonCol>
                      <IonCol size="1">
                        <IonIcon
                          id="dropDownToggler"
                          onClick={() => toggleItem(index)}
                          className="circle-up-down"
                          src={
                            expandedItems[index]
                              ? chevronUpCircle
                              : chevronDownCircle
                          }
                        ></IonIcon>
                      </IonCol>
                    </IonCol>
                    {expandedItems[index] && (
                      <IonRow className="circlesCont">
                        {dataArray
                          ?.filter((data) => {
                            const { gCode, mCode, vCode } = data;
                            return (
                              (gCode === savedModel.groupCode ||
                                gCode === savedModel.g_id) &&
                              (mCode === savedModel.modelCode ||
                                mCode === savedModel.m_id) &&
                              (vCode === savedModel.variantCode ||
                                vCode === savedModel.v_id)
                            );
                          })
                          .map((filteredData, index) => {
                            return (
                              <>
                                {((Number(filteredData.serviceChecks) /
                                  Number(filteredData.serviceChecks_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service checks data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service checks data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceLabour) /
                                  Number(filteredData.serviceLabour_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service labour data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service labour data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceLubes) /
                                  Number(filteredData.serviceLubes_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceLube}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Fluids data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceLube}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Fluids data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service parts data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service parts data unavailable.</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p>Replacement parts data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Replacement parts data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance labour data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance labour data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceParts}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance parts data available.</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceParts}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance parts data unavailable.</p>
                                  </IonCol>
                                )}
                              </>
                            );
                          })}
                      </IonRow>
                    )}
                    <IonCol size="12" className="timerCont">
                      <IonCol size="12" id="cartDuration">
                        <IonSelect
                          id="range"
                          value={selectedOptions[index]}
                          onIonChange={(e) =>
                            handleSelectOption(index, e.detail.value)
                          }
                        >
                          <IonSelectOption value="1" id="opt">
                            1 Day
                          </IonSelectOption>
                          <IonSelectOption value="7" id="opt">
                            7 Days
                          </IonSelectOption>
                          <IonSelectOption value="30" id="opt">
                            30 Days
                          </IonSelectOption>
                          <IonSelectOption value="365" id="opt">
                            1 Year
                          </IonSelectOption>
                        </IonSelect>
                      </IonCol>
                      <IonCol size="12" id="cartDuration">
                        {garageCostResponses
                          .filter(
                            (data) =>
                              data.gCode === savedModel.groupCode ||
                              (data.gCode === savedModel.g_id &&
                                data.mCode === savedModel.modelCode) ||
                              (data.mCode === savedModel.m_id &&
                                data.vCode === savedModel.varientCode) ||
                              data.vCode === savedModel.v_id
                          )
                          .map((filteredData, innerIndex) => (
                            <IonCol
                              size="12"
                              key={`filteredData-${innerIndex}`}
                              className="priceCart"
                            >
                              {format(
                                renderCost(
                                  selectedOptions[index],
                                  filteredData
                                ),
                                Format.Currency
                              )}
                            </IonCol>
                          ))}
                      </IonCol>
                    </IonCol>
                  </div>
                </IonItem>
              </IonItemSliding>
            </IonCol>
          ))}
        </IonRow>
      </GlobalGrid>

      {sessionState.user?.username !== "GUEST" ? (
        <>
          <IonCol size="12" id="bannerCart" sizeLg="12">
            <IonCol size="2">
              <IonCol size="4">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                  className="inputForm"
                />
              </IonCol>
              <IonCol size="6">
                <span className="FooterFonts"> All</span>
              </IonCol>
            </IonCol>
            <IonCol size="6.5">
              <IonCol size="5">
                <strong className="FooterFonts">TOTAL: </strong>
              </IonCol>
              <IonCol size="6" id="price">
                <strong className="FooterFonts">
                  {" "}
                  {format(calculateTotal(), Format.Currency)}
                </strong>
              </IonCol>
            </IonCol>
            {fetchingComplete ? (
              <IonCol size="5"
              id="btnCart"
                onClick={() => {
                  setShowCouponSec(true);
                }}
              >
                {" "}
                <button
                  className="CheckOut-cart"
                  disabled={purchaseConfirmed || selectedItems.length === 0}
                >
                  CHECKOUT ({selectedItems.length})
                </button>
              </IonCol>
            ) : (
              <></>
            )}
          </IonCol>
        </>
      ) : null}
    </>
  );
};

export default Cart;
