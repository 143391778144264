import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { desktopImages, images } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";

import { RootState } from "../../redux/store";
import {
  setModalData,
  setScreen,
  setWatchVideoModalVisible,
} from "../../redux/ui/uiSlice";
import {
  chevronBackCircleOutline,
  chevronBackOutline,
  volumeHighOutline,
  logoYoutube,
  chevronBackCircleSharp,
} from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./styleTutorial.css";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const solution = [
  "The basic concept of the SALES Solution",
  "How to subscribe",
];
const features = [
  "Introduction to the Sales Solution",
  // 'How to search and select a Model',
  "Customization of selected Model",
  // 'Competitor Analysis',
  "Total Cost Analysis",
  "Specifications Analysis",
  "Maintenance Cost Analysis",
  "Service Cost Analysis",
  // 'Parts Basket',
  "10K Rates Table",
  "Depreciation Value",
];
const featuresLinks = [
  "https://www.youtube.com/embed/_Qfoy2J7LtA",
  // 'How to search and select a Model',
  "https://www.youtube.com/embed/4LEdgLttxQY",
  // 'Competitor Analysis',
  "https://www.youtube.com/embed/KfZ_-_Su-II",
  "https://www.youtube.com/embed/Dg1HzL_4gug",
  "https://www.youtube.com/embed/PsOW7A57Bw4",
  "https://www.youtube.com/embed/rtyUCzX4RT8",
  // 'Parts Basket',
  "https://www.youtube.com/embed/ZUf4MHrR-fs",
  "https://www.youtube.com/embed/ws7CnQCNVkk",
];
// const datasource = [
//   // 'How is the data sourced & maintained?',
//   // 'How to edit user information?',
//   // 'How to edit the labour rate used in calculations?',
//   // 'Legal Complyance & System Limitations',
// ];
const support = ["I wish to communication with backoffice"];
const lingo = [
  "What is a Service Schedule?",
  "What is a Maintenance Schedule?",
  // 'What is Detailed Specifications?',
  "What is TCO?",
  // 'What is Parts Basket?',
  // 'What is the 10K Rates Table?',
  // 'What is a Competitor Analysis?',
  // 'What is the Depreciation Value?',
  // 'What is the Residual Value?',
];

const lingoLinks = [
  "https://www.youtube.com/embed/JMPkaRuOr5k",
  "https://www.youtube.com/embed/FnmxzzPly04",
  // 'What is Detailed Specifications?',
  "https://www.youtube.com/embed/SQtdIKTg51U",
  // 'What is Parts Basket?',
  // 'What is the 10K Rates Table?',
  // 'What is a Competitor Analysis?',
  // 'What is the Depreciation Value?',
  // 'What is the Residual Value?',
];

const mobileFeatures = [
  "- View the YouTube add",
  "- The basic concept of the MAPO app",
  "- What is the VIN scanner?",
  "- What is My Garage?",
  "- Who is in Visitors Parking?",
  "- What is Service Costs?",
  "- What is Detailed Specifications?",
  "- What is Maintenance Costs?",
  "- What is TCO?",
  "- What is Parts Basket?",
  "- What is the 10K Rates Table?",
  "- What is a Competitor Analysis?",
  "- How is the Residual Value calculated?",
  "- How does the Finance Calculator work?",
  "- What is the Reviews section on the app?",
  "- What is the data source?",
];

const Tutorial: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      //dispatch(setScreen(AppScreens.SearchResult));
      history.push("/SearchResult");
    } else {
      //dispatch(setScreen(AppScreens.Home));
      history.push("/home");
    }
  }, [dispatch, uiState]);

  const onVideoLinkClick = useCallback(
    (link: string) => {
      dispatch(setModalData(link));
      dispatch(setWatchVideoModalVisible(true));
    },
    [dispatch]
  );

  return (
    <>
     
        <GlobalGrid
          currentPageTitle="APP TUTORIAL"
          closeCurrentPage={() => onBackPress()}
        >
          <IonGrid className="TutorialContent">
            <IonRow style={{ marginTop: "5rem" }}>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- View the YouTube ad</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  className="YoutubeIcon"
                  icon={logoYoutube}
                  style={{
                    color: "red",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- The basic concept of the MAPO app</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is the VIN scanner? </IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is My Garage?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- Who is in Visitors Parking?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is Service Costs?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is Detailed Specifications?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is Maintenance Costs?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText> - What is TCO?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is Parts Basket?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                sizeXl="3"
                size="8"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is the 10K Rates Table?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is a Competitor Analysis?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="8"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- How is the Residual Value calculated?</IonText>
              </IonCol>

              <IonCol size="4" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="9"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- How does the Finance Calculator work?</IonText>
              </IonCol>

              <IonCol size="3" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="9"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is the Reviews section on the app?</IonText>
              </IonCol>

              <IonCol size="3" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                size="9"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText>- What is the data source?</IonText>
              </IonCol>

              <IonCol size="3" sizeXl="3" className="ion-justify-content-end">
                <IonIcon
                  id="Youtube"
                  className="YoutubeIcon"
                  icon={volumeHighOutline}
                  style={{
                    color: "blue",
                  }}
                ></IonIcon>
              </IonCol>
            </IonRow>

            <IonRow style={{ marginTop: "5rem" }}>
              <IonCol
                size="9"
                sizeXl="3"
                className="ion-justify-content-start FontMobileSpcs"
              >
                <IonText> - Download Full .PDF document</IonText>
              </IonCol>

              <IonCol size="3" sizeXl="3" className="ion-justify-content-end">
                <IonImg
                  id="Youtube"
                  className="YoutubeIcon"
                  src={images.downloadPdf}
                ></IonImg>
              </IonCol>
            </IonRow>
          </IonGrid>
        </GlobalGrid>
     
    </>
  );
};

export default Tutorial;
