import { IonSpinner } from '@ionic/react';
import './style.css';

interface LoadingProps {
  visible: boolean;
}

const Loading: React.FC<LoadingProps> = ({ visible }: LoadingProps) => {
  if (visible) {
    return (
      <div className='overlay'>
        <IonSpinner
          className='overlay-spinner'
          name='crescent'
          color='primary'
        ></IonSpinner>
        
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;
