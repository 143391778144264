import { IonCol, IonRow, IonLabel, IonIcon } from "@ionic/react";
import "../style.css";
import "../../../Global.css";
import { SelectedVehicle, Vehicle } from "../../../interfaces";
import {
  VistorsParking,
  getCustomCompetitors,
  getGarage,
  getImage,
  manualSearch,
  modelClass,
} from "../../../api/mapo";
import { desktopImages, icons } from "../../../assets";
import { useCallback, useEffect, useReducer, useState } from "react";
import { UndetinfiedVinSearchViewType } from "../index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import Button from "../../../components/button";
import { CompetitorData } from "../../../interfaces/competitorData";
import {
  getAllData,
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../../api/getAll";
import { Competitor } from "../../../interfaces/competitors";
import {
  setCompetitors,
  setSwapoutSearchVisible,
  setSwapoutCompetitorIndex,
  showLoader,
  hideLoader,
  setSelectedVehicle,
  setCustomReportParams,
  setScreen,
  setFilteredSearchVisible,
  setSearchResults,
  setVistorsGarage,
  hideSideMenuDisabledItems,
  showSideMenuDisabledItems,
  setUndentifiedVinSearchVisible,
} from "../../../redux/ui/uiSlice";
import {
  chevronBack,
  addOutline,
  removeOutline,
  cartOutline,
  caretForwardOutline,
  cartSharp,
  eye,
  eyedropOutline,
  eyeOutline,
  eyeSharp,
  thumbsUpSharp,
} from "ionicons/icons";
import { VehicleCustomReportParams } from "../../../interfaces/customReportParams";
import { decVal } from "../../../utils";
import { AppScreens } from "../../../enums";
import moment from "moment";
import { stripLeadingSlash } from "history/PathUtils";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { vistorsParking } from "../../../interfaces/VistorsParking";
import { ModelClass } from "../../../interfaces/modelClass";

//describe the shape or structure of an object in TypeScript
interface SearchResultViewProps {
  searchResultsVin: Array<Vehicle> | undefined;
  viewType: UndetinfiedVinSearchViewType | undefined;
}
interface CartItem {
  g_desc: number;
  m_desc: number;
  v_desc: number;

  // Add more properties as needed
}

const SearchResultViewVin: React.FC<SearchResultViewProps> = ({
  searchResultsVin,
  viewType,
}: SearchResultViewProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [garageData, setGarageData] = useState<Array<ModelClass> | undefined>();
  const sessionState = useSelector((state: RootState) => state.session);
  const [addCartAlert, setAddCartAlert] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );

  const hasData = useCallback((type: string, vehicle: Vehicle) => {
    switch (type) {
      case "serviceChecks": {
        if (
          ((Number(vehicle.servicechecks) / Number(vehicle.servicechecks_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceLabour": {
        if (
          ((Number(vehicle.servicelabour) / Number(vehicle.servicelabour_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceLubes": {
        if (
          ((Number(vehicle.servicelubes) / Number(vehicle.servicelubes_max)) *
            100 ?? 0) > 70
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "serviceParts": {
        if (
          ((Number(vehicle.serviceparts) / Number(vehicle.serviceparts_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceChecks": {
        if (
          ((Number(vehicle.maintenancechecks) /
            Number(vehicle.maintenancechecks_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceLabour": {
        if (
          ((Number(vehicle.maintenancelabour) /
            Number(vehicle.maintenancelabour_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
      case "maintenanceParts": {
        if (
          ((Number(vehicle.maintenanceparts) /
            Number(vehicle.maintenanceparts_max)) *
            100 ?? 0) > 79
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }, []);

  const selectPrimaryVehicle = useCallback(
    (v: Vehicle) => {
      const selectedVehicle: SelectedVehicle = {
        make: v.g_desc,
        model: v.m_desc,
        variant: v.v_desc,
        g_id: v.g_id,
        m_id: v.m_id,
        v_id: v.v_id,
        variant_id: v.oem_code,
      };
      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());

      getAllSelectedData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0,
        uiState.customReportParams.deposit ?? 0,
        uiState.customReportParams.interest ?? 0,
        uiState.customReportParams.term ?? 0,
        uiState.customReportParams.residual ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
         // dispatch(setCompetitors(allData.competitors ?? []));

          const selectedVehicleCustomParams: VehicleCustomReportParams = {
            retailprice: decVal(
              allData.selected?.modelSpecification.result?.basic_specs
                ?.base_price
            ),
            co2tax: 0,
            // co2tax: Number(
            //   allData.selected?.modelSpecification.result?.engine_specs.co2 ??
            //     ''
            // ),

            // Service
            servicestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan ?? "0"
            ),
            servicestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan_months ?? "0"
            ),

            // Maintenance
            maintenancestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan ?? "0"
            ),
            maintenancestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
            ),

            // baloonpayment: 0,
          };

          // const competitorCustomParams: Array<VehicleCustomReportParams> = [];

          // allData.competitors?.map((competitor) => {
          //   const competitorCustomParam: VehicleCustomReportParams = {
          //     retailprice: decVal(
          //       competitor.modelSpecification.result?.basic_specs?.base_price
          //     ),
          //     co2tax: 0,
          //     // co2tax: Number(
          //     //   competitor.modelSpecification.result?.engine_specs.co2 ?? ''
          //     // ),

          //     // Service
          //     servicestandardkm: Number(
          //       competitor.modelSpecification.result
          //         ?.service_maintenance_warranty.service_plan ?? "0"
          //     ),
          //     servicestandardmnt: Number(
          //       competitor.modelSpecification.result
          //         ?.service_maintenance_warranty.service_plan_months ?? "0"
          //     ),

          //     // Maintenance
          //     maintenancestandardkm: Number(
          //       competitor.modelSpecification.result
          //         ?.service_maintenance_warranty.maintenance_plan ?? "0"
          //     ),
          //     maintenancestandardmnt: Number(
          //       competitor.modelSpecification.result
          //         ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
          //     ),

          //     baloonpayment: 0,
          //   };
          //   competitorCustomParams.push(competitorCustomParam);
          // });

          dispatch(
            setCustomReportParams({
              ...uiState.customReportParams,
              selectedVehicleCustomParams: selectedVehicleCustomParams,
              //competitorCustomParams: competitorCustomParams,
            })
          );

          dispatch(hideLoader());
          dispatch(setUndentifiedVinSearchVisible(false));
          //dispatch(setScreen(AppScreens.VehicleLanding));
          // setTimeout(() => {
          //   dispatch(setScreen(AppScreens.VehicleLanding));
          // }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams]
  );

  const selectPrimaryVehic = useCallback(
    (v: Vehicle) => {
      const selectedVehicle: SelectedVehicle = {
        make: v.g_desc,
        model: v.m_desc,
        variant: v.v_desc,
        g_id: v.g_id,
        m_id: v.m_id,
        v_id: v.v_id,
        variant_id: v.oem_code,
      };
      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());

      getSelectedSpecificModelData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
          dispatch(setCompetitors(allData.competition ?? []));

          dispatch(hideLoader());
          dispatch(setUndentifiedVinSearchVisible(false));
          setTimeout(() => {
            //dispatch(setScreen(AppScreens.VehicleLanding));
            history.push("/OverViewDetail");
          }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    },
    [dispatch]
  );

  const [showDetail, setShowDetail] = useState(false);
  const [showDataIcon, setshowDataIcon] = useState(false);
  const [showDataIcons, setShowDataIcons] = useState<Array<boolean>>(
    Array(searchResultsVin?.length || 0).fill(false)
  );

  const handleToggleDataIcon = (index: number) => {
    setShowDataIcons((prev) =>
      prev.map((value, i) => (i === index ? !value : value))
    );
  };

  const [previewG, setPreviewG] = useState(Number);
  const [previewM, setPreviewM] = useState(Number);
  const [previewV, setPreviewV] = useState(Number);
  const [previewNametitle, setPreviewNameTitle] = useState(String);
  const [previewModelTitle, setPreviewModelTitle] = useState(String);

  const handlePreview = (v: Number) => {
    searchResultsVin?.map((val, index) => {
      if (index == v) {
        setPreviewG(val.g_id);
        setPreviewM(val.m_id);
        setPreviewV(val.v_id);
        setPreviewNameTitle(val.g_desc);
        setPreviewModelTitle(val.m_desc);
        setShowDetail(true);
      }
    });
  };

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }

    VistorsParking()
      .then((VistorsParkingRespose) => {
        dispatch(setVistorsGarage(VistorsParkingRespose.data));
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        alert(JSON.stringify(err));
      });
  }, []);

  const handleSaveToCart = (row: any, index: number) => {
    // Retrieve existing cart data from localStorage
    const storedCartString = localStorage.getItem("cart");
    const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

    // Check if the item with the same 'gmv' is already in the cart
    const isDuplicate = existingCart.some((item: any) => item.gmv === row.gmv);

    if (!isDuplicate) {
      // Add the current model to the cart
      existingCart.push(row);

      // Update the localStorage with the modified cart
      localStorage.setItem("cart", JSON.stringify(existingCart));

      // Update the length in the state
      setLocalStorageLength((prevLength) => prevLength + 1);

      // Set the flag to indicate that the item was added
      setIsClicked(true);
      setTimeout(() => {
        setAddCartMsg("VEHICLE ADDED TO CART");
        setAddCartAlert(true);
        // Set another timeout after the first one has completed
        setTimeout(() => {
          setAddCartAlert(false);
        }, 3000);
      }, 500);
    } else {
      setDuplicateItemIndex(index);
      setTimeout(() => {
        setAddCartMsg("VEHICLE ALREADY IN CART");
        setAddCartAlert(true);
        // Set another timeout after the first one has completed
        setTimeout(() => {
          setAddCartAlert(false);
        }, 3000);
      }, 500);
    }
  };

  const [CartClicked, setIsClicked] = useState(false);

  //Clicking on the Vistors parking To click
  const onSelectVehicleAllParking = useCallback(
    (modelClass: vistorsParking) => {
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
               // dispatch(setCompetitors(allData.competitors ?? []));

                const selectedVehicleCustomParams: VehicleCustomReportParams = {
                  retailprice: decVal(
                    allData.selected?.modelSpecification.result?.basic_specs
                      ?.base_price
                  ),
                  co2tax: Number(
                    allData.selected?.modelSpecification.result?.engine_specs
                      .co2 ?? ""
                  ),

                  // Service
                  servicestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan ?? "0"
                  ),
                  servicestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan_months ?? "0"
                  ),

                  // Maintenance
                  maintenancestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan ?? "0"
                  ),
                  maintenancestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan_months ??
                      "0"
                  ),

                  // baloonpayment: 0,
                };

                // const competitorCustomParams: Array<VehicleCustomReportParams> =
                //   [];

                // allData.competitors?.map((competitor) => {
                //   const competitorCustomParam: VehicleCustomReportParams = {
                //     retailprice: decVal(
                //       competitor.modelSpecification.result?.basic_specs
                //         ?.base_price
                //     ),
                //     co2tax: Number(
                //       competitor.modelSpecification.result?.engine_specs.co2 ??
                //         ""
                //     ),

                //     // Service
                //     servicestandardkm: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.service_plan ?? "0"
                //     ),
                //     servicestandardmnt: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.service_plan_months ??
                //         "0"
                //     ),

                //     // Maintenance
                //     maintenancestandardkm: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.maintenance_plan ?? "0"
                //     ),
                //     maintenancestandardmnt: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty
                //         .maintenance_plan_months ?? "0"
                //     ),

                //     baloonpayment: 0,
                //   };
                //   competitorCustomParams.push(competitorCustomParam);
                // });

                dispatch(
                  setCustomReportParams({
                    ...uiState.customReportParams,
                    selectedVehicleCustomParams: selectedVehicleCustomParams,
                   // competitorCustomParams: competitorCustomParams,
                  })
                );

                dispatch(hideLoader());
                dispatch(setFilteredSearchVisible(false));
                //dispatch(setScreen(AppScreens.VehicleDetail));
                dispatch(showSideMenuDisabledItems());
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }

          dispatch(hideSideMenuDisabledItems());
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );
  const onSelectVehicleParking = useCallback(
    (modelClass: vistorsParking) => {
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competition ?? []));

                dispatch(hideLoader());
                dispatch(setFilteredSearchVisible(false));
                // dispatch(setScreen(AppScreens.VehicleDetail));
                // history.push("/VehicleDetail");
                setTimeout(() => {
                  //dispatch(setScreen(AppScreens.VehicleLanding));
                  history.push("/VehicleDetail");
                }, 500);
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  useEffect(() => {
    if (garageData === undefined) {
      dispatch(showLoader());

      getGarage(sessionState.user?.username ?? "")
        .then((getGarageResponse) => {
          if (getGarageResponse.status === 200) {
            const newList: Array<ModelClass> = [];
            const filteredGarageItems = getGarageResponse.data.filter(
              (garageItem: { g_id: number; m_id: number; v_id: number }) =>
                garageItem.g_id !== 0 &&
                garageItem.m_id !== 0 &&
                garageItem.v_id !== 0
            );

            if (filteredGarageItems.length === 0) {
              dispatch(hideLoader());
              setGarageData([]);
            } else {
              filteredGarageItems.forEach((garageItem: any) => {
                modelClass(garageItem.g_id, garageItem.m_id, garageItem.v_id)
                  .then((modelClassResponse) => {
                    if (modelClassResponse.status === 200) {
                      newList.push(modelClassResponse.data);
                      if (newList.length === filteredGarageItems.length) {
                        dispatch(hideLoader());
                        setGarageData(newList);
                      }
                    } else {
                      dispatch(hideLoader());
                      console.log(modelClassResponse.statusText);
                      return;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(hideLoader());
                    console.log(JSON.stringify(err));
                  });
              });
            }
          } else {
            dispatch(hideLoader());
            console.log(getGarageResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log(JSON.stringify(err));
        });
    }
  }, [garageData, sessionState.user]);

  //The sorting of cars with recent date
  const sortedResults = searchResultsVin?.sort(
    (a, b) =>
      new Date(b.intro_date).getTime() - new Date(a.intro_date).getTime()
  );

  const groupedByYear: { [year: string]: Vehicle[] } = {};
  sortedResults?.forEach((row) => {
    const year = new Date(row.intro_date).getFullYear().toString();
    if (!groupedByYear[year]) {
      groupedByYear[year] = [];
    }
    groupedByYear[year].push(row);
  });

  return (
    <>
      <IonRow className="resultsVin">
        {!searchResultsVin || searchResultsVin.length === 0 ? (
          <IonCol>
            <IonLabel className="vinResultsText">
              Matches will appear in this section
            </IonLabel>
          </IonCol>
        ) : (
          <>
            <br />
            <p className="vinResultsTxt">
              The filtering information narrowed the possible matches to:{" "}
              {searchResultsVin?.length}
            </p>
            <div className="carDividerVin">
              {addCartAlert && (
                <div className="addToCartAlertCont">
                  <div className="thumb">
                    <IonIcon src={thumbsUpSharp}></IonIcon>
                  </div>
                  <p>{addCartMsg}</p>
                </div>
              )}
              <div className="searchCarContainer">
                {searchResultsVin
                  ?.sort(
                    (a, b) =>
                      new Date(b.intro_date).getTime() -
                      new Date(a.intro_date).getTime()
                  )
                  .map((row, index, array) => {
                    const currentDate = new Date(row.intro_date);
                    const previousDate =
                      index > 0 ? new Date(array[index - 1].intro_date) : null;
                    const showDate =
                      !previousDate ||
                      currentDate.getFullYear() !== previousDate.getFullYear();
                    return (
                      <>
                        {/* {showDate && (
                          <div className="VindateHeader">
                            {moment(currentDate).format("YYYY")}
                          </div>
                        )} */}
                        <div className="returnedCar" key={index}>
                          <div className="returnedCarImage">
                            <img
                              className="carImg"
                              src={getImage(
                                row.g_id.toString(),
                                row.m_id.toString(),
                                row.v_id.toString()
                              )}
                              alt=""
                            />
                          </div>
                          <div className="returnedCarDetails">
                            <span>{row.g_desc + " " + row.m_desc}</span>
                            <span>{row.v_desc}</span>
                            <span>
                              {" "}
                              MY{" "}
                              {moment(new Date(row.intro_date)).format("YYYY")}
                            </span>

                            <span className="checkoutResultsMini">
                              {uiState.VistorsParking?.some(
                                (result) =>
                                  result.g_id === row.g_id &&
                                  result.m_id === row.m_id &&
                                  result.v_id === row.v_id
                              ) ? (
                                <>
                                  {uiState.VistorsParking?.filter(
                                    (result) =>
                                      result.g_id === row.g_id &&
                                      result.m_id === row.m_id &&
                                      result.v_id === row.v_id
                                  ).map((filteredResult, index) => (
                                    <>
                                      <button
                                        onClick={() => {
                                          onSelectVehicleParking(
                                            filteredResult
                                          );
                                          onSelectVehicleAllParking(
                                            filteredResult
                                          );
                                        }}
                                        className="ParkingIcon"
                                      >
                                        <img
                                          id="icon"
                                          src={icons.VistorsParking}
                                        />
                                      </button>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() => {
                                      selectPrimaryVehic(row);
                                      selectPrimaryVehicle(row);
                                    }}
                                    className="eyeIcon"
                                    style={{color: 'white'}}
                                  >
                                    <IonIcon id="icon" src={eyeSharp} color="white" />
                                  </button>
                                </>
                              )}

                              {!garageData?.find(
                                (garageItem: any) =>
                                  garageItem.result[0].groupCode === row.g_id &&
                                  garageItem.result[0].modelCode === row.m_id &&
                                  garageItem.result[0].variantCode === row.v_id
                              ) ? (
                                <button
                                  onClick={() => handleSaveToCart(row, index)}
                                  className="cartIcon"
                                  style={{color: 'white'}}
                                >
                                  ADD TO CART{" "}
                                  <img id="icon" src={icons.WhiteCartIcon} />
                                </button>
                              ) : (
                                <button
                                  //onClick={() => handleSaveToCart(row, index)}
                                  className="Garage-Icon"
                                >
                                  IN GARAGE{" "}
                                  <img id="icon" src={icons.GarageHeader} />
                                </button>
                              )}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </IonRow>
    </>
  );
};

export default SearchResultViewVin;
