import {
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getImage } from '../../api/mapo';
import { desktopImages } from '../../assets';
import DesktopContainer from '../../components/desktop-container';
import DesktopHeader from '../../components/desktop-header';
import DesktopVehicleLabel from '../../components/desktop-vehicle-label';
import Header from '../../components/header';
import '../../Global.css';
import { RootState } from '../../redux/store';
import {
  hideLoader,
  setCreateReportVisible,
  setCustomReportParams,
  setSwapoutCompetitorIndex,
  setSwapoutSearchVisible,
  showLoader,
} from '../../redux/ui/uiSlice';
import './style.css';
import { VehicleCustomReportParams } from '../../interfaces/customReportParams';
import Button from '../../components/button';
import { decVal, format } from '../../utils';
import { Format } from '../../enums';

const CustomizeReport: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const recalculationRequired = useRef(false);
  const [resetting, setResetting] = useState(false);

  const mileageOptions = useCallback(() => {
    const options = [];
    for (let i = 0; i <= 30; i++) {
      options.push(i * 10000);
    }
    return options;
  }, []);

  const anualMileageOptions = useCallback(() => {
    const options = [];
    for (let i = 0; i <= 10; i++) {
      options.push(i * 5000);
    }
    return options;
  }, []);

  const discountOptions = useCallback(() => {
    const options = [];
    for (let i = 0; i <= 60; i++) {
      options.push(format(i * 0.25, Format.Currency));
    }
    return options;
  }, []);

  const interestOptions = useCallback(() => {
    const options = [];
    for (let i = 4.75; i <= 15; i++) {
      options.push(format(i + 0.25, Format.Currency));
    }
    return options;
  }, []);

  const termOptions = useCallback(() => {
    const options = [];
    for (let i = 12; i <= 72; i++) {
      options.push(i);
    }
    return options;
  }, []);

  const getCompetitorCustomParams = useCallback(
    (index) => {
      if (
        uiState?.customReportParams?.competitorCustomParams &&
        uiState?.customReportParams?.competitorCustomParams?.length > index
      ) {
        return uiState?.customReportParams?.competitorCustomParams[index];
      } else {
        return {};
      }
    },
    [uiState?.customReportParams?.competitorCustomParams]
  );

  const updateCompetitorCustomParams = useCallback(
    (newParams: VehicleCustomReportParams, index: number) => {
      const newCompetitorCustomParams = [
        ...(uiState?.customReportParams.competitorCustomParams ?? []),
      ];
      newCompetitorCustomParams[index] = newParams;
      dispatch(
        setCustomReportParams({
          ...uiState?.customReportParams,
          competitorCustomParams: newCompetitorCustomParams,
        })
      );
    },
    [uiState?.customReportParams, dispatch]
  );

  const onResetDefault = useCallback(() => {
    setResetting(true);
    const selectedVehicleCustomParams: VehicleCustomReportParams = {
      retailprice: decVal(
        uiState.selectedVehicle?.data?.modelSpecification.result?.basic_specs
          ?.base_price
      ),
      co2tax: Number(
        uiState.selectedVehicle?.data?.modelSpecification.result?.engine_specs
          .co2 ?? ''
      ),

      // Service
      servicestandardkm: Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          ?.service_maintenance_warranty.service_plan ?? '0'
      ),
      servicestandardmnt: Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          ?.service_maintenance_warranty.service_plan_months ?? '0'
      ),

      // Maintenance
      maintenancestandardkm: Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          ?.service_maintenance_warranty.maintenance_plan ?? '0'
      ),
      maintenancestandardmnt: Number(
        uiState.selectedVehicle?.data?.modelSpecification.result
          ?.service_maintenance_warranty.maintenance_plan_months ?? '0'
      ),

      // baloonpayment: 0,
    };

    const competitorCustomParams: Array<VehicleCustomReportParams> = [];

    uiState.competitors?.map((competitor) => {
      const competitorCustomParam: VehicleCustomReportParams = {
        retailprice: decVal(
          competitor.modelSpecification.result?.basic_specs?.base_price
        ),
        co2tax: Number(
          competitor.modelSpecification.result?.engine_specs.co2 ?? ''
        ),

        // Service
        servicestandardkm: Number(
          competitor.modelSpecification.result?.service_maintenance_warranty
            .service_plan ?? '0'
        ),
        servicestandardmnt: Number(
          competitor.modelSpecification.result?.service_maintenance_warranty
            .service_plan_months ?? '0'
        ),

        // Maintenance
        maintenancestandardkm: Number(
          competitor.modelSpecification.result?.service_maintenance_warranty
            .maintenance_plan ?? '0'
        ),
        maintenancestandardmnt: Number(
          competitor.modelSpecification.result?.service_maintenance_warranty
            .maintenance_plan_months ?? '0'
        ),

        baloonpayment: 0,
      };
      competitorCustomParams.push(competitorCustomParam);
    });

    dispatch(
      setCustomReportParams({
        ...uiState.customReportParams,
        selectedVehicleCustomParams: selectedVehicleCustomParams,
        competitorCustomParams: competitorCustomParams,
      })
    );

    setTimeout(() => {
      setResetting(false);
    }, 300);
  }, [uiState, dispatch]);

  const onSwapCompetitor = useCallback(
    (index: number) => {
      dispatch(setSwapoutCompetitorIndex(index));
      dispatch(setSwapoutSearchVisible(true));
    },
    [dispatch]
  );

  const doCalcs = useCallback(
    (customParams: VehicleCustomReportParams) => {
      const retailprice =
        (customParams.retailprice ?? 0) + (customParams.optionalextras ?? 0);

      let newDiscount = 0;
      if ((customParams?.discountPerc ?? 0) !== 0) {
        const discountPerc =
          (customParams?.discountPerc ?? 0) > 0
            ? (customParams?.discountPerc ?? 0) / 100
            : 0;
        newDiscount = retailprice * discountPerc;
      }

      let newDeposit = 0;
      if ((customParams?.depositPerc ?? 0) !== 0) {
        const depositPerc =
          (customParams?.depositPerc ?? 0) > 0
            ? (customParams?.depositPerc ?? 0) / 100
            : 0;
        newDeposit = retailprice * depositPerc;
      }

      let newResidual = 0;
      if ((customParams?.residualPerc ?? 0) !== 0) {
        const residualPerc =
          (customParams?.residualPerc ?? 0) > 0
            ? (customParams?.residualPerc ?? 0) / 100
            : 0;
        newResidual = retailprice * residualPerc;
      }

      const co2 = customParams.co2tax ?? 0;
      const interestRate = uiState?.customReportParams.interest ?? 0;
      const numberOfPayments = uiState?.customReportParams.term ?? 0;

      // Calculated
      let totalPriceIncludingVAT = 0;
      let totalPriceExcludingVAT = 0;
      let financeAmount = 0;
      let totalCost = 0;
      let totalInterest = 0;

      totalPriceExcludingVAT = retailprice - newDiscount;
      let vat = totalPriceExcludingVAT * 0.15;
      totalPriceIncludingVAT = totalPriceExcludingVAT + vat;
      financeAmount = totalPriceIncludingVAT + co2 - newResidual - newDeposit;
      if (uiState?.customReportParams?.selectedVehicleCustomParams?.cash) {
        totalCost = financeAmount;
        totalInterest = 0;
      } else {
        totalCost =
          ((interestRate / 1200) * financeAmount * numberOfPayments) /
          (1 - Math.pow(1 + interestRate / 1200, -numberOfPayments));
        totalInterest = totalCost - financeAmount;
      }

      return {
        totalPriceIncludingVAT,
        totalPriceExcludingVAT,
        financeAmount,
        totalCost,
        totalInterest,
        vat,
        newDeposit,
        newDiscount,
        newResidual,
      };
    },
    [uiState?.customReportParams]
  );

  const timedCalcs = useCallback(() => {
    if (recalculationRequired.current) {
      const {
        totalPriceExcludingVAT,
        financeAmount,
        totalCost,
        totalInterest,
        vat,
        newDeposit,
        newDiscount,
        newResidual,
      } = doCalcs(
        uiState?.customReportParams?.selectedVehicleCustomParams ?? {}
      );

      const newCompetitors: Array<VehicleCustomReportParams> = [];

      uiState?.customReportParams?.competitorCustomParams?.map(
        (customReportParams: VehicleCustomReportParams) => {
          const {
            totalPriceExcludingVAT,
            financeAmount,
            totalCost,
            totalInterest,
            vat,
            newDeposit,
            newDiscount,
            newResidual,
          } = doCalcs(customReportParams ?? {});
          const newCustomReportParams: VehicleCustomReportParams = {
            ...customReportParams,
            financeamount: financeAmount ?? 0,
            vat: vat ?? 0,
            subtotal: totalPriceExcludingVAT ?? 0,
            totalinterest: totalInterest ?? 0,
            totalcost: totalCost ?? 0,
          };
          if ((customReportParams?.discountPerc ?? 0) !== 0) {
            newCustomReportParams.discount = newDiscount;
          }
          if (
            (uiState?.customReportParams?.selectedVehicleCustomParams
              ?.depositPerc ?? 0) !== 0
          ) {
            newCustomReportParams.deposit = newDeposit;
          }
          if (
            (uiState?.customReportParams?.selectedVehicleCustomParams
              ?.residualPerc ?? 0) !== 0
          ) {
            newCustomReportParams.residual = newResidual;
            newCustomReportParams.baloonpayment = newResidual;
          }
          newCompetitors.push(newCustomReportParams);
          return '';
        }
      );

      dispatch(
        setCustomReportParams({
          ...uiState?.customReportParams,
          selectedVehicleCustomParams: {
            ...uiState?.customReportParams.selectedVehicleCustomParams,
            financeamount: financeAmount ?? 0,
            vat: vat ?? 0,
            subtotal: totalPriceExcludingVAT ?? 0,
            totalinterest: totalInterest ?? 0,
            totalcost: totalCost ?? 0,
            deposit: newDeposit,
            discount: newDiscount,
            residual: newResidual,
            baloonpayment: newResidual,
          },
          competitorCustomParams: newCompetitors,
        })
      );

      setTimeout(() => {
        recalculationRequired.current = false;
      }, 500);
    }
  }, [recalculationRequired, dispatch, doCalcs, uiState?.customReportParams]);

  useEffect(() => {
    if (!recalculationRequired.current) {
      recalculationRequired.current = true;
      timedCalcs();
    }
  }, [
    uiState?.customReportParams?.selectedVehicleCustomParams?.retailprice,
    uiState?.customReportParams?.selectedVehicleCustomParams?.depositPerc,
    uiState?.customReportParams?.selectedVehicleCustomParams?.discountPerc,
    uiState?.customReportParams?.selectedVehicleCustomParams?.optionalextras,
    uiState?.customReportParams?.selectedVehicleCustomParams?.residual,
    uiState?.customReportParams?.selectedVehicleCustomParams?.cash,
    uiState?.customReportParams?.interest,
    uiState?.customReportParams?.term,
    uiState?.customReportParams?.residual,
    uiState?.customReportParams?.competitorCustomParams,
    uiState.customReportParams.toggles.finance,
    timedCalcs,
  ]);

  const renderAddCompetitorButtons = useCallback(() => {
    // console.log('uiState.competitors?.length', uiState.competitors?.length);
    const missingCompetitors = uiState.competitors
      ? 3 - uiState.competitors?.length
      : 3;
    console.log('missingCompetitors', missingCompetitors);
    for (
      let i = uiState.competitors?.length ?? 0;
      i <= (uiState.competitors?.length ?? 0) + missingCompetitors;
      i++
    ) {
      // console.log('render competitor');
      return (
        <IonCol
          key={'col' + i + 1}
          size='3'
          className='ion-no-padding detailBox'
        >
          <IonCol size='3'>
            <DesktopVehicleLabel />
            <div className='centerContent'>
              <img
                className='desktopOtherCarImage'
                src={getImage('', '', '')}
                alt=''
              />
            </div>
            <br />
            <a
              href='#'
              onClick={() => onSwapCompetitor(i)}
              className='centerContent'
            >
            </a>
          </IonCol>
        </IonCol>
      );
    }
  }, [onSwapCompetitor, uiState.competitors]);

  if (uiState.isDesktop) {
    return (
      <>
        <IonPage className='pageDesktopBg'>
          <DesktopHeader></DesktopHeader>
          {!resetting ? (
            <IonRow className='desktopContent'>
              {/* <IonCol size='1' className='ion-no-padding ion-no-margin'>
              <SideMenu></SideMenu>
            </IonCol> */}
              <IonCol size='12' className='ion-no-padding ion-no-margin'>
                <DesktopContainer>
                  <div className='detailContent'>
                    <IonRow className='spaceitems'>
                      <IonCol size='10' className='ion-no-padding'>
                        <span
                          className='desktopHeadingText'
                          style={{
                            color: sessionState.theme?.sidebar_background,
                          }}
                        >
                          Customize Report
                        </span>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol
                        key={'col-1'}
                        size='3'
                        className='ion-no-padding detailBox'
                      >
                        <IonCol size='3'>
                          {uiState.selectedVehicle ? (
                            <>
                              <DesktopVehicleLabel
                                vehicle={uiState.selectedVehicle}
                              />
                              <div className='centerContent'>
                                <img
                                  className='desktopOtherCarImage'
                                  src={getImage(
                                    uiState.selectedVehicle.g_id.toString(),
                                    uiState.selectedVehicle.m_id.toString(),
                                    uiState.selectedVehicle.v_id.toString()
                                  )}
                                  alt=''
                                />
                              </div>
                            </>
                          ) : null}
                          <br />
                          <IonRow>
                            <IonCol size='5.5' className='desktopLabel'>
                              TOTAL LIFESPAN
                            </IonCol>
                            <IonCol size='6.5' className='ion-no-padding'>
                              <IonSelect
                                value={
                                  uiState?.customReportParams.totalLifeSpan
                                }
                                className='selectCustomize'
                                onIonChange={(ev: any) =>
                                  dispatch(
                                    setCustomReportParams({
                                      ...uiState?.customReportParams,
                                      totalLifeSpan: Number(
                                        ev.target.value ?? '0'
                                      ),
                                    })
                                  )
                                }
                              >
                                {mileageOptions().map((option, index) => {
                                  return (
                                    <IonSelectOption
                                      key={'mo' + index}
                                      value={option}
                                    >
                                      {option}
                                    </IonSelectOption>
                                  );
                                })}
                              </IonSelect>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size='5.5' className='desktopLabel'>
                              ANNUAL MILEAGE
                            </IonCol>
                            <IonCol size='6.5' className='ion-no-padding'>
                              <IonSelect
                                className='selectCustomize'
                                value={
                                  uiState?.customReportParams.annualMileage
                                }
                                onIonChange={(ev: any) =>
                                  dispatch(
                                    setCustomReportParams({
                                      ...uiState?.customReportParams,
                                      annualMileage: Number(
                                        ev.target.value ?? '0'
                                      ),
                                    })
                                  )
                                }
                              >
                                {anualMileageOptions().map((option) => {
                                  return (
                                    <IonSelectOption value={option}>
                                      {option}
                                    </IonSelectOption>
                                  );
                                })}
                              </IonSelect>
                            </IonCol>
                          </IonRow>
                          <br />
                          <IonRow className='group'>
                            <IonCol size='2'>
                              <IonToggle
                                checked={
                                  uiState?.customReportParams?.toggles?.finance
                                }
                                onIonChange={(ev: any) =>
                                  dispatch(
                                    setCustomReportParams({
                                      ...uiState?.customReportParams,
                                      toggles: {
                                        ...uiState?.customReportParams.toggles,
                                        finance: ev.target.checked,
                                      },
                                      selectedVehicleCustomParams: {
                                        ...uiState?.customReportParams
                                          .selectedVehicleCustomParams,
                                        retailprice: ev.target.checked
                                          ? uiState?.customReportParams
                                              ?.selectedVehicleCustomParams
                                              ?.retailprice ?? 0
                                          : 0,
                                      },
                                    })
                                  )
                                }
                              ></IonToggle>
                            </IonCol>
                            <IonCol>
                              <IonLabel className='groupHeading'>
                                FINANCE
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            DEAL TYPE
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonSelect
                              className='selectCustomize'
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.cash
                                  ? 'Cash'
                                  : 'Finance'
                              }
                              onIonChange={(ev: any) => {
                                const cashDeal =
                                  ev.target.value === 'Cash' ? true : false;
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    interest: cashDeal ? 0 : 9.5,
                                    term: cashDeal ? 0 : 60,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      cash: cashDeal,
                                    },
                                  })
                                );
                              }}
                            >
                              <IonSelectOption value={'Finance'}>
                                Finance
                              </IonSelectOption>
                              <IonSelectOption value={'Cash'}>
                                Cash
                              </IonSelectOption>
                            </IonSelect>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            RETAIL PRICE
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.retailprice
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      retailprice: Number(
                                        ev?.target?.value?.replace(',', '.') ??
                                          '0'
                                      ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            DISCOUNT
                          </IonCol>
                          <IonCol
                            size='3'
                            className='ion-no-padding rightMargin'
                          >
                            <IonSelect
                              className='selectCustomize'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.discountPerc,
                                Format.Currency
                              )}
                              onIonChange={(ev: any) => {
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      discountPerc: Number(
                                        ev?.target?.value?.replace(',', '.') ??
                                          '0'
                                      ),
                                    },
                                  })
                                );
                              }}
                            >
                              {discountOptions().map((option) => {
                                return (
                                  <IonSelectOption value={option}>
                                    {option}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonCol>
                          <IonCol
                            size='3.5'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.discount ?? 0,
                                Format.Currency
                              )}
                              readonly
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>ADDITIONAL COSTS</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.optionalextras
                              }
                            ></IonInput>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            DEPOSIT
                          </IonCol>
                          <IonCol
                            size='3'
                            className='ion-no-padding rightMargin'
                          >
                            <IonSelect
                              className='selectCustomize'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.depositPerc,
                                Format.Currency
                              )}
                              onIonChange={(ev: any) => {
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      depositPerc: Number(
                                        ev?.target?.value?.replace(',', '.') ??
                                          '0'
                                      ),
                                    },
                                  })
                                );
                              }}
                            >
                              {discountOptions().map((option) => {
                                return (
                                  <IonSelectOption value={option}>
                                    {option}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonCol>
                          <IonCol
                            size='3.5'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.deposit,
                                Format.Currency
                              )}
                              readonly
                            ></IonInput>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>SUBTOTAL</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.subtotal,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>CO2 TAX</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.co2tax,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>VAT</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.vat,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>FINANCED AMOUNT</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.financeamount,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            INTEREST RATE & TERM
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding rightMargin'
                          >
                            <IonSelect
                              className='selectCustomize'
                              value={format(
                                uiState?.customReportParams?.interest,
                                Format.Currency
                              )}
                              onIonChange={(ev: any) => {
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    interest: Number(
                                      ev?.target?.value?.replace(',', '.') ??
                                        '0'
                                    ),
                                  })
                                );
                              }}
                            >
                              {interestOptions().map((option) => {
                                return (
                                  <IonSelectOption value={option}>
                                    {option}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonCol>
                          <IonCol size='1'>
                            <span className='suffix'>%</span>
                          </IonCol>
                          <IonCol
                            size='2'
                            pull='0.5'
                            className='ion-no-padding leftMargin'
                          >
                            <IonSelect
                              className='selectCustomize'
                              value={uiState?.customReportParams?.term}
                              onIonChange={(ev: any) => {
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    term: ev.target.value,
                                  })
                                );
                              }}
                            >
                              {termOptions().map((option) => {
                                return (
                                  <IonSelectOption value={option}>
                                    {option}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonCol>
                          <IonCol size='1.5'>
                            <span className='suffix'>MNT</span>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            RESIDUAL/BALOON
                          </IonCol>
                          <IonCol
                            size='3'
                            className='ion-no-padding rightMargin'
                          >
                            <IonSelect
                              className='selectCustomize'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.residualPerc,
                                Format.Currency
                              )}
                              onIonChange={(ev: any) => {
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      residualPerc: Number(
                                        ev?.target?.value?.replace(',', '.') ??
                                          '0'
                                      ),
                                    },
                                  })
                                );
                              }}
                            >
                              {discountOptions().map((option) => {
                                return (
                                  <IonSelectOption value={option}>
                                    {option}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonCol>
                          <IonCol
                            size='3.5'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.residual ?? 0,
                                Format.Currency
                              )}
                              readonly
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>TOTAL INTEREST</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.totalinterest,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>TOTAL COST</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              readonly
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.totalcost,
                                Format.Currency
                              )}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles?.service
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      service: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>
                              SERVICE
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            STANDARD PLAN
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding rightMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              type='number'
                              readonly
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.servicestandardkm
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1'>
                            <span className='suffix'>KM</span>
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              type='number'
                              readonly
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.servicestandardmnt
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1.5'>
                            <span className='suffix'>MNT</span>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            EXTEND PLAN TO
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding rightMargin'
                          >
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.serviceadditionalkm
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        .selectedVehicleCustomParams,
                                      serviceadditionalkm: Number(
                                        ev.target.value
                                      ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1'>
                            <span className='suffix'>KM</span>
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.serviceadditionalmnt
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        .selectedVehicleCustomParams,
                                      serviceadditionalmnt: Number(
                                        ev.target.value
                                      ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1.5'>
                            <span className='suffix'>MNT</span>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>ADDITIONAL COST</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.serviceadditionalcost
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      serviceadditionalcost: Number(
                                        ev.target.value ?? '0'
                                      ),
                                      optionalextras:
                                        (uiState?.customReportParams
                                          ?.selectedVehicleCustomParams
                                          ?.maintenanceadditionalcost ?? 0) +
                                        Number(
                                          ev?.target?.value?.replace(
                                            ',',
                                            '.'
                                          ) ?? '0'
                                        ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles
                                  ?.maintenance
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      maintenance: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>
                              MAINTENANCE
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            STANDARD PLAN
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding rightMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              type='number'
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.maintenancestandardkm
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1'>
                            <span className='suffix'>KM</span>
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize inputDisabled'
                              type='number'
                              readonly
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.maintenancestandardmnt
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1.5'>
                            <span className='suffix'>MNT</span>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            EXTEND PLAN TO
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding rightMargin'
                          >
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.maintenanceadditionalkm
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        .selectedVehicleCustomParams,
                                      maintenanceadditionalkm: Number(
                                        ev.target.value
                                      ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1'>
                            <span className='suffix'>KM</span>
                          </IonCol>
                          <IonCol
                            size='2'
                            className='ion-no-padding leftMargin'
                          >
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.maintenanceadditionalmnt
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        .selectedVehicleCustomParams,
                                      maintenanceadditionalmnt: Number(
                                        ev.target.value
                                      ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                          <IonCol size='1.5'>
                            <span className='suffix'>MNT</span>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            <strong>ADDITIONAL COST</strong>
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize'
                              type='number'
                              min={0}
                              value={
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams
                                  ?.maintenanceadditionalcost
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    selectedVehicleCustomParams: {
                                      ...uiState?.customReportParams
                                        ?.selectedVehicleCustomParams,
                                      maintenanceadditionalcost: Number(
                                        ev.target.value ?? '0'
                                      ),
                                      optionalextras:
                                        (uiState?.customReportParams
                                          ?.selectedVehicleCustomParams
                                          ?.serviceadditionalcost ?? 0) +
                                        Number(
                                          ev?.target?.value?.replace(
                                            ',',
                                            '.'
                                          ) ?? '0'
                                        ),
                                    },
                                  })
                                )
                              }
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles?.fuel
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      fuel: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>FUEL</IonLabel>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles?.tyre
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      tyre: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>TYRE</IonLabel>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles
                                  ?.depreciation
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      depreciation: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>
                              DEPRECIATION & RESIDUAL
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size='5.5' className='desktopLabel'>
                            BALOON PAYMENT TO BE SUBTRACTED FROM RESIDUAL
                          </IonCol>
                          <IonCol size='6.5' className='ion-no-padding'>
                            <IonInput
                              className='inputCustomize inputDisabled'
                              value={format(
                                uiState?.customReportParams
                                  ?.selectedVehicleCustomParams?.residual,
                                Format.Currency
                              )}
                              // onIonChange={(ev: any) =>
                              //   dispatch(
                              //     setCustomReportParams({
                              //       ...uiState?.customReportParams,
                              //       selectedVehicleCustomParams: {
                              //         ...uiState?.customReportParams
                              //           ?.selectedVehicleCustomParams,
                              //         baloonpayment: Number(
                              //           ev.target.value ?? '0'
                              //         ),
                              //       },
                              //     })
                              //   )
                              // }
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <br />
                        <IonRow className='group'>
                          <IonCol size='2'>
                            <IonToggle
                              checked={
                                uiState?.customReportParams?.toggles?.insurance
                              }
                              onIonChange={(ev: any) =>
                                dispatch(
                                  setCustomReportParams({
                                    ...uiState?.customReportParams,
                                    toggles: {
                                      ...uiState?.customReportParams.toggles,
                                      insurance: ev.target.checked,
                                    },
                                  })
                                )
                              }
                            ></IonToggle>
                          </IonCol>
                          <IonCol>
                            <IonLabel className='groupHeading'>
                              INSURANCE
                            </IonLabel>
                          </IonCol>
                        </IonRow>

                        {uiState.competitors?.length === 0 ? (
                          <span className='resultSubtitle'>
                            No uiState.competitors data available.
                          </span>
                        ) : null}
                      </IonCol>
                      {uiState.competitors?.map((result, index) => {
                        return (
                          <IonCol
                            key={'col' + index}
                            size='3'
                            className='ion-no-padding detailBox'
                          >
                            <IonCol size='3'>
                              {uiState.selectedVehicle ? (
                                <>
                                  <DesktopVehicleLabel
                                    vehicle={{
                                      make: result.modelSpecification.attributes
                                        .make,
                                      model:
                                        result.modelSpecification.attributes
                                          .model,
                                      variant:
                                        result.modelSpecification.attributes
                                          .variant,
                                      g_id: Number(
                                        result.modelSpecification.attributes.G
                                      ),
                                      m_id: Number(
                                        result.modelSpecification.attributes.M
                                      ),
                                      v_id: Number(
                                        result.modelSpecification.attributes.V
                                      ),
                                      variant_id:
                                        result.modelClass?.result &&
                                        result.modelClass?.result.length > 0
                                          ? result.modelClass?.result[0]
                                              .variantId
                                          : '',
                                    }}
                                  />
                                  <div className='centerContent'>
                                    <img
                                      className='desktopOtherCarImage'
                                      src={getImage(
                                        result.modelSpecification.attributes.G,
                                        result.modelSpecification.attributes.M,
                                        result.modelSpecification.attributes.V
                                      )}
                                      alt=''
                                    />
                                  </div>
                                </>
                              ) : null}
                              <br />
                              <a
                                href='#'
                                onClick={() => onSwapCompetitor(index)}
                                className='centerContent'
                              >
                                <img
                                  src={desktopImages.swapIcon}
                                  alt=''
                                  className='swapIcon'
                                />
                              </a>
                              <IonRow style={{ paddingTop: 60 }}>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    readonly={true}
                                    value={
                                      uiState?.customReportParams
                                        ?.selectedVehicleCustomParams?.cash
                                        ? 'Cash'
                                        : 'Finance'
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .retailprice
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          retailprice: Number(
                                            ev.target.value ?? '0'
                                          ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonSelect
                                    className='selectCustomize'
                                    // value={
                                    //   getCompetitorCustomParams(index)
                                    //     .discountPerc
                                    // }
                                    onIonChange={(ev: any) => {
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          discountPerc: Number(
                                            ev?.target?.value?.replace(
                                              ',',
                                              '.'
                                            ) ?? '0'
                                          ),
                                        },
                                        index
                                      );
                                    }}
                                  >
                                    {discountOptions().map((option) => {
                                      return (
                                        <IonSelectOption value={option}>
                                          {option}
                                        </IonSelectOption>
                                      );
                                    })}
                                  </IonSelect>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='4'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    value={format(
                                      getCompetitorCustomParams(index).discount,
                                      Format.Currency
                                    )}
                                    readonly
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .optionalextras
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonSelect
                                    className='selectCustomize'
                                    // value={
                                    //   getCompetitorCustomParams(index).depositPerc
                                    // }
                                    onIonChange={(ev: any) => {
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          depositPerc: Number(
                                            ev?.target?.value?.replace(
                                              ',',
                                              '.'
                                            ) ?? '0'
                                          ),
                                        },
                                        index
                                      );
                                    }}
                                  >
                                    {discountOptions().map((option) => {
                                      return (
                                        <IonSelectOption value={option}>
                                          {option}
                                        </IonSelectOption>
                                      );
                                    })}
                                  </IonSelect>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='4'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    value={format(
                                      getCompetitorCustomParams(index).deposit,
                                      Format.Currency
                                    )}
                                    readonly
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index).subtotal,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index).co2tax,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index).vat,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index)
                                        .financeamount,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='2.5'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      uiState?.customReportParams.interest,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='2'>
                                  <span className='suffix'>%</span>
                                </IonCol>
                                <IonCol
                                  push='1.5'
                                  size='2.5'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={uiState?.customReportParams.term}
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='1.5'>
                                  <span className='suffix'>MNT</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonSelect
                                    className='selectCustomize'
                                    onIonChange={(ev: any) => {
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          residualPerc: Number(
                                            ev?.target?.value?.replace(
                                              ',',
                                              '.'
                                            ) ?? '0'
                                          ),
                                        },
                                        index
                                      );
                                    }}
                                  >
                                    {discountOptions().map((option) => {
                                      return (
                                        <IonSelectOption value={option}>
                                          {option}
                                        </IonSelectOption>
                                      );
                                    })}
                                  </IonSelect>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='4'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    value={format(
                                      getCompetitorCustomParams(index).residual,
                                      Format.Currency
                                    )}
                                    readonly
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index)
                                        .totalinterest,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    readonly
                                    value={format(
                                      getCompetitorCustomParams(index)
                                        .totalcost,
                                      Format.Currency
                                    )}
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow style={{ paddingTop: 50 }}>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    type='number'
                                    readonly
                                    value={
                                      getCompetitorCustomParams(index)
                                        .servicestandardkm
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1' push='2'>
                                  <span className='suffix'>KM</span>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='2'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    type='number'
                                    readonly
                                    value={
                                      getCompetitorCustomParams(index)
                                        .servicestandardmnt
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='2'>
                                  <span className='suffix'>MNT</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .serviceadditionalkm
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          serviceadditionalkm: Number(
                                            ev.target.value ?? '0'
                                          ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1' push='2'>
                                  <span className='suffix'>KM</span>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='2'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .serviceadditionalmnt
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          serviceadditionalmnt: Number(
                                            ev.target.value ?? '0'
                                          ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='2'>
                                  <span className='suffix'>MNT</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .serviceadditionalcost
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          serviceadditionalcost: Number(
                                            ev.target.value ?? '0'
                                          ),
                                          optionalextras:
                                            (getCompetitorCustomParams(index)
                                              .maintenanceadditionalcost ?? 0) +
                                            Number(
                                              ev?.target?.value?.replace(
                                                ',',
                                                '.'
                                              ) ?? '0'
                                            ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                              <IonRow style={{ paddingTop: 50 }}>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    type='number'
                                    readonly
                                    value={
                                      getCompetitorCustomParams(index)
                                        .maintenancestandardkm
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1' push='2'>
                                  <span className='suffix'>KM</span>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='2'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize inputDisabled'
                                    type='number'
                                    readonly
                                    value={
                                      getCompetitorCustomParams(index)
                                        .maintenancestandardmnt
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='2'>
                                  <span className='suffix'>MNT</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='3'
                                  className='ion-no-padding rightMargin'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .maintenanceadditionalkm
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          maintenanceadditionalkm: Number(
                                            ev.target.value ?? '0'
                                          ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1' push='2'>
                                  <span className='suffix'>KM</span>
                                </IonCol>
                                <IonCol
                                  push='2'
                                  size='2'
                                  className='ion-no-padding leftMargin'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .maintenanceadditionalmnt
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          maintenanceadditionalmnt: Number(
                                            ev.target.value ?? '0'
                                          ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                                <IonCol size='1.5' push='2'>
                                  <span className='suffix'>MNT</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol
                                  push='2'
                                  size='7'
                                  className='ion-no-padding'
                                >
                                  <IonInput
                                    className='inputCustomize'
                                    type='number'
                                    value={
                                      getCompetitorCustomParams(index)
                                        .maintenanceadditionalcost
                                    }
                                    onIonChange={(ev: any) =>
                                      updateCompetitorCustomParams(
                                        {
                                          ...getCompetitorCustomParams(index),
                                          maintenanceadditionalcost: Number(
                                            ev.target.value ?? '0'
                                          ),
                                          optionalextras:
                                            (getCompetitorCustomParams(index)
                                              .serviceadditionalcost ?? 0) +
                                            Number(
                                              ev?.target?.value?.replace(
                                                ',',
                                                '.'
                                              ) ?? '0'
                                            ),
                                        },
                                        index
                                      )
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonCol>
                        );
                      })}
                      {renderAddCompetitorButtons()}
                    </IonRow>
                    <IonRow>
                      <IonCol size='8'></IonCol>
                      <IonCol size='2'>
                        <Button
                          onPress={() => {
                            dispatch(setCreateReportVisible(true));
                          }}
                          title='SAVE CUSTOMIZATION'
                          customClass='blueButton'
                        />
                      </IonCol>
                      <IonCol size='2'>
                        <Button
                          onPress={onResetDefault}
                          title='RESET DEFAULT'
                          customClass='redButton'
                        />
                      </IonCol>
                    </IonRow>
                  </div>
                </DesktopContainer>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow className='desktopContent'></IonRow>
          )}
        </IonPage>
      </>
    );
  } else {
    return (
      <>
        <IonPage className='pageBg'>
          <Header></Header>
          <IonContent className='contentPage'>
            <div className='innerContent'>About - Content required...</div>
          </IonContent>
        </IonPage>
      </>
    );
  }
};

export default CustomizeReport;
