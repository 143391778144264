import { SelectedVehicle } from '../../interfaces';
import './style.css';

interface DesktopVehicleLabelProps {
  vehicle?: SelectedVehicle;
  vertical?: boolean;
}

const DesktopVehicleLabelModel: React.FC<DesktopVehicleLabelProps> = ({
  vehicle,
  vertical,
}: DesktopVehicleLabelProps) => {
  return (
    <div className='vehicleModelDisplay' style={vertical ? { textAlign: 'center' } : {}}>
      <span className='dvlBrand'>{vehicle?.make} </span>
      {vertical ? <br /> : null}
      <span className='dvlModel'>{vehicle?.model} </span>
      {vertical ? <br /> : null}
      <br />
      {vehicle?.g_id !== 0 ? (
        <span className='dvlModel'>
          G{vehicle?.g_id}M{vehicle?.m_id}{' '}
        </span>
      ) : (
        <span className='dvlModel'> </span>
      )}
      <br />
      
    </div>
  );
};

export default DesktopVehicleLabelModel;
