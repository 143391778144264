import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonPopover,
  IonRange,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import { format } from "../../utils";
import { useHistory } from "react-router-dom";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const FinanceCalculator: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const [depositAmount, setDepositAmount] = useState(0);
  const [paymentTerm, setPaymentTerm] = useState(60);
  const [balloonPayment, setBalloonPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(11);
  const [monthlyInstallment, setMonthlyInstallment] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [ContractFee, setContractFee] = useState("");
  const [LicenceFee, setLicenceFee] = useState("");
  const [OptionalFee, setoptionalFee] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const [basePrice, setBasePrice] = useState(
    uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
      .base_price
  );

  useEffect(() => {}, [basePrice]);

  const handleInputChange = (e: any) => {
    const inputValue = e.detail.value.replace(/\s/g, "");
    setBasePrice(inputValue);
  };

  // Ensure that basePrice is not undefined before formatting
  const formattedBasePrice =
    basePrice !== undefined
      ? new Intl.NumberFormat().format(parseFloat(basePrice))
      : "";

  //for the ballon payment
  const handleTouchStart = () => {
    setIsTouching(true);
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
  };

  useEffect(() => {
    // Calculate the total amount whenever any relevant data changes
    const calculatedTotalAmount =
      Number(LicenceFee) +
      Number(ContractFee) +
      Number(OptionalFee) +
      (Number(basePrice) * 15) / 100 +
      Number(basePrice);

    // Update the state with the new total amount
    setTotalAmount(calculatedTotalAmount);
  }, [LicenceFee, ContractFee, OptionalFee, uiState.selectedVehicle]);

  //Checking the event change
  const onChangeContractFee = (event: any) => {
    setContractFee(event.target.value);
  };

  const onChangeLicenseFee = (event: any) => {
    setLicenceFee(event.target.value);
  };

  const onChangeOptionalCost = (event: any) => {
    setoptionalFee(event.target.value);
  };

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.VehicleDetail));
    history.push("/VehicleDetail");
  }, [dispatch]);

  function calculateCarLoan(
    loanAmount: number,
    termMonths: number,
    interestRate: number
  ): { monthlyPayment: number; totalPayment: number } {
    // const termMonths = termYears * 12;
    const monthlyInterestRate = interestRate / 1200; // 12 months in a year, 100 to convert from percentage to decimal, 1200 is the result.
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -termMonths));
    const totalPayment = monthlyPayment * termMonths;
    return { monthlyPayment, totalPayment };
  }

  const getFinancedAmountBallon = useCallback(() => {
    let financeAmount =
      (Number(basePrice) * 15) / 100 + Number(basePrice) - depositAmount;

    // if (balloonPayment !== 0) {
    //   let balloonAmount = financeAmount * (balloonPayment / 100);
    //   financeAmount = financeAmount - balloonAmount;
    // }
    return financeAmount;
  }, [
    depositAmount,
    balloonPayment,
    uiState.selectedVehicle,
    ContractFee,
    LicenceFee,
  ]);

  const getBallonPayment = useCallback(() => {
    let balloonAmount = (totalAmount * balloonPayment) / 100;
    return balloonAmount;
  }, [
    depositAmount,
    balloonPayment,
    uiState.selectedVehicle,
    ContractFee,
    LicenceFee,
    OptionalFee,
  ]);

  const getFinancedAmount = useCallback(() => {
    let financeAmount =
      // Number(
      //   uiState.selectedVehicle?.data?.modelSpecification?.result.basic_specs
      //     .base_price
      // ) - depositAmount;

      (Number(LicenceFee) * 15) / 100 +
      Number(LicenceFee) +
      (Number(ContractFee) * 15) / 100 +
      Number(ContractFee) +
      (Number(OptionalFee) * 15) / 100 +
      Number(OptionalFee) +
      (Number(basePrice) * 15) / 100 +
      Number(basePrice) -
      depositAmount;

    // if (balloonPayment !== 0) {
    //   let balloonAmount = financeAmount * (balloonPayment / 100);
    //   financeAmount = financeAmount - balloonAmount;
    // }
    return financeAmount;
  }, [
    depositAmount,
    balloonPayment,
    uiState.selectedVehicle,
    ContractFee,
    LicenceFee,
    OptionalFee,
  ]);

  useEffect(() => {
    const { monthlyPayment, totalPayment } = calculateCarLoan(
      getFinancedAmount(),
      paymentTerm,
      interestRate
    );
    setMonthlyInstallment(monthlyPayment);
    setTotalCost(totalPayment);
  }, [
    getFinancedAmount,
    paymentTerm,
    interestRate,
    depositAmount,
    balloonPayment,
    uiState.selectedVehicle,
  ]);

  const TotalFinacedAmount = totalCost - getFinancedAmount();
  const TotalInterestAmou = (balloonPayment * TotalFinacedAmount) / 100;

  const [showDisclaimer, setShowDisclaimer] = useState(true);

  useEffect(() => {
    if (showDisclaimer) {
      setTimeout(() => {
        setShowDisclaimer(false);
      }, 6000);
    }
  }, [showDisclaimer]);

  //Calculating the balloon payment

  const P =
    (Number(LicenceFee) * 15) / 100 +
    Number(LicenceFee) +
    (Number(ContractFee) * 15) / 100 +
    Number(ContractFee) +
    (Number(OptionalFee) * 15) / 100 +
    Number(OptionalFee) +
    (Number(basePrice) * 15) / 100 +
    Number(basePrice);
  const r = Number(interestRate) / 100 / 12;
  const n = Number(paymentTerm);
  const b = Number(balloonPayment) / 100;

  const numerator = P * (r / (1 - Math.pow(1 + r, -n)));
  const balloonPayments = (P * b) / n;
  const monthlyPaymentResult = numerator - balloonPayments;

  const [FinanceCash, setFinanceCash] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return (
    <>
      <GlobalGrid
        currentPageTitle="FINANCE CALCULATOR"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <SubPageHeading
            onBack={onBackPress}
            vehicle={uiState.selectedVehicle}
          ></SubPageHeading>
        </div>
        <IonGrid>
          <IonRow className="reverse-row">
            <IonCol
              size="12"
              sizeXs="12"
              sizeMd="12"
              sizeLg="12"
              sizeXl="5"
              className="finance-filters"
            >
              <div className="serviceHeaderCont ">
                <IonRow className="sliderStateDiv">
                  <IonRow className="ToggleDiv">
                    <div className="toggleState">
                      <p>{FinanceCash ? "FINANCE" : "CASH"}</p>
                      <IonToggle
                        checked={FinanceCash}
                        className="FinanceToggleBtn"
                        onIonChange={(e: any) => setFinanceCash(!FinanceCash)}
                      />
                    </div>
                  </IonRow>
                  <div className="from">
                    <span id="fromOne">DEPOSIT AMOUNT</span>
                    <span>
                      {" "}
                      <IonInput
                        type="text"
                        value={
                          depositAmount !== null
                            ? depositAmount.toLocaleString()
                            : ""
                        }
                        onIonChange={(e: any) => {
                          const value = e.detail.value
                            ? parseFloat(e.detail.value.replace(/[^\d.]/g, ""))
                            : 0;
                          setDepositAmount(value);
                        }}
                        className="inline-input"
                      ></IonInput>{" "}
                    </span>
                  </div>

                  <div className="from">
                    <IonRange
                      value={depositAmount}
                      onIonChange={(ev: any) => {
                        setDepositAmount(Number(ev.detail.value));
                      }}
                      min={0}
                      step={500}
                      max={Number(basePrice) ?? 0}
                    ></IonRange>
                  </div>
                </IonRow>
                <IonRow className="sliderStateDiv">
                  <div className="from">
                    <span id="fromOne">PAYMENT TERM</span>
                    <span>
                      {" "}
                      <IonInput
                        type="number"
                        value={paymentTerm}
                        onIonChange={(e: any) => {
                          const value = e.detail.value
                            ? parseInt(e.detail.value, 10)
                            : 0;
                          setPaymentTerm(value);
                        }}
                        className="inline-input"
                      >
                        {" "}
                      </IonInput>
                      {"mnt"}
                    </span>
                  </div>

                  <div className="from">
                    <IonRange
                      onIonChange={(ev: any) => {
                        setPaymentTerm(Number(ev.target.value ?? "0"));
                      }}
                      value={paymentTerm}
                      min={1}
                      max={96}
                    ></IonRange>
                  </div>
                </IonRow>

                <IonRow className="sliderStateDiv MarginTop">
                  <div className="from">
                    <span id="fromOne">BALLOON PAYMENT</span>
                    <span>
                      {" "}
                      <IonInput
                        type="number"
                        value={balloonPayment}
                        debounce={500} // Add a debounce to delay the update
                        onIonChange={(e: any) => {
                          const value = e.detail.value
                            ? parseFloat(e.detail.value)
                            : 0;
                          setBalloonPayment(value);
                        }}
                        className="inline-input"
                      ></IonInput>
                      {"%"}
                    </span>
                  </div>

                  <div className="from">
                    <IonRange
                      onIonChange={(ev: any) => {
                        setBalloonPayment(Number(ev.target.value ?? "0"));
                      }}
                      value={balloonPayment}
                      min={0}
                      max={100}
                      step={1}
                      onTouchStart={handleTouchStart}
                    ></IonRange>
                  </div>
                  <div>
                    <IonCol>
                      {balloonPayment > 0 && (
                        <div className="BalloonPayPop">
                          At the end of the payment term, you'll owe
                          <span className="BallonAmoutColour">
                            {format(getBallonPayment(), Format.Currency)}
                          </span>{" "}
                          plus interest
                        </div>
                      )}
                    </IonCol>
                  </div>
                </IonRow>

                <IonRow className="sliderStateDiv">
                  <div className="from">
                    <span id="fromOne">INTEREST RATE</span>
                    <span>
                      <IonInput
                        type="number"
                        value={interestRate}
                        onIonChange={(e: any) => {
                          const value = e.detail.value
                            ? parseFloat(e.detail.value)
                            : 0;
                          setInterestRate(value);
                        }}
                        className="inline-input"
                      ></IonInput>
                      {"%"}
                    </span>
                  </div>

                  <div className="from">
                    <IonRange
                      onIonChange={(ev: any) => {
                        setInterestRate(Number(ev.target.value ?? "0"));
                      }}
                      value={interestRate}
                      min={1}
                      max={30}
                      step={0.25}
                    ></IonRange>
                  </div>
                </IonRow>
              </div>
            </IonCol>

            <IonCol
              size="11.5"
              sizeXs="11.5"
              sizeMd="11.5"
              sizeLg="12"
              sizeXl="7"
            >
              <div className="innerContent">
                <div>
                  <IonRow className="">
                    <IonCol className="FinanceGrid" size="6">
                      <strong className="list-price-margin">
                        <span className="ListPrices">LIST PRICE</span>
                      </strong>
                    </IonCol>

                    <IonCol className="finance-cal-AmountGrid" size="4">
                      <strong>
                        <span className="LiSTAMOUNT">
                          <IonInput
                            type="text"
                            className="baseInput"
                            value={basePrice}
                            onIonChange={handleInputChange}
                          />
                        </span>
                      </strong>
                    </IonCol>
                  </IonRow>

                  <IonRow className="">
                    <IonCol size="6" className="FinanceGrid">
                      <span className="subtitle ">CONTRACT FEE</span>
                    </IonCol>

                    <IonCol size="4" className="finance-cal-AmountGrid">
                      <span className=" subtitle FontMobileSpecs ">
                        <input
                          placeholder="0.00"
                          type="number"
                          value={ContractFee}
                          onChange={(event) => onChangeContractFee(event)}
                          className="PlaceHolderLicense"
                        />
                      </span>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="6" className="LincenseGrid">
                      <span className="subtitle ">LICENSE & REG</span>
                    </IonCol>

                    <IonCol size="4" className="finance-cal-AmountGrid">
                      <span className="subtitle FontMobileSpecs">
                        <input
                          type="number"
                          value={LicenceFee}
                          onChange={(event) => onChangeLicenseFee(event)}
                          placeholder="0.00"
                          className="PlaceHolderLicense"
                        />
                      </span>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="6" className="LincenseGrid">
                      <span className="subtitle ">OPTIONAL COST</span>
                    </IonCol>

                    <IonCol size="4" className="finance-cal-AmountGrid">
                      <span className="subtitle FontMobileSpecs">
                        <input
                          type="number"
                          value={OptionalFee}
                          onChange={(event) => onChangeOptionalCost(event)}
                          placeholder="0.00"
                          className="PlaceHolderLicense"
                        />
                      </span>
                    </IonCol>
                  </IonRow>
                  <div className="resultDividers"></div>
                  <IonRow className="">
                    <IonCol size="6" className="VatText">
                      <span className="subtitle ">VAT</span>
                    </IonCol>

                    <IonCol size="4" className="finance-cal-AmountGrid ">
                      <span className=" FontMobileSpecs ">
                        {format(
                          (Number(LicenceFee) * 15) / 100 +
                            (Number(ContractFee) * 15) / 100 +
                            (Number(OptionalFee) * 15) / 100 +
                            (Number(basePrice) * 15) / 100,
                          Format.Currency
                        )}
                      </span>
                    </IonCol>
                  </IonRow>

                  <IonRow className="">
                    <IonCol size="6" className="FinanceGrid">
                      <strong>
                        <span className="ListPrice">TOTAL PRICE</span>
                      </strong>
                    </IonCol>

                    <IonCol size="4" className="finance-cal-AmountGrid">
                      <span className="TotalAmount">
                        <strong>
                          {format(
                            (Number(LicenceFee) * 15) / 100 +
                              Number(LicenceFee) +
                              (Number(ContractFee) * 15) / 100 +
                              Number(ContractFee) +
                              (Number(OptionalFee) * 15) / 100 +
                              Number(OptionalFee) +
                              (Number(basePrice) * 15) / 100 +
                              Number(basePrice),
                            Format.Currency
                          )}
                        </strong>
                      </span>
                    </IonCol>
                  </IonRow>
                </div>
                <br />
                <br />
                <IonRow>
                  <IonCol size="8" className="detail FinanceGrids">
                    AMOUNT TO FINANCED
                  </IonCol>
                  <IonCol size="3" className="FinancedAmounts">
                    <span className="subtitle ">
                      {" "}
                      {format(Number(getFinancedAmount()), Format.Currency)}
                    </span>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="8" className="detail FinanceGrids">
                    INTEREST AMOUNT
                  </IonCol>

                  <IonCol size="3" className="FinancedAmounts">
                    <span className="subtitle ">
                      {FinanceCash
                        ? format(
                            totalCost - getFinancedAmount() + TotalInterestAmou,
                            Format.Currency
                          )
                        : "0.00"}
                    </span>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="8" className="detail FinanceGrids">
                    TOTAL COST OF FINANCE AMOUNT
                  </IonCol>

                  <IonCol size="3" className="FinancedAmounts">
                    <span className="subtitle ">
                      {FinanceCash
                        ? format(totalCost + TotalInterestAmou, Format.Currency)
                        : "0.00"}
                    </span>
                  </IonCol>
                </IonRow>
                &nbsp;
                <br />
                <IonRow className="Disclaimer">
                  <IonCol>
                    <IonLabel className="subtitle">
                      <div className="scrollable-content">
                        <p>
                          <span className="FontMobile">
                            Disclaimer All calculations made on calculators
                            supplied on this site, together with rates quoted,
                            are guidelines only and are subject to confirmation
                            at the time of finalising any transactions. All
                            information regarding the products, fees and/or
                            costs which are included in and form a fundamental
                            basis of the calculations are subject to change at
                            any time prior to a final pre-agreement quote being
                            handed to the User.{" "}
                          </span>
                        </p>
                        <p>
                          <span className="FontMobile">
                            {" "}
                            The User indemnifies WesBank against any loss or
                            liability, which the User may suffer as a result of
                            the use of any calculator. The site and all
                            information provided on this site and the services
                            provided on this site, are provided "as is". The
                            information provided on this site should not be
                            treated as professional advice of any kind.{" "}
                          </span>
                        </p>
                      </div>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                &nbsp;
                <br />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="totalsDisplay">
          <span>
            {FinanceCash ? "ESTIMATED MONTHLY PAYMENT" : "TOTAL PRICE"}{" "}
          </span>
          <IonCol
            size=""
            className="ion-justify-content-center mobileTotalCostMoney"
          >
            {FinanceCash
              ? format(monthlyInstallment - balloonPayments, Format.Currency)
              : format(P, Format.Currency)}
          </IonCol>
          {/* <span>{format(monthlyPaymentResult, Format.Currency)}</span> */}
        </div>
      </GlobalGrid>
    </>
  );
};

export default FinanceCalculator;
